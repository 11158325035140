import mapValues from 'lodash-es/mapValues';
import { LOCATION_CHANGE } from 'react-router-redux';

import { MODALS } from '~/static-constants/modals';

import {
  ModalAction,
  MODAL_ACTION_TYPES as ACTIONS,
} from '../../actions/modalActions';

export function createModalsReducer() {
  const reducers = mapValues(MODALS, () => modalReducer);
  const relevantActions = [ACTIONS.SHOW_MODAL, ACTIONS.HIDE_MODAL];
  const initialState = mapValues(reducers, (reducer) =>
    reducer(undefined, {} as ModalAction),
  );

  return (state: Record<string, any> = initialState, action: ModalAction) => {
    if (relevantActions.includes(action.type)) {
      const { modal } = action.meta;
      return {
        ...state,
        [modal]: reducers[modal](state[modal], action),
      };
    } else if (action.type === LOCATION_CHANGE) {
      return mapValues(state, (modalState) => ({
        ...modalState,
        isOpened: false,
      }));
    }

    return state;
  };
}

/*
  Individual modal reducer. Only called if the dispatched action is intended
  for the particular modal the reducer is responsible for.
*/
export type ModalState = {
  isOpened: boolean;
  payload: any;
};

const initialState = {
  isOpened: false,
  payload: null,
};

const modalReducer = (
  state: ModalState = initialState,
  action: ModalAction,
): ModalState => {
  switch (action.type) {
    case ACTIONS.SHOW_MODAL:
      return {
        isOpened: true,
        payload: action.payload,
      };
    case ACTIONS.HIDE_MODAL:
      return {
        ...state,
        isOpened: false,
      };
    default:
      return state;
  }
};
