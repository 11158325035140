import * as React from 'react';
import { Route } from 'react-router';

import { ResendVerificationEmail } from './ResendVerificationEmail';

export const ResendVerificationEmailRoute = () => (
  <Route
    component={ResendVerificationEmail}
    cover
    fallbackRoute="/d/invest"
    path="resend-verification-email"
  />
);
