import {
  Button,
  HM,
  PM,
  Flex,
  ProductCard,
  styled,
  Modal,
  ModalContent,
} from '@m1/liquid-react';
import type { IllustrationsType } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useProductSelectionPageQuery,
  useHasIdentityInfoQuery,
  useHasCxIntegratedOnboardingQuery,
} from '~/graphql/hooks';
import {
  OnboardingProductSelectionDestination,
  ProductSelectionPageQuery,
  ProductListIdentifier,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import {
  beginOnboarding,
  changeBeginOnboardingStep,
  logout,
  setOnboardingProduct,
} from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Container } from '~/toolbox/container';

import { Spinner } from '~/toolbox/spinner';
import { isTruthy } from '~/utils/isTruthy';

import style from '../../onboarding/style.module.scss';

type SingleProduct = NonNullable<
  NonNullable<
    NonNullable<
      ProductSelectionPageQuery['viewer']['onboarding']
    >['productSelection']
  >['products']
>[number];

const PRODUCT_MAP = {
  SPEND_SAVINGS: {
    productType: 'Savings',
    destination: OnboardingProductSelectionDestination.BeginSavingsOnboarding,
  },
  BORROW_PERSONAL_LOANS: {
    productType: 'PersonalLoans',
    destination:
      OnboardingProductSelectionDestination.BeginPersonalLoansOnboarding,
  },
  INVEST_INDIVIDUAL: {
    productType: 'Invest',
    destination: OnboardingProductSelectionDestination.BeginInvestOnboarding,
  },
  INVEST_RETIREMENT: {
    productType: 'Invest',
    destination: OnboardingProductSelectionDestination.BeginIraOnboarding,
  },
};

const ProductCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 4rem;
  padding: 4rem 3rem 6rem 3rem;
  @media (min-width: 720px) {
    flex-direction: row;
    justify-content: space-evenly;
    gap: 2rem;
    padding: 3.5rem 0 6rem 0;
  }
  @media (min-width: 1200px) {
    flex-wrap: nowrap;
  }
`;

const DisclosureContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media (min-width: 720px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

export const ProductSelectionPage = () => {
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, data } = useProductSelectionPageQuery();
  const { data: userRoutingData, loading: userRoutingDataLoading } =
    useHasIdentityInfoQuery();

  const { data: hasCxIntegratedOnboardingData, loading: cxFlagLoadingState } =
    useHasCxIntegratedOnboardingQuery();

  React.useEffect(() => {
    dispatch(beginOnboarding());
  }, [dispatch]);

  const hasCxIntegratedOnboarding = Boolean(
    hasCxIntegratedOnboardingData?.viewer?.user?.onboardingIdentityFirst
      ?.hasCxIntegratedOnboarding,
  );

  React.useEffect(() => {
    // TODO: remove this cxio FF check (how can we remove the ProductSelectionPage altogether and route to phone ver at start?)
    if (!cxFlagLoadingState && hasCxIntegratedOnboarding) {
      dispatch(beginOnboarding());

      navigate({ to: '/onboarding/phone-verification' });
    }
  }, [dispatch, hasCxIntegratedOnboarding, navigate, cxFlagLoadingState]);

  const productsNonNull =
    data?.viewer.onboarding?.productSelection?.products?.filter(isTruthy);

  React.useEffect(() => {
    productsNonNull?.forEach((product) => {
      if (product.analyticsEvent) {
        analytics.recordEvent(product.analyticsEvent.name);
      }
    });
  }, [analytics, productsNonNull]);

  if (loading || userRoutingDataLoading) {
    return <Spinner fullScreen />;
  }

  if (!productsNonNull || productsNonNull.length === 0) {
    return <GenericSystemError />;
  }

  const hasMultipleProducts = productsNonNull.length > 1;
  const hasDirectToIRAOnboarding =
    data?.viewer.onboarding?.onboardingInvest.iraOnboarding
      .directToIRAOnboardingEnabled;
  const hasIdentityInfo = userRoutingData?.viewer?.user?.hasIdentityInfo;
  const latestProductSelection =
    userRoutingData?.viewer?.user?.latestProductSelection;
  const shouldRouteToModuleTwo = hasIdentityInfo && latestProductSelection;

  if (shouldRouteToModuleTwo) {
    dispatch(
      setOnboardingProduct({
        destination: PRODUCT_MAP[latestProductSelection]?.productType,
        productIdentifier: latestProductSelection,
      }),
    );
    dispatch(
      changeBeginOnboardingStep({
        step: PRODUCT_MAP[latestProductSelection]?.destination,
      }),
    );
    navigate({ to: '/onboarding/setup-account' });
  } else if (!hasMultipleProducts) {
    if (hasDirectToIRAOnboarding) {
      navigate({ to: '/start/invest-product-selection' });
    } else {
      navigate({ to: '/onboarding/phone-verification' });
    }
  }

  const handleCardClick = (product: NonNullable<SingleProduct>) => {
    const { analyticsEventClick, destination, identifier } = product;

    if (analyticsEventClick?.name) {
      analytics.recordEvent(analyticsEventClick.name);
    }

    const productType =
      PRODUCT_MAP[identifier || ProductListIdentifier.InvestIndividual]
        .productType;

    dispatch(
      setOnboardingProduct({
        destination: productType,
        productIdentifier: identifier,
      }),
    );

    if (
      hasDirectToIRAOnboarding &&
      destination ===
        OnboardingProductSelectionDestination.BeginInvestOnboarding
    ) {
      navigate({ to: '/start/invest-product-selection' });
    } else if (destination) {
      if (
        destination ===
          OnboardingProductSelectionDestination.BeginInvestOnboarding ||
        destination ===
          OnboardingProductSelectionDestination.BeginSavingsOnboarding ||
        destination ===
          OnboardingProductSelectionDestination.BeginPersonalLoansOnboarding
      ) {
        navigate({ to: '/onboarding/phone-verification' });
      } else {
        dispatch(
          changeBeginOnboardingStep({
            step: destination,
          }),
        );
      }
    }
  };

  return (
    <Container>
      <HM
        zIndex={2}
        content={data?.viewer.onboarding?.productSelection?.title}
        pt={92}
      />
      <ProductCardContainer>
        {productsNonNull.map((product) => (
          <ProductCard
            illustrationName={
              (product.illustration?.names
                ? product.illustration?.names[0]
                : 'warningTriangle') as IllustrationsType
            }
            key={product.identifier}
            title={product.header || undefined}
            description={product.subheader || undefined}
          >
            <Button
              label={product.ctaLabel || 'Open an account'}
              size="large"
              onClick={() => {
                handleCardClick(product);
              }}
            />
          </ProductCard>
        ))}
      </ProductCardContainer>
      <DisclosureContainer>
        {productsNonNull.map((product) => (
          <ProductDisclosure
            key={product.destination}
            label={product.disclosure?.label}
            content={product.disclosure?.content}
          />
        ))}
      </DisclosureContainer>
      {__ENV__ !== 'production' && (
        <div className={style.logout}>
          <Button
            kind="link"
            label="logout"
            onClick={() => dispatch(logout())}
          />
        </div>
      )}
    </Container>
  );
};

type ProductDisclosureProps = {
  label: string | null | undefined;
  content: readonly (string | null)[] | null | undefined;
};

const ProductDisclosure = ({ label, content }: ProductDisclosureProps) => {
  const [isOpen, setModalOpen] = React.useState<boolean>(false);

  if (!label || !content) {
    return <GenericSystemError />;
  }

  return (
    <Flex maxWidth={489} minWidth={380} justifyContent="center" flexGrow={1}>
      <Button
        onClick={() => setModalOpen(true)}
        mb={16}
        label={label}
        style={{
          cursor: 'pointer',
          fontWeight: 400,
          textDecoration: 'underline',
        }}
      />
      <Modal open={isOpen} onClose={() => setModalOpen(false)}>
        <ModalContent>
          {content?.map((content) => (
            <PM key={content} mb={8}>
              {content}
            </PM>
          ))}
        </ModalContent>
      </Modal>
    </Flex>
  );
};
