import { PL, Maybe } from '@m1/liquid-react';
import * as React from 'react';

type CommonTransactionValueCellProps = {
  content?: Maybe<string>;
};

export const CommonTransactionValueCell = ({
  content,
}: CommonTransactionValueCellProps) => {
  return <PL content={content} color="foregroundNeutralMain" />;
};
