import { Box, Flex, styled, Card } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { AddToPortfolioButton } from '~/components/security/AddToPortfolioButton';

import { FundDetailsSection } from '~/components/security/FundDetailsSection';
import { SecurityChartModal } from '~/components/security/SecurityChartModal';
import { SecurityDataContainer } from '~/components/security/SecurityDataContainer';
import { SecurityHistoricalChart } from '~/components/security/SecurityHistoricalChart';
import { SecurityKeyData } from '~/components/security/SecurityKeyData/SecurityKeyData';
import { SecurityMarginRequirements } from '~/components/security/SecurityMarginRequirements';
import { SecurityName } from '~/components/security/SecurityName';
import { SecurityNews } from '~/components/security/SecurityNews';
import { SecurityPriceData } from '~/components/security/SecurityPriceData';
import { SecurityProfileDataContainer } from '~/components/security/SecurityProfile';
import { SecurityCurrentQuote } from '~/components/security/SecurityQuote/SecurityCurrentQuoteContainer';
import { SecurityRelatedCategories } from '~/components/security/SecurityRelatedCategories';
import { Sticky } from '~/components/sticky';
import { SecurityDetailFragment } from '~/graphql/types';
import { useDynamicSizing } from '~/hooks/useDynamicSizing';
import { Grid } from '~/toolbox/grid';
import { Spinner } from '~/toolbox/spinner';

import { CryptoDetailsButtonGroup } from './crypto/details/components';

import { useSecurityDetailsContext } from './SecurityDetailsProvider';

type SecurityDetailsPageProps = {
  showBack?: boolean;
  isCover?: boolean;
};

const StyledGridRow = styled(Grid.Row)<{ isCover: boolean }>`
  padding-top: 16px;
  max-width: 1200px;
  flex-wrap: wrap-reverse;
  ${(props) =>
    props.isCover &&
    `
  margin-right: auto;
  margin-left: auto;
  max-width: '1200px';
  `}}
`;

export const SecurityDetailsPage = ({
  showBack = true,
  isCover = false,
}: SecurityDetailsPageProps) => {
  const {
    queryResult: { data, loading, error },
    disableActions,
  } = useSecurityDetailsContext();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [{ height, width }, resizeRef] = useDynamicSizing({
    shouldResize: !modalOpen,
  });

  if (loading) {
    return <Spinner />;
  }

  if (!data && error) {
    return <GenericSystemError />;
  }

  const security = data?.security as SecurityDetailFragment | null | undefined;

  if (!security) {
    return null;
  }

  const isCrypto = security.__typename === 'CryptoAsset';

  return (
    <Grid>
      <StyledGridRow isCover={isCover}>
        <Grid.Col sm={7} xs={12}>
          <Sticky>
            {showBack && (
              <BackArrow
                content="Back"
                mb={24}
                ml={16}
                mt={32}
                onClick={() => window.history.back()}
              />
            )}
            <Box>
              {!modalOpen && (
                <Box>
                  <Card borderColor="transparent" p={16}>
                    <SecurityName
                      sliceable={security}
                      securityPill={security.securityPill}
                    />
                    <Box>
                      <SecurityCurrentQuote security={security} />
                    </Box>
                  </Card>

                  <Flex ref={resizeRef}>
                    {!modalOpen && (
                      <SecurityHistoricalChart
                        securityId={security.id}
                        setModalOpen={() => setModalOpen(true)}
                        chartHeight={height}
                        chartWidth={width}
                      />
                    )}
                  </Flex>
                </Box>
              )}
            </Box>

            <SecurityChartModal
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              security={security}
            />
            {isCrypto && <SecurityProfileDataContainer security={security} />}
            <SecurityKeyData security={security} />
            <Flex justifyContent="space-between" flexWrap="wrap" maxWidth={790}>
              <SecurityPriceData security={security} />
              {!isCrypto && <SecurityMarginRequirements security={security} />}
            </Flex>

            {!isCrypto && <SecurityProfileDataContainer security={security} />}
            <SecurityRelatedCategories />
            {security.__typename === 'Fund' && <FundDetailsSection />}
            {security.__typename === 'Equity' && (
              <SecurityDataContainer mb={10} headingContent="News">
                <SecurityNews
                  hasBorder={false}
                  securityId={security.id}
                  loadMoreQuantity={50}
                  loadMoreLabel={`See all ${security.symbol} news`}
                />
              </SecurityDataContainer>
            )}
          </Sticky>
        </Grid.Col>
        <Grid.Col sm={5} xs={12}>
          {!disableActions &&
            (isCrypto ? (
              <CryptoDetailsButtonGroup crypto={security} />
            ) : (
              <AddToPortfolioButton
                sliceable={security}
                securityId={security.id}
                type={security.type}
              />
            ))}
        </Grid.Col>
      </StyledGridRow>
    </Grid>
  );
};
