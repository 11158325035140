import { Box, Button, HS, LL } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import { InvestBankConnectionAccountNodeFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { CONNECT_BANK_FLOW_STEPS } from '~/static-constants';
import { Link } from '~/toolbox/link';

import { BankRelationshipViaDeposits } from './BankRelationshipViaDeposits';
import { BankRelationshipViaLink } from './BankRelationshipViaLink';

type InvestAccountBankConnectionProps = {
  account: InvestBankConnectionAccountNodeFragment | null | undefined;
  isEligibleForFundingSourceUpdate: boolean | null | undefined;
  isPrimaryEmailVerified: boolean | null | undefined;
};

export const InvestAccountBankConnection = ({
  account,
  isEligibleForFundingSourceUpdate,
  isPrimaryEmailVerified,
}: InvestAccountBankConnectionProps) => {
  const navigate = useNavigate();
  const hasPendingInvestTransfers = account?.hasPendingTransferInstances;
  const canChangeBank =
    (isEligibleForFundingSourceUpdate && !hasPendingInvestTransfers) ?? false;

  const lastAchRelationship = account?.lastAchRelationship;

  const noInvestOrCanceled =
    !lastAchRelationship || lastAchRelationship.status === 'CANCELED';

  if (noInvestOrCanceled) {
    return (
      <Box mt={32}>
        <HS
          textAlign="left"
          content="Securely connect a bank with Plaid to fund your account. It’s easy."
          mt={16}
        />
        <Box mx="auto" width={184}>
          <Button
            kind="primary"
            size="medium"
            fullWidth
            label="Connect a bank"
            mt={32}
            onClick={() => {
              navigate({
                to: '/d/c/connect-bank',
                query: {
                  initialStep: CONNECT_BANK_FLOW_STEPS.SELECT_PLAID,
                  connectionType: 'invest',
                  previousRouteName: null,
                },
              });
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box mt={16}>
      <BankConnectionType
        canChangeBank={canChangeBank}
        isPrimaryEmailVerified={isPrimaryEmailVerified ?? false}
        lastAchRelationship={lastAchRelationship}
      />

      {lastAchRelationship?.status === 'REJECTED' && (
        <LL color="warning" mt={16}>
          You were unable to connect your bank through Plaid. You can try again
          or contact{' '}
          <Link font="LL" to="/d/contact-us">
            Client Support
          </Link>{' '}
          for help.
        </LL>
      )}
    </Box>
  );
};

type BankConnectionTypeProps = {
  lastAchRelationship:
    | InvestBankConnectionAccountNodeFragment['lastAchRelationship']
    | null
    | undefined;
  canChangeBank: boolean;
  isPrimaryEmailVerified: boolean;
};

const BankConnectionType = ({
  lastAchRelationship,
  canChangeBank,
  isPrimaryEmailVerified,
}: BankConnectionTypeProps) => {
  if (!lastAchRelationship) {
    return null;
  }

  if (lastAchRelationship?.__typename === 'AchRelationshipViaLink') {
    return (
      <BankRelationshipViaLink
        achRelationship={lastAchRelationship}
        canChangeBank={canChangeBank}
      />
    );
  } else if (lastAchRelationship?.__typename === 'AchRelationshipViaDeposits') {
    return (
      <BankRelationshipViaDeposits
        achRelationship={lastAchRelationship}
        canChangeBank={canChangeBank}
        isPrimaryEmailVerified={isPrimaryEmailVerified ?? false}
      />
    );
  }
  return <GenericSystemError />;
};
