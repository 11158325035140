import { Box, Flex, HM, HXS, PL, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';
import { InjectedRouteProps } from 'react-router';

import { ExternalLinkButton } from '~/components/ExternalLinkButton';
import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useBorrowAccountUseCaseInfoQuery,
  useBorrowUseCasesQuery,
} from '~/graphql/hooks';
import { useLocation } from '~/hooks/useLocation';
import { BorrowTransferButton } from '~/pages/dashboard/borrow/components/BorrowTransferButton';
import { BorrowUseCaseDialogContent } from '~/pages/dashboard/borrow/components/BorrowUseCaseDialogContent';
import {
  BorrowMarketingUseCase,
  BORROW_USE_CASES,
} from '~/pages/dashboard/borrow/constants';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';
import { formatNumber } from '~/utils';

const StyledBorrowUseCasesNavBar = styled(Flex)`
  width: 430px;
`;

const StyledBorrowUseCasesNavLink = styled(Link)<{
  isActive: boolean;
}>`
  text-decoration: ${(props) => props.isActive && 'underline'};
`;

const StyledBorrowUseCasesContentContainer = styled(Flex)`
  width: 582px;
`;

export const BorrowUseCasesPage = ({ params }: InjectedRouteProps) => {
  const location = useLocation();

  const borrowId: string = location.query.borrowAccountId;
  const { data, loading, error } = useBorrowUseCasesQuery();

  const { data: accountData } = useBorrowAccountUseCaseInfoQuery({
    variables: {
      borrowAccountId: borrowId,
    },
    skip: !borrowId,
  });

  const borrowAccount =
    accountData?.borrowAccount?.__typename === 'BorrowAccount'
      ? accountData.borrowAccount
      : null;

  const viewer = data?.viewer;

  if (loading) {
    return null;
  }

  if (!viewer && error) {
    return <GenericSystemError />;
  }

  const { useCase: activeUseCase } = params;

  const activeUseCaseDetails: BorrowMarketingUseCase | null | undefined =
    BORROW_USE_CASES.find((useCase) => useCase.route === activeUseCase);

  const isEligibleForBorrow = Boolean(
    borrowAccount && borrowAccount.hasCreditAvailable,
  );

  const readBorrowVariables = () => {
    const emptyAmount = `--%`;
    const investedAmount = 100000;

    if (viewer?.borrow) {
      const { maxBorrowableAccountValuePercent } = viewer.borrow;
      const baseRate =
        typeof viewer.borrow.baseRate === 'number'
          ? `${formatNumber(viewer.borrow.baseRate, '0[.]0[0]')}%`
          : emptyAmount;

      const borrowableAmount =
        typeof maxBorrowableAccountValuePercent === 'number'
          ? `${formatNumber(
              investedAmount * (maxBorrowableAccountValuePercent / 100),
              '$0,0',
            )}`
          : emptyAmount;

      const maxBorrowableAccountValue =
        typeof maxBorrowableAccountValuePercent === 'number'
          ? `${formatNumber(maxBorrowableAccountValuePercent, '0[.]0[0]')}%`
          : 'a portion of your portfolio';

      return {
        baseRate,
        borrowableAmount,
        investedAmount: `${formatNumber(investedAmount, '$0,0')}`,
        maxBorrowableAccountValuePercent: maxBorrowableAccountValue,
      };
    }

    return {
      baseRate: emptyAmount,
      borrowableAmount: emptyAmount,
      investedAmount: emptyAmount,
      maxBorrowableAccountValuePercent: 'a portion of your portfolio',
    };
  };
  const borrowVariables = readBorrowVariables();

  return (
    <Container marginBottom={64} marginTop={120}>
      <Flex>
        <StyledBorrowUseCasesNavBar flexDirection="column" pl={123} pt={72}>
          {BORROW_USE_CASES.map((useCase) => (
            <StyledBorrowUseCasesNavLink
              isActive={activeUseCase === useCase.route}
              key={`${useCase.title}_link`}
              mb={16}
              params={{
                useCase: useCase.route,
              }}
              query={
                borrowAccount && borrowAccount.id
                  ? { borrowAccountId: borrowAccount.id }
                  : undefined
              }
              to="/d/c/borrow-use-cases/:useCase"
            >
              {useCase.title}
            </StyledBorrowUseCasesNavLink>
          ))}
        </StyledBorrowUseCasesNavBar>
        <StyledBorrowUseCasesContentContainer flexDirection="column">
          <HM content="How you can use M1 Margin Loans" mb={32} />
          {activeUseCaseDetails && (
            <>
              <Box>
                <Illustration height={170} name={activeUseCaseDetails.image} />
              </Box>
              <HXS content={activeUseCaseDetails.title} mb={16} mt={32} />
              <BorrowUseCaseDialogContent
                dialogContent={borrowVariables}
                route={activeUseCaseDetails.route}
              />
            </>
          )}
          <Box my={24}>
            <Flex alignItems="center">
              <Icon name="check24" />
              <PL
                ml={8}
                content="Instant leverage for your M1 Brokerage Account"
              />
            </Flex>
            <Flex alignItems="center" mt={16}>
              <Icon name="check24" />
              <PL ml={8} content="Pay back principal any time" />
            </Flex>
          </Box>
          <PL color="foregroundNeutralMain">
            Not all securities can be used as collateral for a Margin Loan. The
            amount you can borrow against a security is subject to change
            without notice. These changes or a fluctuation in the market value
            of a security can lead to a margin call. A{' '}
            <Link
              to="https://help.m1.com/en/collections/9439706-margin-calls"
              target="_blank"
            >
              margin call
            </Link>{' '}
            requires you to deposit additional funds or liquidate some or all of
            of your holdings to bring your account back into good standing. You
            cannot take Margin Loans against Retirement, Custodial, or Trust
            accounts. Margin rates may vary.
          </PL>
          <PL color="foregroundNeutralMain" mt={8}>
            Strategies involving margin mentioned here may not be suitable for
            everyone, and investors should consider their own financial
            situation and have a good understanding of margin before utilizing
            it.
          </PL>
          <Flex mt={32}>
            <ButtonGroup>
              {isEligibleForBorrow && borrowAccount && (
                <BorrowTransferButton
                  borrowAccount={borrowAccount}
                  direction="Borrow"
                  kind="primary"
                />
              )}
              <ExternalLinkButton
                destination="SUPPORT_WHAT_IS_BORROW"
                kind={
                  isEligibleForBorrow && borrowAccount ? 'secondary' : 'primary'
                }
                label="Learn more about margin"
                size="large"
              />
            </ButtonGroup>
          </Flex>
        </StyledBorrowUseCasesContentContainer>
      </Flex>
    </Container>
  );
};
