import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import { CryptoScreener } from './components/CryptoScreener';

import { CryptoDetailsRoute } from './details';

export const ResearchCryptoRoute = () => {
  return (
    <Route path="crypto" ignoreScrollBehavior>
      {CryptoDetailsRoute()}
      <IndexRoute component={CryptoScreener} />
    </Route>
  );
};
