import { Flex, PL } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { UseFormSetValue } from 'react-hook-form';

import { useAutoPayEnrollmentQuery } from '~/graphql/hooks';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';
import { isNonNullable } from '~/utils';

import { ControlledDropdown } from '../ControlledDropdown';

type TransferParticipantDropdownProps = {
  disabled?: boolean;
  control: any;
  warning?: any;
  setValue: UseFormSetValue<any>;
};

export const TransferParticipantDropdown = ({
  disabled = false,
  control,
  warning,
  setValue,
  ...props
}: TransferParticipantDropdownProps) => {
  const { transferParticipantId: disbursementAccountId } = useSelector(
    (state) => state.reactHookForm.directToPersonalLoansTransferParticipant,
  );
  const { loanId } = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION,
  );

  const { data, loading } = useAutoPayEnrollmentQuery({
    variables: {
      loanId,
    },
  });

  if (loading) {
    return <Spinner fullScreen centered />;
  }

  const { autoPayAccountList } =
    data?.viewer?.borrow?.personalLoans?.disbursementFlow?.autoPaySetup ?? {};

  const accounts =
    autoPayAccountList
      ?.filter(isNonNullable)
      .map(({ id: value, transferParticipantName: label, ...props }) => ({
        label,
        value,
        participant: {
          id: value,
          transferParticipantName: label,
          ...props,
        },
      })) ?? [];

  // We want to set the initialAccount to the disbursementAccountId
  // if it's in the list of accounts we got back from lens
  const initialAccount = accounts.find(
    ({ value }) => value === disbursementAccountId,
  )?.value;

  const options = accounts.map((account) => {
    return {
      label: (
        <Flex alignItems="center">
          <Icon name="accountBankAccent32" style={{ marginRight: 12 }} />
          <PL content={account.label} />
        </Flex>
      ),
      value: account.value,
      data: account.participant,
    };
  });

  if (initialAccount) {
    setValue('transferParticipant', initialAccount);
  }

  return (
    <ControlledDropdown
      {...props}
      control={control}
      name="transferParticipant"
      label="Withdraw from"
      options={options}
      disabled={disabled}
      warning={warning}
      size="large"
      rules={{ required: 'Required' }}
      defaultValue={initialAccount && initialAccount[0]}
    />
  );
};
