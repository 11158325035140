import * as React from 'react';
import { IndexRedirect, Route } from 'react-router';

import { TransfersActivityRoute } from '~/pages/dashboard/transfers/transfers-activity';
import { TransfersRulesRoute } from '~/pages/dashboard/transfers/transfers-rules';

import { RolloversRoute } from './rollovers/RolloversRoute';
import { TransfersPage } from './TransfersPage';

export const TransfersRoute = () => {
  return (
    <Route path="transfers" component={TransfersPage}>
      {TransfersRulesRoute()}
      {TransfersActivityRoute()}
      {RolloversRoute()}
      <IndexRedirect to="activity" />
    </Route>
  );
};
