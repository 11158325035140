import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import { StockScreenerPage } from '~/pages/dashboard/research/stocks/components';

import { AddStockDetailsRoute } from './details';

export const AddStocksRoute = () => {
  return (
    <Route path="stocks">
      {AddStockDetailsRoute()}
      <IndexRoute component={StockScreenerPage} />
    </Route>
  );
};
