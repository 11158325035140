import * as React from 'react';
import { Route, Redirect } from 'react-router';

import { PersonalLoanLandingPageRoute } from './landing-page';
import { PersonalLoansApplicationRoute } from './loan-application';

import { PersonalLoansNotEligibleRoute } from './not-eligible';
import { PersonalLoansPage } from './PersonalLoansPage';

export const PersonalLoansRoute = () => {
  return (
    <Route path="personal" component={PersonalLoansPage}>
      {PersonalLoanLandingPageRoute()}
      {PersonalLoansApplicationRoute()}
      {PersonalLoansNotEligibleRoute()}
      <Redirect
        from="autopay"
        to="/d/move-money"
        query={{
          mode: 'SCHEDULE',
          toParticipantType: 'LOAN',
          previousRouteName: '/d/borrow/personal/manage-loan',
        }}
      />
    </Route>
  );
};
