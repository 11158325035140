import React from 'react';

import { AppContext } from '~/AppContext';

import { useLocation } from './useLocation';
/**
 * @deprecated This hook is deprecated and will be removed for the react-router v6 migration.
 * Please refrain from using this hook in new code.
 */
export const useHistory = () => {
  const { history } = React.useContext(AppContext);
  const location = useLocation();
  const push = (args: any) => {
    history.push({ ...location, ...args });
  };

  return { ...history, push };
};
