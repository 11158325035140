import * as React from 'react';
import { IndexRedirect, Route } from 'react-router';

import { CoverEscrow } from '~/components/cover';

import { RewardsSummaryRoute } from './summary';

export const CreditCardRewardsRoute = () => {
  return (
    <Route component={CoverEscrow} path="rewards">
      {RewardsSummaryRoute()}
      <IndexRedirect to="summary" />
    </Route>
  );
};
