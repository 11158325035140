import * as React from 'react';

import { AppContext } from '~/AppContext';
import { NavigationQuery } from '~/graphql/types';
import { DashboardNavigation } from '~/hooks/useDashboardNavigation';
import { useNavigate } from '~/hooks/useNavigate';
import {
  setActiveCheckingAccount,
  setActiveSavingsAccount,
} from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { isNotNil } from '~/utils';

import {
  StyledBorder,
  StyledHeading,
  StyledHeadingLabel,
} from '../Navigation.styled';

import { AccountOptionProps } from './SideNav';
import { SideNavLink } from './SideNavLink';

type SideBarSaveProps = {
  selectedNavigation: DashboardNavigation;
  data: NavigationQuery | undefined;
};

const SaveSideNavLinks = ({
  accounts,
  selectedNavigation,
}: {
  accounts: Array<AccountOptionProps>;
  selectedNavigation: DashboardNavigation;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      {accounts.map((account) => (
        <SideNavLink
          selected={
            selectedNavigation.activeL1Nav === 'save' &&
            selectedNavigation.activeL2Nav === account.id
          }
          key={`save-account-${account.id}`}
          label={account.name}
          subText={account.descriptor?.toString() ?? ''}
          onClick={() => {
            if (account.to === '/d/save/checking/transactions') {
              dispatch(setActiveCheckingAccount(account.id));
            } else if (account.to === '/d/save/savings/transactions') {
              dispatch(setActiveSavingsAccount(account.id));
            }
            navigate({ to: account.to ?? '/d/home' });
          }}
        />
      ))}
    </>
  );
};

export const SideNavSave = ({ data, selectedNavigation }: SideBarSaveProps) => {
  const { analytics } = React.useContext(AppContext);
  const save = data?.viewer.save;

  const { cashAccounts, saveAccounts } = React.useMemo(() => {
    const saveAccounts: Array<AccountOptionProps> = [];
    const cashAccounts: Array<AccountOptionProps> = [];

    const saveEdges = save?.savings?.savingsAccounts?.edges ?? [];
    const allEdges = [...saveEdges];

    allEdges.forEach((edge) => {
      if (
        edge?.__typename === 'SavingsAccountEdge' &&
        edge?.node?.id !== undefined
      ) {
        const accountOption = {
          descriptor: edge.node.descriptor ?? '',
          iconName: 'accountCheckingAccent20',
          id: edge.node.id,
          name: edge.node.name,
          to: '/d/save/savings/transactions',
        } as const;
        edge.node.accountType === 'CASH'
          ? cashAccounts.push(accountOption)
          : saveAccounts.push(accountOption);
      }
    });

    return {
      cashAccounts,
      saveAccounts,
    };
  }, [save]);

  if (!save) {
    return null;
  }

  return (
    <>
      {cashAccounts.length > 0 && (
        <>
          <StyledHeading>
            <StyledHeadingLabel>Cash</StyledHeadingLabel>
          </StyledHeading>
          <SaveSideNavLinks
            accounts={cashAccounts}
            selectedNavigation={selectedNavigation}
          />
        </>
      )}
      {saveAccounts.length > 0 && (
        <>
          <StyledHeading>
            <StyledHeadingLabel>Savings</StyledHeadingLabel>
          </StyledHeading>
          <SaveSideNavLinks
            accounts={saveAccounts}
            selectedNavigation={selectedNavigation}
          />
        </>
      )}
      {save?.addSaveAccountCta?.subtitle ? (
        <>
          <StyledBorder />
          <SideNavLink
            selected={false}
            key="save-account-add"
            icon={
              isNotNil(save.addSaveAccountCta.icon)
                ? {
                    position: 'right',
                    default: save.addSaveAccountCta.icon,
                    active: save.addSaveAccountCta.icon,
                  }
                : undefined
            }
            label={save.addSaveAccountCta?.title}
            subText={save.addSaveAccountCta?.subtitle}
            linkTo={save.addSaveAccountCta?.internalPath ?? undefined}
            analyticsProps={{
              name: 'add_account_clicked',
              parameters: {
                product: 'earn',
              },
            }}
            id="add-account-earn"
          />
        </>
      ) : null}
    </>
  );
};
