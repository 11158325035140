import React from 'react';

import {
  useController,
  UseControllerProps,
  FieldValues,
} from 'react-hook-form';

import { Input, InputProps } from '~/toolbox/Input';

export const TextField = <T extends FieldValues>(
  props: UseControllerProps<T> & InputProps,
) => {
  const { field } = useController(props);

  return <Input {...field} {...props} />;
};
