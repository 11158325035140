/* eslint-disable no-constant-condition */

import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { SignAgreementBundleDocument } from '~/graphql/hooks';
import { SignAgreementBundleInput } from '~/graphql/types';

import {
  ACTION_TYPES as ACTIONS,
  displayNotification,
  hideLoadingSpinner,
  navigate,
  showLoadingSpinner,
} from '~/redux/actions';
import { NOTIFICATION_LOCATIONS, NOTIFICATION_TYPES } from '~/static-constants';

import { apolloMutationSaga } from './apolloMutationSaga';

export function* reacceptLegalTermsWatcher(): SagaIterator<void> {
  yield takeLatest(
    ACTIONS.CONFIRM_ACCEPT_LEGAL_TERMS,
    reviewAndAcceptLegalTerms,
  );
}

export function* reviewAndAcceptLegalTerms(action: any): SagaIterator<void> {
  const { signature } = action.payload;

  try {
    yield put(showLoadingSpinner());
    yield call(apolloMutationSaga, {
      mutation: SignAgreementBundleDocument,
      variables: {
        input: {
          signature,
        } satisfies SignAgreementBundleInput,
      },
    });
    yield put({
      type: 'FINISHED_REACCEPTING_LEGAL_TERMS',
    });
  } catch (e: any) {
    yield put(
      displayNotification({
        type: NOTIFICATION_TYPES.ERROR_ALT,
        location: NOTIFICATION_LOCATIONS.REACCEPT_LEGAL_TERMS,
        message: e.message,
      }),
    );
  } finally {
    yield put(hideLoadingSpinner());
    if (action.payload.navigateTo) {
      yield put(navigate({ to: action.payload.navigateTo }));
    }
  }
}
