import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { InvestActivityExcludedBuysPage } from './components';
import { InvestActivityPage } from './InvestActivityPage';

export const ActivityDetailsRoute = () => {
  return (
    <>
      <Route path="activity/:activityId" fallbackRoute="/d/invest/activity">
        <Route
          path="activity-details"
          component={createRouteComponent(InvestActivityPage, (props) => ({
            investActivityEntryId: props.params.activityId,
          }))}
          fallbackRoute="/d/invest/activity"
          cover
        />
        <Route
          path="excluded-buys"
          component={createRouteComponent(
            InvestActivityExcludedBuysPage,
            (props) => ({
              investActivityEntryId: props.params.activityId,
            }),
          )}
          cover
        />
      </Route>
    </>
  );
};
