import { Box, Button, HM, PL } from '@m1/liquid-react';
import isNil from 'lodash-es/isNil';
import * as React from 'react';

import { useGetDocumentsQuery } from '~/graphql/hooks';
import {
  type DocumentNodeFragment,
  DocumentTypeFilterEnumType,
} from '~/graphql/types';
import { useLaunchDarkly } from '~/hooks/useLaunchDarkly';
import { GridTable } from '~/toolbox/grid-table';
import { Spinner } from '~/toolbox/spinner';

import { AccountDocumentsFiltersSection } from './components/AccountDocumentsFiltersSection';
import { AccountOrSpendAccountDocumentRow } from './components/AccountOrSpendAccountDocumentRow';
import { AnnouncementsForDocuments } from './components/AnnouncementsForDocuments';
import { InvestAccountDocumentRow } from './components/InvestAccountDocumentRow';

const { AccountDocument, Statement, TaxForm } = DocumentTypeFilterEnumType;

export const SettingsDocumentsPage = () => {
  const [includeFilter, setIncludeFilter] = React.useState<
    DocumentTypeFilterEnumType[]
  >([AccountDocument, Statement, TaxForm]);

  const { data, loading, error, fetchMore, previousData, refetch } =
    useGetDocumentsQuery({
      variables: {
        first: 20,
        filter: {
          includeStatements: false,
          includeTaxForms: false,
          includeTradeConfirmations: false,
          includeFilter,
        },
      },
    });

  const documents = React.useMemo(() => {
    const documentEdges = data?.viewer?.documents?.edges ?? [];

    const documentNodes: DocumentNodeFragment[] = [];

    for (const documentEdge of documentEdges) {
      const documentNode = documentEdge?.node ?? null;

      if (!isNil(documentNode)) {
        documentNodes.push(documentNode);
      }
    }

    return documentNodes;
  }, [data?.viewer?.documents?.edges]);

  const investmentsRelatedFlag = useLaunchDarkly(
    'settings-documents-investments-related',
    false,
  );

  React.useEffect(() => {
    refetch({
      first: 20,
      filter: {
        includeStatements: false,
        includeTaxForms: false,
        includeTradeConfirmations: false,
        includeFilter,
      },
    });
  }, [includeFilter, refetch]);

  const handleLoadMoreClick = React.useCallback(() => {
    fetchMore({
      variables: {
        after: data?.viewer?.documents?.pageInfo?.endCursor,
      },
    });
  }, [data?.viewer?.documents?.pageInfo?.endCursor, fetchMore]);

  const handleChangeFilter = (filterKey: DocumentTypeFilterEnumType): void => {
    setIncludeFilter((values) => {
      if (values.includes(filterKey)) {
        return values.filter((value) => value !== filterKey);
      }

      return [...values, filterKey];
    });
  };

  const canLoadMore = data?.viewer?.documents?.pageInfo?.hasNextPage ?? false;

  // Only show the full page spinner if this is the first page load. Subsequent
  // loads (due to filter changes) should show the small spinner in the header:
  if (
    (loading && previousData === undefined) ||
    !investmentsRelatedFlag.isReady
  ) {
    return <Spinner />;
  }

  // There was either a query error or Lens was unable to return documents:
  if (error) {
    return (
      <PL
        content="There was an error loading your documents. Please try again or contact support."
        pt={32}
        textAlign="center"
      />
    );
  }

  return (
    <Box px={16} pb={64}>
      <HM content="Documents" fontWeight={300} />
      <AnnouncementsForDocuments />
      <AccountDocumentsFiltersSection
        includeFilter={includeFilter}
        loading={loading}
        onChangeFilter={handleChangeFilter}
        showInvestmentsRelatedFilter={
          investmentsRelatedFlag.flagResult as boolean
        }
      />
      <GridTable
        gridTemplateColumns="minmax(auto, 25%) minmax(300px, 20%) minmax(300px, auto)"
        emptyMessage="No documents to display."
      >
        <GridTable.HeaderRow>
          <GridTable.HeaderCell label="Date" />
          <GridTable.HeaderCell label="Account" />
          <GridTable.HeaderCell label="Document" />
        </GridTable.HeaderRow>

        {documents.map((document) => (
          <AccountDocumentsTableRow key={document.id} document={document} />
        ))}
      </GridTable>

      {canLoadMore && (
        <Box pt={16} textAlign="center">
          <Button
            disabled={loading}
            label="Load More"
            kind="secondary"
            size="small"
            onClick={handleLoadMoreClick}
          />
        </Box>
      )}
    </Box>
  );
};

function AccountDocumentsTableRow({
  document,
}: {
  document: DocumentNodeFragment;
}) {
  switch (document.__typename) {
    case 'AccountDocument':
    case 'SpendAccountDocument':
      return <AccountOrSpendAccountDocumentRow document={document} />;

    case 'InvestAccountDocument':
      return <InvestAccountDocumentRow document={document} />;

    default:
      return null;
  }
}
