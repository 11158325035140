import * as React from 'react';
import { IndexRedirect, Route } from 'react-router';

import { withProps } from '~/hocs';

import { DocumentList } from './components/DocumentList';
import { DocumentsContainer } from './DocumentsContainer';
import { routes } from './routes';
import { SettingsDocumentsPage } from './SettingsDocumentsPage';

export const SettingsDocumentsRoute = () => (
  <>
    <Route path="documents" component={SettingsDocumentsPage} />
    <Route path="documents-beta" component={DocumentsContainer}>
      {routes.map(({ path, documentType, label: title }) => (
        <Route
          key={path}
          path={path}
          component={withProps({ documentType, title })(DocumentList)}
        />
      ))}
      <IndexRedirect to={`/d/settings/documents-beta/${routes[0].path}`} />
    </Route>
  </>
);
