import keys from 'lodash-es/keys';
import pick from 'lodash-es/pick';
import { LOCATION_CHANGE } from 'react-router-redux';

import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import {
  MARGIN_CALL_INFORMATION_STEPS,
  RESOLVE_CALL_STEPS as STEPS,
} from '~/static-constants';

import { FlowState } from '../newFlowsReducer.types';
import { createStepReducer } from '../utils';

export type ResolveCallFlowState = FlowState<
  typeof STEPS,
  {
    basePath: string;
    borrowAccountId: string;
    lastMarginCallInformationFlowStep: string | null | undefined;
    portfolioSliceId: string;
  }
>;

export const resolveCallFlowInitialState: ResolveCallFlowState = {
  basePath: '',
  borrowAccountId: '',
  lastMarginCallInformationFlowStep: '',
  portfolioSliceId: '',
  step: STEPS.RESOLVE_MARGIN_CALL,
  stepTitle: '',
};

const stepReducer = createStepReducer(resolveCallFlowInitialState);

export function resolveCall(
  state: ResolveCallFlowState = resolveCallFlowInitialState,
  action: any,
): ResolveCallFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_RESOLVE_CALL_FLOW:
      return {
        ...resolveCallFlowInitialState,
        ...pick(action.payload.initialState, keys(resolveCallFlowInitialState)),
        basePath: action.payload.basePath,
        step: STEPS.RESOLVE_MARGIN_CALL,
      };
    case ACTIONS.SELECTED_CREATE_TRANSFER_RESOLUTION_OPTION:
      return {
        ...state,
        lastMarginCallInformationFlowStep:
          MARGIN_CALL_INFORMATION_STEPS.RESOLVE_CALL_OPTIONS,
      };
    case ACTIONS.SELECTED_SELL_POSITION_RESOLUTION_OPTION:
      return {
        ...state,
        ...pick(action.payload, keys(resolveCallFlowInitialState)),
        lastMarginCallInformationFlowStep:
          MARGIN_CALL_INFORMATION_STEPS.POSITION_DETAILS,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        step: stepReducer(state, action),
      };
    default:
      return state;
  }
}
