import { LOCATION_CHANGE } from 'react-router-redux';

import { RoutingState } from './routingReducer.types';
import { buildAppHistory } from './routingReducer.utils';

export const initialState: RoutingState = {
  locationBeforeTransitions: null,
  appHistory: [],
};

export const routingReducer = (
  state: RoutingState = initialState,
  action: any,
): RoutingState => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const appHistory = buildAppHistory(state, action);
      return Object.assign({}, state, {
        appHistory,
      });
    }
    default:
      return state;
  }
};
