import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { TransfersActivityPage } from './TransfersActivityPage';

export const TransfersActivityRoute = () => {
  return (
    <Route
      path="activity"
      component={createRouteComponent(TransfersActivityPage)}
    />
  );
};
