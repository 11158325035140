import { arrayMove } from '@dnd-kit/sortable';
import { cloneDeep } from 'lodash-es';
import { SagaIterator } from 'redux-saga';

import { PieEditModelDocument, PieEditModelQueryResult } from '~/graphql/hooks';
import {
  FlattenedItem,
  TreeItem,
  TreeItems,
} from '~/pages/dashboard/wizards/pie-editor/PieEditor.types';

import {
  addItem,
  buildTree,
  changePercentage,
  equalizePie,
  findItemDeep,
  findParentItemDeep,
  flattenTree,
  getRemoteEditModel,
  mapRemotePieToTreeItems,
  mapRemotePortfolioToTreeItems,
  mapTreeItemsToRemotePieString,
  removeEmptyPies,
  removeItem,
} from '~/pages/dashboard/wizards/pie-editor/PieEditor.utils';

import { preparePieTreeForUpdate } from '~/pie-trees';

import {
  PIE_EDITOR_ACTIONS,
  PieEditorAddSlicesComplete,
  PieEditorMoveSlice,
} from '../actions/PieEditorAction';
import { PieEditorState } from '../reducers/pieEditorReducer';

import { findCircularReferencePies } from '../selectors/pieEditorSelectors';

import { apolloQuerySaga } from './apolloQuerySaga';
import { call, put, select, takeEvery } from './effects';

export function* pieEditorSaga(): SagaIterator<void> {
  yield takeEvery(
    // @ts-ignore - Not sure why this is erroring
    PIE_EDITOR_ACTIONS.PIE_EDITOR_ADD_SLICES_COMPLETE,
    handleAddSlicesComplete,
  );

  yield takeEvery(
    // @ts-ignore - Not sure know why this is erroring
    PIE_EDITOR_ACTIONS.PIE_EDITOR_MOVE_SLICE,
    handleMoveSliceComplete,
  );
}

function* handleAddSlicesComplete(action: {
  payload: PieEditorAddSlicesComplete['payload'];
}): SagaIterator<void> {
  const { addSlices, history, historyIndex }: PieEditorState = yield select(
    (state) => state.pieEditor,
  );
  let tree = history[historyIndex].tree;
  const circRefNames: string[] = [];
  const alreadyExistNames: string[] = [];
  let parentId;

  if (action.payload.addToSelfPie) {
    const newItem: TreeItem = {
      id: crypto.randomUUID(),
      percentage: 1,
      meta: {
        type: 'sortable',
        name: 'Slices added to portfolio',
        isSystemPie: false,
      },
      children: [
        {
          id: crypto.randomUUID(),
          meta: {
            type: 'add',
            name: 'Slices added to portfolio',
            isSystemPie: false,
          },
          children: [],
          allowsChildren: false,
          draggable: false,
          removeable: false,
        },
      ],
      allowsChildren: true,
      removeable: true,
    };
    // Add empty pie to the root pie, then set the parent id to this added pie for the new slices
    tree = addItem(cloneDeep(tree), newItem, tree[0].children[0].id);
    parentId = newItem.children[0].id;
  } else {
    parentId =
      action.payload.parentId ?? addSlices?.parentId ?? tree[0].children[0].id;
  }

  const parent = parentId ? findParentItemDeep(tree, parentId) : null;
  const pieName = addSlices?.meta.pie ?? parent?.meta.name ?? 'pie';

  if (parentId) {
    for (const slice of action.payload.slices) {
      let children: TreeItems | undefined;
      let allowsChildren: boolean | undefined;
      let isSystemPie: boolean | undefined;

      if (
        parent &&
        parent.children.some(
          (item) =>
            item.meta.id &&
            slice.id &&
            (item.meta.id === slice.id ||
              item.meta.securityInfo?.symbol === slice.securityInfo?.symbol || // due to encoding, sometimes id matching leads to false negatives
              item.meta.securityInfo?.symbol === slice.symbolOrName), // symbols are not shared between stocks/etfs so we can check uniqueness with the symbol
        )
      ) {
        alreadyExistNames.push(slice.symbolOrName);
        continue;
      }
      if (slice.isPie) {
        // If it's a pie, get the edit model so we can add the children
        const { data }: PieEditModelQueryResult = yield call(apolloQuerySaga, {
          query: PieEditModelDocument,
          variables: {
            pieEditorRouteParam: slice.id,
          },
        });
        if (data && slice.id) {
          const { kind, model } = getRemoteEditModel(data.node);
          const sliceable = (
            kind === 'PIE'
              ? mapRemotePieToTreeItems(model, slice.id)
              : mapRemotePortfolioToTreeItems(model)
          )[0];
          children = sliceable.children;
          allowsChildren = sliceable.allowsChildren;
          isSystemPie = sliceable.meta.isSystemPie;
        }
      }
      const isNewPie = slice.id === undefined;
      const childrenWithAdd = children ? children : [];
      const newItem: TreeItem = {
        id: crypto.randomUUID(),
        percentage: 1,
        meta: {
          type: 'sortable',
          id: isNewPie ? undefined : slice.id,
          name: isNewPie ? 'New pie' : slice.symbolOrName,
          isSystemPie: isSystemPie ?? false,
          securityInfo: slice.securityInfo ?? undefined,
        },
        children: !isNewPie
          ? childrenWithAdd
          : [
              {
                id: crypto.randomUUID(),
                meta: {
                  type: 'add',
                  name: 'New pie',
                  isSystemPie: false,
                },
                children: [],
                allowsChildren: false,
                draggable: false,
                removeable: false,
              },
            ],
        allowsChildren: allowsChildren || isNewPie,
        removeable: true,
      };
      const tempTree = addItem(cloneDeep(tree), newItem, parentId);
      const circRefs = findCircularReferencePies(tempTree);
      if (circRefs.length) {
        // don't add slice, will show toast instead
        circRefNames.push(slice.symbolOrName);
      } else {
        tree = tempTree;
      }
    }

    const allErrorNames = [...circRefNames, ...alreadyExistNames];

    if (allErrorNames.length > 0) {
      const reasons: string[] = [];
      if (circRefNames.length > 0) {
        reasons.push('circular references');
      }
      if (alreadyExistNames.length > 0) {
        reasons.push('the slice already existing in that pie');
      }

      yield put({
        type: 'ADD_TOAST',
        payload: {
          content: `Unable to add slice${allErrorNames.length > 1 ? 's' : ''} ${allErrorNames.join(', ')} to ${pieName} due to ${reasons.join(' and ')}.`,
          duration: 'long',
          kind: 'alert',
        },
      });
    }
    if (action.payload.slices.length !== allErrorNames.length) {
      const sliceNames = action.payload.slices.map(
        (slice) => slice.symbolOrName,
      );
      let finalSeparator = '';
      if (sliceNames.length > 2) {
        finalSeparator = ', & ';
      } else if (sliceNames.length === 2) {
        finalSeparator = ' & ';
      }
      yield put({
        type: 'PIE_EDITOR_UPDATE_HISTORY_TREE',
        payload: {
          tree,
          meta: {
            slice:
              sliceNames.slice(0, -1).join(',') +
              finalSeparator +
              sliceNames.slice(-1),
            pie: pieName ?? 'pie',
          },
          type: 'add',
        },
      });
    }
  }

  // Reset add slices
  yield put({
    type: 'PIE_EDITOR_ADD_SLICES_START',
    payload: null,
  });
}

function* handleMoveSliceComplete(action: {
  payload: PieEditorMoveSlice['payload'];
}): SagaIterator<void> {
  const { items, activeId, overId, depth, parentId } = action.payload;
  const { kind }: PieEditorState = yield select((state) => state.pieEditor);

  const clonedItems = cloneDeep(items);
  const clonedFlattenedItems: FlattenedItem[] = cloneDeep(flattenTree(items));

  let overIndex = clonedFlattenedItems.findIndex(({ id }) => id === overId);
  const overItem = clonedFlattenedItems[overIndex];
  const activeIndex = clonedFlattenedItems.findIndex(
    ({ id }) => id === activeId,
  );
  const activeTreeItem = clonedFlattenedItems[activeIndex];

  if (overItem.meta.type === 'add') {
    overIndex++;
  }

  clonedFlattenedItems[activeIndex] = {
    ...activeTreeItem,
    depth,
    parentId,
  };

  const sortedItems = arrayMove(clonedFlattenedItems, activeIndex, overIndex);
  let newItems = buildTree(sortedItems);
  const movedItem = findItemDeep(newItems, activeId);
  const movedItemName =
    movedItem?.meta.name ?? movedItem?.meta.securityInfo?.symbol ?? 'slice';
  let isMerged;

  // Move Slices prep
  const sourceParentBeforeUpdates = findParentItemDeep(clonedItems, activeId);
  // We want to remove empty pies from the whole pie tree so we don't call move slices with empty pies
  // The pie tree will be updated to be as the user intended at the end of saving the pie after all the moves
  let destinationParent = findParentItemDeep(
    removeEmptyPies(newItems),
    activeId,
  );

  const duplicates =
    destinationParent?.children.filter(
      (child) => child.meta.id === movedItem?.meta.id,
    ) ?? [];
  if (duplicates.length > 1) {
    // We have a duplicate to merge - remove the slice that wasn't moved, and add its percentage to the moved slice
    const duplicate = duplicates.find((child) => child.id !== activeId);
    if (duplicate) {
      const newPercentage =
        (movedItem?.percentage ?? 0) + (duplicate.percentage ?? 0);
      const mergedPercentageTree = changePercentage(
        newItems,
        activeId,
        newPercentage,
      );
      newItems = removeItem(mergedPercentageTree, duplicate.id);
      isMerged = true;
      destinationParent = findParentItemDeep(newItems, activeId);
    }
  }

  const destinationParentName = destinationParent?.meta.name ?? 'new pie';

  if (destinationParent?.meta.isSystemPie) {
    yield put({
      type: 'ADD_TOAST',
      payload: {
        content: `Unable to move ${movedItemName} to ${destinationParentName} because it is a system pie.`,
        duration: 'long',
        kind: 'alert',
      },
    });
    return;
  }

  const sourceParentAfterUpdates = findItemDeep(
    // We want to remove empty pies from the whole pie tree so we don't call move slices with empty pies
    // The pie tree will be updated to be as the user intended at the end of saving the pie after all the moves
    removeEmptyPies(newItems),
    sourceParentBeforeUpdates?.id ?? '',
  );

  const isReorder =
    destinationParent &&
    sourceParentBeforeUpdates &&
    destinationParent.id === sourceParentBeforeUpdates.id;

  const moveSlicesInput =
    kind === 'PORTFOLIO' && // only move slices for portfolios
    sourceParentAfterUpdates &&
    destinationParent &&
    sourceParentBeforeUpdates &&
    !isReorder // Don't need to move slices if just changing the order
      ? {
          destinationPiePortfolioSliceId: String(destinationParent.id),
          destinationPieSerialized: mapTreeItemsToRemotePieString(
            // Equalize pie just for the sake of moving slices - pie tree will be updated correctly at end of save
            equalizePie([destinationParent], destinationParent.id),
            preparePieTreeForUpdate,
          ),
          moveSliceIds: [String(activeId)],
          sourcePieBeforeUpdatesSerialized: mapTreeItemsToRemotePieString(
            [sourceParentBeforeUpdates],
            preparePieTreeForUpdate,
          ),
          sourcePiePortfolioSliceId: String(sourceParentBeforeUpdates.id),
          sourcePieSerialized: mapTreeItemsToRemotePieString(
            // Equalize pie just for the sake of moving slices - pie tree will be updated correctly at end of save
            equalizePie(
              [sourceParentAfterUpdates],
              sourceParentAfterUpdates.id,
            ),
            preparePieTreeForUpdate,
          ),
        }
      : null;

  yield put({
    type: 'PIE_EDITOR_UPDATE_HISTORY_TREE',
    payload: {
      tree: newItems,
      type: 'move',
      meta: {
        slice: movedItemName,
        oldPie: sourceParentBeforeUpdates?.meta.name ?? 'previous pie',
        newPie: destinationParentName,
        moveSlicesInput,
        isReorder,
        isMerged,
      },
    },
  });
}
