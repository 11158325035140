import { Button, HXS, PL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { UNSAFE_connectRedux } from '~/hocs';
import { logout } from '~/redux/actions';
import { Container } from '~/toolbox/container';

type Props = {
  dispatch: (...args: Array<any>) => any;
};

class RenderFailurePageComponent extends React.Component<Props> {
  render() {
    return (
      <Container centered padded text>
        {/* @ts-expect-error - TS2322 - Type '{ alt: string; name: "warningTriangle"; mt: number; }' is not assignable to type 'IntrinsicAttributes & IllustrationProps & { children?: ReactNode; }'. */}
        <Illustration alt="" name="warningTriangle" mt={32} />
        <HXS
          content="Something went wrong"
          mt={32}
          color="foregroundNeutralMain"
        />
        <PL
          content="We're working on it. If the problem persists, please contact support."
          mt={16}
          color="foregroundNeutralSecondary"
        />
        <Button
          kind="primary"
          label="Logout"
          mt={64}
          onClick={this.handleClick}
          size="large"
        />
      </Container>
    );
  }

  handleClick = (): void => {
    this.props.dispatch(logout());
  };
}
const enhancer = UNSAFE_connectRedux();

export const RenderFailurePage = enhancer(RenderFailurePageComponent) as any;
