import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { DocumentTypeFilterEnumType } from '~/graphql/types';
import { Checkbox } from '~/toolbox/checkbox';
import { Spinner } from '~/toolbox/spinner';

const {
  AccountDocument,
  InvestmentsRelated,
  Statement,
  TaxForm,
  TradeConfirmation,
} = DocumentTypeFilterEnumType;

type DocumentsFiltersSectionProps = {
  includeFilter: DocumentTypeFilterEnumType[];
  loading: boolean;
  onChangeFilter: (filterKey: DocumentTypeFilterEnumType) => void;
  showInvestmentsRelatedFilter?: boolean;
};

export const AccountDocumentsFiltersSection = ({
  includeFilter,
  loading,
  onChangeFilter,
  showInvestmentsRelatedFilter = false,
}: DocumentsFiltersSectionProps) => {
  const isFilteredForKey = (filterKey: DocumentTypeFilterEnumType): boolean =>
    includeFilter?.includes?.(filterKey) ?? false;

  return (
    <Flex alignItems="center" justifyContent="flex-end" mb={8} gap={32}>
      {loading && (
        <Spinner
          centered={false}
          radius={18}
          thickness={3}
          display="flex"
          style={{ alignItems: 'center' }}
        />
      )}
      {showInvestmentsRelatedFilter && (
        <Checkbox
          id="investments-related-document-filter"
          label="Investments related"
          onChange={() => onChangeFilter(InvestmentsRelated)}
          value={isFilteredForKey(InvestmentsRelated)}
        />
      )}
      <Checkbox
        id="statements-document-filter"
        label="Statements"
        onChange={() => onChangeFilter(Statement)}
        value={isFilteredForKey(Statement)}
      />
      <Checkbox
        id="tax-forms-document-filter"
        label="Tax forms"
        onChange={() => onChangeFilter(TaxForm)}
        value={isFilteredForKey(TaxForm)}
      />
      <Checkbox
        id="trade-confirmations-document-filter"
        label="Trade confirmations"
        onChange={() => onChangeFilter(TradeConfirmation)}
        value={isFilteredForKey(TradeConfirmation)}
      />
      <Checkbox
        id="accounts-document-filter"
        label="Account documents"
        onChange={() => onChangeFilter(AccountDocument)}
        value={isFilteredForKey(AccountDocument)}
      />
    </Flex>
  );
};
