import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { CreditCardClosedPage } from './CreditCardClosedPage';

export const CreditCardClosedRoute = () => {
  return (
    <Route
      path="closed"
      component={createRouteComponent(CreditCardClosedPage)}
    />
  );
};
