import keys from 'lodash-es/keys';
import pick from 'lodash-es/pick';
import { LOCATION_CHANGE } from 'react-router-redux';

import {
  // @ts-expect-error - TS2305 - Module '"~/graphql/types"' has no exported member 'CreateTransferInstanceErrorEnumValues'.
  CreateTransferInstanceErrorEnumValues,
  Maybe,
} from '~/graphql/types';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import { INITIAL_FUNDING_FLOW_STEPS as STEPS } from '~/static-constants';

import { FlowState } from '../newFlowsReducer.types';
import { createStepReducer } from '../utils';

export type InitialFundingState = FlowState<
  typeof STEPS,
  {
    accountId: string | null | undefined;
    achRelationshipId: string | null | undefined;
    basePath: string;
    callbackUrl: string;
    createTransferInstancePayload:
      | (
          | {
              didSucceed: true;
              transferInstanceId: string;
            }
          | null
          | undefined
        )
      | (
          | {
              didSucceed: false;
              error: CreateTransferInstanceErrorEnumValues;
            }
          | null
          | undefined
        );
    idempotencyKey: Maybe<string>;
    fundingType: ('achLink' | 'deposits') | null | undefined;
  }
>;

export const initialFundingFlowInitialState: InitialFundingState = {
  accountId: null,
  achRelationshipId: null,
  callbackUrl: '',
  basePath: '',
  createTransferInstancePayload: null,
  idempotencyKey: null,
  fundingType: null,
  step: STEPS.BEGIN_INITIAL_FUNDING,
  stepTitle: 'Fund your account',
};

const stepReducer = createStepReducer(initialFundingFlowInitialState);
export function initialFunding(
  state: InitialFundingState = initialFundingFlowInitialState,
  action: any,
): InitialFundingState {
  switch (action.type) {
    case ACTIONS.BEGIN_INITIAL_FUNDING_FLOW:
      return {
        ...initialFundingFlowInitialState,
        ...pick(action.payload, keys(initialFundingFlowInitialState)),
        step: initialFundingFlowInitialState.step,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        step: stepReducer(state, action),
      };
    case ACTIONS.FINISHED_INITIAL_BANK_CONNECTION:
      return {
        ...state,
        achRelationshipId: action.payload.achRelationshipId,
      };
    case ACTIONS.INITIAL_DEPOSIT_MUTATION_COMPLETED:
      return {
        ...state,
        createTransferInstancePayload: {
          didSucceed: true,
          transferInstanceId: action.payload,
        },
      };
    case ACTIONS.STORE_CALLBACK_URL:
      return {
        ...state,
        callbackUrl: action.payload,
      };
    case ACTIONS.SUBMITTED_INITIAL_DEPOSIT_FORM:
      return {
        ...state,
        achRelationshipId: action.payload.achRelationshipId,
      };
    case ACTIONS.INITIAL_DEPOSIT_MUTATION_FAILED:
      return {
        ...state,
        createTransferInstancePayload: {
          didSucceed: false,
          error: action.payload,
        },
      };
    case ACTIONS.SET_IDEMPOTENCY_KEY:
      return {
        ...state,
        idempotencyKey: action.payload,
      };
    case ACTIONS.END_FLOW:
      if (action.meta.flow === 'connectBank') {
        const fundingType =
          action.payload.step === 'select-plaid' ? 'achLink' : 'deposits';
        return {
          ...state,
          fundingType,
        };
      }
      return state;
    default:
      return state;
  }
}
