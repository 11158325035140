import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import {
  InvestAccountOpeningQuestionType,
  OnboardingIdentityFirstQuestionType,
  OnboardingIdentityFirstQuestion,
} from '~/graphql/types';
import {
  showLoadingSpinner,
  hideLoadingSpinner,
  navigate,
  identityFirstFlowFinished,
} from '~/redux/actions';
import { OnboardingFlowState } from '~/redux/reducers/newFlows/reducers/onboardingReducer';
import { IDENTITY_FIRST_FLOW_STEPS as STEPS } from '~/static-constants';

import type { AppState } from '../../../../../reducers/types';
import {
  createUpdateProfileInput,
  updateUserProfile,
} from '../../../../common/updateUserProfile';
import { showFailureToast } from '../../../../showFailureToastSaga';
import { changeStep } from '../../../utils';
import { mapQuestionToFlowStep, mapSuitabilityToQuestion } from '../mappings';
import { getNextQuestion } from '../utils';

function* handleFinancialSuitabilityNavigation(
  questionType:
    | OnboardingIdentityFirstQuestionType
    | InvestAccountOpeningQuestionType,
  isPrimary: boolean,
) {
  yield call(changeStep, mapQuestionToFlowStep(questionType, isPrimary));
}

export function* finishedFinancialSuitabilityQuestion(
  action: any,
  nextQuestion: OnboardingIdentityFirstQuestionType | null | undefined,
): SagaIterator<void> {
  const isPrimary = yield select(
    (state) => state.newFlows.IDENTITY_FIRST.input.holder !== 'secondary',
  );

  if (!nextQuestion) {
    yield call(finishedFinancialSuitabilitySection, isPrimary);
  } else {
    yield call(handleFinancialSuitabilityNavigation, nextQuestion, isPrimary);
  }
}

export function* finishedFinancialSuitabilitySection(
  isPrimary: boolean,
): SagaIterator<void> {
  const { product }: OnboardingFlowState = yield select(
    (state: AppState) => state.newFlows.onboarding,
  );
  const productIdentifier = product?.productIdentifier;

  if (isPrimary) {
    try {
      yield put(showLoadingSpinner());

      const localProfileInput = yield select(
        (state) => state.newFlows.IDENTITY_FIRST.input,
      );
      const updateProfileInput = createUpdateProfileInput(localProfileInput);

      const { result } = yield call(
        updateUserProfile,
        updateProfileInput,
        false,
        productIdentifier,
      );

      if (result?.didSucceed) {
        yield put(identityFirstFlowFinished(localProfileInput));

        switch (productIdentifier) {
          case 'SPEND_SAVINGS':
            yield put(navigate({ to: '/onboarding/savings-onboarding' }));
            break;
          case 'BORROW_PERSONAL_LOANS':
            yield put(
              navigate({ to: '/onboarding/personal-loans-onboarding' }),
            );
            break;
          default:
            yield put(navigate({ to: '/onboarding/setup-invest-account' }));
            break;
        }
      } else {
        yield call(showFailureToast);
      }
    } catch {
      yield call(showFailureToast);
    } finally {
      yield put(hideLoadingSpinner());
    }
  } else {
    yield call(changeStep, STEPS.COLLECT_INVESTMENT_EXPERIENCE);
  }
}

export function* handleInvestAndSuitabilityNavigation(
  action: any,
  allInvestAndSuitabilityQuestions: OnboardingIdentityFirstQuestion[],
): SagaIterator<void> {
  const suitabilityField: string = action.payload.field;
  const currentQuestion = mapSuitabilityToQuestion(suitabilityField);
  const nextQuestion = getNextQuestion(
    currentQuestion,
    allInvestAndSuitabilityQuestions,
  );

  yield call(finishedFinancialSuitabilityQuestion, action, nextQuestion);
}
