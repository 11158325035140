import { Maybe } from '@m1/liquid-react';
import * as React from 'react';
import {
  EnterHook,
  InjectedRouteProps,
  NamedDescriptor,
  formatPattern,
} from 'react-router';
import { Store } from 'redux';

import { AppState } from '~/redux';

import { RouteHandlers } from '../configure-router';

/**
 * @deprecated Do not use, will be removed with React Router upgrade.
 */
export function createRouteComponent<T extends React.ComponentType<any>>(
  PageComponent: T,
  mapProps?: (routeProps: InjectedRouteProps) => React.ComponentProps<T>,
): React.ComponentType<any> {
  return class extends React.Component<InjectedRouteProps> {
    render() {
      const props = mapProps ? mapProps(this.props) : {};
      // @ts-expect-error - TS2322 - Type '{ children: FC<any> & ReactNode; }' is not assignable to type 'LibraryManagedAttributes<T, any>'. | TS2786 - 'PageComponent' cannot be used as a JSX component.
      return <PageComponent children={this.props.children} {...props} />;
    }
  };
}

/**
 * @deprecated Do not use, will be removed with React Router upgrade.
 */
export function createRouteHandler(store: Store<AppState>): RouteHandlers {
  return (...handlers) => {
    if (handlers.length === 0) {
      return undefined;
    }

    return (...args) => {
      // The react-router@v3 docs and our type definition both say `EnterHook`
      // can only have 3 params and the first two are `nextState` and `replace`.
      // But we've had this check for args.length === 4 since at least 2019.
      // With react-router going away soon, going to leave it for now.
      // @ts-ignore Legacy react router.
      const [nextRouterState, replace]: Parameters<EnterHook> =
        // @ts-ignore Legacy react router. Same as above.
        args.length === 4 ? [args[1], args[2]] : args;

      let result;
      const state = store.getState();

      for (const handler of handlers) {
        result = handler(state, nextRouterState);

        if (result) {
          break;
        }
      }

      if (result) {
        // Our legacy type def for `LocationDescriptor` looks incorrect.
        // We expect it to always been an object, but in the docs
        // `type LocationDescriptor = LocationDescriptorObject | Path`
        // and Path is effectively a string. Leaving this for now too.
        // @ts-ignore Legacy react router.
        replace(result);
      }
    };
  };
}

/**
 * @deprecated Do not use, will be removed with React Router upgrade.
 */
export function createLocationDescriptor(
  route: Maybe<NamedDescriptor | string>,
  routeParams: Maybe<Record<string, any>>,
  locationParams: Maybe<Record<string, any>>,
): {
  pathname: string;
} {
  if (!route) {
    throw new Error('Cannot create location descriptor without a route.');
  }

  if (typeof route === 'string') {
    return {
      pathname: route,
      ...locationParams,
    };
  }

  return {
    // formatPattern will throw if routeParams is missing a param in pattern.
    pathname: formatPattern(route.pattern, routeParams || {}),
    ...locationParams,
  };
}
