import * as React from 'react';
import { Route } from 'react-router';

import { PortfolioDividendsRoute } from '../portfolio/PortfolioDividendsPageRoute';

import { HoldingsPage } from './HoldingsPage';
import { PositionDetailsRoute } from './position-details';

export const HoldingRoute = () => {
  return (
    <Route path="holdings" component={HoldingsPage}>
      <>
        {PositionDetailsRoute()}
        {PortfolioDividendsRoute()}
      </>
    </Route>
  );
};
