import {
  useScroll as reactRouterScroll,
  InjectedRouteWithScrollProps,
  UseScrollReturn,
} from 'react-router-scroll';

export function createScrollMiddleware(): UseScrollReturn {
  const scroller = (
    scrollBehavior: ScrollBehavior,
    { routes }: InjectedRouteWithScrollProps,
  ): boolean => {
    if (routes.some((route) => route.ignoreScrollBehavior)) {
      return false;
    }

    return true;
  };

  // Using an import alias so the React Hooks ESLint plugin won't complain about
  // how useScroll isn't a valid hook:
  return reactRouterScroll(scroller);
}
