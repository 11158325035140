import {
  Box,
  Button,
  Flex,
  HXS,
  HXXS,
  PL,
  PM,
  PXL,
  Card,
} from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import { FundingSourceNodeFragment } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useFundingSources } from '~/hooks/useFundingSources';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { CONNECT_BANK_FLOW_STEPS } from '~/static-constants';
import { Container } from '~/toolbox/container';
import { Spinner } from '~/toolbox/spinner';

import { InvestAvailableFundingSource } from './components/InvestAvailableFundingSource';
import { SavingsAvailableFundingSource } from './components/SavingsAvailableFundingSource';
import { StandardAvailableFundingSource } from './components/StandardAvailableFundingSource';

const SourceCard = ({
  fundingSource,
}: {
  fundingSource: FundingSourceNodeFragment;
}) => {
  const {
    query: { type: connectionType },
  } = useLocation();

  switch (connectionType) {
    case 'savings':
      return <SavingsAvailableFundingSource fundingSource={fundingSource} />;
    case 'invest':
      return <InvestAvailableFundingSource fundingSource={fundingSource} />;
    case 'fs_standard':
    default:
      return <StandardAvailableFundingSource fundingSource={fundingSource} />;
  }
};

export const AvailableFundingSourcesPage = () => {
  const {
    fundingSources,
    activeFundingSources,
    pendingFundingSources,
    loading,
  } = useFundingSources();

  const {
    query: { type: connectionType },
  } = useLocation();
  const analytics = useAnalytics();
  const navigate = useNavigate();

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!fundingSources) {
    return <GenericSystemError />;
  }

  const previousRouteName =
    connectionType === 'savings' ? '/d/spend/savings/transactions' : null;

  return (
    <Container height="100vh" marginTop={54}>
      <Box mx="auto" maxWidth={584}>
        <Flex marginTop={24} marginBottom={16}>
          {connectionType === 'savings' ? (
            <HXS content="To continue, pick one bank account to service all of your M1 accounts." />
          ) : (
            <HXS content="Connect a bank account." />
          )}
        </Flex>
        <Flex marginTop={32} flexDirection="column">
          {activeFundingSources.length > 0 && (
            <Flex flexDirection="column">
              <PXL
                marginBottom={12}
                content="Choose from your validated accounts below, or add a new bank account."
              />
              {connectionType === 'savings' && (
                <Card
                  width={587}
                  p={12}
                  my={24}
                  backgroundColor="backgroundInfoSubtle"
                  borderColor="borderInfo"
                >
                  <PL content="You may need to recreate your recurring transfers, including automatic payments to your M1 Credit Card." />
                </Card>
              )}
              {activeFundingSources.map((fundingSource, i) => {
                return <SourceCard key={i} fundingSource={fundingSource} />;
              })}
            </Flex>
          )}
          {pendingFundingSources.length > 0 && (
            <Flex flexDirection="column">
              <HXXS content="Pending connections" />
              <PM
                marginBottom={12}
                content="If you’re manually connecting your bank, you can verify deposits below."
              />
              {pendingFundingSources.map((fundingSource, i) => {
                return <SourceCard key={i} fundingSource={fundingSource} />;
              })}
            </Flex>
          )}
        </Flex>
        <Box width={240} mb={32}>
          <Button
            kind="secondary"
            size="medium"
            fullWidth
            label="Add a new bank account"
            onClick={() => {
              analytics.recordEvent('m1_hysa_add_new_bank_account');
              navigate({
                to: '/d/c/connect-bank',
                query: {
                  initialStep: CONNECT_BANK_FLOW_STEPS.SELECT_PLAID,
                  connectionType,
                  previousRouteName,
                },
              });
            }}
            mt={32}
          />
        </Box>
      </Box>
    </Container>
  );
};
