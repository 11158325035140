import * as React from 'react';
import { Route } from 'react-router';

import { Cover } from '~/components/cover';

import { ActivateCreditCardCover } from './activate-credit-card';
import { ActivityDetailsRoute } from './activity-details';
import { AddSlicesRoute } from './add-slices';
import { BorrowUseCasesRoute } from './borrow-use-cases';
import { ConfirmIdentityRoute } from './confirm-identity';
import { ConnectBankRoute } from './connect-bank';
import { CreateSmartTransferRoute } from './create-smart-transfer';
import { CreateTransferRoute } from './create-transfer';
import { CreditCardApplicationRoute } from './credit-card-application';
import { EditSmartTransferRoute } from './edit-smart-transfer';
import { EditTransferScheduleRoute } from './edit-transfer-schedule';
import { OpenAccountRoute } from './open-account';
import { ReacceptLegalTermsRoute } from './reaccept-legal-terms';
import { RebalanceRoute } from './rebalance';
import { ReportTransactionIssueRoute } from './ReportTransactionIssue';
import { ResendVerificationEmailRoute } from './resend-verification-email';
import { ResolveCallRoute } from './resolve-call';
import { CreditCardRewardsPayoutRoute } from './rewards-payout-destination';
import { SelectPaymentAccountRoute } from './select-payment-account';
import { SetOrderRoute } from './set-order';
import { SmartTransferContraParticipantEntryInstancesRoute } from './smart-transfer-contra-participant-entry-transfer-instances';
import { SmartTransferDetailsRoute } from './smart-transfer-details';
import { RewardsSingularProgram } from './spend-credit-rewards-singular-program';
import { RewardsViewAllProgramsRoute } from './spend-credit-rewards-view-all-programs';
import { TransferAcatInstanceDetailsRoute } from './transfer-acat-instance-details';
import { TransferInstanceDetailsRoute } from './transfer-instance-details';
import { TransferRuleDetailsRoute } from './transfer-rule-details';
import { WireTransferRoute } from './wire-transfer';

export const CoversRoute = () => {
  return (
    <>
      <Route path="c" component={Cover} ignoreScrollBehavior>
        {ActivateCreditCardCover()}
        {ActivityDetailsRoute()}
        {AddSlicesRoute()}
        {BorrowUseCasesRoute()}
        {ConfirmIdentityRoute()}
        {ConnectBankRoute()}
        {CreateSmartTransferRoute()}
        {CreateTransferRoute()}
        {CreditCardApplicationRoute()}
        {EditSmartTransferRoute()}
        {EditTransferScheduleRoute()}
        {OpenAccountRoute()}
        {ReportTransactionIssueRoute()}
        {ReacceptLegalTermsRoute()}
        {ResendVerificationEmailRoute()}
        {ResolveCallRoute()}
        {RewardsViewAllProgramsRoute()}
        {CreditCardRewardsPayoutRoute()}
        {SmartTransferContraParticipantEntryInstancesRoute()}
        {RewardsSingularProgram()}
        {SelectPaymentAccountRoute()}
        {SetOrderRoute()}
        {SmartTransferDetailsRoute()}
        {TransferAcatInstanceDetailsRoute()}
        {TransferInstanceDetailsRoute()}
        {TransferRuleDetailsRoute()}
        {WireTransferRoute()}
        {RebalanceRoute()}
      </Route>
    </>
  );
};
