import { Flex, styled, Button } from '@m1/liquid-react';
import { MonochromaticIconName } from '@m1/liquid-react/icons';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { useSelector } from '~/redux/hooks';

import {
  HelperDialog,
  HelperDialogProps,
  DialogSequenceConfig,
} from './HelperDialog/HelperDialog';
import {
  SEQUENCED_DIALOGS_WITH_SLICES,
  SEQUENCED_DIALOGS_WITHOUT_SLICES,
} from './PortfolioOrganizer.constants';

type Controls = Array<{
  copy: string;
  dialogProps?: Omit<HelperDialogProps, 'children'>;
  disabled: boolean;
  icon: MonochromaticIconName<'24'>;
  id: string;
  onClick: () => void;
}>;

const StyledButton = styled.button<{
  $isFirst: boolean;
}>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  height: 56px;
  margin-right: ${({ $isFirst }) => ($isFirst ? 16 : 0)}px;
  width: 140px;
`;

type PieControlsProps = {
  dialogSequenceConfig: DialogSequenceConfig;
  onCreatePieClick: () => void;
  onSearchAddClick: () => void;
  pieHasSlices: boolean;
};

export const PieControls = ({
  dialogSequenceConfig,
  onCreatePieClick,
  onSearchAddClick,
  pieHasSlices,
}: PieControlsProps) => {
  const isLessThan1000: boolean = useMediaQuery({
    query: '(max-width: 1000px)',
  });

  const isLessThan1260: boolean = useMediaQuery({
    query: '(max-width: 1260px)',
  });

  const { disabledFeatures, showHelperDialogs } = useSelector((state) => {
    const { disabledFeatures, showHelperDialogs } = state.portfolioOrganizer;

    return {
      disabledFeatures,
      showHelperDialogs,
    };
  });

  const SEQUENCED_DIALOGS = pieHasSlices
    ? SEQUENCED_DIALOGS_WITH_SLICES
    : SEQUENCED_DIALOGS_WITHOUT_SLICES;

  const isCrypto = useSelector<boolean>(
    (state) => state.global.activeAccountIsCrypto,
  );

  const controls: Controls = [
    {
      copy: 'Create pie',
      dialogProps: {
        alignment: 'start',
        content: 'Tap to create a new Pie within this portfolio.',
        direction: 'up',
        height: 200,
        isVisible:
          showHelperDialogs &&
          SEQUENCED_DIALOGS.CREATE_PIE ===
            dialogSequenceConfig.activeDialogPage,
        kind: 'normal',
        pageNumber: SEQUENCED_DIALOGS.CREATE_PIE,
        sequence: dialogSequenceConfig,
        title: 'Create Pie',
      },
      disabled: Boolean(disabledFeatures?.includes('CREATE_PIE')),
      icon: `createPie24`,
      id: 'create-pie',
      onClick: onCreatePieClick,
    },
    {
      copy: 'Add slice',
      dialogProps: {
        alignment: 'start',
        content: isCrypto
          ? 'Tap to view the list of coins you can add to your portfolio or to a custom Pie.'
          : 'Tap to view the list of securities you can add to your portfolio or to a custom Pie.',
        direction: 'up',
        height: 190,
        isVisible:
          showHelperDialogs &&
          SEQUENCED_DIALOGS.SEARCH_AND_ADD ===
            dialogSequenceConfig.activeDialogPage,
        kind: 'normal',
        margin: '16px 0 0 0',
        pageNumber: SEQUENCED_DIALOGS.SEARCH_AND_ADD,
        sequence: dialogSequenceConfig,
        title: 'Add Slice',
      },
      disabled: Boolean(disabledFeatures?.includes('ADD_SLICES')),
      icon: `addSelected24`,
      id: 'search-add',
      onClick: onSearchAddClick,
    },
  ];

  return (
    <Flex pb={isLessThan1260 && !isLessThan1000 ? 16 : 0} gap={8}>
      {controls.map(
        ({ copy, disabled, icon, id, onClick }, i) =>
          !disabled && (
            <React.Fragment key={id}>
              {controls[i].dialogProps ? (
                <HelperDialog {...controls[i].dialogProps}>
                  <Button onClick={onClick} leftIcon={icon}>
                    {copy}
                  </Button>
                </HelperDialog>
              ) : (
                <Button onClick={onClick} leftIcon={icon}>
                  {copy}
                </Button>
              )}
            </React.Fragment>
          ),
      )}
    </Flex>
  );
};
