import { Box } from '@m1/liquid-react';
import moment from 'moment';
import React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useGenerateIdempotencyKeyMutation,
  useGetTransferWizardPreloadQuery,
} from '~/graphql/hooks';
import { useOnMount } from '~/hooks/useOnMount';
import { clearSingleReactHookForm } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { CreateTransferProvider } from './CreateTransferProvider';
import { CreateTransferWizard } from './TransferWizard';

export const CreateTransferPage = () => {
  const dispatch = useDispatch();
  const [defaultDate] = React.useState(moment().toISOString());

  // Ensure that form fields are cleared whenever the cover-wizard is initially mounted
  useOnMount(() => {
    dispatch(clearSingleReactHookForm('createTransferDetails'));
  });

  const data = useGetTransferWizardPreloadQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      date: defaultDate,
    },
  });

  /*
   * We need to generate a unique idempotency key for every transfer.
   * Note that this work is temporary and will be deprecated by
   * april 2024. By then BE will be able to accept any random string as
   * the idempotency key, and will generate a UUID accordingly.
   */
  const [
    generateIdempotencyKey,
    { data: idempotencyKeyData, loading: generateIdempotencyKeyLoading },
  ] = useGenerateIdempotencyKeyMutation({});

  React.useEffect(() => {
    generateIdempotencyKey({
      variables: {
        input: {},
      },
    });
  }, []);

  const { loading, error } = data;

  if (loading || generateIdempotencyKeyLoading) {
    return <Spinner fullScreen />;
  }

  if (error) {
    return <GenericSystemError />;
  }

  const idempotencyKey =
    idempotencyKeyData?.generateIdempotencyKey?.outcome?.idempotencyKey;

  return (
    <Box>
      <CreateTransferProvider
        {...{
          data,
          idempotencyKey,
        }}
      >
        <CreateTransferWizard />
      </CreateTransferProvider>
    </Box>
  );
};
