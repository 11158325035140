import { Button, Text } from '@m1/liquid-react';
import * as React from 'react';

import { Grid } from '~/toolbox/grid';

type Props = {
  onFinishStep: (...args: Array<any>) => any;
};

export class AccountChallengeFailedStep extends React.Component<Props> {
  render() {
    return (
      <Grid constrain>
        <Grid.Row xsTextCenter>
          <Grid.Col xs={12}>
            <div
              style={{
                padding: `16px 0 32px 0`,
              }}
            >
              <Text content="Unable to reset your password. Please try again." />
            </div>
            <Button
              label="Try Again"
              kind="primary"
              onClick={this.handleClick}
              size="large"
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    );
  }

  handleClick = (): void => {
    this.props.onFinishStep();
  };
}
