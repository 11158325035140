import { Button, PM, LS } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import {
  useCreateAccountDocumentLinkMutation,
  useCreateSpendAccountDocumentLinkMutation,
} from '~/graphql/hooks';
import { DocumentNodeFragment } from '~/graphql/types';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';
import { GridTable } from '~/toolbox/grid-table';

export type AccountOrSpendAccountDocumentRowProps = {
  document: DocumentNodeFragment;
};

export const AccountOrSpendAccountDocumentRow = ({
  document,
}: AccountOrSpendAccountDocumentRowProps) => {
  const [createSpendDoc, { loading: spendDocLoading }] =
    useCreateSpendAccountDocumentLinkMutation();
  const [createAccountDoc, { loading: accountDocLoading }] =
    useCreateAccountDocumentLinkMutation();

  const { addToast } = useToast();
  usePortaledSpinner(accountDocLoading || spendDocLoading);

  const handleLinkClick = (): void => {
    const handleErrorToast = (message: string) =>
      addToast({
        kind: 'alert',
        content: message,
        duration: 'short',
      });

    if (document.__typename === 'AccountDocument') {
      createAccountDoc({
        variables: {
          input: {
            accountDocumentId: document.id,
          },
        },
        onCompleted: (data) => {
          window.open(data.createAccountDocumentLink.outcome?.url, '_blank');
        },
        onError: (err) => handleErrorToast(err.message),
      });
    } else {
      createSpendDoc({
        variables: {
          input: {
            spendAccountDocumentId: document.id,
          },
        },
        onCompleted: (data) => {
          window.open(
            data.createSpendAccountDocumentLink.outcome?.url,
            '_blank',
          );
        },
        onError: (err) => handleErrorToast(err.message),
      });
    }
  };

  return (
    <GridTable.Row key={document.id}>
      <GridTable.Cell>{moment(document.date).format('ll')}</GridTable.Cell>
      <GridTable.Cell>
        {document.accountName ? (
          <>
            <PM
              fontWeight={600}
              overflow="hidden"
              textOverflow="ellipsis"
              content={document.accountName.label}
            />
            <LS
              as="small"
              color="foregroundNeutralMain"
              overflow="hidden"
              textOverflow="ellipsis"
              content={document.accountName.subLabel}
            />
          </>
        ) : (
          '--'
        )}
      </GridTable.Cell>
      <GridTable.Cell>
        <PM
          content={
            <Button
              kind="link"
              label={document.name}
              onClick={handleLinkClick}
            />
          }
          fontWeight={600}
        />
      </GridTable.Cell>
    </GridTable.Row>
  );
};
