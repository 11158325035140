import * as React from 'react';

import { StyledLink, StyledLinkIcon } from './Link.styled';
import { LinkProps } from './Link.types';
import { formatInternalLinkProps, getInternalUrl } from './Link.utils';

export const Link = ({
  children,
  color,
  disabled,
  fontWeight = 600,
  icon,
  iconPosition = 'right',
  kind = 'primary',
  style = {},
  target,
  to,
  underline,
  query,
  params,
  font = 'PL',
  state,
  ...rest
}: LinkProps) => {
  const internalUrl = getInternalUrl({ to, params, query });

  const isInternalLink = internalUrl !== null;

  /**
   * React Router does not handle external urls, so we need to handle external vs internal differently.
   * External urls we simply use the plain anchor and pass in the to as an href, internal
   * links we cast to the Link component from react router.
   */
  const specificLinkProps = isInternalLink // 'as' vs 'forwardedAs' TLDR: If casting to another component use 'forwardedAs'. Otherwise use 'as'.
    ? formatInternalLinkProps({ internalUrl, state, query, target })
    : { forwardedAs: 'a', href: to, target: target ?? '_blank' };

  return (
    <StyledLink
      {...rest}
      style={style}
      font={font}
      fontWeight={fontWeight}
      $underline={underline}
      $disabled={disabled}
      $iconPosition={iconPosition}
      $kind={kind}
      rel={isInternalLink ? undefined : 'noopener noreferrer'}
      role="link" // We need to add this here because RR doesn't add an href for internal links.
      {...specificLinkProps}
    >
      {children}
      {icon && <StyledLinkIcon $iconPosition={iconPosition} name={icon} />}
    </StyledLink>
  );
};
