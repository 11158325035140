import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { PieEditor } from './PieEditor';

/**
 * pieEditorRouteParam can be an root portfolio slice id, child portfolio slice id, account id, or a pie id
 * Pie ids are used for editing a pie, all others are used for editing a portfolio
 */
export const PieEditorRoute = () => (
  <Route
    path="pie-editor/:pieEditorRouteParam"
    fallbackRoute="home"
    component={createRouteComponent(PieEditor, (props) => ({
      pieEditorRouteParam: props.params.pieEditorRouteParam,
      rest: props,
    }))}
  />
);
