import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { OpenCustodialAccountDocument } from '~/graphql/hooks';

import {
  NewCustodialAccountBeneficiaryInput,
  OpenCustodialAccountInput,
  OpenCustodialAccountPayload,
  // @ts-expect-error - TS2305 - Module '"~/graphql/types"' has no exported member 'SocialSecurityNumber'.
  SocialSecurityNumber,
} from '~/graphql/types';
import {
  displayNotification,
  hideLoadingSpinner,
  navigate,
  showLoadingSpinner,
} from '~/redux/actions';
import { apolloMutationSaga } from '~/redux/sagas/apolloMutationSaga';
import { NOTIFICATION_LOCATIONS, NOTIFICATION_TYPES } from '~/static-constants';
import { formatDateForAccountMutation } from '~/utils';

import type { AppState } from '../../../../../reducers/types';

export function* handleFinishedCustodialReview(
  signature: string,
): SagaIterator {
  try {
    yield put(showLoadingSpinner());
    const {
      input,
    }: {
      input: NewCustodialAccountBeneficiaryInput & {
        ssn: SocialSecurityNumber;
      };
    } = yield select((state: AppState) => ({
      input: state.newFlows.IDENTITY_FIRST.input.custodial,
    }));

    const { ssn, legalResidence, dateOfBirth, ...rest } = input;

    const mutationInput: OpenCustodialAccountInput = {
      custodialAccountBeneficiary: {
        ...rest,
        dateOfBirth: formatDateForAccountMutation(dateOfBirth),
        legalResidence: {
          ...legalResidence,
          // @ts-expect-error - TS2322 - Type '"USA"' is not assignable to type 'MailingAddressCountryEnum'.
          country: 'USA',
        },
      },
      custodialAccountBeneficiarySsn: ssn,
      signature,
    };

    const { data: result } = yield call(apolloMutationSaga, {
      mutation: OpenCustodialAccountDocument,
      variables: { input: mutationInput },
    });
    const accountId = readAccountId(result);
    yield put({
      type: 'SET_ACTIVE_INVEST_ACCOUNT',
      payload: accountId,
    });
    yield put(
      displayNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        location: NOTIFICATION_LOCATIONS.SNACKBAR,
        message: `Your custodial account for ${input.firstName} ${input.lastName} has been opened`,
      }),
    );
    yield put(navigate({ to: '/d/invest/portfolio' }));
  } catch (e: any) {
    yield put(
      displayNotification({
        type: NOTIFICATION_TYPES.ERROR_ALT,
        location: NOTIFICATION_LOCATIONS.REVIEW_SETUP_ACCOUNT,
        message: e.message,
      }),
    );
  } finally {
    yield put(hideLoadingSpinner());
  }
}

type OpenAccountMutationResult = {
  openCustodialAccount: OpenCustodialAccountPayload | null | undefined;
};

function readAccountId(
  result: OpenAccountMutationResult,
): string | null | undefined {
  return result.openCustodialAccount?.outcome?.account?.id;
}
