import * as React from 'react';
import { Route } from 'react-router';

import { DocumentManagementPage } from './DocumentManagementPage';

export const DocumentUploadRoute = () => {
  return (
    <Route
      path="document-upload(/:associatedService)(/:businessPurpose)"
      component={DocumentManagementPage}
      cover
    />
  );
};
