import { Box, PL, OneColumnConstrained, styled, theme } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';

import { useNavigate } from '~/hooks/useNavigate';

import { StyledHeader } from './StyledHeader';

const StyledIllustration = styled(Illustration)`
  height: 234px;
  width: 234px;

  @media screen and (min-width: ${theme.breakpoints.SMALL}) {
    height: 343px;
    width: 343px;
  }
`;

export const ManualReview = () => {
  const navigate = useNavigate();

  return (
    <OneColumnConstrained>
      <Box display="flex" justifyContent="center" mt={48}>
        <StyledIllustration name="loanFinancialId" />
      </Box>
      <StyledHeader content="We may need more information from you." mt={24} />
      <PL
        content="We’re reviewing your application. If we need more information, we’ll call or email you. Once the review is complete, we’ll email you a link to accept your loan."
        my={8}
      />
      <ResponsiveButton
        mt={36}
        kind="primary"
        label="Return Home"
        onClick={() => {
          navigate({ to: '/d/home' });
        }}
        size="large"
      />
    </OneColumnConstrained>
  );
};
