import * as React from 'react';
import { IndexRedirect, Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { SavingsTransactionsRoute } from './landing-page/index';
import { SavingsPage } from './SavingsPage';

export const SavingsRoute = () => {
  return (
    <Route path="savings" component={createRouteComponent(SavingsPage)}>
      {SavingsTransactionsRoute()}
      <IndexRedirect to="/d/save/savings/transactions" />
    </Route>
  );
};
