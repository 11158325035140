/* eslint-disable react/sort-comp */

/* eslint-disable no-return-assign */

import { useTheme } from '@m1/liquid-react';
import * as React from 'react';
import Select from 'react-select';

import { PossibleOptions, SelectProps } from './Dropdown.types';
import { DropdownIndicator } from './DropdownIndicator';
import { GroupHeading } from './GroupHeading';
import { Menu } from './Menu';
import { Option } from './Option';
import { Placeholder } from './Placeholder';
import { SelectContainer } from './SelectContainer';
import { SingleValue } from './SingleValue';

/**
 * Important! Please Read!
 *
 * If you pass a custom component in via the `components`, do _not_ extend the
 * exported component from react-select with a styled() wrapper. See example below.
 *
 * @example (Bad)
 *   import { components } from 'react-select';
 *
 *   // Do not do this!
 *   const StyledPlaceholder = styled(components.Placeholder)`
 *     padding: 8px;
 *   `;
 *
 *   export const SomeComponent = () => (
 *     <StyledPlaceholder>
 *       // ...
 *     </StyledPlaceholder>
 *   );
 *
 * Instead, use styled() for the contents of the component and wrap it with the
 * export from `react-select`. See example below.
 *
 * @example (Good)
 *  import { components } from 'react-select';
 *
 *   // Do this!
 *   const StyledContainer = styled.div`
 *     padding: 8px;
 *   `;
 *
 *   export const SomeComponent = () => (
 *     <components.Placeholder>
 *       <StyledContainer>
 *         // ...
 *       </StyledContainer>
 *     </components.Placeholder>
 *   );
 */

export const Dropdown = ({
  // TODO: implement compact style?
  // compact = false,
  disabled = false,
  displayOptionValue = false,
  isSearchable = true,
  id,
  onChange,
  onFocus,
  size = 'medium',
  ...props
}: SelectProps) => {
  const [isFocused, setIsFocused] = React.useState(false);

  const handleBlur = React.useCallback(() => {
    if (isFocused) {
      setIsFocused(false);
    }
  }, [isFocused]);

  const handleFocus = React.useCallback(
    (event: React.FocusEvent) => {
      if (!isFocused) {
        setIsFocused(true);
      }
      if (onFocus) {
        onFocus(event);
      }
    },
    [isFocused, onFocus],
  );

  const handleChange = React.useCallback(
    ({ value }: { value: string }) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  const appTheme = useTheme();

  const options = React.useMemo<PossibleOptions | null>(() => {
    if (props.source) {
      return props.source.map(
        ({
          name,
          description,
          label,
          options,
        }: {
          name: string;
          description: string;
          label?: string;
          options?: Array<{ name: string; description: string }>;
        }) =>
          label && options
            ? {
                label,
                options: options.map(({ name, description }) => ({
                  value: name,
                  label: description,
                })),
              }
            : { value: name, label: description },
      );
    } else if (props.options) {
      return props.options;
    }

    return null;
  }, [props.source, props.options]);

  const memoizedValueCache = React.useMemo(() => {
    const map = new Map();
    if (options) {
      for (const option of options) {
        // @ts-expect-error - TS2339 - Property 'options' does not exist on type 'DropdownOption | DropdownGroup'.
        if (option.options) {
          const group: any = option;
          for (const subOption of group.options) {
            map.set(subOption.value, subOption);
          }
        } else {
          // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'DropdownOption | DropdownGroup'.
          map.set(option.value, option);
        }
      }
    }
    return map;
  }, [options]);

  const value = memoizedValueCache.get(props.value);

  const conditionalStyles = {};

  if (props.error) {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type '"borderColor"' can't be used to index type '{}'.
    conditionalStyles['borderColor'] = appTheme.colors.critical;
  } else if (disabled) {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type '"borderColor"' can't be used to index type '{}'.
    conditionalStyles['borderColor'] = appTheme.colors.borderMain;
  }

  const menuBackgroundColor =
    props.backgroundColor &&
    props.backgroundColor === 'backgroundNeutralSecondary'
      ? 'backgroundNeutralMain'
      : 'backgroundNeutralSecondary';

  return (
    // @ts-expect-error - TS2769 - No overload matches this call. | TS2786 - 'Select' cannot be used as a JSX component.
    <Select
      appTheme={appTheme}
      components={{
        GroupHeading,
        Menu,
        Option,
        Placeholder,
        SelectContainer,
        SingleValue,
        DropdownIndicator,
        ...(props.components || {}),
      }}
      backgroundColor={props.backgroundColor}
      defaultValue={props.defaultValue}
      displayOptionValue={displayOptionValue}
      error={props.error}
      isDisabled={disabled}
      isFocused={isFocused}
      isSearchable={isSearchable}
      inputId={id}
      label={props.label}
      menuIsOpen={props.menuIsOpen}
      name={props.name}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      options={options}
      optionContent={props.optionContent}
      placeholder={props.placeholder}
      styles={{
        container: (provided) => ({
          ...provided,
          margin: `8px 0px`,
          minWidth: 106,
          ...(props.style || {}),
        }),
        control: (provided) => ({
          ...provided,
          boxShadow: 'none',
          backgroundColor: props.backgroundColor
            ? appTheme.colors[props.backgroundColor]
            : 'inherit',
          borderColor: isFocused
            ? appTheme.colors.borderActive
            : appTheme.colors.borderMain,
          borderWidth: 1,
          maxHeight: 48,
          minHeight: size === 'large' ? 66 : 48,
          ...(props.style?.control || {}),
          ...conditionalStyles,
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          color: (() => {
            if (disabled) {
              return appTheme.colors.foregroundNeutralTertiary;
            } else if (isFocused) {
              return appTheme.colors.borderActive;
            }
            return appTheme.colors.foregroundNeutralSecondary;
          })(),
          transition: 'all 0.2s ease-in-out',
          transform: `rotateX(${
            state.selectProps.menuIsOpen ? '180deg' : '0'
          })`,
          ...conditionalStyles,
        }),
        group: (provided) => ({
          ...provided,
          padding: 0,
        }),
        groupHeading: (provided) => ({
          ...provided,
          backgroundColor: props.backgroundColor
            ? appTheme.colors[props.backgroundColor]
            : 'inherit',
          borderBottom: `1px solid ${appTheme.colors.borderMain}`,
          borderTop: `1px solid ${appTheme.colors.borderMain}`,
          color: `${appTheme.colors.foregroundNeutralMain}`,
          padding: `0 16px 1px`,
          ...(props.style?.groupHeading || {}),
        }),
        input: () => ({
          isDisabled: true,
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        menu: (provided) => ({
          ...provided,
          minWidth: 'fit-content',
          boxShadow: '0 4px 8px 0 rgba(18, 18, 61, 0.2)',
          zIndex: 1000000,
          top: 'auto',
          ...(props.style?.menu || {}),
        }),
        menuList: (provided) => ({
          ...provided,
          backgroundColor: appTheme.colors[menuBackgroundColor],
          borderRadius: 8,
          ...(props.style?.menuList || {}),
        }),
        option: () => {},
        placeholder: () => {},
        singleValue: () => {},
        valueContainer: (provided) => ({
          ...provided,
          padding:
            size === 'large' ? '14px 0px 14px 16px' : '11px 0px 11px 16px',
          flexWrap: 'nowrap',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }),
      }}
      tabIndex={props.tabIndex}
      theme={(theme) => ({
        ...theme,
        borderRadius: 8,
        colors: {
          primary: appTheme.colors.primary,
          neutral30: appTheme.colors.foregroundNeutralTertiary,
        },
      })}
      warning={props.warning}
      value={value || null}
      onKeyDown={(e) => props?.onKeyDown && props.onKeyDown(e)}
    />
  );
};
