import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { SmartTransferDetails } from '~/components/SmartTransferDetails';

export type SmartTransferDetailsProps = {
  smartTransferRuleId: string;
};

export const SmartTransferDetailsPage = ({
  smartTransferRuleId,
}: SmartTransferDetailsProps) => (
  <Box margin="0 auto" pb={100} pt={64} width={490}>
    <PageErrorBoundary>
      <SmartTransferDetails smartTransferRuleId={smartTransferRuleId} />
    </PageErrorBoundary>
  </Box>
);
