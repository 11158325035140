import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  ACTION_TYPES as ACTIONS,
  FinishedAddSharedPieAction,
  navigate,
} from '~/redux/actions';

import { hasUserOnboarded } from '../common';

export function* shareMonitor(): SagaIterator<void> {
  yield takeLatest(
    ACTIONS.FINISHED_ADD_SHARED_PIE,
    function* ({ payload }: FinishedAddSharedPieAction): SagaIterator<void> {
      const { pieId, didAttemptPieCreation } = payload;

      const [userHasOnboarded] = yield all([call(hasUserOnboarded)]);

      if (userHasOnboarded) {
        if (didAttemptPieCreation && pieId) {
          yield put(
            navigate({
              to: '/d/research/my-pies/details/:userPieId',
              params: { userPieId: pieId },
            }),
          );
        } else {
          yield put(navigate({ to: '/d/research/my-pies' }));
        }
      } else {
        yield put(navigate({ to: '/start/product-selection' }));
      }
    },
  );
}
