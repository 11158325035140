import { Button, Flex, HXS, PL } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { SmartTransferTimingMessage } from '~/components/SmartTransferTimingMessage';
import { useSmartTransferReceiptQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { useSelector } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Spinner } from '~/toolbox/spinner';

export const SmartTransferReceipt = () => {
  const navigate = useNavigate();
  const balanceTrigger = useSelector(
    (state) => state.newFlows.CREATE_SMART_TRANSFER.input.balanceTrigger,
  );
  const smartTransferRuleId = useSelector(
    (state) => state.newFlows.CREATE_SMART_TRANSFER.smartTransferRuleId,
  );

  const { data, loading } = useSmartTransferReceiptQuery({
    variables: {
      smartTransferRuleId: smartTransferRuleId as string,
    },
    skip: !smartTransferRuleId,
  });

  if (loading) {
    return <Spinner />;
  }

  if (data?.node?.__typename !== 'SmartTransferRule') {
    return <GenericSystemError />;
  }

  const editRequirements = data?.node?.editRequirements;

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      maxWidth={490}
      mx="auto"
      pt={100}
    >
      <Illustration height={216} name="smartTransfer" width={216} />
      <HXS content="Smart Transfer created" mt={32} />
      <PL mt={32}>
        Your
        <b>
          {' '}
          {editRequirements?.focusOption &&
            editRequirements.focusOption.name}{' '}
          Smart Transfer{' '}
        </b>{' '}
        has been created.
      </PL>
      {editRequirements && balanceTrigger && (
        <SmartTransferTimingMessage
          balanceTrigger={{
            balanceTriggerType: balanceTrigger.balanceTriggerType,
            balanceThreshold: balanceTrigger.balanceThreshold,
            underBalanceRefillTarget: balanceTrigger.underBalanceRefillTarget,
          }}
          editRequirementsId={editRequirements.id}
          style={{
            marginTop: 16,
          }}
        />
      )}
      <ButtonGroup mt={64}>
        <Button
          kind="secondary"
          label="View Smart Transfer"
          onClick={() => {
            navigate({
              to: '/d/c/smart-transfer-details/:smartTransferRuleId',
              params: { smartTransferRuleId },
            });
          }}
        />
        <Button
          kind="primary"
          label="Done"
          onClick={() => {
            navigate({ to: '/d/transfers/rules' });
          }}
        />
      </ButtonGroup>
    </Flex>
  );
};
