import { Button, Flex, useTheme } from '@m1/liquid-react';
import * as React from 'react';

import {
  SecondaryPeriod,
  SecondaryPeriodSelector,
} from '~/components/period-selector';
import { AppAction } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { SecurityVolumeBars } from '../../security/security-volume-bars';

import {
  SecondaryHistoryChartContainer,
  Loader,
  ControlsContainer,
  ControlsContainerModal,
  ChartContainer,
  HoverTarget,
  Scrubber,
  NoDataMessage,
} from './elements';
import { SecondaryHistoricalChartD3 } from './SecondaryHistoricalChartD3';
import type { DataPoint, Notation } from './types';

type Props = {
  chartHeight?: number;
  chartWidth?: number;
  data: Array<DataPoint>;
  intradayDateMinutes: Array<string> | null | undefined;
  isModal: boolean;
  loading: boolean;
  noDataMessage?: string;
  notations: Array<Notation>;
  period: string;
  periodOptions: Array<SecondaryPeriod> | null | undefined;
  setModalOpen: (value: boolean) => void;
  startLineValue?: number | null | undefined;
};

export const SecondaryHistoricalChart = ({
  chartHeight,
  chartWidth,
  data,
  isModal,
  loading = false,
  noDataMessage = 'No data available',
  notations = [],
  period,
  periodOptions,
  setModalOpen,
  startLineValue,
  intradayDateMinutes,
}: Props) => {
  const dispatch = useDispatch();
  const chart = React.useRef(null);
  const chartWrapper = React.useRef(null);
  const d3Chart = React.useRef<SecondaryHistoricalChartD3>();
  const [chartInitialized, setChartInitalized] = React.useState(false);
  const initialHeight = 380;
  const initialWidth = 780;
  const dispatcher = React.useCallback(
    (action: AppAction) => {
      dispatch(action);
    },
    [dispatch],
  );

  const theme = useTheme();
  const initializeChart = React.useCallback(() => {
    if (data && data.length > 1) {
      d3Chart.current = new SecondaryHistoricalChartD3({
        chart: chart.current,
        chartWrapper: chartWrapper.current,
        data,
        dispatcher,
        height: chartHeight || initialHeight,
        width: chartWidth || initialWidth,
        isModal,
        notations,
        startLineValue,
        intradayDateMinutes,
        theme,
      });
      setChartInitalized(true);
    }
  }, [
    chartHeight,
    chartWidth,
    data,
    dispatcher,
    isModal,
    notations,
    startLineValue,
    intradayDateMinutes,
    theme,
  ]);

  React.useEffect(() => {
    if (!chartInitialized) {
      initializeChart();
    }
    if (d3Chart.current && chartInitialized) {
      d3Chart.current.updateChart(true);
    }
    if (chartInitialized && d3Chart.current && data.length > 1) {
      d3Chart.current.input.startLineValue = startLineValue;
      d3Chart.current.input.data = data;
      d3Chart.current.input.notations = notations;
      d3Chart.current.updateChart(false);
    }
  }, [chartInitialized, data, initializeChart, notations, startLineValue]);

  React.useEffect(() => {
    if (d3Chart.current) {
      d3Chart.current.removeChart();
      initializeChart();
    }
  }, [initializeChart, chartHeight, chartWidth]);

  return (
    <SecondaryHistoryChartContainer>
      {loading && (
        <Loader>
          <Spinner />
        </Loader>
      )}
      {isModal && Array.isArray(periodOptions) && (
        <ControlsContainerModal>
          {periodOptions && (
            <SecondaryPeriodSelector periods={periodOptions} period={period} />
          )}
        </ControlsContainerModal>
      )}
      <ChartContainer ref={chartWrapper}>
        <HoverTarget id="hoverTarget" />
        <Scrubber id="scrubber" />
        <svg ref={chart} height={initialHeight} width={initialWidth} />
        {Array.isArray(data) && data.length <= 1 && !loading && (
          <NoDataMessage>{noDataMessage}</NoDataMessage>
        )}
        {isModal && (
          <SecurityVolumeBars
            intradayDateMinutes={intradayDateMinutes}
            width={chartWidth}
            data={data}
          />
        )}
      </ChartContainer>
      {!isModal && (
        <Flex>
          {Array.isArray(periodOptions) && (
            <ControlsContainer>
              {periodOptions && (
                <SecondaryPeriodSelector
                  periods={periodOptions}
                  period={period}
                />
              )}
            </ControlsContainer>
          )}
          <Button
            kind="secondary"
            label="Expand"
            rightIcon="expandArrow20"
            onClick={() => setModalOpen(true)}
            size="medium"
          />
        </Flex>
      )}
    </SecondaryHistoryChartContainer>
  );
};
