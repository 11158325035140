import merge from 'lodash-es/merge';
import set from 'lodash-es/set';
import { LOCATION_CHANGE } from 'react-router-redux';

import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import {
  ACCOUNT_SETUP_FLOW_STEPS as STEPS,
  INVEST_ACCOUNT_TYPES,
  RETIREMENT_ACCOUNT_TYPES,
} from '~/static-constants';

import { FlowState } from '../newFlowsReducer.types';
import { createStepReducer } from '../utils';

export type AccountSetupFlowState = FlowState<
  string,
  {
    accountId: string | null | undefined;
    basePath: string;
    input: Record<string, any>;
    phone: string | null | undefined;
  }
>;

export const accountSetupFlowInitialState: AccountSetupFlowState = {
  accountId: null,
  basePath: '',
  input: {},
  phone: null,
  step: STEPS.LOAD_STATUS,
  stepTitle: 'Open Account',
};

const stepReducer = createStepReducer(accountSetupFlowInitialState);

export function accountSetup(
  state: AccountSetupFlowState = accountSetupFlowInitialState,
  action: any,
): AccountSetupFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_ACCOUNT_SETUP_FLOW: {
      const { basePath, phone, registration } = action.payload;
      const input = registration
        ? {
            registration,
          }
        : {};

      return {
        ...accountSetupFlowInitialState,
        basePath,
        phone,
        input,
      };
    }
    case 'SET_ACTIVE_CRYPTO_ACCOUNT':
    case 'SET_ACTIVE_INVEST_ACCOUNT':
      return {
        ...state,
        accountId: action.payload,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        step: stepReducer(state, action),
      };
    default:
      return {
        ...state,
        input: inputReducer(state.input, action),
      };
  }
}

// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type. | TS7006 - Parameter 'action' implicitly has an 'any' type.
function inputReducer(state, action) {
  switch (action.type) {
    case ACTIONS.SELECTED_ACCOUNT_TYPE:
      switch (action.payload) {
        case INVEST_ACCOUNT_TYPES.INDIVIDUAL_TAXABLE:
        case INVEST_ACCOUNT_TYPES.JOINT_TAXABLE:
        case INVEST_ACCOUNT_TYPES.CUSTODIAL:
        case INVEST_ACCOUNT_TYPES.CRYPTO:
          return Object.assign(
            {
              ...state,
            },
            {
              registration: action.payload,
            },
          );
        default:
          return state;
      }
    case ACTIONS.SELECTED_RETIREMENT_ACCOUNT_TYPE:
      return action.payload === RETIREMENT_ACCOUNT_TYPES.ROLLOVER
        ? Object.assign(
            {
              ...state,
            },
            {
              transferInfoOptIn: true,
            },
          )
        : Object.assign(
            {
              ...state,
            },
            {
              registration: action.payload,
              transferInfoOptIn: false,
            },
          );
    case ACTIONS.SELECTED_ROLLOVER_ACCOUNT_TYPE:
    case ACTIONS.SELECTED_OTHER_ACCOUNT_TYPE:
      return Object.assign(
        {
          ...state,
        },
        {
          registration: action.payload,
        },
      );
    case ACTIONS.SUBMITTED_CONTACT_INFO_FORM:
    case ACTIONS.SUBMITTED_CUSTODIAL_CONTACT_INFO_FORM:
    case ACTIONS.SUBMITTED_IDENTITY_INFO_FORM:
    case 'EXPLAINED_PROFILE_COLLECTION':
      return merge({}, state, action.payload.values);
    case ACTIONS.COLLECTED_HOLDER_SSN: {
      const { holder, ssn } = action.payload;
      return merge({}, state, {
        [`${holder}Ssn`]: ssn,
        holder,
      });
    }
    case 'COLLECTED_TRUSTED_CONTACT': {
      return merge({}, state, action.payload);
    }
    case 'SKIPPED_TRUSTED_CONTACT': {
      return merge({}, state, {
        trustedContact: null,
      });
    }
    case ACTIONS.FINISHED_ACCOUNT_SETUP_REVIEW:
      return merge({}, state, action.payload);
    case ACTIONS.SUBMITTED_PROFILE_INPUT: {
      const { field, value } = action.payload;
      return merge(
        {
          ...state,
        },
        set({}, field, value),
      );
    }
    case ACTIONS.SUBMITTED_INTRODUCTION_SOURCE:
      return merge({}, state, {
        introductionSource: action.payload,
      });
    default:
      return state;
  }
}
