import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { CreateTransferFlow } from '~/flows';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { CREATE_TRANSFER_FLOW_MODES as MODES } from '~/static-constants';

export const CreateTransferPageDeprecated = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const modes = Object.values(MODES);
  const mode =
    location.query && location.query.mode && modes.includes(location.query.mode)
      ? location.query.mode
      : MODES.ONE_TIME;

  const fallbackRoute =
    mode === MODES.SCHEDULE ? '/d/transfers/rules' : '/d/transfers';
  const previousRouteName = location.query.previousRouteName || fallbackRoute;

  const handleFinish = (): void =>
    navigate({
      to: previousRouteName,
      options: { state: { forceRefetch: true } },
    });

  return (
    <Box width={490} m="0 auto" p="100px 0">
      <PageErrorBoundary onClickRetry={handleFinish} retryButtonLabel="Exit">
        <CreateTransferFlow
          basePath="d/c/create-transfer"
          mode={mode}
          onFinish={handleFinish}
        />
      </PageErrorBoundary>
    </Box>
  );
};
