import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { CategoryDetailsPage } from './CategoryDetailsPage';

export const ResearchModelPortfolioCategoryDetailsRoute = () => {
  return (
    <Route
      path=":categoryKey"
      component={createRouteComponent(CategoryDetailsPage, (props) => ({
        categoryKey: props.params.categoryKey,
      }))}
    />
  );
};
