import * as React from 'react';
import { Route } from 'react-router';

import { RewardsViewAllProgramsComponent } from './components/RewardsViewAllProgramsComponent';

export const RewardsViewAllProgramsRoute = () => {
  return (
    <Route
      path="rewards/programs/:programType"
      lastVisited="/d/spend/credit/rewards"
      component={RewardsViewAllProgramsComponent}
      cover
    />
  );
};
