import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { MaintenancePage } from './MaintenancePage';

export const MaintenanceRoute = () => {
  return (
    <Route
      path="maintenance"
      component={createRouteComponent(MaintenancePage)}
    />
  );
};
