import { Box, Image, PL, PM, PXL, Flex, Tooltip } from '@m1/liquid-react';
import * as React from 'react';

import { CopyIconButton } from '~/components/CopyIconButton';
import { DashboardHeaderQuery } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { EnvironmentDropdown } from '~/pages/login/components/EnvironmentDropdown';
import { UserLists } from '~/pages/login/components/UserLists';
import { Link } from '~/toolbox/link';

import missionControl from './missionControl.svg';

type Props = {
  style?: Record<string, any>;
  viewer: DashboardHeaderQuery['viewer'] | null | undefined;
};

const dontPropagate = (e: React.MouseEvent) => e.stopPropagation();

export const DeveloperToolMenu = (props: Props) => {
  const navigate = useNavigate();
  const isProduction =
    __NODE_ENV__ === 'production' && __ENV__ === 'production';

  if (isProduction) {
    return null;
  }

  const { viewer } = props;

  const viewerLabel = viewer?.profile?.primary.fullName ?? 'My Account';

  return (
    <Flex
      alignItems="center"
      style={props.style}
      pt={4}
      data-testid="developer-tool-menu"
    >
      <Tooltip
        placement="bottom-start"
        // @ts-ignore-error too bad we want to use our own icon
        icon="settings24"
        trigger="click focus"
        maxWidth={450}
        body={
          <Box color="foregroundNeutralMain">
            <Box cursor="default" whiteSpace="nowrap">
              <Box mt={-8} onClick={dontPropagate}>
                <EnvironmentDropdown fullWidth />
              </Box>
              <PL
                display="flex"
                alignItems="center"
                fontWeight={600}
                mb={2}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {viewerLabel}{' '}
                {viewer?.user?.correlationKey && (
                  <Link
                    to={`https://mission-control.m1finance.staging/user/${viewer.user.correlationKey}/overview`}
                    target="_blank"
                  >
                    <Image
                      src={missionControl}
                      alt="mission control"
                      height={24}
                      ml={8}
                      // @ts-expect-error valid html
                      title="View user in Mission Control"
                    />
                  </Link>
                )}
              </PL>
              {viewer?.user?.username && (
                <PM
                  color="foregroundNeutralMain"
                  display="flex"
                  alignItems="center"
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {viewer.user.username}{' '}
                  <CopyIconButton
                    color="primary"
                    size="small"
                    name="copy20"
                    value={viewer.user.username}
                  />
                </PM>
              )}
              {viewer?.user?.correlationKey && (
                <PM
                  color="foregroundNeutralMain"
                  display="flex"
                  alignItems="center"
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {viewer.user.correlationKey}{' '}
                  <CopyIconButton
                    color="primary"
                    size="small"
                    name="copy20"
                    value={viewer.user.correlationKey}
                  />
                </PM>
              )}
              <Box
                mt={24}
                mb={4}
                onClick={dontPropagate}
                data-testid="users-lists-container"
              >
                <PXL fontWeight={600}>User Switcher</PXL>
                <UserLists
                  fullWidth
                  onClickUser={(user) => {
                    navigate({
                      to: '/logout',
                      query: { user: user.username, autoLogin: true },
                    });
                  }}
                />
              </Box>
            </Box>
          </Box>
        }
      />
    </Flex>
  );
};
