import * as React from 'react';
import { Route } from 'react-router';

import { EditSmartTransferPage } from './EditSmartTransferPage';

export const EditSmartTransferRoute = () => {
  return (
    <Route
      path="edit-smart-transfer/:smartTransferRuleId"
      component={EditSmartTransferPage}
      fallbackRoute="/d/transfers/rules"
      cover
    >
      <Route path="new-contra-entry/:newContraParticipantEntryOptionId" />
      <Route path="edit-contra-entry/:contraParticipantEntryEditRequirementsId" />
    </Route>
  );
};
