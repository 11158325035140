import { Button } from '@m1/liquid-react';
import React from 'react';

import { useDispatch } from 'react-redux';

import type {
  AnalyticsEventFragment,
  AnnouncementFragment,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { LinkableButton } from '~/lens-toolbox/LinkableButton';
import { isNonNullable } from '~/utils';

type AnnouncementCallToActionProps = {
  action: AnnouncementFragment['action'];
  link: AnnouncementFragment['appLink'];
  onDismiss: () => void;
};

export const AnnouncementCallToAction = ({
  action,
  link,
  onDismiss,
}: AnnouncementCallToActionProps) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const announcementAction =
    action?.type && action.type === 'ENABLE_PLUS_AUTO_RENEW'
      ? action.type
      : null;

  if (action?.type && announcementAction) {
    return (
      <Button
        label={action.title}
        onClick={() => {
          const event = action?.analyticsEvent;
          if (isNonNullable(event)) {
            analytics.recordAppAnalyticsEvent(event as AnalyticsEventFragment);
          }
          dispatch({
            type: announcementAction,
          });
          onDismiss();
        }}
        mt={32}
        size="large"
      />
    );
  } else if (link) {
    return (
      <LinkableButton
        onClick={onDismiss}
        linkable={link}
        mt={32}
        size="large"
      />
    );
  }
  return null;
};
