import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { usePositionDetailsRendererQuery } from '~/graphql/hooks';

import { PositionDetailsFragment } from '~/graphql/types';
import { Spinner } from '~/toolbox/spinner';

import { PositionDetailsContainer } from './PositionDetailsContainer';

export type PositionDetailsRendererProps = {
  isNavigable?: boolean;
  onSellLinkClick?: (...args: Array<any>) => any;
  positionId: string;
  previousRouteName?: string | null | undefined;
};

export const PositionDetails = ({
  isNavigable,
  onSellLinkClick,
  positionId,
  previousRouteName,
}: PositionDetailsRendererProps) => {
  const { data, loading } = usePositionDetailsRendererQuery({
    variables: {
      positionId,
    },
  });

  if (loading) {
    return <Spinner />;
  }

  const position = data?.position;

  if (position?.__typename !== 'Position') {
    return <GenericSystemError />;
  }

  return (
    <PositionDetailsContainer
      isNavigable={isNavigable}
      onSellLinkClick={onSellLinkClick}
      position={position as PositionDetailsFragment}
      previousRouteName={previousRouteName}
    />
  );
};
