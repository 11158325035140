import * as React from 'react';
import { IndexRoute, Redirect, Route } from 'react-router';

import { ModelPortfolioPage } from '~/pages/dashboard/research/model-portfolios/ModelPortfolioPage';

import { AddModelPortfolioCategoryDetailsRoute } from './category-details';
import { AddModelPortfolioDetailsRoute } from './pie-details';

export const AddModelPortfoliosRoute = () => {
  return (
    <Route path="model-portfolios">
      <Redirect
        from="/d/c/add-slices/expert-pies"
        to="/d/c/add-slices/model-portfolios"
      />
      <Redirect
        from="/d/c/add-slices/expert-pies/:categoryKey"
        to="/d/c/add-slices/model-portfolios/:categoryKey"
      />
      <Redirect
        from="/d/c/add-slices/expert-pies/details/:modelPortfolioId"
        to="/d/c/add-slices/model-portfolios/details/:modelPortfolioId"
      />
      {AddModelPortfolioCategoryDetailsRoute()}
      {AddModelPortfolioDetailsRoute()}
      <IndexRoute component={ModelPortfolioPage} />
    </Route>
  );
};
