import * as React from 'react';
import { Route } from 'react-router';

import { PageErrorBoundary } from '~/components/page-error-boundary';

import { DividendManagementPage } from './DividendManagementPage';

export const InvestAutomationRoutes = () => {
  return (
    <Route
      path="automation/dividend-management"
      component={function (props) {
        return (
          <PageErrorBoundary>
            <DividendManagementPage {...props} />
          </PageErrorBoundary>
        );
      }}
    />
  );
};
