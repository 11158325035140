import {
  Box,
  Button,
  Flex,
  HM,
  HXS,
  PL,
  PM,
  PXL,
  styled,
  Card,
} from '@m1/liquid-react';
import React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { TruncateStringContent } from '~/components/TruncateStringContent';

import {
  useAccountRebalancePageQuery,
  useRebalancePortfolioSliceMutation,
} from '~/graphql/hooks';
import { InvestDisclosuresType } from '~/graphql/types';

import { useAnalytics } from '~/hooks/useAnalytics';
import { useLocation } from '~/hooks/useLocation';
import { useModal } from '~/hooks/useModal';
import { useNavigate } from '~/hooks/useNavigate';

import { useSelector } from '~/redux/hooks';
import { EXTERNAL_LINKS } from '~/static-constants';
import { useToast } from '~/toasts/useToast';
import { Link } from '~/toolbox/link/Link';
import { Spinner } from '~/toolbox/spinner';

const Header = styled(Flex)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled(Box)`
  margin: 0 auto;
  padding: 100px 0;
  width: 490px;
`;

export const RebalancePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { showModal } = useModal('TRADE_DISCLOSURES');
  const analytics = useAnalytics();
  const { accountId, isCryptoAccount } = useSelector((state) => ({
    accountId: state.global.activeAccountId as string,
    isCryptoAccount: state.global.activeAccountIsCrypto,
  }));

  const { portfolioName, portfolioId } = location.query;

  const { data, loading: queryLoading } = useAccountRebalancePageQuery({
    variables: { accountId },
    skip: !accountId,
  });
  const [rebalancePortfolioSlice, { loading: mutationLoading }] =
    useRebalancePortfolioSliceMutation({
      variables: {
        input: {
          isRebalance: true,
          portfolioSliceId: portfolioId,
        },
      },
    });

  if (!accountId) {
    return (
      <GenericSystemError content="There was an error loading your account. Please try again later." />
    );
  }

  if (mutationLoading || queryLoading) {
    return <Spinner />;
  }

  if (data?.account?.__typename !== 'Account') {
    return null;
  }

  const account = data?.account;

  const handleClickRebalance = (): void => {
    rebalancePortfolioSlice({
      onCompleted: () => {
        const nextTradeTime =
          account.preferredTradeWindow !== 'DEFAULT'
            ? account.preferredTradeWindow
            : account.trading?.nextWindowForAccount;

        navigate({ to: `/d/invest/portfolio/${portfolioId}` });

        analytics.recordEvent('m1_invest_rebalance_screen_CTA', null, {
          click_id: 'rebalance',
        });

        addToast({
          content: `Rebalance requested. We’ll execute it for you during the ${nextTradeTime?.toLowerCase()} trade window.`,
          duration: 'long',
          kind: 'success',
        });
      },
      onError: () => {
        addToast({
          content:
            'Something went wrong with your request. Please try again later or contact support.',
          duration: 'long',
          kind: 'alert',
        });
      },
    });
  };

  return (
    <>
      <Container>
        <TruncateStringContent>
          <HM>Rebalance {portfolioName}</HM>
        </TruncateStringContent>
        <Card mt={40} p={32}>
          <Header>
            <Box width="100%">
              <HXS fontWeight={600}>Two ways to approach this</HXS>
              <Box mt={8}>
                <Link to={EXTERNAL_LINKS.REBALANCING_FAQ} target="_blank">
                  Learn more about rebalancing
                </Link>
              </Box>
            </Box>
          </Header>
          <Box mt={28}>
            <PXL fontWeight={600}>
              Rebalance in the next trade window<sup>1</sup>
            </PXL>
            <PL fontWeight={400} color="foregroundNeutralSecondary">
              Possible taxable event<sup>2</sup>
            </PL>
            <PM mt={16} mb={24}>
              We’ll buy and sell Slices to return your Pie to its targets.
            </PM>
            <Button
              label="Rebalance"
              size="large"
              onClick={handleClickRebalance}
            />
          </Box>
          <Box mt={28}>
            <PXL fontWeight={600}>Rebalance over time</PXL>
            <PL fontWeight={400} color="foregroundNeutralSecondary">
              No taxable event, no selling occurs<sup>2</sup>
            </PL>
            <PM mt={16} mb={24}>
              Every time you make a deposit with auto-invest on, we buy what’s
              underweight in your overall portfolio to bring it closer to its
              targets. So, consider setting up recurring deposits to rebalance
              over time.
            </PM>
            <Button
              label="Schedule deposits"
              kind="secondary"
              size="large"
              onClick={() => {
                analytics.recordEvent('m1_invest_rebalance_screen_CTA', null, {
                  click_id: 'schedule_deposits',
                });
                navigate({
                  to: `/d/c/create-transfer`,
                  query: {
                    mode: 'SCHEDULE',
                    toParticipantId: accountId,
                  },
                });
              }}
            />
          </Box>
        </Card>
        <Box mt={44} textAlign="center">
          <Button
            kind="link"
            onClick={() =>
              showModal({
                disclosuresFor: isCryptoAccount
                  ? InvestDisclosuresType.Crypto
                  : InvestDisclosuresType.Securities,
              })
            }
            label={
              <PL whiteSpace="nowrap" textDecoration="underline">
                <sup>1,2</sup> Learn more about how M1 trades and view important
                disclosures.
              </PL>
            }
          />
        </Box>
      </Container>
    </>
  );
};
