import castArray from 'lodash-es/castArray';
import get from 'lodash-es/get';
import { routerActions } from 'react-router-redux';
import { SagaIterator } from 'redux-saga';
import {
  call,
  getContext,
  put,
  select,
  setContext,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { ACTIONS } from '~/redux/actions/rootAction';
import type {
  FlowDefinition,
  FlowStep,
  FLOWS,
} from '~/redux/reducers/newFlows';
import { buildRoute } from '~/utils/route';

import type { AppState } from '../../../reducers/types';

interface SagaHelpers<D extends FlowDefinition> {
  changeStep(
    step: FlowStep<D>,
    ...rest: any[]
  ): ReturnType<typeof changeFlowStep>;
  selectFlowState(...rest: any): ReturnType<typeof selectFlowState>;
  takeFlow(...rest: any[]): ReturnType<typeof takeFlow>;
  takeFlowStep(
    step: FlowStep<D>,
    ...rest: any[]
  ): ReturnType<typeof takeFlowStep>;
}

/*
  Creates a set of utility functions tailored for a specific flow. This is done only
  for convenience and doesn't offer any functionality on top of the underlying functions.

  For example, the following are equivalent:

  const someFlowDefinition = {
    foo: 'bar',
    steps: { ... }
  };

  // Sample one
  changeFlowStep(someFlowDefinition, someFlowDefinition.steps.stepOne)

  // Sample two
  const { changeStep } = createSagaHelpers(someFlowDefinition);
  changeStep(someFlowDefinition.steps.stepOne);

  It's unnecessary boilerplate in this particular example, but pays dividends the more
  you use the various functions.
*/

export function createSagaHelpers<D extends FlowDefinition>(
  definition: D,
): SagaHelpers<D> {
  const name = definition.name;
  const steps = definition.steps;
  return {
    changeStep: (step, ...rest) => changeFlowStep(steps, step, ...rest),
    // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
    selectFlowState: (...rest) => selectFlowState(name, ...rest),
    // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
    takeFlow: (...rest) => takeFlow(name, ...rest),
    // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
    takeFlowStep: (step, ...rest) => takeFlowStep(definition, step, ...rest),
  };
}

/**
  Utility function to provide a procedural mechanism to change steps.

  @param steps - Provided by a flow definition, object of all valid steps of a flow.
  @param step - A key of steps, which step to change to.
  @param replace - When true, replaces the last entry in browser history rather than
    pushing. This is useful when setting an initial step, or overriding a loading step.
  @param otherOptions - A catch-all to provide additional options to the router action, e.g. query params.
*/
function* changeFlowStep<K>(
  // @ts-expect-error - TS2344 - Type 'K' does not satisfy the constraint 'string | number | symbol'.
  steps: Record<K, string>,
  step: K,
  replace: boolean = false,
  otherOptions: Record<string, any> = {},
): SagaIterator<void> {
  const action = replace ? 'replace' : 'push';

  const basePath: string = yield getContext('basePath') || '';
  const pathname = buildRoute([basePath, steps[step]]);

  yield put(
    routerActions[action]({
      ...otherOptions,
      pathname,
      state: {
        step,
      },
    }),
  );
}

/**
  Utility function to make it slightly easier to select Redux state for a given flow.
  @param name - What flow are we selecting state from
  @param path - path to the state key in question. Uses lodash.get syntax.
*/
function* selectFlowState(
  // @ts-expect-error - TS2749 - 'FLOWS' refers to a value, but is being used as a type here. Did you mean 'typeof FLOWS'?
  name: ValueOf<FLOWS>,
  path: string,
): SagaIterator<any> {
  return yield select((state: AppState): Record<string, any> => {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'FLOWS' can't be used to index type 'NewFlowsState'.
    return get(state.newFlows[name], path, undefined);
  });
}

/**
  Listens for the signal to begin a particular flow. When found, calls the provided saga.
  @param name - What flow are we listening for
  @param saga - Saga to call when the flow should begin. Will only allow one instance of a
  flow to be running at once. If a sginal to begin a flow happens when a flow is already running,
  the existing flow would be canceled and a new one created.
  @param {...*} rest - Any other arguments you want to pass to the saga.
*/
function* takeFlow(
  // @ts-expect-error - TS2749 - 'FLOWS' refers to a value, but is being used as a type here. Did you mean 'typeof FLOWS'?
  name: ValueOf<FLOWS>,
  saga: any,
  ...rest: Array<any>
): SagaIterator<void> {
  yield takeLatest(
    // @ts-expect-error - TS7006 - Parameter 'a' implicitly has an 'any' type.
    (a) => shouldFlowBegin(name, a),
    function* (action): SagaIterator<void> {
      // Setup basePath for routing
      // @ts-expect-error - TS2339 - Property 'payload' does not exist on type 'Action<string>'.
      if (action.payload.basePath) {
        yield setContext({
          // @ts-expect-error - TS2339 - Property 'payload' does not exist on type 'Action<string>'.
          basePath: action.payload.basePath,
        });
      } else {
        // eslint-disable-next-line
        console.warn(`${name} flow init action is missing a basePath.`);
      }

      yield call(saga, action, ...rest);
    },
  );
}

// @ts-expect-error - TS2749 - 'FLOWS' refers to a value, but is being used as a type here. Did you mean 'typeof FLOWS'?
function shouldFlowBegin(name: ValueOf<FLOWS>, action: any): boolean {
  return Boolean(
    action.type === ACTIONS.BEGIN_FLOW &&
      action.meta &&
      action.meta.flow === name,
  );
}

function* takeFlowStep<D extends FlowDefinition>(
  definition: D,
  step: FlowStep<D>,
  saga: any,
  ...rest: Array<any>
): SagaIterator<void> {
  yield takeEvery(
    // @ts-expect-error - TS7006 - Parameter 'a' implicitly has an 'any' type.
    (a) => shouldFlowStepBegin(definition, step, a),
    saga,
    ...rest,
  );
}

function shouldFlowStepBegin<D extends FlowDefinition>(
  definition: D,
  step: FlowStep<D>,
  action: any,
): boolean {
  const observedSteps = castArray(step);
  return Boolean(
    action.type === ACTIONS.FINISHED_FLOW_STEP &&
      action.meta &&
      action.meta.flow === definition.name &&
      observedSteps.includes(action.meta.step),
  );
}
