import * as React from 'react';
import { Route } from 'react-router';

import { DisclosuresForCreditPage } from './DisclosuresForCreditPage';

export const DisclosuresForCreditRoute = () => {
  return (
    <Route path="credit-disclosures" component={DisclosuresForCreditPage} />
  );
};
