import { LocationState } from 'react-router';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import {
  ClickedOnPortfolioSliceDocument,
  ClickedOnPortfolioSliceQueryResult,
  ClickedOnSecuritySagaDocument,
  ClickedOnSecuritySagaQueryResult,
  SliceableTypenameSagaDocument,
  SliceableTypenameSagaQueryResult,
} from '~/graphql/hooks';

import { ACTION_TYPES as ACTIONS, navigate, showModal } from '~/redux/actions';
import { APP_MODES } from '~/static-constants';

import type { AppState } from '../../reducers/types';
import { apolloQuerySaga } from '../apolloQuerySaga';
import { select } from '../effects';

import { clickedAddSharedPie } from './clickedAddSharedPieSaga';
import { clickedAddToPortfolio } from './clickedAddToPortfolioSaga';
import { clickedCancelOrder } from './clickedCancelOrderSaga';
import { clickedResendVerificationEmail } from './clickedResendVerificationEmailSaga';
import { clickedSetOrder } from './clickedSetOrderSaga';
import { initializeWatchers } from './initializeWatchers';

export function* eventsSaga(): SagaIterator<void> {
  const watchers = [
    [ACTIONS.CLICKED_ON_SLICEABLE, resolveWithPayload(clickedOnSliceable)],
    [ACTIONS.CLICKED_ON_PIE_CATEGORY, resolveWithPayload(clickedOnPieCategory)],
    [
      ACTIONS.CLICKED_ON_PORTFOLIO_SLICE,
      resolveWithPayload(clickedOnPortfolioSlice),
    ],
    [ACTIONS.CLICKED_CONNECT_BANK, resolveWithPayload(clickedConnectBank)],
    [
      ACTIONS.CLICKED_ADD_TO_PORTFOLIO,
      resolveWithPayload(clickedAddToPortfolio),
    ],
    [ACTIONS.CLICKED_ADD_SHARED_PIE, resolveWithPayload(clickedAddSharedPie)],
    [ACTIONS.CLICKED_CANCEL_ORDER, resolveWithPayload(clickedCancelOrder)],
    [ACTIONS.CLICKED_SET_ORDER, resolveWithPayload(clickedSetOrder)],
    [
      ACTIONS.CLICKED_RESEND_VERIFICATION_EMAIL,
      resolveWithPayload(clickedResendVerificationEmail),
    ],
  ];

  // @ts-expect-error - TS2769 - No overload matches this call.
  yield call(initializeWatchers, watchers);
}

function* clickedOnSliceable(
  sliceableId: string,
  isCrypto?: boolean,
): SagaIterator<void> {
  const appMode: string = yield select((state: AppState) => state.mode);

  const locationState = yield select<LocationState | undefined>(
    (state: AppState) => state.routing.locationBeforeTransitions?.state,
  );

  const { data }: SliceableTypenameSagaQueryResult = yield call(
    apolloQuerySaga,
    { query: SliceableTypenameSagaDocument, variables: { sliceableId } },
  );
  const sliceable = data?.node;

  switch (sliceable?.__typename) {
    case 'CryptoAsset':
    case 'Equity':
    case 'Fund':
      yield call(clickedOnSecurity, sliceableId);
      break;
    case 'SystemPie': {
      const routePath =
        appMode === APP_MODES.ADD
          ? '/d/c/add-slices/model-portfolios/details/:modelPortfolioId'
          : '/d/research/model-portfolios/details/:modelPortfolioId';

      yield put(
        navigate({
          to: routePath,
          params: { modelPortfolioId: sliceableId },
          options: { state: locationState },
        }),
      );
      break;
    }
    case 'UserPie': {
      const routePath =
        appMode === APP_MODES.ADD
          ? '/d/c/add-slices/my-pies/details/:userPieId'
          : '/d/research/my-pies/details/:userPieId';

      yield put(
        navigate({
          to: routePath,
          params: { userPieId: sliceableId },
          query: { isCrypto: isCrypto },
          options: {
            state: locationState,
          },
        }),
      );
      break;
    }
    default: // Unrecognized Sliceable type. Do nothing.
  }
}

function* clickedOnPortfolioSlice(
  portfolioSliceId: string,
): SagaIterator<void> {
  const activeAccountId: string = yield select(
    (state: AppState) => state.global.activeAccountId,
  );

  const { data }: ClickedOnPortfolioSliceQueryResult = yield call(
    apolloQuerySaga,
    { query: ClickedOnPortfolioSliceDocument, variables: { portfolioSliceId } },
  );

  if (
    data?.node &&
    'account' in data.node &&
    data.node.account.id !== activeAccountId
  ) {
    yield put({
      type:
        data.node.account.registration === 'CRYPTO'
          ? 'SET_ACTIVE_CRYPTO_ACCOUNT'
          : 'SET_ACTIVE_INVEST_ACCOUNT',
      payload: data.node.account.id,
    });
  }

  yield put(
    navigate({
      to: '/d/invest/portfolio/:portfolioSliceId',
      params: { portfolioSliceId },
    }),
  );
}

/** @deprecated Transition to using clickedOnSliceable. */
function* clickedOnSecurity(securityId: string): SagaIterator<void> {
  const appMode = yield select((state: AppState) => state.mode);

  const locationState = yield select<LocationState | undefined>(
    (state: AppState) => state.routing.locationBeforeTransitions?.state,
  );

  const { data }: ClickedOnSecuritySagaQueryResult = yield call(
    apolloQuerySaga,
    { query: ClickedOnSecuritySagaDocument, variables: { securityId } },
  );

  const security = data?.node && 'isActive' in data.node ? data.node : null;

  if (security?.isActive) {
    const navigateOptions = {
      state: locationState,
    };
    switch (security.__typename) {
      case 'Equity': {
        const routePath =
          appMode === APP_MODES.ADD
            ? '/d/c/add-slices/stocks/details/:equityId'
            : '/d/research/stocks/details/:equityId';

        yield put(
          navigate({
            to: routePath,
            params: { equityId: securityId },
            options: navigateOptions,
          }),
        );
        break;
      }
      case 'Fund': {
        const routePath =
          appMode === APP_MODES.ADD
            ? '/d/c/add-slices/funds/details/:fundId'
            : '/d/research/funds/details/:fundId';

        yield put(
          navigate({
            to: routePath,
            params: { fundId: securityId },
            options: navigateOptions,
          }),
        );
        break;
      }
      case 'CryptoAsset': {
        const routePath =
          appMode === APP_MODES.ADD
            ? '/d/c/add-slices/crypto/details/:cryptoId'
            : '/d/research/crypto/details/:cryptoId';

        yield put(
          navigate({
            to: routePath,
            params: { cryptoId: securityId },
            options: navigateOptions,
          }),
        );
        break;
      }
      default: // Do nothing.
    }
    // @ts-expect-error - TS2339 - Property '__typename' does not exist on type 'Security'.
  } else if (security.__typename !== 'CryptoAsset') {
    yield put(showModal('SECURITY_STATUS', securityId));
  }
}

function* clickedOnPieCategory(categoryKey: string): SagaIterator<void> {
  const { appMode, state } = yield select((state: AppState) => {
    const { locationBeforeTransitions } = state.routing;

    return {
      appMode: state.mode,
      state: locationBeforeTransitions?.state,
    };
  });

  const routePath =
    appMode === APP_MODES.ADD
      ? '/d/c/add-slices/model-portfolios/:categoryKey'
      : '/d/research/model-portfolios/:categoryKey';

  yield put(
    navigate({
      to: routePath,
      params: { categoryKey },
      options: { state },
    }),
  );
}

function* clickedConnectBank(): SagaIterator<void> {
  yield put(navigate({ to: '/d/c/connect-bank' }));
}

function resolveWithPayload(
  fn: (...args: Array<any>) => any,
): (...args: Array<any>) => any {
  return function (action: any): any {
    return fn(action.payload, action.isCrypto);
  };
}
