import { Box, Button, Flex, HXS, PL, styled } from '@m1/liquid-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { ControlledInput } from '~/components/form/ControlledInput';
import { GenericSystemError } from '~/components/GenericSystemError';

import {
  useTrustedContactPageQuery,
  useUpdateProfileMutation,
} from '~/graphql/hooks';
import { TrustedContactFieldsFragment } from '~/graphql/types';
import { withoutTypename } from '~/graphql/utils';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useToast } from '~/toasts';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

type TrustedContactInput = TrustedContactFieldsFragment;

const StyledInputWrapper = styled(Box)`
  width: 100%;
  margin-right: 24px;
`;

export const TrustedContactPage = () => {
  const { data, error, loading } = useTrustedContactPageQuery();
  const { addToast } = useToast();
  const { control, handleSubmit, reset, formState } =
    useForm<TrustedContactFieldsFragment>();

  const [updateProfile, { loading: updatingProfile }] =
    useUpdateProfileMutation();

  usePortaledSpinner(updatingProfile);

  React.useEffect(() => {
    if (data?.viewer.profile?.trustedContact) {
      reset(data?.viewer.profile?.trustedContact);
    }
  }, [data]);

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer) {
    return <GenericSystemError />;
  }

  const onSubmit = (trustedContact: TrustedContactInput): void => {
    if (trustedContact) {
      updateProfile({
        variables: {
          input: {
            profile: withoutTypename({
              primary: {},
              trustedContact,
            }),
          },
        },
        onCompleted: () =>
          addToast({
            content: 'Profile updated',
            kind: 'success',
            duration: 'long',
          }),
        onError: () =>
          addToast({
            content: 'Error updated profile. Please try again later.',
            kind: 'alert',
            duration: 'long',
          }),
      });
    }
  };

  return (
    <>
      <HXS content="Trusted Contact" pb={8} />
      <PL>
        We’ll only contact this person in certain situations.{' '}
        <Link
          to="https://help.m1.com/en/articles/9331942-what-is-a-trusted-contact"
          target="_blank"
        >
          Learn more about trusted contacts
        </Link>
      </PL>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex flexDirection="column">
            <StyledInputWrapper>
              <ControlledInput
                name="firstName"
                label="First name"
                rules={{ required: 'Required' }}
                control={control}
              />
            </StyledInputWrapper>
            <Box mt={16}>
              <ControlledInput
                name="lastName"
                label="Last name"
                rules={{ required: 'Required' }}
                control={control}
              />
            </Box>
          </Flex>
          <Flex flexDirection="row">
            <StyledInputWrapper>
              <ControlledInput
                name="email"
                label="Email"
                mr={24}
                rules={{ required: 'Required' }}
                control={control}
              />
            </StyledInputWrapper>
            <ControlledInput
              label="Phone number"
              name="phoneNumber"
              rules={{
                required: 'Required',
                minLength: { value: 10, message: 'Invalid phone number' },
                maxLength: { value: 10, message: 'Invalid phone number' },
              }}
              control={control}
              transformInput={(value, prevValue = '') => {
                const normalized = value.replace(/[-)( ]/g, '');
                return prevValue.length === normalized.length
                  ? normalized.substring(0, normalized.length - 1)
                  : normalized;
              }}
              transformValue={(value) => {
                const [, areaCode, firstThree, lastThree] =
                  (value ?? '').match(/(\d{1,3})(\d{1,3})?(\d{1,4})?/) ?? [];
                return `(${areaCode ?? ''}) ${firstThree ?? ''}${
                  lastThree ? '-' : ''
                }${lastThree ?? ''}`;
              }}
            />
          </Flex>
          <Flex flexDirection="row" justifyContent="flex-end" mt={20}>
            <Button
              kind="primary"
              label="Save"
              type="submit"
              disabled={!formState.isDirty || !formState.isValid}
            />
          </Flex>
        </form>
      </Box>
    </>
  );
};
