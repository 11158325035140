import {
  Button,
  Maybe,
  ButtonProps,
  ButtonKind,
  ButtonSize,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import copy from 'copy-to-clipboard';
import * as React from 'react';

import { AppButtonUnion, AppShareButtonTypeEnum } from '~/graphql/types';

import { useToast } from '~/toasts';

import { AppButton } from '../lens-toolbox/AppButton/AppButton';
import {
  LinkableButton,
  LinkableSkeletonButtonProps,
} from '../lens-toolbox/LinkableButton';
import {
  LinkableLink,
  LinkableSkeletonLinkProps,
} from '../lens-toolbox/LinkableLink';

type AppButtonsProps = {
  button: Maybe<AppButtonUnion>;
  appButtonProps?: Partial<ButtonProps>;
  appShareButtonProps?: Partial<ButtonProps>;
  linkButtonProps?: Partial<LinkableSkeletonButtonProps>;
  linkLinkProps?: Partial<LinkableSkeletonLinkProps>;
  useLink?: boolean;
};

export const AppButtons = ({
  button,
  appButtonProps,
  appShareButtonProps,
  linkButtonProps,
  linkLinkProps,
  useLink,
}: AppButtonsProps) => {
  const { addToast } = useToast();

  const handleAppShareButtonClick = (
    shareContent?: string | null,
    shareType?: AppShareButtonTypeEnum | null,
  ) => {
    if (!shareContent) {
      return;
    }

    switch (shareType) {
      case 'SHARE':
      // TODO: Implement share functionality if/when needed
      case 'COPY':
      default:
        copy(shareContent);
        addToast({
          content: 'Copied to clipboard.',
          kind: 'success',
          duration: 'short',
          icon: <Icon name="checkBubbleFill24" color="foregroundSuccess" />,
        });
        break;
    }
  };

  switch (button?.__typename) {
    case 'AppButton': {
      return <AppButton {...appButtonProps} appButton={button} />;
    }
    case 'AppShareButton': {
      return (
        // TODO: figure out why this TS error is throwing
        /* @ts-expect-error - TS2322 - Type '"small" | "medium" | "large"' is not assignable to type '"small" | undefined'. */
        <Button
          {...{
            label: button.label ?? '',
            kind: readButtonKind(button.kind),
            size: readButtonSize(button.size),
            onClick: () =>
              handleAppShareButtonClick(
                button?.shareContent,
                button?.shareType,
              ),
            ...appShareButtonProps,
          }}
        />
      );
    }
    case 'AppLink': {
      return useLink ? (
        <LinkableLink
          {...{
            size: readButtonSize(button.size),
            ...linkLinkProps,
          }}
          linkable={button}
        />
      ) : (
        <LinkableButton
          {...{
            kind: readButtonKind(button.kind),
            size: readButtonSize(button.size),
            ...linkButtonProps,
          }}
          linkable={button}
        />
      );
    }
    default:
      return null;
  }
};

const readButtonKind = (kind?: AppButtonUnion['kind']): ButtonKind => {
  switch (kind) {
    case 'primary':
    case 'secondary':
    case 'link':
    case 'text':
    case 'inverse-primary':
    case 'inverse-secondary':
    case 'inverse-text':
    case 'm1-plus':
    case 'm1-plus-secondary':
    case 'm1-plus-text':
    case 'destructive':
    case 'destructive-text':
      return kind;
    default:
      return 'primary';
  }
};

const readButtonSize = (size?: AppButtonUnion['size']): ButtonSize => {
  switch (size) {
    case 'small':
    case 'medium':
    case 'large':
      return size;
    default:
      return 'medium';
  }
};
