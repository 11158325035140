import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { SpendMarketingPage } from './SpendMarketingPage';

export const CreditCardMarketingRoute = () => {
  return (
    <Route
      path="marketing"
      component={createRouteComponent(SpendMarketingPage)}
    />
  );
};
