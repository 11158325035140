import * as React from 'react';
import { Route } from 'react-router';

import { RewardsSingularProgramPage } from './RewardsSingularProgramPage';

export const RewardsSingularProgram = () => {
  return (
    <Route
      path="rewards/program/:programType/:programId"
      component={RewardsSingularProgramPage}
      lastVisited="/d/spend/credit/rewards"
      cover
    />
  );
};
