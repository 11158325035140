import { Flex, styled, keyframes, Button } from '@m1/liquid-react';
import * as React from 'react';

import { TruncateStringContent } from '~/components/TruncateStringContent';
import { printableAsciiChars } from '~/forms/validators';
import {
  AccountsPageDocument,
  useRenameAccountMutation,
} from '~/graphql/hooks';
import { useToast } from '~/toasts';
import { GridTable } from '~/toolbox/grid-table';
import { Input } from '~/toolbox/Input';

const EditBtnAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledEditButton = styled(Button)`
  animation-name: ${EditBtnAnimation};
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  opacity: 1;
`;

type RenameableAccountCellProps = {
  accountId: string;
  initialAccountName: string;
  isActive: boolean;
  onEditClick: (accountId: string) => void;
  showEdit: boolean;
  disallowEmoji?: boolean;
};

export const RenameableAccountCell = ({
  accountId,
  initialAccountName,
  showEdit,
  isActive,
  onEditClick,
  disallowEmoji,
}: RenameableAccountCellProps) => {
  const { addToast } = useToast();

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [isEditMode, setIsEditMode] = React.useState(false);
  const [newAccountName, setNewAccountName] =
    React.useState(initialAccountName);

  React.useEffect(() => {
    if (!isActive) {
      setNewAccountName(initialAccountName);
    }

    function handleExternalClick(event: MouseEvent) {
      const eventTarget = event.target as HTMLElement;

      const isChildTarget =
        wrapperRef.current?.contains?.(eventTarget) ?? false;

      if (isActive && wrapperRef.current && !isChildTarget) {
        setIsEditMode(false);
        setNewAccountName(initialAccountName);
      }
    }

    document.addEventListener('mousedown', handleExternalClick);

    return () => {
      document.removeEventListener('mousedown', handleExternalClick);
    };
  }, [initialAccountName, isActive]);

  // Set focus to the input when the user clicks the "Edit" button:
  React.useEffect(() => {
    if (isEditMode) {
      inputRef.current?.focus();
    }
  }, [isEditMode, inputRef]);

  const [renameAccountMutation, { loading }] = useRenameAccountMutation();

  const validateInput = (): boolean =>
    isEditMode &&
    Boolean(newAccountName) &&
    newAccountName !== initialAccountName &&
    newAccountName.length <= 50 &&
    (!disallowEmoji || typeof printableAsciiChars(newAccountName) !== 'string');

  const handleClick = async () => {
    onEditClick?.(accountId);

    if (!loading && validateInput()) {
      // TODO - differentiate between Checking and Savings
      await renameAccountMutation({
        variables: {
          input: { accountId, accountName: newAccountName },
        },
        refetchQueries: [{ query: AccountsPageDocument }],
        onCompleted(data) {
          if (!data.renameAccount.didSucceed) {
            addToast({
              content:
                'Unable to rename account. Please try again later or contact support.',
              kind: 'alert',
              duration: 'short',
            });
            return;
          }

          addToast({
            content: 'Your account was successfully renamed.',
            kind: 'success',
            duration: 'short',
          });

          setNewAccountName(data.renameAccount.outcome?.name ?? '');
        },
        onError() {
          addToast({
            content:
              'Something went wrong with your request. Please try again later or contact support.',
            kind: 'alert',
            duration: 'short',
          });

          // Ensure the input value is reset to the initial account name if an
          // error occurs. Otherwise, the account name still has the new name
          // until you click somewhere else on the page:
          setNewAccountName(initialAccountName);
        },
      });
    }

    setIsEditMode(!isEditMode);
  };

  return (
    <Flex ref={wrapperRef} alignItems="center">
      <GridTable.Cell
        // @ts-expect-error - TS2769 - No overload matches this call.
        padded={false}
        content={
          <Flex
            height="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            {isEditMode ? (
              <Flex height={40} flex="1 1 auto">
                <Input
                  ref={inputRef}
                  kind="basic"
                  size="medium"
                  value={newAccountName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setNewAccountName(event.currentTarget.value)
                  }
                />
              </Flex>
            ) : (
              <Flex alignItems="center" height={48} flex="1 1 auto">
                <TruncateStringContent style={{ maxWidth: 330 }}>
                  {newAccountName || initialAccountName}
                </TruncateStringContent>
              </Flex>
            )}
            {isEditMode || showEdit ? (
              <Flex height="100%" alignItems="center">
                <StyledEditButton
                  mt={2}
                  disabled={isEditMode ? !validateInput() : false}
                  ml={20}
                  size="small"
                  kind="secondary"
                  onClick={handleClick}
                  label={isEditMode ? 'Save' : 'Edit'}
                />
              </Flex>
            ) : null}
          </Flex>
        }
      />
    </Flex>
  );
};
