import * as React from 'react';

import { IndexRoute, Route } from 'react-router';

import { AccountSelectionForm } from './components/AccountSelectionForm';
import { WireTransferInstructions } from './components/WireTransferInstructions/WireTransferInstructions';
import { WireTransferProvider } from './providers/WireTransferProvider';

export const WireTransferRoute = () => (
  <Route
    path="wire-transfer"
    fallbackRoute="/d/move-money"
    component={WireTransferProvider}
    cover
  >
    <IndexRoute component={AccountSelectionForm} />
    <Route path="wire-instructions" component={WireTransferInstructions} />
  </Route>
);
