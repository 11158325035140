import { PL, Tooltip } from '@m1/liquid-react';
import * as React from 'react';

import { MyPieFragment } from '~/graphql/types';

import { StyledActionMenu, StyledTrigger } from './MyPie.styled';
import { onPieArchiveCallbackInput } from './types';

type PieActionsPopupProps = {
  onPieArchiveCallback: (arg0: onPieArchiveCallbackInput) => void;
  pie: NonNullable<MyPieFragment['node']>;
};

export const PieActionsPopup = ({
  pie,
  onPieArchiveCallback,
}: PieActionsPopupProps) => {
  const portfolioLinks = pie.portfolioLinks;

  if (portfolioLinks && portfolioLinks.length > 0) {
    return null;
  }

  return (
    <Tooltip
      placement="bottom-end"
      children={<StyledTrigger>⋮</StyledTrigger>}
      contentPadding="0px"
      trigger="click"
      body={
        <StyledActionMenu>
          <button
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();

              onPieArchiveCallback({
                isCrypto: pie.containsCrypto,
                pieId: pie.id,
                pieName: pie.name,
              });
            }}
          >
            <PL>Delete</PL>
          </button>
        </StyledActionMenu>
      }
    />
  );
};
