import {
  Box,
  Button,
  Flex,
  HM,
  PL,
  PM,
  PXL,
  styled,
  useThemeMode,
  Card,
} from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';
import { change } from 'redux-form';

import { MarketingAndDisclaimer } from '~/components/marketing-disclaimer/MarketingAndDisclaimer';
import { LoginFlow } from '~/flows';
import { useEnvironment } from '~/hooks/useEnvironment';
import { useLocation } from '~/hooks/useLocation';
import { useOnMount } from '~/hooks/useOnMount';
import { useSupportLink } from '~/hooks/useSupportLink';
import type { AppState } from '~/redux';
import { enteredPage, exitedPage } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import {
  LOGIN_FLOW_STEPS as STEPS,
  RESPONSIVE_BREAKPOINTS,
  ENVIRONMENT_OPTIONS,
} from '~/static-constants';
import { Container } from '~/toolbox/container';
import { Link } from '~/toolbox/link';
import { M1Logo } from '~/toolbox/M1Logo';

import { LoginMarketing } from './components/LoginMarketing';
import { UserPicker } from './components/UserPicker';

export const StyledContainer = styled(Box)`
  padding-top: 64px;
  background-color: ${({ theme }) => theme.colors.backgroundNeutralSecondary};
  height: 100vh;
  overflow: scroll;
  @media screen and ${RESPONSIVE_BREAKPOINTS.SMALL} {
    padding-top: 0;
  }
`;

export const StyledBackgroundImage = styled(Box)`
  position: absolute;
  width: 100%;

  @media screen and ${RESPONSIVE_BREAKPOINTS.SMALL} {
    top: 18%;
  }
`;

type LoginFlowProps = {
  changeStep: (arg0: string) => void;
  step: ValueOf<typeof STEPS>;
  stepElement: React.ReactElement<any>;
};

const LoginColumn = styled(Flex)`
  flex-direction: column;
`;

const useGetDynamicSupportLink = (): React.ReactNode | null | undefined => {
  const supportLink = useSupportLink('SUPPORT_2FA');

  const showTwoFactorSupportLink = useSelector<boolean>((state: AppState) => {
    return Boolean(
      state.newFlows.login.step === 'COLLECT_AMFA_CODE' &&
        state.newFlows.login.error === 'USER_ACCOUNT_LOCKED',
    );
  });

  if (showTwoFactorSupportLink) {
    return (
      <Link pt={4} {...supportLink}>
        <PM content="Contact support" fontWeight={600} />
      </Link>
    );
  }

  return null;
};

export const LoginPage = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const [environment, setEnvironment] = useEnvironment();
  const isProduction =
    __NODE_ENV__ === 'production' && __ENV__ === 'production';
  const { activeThemeMode } = useThemeMode();

  React.useEffect(() => {
    !isProduction &&
      dispatch(change('login-form', 'environment', environment.label));
  }, [isProduction, environment, dispatch]);

  React.useEffect(() => {
    if (location.query.lens) {
      const envValue = location.query.lens
        .replace('/console', '')
        .replace(/\/+$/, '');
      // If environment is passed in by name or matching URL, find the match and use that
      const env = ENVIRONMENT_OPTIONS.find(
        (e) => e.value === envValue || e.label === envValue,
      );
      if (env) {
        setEnvironment(env);
      } else {
        setEnvironment({
          label: 'custom',
          value: envValue,
        });
      }
    }
  }, [location.query.lens, setEnvironment]);

  useOnMount(() => {
    dispatch(enteredPage('LOGIN', undefined));
    return () => {
      dispatch(exitedPage('LOGIN', undefined));
    };
  });

  const renderBottomLink = ({ changeStep, step }: LoginFlowProps) => {
    switch (step) {
      case STEPS.COLLECT_AMFA_CODE:
      case STEPS.COLLECT_TWO_FACTOR_AUTH_CODE:
        return (
          <PL textAlign="center">
            <Button
              kind="link"
              onClick={() => changeStep(STEPS.COLLECT_CREDENTIALS)}
              label="Back to login"
            />
          </PL>
        );
      default:
        return (
          <PL color="foregroundNeutralMain" textAlign="center">
            Don't have an account? <Link to="/signup">Sign up</Link>
          </PL>
        );
    }
  };

  const dynamicContactSupportLink = useGetDynamicSupportLink();

  return (
    <StyledContainer>
      <StyledBackgroundImage>
        <Illustration name="signupLoginWhisp" width="100%" opacity="0.5" />
      </StyledBackgroundImage>
      <LoginFlow>
        {(props: LoginFlowProps) => {
          return (
            <Container>
              <Flex justifyContent="center" pb={32}>
                <Flex flexDirection="column">
                  <Box pb={32} mx="auto">
                    <Link to="https://www.m1.com/">
                      <M1Logo width={42} />
                    </Link>
                  </Box>
                  <HM
                    color="foregroundNeutralMain"
                    content="Welcome back!"
                    pb={16}
                  />
                </Flex>
              </Flex>
              <Flex flexWrap="wrap" pb={104} justifyContent="space-evenly">
                <LoginColumn zIndex={5} width={430} mb={16}>
                  {props.step === 'COLLECT_AMFA_CODE' && (
                    <>
                      <HM
                        color="secondary"
                        content="We emailed you a 6-digit security code."
                        pt={16}
                        textAlign="center"
                      />
                      <HM
                        color="secondary"
                        content="Please enter it below."
                        pb={8}
                        textAlign="center"
                      />
                      <PXL
                        color="secondary"
                        content="Your code expires in 5 minutes."
                        fontWeight={400}
                        pt={32}
                        pb={64}
                        textAlign="center"
                      />
                    </>
                  )}
                  <Card
                    position="relative"
                    isElevated
                    p={32}
                    backgroundColor="backgroundNeutralSecondary"
                  >
                    {props.stepElement}
                    <Box pt={24} textAlign="center">
                      {renderBottomLink(props)}
                    </Box>
                  </Card>
                  {!isProduction &&
                    props.step !== 'COLLECT_AMFA_CODE' &&
                    props.step !== 'COLLECT_TWO_FACTOR_AUTH_CODE' && (
                      <UserPicker
                        users={location.query.users ?? location.query.user}
                      />
                    )}
                </LoginColumn>
                {![
                  'COLLECT_AMFA_CODE',
                  'COLLECT_TWO_FACTOR_AUTH_CODE',
                ].includes(props.step) && (
                  <LoginColumn zIndex={0}>
                    <LoginMarketing />
                  </LoginColumn>
                )}
              </Flex>
              <MarketingAndDisclaimer
                themeMode={activeThemeMode}
                dynamicContactSupportLink={dynamicContactSupportLink}
              />
            </Container>
          );
        }}
      </LoginFlow>
    </StyledContainer>
  );
};
