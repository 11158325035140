import * as React from 'react';
import { LocationState, withRouter } from 'react-router';

import { compose } from '~/hocs';
import { navigable } from '~/router';
import { LinkProps } from '~/toolbox/link/Link.types';
import { PillProps } from '~/toolbox/Pill';
import { Tabs } from '~/toolbox/tabs';

// TODO: Pull this out into some common util.
const enhancer = compose<any, any>(
  withRouter,
  navigable(),
  // @ts-expect-error - TS7006 - Parameter 'Component' implicitly has an 'any' type.
  (Component) =>
    // @ts-expect-error - TS7031 - Binding element 'router' implicitly has an 'any' type. | TS7031 - Binding element 'to' implicitly has an 'any' type.
    ({ router, to, ...rest }) => (
      <Component
        {...rest}
        isActive={router.isActive(to)}
        onClick={(event: React.MouseEvent) => {
          if (event.defaultPrevented) {
            return;
          }
          if (rest.target) {
            return;
          }
          event.preventDefault();
          router.push(to);
        }}
      />
    ),
);

const NavigableTab = enhancer(Tabs.Tab);

export type SecondaryNavigationItemProps = Pick<LinkProps, 'to'> & {
  label: string;
  pill?: PillProps;
  state?: LocationState;
};

export type SecondaryNavigationProps = {
  items: SecondaryNavigationItemProps[];
};

export const SecondaryNavigation = ({
  items,
  ...rest
}: SecondaryNavigationProps) => (
  <Tabs as="nav" {...rest}>
    {items.map((item) => (
      <NavigableTab key={item.label} {...item} />
    ))}
  </Tabs>
);
