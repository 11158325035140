import { Flex, Box, PM, styled } from '@m1/liquid-react';
import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { ControlledInput } from '~/components/form/ControlledInput';
import { TextField } from '~/components/form/TextField';
import { PhoneNumberField } from '~/forms/fields/PhoneNumberField';
import {
  confirmEmailMatching,
  jointAccountFormEmailValidator,
} from '~/forms/validators';
import { AppButtonFragment, SavingsCoOwnerInformation } from '~/graphql/types';
import { AppButton } from '~/lens-toolbox/AppButton';
import { FIELD_MAX_LENGTHS } from '~/static-constants';

type SavingsAccountInvitationFormProps = {
  initialValues: SavingsCoOwnerInformation | null;
  primaryCtaButton: AppButtonFragment | null | undefined;
  primaryUserEmail: string | undefined;
  primaryUserPhoneNumber: string | undefined;
  onSubmit: (formValues: Record<string, any>) => void;
};

export type SavingsAccountInvitationFormValues = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  confirmEmail?: string;
};

const StyledForm = styled.form`
  width: 100%;
`;

const isProduction = __NODE_ENV__ === 'production' && __ENV__ === 'production';
const mockEmail = `pandas+roomate${Math.floor(
  Math.random() * 10000,
)}@example.com`;
const mockFormData: SavingsAccountInvitationFormValues = {
  'firstName': 'Bob',
  'lastName': 'Ross',
  'phone': '8158235622',
  'email': mockEmail,
  'confirmEmail': mockEmail,
};

export const SavingsAccountInvitationForm = ({
  initialValues,
  primaryCtaButton,
  primaryUserEmail,
  primaryUserPhoneNumber,
  onSubmit,
}: SavingsAccountInvitationFormProps) => {
  const hasInitialValues = Boolean(initialValues);
  const defaultValues = initialValues
    ? {
        firstName: initialValues.firstName,
        lastName: initialValues.lastName,
        phone: initialValues.phoneNumber,
        email: initialValues.email,
        confirmEmail: initialValues.email,
      }
    : {};
  const formMethods = useForm<SavingsAccountInvitationFormValues>({
    defaultValues,
  });
  const {
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields },
    trigger,
  } = formMethods;
  const email = watch('email');
  const confirmEmail = watch('confirmEmail');
  const phone = watch('phone');
  const disableSubmit = hasInitialValues
    ? false
    : Object.keys(dirtyFields).length < 5;

  React.useEffect(() => {
    if (email) {
      trigger('email');
    }

    if (phone) {
      trigger('phone');
    }
    /*
       If there was an error on the confirmEmail input based on the first email input (aka email doesn't match)
       and the error has been fixed the error message needs to clear.
       This tells the form that when there are values for the 2 email inputs (and these values are changing),
       trigger validation for the confirmEmail
    */
    if (email && confirmEmail) {
      trigger('confirmEmail');
    }
  }, [email, confirmEmail, phone, trigger]);

  return (
    <FormProvider {...formMethods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDirection="column" maxWidth={588}>
          <ControlledInput
            control={control}
            name="firstName"
            label="Co-owner’s first name"
          />
          <ControlledInput
            control={control}
            name="lastName"
            label="Co-owner’s last name"
          />
          <PhoneNumberField
            label="Co-owner’s phone number"
            error={errors.phone?.message}
            primaryUserPhoneNumber={primaryUserPhoneNumber}
          />
          <TextField
            name="email"
            type="email"
            label="Co-owner’s email"
            control={control}
            maxLength={FIELD_MAX_LENGTHS.USERNAME}
            error={errors.email?.message}
            rules={{
              required: 'Required.',
              validate: {
                email: (value) =>
                  value
                    ? jointAccountFormEmailValidator(
                        value,
                        email,
                        primaryUserEmail,
                      )
                    : undefined,
              },
            }}
          />
          <TextField
            name="confirmEmail"
            type="email"
            label="Confirm co-owner’s email"
            control={control}
            maxLength={FIELD_MAX_LENGTHS.USERNAME}
            error={errors.confirmEmail?.message}
            rules={{
              required: 'Required.',
              validate: {
                confirmEmail: (value) =>
                  value ? confirmEmailMatching(value, email) : undefined,
              },
            }}
          />
          <Box mt={40}>
            {primaryCtaButton && (
              <AppButton
                type="submit"
                disabled={disableSubmit}
                appButton={primaryCtaButton}
                size="large"
              />
            )}
          </Box>
          {!isProduction && !hasInitialValues && (
            <PM
              color="primary"
              cursor="pointer"
              content="Mock this form!"
              onClick={() => {
                Object.keys(mockFormData).forEach((key) =>
                  // @ts-ignore - not typing the mocker
                  setValue(key, mockFormData[key], {
                    shouldValidate: true,
                    shouldDirty: true,
                  }),
                );
              }}
              pt={8}
            />
          )}
        </Flex>
      </StyledForm>
    </FormProvider>
  );
};
