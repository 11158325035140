import {
  Box,
  Flex,
  Maybe,
  PL,
  PM,
  SemanticColorNames,
  Skeleton,
  TabularDataSet,
  styled,
} from '@m1/liquid-react';
import * as React from 'react';

import { AppTooltipFragment } from '~/graphql/types';

import { Progress } from '~/toolbox/progress';
import { CashFormatter } from '~/utils';

import { AppTooltip } from '../../lens-toolbox/AppTooltip';

type GaugeBound = {
  value: number;
  label: string;
  color: SemanticColorNames;
  tooltip?: AppTooltipFragment | null;
};

type LinearGaugeProps = {
  value: number;
  valueIndicator: {
    foregroundColor: Maybe<SemanticColorNames>;
    backgroundColor: Maybe<SemanticColorNames>;
  };
  middleBound: GaugeBound;
  upperBound: GaugeBound;
  format?: 'currency' | 'percentage';
};

const StyledGaugeItem = styled(Box)`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderMain};
  justify-content: space-between;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
`;

const GaugeItem = ({
  label,
  value,
}: {
  label: React.ReactNode;
  value: React.ReactNode;
}) => (
  <StyledGaugeItem font="PM" fontWeight={400} py={14} flexWrap="wrap">
    <Skeleton color="foregroundNeutralSecondary" skeletonWidth="20%">
      {label}
    </Skeleton>
    <Skeleton
      color="foregroundNeutralMain"
      textAlign="right"
      skeletonWidth="20%"
    >
      {value}
    </Skeleton>
  </StyledGaugeItem>
);

const LinearGaugeLabel = ({
  label,
  color,
}: {
  label: string;
  color: SemanticColorNames;
}) => (
  // a circle with background color of color and then the label as PM.400 in foregroundNeutralSecondary color
  <Flex alignItems="center">
    <Box
      borderRadius="50%"
      backgroundColor={color}
      width={16}
      height={16}
      mr={16}
    />
    <PM fontWeight={400} color="foregroundNeutralSecondary">
      {label}
    </PM>
  </Flex>
);

export const LinearGauge = ({
  value,
  valueIndicator,
  middleBound,
  upperBound,
  format = 'currency',
}: LinearGaugeProps) => {
  const [isNarrow, setIsNarrow] = React.useState(false);
  const boxRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    const checkSize = () => {
      if (boxRef.current) {
        setIsNarrow(boxRef.current.offsetWidth < 320);
      }
    };

    window.addEventListener('resize', checkSize);

    // Check the size on initial render
    checkSize();

    // Cleanup the event listener on unmount
    return () => window.removeEventListener('resize', checkSize);
  }, []);

  const data = [
    {
      label: (
        <>
          <LinearGaugeLabel
            color={middleBound.color}
            label={middleBound.label}
          />
          {middleBound.tooltip && (
            <AppTooltip appTooltip={middleBound.tooltip} />
          )}
        </>
      ),
      data: (
        <PL fontWeight={400} ml={16 + 16}>
          {CashFormatter.format(middleBound.value)}
        </PL>
      ),
    },
    {
      label: (
        <>
          <LinearGaugeLabel label={upperBound.label} color={upperBound.color} />
          {upperBound.tooltip && <AppTooltip appTooltip={upperBound.tooltip} />}
        </>
      ),
      data: (
        <PL fontWeight={400} ml={16 + 16}>
          {CashFormatter.format(upperBound.value)}
        </PL>
      ),
    },
  ];

  const percent = isNaN(middleBound.value / upperBound.value)
    ? 1
    : middleBound.value / upperBound.value;

  return (
    <Box ref={boxRef} maxWidth={546}>
      <Box mb={16} mt={64}>
        <Progress
          percent={percent * 100}
          height={24}
          borderRadius={12}
          borderColor="backgroundNeutralTertiary"
          borderWidth={3}
          color={middleBound.color}
          backgroundColor={upperBound.color}
          curvedProgressIndicator={false}
          secondaryIndicator={(value / upperBound.value) * 100}
          secondaryIndicatorLabel={{
            label: (
              <PM fontWeight={400}>
                {format === 'currency'
                  ? CashFormatter.format(value)
                  : `${value}%`}
              </PM>
            ),
            foregroundColor:
              valueIndicator.foregroundColor ?? 'foregroundNeutralOnDark',
            backgroundColor:
              valueIndicator.backgroundColor ?? 'backgroundFeatureFlat',
          }}
        />
      </Box>
      {isNarrow ? (
        <>
          {data.map((datum, i) => (
            <GaugeItem key={i} label={datum.label} value={datum.data} />
          ))}
        </>
      ) : (
        <Box maxWidth={340}>
          <TabularDataSet forceFirstBorder={false} tabularData={data} />
        </Box>
      )}
    </Box>
  );
};
