import { Box, HM } from '@m1/liquid-react';
import React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { SystemPieHeaderContainer } from '~/components/pie/PieDetailsPage/SystemPieHeaderContainer';
import { usePieDetailsPageQuery } from '~/graphql/hooks';
import { PieDetailsNodeFragment, PieBacktestPeriodEnum } from '~/graphql/types';
import { useLocation } from '~/hooks/useLocation';
import { Grid } from '~/toolbox/grid';
import { Spinner } from '~/toolbox/spinner';

import { PieDetailsCard } from '../PieDetailsCard/PieDetailsCard';

import { PieDetailsContext } from './hooks/usePieDetailsContext';
import { ChartInfo, PieDetailsProviderProps } from './PieDetailsPage.types';
import { PieGraphSection } from './PieGraphSection';
import { PieLinkedLocationSection } from './PieLinkedLocationSection';
import { PieMethodologySection } from './PieMethodologySection';
import { PieProfileSection } from './PieProfileSection';
import { PieSliceTable } from './PieSliceTable';

export const PieDetailsPage = ({
  pieId,
  showPortfolioLinks = true,
  ...rest
}: React.PropsWithChildren<PieDetailsProviderProps>) => {
  const { pathname } = useLocation();
  const [chartInfo, setChartInfo] = React.useState<ChartInfo | null>(null);
  const queryResult = usePieDetailsPageQuery({
    variables: {
      period: PieBacktestPeriodEnum.FiveYears,
      pieId,
      showPortfolioLinks,
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });

  const pie = queryResult.data?.node as
    | PieDetailsNodeFragment
    | null
    | undefined;

  if (queryResult.loading) {
    return (
      <Box p={20}>
        <Spinner />
      </Box>
    );
  }

  if (!pie || (!pie && queryResult.error)) {
    return <GenericSystemError />;
  }

  const backRoute = pathname.includes('add-slices')
    ? '/d/c/add-slices/my-pies'
    : '/d/research/my-pies';

  return (
    <PieDetailsContext.Provider
      value={{ queryResult, pie, chartInfo, setChartInfo, ...rest }}
    >
      <Box>
        <BackArrow mt={32} to={backRoute} content="Back to my pies" />
        {pie.__typename === 'SystemPie' && (
          <SystemPieHeaderContainer pie={pie} />
        )}
        <Grid>
          <Grid.Row>
            <Grid.Col
              sm={5}
              xs={12}
              css={{
                padding: '32px 32px 32px 0',
              }}
            >
              <PieDetailsCard />
            </Grid.Col>
            <Grid.Col
              sm={7}
              xs={12}
              css={{
                paddingTop: 32,
              }}
            >
              <Grid.Row css={{ paddingBottom: 16 }}>
                {pie.isOwnPie && (
                  <Grid.Col
                    xs={7}
                    css={{
                      paddingBottom: 16,
                    }}
                  >
                    <HM content={pie.name} />
                  </Grid.Col>
                )}
              </Grid.Row>
              {!rest.disablePerformance && <PieGraphSection />}
              <PieProfileSection />
              <PieMethodologySection />
              <PieLinkedLocationSection />
              <PieSliceTable />
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Box>
    </PieDetailsContext.Provider>
  );
};
