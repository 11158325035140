import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { InvestMarketingScreen } from './InvestMarketingScreen';

export const InvestMarketingRoute = () => {
  return (
    <Route
      path="invest-marketing"
      component={createRouteComponent(InvestMarketingScreen)}
    />
  );
};
