/* eslint-disable no-return-assign */

import { PL, HM, Maybe } from '@m1/liquid-react';
import classNames from 'classnames';
import pick from 'lodash-es/pick';
import React, { Component } from 'react';
import AutosizeInput from 'react-input-autosize';

import style from './style.module.scss';

export type AmountInputProps = {
  autoComplete?: string;
  autoFocus?: boolean;
  error: string;
  subtitle?: Maybe<string>;
  maxLength?: number;
  onChange: (...args: Array<any>) => any;
  style: Record<string, any>;
  tabIndex?: number;
  value: string;
};

export class AmountInput extends Component<AmountInputProps> {
  // @ts-expect-error - TS2564 - Property 'input' has no initializer and is not definitely assigned in the constructor.
  input: HTMLInputElement;

  render() {
    const classes = classNames(style.root, {
      [style.error]: this.props.error,
    });
    return (
      <div className={classes} style={this.props.style}>
        <div className={style.inputWrapper}>
          <HM color={this.props.error ? 'critical' : undefined} content="$" />
          <AutosizeInput {...this.collectInputProps()} placeholder="0" />
        </div>
        <div
          style={{
            height: 60,
          }}
        >
          {this.props.error ? (
            <PL color="critical" content={this.props.error} />
          ) : (
            <PL color="foregroundNeutralMain" content={this.props.subtitle} />
          )}
        </div>
      </div>
    );
  }

  collectInputProps = (): Record<string, any> => ({
    ...pick(this.props, [
      'autoComplete',
      'autoFocus',
      'disabled',
      'id',
      'name',
      'onBlur',
      'onFocus',
      'onChange',
      'onKeyDown',
      'maxLength',
      'placeholder',
      'readOnly',
      'tabIndex',
      'type',
      'value',
    ]),
    className: style.input,
  });
}
