import * as React from 'react';
import { IndexRedirect, Redirect, Route, formatPattern } from 'react-router';

import { RenderFailurePage } from '~/components/render-failure-page';
import { AnalyticsReporter } from '~/loggers';
import { ForgotPasswordRoute } from '~/pages/forgot-password';
import { LoginRoute } from '~/pages/login';
import { LogoutRoute } from '~/pages/logout';
import { MaintenanceRoute } from '~/pages/maintenance';
import { NotFoundRoute } from '~/pages/not-found';
import { PlaidRedirectRoute } from '~/pages/plaid-redirect';
import { ResetPasswordRoute } from '~/pages/reset-password';
import { SharePieRoute } from '~/pages/share';
import { SignupRoute } from '~/pages/signup';
import { VerifyEmailRoute } from '~/pages/verify-email';
import { RouteHandlers } from '~/router/configure-router';
import { buildRoute } from '~/utils/route';

import { AppPage } from './AppPage';
import { DashboardRoute } from './dashboard';
import { OnboardingRoute } from './onboarding';
import { PersonalLoanDirectWizardRoute } from './personal-loan-direct';
import { PersonalLoanApplicationErrorRoute } from './personal-loan-direct/error-routes/PersonalLoanErrorRoute';
import { StartRoute } from './start';

export const AppRoute = (
  analytics: AnalyticsReporter,
  routeHandlers: RouteHandlers,
) => {
  let hasRenderedOnce = false;

  // "state" here is history state, not Redux state.
  const logPageView = (state: any): void => {
    const route = collectRouteFromState(state);

    // special case for reset-password page to avoid leaking token
    // for reset-password, don't send pageView at all.
    if (route.indexOf('reset-password') === -1) {
      analytics.pageView(route);
    }
  };

  return (
    <Route
      path="/"
      component={AppPage}
      // @ts-expect-error - TS7031 - Binding element 'props' implicitly has an 'any' type. | TS7031 - Binding element 'done' implicitly has an 'any' type. | TS7031 - Binding element 'error' implicitly has an 'any' type.
      render={({ props, done, error }) => {
        if (error && !hasRenderedOnce) {
          return <RenderFailurePage />;
        }

        if (done || hasRenderedOnce) {
          hasRenderedOnce = true;
          return <AppPage {...props} />;
        }
      }}
      onEnter={(nextState: any) => logPageView(nextState)}
      onChange={(_: any, nextState: any) => logPageView(nextState)}
    >
      <IndexRedirect to="d" />
      {ForgotPasswordRoute(routeHandlers)}
      {DashboardRoute(routeHandlers)}
      {LoginRoute(routeHandlers)}
      {LogoutRoute(routeHandlers)}
      {MaintenanceRoute()}
      {OnboardingRoute(routeHandlers)}
      {StartRoute(routeHandlers)}
      {PlaidRedirectRoute()}
      {ResetPasswordRoute()}
      {SharePieRoute()}
      {SignupRoute(routeHandlers)}
      {VerifyEmailRoute()}
      {PersonalLoanDirectWizardRoute(routeHandlers)}
      {PersonalLoanApplicationErrorRoute()}
      <Redirect from="d/funding" to="d/invest/funding" />
      <Redirect from="d/funding/*" to="d/invest/funding" />
      <Redirect from="activate-your-card" to="d/c/activate-credit-card" />
      <Redirect from="d/open-invest-account" to="d/c/open-account" />
      <Redirect from="savings-onboarding" to="onboarding/savings-onboarding" />
      {NotFoundRoute()}
    </Route>
  );
};

// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
function collectRouteFromState(state): string {
  const routeParts = state.routes
    // @ts-expect-error - TS7006 - Parameter 'route' implicitly has an 'any' type.
    .map((route) => {
      let { path } = route;
      if (route.unmask) {
        path = formatPattern(path, state.params);
      }
      if (route.includeSearchParams) {
        path = path.concat(state.location.search);
      }
      return path;
    })
    .filter(Boolean);

  return buildRoute([...routeParts]);
}
