import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { CustodialBeneficiaryPage } from './components/CustodialBeneficiary';

export const CustodialBeneficiaryRoute = () => {
  return (
    <Route
      path="custodial-beneficiary"
      component={createRouteComponent(CustodialBeneficiaryPage)}
    />
  );
};
