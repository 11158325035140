import { Button } from '@m1/liquid-react';
import * as React from 'react';

import {
  Controller,
  ControllerProps,
  FieldValues,
  Path,
} from 'react-hook-form';

import { InputProps } from '~/toolbox/Input';

import { TransferFrequencyEnum } from '../../steps/TransferDetails.types';

import { StyledTransferRadioContainer } from './TransferScheduleOptions.styled';
import { FREQUENCY_TO_LABEL } from './TransferScheduleOptions.utils';

type TransferScheduleRadioGroupProps<
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues = FieldValues,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  InputProps & {
    onFrequencyChange: (freq: TransferFrequencyEnum) => void;
  };

export const TransferScheduleRadioGroup = <
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues,
>({
  control,
  name,
  rules,
  disabled,
  onFrequencyChange,
}: TransferScheduleRadioGroupProps<TName, TFieldValues>) => {
  return (
    <Controller
      {...{
        control,
        name,
        rules,
      }}
      render={({ field }) => (
        <>
          {Object.values(TransferFrequencyEnum).map(
            (f: TransferFrequencyEnum) => (
              <StyledTransferRadioContainer key={f}>
                <Button
                  kind={field.value === f ? 'inverse-primary' : 'text'}
                  label={FREQUENCY_TO_LABEL[f]}
                  disabled={disabled}
                  onClick={() => {
                    if (!disabled) {
                      field.onChange(f);
                      onFrequencyChange?.(f);
                    }
                  }}
                  size="medium"
                >
                  {FREQUENCY_TO_LABEL[f]}
                </Button>
              </StyledTransferRadioContainer>
            ),
          )}
        </>
      )}
    />
  );
};
