import * as React from 'react';
import { Route } from 'react-router';

import { SaveMarketingPage } from '~/pages/save/marketing/SaveMarketingPage';
import { createRouteComponent } from '~/router';

export const SaveMarketingRoute = () => {
  return (
    <Route
      path="marketing"
      component={createRouteComponent(SaveMarketingPage)}
    />
  );
};
