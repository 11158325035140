import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { PositionDetails } from '~/components/invest/PositionDetails/PositionDetailsRenderer';
import { InjectedRouteProps } from '~/router';

type PositionDetailsPageProps = InjectedRouteProps & {
  params: {
    positionId: string;
  };
};

export const PositionDetailsPage = ({
  params,
  router,
}: PositionDetailsPageProps) => {
  return (
    <Box mx="auto" pt={64} maxWidth={780}>
      <BackArrow content="Holdings" mb={16} onClick={() => router.goBack()} />
      <PositionDetails positionId={params.positionId} />
    </Box>
  );
};
