import { RouteActions as ReactRouterRouteActions } from 'react-router-redux';

export const ROUTING_ACTIONS = Object.freeze({
  '@@router/LOCATION_CHANGE': '@@router/LOCATION_CHANGE' as const,
});

type RouteTypeAction<A extends keyof ReactRouterRouteActions> =
  ReactRouterRouteActions[A] & {
    type?: '@@router/LOCATION_CHANGE';
  };

export type RouteAction =
  | RouteTypeAction<'push'>
  | RouteTypeAction<'replace'>
  | RouteTypeAction<'go'>
  | RouteTypeAction<'goBack'>
  | RouteTypeAction<'goForward'>;
