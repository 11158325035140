import { Box, Flex, LS, PM, PS, PXL, Tooltip } from '@m1/liquid-react';
import React from 'react';

import { Gain } from '~/components/gain';
import { PercentGain } from '~/components/percent-gain';
import {
  AccountOriginatorEnum,
  BorrowAccountFragment,
  InvestmentsFragment,
} from '~/graphql/types';
import { useSelector } from '~/redux/hooks';
import { Link } from '~/toolbox/link';
import { CashFormatter } from '~/utils';

import { Downloads } from './Downloads';
import { PartialTotalInvestmentsCostPopup } from './PartialTotalInvestmentsCostPopup';
import { PartialTotalInvestmentsValuePopup } from './PartialTotalInvestmentsValuePopup';

const readCurrency = (value: number | null | undefined) => {
  return typeof value === 'number' ? CashFormatter.format(value) : '--';
};

type InvestmentTableInfoSectionProps = {
  accountOriginator: AccountOriginatorEnum | null;
  investments: InvestmentsFragment | null | undefined;
  borrowAccount?: BorrowAccountFragment['borrowAccount'];
};

export const InvestmentTableInfoSection = ({
  accountOriginator,
  investments,
  borrowAccount,
}: InvestmentTableInfoSectionProps) => {
  const qtyLabel = useSelector((state) =>
    state.global.activeAccountIsCrypto ? 'Quantity' : 'Shares',
  );

  const renderBorrowTotal = (
    label: React.ReactNode,
    value: React.ReactNode,
    tooltip?: React.ReactNode,
  ) => (
    <Flex alignItems="center" justifyContent="space-between" mb={4}>
      <Flex alignItems="center">
        <PS color="foregroundNeutralMain" content={label} mr={4} />
        {tooltip}
      </Flex>
      <LS as="div" content={<b>{value}</b>} ml={8} />
    </Flex>
  );

  function readTotalCost() {
    if (typeof investments?.totalCost?.cost !== 'number') {
      return (
        <PartialTotalInvestmentsCostPopup placement="bottom-start" label="--" />
      );
    } else if (investments.totalCost.isPartial) {
      return (
        <PartialTotalInvestmentsCostPopup
          placement="bottom-start"
          label={CashFormatter.format(investments.totalCost.cost)}
        />
      );
    }

    return CashFormatter.format(investments.totalCost.cost);
  }

  const totalUnrealizedGain = !investments?.totalUnrealizedGain ? (
    '--'
  ) : (
    <Flex whiteSpace="nowrap">
      <Gain value={investments?.totalUnrealizedGain.gain} />
      <PercentGain
        includeParenthesis
        value={investments?.totalUnrealizedGain.gainPercent}
      />
    </Flex>
  );

  function readTotalValue() {
    if (typeof investments?.totalValue?.value !== 'number') {
      return (
        <PartialTotalInvestmentsValuePopup placement="bottom" label="--" />
      );
    } else if (investments.totalValue.isPartial) {
      return (
        <PartialTotalInvestmentsValuePopup
          placement="bottom"
          label={CashFormatter.format(investments.totalValue.value)}
        />
      );
    }

    return CashFormatter.format(investments.totalValue.value);
  }

  const renderTotal = (
    label: string,
    value: React.ReactNode,
    Popup?: React.ReactNode,
  ) => (
    <Box mr={64}>
      <Flex alignItems="center" justifyContent="space-between">
        <LS color="foregroundNeutralMain" content={label} mb={4} mr={8} />
        {Popup && Popup}
      </Flex>

      <PXL as="div" fontWeight={600} content={value} />
    </Box>
  );

  const positionTotal =
    typeof investments?.positions.total === 'number'
      ? investments.positions.total
      : '--';

  const creditBorrowed = readCurrency(borrowAccount?.creditBorrowed);
  const marginEquity = readCurrency(borrowAccount?.status?.marginEquity);
  const requiredEquity = readCurrency(
    borrowAccount?.status?.requiredMarginEquity,
  );

  const excessEquity =
    typeof borrowAccount?.status?.excessMarginEquity === 'number' ? (
      <Gain value={borrowAccount.status.excessMarginEquity} />
    ) : (
      '--'
    );

  return (
    <Flex mb={16} mt={32} position="relative" flexDirection="column">
      <Flex flexDirection="row">
        <Flex alignItems="flex-start">
          {renderTotal('Positions', positionTotal)}
          {renderTotal(
            'Cost basis',
            readTotalCost(),
            <Tooltip
              placement="bottom-start"
              icon="tooltip16"
              offset={[-8, 4]}
              iconColor="foregroundPrimary"
              body={
                <PM
                  content="Cost basis displayed on this screen may have certain limitations and should not be utilized for tax purposes. This information is general in nature and should not be considered tax advice."
                  m="8px 12px"
                />
              }
            />,
          )}
          {renderTotal('Value', readTotalValue())}
          {renderTotal('Unrealized gain', totalUnrealizedGain)}
        </Flex>
        {borrowAccount?.hasCreditBorrowed && (
          <Flex justifyContent="center" flexDirection="column">
            <Flex justifyContent="flex-end">
              <Flex flexDirection="column">
                {renderBorrowTotal('Total borrowed', creditBorrowed)}
                {renderBorrowTotal('Total equity value', marginEquity)}
              </Flex>
              <Flex flexDirection="column" ml={8}>
                {renderBorrowTotal(
                  'Required equity',
                  requiredEquity,
                  <Tooltip
                    placement="bottom-end"
                    icon="tooltip16"
                    iconColor="foregroundPrimary"
                    offset={[-8, 4]}
                    body={
                      <PM
                        content="The minimum amount of equity that must be maintained when leveraged with M1 Borrow."
                        m="8px 12px"
                      />
                    }
                  />,
                )}

                {renderBorrowTotal('Excess equity', excessEquity)}
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex alignItems="end" flexBasis="auto" flexWrap="wrap">
        <Flex flex={1}>
          <Link
            mt={24}
            to="https://help.m1.com/en/articles/9331949-download-and-view-realized-gains#h_01HPQCJGAYVWR9ZF7G0Q3BM2WZ"
            target="_blank"
            rel="noopener noreferrer"
          >
            Understanding cost basis and average price
          </Link>
        </Flex>
        <Flex
          flex={1}
          justifyContent="flex-end"
          style={{
            fontSize: 14,
          }}
        >
          {investments && (
            <Downloads
              accountOriginator={accountOriginator}
              investments={investments}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
