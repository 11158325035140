import React from 'react';

import {
  TransferParticipantSideEnum,
  TransferWizardParticipantDetailsFragment,
} from '~/graphql/types';

import { assertAllCasesHandled } from '~/utils/switchStatements';

import { CreateTransferContext } from '../CreateTransferProvider';
import { TransferFrequencyEnum } from '../steps/TransferDetails.types';

export function useParticipantSummary({
  sourceId,
  destinationId,
  frequency,
  anchoredSide = TransferParticipantSideEnum.From,
}: {
  sourceId: string | null | undefined;
  destinationId: string | null | undefined;
  frequency: TransferFrequencyEnum | null | undefined;
  anchoredSide: TransferParticipantSideEnum | undefined;
}): {
  sourceDetails: TransferWizardParticipantDetailsFragment | null;
  destinationDetails: TransferWizardParticipantDetailsFragment | null;
  sourceDetailsList: TransferWizardParticipantDetailsFragment[];
  destinationDetailsList: TransferWizardParticipantDetailsFragment[];
  isSelectedRelationshipEligible: boolean;
} {
  const transferContext = React.useContext(CreateTransferContext);

  const participantGroups =
    transferContext?.data?.viewer?.transfers?.participantGroups;

  const result = React.useMemo(() => {
    const participantGroupKey =
      frequency === TransferFrequencyEnum.OneTime ? 'oneTime' : 'scheduled';

    const sourceDetailsList =
      participantGroups?.[participantGroupKey]?.sourceParticipants ?? [];

    const sourceDetails =
      sourceDetailsList?.find(
        (sourceDetails) => sourceDetails?.account?.id === sourceId,
      ) ?? null;

    const destinationDetailsList =
      participantGroups?.[participantGroupKey]?.destinationParticipants ?? [];

    const destinationDetails =
      destinationDetailsList?.find(
        (destinationDetails) =>
          destinationDetails?.account?.id === destinationId,
      ) ?? null;

    /*
     * For most transfers, the anchored side will be the source.
     * This means that all source participants will be eligible, and only
     * destinations that are related to the selected source will be eligible.
     *
     * When a user is making a payment, the anchored side will be the destination.
     */
    let isSelectedRelationshipEligible = false;
    switch (anchoredSide) {
      case TransferParticipantSideEnum.To: {
        isSelectedRelationshipEligible =
          destinationDetails?.relationships?.some(
            (relationship) => relationship?.source?.id === destinationId,
          ) ?? false;
        break;
      }
      case TransferParticipantSideEnum.From: {
        isSelectedRelationshipEligible =
          sourceDetails?.relationships?.some(
            (relationship) => relationship.destination?.id === destinationId,
          ) ?? false;
        break;
      }
      default: {
        /*
         * If we have a new anchored side, we need to update this switch statement
         * The below will throw an error if a new enum value is not accounted for above.
         */
        return assertAllCasesHandled(anchoredSide);
      }
    }

    return {
      sourceDetails,
      sourceDetailsList,
      destinationDetails,
      destinationDetailsList,
      isSelectedRelationshipEligible,
    };
  }, [sourceId, destinationId, frequency, participantGroups]);

  return result;
}
