import * as React from 'react';
import { Route } from 'react-router';

import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';

import { CreateTransferPage } from './CreateTransferPage';
import { CreateTransferPageDeprecated } from './CreateTransferPageDeprecated';

export const CreateTransferRoute = () => {
  return (
    <>
      <Route
        path="transfer(/:step)"
        component={CreateTransferPage}
        fallbackRoute="/d/transfers"
        cover
      />
      {/* Payments have not been migrated to new flow, still using legacy components */}
      <Route
        path="payment(/:step)"
        component={RedirectToMoveMoney}
        fallbackRoute="/d/transfers"
        cover
      />
      {/* --------------------------------
       * The create-transfer route is
       * deprecated as of 12.28.23
       * ----------------------------------*/}
      <Route
        path="create-transfer(/:step)"
        component={CreateTransferPageDeprecated}
        fallbackRoute="/d/transfers"
        cover
      />
    </>
  );
};

const RedirectToMoveMoney = () => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    navigate({
      to: '/d/move-money',
      query: { ...location.query },
    });
  }, [location, navigate]);

  return null;
};
