import { HXXS, Maybe, PM } from '@m1/liquid-react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ControlledInput } from '~/components/form/ControlledInput';
import { requireNonNil } from '~/forms/validators';
import { formatCurrencyWithCommas, normalizeOnlyDigitsAsString } from '~/utils';
export const AnnualIncomeMonthlyRentMortgageForm = (props: {
  subtitle: Maybe<string>;
}) => {
  const { control } = useFormContext();

  const format = (value: string | null): string => {
    return value ? `$${formatCurrencyWithCommas(value)}` : '';
  };

  return (
    <>
      <HXXS content="How much do you make before taxes each year?" />
      <PM
        fontWeight={400}
        color="foregroundNeutralSecondary"
        content={
          props.subtitle ??
          `Include wages, bonuses and other income. We'll verify this information later on.`
        }
        my={8}
      />
      <ControlledInput
        name="annualIncome"
        control={control}
        placeholder="Yearly income"
        mb={24}
        inputMode="numeric"
        maxLength={11}
        minLength={1}
        transformInput={normalizeOnlyDigitsAsString}
        hint="Enter $0 if you have no income."
        transformValue={format}
        rules={{
          required: 'Required',
          validate: {
            requireNonNil: requireNonNil,
          },
        }}
      />
      <HXXS content="How much is your mortgage or rent?" mt={24} />

      <ControlledInput
        name="monthlyHousingCosts"
        control={control}
        placeholder="Monthly payment"
        mb={24}
        inputMode="numeric"
        transformInput={normalizeOnlyDigitsAsString}
        transformValue={format}
        minLength={1}
        rules={{
          required: 'Required',
          validate: {
            requireNonNil: requireNonNil,
          },
        }}
      />
    </>
  );
};
