/* eslint-disable no-return-assign */

import { SemanticColorNames, type BoxProps } from '@m1/liquid-react';
import type { MonochromaticIconName } from '@m1/liquid-react/icons';
import * as React from 'react';

import { stringToValidNumber } from '~/utils';

import {
  InputIconAndContentOrder,
  InputKind,
  InputMode,
  InputSize,
  InputType,
} from './Input.types';
import {
  StyledContent,
  StyledDollarSign,
  StyledIcon,
  StyledMessage,
  StyledMessageContainer,
  StyledPercentSign,
} from './subcomponents/Input.styled';
import { StyledInput } from './subcomponents/StyledInput';
import { StyledInputBox } from './subcomponents/StyledInputBox';
import { StyledInputContainer } from './subcomponents/StyledInputContainer';
import { StyledInputLabel } from './subcomponents/StyledInputLabel';

export type InputProps = React.PropsWithRef<
  BoxProps & {
    additionalContent?: React.ReactNode;
    additionalContentOnly?: boolean;
    align?: 'left' | 'center' | 'right';
    autoComplete?: string;
    autoFocus?: boolean;
    backgroundColor?: SemanticColorNames;
    disabled?: boolean;
    error?: string | boolean;
    hint?: string;
    icon?: MonochromaticIconName;
    iconAndContentOrder?: InputIconAndContentOrder;
    id?: string;
    includeDollarSign?: boolean;
    includePercentSign?: boolean;
    inputMode?: InputMode;
    isBold?: boolean;
    kind?: InputKind;
    label?: React.ReactNode;
    max?: string | number;
    maxLength?: number;
    minLength?: number;
    min?: string | number;
    name?: string;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
    onClickIcon?: (event: React.MouseEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
    readOnly?: boolean;
    size?: InputSize;
    tabIndex?: number;
    type?: InputType;
    value?: string | number;
    warning?: string | boolean;
    // TODO(Wolf): Find out if we can remove this after getting rid of redux-form.
    props?: never;
  }
>;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      additionalContentOnly = false,
      align = 'left',
      iconAndContentOrder = 'right',
      includeDollarSign = false,
      includePercentSign = false,
      isBold = false,
      kind = 'with-spacing',
      size = 'large',
      type = 'text',
      additionalContent,
      autoComplete,
      autoFocus,
      disabled,
      error,
      hint,
      icon,
      id,
      inputMode,
      label,
      max,
      maxLength,
      min,
      name,
      onBlur,
      onChange,
      onClick,
      onClickIcon,
      onFocus,
      onKeyDown,
      onPaste,
      placeholder,
      readOnly,
      tabIndex,
      value,
      warning,
      backgroundColor,
      props,
    },
    ref,
  ) => {
    const innerRef = React.useRef<HTMLInputElement | null | undefined>(null);
    const input = ref ?? innerRef;

    const [isFocused, setIsFocused] = React.useState(false);
    const handleFocus = React.useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        if (!isFocused) {
          setIsFocused(true);
        }
        if (typeof onFocus === 'function') {
          onFocus(event);
        }
      },
      [isFocused, onFocus],
    );
    const handleBlur = React.useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        if (isFocused && !e.target.value) {
          setIsFocused(false);
        }
        if (typeof onBlur === 'function') {
          onBlur(e);
        }
      },
      [isFocused, onBlur],
    );

    const handleClickIcon = (e: React.MouseEvent<any>): void => {
      if (typeof onClickIcon === 'function') {
        onClickIcon(e);
      }
    };

    // Should the component act as if there's something present in the input?
    const isFilled = (): boolean => {
      return (
        isFocused ||
        Boolean(value) ||
        ['date', 'number'].includes(type) ||
        Boolean(placeholder) ||
        additionalContentOnly
      );
    };

    const message = error || warning || hint;
    const addMessageSpacing = typeof message === 'string';

    const showDollarSign =
      includeDollarSign && (isFocused || Boolean(placeholder));

    const showPercentSign =
      includePercentSign && !error && (isFocused || Boolean(placeholder));

    // Need to do this to prevent errors from `props` being typed as `never`.
    // We want the `props` to be `never` so we can catch if someone passes it
    // in by accident:
    const spreadProps = props as unknown as Record<string, any>;

    return (
      <StyledInputBox
        disabled={disabled}
        hidden={type === 'hidden'}
        kind={kind}
        size={size}
      >
        <StyledInputContainer
          disabled={disabled}
          error={error}
          iconAndContentOrder={iconAndContentOrder}
          isFocused={isFocused}
        >
          {!label || kind === 'basic' ? null : (
            <StyledInputLabel
              htmlFor={id}
              align={align}
              disabled={disabled}
              error={Boolean(error)}
              iconAndContentOrder={iconAndContentOrder}
              isFilled={isFilled()}
              size={size}
              isFocused={isFocused}
              additionalContentOnly={additionalContentOnly}
              backgroundColor={backgroundColor}
            >
              {label}
            </StyledInputLabel>
          )}
          {showDollarSign && <StyledDollarSign>$</StyledDollarSign>}
          {showPercentSign && <StyledPercentSign>%</StyledPercentSign>}
          {!additionalContentOnly && (
            <StyledInput
              align={align}
              autoComplete={autoComplete}
              autoFocus={autoFocus}
              disabled={disabled}
              id={id}
              isBold={isBold}
              name={name}
              onBlur={handleBlur}
              onChange={onChange}
              onClick={onClick}
              onKeyDown={onKeyDown}
              onPaste={onPaste}
              placeholder={placeholder}
              maxLength={maxLength}
              readOnly={readOnly}
              ref={input as React.LegacyRef<HTMLInputElement>}
              tabIndex={tabIndex}
              type={type}
              inputMode={inputMode}
              value={value}
              onFocus={handleFocus}
              size={size}
              min={stringToValidNumber(min)}
              max={stringToValidNumber(max)}
              error={error}
              backgroundColor={backgroundColor}
              {...spreadProps}
              // {...rest} don't do this, it causes the login form not to submit on enter keypress
            />
          )}
          {(icon || additionalContent || error) && (
            <StyledContent
              iconAndContentOrder={iconAndContentOrder}
              p={additionalContentOnly ? '14px 16px' : undefined}
            >
              {additionalContent}
              {icon && (
                <StyledIcon
                  iconAndContentOrder={iconAndContentOrder}
                  name={icon}
                  onClick={handleClickIcon}
                  value={icon}
                />
              )}
              {error && kind !== 'basic' && (
                <StyledIcon
                  iconAndContentOrder={iconAndContentOrder}
                  error={Boolean(error)}
                  name="alert20"
                  value="alert20"
                />
              )}
            </StyledContent>
          )}
        </StyledInputContainer>
        {addMessageSpacing && (
          <StyledMessageContainer>
            {kind === 'with-spacing' && (
              <StyledMessage error={Boolean(error)}>
                {typeof message === 'string' && message}
              </StyledMessage>
            )}
          </StyledMessageContainer>
        )}
      </StyledInputBox>
    );
  },
);
