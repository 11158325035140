import * as React from 'react';
import { Route } from 'react-router';

import { requireAuth } from '~/router';

import { AcatWizardRoute } from '../../acat-wizard';

import { PieEditorRoute } from './pie-editor/PieEditorRoute';

export const WizardsRoute = (routeHandlers: (...args: Array<any>) => any) => {
  return (
    <Route path="w" onEnter={routeHandlers(requireAuth)}>
      {AcatWizardRoute()}
      {PieEditorRoute()}
    </Route>
  );
};
