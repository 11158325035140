import type { AppState } from '../reducers/types';

export * from './notifications';

export function readActiveAccountId(
  state: AppState,
): string | null | undefined {
  return state.global.activeAccountId;
}

function readSavingsAccountId(state: AppState): string | null | undefined {
  return state.global.savingsAccountId;
}

export function readProfileCreated(
  state: AppState,
): boolean | null | undefined {
  return state.global.profileCreated;
}

export function isLoggedIn(state: AppState): boolean {
  return Boolean(state.auth.accessToken);
}

export function readLandingUrl(state: AppState): string | null | undefined {
  return state.config.landingUrl;
}

export function readReferrerCode(state: AppState): string | null | undefined {
  return state.config.referrerCode || null;
}

export function readAffiliateCode(state: AppState): string | null | undefined {
  return state.config.affiliateCode || null;
}

export function hasAccount(state: AppState): boolean {
  return (
    Boolean(readActiveAccountId(state)) ||
    Boolean(readSavingsAccountId(state)) ||
    Boolean(state.global.hasProduct)
  );
}

export function userHasOnboarded(state: AppState): boolean | null {
  return state.global.userHasOnboarded;
}

export function hasProfile(state: AppState): boolean {
  return Boolean(readProfileCreated(state));
}
