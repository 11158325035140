import type { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import { Maybe } from '@m1/liquid-react';
import { cloneDeep } from 'lodash-es';

import moment from 'moment-timezone';

import { PieEditModelQuery } from '~/graphql/types';
import {
  RemotePie,
  RemotePortfolioSlice,
  RemoteSecurity,
  RemoteSlice,
} from '~/pie-trees';

import type {
  FlattenedItem,
  Model,
  TreeItem,
  TreeItems,
} from './PieEditor.types';

export const iOS = /iPad|iPhone|iPod/.test(navigator.platform);

export const highlightTime = 5000;
export const NEW_PIE_ROUTE = 'new';
export const NEW_CRYPTO_PIE_ROUTE = 'new-crypto';

function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth);
}

/**
 * Calculates the new depth and parent ID for an item being dragged in a nested list.
 *
 * This function is used to determine the new position of an item within a nested list
 * when it is being dragged and dropped. It calculates the depth of the item based on
 * the drag offset and indentation width, and ensures that the item is placed within
 * valid depth boundaries. It also determines the new parent ID for the item based on
 * its new depth and position relative to other items.
 *
 * @param {FlattenedItem[]} items - The list of items in their flattened form.
 * @param {UniqueIdentifier} activeId - The unique identifier of the item being dragged.
 * @param {UniqueIdentifier} overId - The unique identifier of the item over which the dragged item is being dropped.
 * @param {number} dragOffset - The offset of the drag action.
 * @param {number} indentationWidth - The width of the indentation for nested items.
 * @returns {{ depth: number, maxDepth: number, minDepth: number, parentId: UniqueIdentifier | null }} - An object containing the new depth, maximum depth, minimum depth, and parent ID for the dragged item.
 */
export function getProjection(
  items: FlattenedItem[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number,
): {
  depth: number;
  maxDepth: number;
  minDepth: number;
  parentId: UniqueIdentifier | null;
} {
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem.depth + dragDepth;
  // Don't allow dropping a new child into an item that can't allow children
  const maxDepth = previousItem?.allowsChildren
    ? getMaxDepth({
        previousItem,
      })
    : previousItem?.depth;
  const minDepth = getMinDepth({ nextItem });
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  return { depth, maxDepth, minDepth, parentId: getParentId() };

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId;
    }

    if (depth > previousItem.depth) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentId;

    return newParent ?? null;
  }
}

function getMaxDepth({ previousItem }: { previousItem: FlattenedItem }) {
  if (previousItem) {
    return previousItem.depth + 1;
  }

  return 0;
}

function getMinDepth({ nextItem }: { nextItem: FlattenedItem }) {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
}

/**
 * Flattens tree items in an array with depth and index
 */
export function flattenTree(
  items: TreeItems,
  parentId: UniqueIdentifier | null = null,
  depth = 0,
): FlattenedItem[] {
  return items.reduce<FlattenedItem[]>((acc, item, index) => {
    return [
      ...acc,
      { ...item, parentId, depth, index },
      ...flattenTree(item.children, item.id, depth + 1),
    ];
  }, []);
}

/**
 * Unflattens a flat array of items into the tree item structure
 */
export function buildTree(flattenedItems: FlattenedItem[]): TreeItems {
  const root: TreeItem = {
    id: 'root',
    children: [],
    allowsChildren: false,
    meta: { type: 'sortable', isSystemPie: false },
  };
  const nodes: Record<string, TreeItem> = { [root.id]: root };
  const items = flattenedItems.map((item) => ({ ...item, children: [] }));

  for (const item of items) {
    const { id } = item;
    const parentId = item.parentId ?? root.id;
    const parent = nodes[parentId] ?? findItem(items, parentId);

    nodes[id] = { ...item };
    parent.children.push(item);
  }

  return root.children;
}

export function findItem(items: TreeItem[], itemId: UniqueIdentifier) {
  return items.find(({ id }) => id === itemId);
}

/**
 * Finds an item by the unique identifier deeply in the tree
 */
export function findItemDeep(
  items: TreeItems = [],
  itemId: UniqueIdentifier,
): TreeItem | undefined {
  for (const item of items) {
    const { id, children } = item;

    if (id === itemId) {
      return item;
    }

    if (children.length) {
      const child = findItemDeep(children, itemId);

      if (child) {
        return child;
      }
    }
  }

  return undefined;
}

/**
 * Finds an item by the meta identifier deeply in the tree
 */
export const findItemByMetaIdDeep = (
  items: TreeItems,
  metaId: string,
): TreeItem | undefined => {
  for (const item of items) {
    if (item.meta.id === metaId) {
      return item;
    }

    if (item.children.length) {
      const child = findItemByMetaIdDeep(item.children, metaId);

      if (child) {
        return child;
      }
    }
  }

  return undefined;
};

/**
 * Finds the parent of an item in the tree from the top down
 */
export function findParentItemDeep(
  items: TreeItems,
  itemId: UniqueIdentifier,
): TreeItem | undefined {
  for (const item of items) {
    const { id, children } = item;

    if (children.some(({ id }) => id === itemId)) {
      return item;
    }

    if (children.length) {
      const parent = findParentItemDeep(children, itemId);

      if (parent) {
        return parent;
      }
    }
  }

  return undefined;
}

/**
 * Removes an item from the tree
 */
export function removeItem(items: TreeItems, id: UniqueIdentifier) {
  const newItems = [];

  for (const item of items) {
    if (item.id === id) {
      continue;
    }

    if (item.children.length) {
      item.children = removeItem(item.children, id);
    }

    newItems.push(item);
  }

  return newItems;
}

/**
 * Sets a property of all tree items to a value. `property` is a string representing the key of the property to set.
 */
export const setPropertyAll = (
  items: TreeItems,
  property: string,
  value: any,
): TreeItems => {
  return items.map((item) => {
    if (item.children.length) {
      return {
        ...item,
        [property]: value,
        children: setPropertyAll(item.children, property, value),
      };
    }

    return { ...item, [property]: value };
  });
};

/**
 * Sets a property of a tree item and it's children to a value. `property` is a string representing the key of the property to set.
 */
export const setPropertySelfAndChildren = (
  items: TreeItems,
  id: UniqueIdentifier,
  property: string,
  value: any,
): TreeItems => {
  return items.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        [property]: value,
        children: setPropertyAll(item.children, property, value),
      };
    }

    if (item.children.length) {
      return {
        ...item,
        children: setPropertySelfAndChildren(
          item.children,
          id,
          property,
          value,
        ),
      };
    }

    return item;
  });
};

/**
 * Collapses all tree items except the one with the provided id
 */
export const collapseAll = (
  oldItems: TreeItem[],
  exceptId?: Maybe<UniqueIdentifier>,
): TreeItem[] => {
  const items = cloneDeep(oldItems);
  if (!exceptId) {
    return setPropertyAll(items, 'collapsed', true);
  }
  return items.map((item) => {
    if (item.id === exceptId) {
      return {
        ...item,
        children: item.children
          ? setPropertyAll(item.children, 'collapsed', true)
          : item.children,
        collapsed: false,
      };
    }

    if (item.children) {
      const newChildren = collapseAll(item.children, exceptId);
      if (newChildren.some((child) => child.collapsed === false)) {
        return {
          ...item,
          collapsed: false,
          children: newChildren,
        };
      }
    }

    return { ...item, collapsed: true };
  });
};

/**
 * Expands all tree items, or only the one with the provided id
 */
export const expandAll = (
  oldItems: TreeItem[],
  targetId?: Maybe<UniqueIdentifier>,
): TreeItem[] => {
  const items = cloneDeep(oldItems);
  if (!targetId) {
    return setPropertyAll(items, 'collapsed', false);
  }

  return items.map((item) => {
    if (item.id === targetId) {
      return {
        ...item,
        collapsed: false,
        children: item.children
          ? setPropertyAll(item.children, 'collapsed', false)
          : item.children,
      };
    }

    if (item.children) {
      const newChildren = expandAll(item.children, targetId);
      return {
        ...item,
        children: newChildren,
      };
    }

    return item;
  });
};

/**
 * Changes the percentage of a tree item
 */
export function changePercentage(
  oldItems: TreeItems,
  id: UniqueIdentifier,
  value: number,
) {
  return cloneDeep(oldItems).map((item) => {
    if (item.id === id) {
      item.percentage = value;
    } else if (item.children.length) {
      item.children = changePercentage(item.children, id, value);
    }

    return item;
  });
}

/**
 * Renames a pie tree item
 */
export function renamePie(
  oldItems: TreeItems,
  id: UniqueIdentifier,
  name: string,
) {
  return cloneDeep(oldItems).map((item) => {
    if (item.id === id) {
      item.meta.name = name;
      if (item.children[0]) {
        item.children[0].meta.name = name;
      }
    } else if (item.children.length) {
      item.children = renamePie(item.children, id, name);
    }

    return item;
  });
}

/**
 * Adds a new item to the tree. `addButtonId` ties the added item to the parent pie
 */
export function addItem(
  oldItems: TreeItems,
  item: TreeItem,
  addButtonId: UniqueIdentifier,
  isRoot: boolean = true,
): TreeItem[] {
  const items = cloneDeep(oldItems);
  const parent = findParentItemDeep(items, addButtonId);
  if (parent) {
    return items.map((currentItem) => {
      if (currentItem.id === parent.id) {
        currentItem.children.splice(1, 0, item);
      } else if (currentItem.children.length) {
        currentItem.children = addItem(
          currentItem.children,
          item,
          addButtonId,
          false,
        );
      }

      return currentItem;
    });
  }
  if (isRoot) {
    items.splice(1, 0, item);
  }
  return items;
}

/**
 * Sets a property of a specific tree items to a value. `property` is a string representing the key of the property to set.
 */
export function setProperty<T extends keyof TreeItem>(
  items: TreeItems,
  id: UniqueIdentifier,
  property: T,
  setter: (value: TreeItem[T]) => TreeItem[T],
) {
  for (const item of items) {
    if (item.id === id) {
      item[property] = setter(item[property]);
      continue;
    }

    if (item.children.length) {
      item.children = setProperty(item.children, id, property, setter);
    }
  }

  return [...items];
}

/**
 * Equalizes a pie's items to be the same percentage, or as close to equal as possible. They will always add up to 100.
 */
export function equalizePie(items: TreeItems, id: UniqueIdentifier): TreeItems {
  return setProperty(cloneDeep(items), id, 'children', (children) => {
    const childrenWithoutAdd = children.filter((child) => isNotAddItem(child));
    const childrenWithPercentage = childrenWithoutAdd.reduce(
      (sum, child) => (sum += Number(typeof child.percentage === 'number')),
      0,
    );
    const equalPercentage = Math.floor(100 / childrenWithPercentage);
    const remainder = 100 % childrenWithPercentage;
    const addItem = children.find((child) => !isNotAddItem(child));
    const items = childrenWithoutAdd.map((child, i) => {
      if (child.percentage) {
        child.percentage = equalPercentage;
        if (i < remainder) {
          child.percentage += 1;
        }
      }
      return child;
    });
    if (addItem) {
      items.unshift(addItem);
    }
    return items;
  });
}

/**
 * Removes any empty pies from the tree items
 */
export function removeEmptyPies(items: TreeItems): TreeItems {
  return cloneDeep(items).reduce((acc, item) => {
    // Recursively process and filter children
    item.children = removeEmptyPies(item.children).filter(
      (child, _, childrenArray) =>
        // Keep 'add' items if they have siblings or if the parent doesn't allow children
        // Keep all non-'add' items
        isNotAddItem(child) || childrenArray.length > 1 || !item.allowsChildren,
    );

    // Include item if it has children, does not allow children, or is an 'add' item
    if (
      item.children.length > 0 ||
      !item.allowsChildren ||
      item.meta.type === 'add'
    ) {
      acc.push(item);
    }

    return acc;
  }, [] as TreeItems);
}

/** This does not count 'add' buttons */
function countChildren(items: TreeItem[], count = 0): number {
  return items.reduce((acc, { meta, children }) => {
    if (children.length) {
      return countChildren(children, acc + 1);
    }

    return acc + (meta.type === 'add' ? 0 : 1);
  }, count);
}

/** Counts the number of children. This does not count 'add' buttons */
export function getChildCount(items: TreeItems, id: UniqueIdentifier) {
  const item = findItemDeep(items, id);

  return item ? countChildren(item.children) : 0;
}

/** Removes the children that match a list of ids */
export function removeChildrenOf(
  items: FlattenedItem[],
  ids: UniqueIdentifier[],
) {
  const excludeParentIds = [...ids];

  return items.filter((item) => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.children.length) {
        excludeParentIds.push(item.id);
      }
      return false;
    }

    return true;
  });
}

export function checkIfTreeIsCrypto(items: TreeItems): boolean {
  // recursively check the tree for crypto
  return items.some((item) => {
    if (item.children.length) {
      return checkIfTreeIsCrypto(item.children);
    }
    return item.meta.securityInfo?.isCrypto ?? false;
  });
}

/** Generates a new empty pie with a unique ID and name based on the date */
export function generateNewPieTreeItems(): TreeItems {
  const parentId = crypto.randomUUID();
  return [
    {
      id: parentId,
      meta: {
        type: 'sortable',
        name: `New pie - ${moment().format('YYYY-MM-DD')}`,
        isSystemPie: false,
      },
      children: [
        {
          id: crypto.randomUUID(),
          meta: {
            type: 'add',
            id: parentId,
            name: 'New pie',
            isSystemPie: false,
          },
          children: [],
          allowsChildren: false,
          draggable: false,
          removeable: false,
          collapsed: true,
        },
      ],
      allowsChildren: true,
      removeable: false,
      collapsed: false,
    },
  ];
}

const isPie = (remote: RemotePie | RemoteSecurity): remote is RemotePie =>
  remote.type === 'old_pie' || remote.type === 'new_pie';

const isNotAddItem = (item: TreeItem) => item.meta.type !== 'add';

function mapRemoteSliceToTreeItem(
  remoteSlice: RemoteSlice,
  parentIsSystemPie: boolean = false,
): TreeItem {
  const metaId =
    remoteSlice.to.type === 'new_pie' ? remoteSlice.to.name : remoteSlice.to.id;
  const id = crypto.randomUUID();
  const isSystemPie =
    'isSystemPie' in remoteSlice.to
      ? (remoteSlice.to.isSystemPie ?? false)
      : false;
  const treeItem: TreeItem = {
    id,
    meta: {
      type: 'sortable',
      id: metaId,
      name: remoteSlice.to.type === 'old_pie' ? remoteSlice.to.name : undefined,
      isSystemPie,
      securityInfo:
        remoteSlice.to.type === 'security'
          ? remoteSlice.to.securityInfo
          : undefined,
    },
    percentage: remoteSlice.percentage,
    description:
      'description' in remoteSlice.to ? remoteSlice.to.description : undefined,
    children: [],
    allowsChildren: false,
    removeable: !parentIsSystemPie,
    collapsed: true,
    draggable: !parentIsSystemPie,
  };

  if (remoteSlice.to.type !== 'security' && remoteSlice.to.slices) {
    treeItem.children = remoteSlice.to.slices.map((slice) =>
      mapRemoteSliceToTreeItem(slice, isSystemPie),
    );
    treeItem.allowsChildren = true;
    if (!isSystemPie) {
      treeItem.children.unshift({
        id: crypto.randomUUID(),
        meta: {
          type: 'add',
          id: treeItem.meta.id,
          isSystemPie: remoteSlice.to.isSystemPie ?? false,
        },
        children: [],
        allowsChildren: false,
        draggable: false,
        removeable: false,
      });
    }
  }

  return treeItem;
}

function mapRemotePortfolioSliceToTreeItem(
  remotePortfolioSlice: RemotePortfolioSlice,
  parentIsSystemPie: boolean = false,
): TreeItem {
  const metaId =
    remotePortfolioSlice.to.type === 'new_pie'
      ? remotePortfolioSlice.to.name
      : remotePortfolioSlice.to.id;
  const id = remotePortfolioSlice.id;
  const isSystemPie =
    'isSystemPie' in remotePortfolioSlice.to
      ? (remotePortfolioSlice.to.isSystemPie ?? false)
      : false;
  const treeItem: TreeItem = {
    id,
    meta: {
      type: 'sortable',
      id: metaId,
      portfolioSliceId: id,
      name:
        remotePortfolioSlice.to.type === 'old_pie'
          ? remotePortfolioSlice.to.name
          : undefined,
      isSystemPie,
      securityInfo:
        remotePortfolioSlice.to.type === 'security'
          ? remotePortfolioSlice.to.securityInfo
          : undefined,
    },
    percentage: remotePortfolioSlice.percentage,
    description:
      'description' in remotePortfolioSlice.to
        ? remotePortfolioSlice.to.description
        : undefined,
    children: [],
    allowsChildren: false,
    removeable: !parentIsSystemPie,
    collapsed: true,
    draggable: !parentIsSystemPie,
    hasOrder: remotePortfolioSlice.order,
    hasRebalance: remotePortfolioSlice.rebalance,
  };

  if (
    remotePortfolioSlice.to.type !== 'security' &&
    remotePortfolioSlice.children
  ) {
    treeItem.children = remotePortfolioSlice.children.map((child) =>
      mapRemotePortfolioSliceToTreeItem(child, isSystemPie),
    );
    treeItem.allowsChildren = true;
    if (!isSystemPie) {
      treeItem.children.unshift({
        id: `add-${remotePortfolioSlice.id}`,
        meta: {
          type: 'add',
          id: treeItem.meta.id,
          isSystemPie: remotePortfolioSlice.to.isSystemPie ?? false,
        },
        children: [],
        allowsChildren: false,
        draggable: false,
        removeable: false,
        hasOrder: remotePortfolioSlice.order,
        hasRebalance: remotePortfolioSlice.rebalance,
      });
    }
  }

  return treeItem;
}

/** Maps the remote portfolio model from lens to the tree item model */
export function mapRemotePortfolioToTreeItems(
  remotePortfolio: RemotePortfolioSlice,
  portfolioSliceId?: string,
): TreeItems {
  let treeItems: TreeItems = [];
  const parentId =
    remotePortfolio.to.type === 'new_pie'
      ? remotePortfolio.to.name
      : remotePortfolio.to.id;

  if (remotePortfolio.children) {
    remotePortfolio.children.forEach((child) => {
      const metaId = child.to.type === 'new_pie' ? child.to.name : child.to.id;
      const isSystemPie = isPie(child.to)
        ? (child.to.isSystemPie ?? false)
        : false;
      const treeItem: TreeItem = {
        id: child.id,
        meta: {
          type: 'sortable',
          id: metaId,
          portfolioSliceId: child.id,
          name: child.to.type === 'old_pie' ? child.to.name : undefined,
          isSystemPie,
          securityInfo: !isPie(child.to) ? child.to.securityInfo : undefined,
        },
        percentage: child.percentage,
        description: isPie(child.to) ? child.to.description : undefined,
        children: [],
        allowsChildren: false,
        removeable: isPie(remotePortfolio.to)
          ? !remotePortfolio.to.isSystemPie
          : true,
        collapsed: true,
        hasOrder: child.order,
        hasRebalance: child.rebalance,
      };

      if (child.to.type !== 'security' && child.children) {
        treeItem.children = child.children.map((grandChild) =>
          mapRemotePortfolioSliceToTreeItem(grandChild, isSystemPie),
        );
        treeItem.allowsChildren = true;
        if (!isSystemPie) {
          treeItem.children.unshift({
            id: `add-${child.id}`,
            meta: {
              type: 'add',
              id: treeItem.meta.id,
              portfolioSliceId: treeItem.meta.portfolioSliceId,
              isSystemPie: child.to.isSystemPie ?? false,
            },
            children: [],
            allowsChildren: false,
            draggable: false,
            removeable: false,
          });
        }
      }

      treeItems.push(treeItem);
    });
  }

  const isSystemPie = isPie(remotePortfolio.to)
    ? (remotePortfolio.to.isSystemPie ?? false)
    : false;

  treeItems.unshift({
    id: `add-${remotePortfolio.id}`,
    meta: {
      type: 'add',
      id: parentId,
      portfolioSliceId: remotePortfolio.id,
      isSystemPie,
    },
    children: [],
    allowsChildren: false,
    draggable: false,
    removeable: false,
    collapsed: true,
  });

  treeItems = [
    {
      id: remotePortfolio.id,
      meta: {
        type: 'sortable',
        id: parentId,
        portfolioSliceId: remotePortfolio.id,
        name: isPie(remotePortfolio.to)
          ? remotePortfolio.to.name
          : remotePortfolio.to.securityInfo?.symbol,
        isSystemPie,
      },
      description: isPie(remotePortfolio.to)
        ? remotePortfolio.to.description
        : null,
      children: treeItems,
      allowsChildren: true,
      removeable: false,
      draggable: false,
      collapsed: false,
      hasOrder: remotePortfolio.order,
      hasRebalance: remotePortfolio.rebalance,
    },
  ];

  // Collapse all except pie entered from and its parents
  return collapseAll(treeItems, portfolioSliceId ?? remotePortfolio.id);
}

/** Maps the remote pie edit model from lens to the tree item model */
export function mapRemotePieToTreeItems(
  remotePie: RemotePie,
  pieId: string,
): TreeItems {
  let treeItems: TreeItems = [];
  const parentId = remotePie.type === 'new_pie' ? remotePie.name : remotePie.id;

  if (remotePie.slices) {
    remotePie.slices.forEach((slice) => {
      const metaId = slice.to.type === 'new_pie' ? slice.to.name : slice.to.id;
      const isSystemPie =
        'isSystemPie' in slice.to ? (slice.to.isSystemPie ?? false) : false;
      const treeItem: TreeItem = {
        id: crypto.randomUUID(),
        meta: {
          type: 'sortable',
          id: metaId,
          name: slice.to.type === 'old_pie' ? slice.to.name : undefined,
          isSystemPie,
          securityInfo:
            slice.to.type === 'security' ? slice.to.securityInfo : undefined,
        },
        percentage: slice.percentage,
        description:
          'description' in slice.to ? slice.to.description : undefined,
        children: [],
        allowsChildren: false,
        removeable: !remotePie.isSystemPie,
        collapsed: true,
        draggable: !remotePie.isSystemPie,
      };

      if (slice.to.type !== 'security' && slice.to.slices) {
        treeItem.children = slice.to.slices.map((slice) =>
          mapRemoteSliceToTreeItem(slice, isSystemPie),
        );
        treeItem.allowsChildren = true;
        if (!isSystemPie) {
          treeItem.children.unshift({
            id: crypto.randomUUID(),
            meta: {
              type: 'add',
              id: treeItem.meta.id,
              isSystemPie: slice.to.isSystemPie ?? false,
            },
            children: [],
            allowsChildren: false,
            draggable: false,
            removeable: false,
          });
        }
      }

      treeItems.push(treeItem);
    });
  }

  if (!remotePie.isSystemPie) {
    treeItems.unshift({
      id: crypto.randomUUID(),
      meta: {
        type: 'add',
        id: parentId,
        isSystemPie: remotePie.isSystemPie ?? false,
      },
      children: [],
      allowsChildren: false,
      draggable: false,
      removeable: false,
      collapsed: true,
    });
  }

  treeItems = [
    {
      id: crypto.randomUUID(),
      meta: {
        type: 'sortable',
        id: parentId,
        name: remotePie.name,
        isSystemPie: remotePie.isSystemPie ?? false,
      },
      description: remotePie.description,
      children: treeItems,
      allowsChildren: true,
      removeable: false,
      draggable: false,
      collapsed: false,
    },
  ];

  // Collapse all except pie entered from and its parents
  const item = findItemByMetaIdDeep(treeItems, pieId);
  return collapseAll(treeItems, item?.id);
}

/** Maps the tree item model to the remote pie edit model for lens */
const mapTreeItemsToRemotePie = (items: TreeItems): RemotePie => {
  const rootItem = items[0];
  if (!rootItem) {
    // TODO check if this is ok, this really shouldn't happen
    return {
      type: 'new_pie',
      name: 'New pie',
      slices: [],
    };
  }
  const slices = rootItem.children.filter(isNotAddItem).map((item) => {
    const children = item.children
      .filter(isNotAddItem)
      .map((child) => mapTreeItemToRemoteSlice(child));
    let type: RemoteSlice['to']['type'] = 'security';
    if (children.length) {
      type = item.meta.id ? 'old_pie' : 'new_pie';
    }
    if (type === 'new_pie') {
      return {
        percentage: item.percentage ?? 1,
        to: {
          type,
          name: item.meta.name ?? 'slice',
          slices: children,
          description: item.description,
        },
      } as const satisfies RemoteSlice;
    }

    return {
      percentage: item.percentage ?? 1,
      to: {
        type,
        id: String(item.meta.id),
        name: item.meta.isSystemPie ? undefined : item.meta.name,
        slices: children.length && !item.meta.isSystemPie ? children : null,
        description: item.meta.isSystemPie ? undefined : item.description,
      },
    } as const satisfies RemoteSlice;
  });

  if (!rootItem.meta.id) {
    return {
      type: 'new_pie',
      name: rootItem.meta.name ?? 'New pie',
      slices,
      description: rootItem.description,
    };
  }

  return {
    type: 'old_pie',
    id: String(rootItem.meta.id),
    name: rootItem.meta.name,
    slices,
    description: rootItem.description,
  };
};

/** Maps the tree item model to the remote portfolio slice model for lens */
function mapTreeItemToRemoteSlice(item: TreeItem): RemoteSlice {
  const children = item.children
    .filter(isNotAddItem)
    .map((child) => mapTreeItemToRemoteSlice(child));
  let type: RemoteSlice['to']['type'] = 'security';
  if (children.length) {
    type = (item.meta.id ? 'old_pie' : 'new_pie') as 'old_pie';
  }
  const slice: RemoteSlice = {
    percentage: item.percentage ?? 1,
    to: {
      type,
      id: String(item.meta.id),
      name: item.meta.isSystemPie ? undefined : item.meta.name,
      slices: children.length && !item.meta.isSystemPie ? children : null,
      description: item.meta.isSystemPie ? undefined : item.description,
    },
  };

  return slice;
}

/** Maps the tree item model to the stringified remote pie edit model with optional extra mapping */
export const mapTreeItemsToRemotePieString = (
  items: TreeItems,
  extraMapping?: (items: any) => any,
): string => {
  const remotePie = mapTreeItemsToRemotePie(items);
  return JSON.stringify(extraMapping ? extraMapping(remotePie) : remotePie);
};

/** Gets the kind of model, model object, and whether the model is crypto or not, based on the PieEditModelQuery response */
export const getRemoteEditModel = (node: PieEditModelQuery['node']): Model => {
  try {
    if (node?.__typename === 'RootPortfolioSlice' && node.stringifiedModel) {
      return {
        kind: 'PORTFOLIO',
        model: JSON.parse(node.stringifiedModel) as RemotePortfolioSlice,
        isCrypto: node.to.containsCrypto,
      };
    }
    if (
      node?.__typename === 'ChildPortfolioSlice' &&
      node.rootAncestor.stringifiedModel
    ) {
      return {
        kind: 'PORTFOLIO',
        model: JSON.parse(
          node.rootAncestor.stringifiedModel,
        ) as RemotePortfolioSlice,
        isCrypto: node.rootAncestor.to.containsCrypto,
      };
    }
    if (
      node?.__typename === 'Account' &&
      node.rootPortfolioSlice?.stringifiedModel
    ) {
      return {
        kind: 'PORTFOLIO',
        model: JSON.parse(
          node.rootPortfolioSlice.stringifiedModel,
        ) as RemotePortfolioSlice,
        isCrypto: node.rootPortfolioSlice.to.containsCrypto,
      };
    }
    const userPie = node as ExtractTypename<
      PieEditModelQuery['node'],
      'UserPie'
    >;
    return {
      kind: 'PIE',
      model: JSON.parse(userPie.editModel) as RemotePie,
      isCrypto: userPie.containsCrypto,
    };
  } catch {
    throw new Error('Unable to parse pie model');
  }
};
