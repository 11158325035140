import * as React from 'react';
import { Route } from 'react-router';

import { DisclosuresForSavingsPage } from './DisclosuresForSavingsPage';

export const DisclosuresForSavingsRoute = () => {
  return (
    <Route path="savings-disclosures" component={DisclosuresForSavingsPage} />
  );
};
