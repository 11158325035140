import { Box } from '@m1/liquid-react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { ControlledInput } from '~/components/form/ControlledInput';
import { FormMockerReactHookForm } from '~/forms/FormMockers';
import { printableAsciiChars } from '~/forms/validators';

import { FIELD_MAX_LENGTHS } from '~/static-constants';
import { getEnumEntries } from '~/utils';

type EmploymentStatusAndInfoFormProps = {
  occupationOptions: { label: string }[];
};

export const EmploymentStatusAndInfoForm = ({
  occupationOptions,
}: EmploymentStatusAndInfoFormProps) => {
  const {
    control,
    watch,
    setValue,
    register,
    unregister,
    formState: { errors },
  } = useFormContext();

  const values = watch();

  const employmentStatus = values?.employment?.status;
  const isEmployed =
    employmentStatus === 'EMPLOYED' || employmentStatus === 'SELF_EMPLOYED';

  const occupationsWithNames = occupationOptions?.map((option) => {
    return {
      name: option.label,
      description: option.label,
    };
  });

  React.useEffect(() => {
    if (!isEmployed) {
      unregister('employment.employer');
      unregister('employment.occupation');
    } else {
      register('employment.employer');
      register('employment.occupation');
    }
  }, [isEmployed]);

  return (
    <Box>
      <Box pb={8}>
        <ControlledDropdown
          name="employment.status"
          label="Employment status"
          control={control}
          placeholder="Select"
          isSearchable={false}
          source={getEnumEntries('EmploymentStatusEnum')}
          rules={{ required: 'Required' }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
      </Box>
      {isEmployed && (
        <>
          <Box pb={8}>
            <ControlledInput
              name="employment.employer"
              label="Employer name"
              control={control}
              error={errors.employer?.message && 'Required'}
              rules={{
                required: 'Required',
                maxLength: FIELD_MAX_LENGTHS.DEFAULT,
                validate: {
                  printableAsciiChars: (value) =>
                    typeof value === 'string' && printableAsciiChars(value),
                },
              }}
            />
          </Box>
          <Box pb={8}>
            <ControlledDropdown
              name="employment.occupation"
              label="Occupation/Industry"
              isSearchable={false}
              source={occupationsWithNames}
              placeholder="Select"
              control={control}
              rules={{
                required: 'Required',
              }}
            />
          </Box>
        </>
      )}
      <FormMockerReactHookForm
        fields={[{ name: 'employment.status', value: 'STUDENT' }]}
        setValue={setValue}
      />
    </Box>
  );
};
