import { RouterState } from 'react-router';

import type { AppState } from '~/redux';
import {
  isLoggedIn,
  hasAccount,
  hasProfile,
  userHasOnboarded,
} from '~/redux/selectors';

export type RouteHandler = (
  state: AppState,
  nextRouterState: RouterState,
) => string | null | undefined;

// Send the user to the login page if we are not currently logged in.
export function requireAuth(state: AppState): string | null | undefined {
  if (!isLoggedIn(state)) {
    const path = state.routing.locationBeforeTransitions?.pathname;

    // If user has personal loan direct link, route them to PLOE signup page
    if (path?.includes('direct-loan-application')) {
      return '/direct-loan-application';
    }
    return `/login${String(state.routing.locationBeforeTransitions?.search)}`;
  }

  return undefined;
}

// Forward the user on to the home page if we are already logged in.
export function forwardIfAuth(state: AppState): string | null | undefined {
  if (isLoggedIn(state)) {
    return '/d/home';
  }

  return undefined;
}

export function requireAccountOrProfile(
  state: AppState,
): string | null | undefined {
  if (!hasProfile(state) && !hasAccount(state) && !userHasOnboarded(state)) {
    return '/start/product-selection';
  }

  return undefined;
}
