import React from 'react';

import { MaskedInput } from '~/toolbox/Input';

export const DEFAULT_MAX_VALUE = '9999999999';
export const DEFAULT_MAX_LENGTH = DEFAULT_MAX_VALUE.length;
export const DEFAULT_SCALE = 0; // default to integers

export const createMaskOptions = ({
  maxLength,
  allowNegative,
  scale,
}: {
  scale?: number;
  allowNegative?: boolean;
  maxLength?: number;
}) => {
  const max: number =
    (maxLength ?? DEFAULT_MAX_LENGTH) < DEFAULT_MAX_LENGTH
      ? Number(DEFAULT_MAX_VALUE.slice(0, maxLength))
      : Number(DEFAULT_MAX_VALUE);

  /*
   * This component makes use of the MaskedInput, which requires us to define
   * an input mask.
   * The mask dictates the formatting of the input field.
   */
  const mask = [
    {
      mask: '',
    },
    {
      mask: '$num',
      blocks: {
        num: {
          scale: scale ?? DEFAULT_SCALE,
          signed: false,
          mask: Number,
          radix: '.',
          thousandsSeparator: ',',
          // Optional min & max:
          ...(maxLength && {
            max,
          }),
        },
      },
    },
  ];

  // Optionally allow negative values
  if (allowNegative) {
    mask.push({
      mask: '-$num',
      blocks: {
        num: {
          scale: scale ?? DEFAULT_SCALE,
          signed: false,
          mask: Number,
          radix: '.',
          thousandsSeparator: ',',
          // Optional min & max:
          ...(maxLength && {
            max,
          }),
        },
      },
    });
  }

  return {
    mask,
  };
};

export type CurrencyInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onFocus' | 'size' | 'type' | 'value'
> & {
  allowNegative?: boolean;
  error?: string | boolean;
  label?: string;
  maxLength?: number;
  name?: string | undefined;
  onChange: (value: number | string | null | undefined) => void;
  scale?: number;
  validate?: Function[];
  value?: any;
};

export const CurrencyInput = ({
  allowNegative = false,
  label,
  maxLength = DEFAULT_MAX_LENGTH,
  name,
  onChange,
  scale = DEFAULT_SCALE,
  value,
  ...rest
}: CurrencyInputProps) => {
  const maskOptions = React.useMemo(
    () => createMaskOptions({ maxLength, allowNegative, scale }),
    [allowNegative],
  );

  return (
    <MaskedInput
      {...{
        ...rest,
        maskOptions,
        name,
        label,
        value: typeof value === 'string' ? value : String(value),
        onChange: (
          value: string | number | null,
          maskedValue: string | null,
        ) => {
          if (maskedValue && maskedValue.includes('-')) {
            if (allowNegative) {
              return onChange?.(Number(value) * -1);
            }
          }
          return onChange?.(value);
        },
      }}
    />
  );
};
