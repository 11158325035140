import { Theme, PM, PXS, Flex, Box, styled, useTheme } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { useDelay } from '~/hooks/useDelay';
import { Slice, Pie, isPieTreeValid } from '~/pie-trees';
import { highlightSlice } from '~/redux/actions';
import { useSelector, useDispatch } from '~/redux/hooks';
import { Checkbox } from '~/toolbox/checkbox';
import { GridTable } from '~/toolbox/grid-table';
import { Indicator } from '~/toolbox/indicator';
import { Logo } from '~/toolbox/logo';
import { Pill } from '~/toolbox/Pill';

import { EditorFeature } from '../../PortfolioEditor.types';

import { SliceableListItemPercentageAdjustor } from './SliceableListItemPercentageAdjustor';

const StyledRow = styled(GridTable.Row)<{
  backgroundColor: string;
  sliceColor: string | null | undefined;
}>`
  background-color: ${(props) => props.backgroundColor};
  border-left: ${(props) =>
    props.sliceColor ? `4px solid ${props.sliceColor}` : 'none'};
  cursor: pointer;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  height: 56px;
  transition: background-color 800ms ease;
  user-select: none;
`;

const getLogo = (slice: Slice) => {
  switch (slice.to.type) {
    case 'security':
      return slice.to.profile?.logoUrl || '';
    case 'new_pie':
      return <Icon name="createPie32" />;
    case 'old_pie':
      return slice.to.__typename === 'USER_PIE' ? (
        <Icon name="createPie32" />
      ) : (
        <Icon name="m1Logo32" />
      );
    default:
      return null;
  }
};

const getRowColorProps = (
  slice: Slice,
  theme: Theme,
):
  | {
      backgroundColor?: string;
      color?: string;
    }
  | null
  | undefined => {
  const sliceIsPieAndIsInvalid =
    (slice.to.type === 'new_pie' || slice.to.type === 'old_pie') &&
    !isPieTreeValid(slice.to);

  let colorProps = null;
  if (sliceIsPieAndIsInvalid) {
    colorProps = {
      backgroundColor: theme.colors.backgroundWarningSubtle,
      color: theme.colors.warning,
    };
  } else if (slice.to.__highlighted__) {
    colorProps = {
      backgroundColor: theme.colors.backgroundPrimarySubtle,
    };
  }

  return colorProps;
};

const getSliceName = (
  slice: Slice,
  destinationPie: Pie,
  moveSliceIds: Array<string>,
) => {
  let name = slice.to.name || 'Slice';
  // @ts-expect-error - TS2339 - Property 'slices' does not exist on type 'Sliceable'.
  const childSlices = slice.to.slices || [];
  const isDestination = slice.to.__id === destinationPie.__id;

  if (slice.to.type !== 'old_pie') {
    return name;
  }

  if (isDestination) {
    const numberOfMergedSlices = (destinationPie.slices || []).reduce(
      // @ts-expect-error - TS2339 - Property '__merged__' does not exist on type 'Sliceable'.
      (total, slice) => (slice.to.__merged__ ? total + 1 : total),
      0,
    );

    const sliceTotal =
      childSlices.length + moveSliceIds.length - numberOfMergedSlices;

    name = `${name} (${sliceTotal})`;
  } else {
    name = `${name} (${childSlices.length})`;
  }

  return name;
};

type MoveSlicesData = {
  destinationPie: Pie;
  moveSliceIds: Array<string>;
};

export type SliceableListItemProps = {
  allowZeroPercentSlices?: boolean;
  disabledFeatures?: Array<EditorFeature>;
  slice: Slice;
  sliceColor: string | null | undefined;
};

export const SliceableListItem = ({
  allowZeroPercentSlices,
  disabledFeatures,
  slice,
  sliceColor,
}: SliceableListItemProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [fade, setFade] = React.useState(false);
  const [isHover, setIsHover] = React.useState(false);

  const { highlightedSliceId: highlightedSlice } = useSelector(
    (state) => state.interface,
  );

  const { destinationPie, moveSliceIds } = useSelector<MoveSlicesData>(
    (state) => {
      const { destinationPie, moveSliceIds } = state.newFlows.PORTFOLIO_EDITOR;

      return {
        destinationPie,
        moveSliceIds,
      };
    },
  );

  const isNewPieSliceAndHasZeroSlices =
    slice.to.type === 'new_pie' && slice.to.slices.length === 0;

  const sliceIsPieAndIsInvalid =
    (slice.to.type === 'new_pie' || slice.to.type === 'old_pie') &&
    !isPieTreeValid(slice.to);

  const logo = getLogo(slice);
  // @ts-expect-error - TS2339 - Property 'symbol' does not exist on type 'Sliceable'. | TS2339 - Property 'symbol' does not exist on type 'Sliceable'.
  const sublabel = slice.to.symbol ? slice.to.symbol : null;
  const hideCheckbox =
    disabledFeatures?.includes('DELETE_SLICES') &&
    disabledFeatures?.includes('MOVE_SLICES');

  const rowColorProps = getRowColorProps(slice, theme);
  const hasBackgroundColor = rowColorProps?.backgroundColor;

  const hoveringSlice = isHover || slice.to.__id === highlightedSlice;

  const onDelayCallback = React.useCallback(() => {
    if (hasBackgroundColor && !fade) {
      setFade(true);
    }
  }, [fade, hasBackgroundColor]);

  useDelay(onDelayCallback, 2000);

  const onCheckboxChange = React.useCallback(() => {
    if (slice.to.__checked) {
      dispatch({
        type: 'REMOVE_PORTFOLIO_EDITOR_ACTIVE_SLICE_IDS',
        payload: {
          ids: [slice.to.__id],
        },
      });
    } else {
      dispatch({
        type: 'UPDATE_PORTFOLIO_EDITOR_ACTIVE_SLICE_IDS',
        payload: {
          ids: [slice.to.__id],
        },
      });
    }
  }, [dispatch, slice.to.__checked, slice.to.__id]);

  const onSliceClick = React.useCallback(
    (slice: Slice) => {
      if (slice.to.type === 'new_pie') {
        dispatch({
          payload: slice.to,
          type: 'CLICKED_PORTFOLIO_EDITOR_PIE_SLICE',
        });
      }
    },
    [dispatch],
  );

  const backgroundColor = React.useMemo(() => {
    if (hoveringSlice) {
      return theme.colors.backgroundNeutralTertiary;
    }

    if (hasBackgroundColor && !fade) {
      return rowColorProps?.backgroundColor;
    }

    return theme.colors.backgroundNeutralSecondary;
  }, [
    fade,
    hasBackgroundColor,
    hoveringSlice,
    rowColorProps?.backgroundColor,
    theme,
  ]);

  const sliceName = getSliceName(slice, destinationPie, moveSliceIds);

  return (
    <StyledRow
      // @ts-expect-error update background color typing
      backgroundColor={backgroundColor}
      onTouchStart={() => dispatch(highlightSlice(slice.to.__id))}
      // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
      onTouchEnd={() => dispatch(highlightSlice())}
      onMouseEnter={() => {
        setIsHover(true);
        dispatch(highlightSlice(slice.to.__id));
      }}
      onMouseLeave={() => {
        setIsHover(false);
        // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
        dispatch(highlightSlice());
      }}
      sliceColor={sliceColor}
    >
      <GridTable.Cell>
        <Flex alignItems="center" overflow="hidden">
          {!hideCheckbox && (
            <Checkbox
              checked={slice.to.__checked}
              onChange={onCheckboxChange}
              onClick={(e) => e.stopPropagation()}
              size="large"
            />
          )}
          <Flex
            alignItems="center"
            onClick={() => {
              if (onSliceClick) {
                onSliceClick(slice);
              }
            }}
            maxWidth="100%"
            flexGrow={1}
          >
            <Box position="relative">
              <Logo
                content={logo}
                placeholder={sublabel}
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  marginLeft: 16,
                  paddingBottom: 0,
                  userSelect: 'none',
                  width: '32px',
                }}
              />
              {/* @ts-expect-error - TS2339 - Property 'isActive' does not exist on type 'Sliceable'. */}
              {slice.to.isActive === false && (
                <Indicator color="orange" icon="warning24" />
              )}
            </Box>
            <Flex
              flexDirection="column"
              alignItems="baseline"
              ml={12}
              maxWidth="calc(100% - 60px)"
              flexGrow={1}
              style={{
                cursor: 'pointer',
              }}
            >
              {(sublabel || slice.percentage === 0) && (
                <Flex>
                  {sublabel && (
                    <PXS
                      color="foregroundNeutralSecondary"
                      content={sublabel}
                      mr={8}
                    />
                  )}
                  {slice.percentage === 0 && (
                    <Pill kind="danger" label="pending" />
                  )}
                </Flex>
              )}
              <Flex
                alignItems="center"
                overflow="hidden"
                minWidth={0}
                maxWidth="calc(100% - 20px)"
                paddingRight={16}
              >
                <PM
                  color={
                    sliceIsPieAndIsInvalid
                      ? 'warningShade'
                      : 'foregroundNeutralMain'
                  }
                  overflow="hidden"
                  maxWidth="100%"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  content={sliceName}
                />
                {isNewPieSliceAndHasZeroSlices && (
                  <PXS
                    content="New pie requires a minimum of one slice."
                    color="foregroundNeutralMain"
                    ml={12}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </GridTable.Cell>
      <GridTable.Cell textAlign="right">
        <Flex alignItems="center" justifyContent="flex-end">
          <SliceableListItemPercentageAdjustor
            allowZeroPercentSlices={allowZeroPercentSlices}
            slice={slice}
          />
        </Flex>
      </GridTable.Cell>
    </StyledRow>
  );
};
