import { Box, Maybe, SemanticColorNames, css, styled } from '@m1/liquid-react';

export const Container = styled(Box)(
  ({ color = 'backgroundNeutralTertiary', theme, borderColor }) => css`
    background: ${theme.colors[color]};
    border-color: ${borderColor ? theme.colors[borderColor] : 'transparent'};
    border-style: solid;
  `,
);

export const Bar = styled(Box)<{
  $color: SemanticColorNames | 'transparent' | undefined;
  $borderRightWidth?: Maybe<number>;
}>(
  ({
    $color = 'foregroundPrimary',
    theme,
    borderColor,
    $borderRightWidth = 0,
  }) => css`
    background: ${$color === 'transparent' ? $color : theme.colors[$color]};
    transition: width 0.2s ease-in-out;
    border-color: ${borderColor ? theme.colors[borderColor] : 'transparent'};
    border-right-width: ${$borderRightWidth}px;
    border-style: solid;
  `,
);
