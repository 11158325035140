import { Maybe } from '@m1/liquid-react';
import { connect as UNSAFE_connectRedux } from 'react-redux';
import { LocationDescriptor } from 'react-router';

import { compose, mapProps } from '~/hocs';

import { createLocationDescriptor } from './utils/deprecated';
import { toValidRoutePath } from './utils/toValidRoutePath';

type NavigableProps = LocationDescriptor & {
  to?: string | undefined;
  params?: Maybe<Record<string, unknown>>;
  locationParams?: Maybe<Record<string, unknown>>;
};

export function navigable() {
  return compose<any, any>(
    UNSAFE_connectRedux((state, ownProps: NavigableProps) => {
      if (ownProps.to === undefined) {
        return {};
      }

      const route = toValidRoutePath(ownProps.to, ownProps.params);

      let locationParams: Record<string, any> = {};
      if (ownProps.query) {
        locationParams.query = ownProps.query;
      } else if (ownProps.state) {
        locationParams.state = ownProps.state;
      } else if (ownProps.locationParams) {
        locationParams = ownProps.locationParams;
      }

      return {
        to: createLocationDescriptor(route, ownProps.params, locationParams),
      };
    }),
    // @ts-expect-error - TS2345 - Argument of type '({ dispatch, ...rest }: { [x: string]: any; dispatch: any; }) => { [x: string]: any; }' is not assignable to parameter of type 'mapper<unknown, { [x: string]: any; }>'.
    mapProps(({ dispatch, ...rest }) => ({
      ...rest,
    })),
  );
}
