import { Box, HM, PXL, styled } from '@m1/liquid-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { GenericSystemError } from '~/components/GenericSystemError';
import {
  PlatformBillingSettingsPageDocument,
  usePlatformBillingSettingsPageQuery,
  useSetPreferredPlatformPaymentMethodMutation,
} from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { InformationBanner } from '~/lens-toolbox/InformationBanner';
import { EXTERNAL_LINKS } from '~/static-constants';
import { useToast } from '~/toasts';
import { Spinner } from '~/toolbox/spinner';

import { BillingMethodSection } from './BillingMethodSection';
import { SettingsPlatformBillingHistorySection } from './SettingsPlatformBillingHistory';

export type PlatformBillingMethod = {
  platformBillingMethod: string | null | undefined;
};

const StyledBox = styled(Box)`
  max-width: 792px;
`;

const StyledInformationBanner = styled(InformationBanner)`
  & div > p:last-child {
    align-self: flex-start;
    margin-left: 24px;
    & > a {
      color: ${({ theme }) => theme.colors.critical};
    }
  }

  & div:first-child {
    color: ${({ theme }) => theme.colors.critical};
  }
`;

export const SettingsPlatformBillingPage = () => {
  // General Hooks
  const { addToast } = useToast();
  const analytics = useAnalytics();

  // graphQL query/mutation
  const { loading, data, refetch } = usePlatformBillingSettingsPageQuery({
    variables: {
      first: 15,
    },
  });
  const [setPreferredPaymentMethod, { loading: mutationLoading }] =
    useSetPreferredPlatformPaymentMethodMutation();
  usePortaledSpinner(mutationLoading);
  // React Hook Form
  const formMethods = useForm<PlatformBillingMethod>();
  const { setValue } = formMethods;
  const [platformBillingMethod] = formMethods.watch(['platformBillingMethod']);
  const handleChange = (value: string) => {
    setValue('platformBillingMethod', value);
    setPreferredPaymentMethod({
      variables: {
        input: {
          participantId: value,
        },
      },
      refetchQueries: [
        {
          query: PlatformBillingSettingsPageDocument,
          variables: { first: 15 },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        const { setPreferredPlatformPaymentMethod } = data;
        if (
          setPreferredPlatformPaymentMethod?.didSucceed &&
          setPreferredPlatformPaymentMethod.outcome
        ) {
          const { successAnalyticsEvent } =
            setPreferredPlatformPaymentMethod.outcome;

          if (successAnalyticsEvent) {
            analytics.recordAppAnalyticsEvent(successAnalyticsEvent);
          }

          addToast({
            content: 'Payment method updated successfully.',
            kind: 'success',
            duration: 'long',
          });
        }
      },
      onError: (e) => {
        addToast({
          content: e.message,
          kind: 'alert',
          link: {
            to: EXTERNAL_LINKS.CONTACT_US,
            children: 'Contact Support',
            target: '_blank',
          },
        });
      },
    });
  };

  React.useEffect(() => {
    if (
      data?.viewer.settings?.platformBilling?.billingMethodSection
        ?.preferredPaymentMethodId &&
      !platformBillingMethod
    ) {
      setValue(
        'platformBillingMethod',
        data?.viewer.settings?.platformBilling?.billingMethodSection
          ?.preferredPaymentMethodId,
      );
    }
  }, [
    data?.viewer.settings?.platformBilling?.billingMethodSection
      ?.preferredPaymentMethodId,
    platformBillingMethod,
    setValue,
  ]);

  if (loading) {
    return <Spinner />;
  }
  const viewer = data?.viewer;
  const platformBilling = data?.viewer?.settings?.platformBilling;
  const billingMethodSection =
    viewer?.settings?.platformBilling?.billingMethodSection;

  if (!data || !viewer) {
    return <GenericSystemError onClickRetry={refetch} />;
  }
  const title = viewer?.settings?.platformBilling?.header.title;
  const subtitles = viewer?.settings?.platformBilling?.header.subtitles;
  const paymentFailureBanner =
    viewer?.settings?.platformBilling?.paymentFailureBanner;

  return (
    <>
      {paymentFailureBanner && (
        <StyledInformationBanner appBanner={paymentFailureBanner} mb={24} />
      )}
      <StyledBox>
        {platformBilling?.header && (
          <>
            <HM fontWeight={300} content={title} mb={8} />
            {subtitles &&
              subtitles.map((subtitle, index) => (
                <PXL key={index} content={subtitle} mb={24} />
              ))}
          </>
        )}
        {billingMethodSection && (
          <BillingMethodSection
            billingMethodSection={billingMethodSection}
            handleChange={handleChange}
            formMethods={formMethods}
            platformBillingMethod={platformBillingMethod}
          />
        )}
        {platformBilling?.billingHistorySection && (
          <SettingsPlatformBillingHistorySection
            refetch={refetch}
            historySection={platformBilling?.billingHistorySection}
          />
        )}
      </StyledBox>
    </>
  );
};
