import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import { FundScreenerPage } from './components';
import { ResearchFundDetailsRoute } from './details';

export const FundsScreenerRoute = () => {
  return (
    <Route path="funds" ignoreScrollBehavior>
      {ResearchFundDetailsRoute()}
      <IndexRoute component={FundScreenerPage} />
    </Route>
  );
};
