import { FLOWS } from '~/redux/reducers/newFlows';
import { RESET_PASSWORD_FLOW_STEPS as STEPS } from '~/static-constants';

import { createStepFade, makeFlowComponent } from '../utils';

import { AccountChallengeStep } from './steps/account-challenge';
import { AccountChallengeFailedStep } from './steps/account-challenge-failed';
import { InputNewPasswordStep } from './steps/input-new-password';
import { ResetPasswordSuccessStep } from './steps/ResetPasswordSuccessStep';

export const ResetPasswordFlow = makeFlowComponent({
  name: FLOWS.RESET_PASSWORD,
  Component: createStepFade({
    [STEPS.INPUT_NEW_PASSWORD]: InputNewPasswordStep,
    [STEPS.ACCOUNT_CHALLENGE]: AccountChallengeStep,
    [STEPS.ACCOUNT_CHALLENGE_FAILED]: AccountChallengeFailedStep,
    [STEPS.SUCCESS]: ResetPasswordSuccessStep,
  }),
});
