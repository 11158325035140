import * as React from 'react';
import { Route } from 'react-router';

import { SelectPaymentAccountPage } from './SelectPaymentAccountPage';

export const SelectPaymentAccountRoute = () => {
  return (
    <Route
      path="select-payment-account"
      component={SelectPaymentAccountPage}
      fallbackRoute="/d/invest/portfolio"
      cover
    />
  );
};
