import {
  Box,
  Flex,
  LayoutableProps,
  PL,
  PM,
  spacingUnits,
  useTheme,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { components } from 'react-select';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { TransferWizardParticipantDetailsFragment } from '~/graphql/types';

import {
  StyledContent,
  StyledLabel,
  StyledText,
} from './TransferParticipantFields.styled';
import { participantsToDropdownOptions } from './TransferParticipantFields.utils';

export const GroupHeading = ({ children, ...rest }: any) => {
  return (
    <components.GroupHeading {...rest}>
      <PM color="foregroundNeutralSecondary" content={children} />
    </components.GroupHeading>
  );
};

// FIXME(Wolf): Fix this once you upgrade react-select.
const ReactSelectOption = components.Option as any;
export const Option = (props: any) => {
  const { children, ...rest } = props;
  return (
    <ReactSelectOption {...rest}>
      <StyledContent
        isFocused={props.isFocused}
        selectProps={props.selectProps}
      >
        {props.selectProps.optionContent ? (
          props.selectProps.optionContent(props as any)
        ) : (
          <StyledText
            content={children}
            isFocused={props.isFocused}
            selectProps={props.selectProps}
          />
        )}
        {props.isSelected && (
          <Icon name="check24" color="foregroundNeutralMain" ml={8} />
        )}
      </StyledContent>
    </ReactSelectOption>
  );
};

function Placeholder(props: any) {
  const isDisabled = props.selectProps.isDisabled;
  return (
    <components.Placeholder {...props}>
      <Flex alignItems="center" gap={16}>
        <Icon
          name={
            isDisabled
              ? 'accountAccountsDisabled32'
              : 'accountAccountsPrimary32'
          }
        />
        <PL
          color={
            isDisabled ? 'foregroundNeutralTertiary' : 'foregroundNeutralMain'
          }
          content="Select account"
        />
      </Flex>
    </components.Placeholder>
  );
}

const SelectContainer = (props: any) => {
  return (
    <components.SelectContainer {...props}>
      <Box position="relative" pt={8}>
        {props.selectProps.label && (
          <StyledLabel
            htmlFor={props.selectProps.name}
            appTheme={props.selectProps.appTheme}
            backgroundColor={props.selectProps.backgroundColor}
            error={props.selectProps.error}
            isLabelForDestination={props.selectProps.name === 'destinationId'}
            isDisabled={props.selectProps.isDisabled}
            isFocused={props.selectProps.isFocused}
          >
            {props.selectProps.label}
          </StyledLabel>
        )}
        {props.children}
      </Box>
    </components.SelectContainer>
  );
};

export type TransferParticipantFieldsProps = LayoutableProps & {
  sourceDetails: TransferWizardParticipantDetailsFragment | null;
  sourceDetailsList: TransferWizardParticipantDetailsFragment[];
  isSelectedRelationshipEligible: boolean;
};

export const TransferParticipantFields = ({
  sourceDetailsList,
  sourceDetails,
  isSelectedRelationshipEligible,
  ...props
}: TransferParticipantFieldsProps) => {
  const theme = useTheme();
  const { control, setValue, trigger, watch } = useFormContext();
  const { isDirty } = useFormState();

  const [sourceId, schedule] = watch(['sourceId', 'schedule']);

  // All sources` ("from" accounts) available to user
  const sources = participantsToDropdownOptions(
    sourceDetailsList.map((source) => source.account!),
  );

  // All destinations ("to" accounts) available for the selected source
  const destinations = participantsToDropdownOptions(
    (sourceDetails?.relationships ?? []).map(
      (participant) => participant.destination!,
    ),
  );

  React.useEffect(() => {
    if (!isDirty) {
      return;
    }
    /*
     * Reset the destination only when the selected source doesn't have
     * the existing destination as an option and the form is dirty.
     */
    if (!isSelectedRelationshipEligible) {
      setValue('destinationId', null);
    }
    /*
     * This will be triggered when the relationship is valid and the
     * schedule has changed.  This is important, since min/max values
     * vary between one-time and scheduled relationships.
     */
    trigger('amount');
  }, [isDirty, isSelectedRelationshipEligible, schedule]);

  const commonStyles = {
    control: {
      borderRadius: '8px 8px 0 0px',
      maxHeight: 'auto',
      minHeight: '72px',
      padding: '0 4px 0 0',
    },
    groupHeading: {
      borderTop: 'none',
      borderBottom: 'none',
      marginBottom: '8px',
      marginTop: '16px',
      padding: '0 24px',
      textTransform: 'inherit',
    },
    margin: 0,
    menu: {
      boxShadow:
        '0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 15px 40px 0px rgba(0, 0, 0, 0.20);',
    },
    pointerEvents: 'inherit',
  };

  return (
    <Box mb={spacingUnits.l} {...props}>
      <ControlledDropdown
        components={{ GroupHeading, Option, Placeholder, SelectContainer }}
        control={control}
        isSearchable={false}
        label="From"
        name="sourceId"
        placeholder="Select"
        size="large"
        // Need to sort values so that "External Accounts" are first. This is required because there
        // is no guaranteed order to Object.values resolves the order from keys.
        // (see https://stackoverflow.com/a/5525820 for more info)
        source={Object.values(sources).sort(
          (a, b) => a.label.charCodeAt(0) - b.label.charCodeAt(0),
        )}
        style={{
          ...commonStyles,
        }}
        rules={{ required: 'An account must be selected.' }}
      />
      <ControlledDropdown
        components={{ GroupHeading, Option, Placeholder, SelectContainer }}
        control={control}
        disabled={!sourceId}
        isSearchable={false}
        label="To"
        name="destinationId"
        placeholder="Select"
        size="large"
        source={Object.values(destinations).sort(
          (a, b) => a.label.charCodeAt(0) - b.label.charCodeAt(0),
        )}
        style={{
          ...commonStyles,
          control: {
            ...commonStyles.control,
            borderRadius: '0 0 8px 8px',
            ...(!sourceId && {
              cursor: 'not-allowed',
              '&:hover': {
                borderColor: theme.colors.borderMain,
              },
            }),
          },
          cursor: !sourceId ? 'not-allowed' : 'inherit',
          marginTop: '-9px',
        }}
        rules={{ required: 'An account must be selected.' }}
      />
    </Box>
  );
};
