import {
  createHistory as createHistoryFromPackage,
  useBeforeUnload,
} from 'history';
import { useRouterHistory } from 'react-router';

// eslint-disable-next-line react-hooks/rules-of-hooks
export const createHistory = useRouterHistory(
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useBeforeUnload(createHistoryFromPackage),
);
