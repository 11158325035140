import * as React from 'react';
import { Route, IndexRoute } from 'react-router';

import { BorrowAccountDetailsPage } from './BorrowAccountDetailsPage';
import { MarginMarketingRoute } from './marketing';

export const MarginRoute = () => {
  return (
    <Route path="margin">
      {MarginMarketingRoute()}
      <IndexRoute component={BorrowAccountDetailsPage} />
    </Route>
  );
};
