import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { GlobalLoadingSpinner } from '~/components/GlobalLoadingSpinner';
import * as modals from '~/components/modals';
import { ToastContainer } from '~/toasts/ToastContainer';

type AppPageProps = {
  children: React.ReactNode;
};

export const AppPage = ({ children }: AppPageProps) => (
  <Box
    backgroundColor="backgroundNeutralMain"
    color="foregroundNeutralMain"
    minHeight="100%"
  >
    <ToastContainer />
    {children}
    <GlobalLoadingSpinner />
    {}
    {Object.entries(modals).map(([name, Modal]) => (
      <Modal key={name} />
    ))}
  </Box>
);
