import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import {
  ResendEmailVerificationDocument,
  ResendEmailVerificationMutationResult,
} from '~/graphql/hooks';
import { ResendEmailVerificationInput } from '~/graphql/types';
import {
  ClickedResendVerificationEmailAction,
  hideLoadingSpinner,
  navigate,
  showLoadingSpinner,
} from '~/redux/actions';

import { apolloMutationSaga } from '../apolloMutationSaga';

export function* clickedResendVerificationEmail(
  payload: ClickedResendVerificationEmailAction['payload'],
): SagaIterator<void> {
  yield put(showLoadingSpinner());
  try {
    const result: ResendEmailVerificationMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: ResendEmailVerificationDocument,
        variables: {
          input: {} satisfies ResendEmailVerificationInput,
        },
      },
    );

    if (result.data && payload?.successNotificationLocation) {
      yield put({
        payload: {
          content: 'Email sent.',
          duration: 'long',
          kind: 'success',
        },
        type: 'ADD_TOAST',
      });
    }
    if (payload?.pathToRedirectToOnSuccess) {
      yield put(navigate({ to: payload.pathToRedirectToOnSuccess }));
    }
  } catch (e: any) {
    if (payload?.errorNotificationLocation) {
      yield put({
        payload: {
          content: 'Failed to resend email.',
          duration: 'long',
          kind: 'error',
        },
        type: 'ADD_TOAST',
      });
    }
  } finally {
    yield put(hideLoadingSpinner());
  }
}
