import { ACTION_TYPES } from '~/redux/actions';

import { FLOWS } from '../newFlowsReducer.definition';
import { Routable } from '../newFlowsReducer.types';

// This could do a lot more, e.g. handle basePath and step changes
export function createFlowReducer(
  name: ValueOf<typeof FLOWS>,
  reducer: (...args: Array<any>) => any,
) {
  return function (state: any, action: any): any {
    switch (action.type) {
      case ACTION_TYPES.BEGIN_FLOW:
      case ACTION_TYPES.FINISHED_FLOW_STEP:
      case ACTION_TYPES.END_FLOW:
        return action.meta.flow === name ? reducer(state, action) : state;
      default:
        return reducer(state, action);
    }
  };
}

export function createStepReducer(initialState: Routable) {
  return function stepReducer(state: Routable, action: any) {
    const pathSegments = action.payload.pathname
      .replace(state.basePath, '')
      .split('/')
      .filter(Boolean);

    // If there's no path after basePath, set step to initial step. Otherwise, use the next segment in the path.
    return pathSegments.length === 0 ? initialState.step : pathSegments[0];
  };
}
