import { Box, Button, Flex, HM, HXS, LM, Card } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';
import { InjectedRouteProps } from 'react-router';

import { GenericSystemError } from '~/components/GenericSystemError';
import { LabelWithValue } from '~/components/label-with-value';
import { useTransferAcatInstanceDetailsPageQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { AppPill } from '~/lens-toolbox/AppPill';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Grid } from '~/toolbox/grid';
import { Spinner } from '~/toolbox/spinner';

import { AcatNextStepsContainer } from './components/AcatNextStepsContainer';
import { AcatStepCard } from './components/AcatStepCard';
import { AcatTransferSpecificMessageCard } from './components/AcatTransferSpecificMessageCard';

export type TransferAcatInstanceDetailsPageProps = InjectedRouteProps & {
  transferInstanceId: string;
};

export const TransferAcatInstanceDetailsPage = ({
  transferInstanceId,
  location,
}: TransferAcatInstanceDetailsPageProps) => {
  const navigate = useNavigate();
  const { data, loading } = useTransferAcatInstanceDetailsPageQuery({
    variables: {
      transferInstanceId,
    },
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  const acat =
    data?.node?.__typename === 'AcatTransferInstance' ? data.node : null;

  if (!acat) {
    return <GenericSystemError />;
  }

  const previousRouteName = location.query.previousRouteName || '/d/transfers';

  const acatDetails = acat.details;

  const firstStep = acatDetails?.steps ? acatDetails.steps[0] : null;
  const steps = acatDetails?.steps ? [...acatDetails.steps].slice(1) : null;

  return (
    <Box m="0 auto" p="64px 0 100px 0" width={490}>
      <HXS content={acatDetails?.title} />
      <Card mt={32} p={32}>
        <LM color="foregroundNeutralMain" content="Amount" />
        <Flex alignItems="center" mt={8}>
          <HM
            content={
              typeof acat?.amount === 'number'
                ? `$${acat?.amount}`
                : 'Available soon'
            }
          />
          {acatDetails?.pill && <AppPill appPill={acatDetails.pill} ml={8} />}
        </Flex>
        <Flex mb={32}>
          <Grid.Row
            style={{
              marginTop: 16,
            }}
          >
            <Grid.Col xs={6}>
              <LabelWithValue
                label="Submitted"
                value={moment(acat.startDate?.toString()).format(
                  'MMMM D, YYYY h:mm A z',
                )}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              {acatDetails?.supportTicketNumber && (
                <LabelWithValue
                  label="Ticket request number"
                  value={acatDetails.supportTicketNumber}
                />
              )}
            </Grid.Col>
          </Grid.Row>
        </Flex>
        {steps && (
          <AcatStepCard
            steps={steps}
            status={acat.status}
            firstStep={firstStep}
          />
        )}
        {acatDetails?.transferSpecificMessage && (
          <AcatTransferSpecificMessageCard
            transferSpecificMessage={acatDetails.transferSpecificMessage}
          />
        )}
        {acatDetails?.nextSteps && (
          <AcatNextStepsContainer nextSteps={acatDetails.nextSteps} />
        )}
      </Card>
      <ButtonGroup mt={64}>
        <Button
          kind="primary"
          label="Done"
          onClick={() => {
            navigate({ to: previousRouteName });
          }}
          size="large"
        />
      </ButtonGroup>
    </Box>
  );
};
