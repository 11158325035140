import { Flex, HXXS } from '@m1/liquid-react';
import React from 'react';

import { SliceableLogo, SliceableStatusIndicator } from '~/components/pie';
import { AppPillFragment, SliceableFragment } from '~/graphql/types';
import { AppPill } from '~/lens-toolbox/AppPill';
import { getSliceableStatus } from '~/pie-trees/getSliceableStatus';

type SecurityNameProps = {
  sliceable: SliceableFragment;
  securityPill?: AppPillFragment | null | undefined;
};

export const SecurityName = ({
  sliceable,
  securityPill,
}: SecurityNameProps) => {
  return (
    <>
      <Flex flexDirection="row">
        <Flex height={48} width={48} position="relative">
          <SliceableLogo size="48" sliceable={sliceable} />
          {sliceable && ['Fund', 'Equity'].includes(sliceable.__typename) && (
            <SliceableStatusIndicator status={getSliceableStatus(sliceable)} />
          )}
        </Flex>
        {securityPill && (
          <Flex height={18} ml={12} my="auto">
            <AppPill appPill={securityPill} />
          </Flex>
        )}
      </Flex>
      {'symbol' in sliceable && (
        <HXXS
          mt={10}
          content={`${sliceable.name} (${sliceable.symbol})`}
          color="foregroundNeutralMain"
        />
      )}
    </>
  );
};
