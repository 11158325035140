import classNames from 'classnames';
import * as React from 'react';

import { Col } from './col';
import { Row } from './row';
import style from './style.module.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
  constrain?: boolean;
  padded?: boolean;
  style?: Record<string, any>;
};

export class Grid extends React.Component<Props> {
  static Row = Row;
  static Col = Col;

  static defaultProps = {
    constrain: false,
    padded: false,
  };

  render() {
    const { className, constrain, padded } = this.props;
    const classes = classNames(style.root, className, {
      [style.constrain]: constrain,
      [style.padded]: padded,
    });

    return (
      <div className={classes} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}
