import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent, forwardIfAuth, RouteHandlers } from '~/router';

import { LoginPage } from './LoginPage';

export const LoginRoute = (routeHandlers: RouteHandlers) => {
  return (
    <Route
      path="login"
      component={createRouteComponent(LoginPage)}
      onEnter={routeHandlers(forwardIfAuth)}
    />
  );
};
