import { Theme } from '@m1/liquid-react';

import { parseCurrency } from '~/utils/parseCurrency';

export const formatFill = (
  previousClose: number,
  currentClose: number,
  theme: Theme,
): string => {
  const { critical, primaryTint, foregroundNeutralMain } = theme.colors;
  if (previousClose > currentClose) {
    return critical;
  } else if (currentClose > previousClose) {
    return primaryTint;
  }
  return foregroundNeutralMain;
};

const INT_NUMBER_FORMATTER = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
});

const formatToCommaSeparated = (number: number): string => {
  return INT_NUMBER_FORMATTER.format(number);
};

export const formatLargeNumber = (
  number: number,
  isRounded: boolean | undefined = true,
): string => {
  const rounded = Math.round(number);
  const stringifiedNumber = Math.abs(rounded).toString();
  const commaFormattedNumber = formatToCommaSeparated(rounded);
  const commaFormattedArray = commaFormattedNumber.split(',');

  const prefix = commaFormattedArray[0];

  if (stringifiedNumber.length > 9) {
    return `${prefix}B`;
  } else if (stringifiedNumber.length > 6) {
    return `${prefix}M`;
  } else if (stringifiedNumber.length > 4) {
    return `${prefix}K`;
  }
  return isRounded ? stringifiedNumber : number.toFixed(2);
};

export const formatLargeCurrencyString = (amount: string): string | null => {
  const parsedAmount = parseCurrency(amount);
  if (!parsedAmount && parsedAmount !== 0) {
    return null;
  }
  const result = formatLargeNumber(parsedAmount, false);

  return `$${result}` ?? null;
};
