import pick from 'lodash-es/pick';
import { LOCATION_CHANGE } from 'react-router-redux';

import { ACTION_TYPES as ACTIONS } from '~/redux/actions';

// TODO: move the steps to static constants.
import { STEPS } from '../../../../flows/components/credit-card/application';

import { FlowState } from '../newFlowsReducer.types';
import { createFlowReducer, createStepReducer } from '../utils';

export type CreditCardApplicationFlowState = FlowState<
  typeof STEPS,
  {
    basePath: string;
    housingType: string | null | undefined;
    monthlyPayments: number | null | undefined;
    totalAnnualIncome: number | null | undefined;
  }
>;

export const creditCardApplicationInitialState: CreditCardApplicationFlowState =
  {
    basePath: '',
    step: '',
    stepTitle: '',
    totalAnnualIncome: null,
    housingType: null,
    monthlyPayments: null,
  };

const stepReducer = createStepReducer(creditCardApplicationInitialState);

const reducer = (
  state: CreditCardApplicationFlowState = creditCardApplicationInitialState,
  action: any,
): CreditCardApplicationFlowState => {
  switch (action.type) {
    case ACTIONS.BEGIN_FLOW:
      return {
        ...creditCardApplicationInitialState,
        ...pick(action.payload, Object.keys(creditCardApplicationInitialState)),
        step: creditCardApplicationInitialState.step,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        step: stepReducer(state, action),
      };
    case ACTIONS.FINISHED_FLOW_STEP:
      if (
        (action.meta.step === 'finances' || action.meta.step === 'edit') &&
        action.payload.totalAnnualIncome
      ) {
        return {
          ...state,
          totalAnnualIncome: action.payload.totalAnnualIncome,
          monthlyPayments: action.payload.monthlyPayments,
          housingType: action.payload.housingType,
        };
      }
      return state;
    default:
      return state;
  }
};

export const creditCardApplication = createFlowReducer(
  'CREDIT_CARD_APPLICATION',
  reducer,
);
