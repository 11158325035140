/* eslint-disable react/sort-comp */
import PropTypes from 'prop-types';
import * as React from 'react';

import { UNSAFE_connectRedux } from '~/hocs';
import { getEnvironment } from '~/hooks/useEnvironment';
import { AppRoute } from '~/pages';
import type { AppState } from '~/redux';
import { GlobalLoadState } from '~/redux/reducers/globalReducer';
import { configureRouter } from '~/router';

import { AppContextType } from './AppContext';
import { RenderFailurePage } from './components/render-failure-page';

type Props = {
  loadState: GlobalLoadState;
};

class AppComponent extends React.Component<Props> {
  static contextTypes = {
    analytics: PropTypes.object,
    history: PropTypes.object,
    sentry: PropTypes.object,
    store: PropTypes.object,
  };

  children: null | React.ReactNode = null;

  UNSAFE_componentWillReceiveProps(nextProps: Props, context: AppContextType) {
    if (
      this.props.loadState &&
      !this.props.loadState.done &&
      nextProps.loadState.done
    ) {
      const { analytics, history, sentry, store } = context;
      this.children = configureRouter({
        analytics,
        history,
        renderScreensRoot: AppRoute,
        sentry,
        store,
      });
    }

    if (nextProps.loadState.error) {
      this.children = React.createElement(RenderFailurePage);
    }
  }

  componentDidMount() {
    // Reset graphql endpoint for local development environment selector
    const isProduction =
      __NODE_ENV__ === 'production' &&
      (__ENV__ === 'production' || __ENV__ === 'beta');
    if (!isProduction) {
      const env = getEnvironment();
      window.config.graphql.endpoint = env.value;
      const configChangeEvent = new Event('graphqlChange');
      window.dispatchEvent(configChangeEvent);
    }
  }

  render() {
    return this.children;
  }
}

const mapStateToProps = (
  state: AppState,
): {
  loadState: GlobalLoadState;
} => ({
  loadState: state.global.loadState,
});

export const App = UNSAFE_connectRedux(mapStateToProps)(AppComponent);
