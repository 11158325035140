import * as React from 'react';

import { NavigationQuery } from '~/graphql/types';
import { DashboardNavigation } from '~/hooks/useDashboardNavigation';

import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import {
  setActiveBorrowAccount,
  setActivePersonalLoanAccount,
  setBorrowAccountDestination,
} from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { StyledHeading, StyledHeadingLabel } from '../Navigation.styled';

import { AccountOptionProps } from './SideNav';
import { SideNavLink } from './SideNavLink';

type SideNavBorrowProps = {
  selectedNavigation: DashboardNavigation;
  data: NavigationQuery | undefined;
};

export const SideNavBorrow = ({
  data,
  selectedNavigation,
}: SideNavBorrowProps) => {
  const borrow = data?.viewer.borrow;
  if (!borrow) {
    return null;
  }

  const hasPersonalLoan =
    (borrow.personalLoans?.loans?.edges ?? []).length > 0 &&
    !borrow.personalLoans?.activeApplication;

  return (
    <>
      <BorrowAccountsList
        selectedNavigation={selectedNavigation}
        margin={borrow.borrowAccounts}
        personalLoans={borrow.personalLoans}
        isEligibleToApplyForPersonalLoan={
          Boolean(borrow.isEligibleToApplyForPersonalLoan) && !hasPersonalLoan
        }
      />
    </>
  );
};

type NonNullableBorrow = NonNullable<NavigationQuery['viewer']['borrow']>;

type MarginBorrowAccounts = NonNullable<NonNullableBorrow['borrowAccounts']>;

type personalLoanBorrowAccounts = NonNullable<
  NonNullableBorrow['personalLoans']
>;

type BorrowAccountsListProps = {
  isEligibleToApplyForPersonalLoan: boolean;
  margin: MarginBorrowAccounts | null | undefined;
  personalLoans: personalLoanBorrowAccounts | null | undefined;
  selectedNavigation: DashboardNavigation;
};

const BorrowAccountsList = ({
  margin,
  personalLoans,
  selectedNavigation,
  isEligibleToApplyForPersonalLoan,
}: BorrowAccountsListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const marginAccounts: Array<AccountOptionProps> = React.useMemo(() => {
    const accounts: Array<any> = [];
    margin?.edges?.forEach((account) => {
      if (account?.node?.id) {
        accounts.push({
          descriptor: account.node.descriptor || '',
          iconName: 'accountBorrowAccent20',
          id: account.node.id,
          name: account.node.name,
          to: '/d/borrow/margin',
          type: 'margin',
        });
      }
    });
    return accounts;
  }, [margin]);

  const personalLoanAccounts: AccountOptionProps[] = React.useMemo(() => {
    const accounts: AccountOptionProps[] = [];
    personalLoans?.loans?.edges?.forEach((account) => {
      if (account?.node?.id) {
        accounts.push({
          descriptor: account.node.descriptor || '',
          iconName: 'accountBorrowAccent20',
          id: account.node.id,
          name: account.node.name,
          to: '/d/borrow/personal/transactions',
          type: 'personal',
        });
      }
    });
    return accounts;
  }, [personalLoans]);

  const selectedMarginId: string | undefined = location.query?.borrowAccountId;

  const canAddPersonalText =
    isEligibleToApplyForPersonalLoan && personalLoanAccounts.length === 0;

  return (
    <>
      {marginAccounts.length > 0 && (
        <StyledHeading>
          <StyledHeadingLabel>Margin Loans</StyledHeadingLabel>
        </StyledHeading>
      )}

      {marginAccounts.length > 0 ? (
        <>
          {marginAccounts.map((account) => {
            const onClick = () => {
              if (account.type === 'margin') {
                dispatch(setActiveBorrowAccount(account.id));
                dispatch(setBorrowAccountDestination('margin'));
              } else if (account.type === 'personal') {
                dispatch(setActivePersonalLoanAccount(account.id));
                dispatch(setBorrowAccountDestination('personal'));
              }
              navigate({ to: account.to ?? '/d/home' });
            };

            return (
              <SideNavLink
                selected={
                  selectedNavigation.activeL2Nav === account.id ||
                  selectedMarginId === account.id
                }
                key={`borrow-margin-${account.id}`}
                label={account.name}
                subText={account.descriptor?.toString() ?? ''}
                onClick={onClick}
              />
            );
          })}
        </>
      ) : null}

      {personalLoanAccounts.length > 0 && (
        <StyledHeading>
          <StyledHeadingLabel>Personal Loan</StyledHeadingLabel>
        </StyledHeading>
      )}

      {personalLoanAccounts.length > 0 ? (
        <>
          {personalLoanAccounts.map((account) => {
            const onClick = () => {
              if (account.type === 'margin') {
                dispatch(setActiveBorrowAccount(account.id));
                dispatch(setBorrowAccountDestination('margin'));
              } else if (account.type === 'personal') {
                dispatch(setActivePersonalLoanAccount(account.id));
                dispatch(setBorrowAccountDestination('personal'));
              }
              navigate({ to: account.to ?? '/d/home' });
            };

            return (
              <SideNavLink
                selected={selectedNavigation.activeL2Nav === account.id}
                key={`borrow-margin-${account.id}`}
                label={account.name}
                subText={account.descriptor?.toString() ?? ''}
                onClick={onClick}
              />
            );
          })}
        </>
      ) : null}

      <SideNavLink
        selected={
          !selectedMarginId &&
          selectedNavigation.activeL1Nav === 'borrow' &&
          selectedNavigation.activeL3Nav === 'marketing'
        }
        key="add-loan"
        icon={{
          position: 'right',
          default: 'addBubble16',
          active: 'addBubble16',
        }}
        label="Take a loan"
        subText={`${canAddPersonalText ? 'Personal or ' : ''}Margin`}
        linkTo="/d/borrow"
        onClick={() => {
          // Sets the first id as the active account for the "Go to loan dashboard CTA" in "BorrowAccountDetailsPage"
          dispatch(setActiveBorrowAccount(marginAccounts[0]?.id ?? ''));
          dispatch(
            setActivePersonalLoanAccount(personalLoanAccounts[0]?.id ?? ''),
          );
          navigate({ to: '/d/borrow/marketing' });
        }}
        analyticsProps={{
          name: 'add_account_clicked',
          parameters: {
            product: 'borrow',
          },
        }}
        id="add-account-borrow"
      />
    </>
  );
};
