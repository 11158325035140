import {
  Flex,
  Maybe,
  Box,
  Tooltip,
  PM,
  SemanticColorNames,
} from '@m1/liquid-react';
import React from 'react';

import { AppTooltip, TabularDataItemFragment } from '~/graphql/types';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';

export const formatTabularData = (
  accountDetails: Maybe<TabularDataItemFragment>[],
) => {
  return accountDetails.map((item) => {
    let label: React.ReactNode = item?.title;
    let data: React.ReactNode = item?.value;

    if (item?.titleLink || item?.tooltip) {
      label = (
        <Flex>
          {item?.titleLink ? (
            <LinkableLink
              id={`link-${item?.titleLink?.title}`}
              linkable={item?.titleLink}
              height={20}
              fontSize="14px"
              whiteSpace="nowrap"
            />
          ) : (
            <PM
              whiteSpace="nowrap"
              color="foregroundNeutralSecondary"
              content={item?.title}
            />
          )}
          {item?.tooltip && formatTooltip(item?.tooltip)}
        </Flex>
      );
    }

    if (item?.valueLink) {
      data = (
        <LinkableLink
          id={`link-${item?.valueLink?.title}`}
          linkable={item?.valueLink}
          height={20}
          fontSize="14px"
          whiteSpace="nowrap"
        />
      );
    }

    return { label, data };
  });
};

export const formatTooltip = (tooltip: AppTooltip) => {
  return (
    <>
      {Boolean(tooltip) && (
        <Tooltip
          iconColor={tooltip.icon.color as SemanticColorNames}
          header={tooltip?.header}
          body={
            <>
              {tooltip?.body.map((p, i) => (
                <Box
                  as="p"
                  key={p}
                  mb={i >= (tooltip?.body?.length ?? 0) - 1 ? 0 : 8}
                >
                  {p}
                </Box>
              ))}
            </>
          }
          placement="bottom-start"
        />
      )}
    </>
  );
};
