import { Box, Button, Flex, PL } from '@m1/liquid-react';
import isBoolean from 'lodash-es/isBoolean';
import * as React from 'react';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';

import { ControlledInput } from '~/components/form/ControlledInput';

import { BeneficiaryInfoFragment } from '~/graphql/types';
import { DropdownOption } from '~/toolbox/Dropdown';

const beneficiaryTypeOptions: DropdownOption[] = [
  {
    label: 'Primary',
    value: 'primary',
  },
  {
    label: 'Contingent',
    value: 'contingent',
  },
];

type BeneficiaryRowProps<T extends FieldValues> = {
  name: Path<T>;
  beneficiary: BeneficiaryInfoFragment;
  formMethods: UseFormReturn<T>;
  onEditBeneficiaryInfo: () => void;
  onRemoveBeneficiary: () => void;
  onRestoreBeneficiary: () => void;
  isRemoved: boolean;
};

export const BeneficiaryRow = <T extends FieldValues>({
  name,
  beneficiary,
  formMethods,
  onEditBeneficiaryInfo,
  onRemoveBeneficiary,
  onRestoreBeneficiary,
  isRemoved,
}: BeneficiaryRowProps<T>) => {
  const { lineOne, lineTwo, city, stateOrProvince, postalCode } =
    beneficiary.address;
  const address = `${lineOne}${
    lineTwo ? ` ${lineTwo},` : ','
  } ${city}, ${stateOrProvince} ${postalCode}`;

  const isPrimary = React.useMemo(() => {
    if (beneficiary.isPrimary === true) {
      return 'primary';
    }

    if (isBoolean(beneficiary.isPrimary)) {
      return 'contingent';
    }

    return null;
  }, [beneficiary.isPrimary]);

  return (
    <Flex flexDirection="row" alignItems="baseline" justifyContent="stretch">
      <Box width="20%" pr={5}>
        <Button
          kind="link"
          label={`${beneficiary.firstName} ${beneficiary.lastName}`}
          style={{
            textDecoration: isRemoved ? 'line-through' : 'none',
          }}
          onClick={onEditBeneficiaryInfo}
        />
      </Box>
      <Box width="40%" px={5}>
        <span style={{ textDecoration: isRemoved ? 'line-through' : '' }}>
          {address}
        </span>
      </Box>
      <Box width="24%" px={5}>
        {/* Uncontrolled dropdown because this field should be a checkbox, but is a dropdown */}
        <ControlledDropdown
          name={`${name}.isPrimary` as Path<T>}
          control={formMethods.control}
          options={beneficiaryTypeOptions}
          value={isPrimary}
          disabled={isRemoved}
          transformValue={(value) => (value ? 'primary' : 'contingent')}
          transformInput={(value) => value === 'primary'}
        />
      </Box>
      <Box width="8%" minWidth={105} px={5}>
        <ControlledInput
          name={`${name}.sharePercentage` as Path<T>}
          control={formMethods.control}
          disabled={isRemoved}
          rules={{
            pattern: {
              message: 'Value must be between 0-100',
              value: /\d{3}/,
            },
            min: 0,
            max: 100,
          }}
          maxLength={3}
          kind="simple"
          additionalContent={<PL color="foregroundNeutralMain" content="%" />}
          transformInput={(value) => (value ? parseInt(`${value}`, 10) : '')}
          // transformValue={(v) => parseInt(`${v}`, 10)}
        />
      </Box>
      <Box width="8%" pl={5}>
        <Button
          kind="link"
          onClick={isRemoved ? onRestoreBeneficiary : onRemoveBeneficiary}
          label={isRemoved ? 'Restore' : 'Remove'}
        />
      </Box>
    </Flex>
  );
};
