import capitalize from 'lodash-es/capitalize';
import { SagaIterator } from 'redux-saga';
import {
  call,
  fork,
  getContext,
  put,
  select,
  setContext,
  takeEvery,
} from 'redux-saga/effects';

import {
  InvestPortfolioDetailsHeaderDocument,
  PreloadSetOrderDocument,
  SetPortfolioSliceOrderDocument,
} from '~/graphql/hooks';
import {
  ManualPortfolioSliceOrderTypeEnum,
  PortfolioSlice,
  SetPortfolioSliceOrderMutationVariables,
} from '~/graphql/types';
import {
  ACTION_TYPES as ACTIONS,
  ClickedOrderDirectionToggleAction,
  hideLoadingSpinner,
  navigate,
  SET_ORDER_ACTIONS,
  showLoadingSpinner,
} from '~/redux/actions';
import { SET_ORDER_FLOW_STEPS } from '~/redux/reducers/newFlows/newFlowsReducer.definition';
import { SetOrderDefinition } from '~/redux/reducers/newFlows/reducers/setOrderReducer';
import { apolloMutationSaga } from '~/redux/sagas/apolloMutationSaga';
import {
  apolloQuerySaga,
  apolloRefetchSaga,
} from '~/redux/sagas/apolloQuerySaga';

import { getLoggers } from '../../common';
import { createSagaHelpers } from '../utils';

const { changeStep, takeFlow, takeFlowStep } =
  createSagaHelpers(SetOrderDefinition);

export function* setOrderSaga(): SagaIterator<void> {
  yield fork(takeFlow, beginSetOrderFlow);
}

function* beginSetOrderFlow(action: any): SagaIterator<void> {
  const isBuy = action.payload.direction === 'buy';
  let isAutoInvestActive = false;
  const { portfolioSliceId } = action.payload;

  const {
    data: { portfolioSlice },
  } = yield call(apolloQuerySaga, {
    query: PreloadSetOrderDocument,
    variables: {
      portfolioSliceId,
    },
    fetchPolicy: 'network-only',
  });

  isAutoInvestActive = portfolioSlice.account.maxCashThreshold !== null;

  yield setContext({
    portfolioSliceId,
    portfolioSliceName: portfolioSlice.to.name,
    isCryptoAccount: portfolioSlice.account.isCryptoAccount,
  });

  let nextStep: keyof typeof SET_ORDER_FLOW_STEPS = isOpenForOrders(
    portfolioSlice,
  )
    ? 'SETUP_ORDER'
    : 'ORDER_LOCKED';

  if (
    nextStep === 'SETUP_ORDER' &&
    isBuy &&
    action.payload.showBuyingPowerOverview
  ) {
    nextStep = 'BUYING_POWER_OVERVIEW';
  }

  yield fork(takeFlowStep, 'ORDER_LOCKED', finishedOrderLocked);
  yield fork(
    takeFlowStep,
    'BUYING_POWER_OVERVIEW',
    finishedBuyingPowerOverview,
  );
  yield fork(takeFlowStep, 'SETUP_ORDER', finishedConfirmOrder);
  yield fork(takeFlowStep, 'CONFIRM_CRYPTO_ORDER', finishedConfirmOrder);
  yield fork(takeFlowStep, 'ORDER_BLOCKED', handleBlockOrder);

  yield takeEvery(
    'AUTO_INVEST_TOGGLE',
    function* (action: {
      type: 'AUTO_INVEST_TOGGLE';
      payload: boolean;
    }): SagaIterator<void> {
      isAutoInvestActive = action.payload;
      const isOrderActive = yield select(
        (state) => state.newFlows.SET_ORDER._isFlowRunning,
      );
      if (isOrderActive) {
        yield call(changeStep, 'SETUP_ORDER');
      }
    },
  );

  yield takeEvery(
    SET_ORDER_ACTIONS.CLICKED_ORDER_DIRECTION_TOGGLE,
    function* (action: ClickedOrderDirectionToggleAction): SagaIterator<void> {
      // If auto-invest is active, block sell.
      if (action.payload === 'sell' && isAutoInvestActive) {
        yield call(changeStep, 'ORDER_BLOCKED');
      }
    },
  );

  // Also handle if navigating directly to sell order
  if (!isBuy && isAutoInvestActive) {
    nextStep = 'ORDER_BLOCKED';
  }

  yield call(changeStep, nextStep, true);
}

function* finishedOrderLocked() {
  yield put({
    type: ACTIONS.FINISHED_SET_ORDER_FLOW,
  });
}

function* handleBlockOrder(action: any): SagaIterator<void> {
  yield call(changeStep, 'SETUP_ORDER');
}

function* finishedBuyingPowerOverview(action: any): SagaIterator<void> {
  yield call(changeStep, 'SETUP_ORDER');
}

// Action payload comes from here:
// ~/flows/components/set-order/steps/setup-order/index.tsx#L292-L296
function* finishedConfirmOrder(action: any): SagaIterator<void> {
  const isCryptoAccount = yield getContext('isCryptoAccount');

  if (isCryptoAccount && !action.payload.confirmed) {
    yield call(changeStep, 'CONFIRM_CRYPTO_ORDER');
  } else {
    const { analytics } = yield call(getLoggers);

    yield put(showLoadingSpinner());

    const portfolioSliceId = yield getContext('portfolioSliceId');

    /*
     * If the direction is buy and the marginCashFlow is greater than 0, then the order is a margin buy.
     * Currently Lens is bifurcating BE requests based on orderType & marginCashFlow.
     * If neither is provided, the order is a cash buy and will continue to use the existing endpoint.
     */
    const isMarginBuy =
      action.payload.direction === 'buy' && action.payload.marginCashFlow > 0;
    try {
      yield call(apolloMutationSaga, {
        mutation: SetPortfolioSliceOrderDocument,
        variables: {
          input: {
            cashFlow: action.payload.cashFlow,
            orderType: isMarginBuy
              ? ManualPortfolioSliceOrderTypeEnum.Buy
              : undefined,
            portfolioSliceId,
            marginCashFlow: isMarginBuy
              ? action.payload.marginCashFlow
              : undefined,
          },
        } satisfies SetPortfolioSliceOrderMutationVariables,
      });
      // refetch for updated buying power values.
      yield call(apolloRefetchSaga, [InvestPortfolioDetailsHeaderDocument]);

      const tradeWindow = action.payload.tradeWindow.toLowerCase();
      analytics.recordEvent(
        `m1_invest_${action.payload.direction}_order_success`,
        null,
        { is_margin_order: isMarginBuy },
      );

      const marginToastCopy = isMarginBuy
        ? ' Margin borrowing will occur at that time.'
        : '';

      yield put({
        payload: {
          content: `${capitalize(
            action.payload.direction,
          )} order placed. We’ll execute it for you during the ${tradeWindow} trade window.${marginToastCopy}`,
          duration: 'long',
          kind: 'success',
        },
        type: 'ADD_TOAST',
      });
      yield put(navigate({ to: `/d/invest/portfolio/${portfolioSliceId}` }));
    } catch (e: any) {
      analytics.recordEvent(
        `m1_invest_${action.payload.direction}_order_error`,
        null,
        { error: e.message },
      );
      const isCancelOrder =
        action.payload.cashFlow === 0 && action.payload.marginCashFlow === 0;
      yield put({
        payload: {
          content: `Something went wrong and your order was not ${isCancelOrder ? 'cancelled' : 'placed'}. Try again or contact us.`,
          kind: 'alert',
          link: {
            content: 'Contact us',
            onClick: () => {
              if (window.Intercom === undefined) {
                window.open('https://help.m1.com', '_blank');
              } else {
                window.Intercom('show');
              }
            },
          },
          fullWidth: true,
        },
        type: 'ADD_TOAST',
      });
    }

    yield put(hideLoadingSpinner());
  }
}

function isOpenForOrders(
  portfolioSlice: PortfolioSlice | null | undefined,
): boolean {
  return Boolean(
    portfolioSlice &&
      portfolioSlice.orderStatus &&
      !portfolioSlice.orderStatus.isLocked,
  );
}
