import defaults from 'lodash-es/defaults';
import isEmpty from 'lodash-es/isEmpty';
import moment from 'moment';

import { isNotNil } from '~/utils';
import { formatPhoneNumber } from '~/utils/formatting';

const validEmail = /^(.+?)@(.+?\.)+(.+?)$/;
export const invalidWhiteSpace = /^\s+$/;
export const validInput = /^[\u0020-\u007E]*$/;
const validPostalCode = /^[0-9]{5}(?:-[0-9]{4})?$/;
const validPhoneNumber = /^[0-9]{10,}$/;
const validSocialSecurityNumber = /^[0-9]{9}$/;

export const isValidSsn = (value: string) => {
  /* Regex removes any non-numeric characters from string */
  const onlyNums = value.replace(/[^\d]/g, '');

  if (!validSocialSecurityNumber.test(onlyNums)) {
    return 'Invalid SSN';
  }
};

export function required(
  value: any | null | undefined,
): string | null | undefined {
  if (!value) {
    return 'Required';
  }
}

export function requireNonNil(
  value: any | null | undefined,
): string | undefined {
  if (value === undefined || value === null) {
    return 'Required';
  }
}

export function printableAsciiChars(value: string): string | undefined {
  if (invalidWhiteSpace.test(value) || !validInput.test(value)) {
    return 'Invalid input';
  }
}

export function isAlphaNumeric(value: string): string | null | undefined {
  if (value && /[^\w]/.test(value)) {
    return 'Invalid input';
  }
}

export function isAlphabetical(value: string): string | null | undefined {
  if (value && !/^[A-Za-z ]*$/u.test(value)) {
    return 'Must be alphabetical';
  }
}

export function length(exactLength: number): (...args: Array<any>) => any {
  return function (value: string): string | null | undefined {
    if (value && value.length !== exactLength) {
      return `Must be exactly ${exactLength} characters.`;
    }
  };
}

export function minLength(minLength: number): (...args: Array<any>) => any {
  return function (value: string): string | null | undefined {
    if (value && value.length < minLength) {
      return `Must be more than ${minLength - 1} characters.`;
    }
  };
}

export function maxLength(maxLength: number): (...args: Array<any>) => any {
  return function (value: string): string | null | undefined {
    if (value && value.length > maxLength) {
      return `Must be no more than ${maxLength} characters.`;
    }
  };
}

export function postalCode(value: string): string | undefined {
  if (!validPostalCode.test(value)) {
    return 'Invalid postal code';
  }
}

export function optionalPhoneNumber(
  value: string | null | undefined,
): string | null | undefined {
  if (value) {
    return phoneNumber(value);
  }
}

export function phoneNumber(value: string): string | undefined {
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!validPhoneNumber.test(onlyNums)) {
    return 'Invalid phone number';
  }
}

export function securePassword(value: string): string | null | undefined {
  const hasLength = value.length >= 10;
  const hasLowerCase = /[a-z]{1,}/.test(value);
  const hasNumber = /\d/.test(value);
  const hasUpperCase = /[A-Z]{1,}/.test(value);
  const invalid = !(hasLength && hasLowerCase && hasNumber && hasUpperCase);
  if (invalid) {
    return 'It should contain at least 10 characters, one lowercase, one uppercase, and one number.';
  }
}

export function ssn(value: string): string | null | undefined {
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!validSocialSecurityNumber.test(onlyNums)) {
    return 'Invalid SSN';
  }
}

export function date(args: Record<string, any>): (...args: Array<any>) => any {
  const defaultArgs = {
    checkAge: false,
    checkCustodialAccountAge: false,
    rejectFutureDateOfBirth: false,
    format: 'MM/DD/YYYY',
  };
  const {
    checkAge,
    checkCustodialAccountAge,
    rejectFutureDateOfBirth,
    format,
  } = defaults(args, defaultArgs);

  return function (value): string | null | undefined {
    if (!isEmpty(value)) {
      const valueMoment = moment(value, format, true);

      const isValidDate = valueMoment.isValid();
      if (!isValidDate) {
        return `Invalid format, must be ${format}`;
      }

      const now = moment();
      const valueYear = valueMoment.year();
      const currentYear = now.year();
      if (rejectFutureDateOfBirth) {
        if (valueMoment.isAfter(now)) {
          return 'Date of birth cannot be a future date.';
        }
      }
      if (valueYear > currentYear || valueYear < currentYear - 120) {
        return 'Please enter a valid date.';
      }

      if (checkCustodialAccountAge) {
        const isOver21 = now.subtract(21, 'years').isAfter(valueMoment);
        if (isOver21) {
          return 'The account holder must be under 21 years of age.';
        }
      }

      if (checkAge) {
        const isOver18 = now.subtract(18, 'years').isAfter(valueMoment);
        if (!isOver18) {
          return 'You must be over the age of 18.';
        }
      }
    }
  };
}

export function bankRoutingNumber(value: string): string | null | undefined {
  if (value && !/^[0-9]{9}$/.test(value)) {
    return 'Invalid bank routing number';
  }
}

export function bankAccountNumber(value: string): string | null | undefined {
  if (value && !/^[0-9-]{1,17}$/.test(value)) {
    return 'Invalid bank account number';
  }
}

export function minOf(
  min: number,
  feedback: string | null | undefined,
): (...args: Array<any>) => any {
  const message = feedback || `Value must be at least ${min}.`;

  return function (value: string): string | null | undefined {
    const num = Number(value);
    if (num < min) {
      return message;
    }
  };
}

export function greaterThan(
  min: number,
  feedback: string | null | undefined,
): (...args: Array<any>) => any {
  const message = feedback || `Value must exceed ${min}.`;

  return function (value: string): string | null | undefined {
    const num = Number(value);
    if (num <= min) {
      return message;
    }
  };
}

export function maxOf(
  max: number,
  feedback: string | null | undefined,
): (...args: Array<any>) => any {
  const message = feedback || `Value must be at most ${max}.`;

  return function (value: string): string | null | undefined {
    const num = Number(value);
    if (num > max) {
      return message;
    }
  };
}

export function email(value: string): string | null | undefined {
  if (value && !validEmail.test(value)) {
    return 'Invalid email address';
  }
}

export function emailValidator(value: string): string | boolean {
  return (value && validEmail.test(value)) || 'Invalid email address';
}

export function passwordValidator(value: string): string | boolean {
  // password must be at least 10 characters long
  // include 1 number, 1 uppercase letter, 1 lowercase letter
  return (
    (value.length >= 10 &&
      /[0-9]/.test(value) &&
      /[A-Z]/.test(value) &&
      /[a-z]/.test(value)) ||
    'Invalid password'
  );
}

export function requiredLoanPurpose(value: string): string | null | undefined {
  if (!value) {
    return "Please select a reason you'll be using the loan.";
  }
  return null;
}
export function requiredLoanTerm(value: string): string | null | undefined {
  if (!value) {
    return 'Please select a term for the loan.';
  }
  return null;
}
export function requiredLoanAmount(value: string): string | null | undefined {
  if (!value) {
    return 'Amount must be entered to continue';
  }
  if (parseInt(value, 10) < 2500) {
    return 'The minimum amount is $2,500';
  }
  if (parseInt(value, 10) > 50000) {
    return 'The maximum amount is $50,000';
  }
  return null;
}

export function valueMatchesInput(
  valueToBeMatched: string | undefined,
  message: string,
): (...args: Array<any>) => any {
  return function (value) {
    if (value !== valueToBeMatched) {
      return message;
    }
  };
}

export function emailTypoWarning(value: string): string | undefined {
  if (value) {
    const domain = value.split('@')[1];

    if (domain) {
      const gmailVariants = ['gmial', 'gmai', 'gamil', 'gnail'];
      const hotmailVariants = ['hotnail', 'hormail', 'hotmal', 'hotmai'];
      const yahooVariants = ['yaho', 'yhaoo', 'yahho', 'yahoomail'];
      const outlookVariants = ['outlok', 'oulook', 'outook'];
      const concattedVariants = Array.prototype.concat(
        gmailVariants,
        hotmailVariants,
        yahooVariants,
        outlookVariants,
      );

      const found = value
        .split('@')[1]
        .match(
          new RegExp(`(${concattedVariants.join('|')})(?=\\.)|\\.con$`, 'gi'),
        );

      if (found) {
        return `Are you sure you meant ${found.join('')}?`;
      }
    }
  }
  return undefined;
}

export const confirmEmailMatching = (confirmEmail: string, email: string) => {
  // A function that compares the 2nd of 2 emails when filling out a form
  // first check if there is a typo (otherwise message = undefined)
  let message = emailTypoWarning(confirmEmail);

  if (!validEmail.test(confirmEmail)) {
    message = 'Invalid email address';
  }

  if (confirmEmail && email) {
    // If both emails have been input AND match return undefined
    // This works in the case where the first email might have a typo/be invalid
    message = confirmEmail === email ? undefined : 'Emails must match';
  }

  return message;
};

export const jointAccountFormEmailValidator = (
  value: string,
  emailValue: string,
  userAEmail?: string,
) => {
  const emailValidator = email(value);
  const emailTypeWarning = emailTypoWarning(emailValue);
  if (typeof emailValidator === 'string') {
    return emailValidator;
  } else if (typeof emailTypeWarning === 'string') {
    return emailTypeWarning;
  } else if (userAEmail && value === userAEmail) {
    return 'Only enter information for the person you want to invite to this account.';
  }

  return undefined;
};

export const jointAccountPhoneNumberValidator = (
  value: string,
  primaryUserPhoneNumber?: string,
): string | undefined => {
  const phoneNumberValidator = phoneNumber(value);
  if (
    primaryUserPhoneNumber &&
    (value === formatPhoneNumber(primaryUserPhoneNumber) ||
      value === primaryUserPhoneNumber)
  ) {
    // The condition compares the value and a formatted userAPhoneNumber
    // b/c the value is being formatted in the PhoneNumberField component
    return 'Only enter information for the person you want to invite to this account.';
  } else if (typeof phoneNumberValidator === 'string') {
    return phoneNumberValidator;
  }

  return undefined;
};

export const trimWhitespace = (
  value: string | null | undefined,
): string | null | undefined => {
  if (isNotNil(value)) {
    return value.trim();
  }
  return value;
};
