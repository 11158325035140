import { styled } from '@m1/liquid-react';

import { collectPaddingStyles } from '../utils';

export const StyledInputBox = styled.div<{
  disabled?: boolean;
  hidden?: boolean;
  kind?: 'simple' | 'with-spacing' | 'basic';
  size?: 'medium' | 'large' | 'xlarge';
}>`
  color: ${({ disabled, theme }) => {
    if (disabled) {
      return theme.colors.foregroundNeutralTertiary;
    }
    return theme.colors.foregroundNeutralMain;
  }};
  display: ${({ hidden }) => hidden && 'none'};
  position: relative;
  width: 100%;
  ${({ kind }) => collectPaddingStyles(kind)};

  transition: all 0.3s ease-in-out;
`;
