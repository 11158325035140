import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { CryptoPage } from './CryptoPage';

export const CryptoRoute = () => {
  return <Route path="crypto" component={createRouteComponent(CryptoPage)} />;
};
