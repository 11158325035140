import {
  Box,
  Button,
  Card,
  Flex,
  FloatingPanel,
  HXXS,
  Maybe,
  PM,
  Tooltip,
} from '@m1/liquid-react';
import * as React from 'react';

import {
  useMoveSlicesBatchMutation,
  useUpdatePieTreeMutation,
} from '~/graphql/hooks';
import { clearPieShareUrlData } from '~/graphql/local/reactiveVars/pieShareUrl';
import { MoveSlicesInput } from '~/graphql/types';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { useDispatch, useSelector } from '~/redux/hooks';
import {
  selectEmptyPies,
  selectPieEditorInactiveItems,
  selectPieEditorInvalidPercentage,
} from '~/redux/selectors/pieEditorSelectors';
import { useToast } from '~/toasts';

import { AiCard } from './PieEditor.styled';
import { HighlightEvent } from './PieEditor.types';
import {
  checkIfTreeIsCrypto,
  mapTreeItemsToRemotePieString,
} from './PieEditor.utils';

export const PieEditorSubmit = () => {
  const dispatch = useDispatch();
  const [showErrorPanel, setShowErrorPanel] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [updatePieTree, { loading: updatePieTreeLoading }] =
    useUpdatePieTreeMutation();
  const [moveSlicesBatch, { loading: moveSlicesBatchLoading }] =
    useMoveSlicesBatchMutation();
  const { addToast } = useToast();
  usePortaledSpinner(updatePieTreeLoading || moveSlicesBatchLoading);
  const incompletePies = useSelector(selectPieEditorInvalidPercentage);
  const inactiveItems = useSelector(selectPieEditorInactiveItems);
  const emptyPies = useSelector(selectEmptyPies);

  const { kind, history, autoFix } = useSelector((state) => state.pieEditor);
  const items = history.at(-1)?.tree;
  const isCrypto = items ? checkIfTreeIsCrypto(items) : false;

  const hasIncompletePies = Boolean(incompletePies?.length);
  const hasInactiveItems = Boolean(inactiveItems?.length);
  const hasEmptyPies = Boolean(emptyPies?.length);
  const hasErrors = hasIncompletePies || hasInactiveItems || hasEmptyPies;

  const scrollToSlice = ({
    id,
    highlight,
    uncollapse,
  }: {
    id: string;
    highlight?: HighlightEvent['type'];
    uncollapse?: boolean;
  }) => {
    uncollapse &&
      dispatch({
        type: 'PIE_EDITOR_UPDATE_COLLAPSED',
        payload: {
          id,
          collapsed: false,
          actOnParent: true,
        },
      });
    setTimeout(() => {
      const el = document.getElementById(id);
      el?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 1000);
    if (highlight) {
      const e = new CustomEvent<HighlightEvent>('pie-editor-highlight', {
        detail: {
          ids: [id],
          type: 'row',
        },
      });
      window.dispatchEvent(e);
    }
  };

  const handleSuccess = React.useCallback(
    (pieId: Maybe<string>, successMessage?: Maybe<string>) => {
      addToast({
        content: successMessage ?? 'Your pie has been updated.',
        kind: 'success',
      });
      dispatch({
        type: 'PIE_EDITOR_CLEAR_PIE',
      });
      let previousRoute =
        location.query.previousRouteName ??
        `/d/research/my-pies/details/${pieId}`;
      if (previousRoute.includes('my-pies')) {
        previousRoute = `/d/research/my-pies/details/${pieId}`;
      }
      navigate({
        to: previousRoute,
      });
      dispatch({ type: 'PIE_EDITOR_CLEAR_PIE' });
    },
    [addToast, dispatch, location.query.previousRouteName, navigate],
  );

  const handlePieTreeUpdate = React.useCallback(() => {
    if (items) {
      updatePieTree({
        variables: {
          input: {
            serializedTree: mapTreeItemsToRemotePieString(items),
            isCrypto,
          },
        },
        onError: (e) => {
          addToast({
            content: e.message,
            kind: 'alert',
            duration: 'long',
          });
        },
        onCompleted: ({ updatePieTree }) => {
          const pieId = updatePieTree?.pie?.id;
          if (pieId) {
            clearPieShareUrlData(pieId);
          }
          handleSuccess(pieId);
        },
      });
    }
  }, [items, isCrypto, navigate, updatePieTree, addToast, location, dispatch]);

  const fixAllEmptyPies = () => {
    setShowErrorPanel(false);
    emptyPies?.forEach((pie) => {
      const id = String(pie.id);
      dispatch({
        type: 'PIE_EDITOR_REMOVE_SLICE',
        payload: {
          id,
        },
      });
    });
  };

  const fixAllIncompletePies = () => {
    setShowErrorPanel(false);
    incompletePies?.forEach((pie) => {
      const id = String(pie.id);
      dispatch({
        type: 'PIE_EDITOR_EQUALIZE_PIE',
        payload: {
          id,
          meta: { pie: pie.meta.name ?? 'pie' },
        },
      });
    });
  };

  const fixAllInactiveSlices = () => {
    setShowErrorPanel(false);
    inactiveItems?.forEach((item) => {
      dispatch({
        type: 'PIE_EDITOR_REMOVE_SLICE',
        payload: {
          id: item.id,
        },
      });
    });
  };

  if (autoFix) {
    if (hasEmptyPies) {
      fixAllEmptyPies();
    } else if (hasIncompletePies) {
      fixAllIncompletePies();
    } else if (hasInactiveItems) {
      fixAllInactiveSlices();
    } else {
      dispatch({
        type: 'PIE_EDITOR_AUTO_FIX',
        payload: { enabled: false },
      });
    }
  }

  const handlePieTreeUpdateWithMoveSlices = React.useCallback(async () => {
    const movesInputs = history.reduce((acc, history) => {
      if (history.type === 'move' && history.meta.moveSlicesInput) {
        acc.push(history.meta.moveSlicesInput);
      }
      return acc;
    }, [] as MoveSlicesInput[]);
    if (kind === 'PORTFOLIO' && !isCrypto && items && movesInputs.length > 0) {
      // Move slices batch handles all moves and final pie tree update since this can take a while
      await moveSlicesBatch({
        variables: {
          input: {
            moves: movesInputs,
            finalPieTreeInput: {
              serializedTree: mapTreeItemsToRemotePieString(items),
              isCrypto,
            },
          },
        },
        onError: () => {
          addToast({
            content: 'Unable to update pie tree.',
            kind: 'alert',
            duration: 'long',
          });
        },
        onCompleted: ({ moveSlicesBatch }) => {
          if (!moveSlicesBatch?.didSucceed) {
            addToast({
              content: 'Failed to move slices and update pie.',
              kind: 'alert',
              duration: 'long',
            });
          } else {
            handleSuccess(
              moveSlicesBatch.outcome?.pie?.id,
              'Your slice values have moved and your pie has been updated.',
            );
          }
        },
      });
    } else {
      handlePieTreeUpdate();
    }
  }, [
    items,
    isCrypto,
    handlePieTreeUpdate,
    moveSlicesBatch,
    addToast,
    history,
    kind,
    handleSuccess,
  ]);

  return hasErrors ? (
    <FloatingPanel
      controlled
      active={showErrorPanel}
      placement="bottom-end"
      onHide={() => setShowErrorPanel(false)}
      maxWidth={448}
      content={
        <Flex
          p={16}
          gap={16}
          flexDirection="column"
          maxHeight="50vh"
          overflowY="scroll"
        >
          {hasErrors && (
            <AiCard>
              <HXXS fontWeight={300}>
                You have multiple errors in your Pie. The errors are listed
                below. You can fix the errors one at a time or click below to
                fix them all at once.{' '}
                <Tooltip
                  body={
                    <Box p={16}>
                      <PM mb={16}>
                        If invalid slices are removed from your Pie, the Pie
                        will be equalized. Changing targets will not cause
                        sales. New deposits will go toward underweight and new
                        slices.
                      </PM>
                      <PM>
                        Changes should be reviewed before saving. Check
                        estimated trades to understand the impact of your
                        changes in the next trading window.
                      </PM>
                    </Box>
                  }
                />
              </HXXS>
              <Button
                fullWidth
                mt={16}
                kind="primary"
                size="medium"
                label="Fix all"
                onClick={() => {
                  setShowErrorPanel(false);
                  dispatch({
                    type: 'PIE_EDITOR_AUTO_FIX',
                    payload: { enabled: true },
                  });
                }}
              />
            </AiCard>
          )}
          {emptyPies?.length && emptyPies.length > 1 && (
            <Card>
              <HXXS fontWeight={300}>You have empty pies.</HXXS>
              <Button
                fullWidth
                mt={16}
                kind="secondary"
                size="medium"
                label="Remove all empty pies"
                onClick={() => {
                  fixAllEmptyPies();
                }}
              />
            </Card>
          )}
          {emptyPies?.map((pie) => (
            <Card key={pie.id}>
              <HXXS fontWeight={300}>
                Pie{' '}
                {
                  <Button
                    kind="link"
                    title={`Scroll to ${pie.meta.name}`}
                    onClick={() => {
                      setShowErrorPanel(false);
                      scrollToSlice({
                        id: String(pie.id),
                        uncollapse: true,
                        highlight: 'percentage',
                      });
                    }}
                    label={pie.meta.name ?? 'pie'}
                    underline
                  />
                }{' '}
                has no child slices.
              </HXXS>
              <Button
                fullWidth
                mt={16}
                kind="secondary"
                size="medium"
                label="Remove empty pie"
                onClick={() => {
                  setShowErrorPanel(false);
                  const id = String(pie.id);
                  scrollToSlice({ id });
                  dispatch({
                    type: 'PIE_EDITOR_REMOVE_SLICE',
                    payload: {
                      id,
                    },
                  });
                }}
              />
            </Card>
          ))}
          {incompletePies?.length && incompletePies.length > 1 && (
            <Card>
              <HXXS fontWeight={300}>
                You have multiple pies that do not sum up to 100%.
              </HXXS>
              <Button
                fullWidth
                mt={16}
                kind="secondary"
                size="medium"
                label="Equalize all pies"
                onClick={() => {
                  fixAllIncompletePies();
                }}
              />
            </Card>
          )}
          {incompletePies?.map((pie) => (
            <Card key={pie.id}>
              <HXXS fontWeight={300}>
                Pie{' '}
                {
                  <Button
                    kind="link"
                    title={`Scroll to ${pie.meta.name}`}
                    onClick={() => {
                      setShowErrorPanel(false);
                      scrollToSlice({
                        id: String(pie.id),
                        uncollapse: true,
                        highlight: 'percentage',
                      });
                    }}
                    underline
                    label={pie.meta.name ?? 'pie'}
                  />
                }{' '}
                does not sum up to 100%.
              </HXXS>
              <Button
                mt={16}
                kind="secondary"
                size="medium"
                label="Equalize pie"
                fullWidth
                onClick={() => {
                  setShowErrorPanel(false);
                  const id = String(pie.id);
                  scrollToSlice({ id });
                  dispatch({
                    type: 'PIE_EDITOR_EQUALIZE_PIE',
                    payload: {
                      id,
                      meta: { pie: pie.meta.name ?? 'pie' },
                    },
                  });
                }}
              />
            </Card>
          ))}
          {inactiveItems?.length && inactiveItems.length > 1 && (
            <Card>
              <HXXS fontWeight={300}>
                You have multiple slices that are delisted or inactive. You must
                remove them to save your pie.
              </HXXS>
              <Button
                fullWidth
                mt={16}
                kind="secondary"
                size="medium"
                label="Remove inactive slices"
                onClick={() => {
                  fixAllIncompletePies();
                }}
              />
            </Card>
          )}
          {inactiveItems?.map((item) => (
            <Card key={item.id}>
              <HXXS fontWeight={300}>
                Slice{' '}
                {
                  <Button
                    kind="link"
                    title={`Scroll to ${item.meta.securityInfo?.symbol}`}
                    label={item.meta.securityInfo?.symbol ?? 'slice'}
                    onClick={() => {
                      setShowErrorPanel(false);
                      scrollToSlice({
                        id: String(item.id),
                        uncollapse: true,
                        highlight: 'row',
                      });
                    }}
                    underline
                  />
                }{' '}
                is delisted or inactive. You must remove it to save your pie.
              </HXXS>
              <Button
                fullWidth
                mt={16}
                kind="secondary"
                size="medium"
                label="Remove inactive slice"
                onClick={() => {
                  setShowErrorPanel(false);
                  const id = String(item.id);
                  scrollToSlice({ id });
                  dispatch({
                    type: 'PIE_EDITOR_REMOVE_SLICE',
                    payload: {
                      id,
                    },
                  });
                }}
              />
            </Card>
          ))}
        </Flex>
      }
      trigger={
        <Button
          label="Fix errors"
          size="medium"
          kind="primary"
          onClick={() => setShowErrorPanel(true)}
        />
      }
    />
  ) : (
    <Button
      label="Save"
      size="medium"
      kind="primary"
      onClick={handlePieTreeUpdateWithMoveSlices}
      disabled={history.filter((h) => h.type !== 'init').length === 0}
    />
  );
};
