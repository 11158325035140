import { Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { PageErrorBoundary } from '~/components/page-error-boundary';
import { SetOrderFlow } from '~/flows';
import { useLocation } from '~/hooks/useLocation';
import { returnToResolveMarginCallInformation } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { SET_ORDER_FLOW_STEPS as STEPS } from '~/static-constants';

// Styled Components
const StyledContainer = styled(Flex)`
  margin: auto;
  width: 550px;
`;

export const SetOrder = () => {
  const [step, setStep] = React.useState<string>();
  const location = useLocation();
  const dispatch = useDispatch();
  const portfolioSliceId = useSelector(
    (state) => state.newFlows.RESOLVE_CALL.portfolioSliceId,
  );

  const handleOnChangeStep = (step: ValueOf<typeof STEPS>): void => {
    setStep(step);
  };

  return (
    <PageErrorBoundary>
      <StyledContainer flexDirection="column" pb={32} pt={100}>
        {step === STEPS.SETUP_ORDER && (
          <BackArrow
            content="Position Details"
            mb={24}
            onClick={() =>
              // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
              dispatch(returnToResolveMarginCallInformation())
            }
          />
        )}
        <SetOrderFlow
          basePath={location.pathname}
          direction="sell"
          isFromHoldingsPositionDetail={false}
          portfolioSliceId={portfolioSliceId}
          onChangeStep={handleOnChangeStep}
        />
      </StyledContainer>
    </PageErrorBoundary>
  );
};
