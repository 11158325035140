import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useEditContraEntryQuery } from '~/graphql/hooks';
import { Spinner } from '~/toolbox/spinner';

import { FulfillmentConditionRefetchContainerEditContra } from './FulfillmentConditionRefetchContainerEditContra';
import type { LocalContraEntry } from './types';

type EditContraEntryProps = {
  contraEntryToEdit: LocalContraEntry;
  editRequirementsId: string;
  onFinishEditContraEntry: (
    arg0: LocalContraEntry,
    arg1: 'REPLACE' | 'REMOVE',
  ) => void;
  smartTransferRuleId: string;
};

export const EditContraEntry = ({
  contraEntryToEdit,
  editRequirementsId,
  onFinishEditContraEntry,
  smartTransferRuleId,
}: EditContraEntryProps) => {
  const { data, loading, refetch } = useEditContraEntryQuery({
    variables: {
      editRequirementsId,
    },
  });

  if (loading) {
    return (
      <Box mt={32}>
        <Spinner />
      </Box>
    );
  }

  if (
    data?.node?.__typename !==
    'SmartTransferContraParticipantEntryEditRequirements'
  ) {
    return <GenericSystemError />;
  }
  return (
    <FulfillmentConditionRefetchContainerEditContra
      contraEntryToEdit={contraEntryToEdit}
      onFinishEditContraEntry={onFinishEditContraEntry}
      editRequirements={data.node}
      smartTransferRuleId={smartTransferRuleId}
    />
  );
};
