import React from 'react';
import { Field } from 'redux-form';

import { withProps } from '~/hocs';

import { CurrencyInput } from './CurrencyInput';
interface ReduxFieldInput {
  name: string;
  value: string | number | null;
  onChange: (value: string | number | null | React.SyntheticEvent) => void;
}

type ReduxFieldMeta = {
  active: boolean;
  asyncValidating: boolean;
  autofilled: boolean;
  dirty: boolean;
  dispatch: (action: any) => void;
  error: any;
  form: string;
  initial: any;
  invalid: boolean;
  pristine: boolean;
  submitFailed: boolean;
  submitting: boolean;
  touched: boolean;
  valid: boolean;
  visited: boolean;
  warning: boolean;
};

// NOTE -- This is how input + meta fields get populated from redux-form
const enhancer = withProps({
  component: ({
    input,
    meta,
  }: {
    input: ReduxFieldInput;
    meta: ReduxFieldMeta;
  }) => {
    // @ts-expect-error | TS complains about typing for onChange method, but it's fine.
    return <CurrencyInput {...{ ...input, ...meta }} />;
  },
});

export const CurrencyField = enhancer(Field) as any;
