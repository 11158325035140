import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { SmartTransferDetailsPage } from './SmartTransferDetailsPage';

export const SmartTransferDetailsRoute = () => {
  return (
    <Route
      component={createRouteComponent(SmartTransferDetailsPage, (props) => ({
        smartTransferRuleId: props.params.smartTransferRuleId,
      }))}
      cover
      fallbackRoute="/d/transfers/rules"
      path="smart-transfer-details/:smartTransferRuleId"
    />
  );
};
