import { Theme, PS, LS, Box, styled } from '@m1/liquid-react';
import * as React from 'react';
import { components } from 'react-select';

import { SelectProps } from './Dropdown.types';

type SelectContainerProps = {
  backgroundColor?: string;
  children?: React.ReactNode;
  selectProps: SelectProps & {
    appTheme: Theme;
  };
};

const StyledLabel = styled(LS)<
  Pick<SelectProps, 'error'> & {
    appTheme: Theme;
    backgroundColor?: 'backgroundNeutralMain' | 'backgroundNeutralSecondary';
    isDisabled?: boolean;
    isFocused?: boolean;
  }
>`
  background-color: ${(props) =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor]
      : props.theme.colors.backgroundNeutralSecondary};
  left: 8px;
  line-height: 1.5rem;
  padding: 0 8px;
  position: absolute;
  top: 1px;
  z-index: 1;
  font-weight: ${({ error }) => error && 600};
  color: ${({ error, isDisabled, isFocused, theme }) => {
    if (isDisabled) {
      return theme.colors.foregroundNeutralTertiary;
    }
    if (error) {
      return theme.colors.critical;
    }
    if (isFocused) {
      return theme.colors.primary;
    }
    return theme.colors.foregroundNeutralSecondary;
  }};
`;

const StyledMessage = styled(PS)<
  Pick<SelectProps, 'error'> & {
    isDisabled?: boolean;
  }
>`
  font-weight: ${({ error }) => error && 600};
  color: ${({ error, isDisabled, theme }) => {
    if (isDisabled) {
      return theme.colors.foregroundNeutralTertiary;
    }
    if (error) {
      return theme.colors.critical;
    }
    return theme.colors.foregroundNeutralSecondary;
  }};
`;

// FIXME(Wolf): Fix this once you upgrade react-select.
const ReactSelectSelectContainer = components.SelectContainer as any;

export class SelectContainer extends React.Component<SelectContainerProps> {
  render() {
    return (
      <ReactSelectSelectContainer {...this.props}>
        <Box position="relative" py={8}>
          {this.props.selectProps.label && (
            <StyledLabel
              htmlFor={this.props.selectProps.name}
              appTheme={this.props.selectProps.appTheme}
              backgroundColor={this.props.selectProps.backgroundColor}
              error={this.props.selectProps.error}
              isDisabled={this.props.selectProps.isDisabled}
              isFocused={this.props.selectProps.isFocused}
            >
              {this.props.selectProps.label}
            </StyledLabel>
          )}
          {this.props.children}
          {this.renderMessage()}
        </Box>
      </ReactSelectSelectContainer>
    );
  }

  renderMessage() {
    const { error, warning, info, isDisabled } = this.props.selectProps;
    const message = error || warning || info;
    if (message) {
      return (
        <Box pt={8}>
          <StyledMessage
            content={message}
            error={error}
            isDisabled={isDisabled}
          />
        </Box>
      );
    }
    return null;
  }
}
