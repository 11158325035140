import * as React from 'react';
import { InjectedRouteProps } from 'react-router';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { useNavigate } from '~/hooks/useNavigate';
import { beginResolveCallFlow } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

export const ResolveMarginCallLayout = (props: InjectedRouteProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const basePath = 'd/c/resolve-call';
  const previousRouteName = props.location.query.from || '/d/borrow';

  const handleFinish = React.useCallback(
    () => navigate({ to: previousRouteName }),
    [navigate, previousRouteName],
  );

  React.useEffect(() => {
    dispatch(
      beginResolveCallFlow({
        basePath,
        initialState: {
          borrowAccountId: props.location.query.borrowAccountId,
        },
        onFinish: handleFinish,
      }),
    );
  }, [dispatch, handleFinish, props.location.query.borrowAccountId]);

  const childrenWithProps = React.Children.map(props.children, (child) =>
    // @ts-expect-error - TS2769 - No overload matches this call.
    React.cloneElement(child, {
      handleFinish,
    }),
  );

  return (
    <div>
      <PageErrorBoundary onClickRetry={handleFinish} retryButtonLabel="Exit">
        {childrenWithProps}
      </PageErrorBoundary>
    </div>
  );
};
