import { TwoColumn3by9 } from '@m1/liquid-react';
import * as React from 'react';

import { useLaunchDarkly } from '~/hooks/useLaunchDarkly';
import { Navigation } from '~/toolbox/navigation';

import style from '../navStyles.module.scss';

import { AnnouncementsForDocuments } from './components/AnnouncementsForDocuments';
import { routes } from './routes';

const navItems = routes.map(({ label, path }) => ({
  to: `/d/settings/documents-beta/${path}`,
  label,
  className: style.link,
  activeClassName: style.active,
}));

export const DocumentsContainer = ({ children }: React.PropsWithChildren) => {
  const { flagResult: hasInvestmentsRelatedFlag } = useLaunchDarkly(
    'settings-documents-investments-related',
    false,
  );
  const items =
    hasInvestmentsRelatedFlag === true
      ? navItems
      : navItems.filter(({ label }) => label !== 'Investments');

  return (
    <TwoColumn3by9 pb={64}>
      <TwoColumn3by9.Column1>
        <Navigation orientation="vertical" items={items} />
      </TwoColumn3by9.Column1>
      <TwoColumn3by9.Column2>
        <AnnouncementsForDocuments />
        {children}
      </TwoColumn3by9.Column2>
    </TwoColumn3by9>
  );
};
