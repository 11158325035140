import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { TextField } from '~/components/form/TextField';

import { formatPhoneNumber } from '~/utils/formatting';

import { jointAccountPhoneNumberValidator, phoneNumber } from '../validators';

export const PhoneNumberField = ({ ...props }) => {
  const { control, watch } = useFormContext();
  const phone = watch('phone');
  return (
    <TextField
      {...props}
      name="phone"
      label={props.label || 'Phone number'}
      type="tel"
      maxLength={14}
      control={control}
      autoComplete="tel-national"
      defaultValue=""
      rules={{
        required: 'Required.',
        validate: {
          phoneNumber: (value) =>
            // This is the validator for SavingsAccountInvitationForm phone number field.
            // All other instances of the PhoneNumberField should just validate with phoneNumber().
            props.primaryUserPhoneNumber
              ? jointAccountPhoneNumberValidator(
                  value,
                  props.primaryUserPhoneNumber,
                )
              : phoneNumber(value),
        },
      }}
      value={formatPhoneNumber(phone)}
    />
  );
};
