import * as React from 'react';
import { Route } from 'react-router';

import { RewardsPayoutLocation } from './page';

export const CreditCardRewardsPayoutRoute = () => {
  return (
    <Route
      path="rewards-payout(/:step)"
      component={RewardsPayoutLocation}
      fallbackRoute="/d/spend/credit/rewards/summary"
      cover
    />
  );
};
