import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { CreditCardLandingPage } from './CreditCardLandingPage';

export const CreditCardLandingPageRoute = () => {
  return (
    <Route
      path="transactions"
      component={createRouteComponent(CreditCardLandingPage)}
    />
  );
};
