import { Button, Flex, HM, PL } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { CloseButton } from '~/components/cover/components';
import { GeneralErrorPage } from '~/flows/components/personal-loans/application/steps/GeneralErrorPage';
import { usePersonalLoansPromptForBankConnectionQuery } from '~/graphql/hooks';
import { useFundingSources } from '~/hooks/useFundingSources';
import { useNavigate } from '~/hooks/useNavigate';
import { AppImage } from '~/lens-toolbox/AppImage';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { useSelector } from '~/redux/hooks';
import { Container } from '~/toolbox/container';
import { Divider } from '~/toolbox/divider';
import { Spinner } from '~/toolbox/spinner';

import { PlaidVerifyMicrodeposits } from './PlaidVerifyMicrodeposits';

type PromptForBankConnectionProps = {
  onFinishStep: () => void;
};

export const PromptForBankConnection = ({
  onFinishStep,
}: PromptForBankConnectionProps) => {
  const navigate = useNavigate();
  const loanId = useSelector(
    (state) => state.newFlows.PERSONAL_LOANS_APPLICATION.loanId,
  );

  const {
    fundingSources,
    pendingFundingSources,
    loading: fundingSourcesLoading,
  } = useFundingSources();

  const { data, loading } = usePersonalLoansPromptForBankConnectionQuery({
    variables: {
      loanId: loanId as string,
    },
    skip: !loanId,
  });

  const handleSkip = () => navigate({ to: '/d/borrow' });

  if (loading || fundingSourcesLoading) {
    return <Spinner />;
  }

  const hasDisbursementOption =
    data?.viewer.borrow?.personalLoans?.disbursementFlow?.hasDisbursementOption;

  const primaryContinueLink =
    data?.viewer.borrow?.personalLoans?.disbursementFlow
      ?.promptForBankConnection?.primaryContinueLink;

  const secondaryContinueLink =
    data?.viewer.borrow?.personalLoans?.disbursementFlow
      ?.promptForBankConnection?.secondaryContinueLink;

  const bankConnectionImage =
    data?.viewer.borrow?.personalLoans?.disbursementFlow
      ?.promptForBankConnection?.image;

  if (!primaryContinueLink || !fundingSources) {
    return <GeneralErrorPage />;
  }

  if (hasDisbursementOption) {
    onFinishStep();
  }

  // Move all flow code out to a useWizard hook eventually
  if (pendingFundingSources.length > 0) {
    return <PlaidVerifyMicrodeposits />;
  }

  return (
    <Container>
      <CloseButton color="foregroundNeutralMain" onClick={handleSkip} />
      <Flex flexDirection="column" maxWidth={600} mx="auto">
        <Flex justifyContent="center" pt={64}>
          {bankConnectionImage && <AppImage appImage={bankConnectionImage} />}
        </Flex>
        <HM
          content={
            data?.viewer.borrow?.personalLoans?.disbursementFlow
              ?.promptForBankConnection?.header
          }
          py={48}
        />
        <Flex alignItems="center">
          <Icon name="security32" />
          <PL
            content={
              data?.viewer.borrow?.personalLoans?.disbursementFlow
                ?.promptForBankConnection?.subheader
            }
            pl={8}
          />
        </Flex>
        <Divider spacing="relaxed" />
        <Flex alignItems="center" pt={8}>
          <Button
            label={primaryContinueLink?.title}
            onClick={() => {
              navigate({
                to: '/d/c/connect-bank',
                query: { connectionType: 'personal_loans' },
              });
            }}
            size="large"
          />
          {secondaryContinueLink && (
            <LinkableLink font="PL" pl={32} linkable={secondaryContinueLink} />
          )}
        </Flex>
      </Flex>
    </Container>
  );
};
