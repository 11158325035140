import { Flex, PL } from '@m1/liquid-react';
import * as React from 'react';

import { CopyIconButton } from '~/components/CopyIconButton';

// This is the old Web component that served to render the SavingsAccountDetails type
// SavingsAccountDetails will be used to render the manual setup info
const SubSection = ({
  label,
  value,
  copyable,
}: {
  label: string;
  value: string;
  copyable: boolean;
}) => {
  return (
    <Flex mb={16} justifyContent="space-between" flexDirection="row">
      <PL color="foregroundNeutralSecondary" fontWeight={400} content={label} />
      <Flex alignItems="center">
        <PL fontWeight={600} content={value} />
        {copyable && (
          <CopyIconButton
            size="small"
            value={value}
            name="copy20"
            color="primary"
          />
        )}
      </Flex>
    </Flex>
  );
};

export const EarnDirectDepositManualSetup = () => {
  return (
    <Flex>
      Manual Setup
      <div>
        <p>Option 1</p>
        <p>Option 2</p>
        <p>Option 3</p>
      </div>
    </Flex>
  );
};
