import * as React from 'react';
import { Route } from 'react-router';

import { AvailableFundingSourcesPage } from '~/pages/dashboard/bank-connection/AvailableFundingSourcesPage';

export const AvailableFundingSourcesRoute = () => {
  return (
    <Route
      path="available-funding-sources"
      component={AvailableFundingSourcesPage}
    />
  );
};
