import { SagaIterator } from 'redux-saga';

import { call } from 'redux-saga/effects';

import {
  QueryAllPortfoliosDocument,
  QueryAllPortfoliosQueryResult,
} from '~/graphql/hooks';

import { apolloQuerySaga } from '../../apolloQuerySaga';

export function* queryUserHasPortfolio(): SagaIterator<boolean> {
  const { data }: QueryAllPortfoliosQueryResult = yield call(apolloQuerySaga, {
    query: QueryAllPortfoliosDocument,
  });

  return Boolean(
    data?.viewer?.accounts?.edges?.some(
      (account) => account?.node?.hasPortfolio,
    ),
  );
}
