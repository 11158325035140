import * as React from 'react';
import { Route } from 'react-router';

import { DisclosuresForCryptoPage } from './DisclosuresForCryptoPage';

export const DisclosuresForCryptoRoute = () => {
  return (
    <Route path="crypto-disclosures" component={DisclosuresForCryptoPage} />
  );
};
