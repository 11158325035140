import { HXS, PL, Box } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { TextWithPhoneLinks } from '~/components/TextWithPhoneLinks';
import { useReportTransactionIssuePageQuery } from '~/graphql/hooks';
import { LinkableButton } from '~/lens-toolbox/LinkableButton';
import { Spinner } from '~/toolbox/spinner';

export type ReportTransactionIssuePageProps = {
  nodeId: string;
};

export const ReportTransactionIssuePage = ({
  nodeId,
}: ReportTransactionIssuePageProps) => {
  const { data, loading } = useReportTransactionIssuePageQuery({
    variables: {
      nodeId,
    },
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (data?.node?.__typename !== 'SpendActivityEntryDisputeDetails') {
    return <GenericSystemError />;
  }

  const { content, title, phoneNumber, resolutionCta } = data.node;

  return (
    <Box m="0 auto" p="64px 0 100px 0" width={490}>
      <HXS content={title} mb={24} />
      {content
        ?.filter(Boolean)
        .map((instruction, i) => (
          <PL
            content={
              <TextWithPhoneLinks
                text={instruction}
                phoneNumber={phoneNumber}
              />
            }
            key={`issue-instruction-${i}`}
            mt={12}
          />
        ))}
      {resolutionCta && <LinkableButton linkable={resolutionCta} mt={64} />}
    </Box>
  );
};
