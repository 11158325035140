import * as React from 'react';
import { Route } from 'react-router';

import { ApplicationReceived } from '../components/ApplicationReceived';
import { ApplicationRejected } from '../components/ApplicationRejected';
import { DisbursementError } from '../components/DisbursementError';
import { ManualReview } from '../components/ManualReview';
import { NotEligiblePage } from '../components/NotEligible';

export const PersonalLoanApplicationErrorRoute = () => {
  return (
    <Route path="direct-loan-application-error">
      <Route path="application-received" component={ApplicationReceived} />
      <Route path="application-rejected" component={ApplicationRejected} />
      <Route path="not-eligible" component={NotEligiblePage} />
      <Route path="manual-review" component={ManualReview} />
      <Route path="disbursement-error" component={DisbursementError} />
    </Route>
  );
};
