import {
  ChartableSliceDatumFragment,
  PortfolioSlicePerformancePeriodEnum,
} from '~/graphql/types';

import { AnalyticsReporter } from '~/loggers';

import {
  ChartableSliceChartPeriodDuration,
  ChartableSliceChartProps,
  ChartableSliceDatumPoint,
} from './ChartableSliceChart.types';

export const periodDurationToPeriodEnum = (
  periodDuration: ChartableSliceChartPeriodDuration,
) => {
  switch (periodDuration) {
    case 'ytd':
      return PortfolioSlicePerformancePeriodEnum.Ytd;
    case 'all':
      return PortfolioSlicePerformancePeriodEnum.Max;
    default: {
      const duration = parseInt(periodDuration, 10);
      if (isNaN(duration)) {
        // return all by default
        return PortfolioSlicePerformancePeriodEnum.Max;
      }
      const timePeriod = periodDuration.slice(-1);

      switch (timePeriod) {
        case 'd':
          return PortfolioSlicePerformancePeriodEnum.OneDay;
        case 'w':
          return PortfolioSlicePerformancePeriodEnum.OneWeek;
        case 'M':
          return periodDuration === '3M'
            ? PortfolioSlicePerformancePeriodEnum.OneQuarter
            : PortfolioSlicePerformancePeriodEnum.OneMonth;
        case 'y':
          return PortfolioSlicePerformancePeriodEnum.OneYear;
        default:
          // return all by default
          return PortfolioSlicePerformancePeriodEnum.Max;
      }
    }
  }
};

export const periodDurationToRangeSelectorButton = ({
  periodDuration,
  analytics,
  chartName,
  onChangePeriod,
}: {
  periodDuration: ChartableSliceChartPeriodDuration;
  analytics?: AnalyticsReporter;
  chartName: string;
  onChangePeriod?: ChartableSliceChartProps['onChangePeriod'];
}): Highcharts.RangeSelectorButtonsOptions => {
  const recordRangeSelectionEvent = (
    period: ChartableSliceChartPeriodDuration,
  ) => {
    analytics?.recordEvent('range_selection_clicked', null, {
      chart: chartName,
      selection: period,
    });
  };

  const handleClick = () => {
    recordRangeSelectionEvent(periodDuration);
    onChangePeriod?.(periodDurationToPeriodEnum(periodDuration));
  };

  switch (periodDuration) {
    case 'ytd':
    case 'all':
      return {
        type: periodDuration,
        text: periodDuration,
        events: {
          click: handleClick,
        },
      };
    default: {
      const duration = parseInt(periodDuration, 10);
      if (isNaN(duration)) {
        // return all by default
        return {
          type: 'all',
          text: 'all',
          events: {
            click: handleClick,
          },
        };
      }
      const timePeriod = periodDuration.slice(-1);

      switch (timePeriod) {
        case 'd':
          return {
            type: 'day',
            count: duration,
            text: periodDuration,
            events: {
              click: handleClick,
            },
          };
        case 'w':
          return {
            type: 'week',
            count: duration,
            text: periodDuration,
            events: {
              click: handleClick,
            },
          };
        case 'M':
          return {
            type: 'month',
            count: duration,
            text: periodDuration,
            events: {
              click: handleClick,
            },
          };
        case 'y':
          return {
            type: 'year',
            count: duration,
            text: periodDuration,
            events: {
              click: handleClick,
            },
          };
        default:
          // return all by default
          return {
            type: 'all',
            text: 'all',
            events: {
              click: handleClick,
            },
          };
      }
    }
  }
};

const pointsArePoints = (point: any): point is ChartableSliceDatumPoint => {
  return point?.datum !== undefined;
};

export const sumDividends = (
  points: Array<ChartableSliceDatumPoint | ChartableSliceDatumFragment> | null,
) => {
  return (
    points?.reduce((sum: number, point) => {
      const dividends = pointsArePoints(point)
        ? point.datum?.sumDividends
        : point.sumDividends;
      return sum + (dividends ?? 0);
    }, 0) ?? 0
  );
};

export const sumTrades = (
  points: Array<ChartableSliceDatumPoint | ChartableSliceDatumFragment> | null,
) => {
  return (
    points?.reduce((sum: number, point) => {
      const trades = pointsArePoints(point)
        ? point.datum?.sumTrades
        : point.sumTrades;
      return sum + (trades ?? 0);
    }, 0) ?? 0
  );
};
