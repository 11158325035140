import { Button, Text } from '@m1/liquid-react';
import * as React from 'react';
import { InjectedRouteProps, withRouter } from 'react-router';

import { GenericSystemError } from '~/components/GenericSystemError';
import { SliceableNameCell } from '~/components/pie';

import { useInvestActivityTradeSummaryQuery } from '~/graphql/hooks';
import { TradeConnectionFragment, TradeEntryFragment } from '~/graphql/types';
import { Grid } from '~/toolbox/grid';
import { GridTable } from '~/toolbox/grid-table';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';
import { formatNumber } from '~/utils';

type TradeType = 'buys' | 'sells';

const initialVariables = {
  firstForBuys: 100,
  firstForSells: 100,
  afterForBuys: null,
  afterForSells: null,
};

function readTradesFromConnection(
  connection: TradeConnectionFragment | null | undefined,
): Array<TradeEntryFragment> {
  const trades: TradeEntryFragment[] = [];
  if (connection && Array.isArray(connection.edges)) {
    for (const edge of connection.edges) {
      if (edge && edge.node) {
        trades.push(edge.node);
      }
    }
  }
  return trades;
}

const InvestActivityTradeSummaryEntryDetailsComponent = ({
  params,
}: InjectedRouteProps) => {
  const investActivityEntryId = params.activityId;
  const { data, loading, variables, refetch } =
    useInvestActivityTradeSummaryQuery({
      variables: {
        id: investActivityEntryId as string,
        ...initialVariables,
      },
      skip: !investActivityEntryId,
    });

  if (loading) {
    return <Spinner />;
  }

  if (!investActivityEntryId) {
    return null;
  }

  if (data?.node?.__typename !== 'InvestActivityTradeSummaryEntry') {
    return <GenericSystemError />;
  }

  const activity = data.node;

  const hasMoreBuys = Boolean(activity.buyTrades?.pageInfo.hasNextPage);
  const hasMoreSells = Boolean(activity.sellTrades?.pageInfo.hasNextPage);

  const previousBuyFirst =
    variables?.firstForBuys ?? initialVariables.firstForBuys;
  const previousSellsFirst =
    variables?.firstForSells ?? initialVariables.firstForSells;

  const handleRefetch = (tradeType: TradeType) => {
    if (tradeType === 'buys') {
      refetch({
        firstForBuys: previousBuyFirst + 100,
      });
    } else {
      refetch({
        firstForSells: previousSellsFirst + 100,
      });
    }
  };

  const renderBuysHeading = () => {
    if (activity) {
      const { countOfBuys, excludedBuys } = activity;
      return (
        <>
          Buys: {countOfBuys}
          {excludedBuys && excludedBuys.total && investActivityEntryId ? (
            <>
              &nbsp;(
              <Link
                to="/d/c/activity/:activityId/excluded-buys"
                params={{
                  activityId: investActivityEntryId,
                }}
              >
                {excludedBuys.total} excluded
              </Link>
              )
            </>
          ) : null}
        </>
      );
    }
    return 'Buys';
  };

  const renderSellsHeader = () => {
    if (activity) {
      return `Sells: ${activity.countOfSells}`;
    }
    return 'Sells';
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col xs={6}>
          <Text
            as="h3"
            color="foregroundNeutralMain"
            content={renderBuysHeading()}
            mb={8}
          />
          <TradesTable
            connection={activity.buyTrades as TradeConnectionFragment}
            showLoadMore={hasMoreBuys}
            handleRefetch={() => handleRefetch('buys')}
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <Text
            as="h3"
            color="foregroundNeutralMain"
            content={renderSellsHeader()}
            mb={8}
          />
          <TradesTable
            connection={activity.sellTrades as TradeConnectionFragment}
            showLoadMore={hasMoreSells}
            handleRefetch={() => handleRefetch('sells')}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

const TradesTable = ({
  connection,
  handleRefetch,
  showLoadMore,
}: {
  connection: TradeConnectionFragment | null | undefined;
  handleRefetch: () => void;
  showLoadMore: boolean;
}) => {
  const trades = readTradesFromConnection(connection);
  return (
    <GridTable emptyMessage="No trades" gridTemplateColumns="250px 1fr">
      <GridTable.HeaderRow>
        <GridTable.HeaderCell label="Name" />
        <GridTable.HeaderCell label="Value" justifySelf="end" />
      </GridTable.HeaderRow>
      {trades.map((trade) => (
        <GridTable.NavigableRow
          key={trade.id}
          params={{
            activityId: trade.id,
          }}
          to="/d/c/activity/:activityId/activity-details"
        >
          <GridTable.Cell>
            {trade.tradeSecurity.security ? (
              <SliceableNameCell sliceable={trade.tradeSecurity.security} />
            ) : (
              trade.tradeSecurity.descriptor
            )}
          </GridTable.Cell>
          <GridTable.Cell
            content={formatNumber(trade.amount)}
            justifySelf="end"
          />
        </GridTable.NavigableRow>
      ))}
      {showLoadMore && (
        <GridTable.Row gridTemplateColumns="auto">
          <GridTable.Cell alignSelf="center" justifySelf="center">
            <Button
              label="Load More"
              kind="primary"
              size="small"
              onClick={handleRefetch}
            />
          </GridTable.Cell>
        </GridTable.Row>
      )}
    </GridTable>
  );
};

export const InvestActivityTradeSummaryEntryDetails = withRouter(
  InvestActivityTradeSummaryEntryDetailsComponent,
);
