import { Box, Flex, HM, HXXS } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';

import { useSavingsNameAccountQuery } from '~/graphql/hooks';
import { AppButton } from '~/lens-toolbox/AppButton';
import { useSelector } from '~/redux/hooks';
import { Input } from '~/toolbox/Input';

type SavingsNameAccountProps = {
  onFinishStep: (name: string | null) => void;
};

export const SavingsNameAccount = ({
  onFinishStep,
}: SavingsNameAccountProps) => {
  const initialName = useSelector(
    (state) => state.newFlows.SAVINGS_ONBOARDING.accountName,
  );
  const [name, setName] = React.useState<string>(initialName ?? '');
  const { loading, data } = useSavingsNameAccountQuery({});
  const nameAccountPage =
    data?.viewer.save?.savings?.onboarding?.nameAccountPage;
  const validateInput = (): boolean => Boolean(name && name.length <= 50);
  return (
    <Flex
      alignItems="baseline"
      flexDirection="column"
      justifyContent="center"
      maxWidth={588}
      mx="auto"
    >
      <Box my={32}>
        {/* Go back 2 steps to skip initial loading step  */}
        <BackArrow content="Back" onClick={() => window.history.back()} />
      </Box>
      <HM content={nameAccountPage?.title} mb={16} />
      <HXXS content={nameAccountPage?.subtitle} mb={48} fontWeight={300} />
      <Input
        label={nameAccountPage?.inputFieldName}
        placeholder={nameAccountPage?.inputPlaceholder ?? ''}
        value={name}
        onChange={(e: React.SyntheticEvent<HTMLInputElement>) =>
          setName((e.target as HTMLInputElement).value)
        }
        error={
          !validateInput() && name !== null && name !== ''
            ? '50 character limit exceeded.'
            : ''
        }
      />
      <Flex mt={32}>
        {nameAccountPage?.primaryCtaButton && (
          <AppButton
            appButton={nameAccountPage?.primaryCtaButton}
            onClick={() => onFinishStep(name)}
            disabled={name === '' || !validateInput() || loading}
            size="large"
            mr={16}
          />
        )}
        {nameAccountPage?.secondaryCtaButton && (
          <AppButton
            appButton={nameAccountPage?.secondaryCtaButton}
            onClick={() => onFinishStep(null)}
            disabled={loading}
            size="large"
            kind="text"
          />
        )}
      </Flex>
    </Flex>
  );
};
