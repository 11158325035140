import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { PersonalLoansNotEligiblePage } from './PersonalLoansNotEligiblePage';

export const PersonalLoansNotEligibleRoute = () => (
  <Route
    path="not-eligible"
    component={createRouteComponent(PersonalLoansNotEligiblePage)}
  />
);
