import { routerActions } from 'react-router-redux';

import { useDispatch } from '~/redux/hooks';
import { buildRoute } from '~/utils/route';

/**
 * Custom hook to change the current step based on the given basePath.
 * Primarily used to handle component based step routing for
 * saga flows.
 *
 * This hook is a replication of the changeStep function within flows.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.basePath - The base path for the URL.
 *
 * @returns {Function} A function to handle changing steps.
 *
 * @example
 * const handleChangeStep = useChangeStep({ basePath: '/wizard' });
 * handleChangeStep({ step: 'step1' });
 */
export const useChangeStep = <K extends string>({
  basePath,
}: {
  basePath: string;
}) => {
  const dispatch = useDispatch();
  const handleChangeStep = ({
    otherOptions = {},
    replace = false,
    step,
  }: {
    step: K;
    replace?: boolean;
    otherOptions?: Record<string, any>;
  }) => {
    const action = replace ? 'replace' : 'push';

    const pathname = buildRoute([basePath, step]);

    dispatch(
      routerActions[action]({
        ...otherOptions,
        pathname,
      }),
    );
  };
  return handleChangeStep;
};
