import * as React from 'react';
import { IndexRoute, Redirect, Route } from 'react-router';

import { PersonalLoansRoute } from '~/pages/dashboard/borrow/personal';

import { BorrowPage } from './BorrowPage';
import { BorrowSplashPage } from './BorrowSplashPage';
import { MarginRoute } from './margin';

import { BorrowMarketingRoute } from './marketing';

export const BorrowRoute = () => {
  return (
    <Route path="borrow" component={BorrowPage}>
      {PersonalLoansRoute()}
      {BorrowMarketingRoute()}
      {MarginRoute()}
      <IndexRoute component={BorrowSplashPage} />
      <Redirect from="/d/borrow/*" to="/d/borrow" />
    </Route>
  );
};
