import { useQuery } from '@apollo/client';
import React from 'react';

import {
  onlyActiveLimits,
  onScreenerSortChange,
  handlePreviousScreenerPage,
  handleNextScreenerPage,
} from '~/components/research/Screener/utils';

import {
  FundScreenerDocument,
  SecurityScreenerDocument,
} from '~/graphql/hooks';
import { HistoricalQuotePeriodEnum, SecurityTypeEnum } from '~/graphql/types';

import { useHistory } from '~/hooks/useHistory';
import { useLocation } from '~/hooks/useLocation';

import {
  ScreenerState,
  ScreenerRouterQuery,
  ScreenerQuery,
  ScreenerVariables,
  useScreenerStateReturn,
  LimitOption,
} from './types';

export const useScreenerState = <T extends SecurityTypeEnum>({
  screenerType,
  defaultVariables,
  defaultLimits,
}: ScreenerState<T>): useScreenerStateReturn<T> => {
  const [prevCursor, setPrevCursor] = React.useState<(string | null)[]>([null]);
  const [limitState, setLimitState] = React.useState(defaultLimits);

  const { query } = useLocation();
  const { push } = useHistory();

  const screenerQuery =
    screenerType === SecurityTypeEnum.Equity
      ? SecurityScreenerDocument
      : FundScreenerDocument;

  const queryResult = useQuery<ScreenerQuery<T>, ScreenerVariables<T>>(
    screenerQuery,
    {
      variables: defaultVariables,
    },
  );

  React.useEffect(() => {
    if (query) {
      const { sort, sortType, first, ...rest } =
        query as ScreenerRouterQuery<T>;

      const sortVariables =
        sort && sortType
          ? {
              direction: sort,
              type: sortType,
            }
          : defaultVariables.sort;

      const variables = {
        ...defaultVariables,
        ...rest,
        sort: sortVariables,
        limit: getLimitArray(),
      } as ScreenerVariables<T>;

      queryResult.refetch(variables);
    }
  }, [query]);

  const updateScreenerQuery = (args: any) => {
    push({
      query: {
        ...query,
        ...args,
      },
    });
  };

  const handleSortChange = (
    sortType: Exclude<
      typeof defaultVariables.sort,
      any[] | null | undefined
    >['type'],
  ) => {
    const { direction, type } = onScreenerSortChange(
      queryResult.variables?.sort,
      sortType,
    )[0];

    updateScreenerQuery({
      sort: direction,
      sortType: type,
    });
  };

  const getLimitArray = React.useCallback(
    (limitObject: typeof defaultLimits = limitState) => {
      return Object.values(limitObject).filter(
        onlyActiveLimits,
      ) as ScreenerVariables<T>['limit'];
    },
    [limitState],
  );

  const handleLimitChange = (limit: LimitOption<T>) => {
    const newLimit = { ...limitState, [limit.type]: limit };
    setLimitState(newLimit);

    const limits = getLimitArray(newLimit);

    queryResult.refetch({
      limit: limits,
    } as ScreenerVariables<T>);
  };

  const handlePeriodChange = (period: HistoricalQuotePeriodEnum) => {
    updateScreenerQuery({ period });
  };

  const handleReset = () => {
    const { sort } = defaultVariables;

    const sortOptions = Array.isArray(sort) ? sort[0] : sort;

    const sortDirection = sortOptions?.direction;
    const sortType = sortOptions?.type;
    setLimitState(defaultLimits);

    push({
      query: { ...defaultVariables, sort: sortDirection, sortType },
    });
  };

  const hasCategoryFilters =
    query.filterCategory !== undefined && Boolean(query.filterCategory?.length);
  const hasLimitFilters = Boolean(
    (queryResult.variables?.limit as any[])?.filter(onlyActiveLimits).length,
  );

  const hasFilters = hasCategoryFilters || hasLimitFilters;

  const handleNextPage = React.useCallback(() => {
    handleNextScreenerPage({
      endCursor: queryResult.data?.viewer.securities.pageInfo.endCursor,
      fetchMore: queryResult.fetchMore,
      setPrevCursor,
    });
  }, [
    queryResult.fetchMore,
    queryResult.data?.viewer.securities.pageInfo.endCursor,
  ]);

  const handlePreviousPage = React.useCallback(() => {
    handlePreviousScreenerPage({
      setPrevCursor,
      prevCursor,
      fetchMore: queryResult.fetchMore,
    });
  }, [queryResult.fetchMore, prevCursor]);

  const hasPreviousPage = prevCursor.length > 1;

  return {
    updateScreenerQuery,
    hasFilters,
    handleLimitChange,
    handleSortChange,
    handlePeriodChange,
    handleReset,
    queryResult,
    handleNextPage,
    handlePreviousPage,
    hasPreviousPage,
    limitState,
    setLimitState,
  };
};
