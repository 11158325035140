import * as React from 'react';
import { Route } from 'react-router';

import { CategoryDetailsPage } from '~/pages/dashboard/research/model-portfolios/category-details/CategoryDetailsPage';
import { createRouteComponent } from '~/router';

export const AddModelPortfolioCategoryDetailsRoute = () => {
  return (
    <Route
      path=":categoryKey"
      component={createRouteComponent(CategoryDetailsPage, (props) => ({
        categoryKey: props.params.categoryKey,
      }))}
    />
  );
};
