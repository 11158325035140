import * as React from 'react';
import { Route } from 'react-router';

import { RESOLVE_CALL_STEPS as STEPS } from '~/static-constants';

import { CreateTransferPage } from './create-transfer';
import { ResolveMarginCallPage } from './margin-call-information';
import { ResolveMarginCallLayout } from './ResolveMarginCallLayout';
import { SetOrder } from './set-order';

export const ResolveCallRoute = () => (
  <Route
    component={ResolveMarginCallLayout}
    cover
    fallbackRoute="/d/borrow"
    path="resolve-call"
  >
    <Route
      component={ResolveMarginCallPage}
      path={`${STEPS.RESOLVE_MARGIN_CALL}(/:resolveMarginCallStep)`}
      unmask
    />
    <Route
      component={CreateTransferPage}
      path={`${STEPS.CREATE_TRANSFER}(/:createTransferStep)`}
      unmask
    />
    <Route
      component={SetOrder}
      path={`${STEPS.SET_ORDER}(/:setOrderStep)`}
      unmask
    />
  </Route>
);
