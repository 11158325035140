import {
  TwoColumn4by8,
  Skeleton,
  PXL,
  SemanticColorNames,
  PL,
  Box,
  HXS,
  PS,
  TabularDataSet,
  useThemeMode,
  Maybe,
  useSkeleton,
  Card,
  Modal,
  ModalContent,
} from '@m1/liquid-react';
import * as React from 'react';

import { DismissibleCard } from '~/components/DismissibleCard';
import { LinearGauge } from '~/components/LinearGauge';
import {
  AppCardFragment,
  MarginAvailableToBorrowFragment,
  MarginDetailFragment,
  MarginLoanInterestFragment,
} from '~/graphql/types';
import { AppCard } from '~/lens-toolbox/AppCard/AppCard';
import { RichText } from '~/lens-toolbox/RichText/RichText';
import { Carousel } from '~/toolbox/carousel';

import { MarginListItemsContainer, MarginListItem } from './MarginListItem';

const mockListItems = [
  { header: '', value: '' },
  { header: '', value: '' },
  { header: '', value: '' },
];

export const MarginLandingPageLeftColumn = ({
  availableToBorrow,
  loanInterest,
  howBillingWorks,
  promotionCards,
  priorityPromotionCards,
}: {
  availableToBorrow: Maybe<MarginAvailableToBorrowFragment>;
  loanInterest: Maybe<MarginLoanInterestFragment>;
  howBillingWorks: Maybe<MarginDetailFragment>;
  promotionCards: Maybe<Array<AppCardFragment>>;
  priorityPromotionCards: Maybe<Array<AppCardFragment>>;
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { activeThemeMode } = useThemeMode();
  const { isLoading } = useSkeleton();
  return (
    <TwoColumn4by8.Column1>
      {/* Many users won't have promos, so don't show skeleton for something they may not see */}
      {!isLoading &&
        priorityPromotionCards &&
        priorityPromotionCards.length > 0 && (
          <AppCard
            appCard={priorityPromotionCards[0]}
            mb={32}
            position="relative"
            tooltipPlacement="bottom"
            zIndex={2}
          />
        )}
      <Card p={16} mb={32}>
        <Skeleton skeletonWidth="50%" mb={16}>
          <PXL fontWeight={300}>{availableToBorrow?.title}</PXL>
        </Skeleton>
        <Skeleton skeletonWidth="100%">
          <LinearGauge
            value={availableToBorrow?.currentBalanceDetail?.numericValue ?? 0}
            valueIndicator={{
              foregroundColor: availableToBorrow?.currentBalanceDetail
                ?.foregroundColor as SemanticColorNames,
              backgroundColor: availableToBorrow?.currentBalanceDetail
                ?.backgroundColor as SemanticColorNames,
            }}
            middleBound={{
              label: availableToBorrow?.withdrawableMaxDetail?.label ?? '',
              value:
                availableToBorrow?.withdrawableMaxDetail?.numericValue ?? 0,
              color:
                (availableToBorrow?.withdrawableMaxDetail
                  ?.backgroundColor as SemanticColorNames) ?? 'datapointTint',
              tooltip: availableToBorrow?.withdrawableMaxDetail?.tooltip,
            }}
            upperBound={{
              label: availableToBorrow?.investableMaxDetail?.label ?? '',
              value: availableToBorrow?.investableMaxDetail?.numericValue ?? 0,
              color:
                (availableToBorrow?.investableMaxDetail
                  ?.backgroundColor as SemanticColorNames) ??
                'backgroundFeatureFlat',
              tooltip: availableToBorrow?.investableMaxDetail?.tooltip,
            }}
          />
        </Skeleton>
        <Skeleton mt={16} skeletonWidth="100%" skeletonHeight={80}>
          {availableToBorrow?.breakdown?.map((breakdown, i) => (
            <RichText
              richText={breakdown}
              key={i}
              containerProps={{ display: 'inline' }}
              buttonProps={{
                kind: 'link',
                onClick: () => setIsModalOpen(true),
              }}
              textProps={{
                font: 'PL',
              }}
            />
          ))}
        </Skeleton>
      </Card>
      {/* Many users won't have promos, so don't show skeleton for something they may not see */}
      {!isLoading && promotionCards && promotionCards.length > 0 && (
        <Carousel
          indicator="numbers"
          fullSize
          scrollSnap
          fixedProgressIndicator={false}
          items={promotionCards.map((card, i) => (
            <AppCard
              key={i}
              appCard={card}
              hasSiblingCards={promotionCards.length > 1}
              // Only show bottom margin if one card is present
              mb={promotionCards.length === 1 ? 32 : 0}
            />
          ))}
        />
      )}
      <Card p={16} mb={32}>
        <Skeleton skeletonWidth="50%" mb={16}>
          <PXL fontWeight={300}>{loanInterest?.title}</PXL>
        </Skeleton>
        <MarginListItemsContainer>
          {(loanInterest?.listItems ?? mockListItems).map((item, i) => (
            <MarginListItem label={item.header} value={item.value} key={i} />
          ))}
        </MarginListItemsContainer>
      </Card>
      {howBillingWorks && (
        <DismissibleCard
          p={16}
          mb={32}
          borderColor="transparent"
          backgroundColor="backgroundNeutralMain"
          localStorageKey="margin-how-billing-works-card"
        >
          <PL fontWeight={600} mb={4}>
            {howBillingWorks.label}
          </PL>
          <PL fontWeight={400} color="foregroundNeutralSecondary">
            {howBillingWorks.labelValue}
          </PL>
        </DismissibleCard>
      )}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalContent width="wide">
          <Box mt={24}>
            <video
              autoPlay
              muted
              style={{
                verticalAlign: 'center',
                width: '100%',
              }}
            >
              <source
                src={
                  activeThemeMode === 'light'
                    ? '/animations/margin-light.mp4'
                    : '/animations/margin-dark.mp4'
                }
                type="video/mp4"
              />
            </video>
          </Box>
          <HXS my={24}>{availableToBorrow?.additionalDetails?.title}</HXS>
          {availableToBorrow?.additionalDetails?.description?.map((desc, i) => (
            <PL fontWeight={400} mb={24} key={i}>
              <RichText richText={desc} />
            </PL>
          ))}
          <Box width={240} mb={32}>
            <TabularDataSet
              tabularData={[
                {
                  label: (
                    <PS color="foregroundNeutralSecondary">
                      {
                        availableToBorrow?.additionalDetails
                          ?.withdrawableMaxDetail?.label
                      }
                    </PS>
                  ),
                  data: (
                    <PXL fontWeight={500}>
                      {
                        availableToBorrow?.additionalDetails
                          ?.withdrawableMaxDetail?.labelValue
                      }
                    </PXL>
                  ),
                },
                {
                  label: (
                    <PS color="foregroundNeutralSecondary">
                      {
                        availableToBorrow?.additionalDetails
                          ?.investableMaxDetail?.label
                      }
                    </PS>
                  ),
                  data: (
                    <PXL fontWeight={500}>
                      {
                        availableToBorrow?.additionalDetails
                          ?.investableMaxDetail?.labelValue
                      }
                    </PXL>
                  ),
                },
              ]}
            />
          </Box>
        </ModalContent>
      </Modal>
    </TwoColumn4by8.Column1>
  );
};
