import { Box, Button, Flex, PM, Card } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';

import { useAcatWizardContraBrokerageDetailsStepQuery } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { AppImage } from '~/lens-toolbox/AppImage';
import { Input } from '~/toolbox/Input';

import { AcatWizardBackButton } from '../components/AcatWizardBackButton';
import { AcatWizardErrorState } from '../components/AcatWizardErrorState';
import { AcatWizardLoadingPlaceholder } from '../components/AcatWizardLoadingPlaceholder';
import { AcatWizardStepCard } from '../components/AcatWizardStepCard';
import { AcatWizardStepContainer } from '../components/AcatWizardStepContainer';
import { useAcatWizardFormContext } from '../hooks/useAcatWizardFormContext';
import { handleEnterKeyDownUtils } from '../utils';

export function AcatWizardContraBrokerageDetailsStep() {
  const { watch, next, back, register } = useAcatWizardFormContext();
  const { externalBrokerageId, originAccountNumber } = watch();
  const analytics = useAnalytics();

  const { data, loading } = useAcatWizardContraBrokerageDetailsStepQuery({
    variables: {
      id: externalBrokerageId as string,
    },
    skip: !externalBrokerageId,
  });

  const brokerageAccountDetailsContent =
    data?.viewer.invest?.acatWizard?.brokerageAccountDetailsContent;

  React.useEffect(() => {
    analytics.pageView('m1_acat_wizard_contra_account_number_screen_view');
  }, [analytics]);

  if (loading) {
    // TODO: Replace this with a skeleton loader
    return <AcatWizardLoadingPlaceholder />;
  } else if (!brokerageAccountDetailsContent) {
    return (
      <AcatWizardErrorState>
        <BackArrow content="Back" onClick={back} />
      </AcatWizardErrorState>
    );
  }

  const { accountNumberTextField, informationalMessage } =
    brokerageAccountDetailsContent;

  const maxAccountLength = accountNumberTextField?.maxAccountLength ?? 1000;
  const minAccountLength = accountNumberTextField?.minAccountLength ?? 0;

  const isDisabled =
    !originAccountNumber ||
    originAccountNumber?.length > maxAccountLength ||
    originAccountNumber?.length < minAccountLength;

  const onContinue = () => {
    next();
  };

  return (
    <AcatWizardStepContainer>
      <AcatWizardBackButton />
      <AcatWizardStepCard header={brokerageAccountDetailsContent.header}>
        <Box>
          {accountNumberTextField?.placeholder && (
            <Input
              {...register('originAccountNumber', {
                maxLength: 5,
              })}
              autoFocus
              label={accountNumberTextField.label}
              maxLength={accountNumberTextField.maxAccountLength ?? undefined}
              onKeyDown={(e: any) =>
                handleEnterKeyDownUtils(e, !isDisabled, next)
              }
              placeholder={accountNumberTextField.placeholder}
              type="text"
            />
          )}
          {informationalMessage?.copy && (
            <Card p={16} mt={16}>
              <Flex gap={8}>
                {informationalMessage.icon && (
                  <Box minWidth={24}>
                    <AppImage appImage={informationalMessage.icon} />
                  </Box>
                )}
                <PM>{informationalMessage.copy}</PM>
              </Flex>
            </Card>
          )}
        </Box>
      </AcatWizardStepCard>
      <Flex>
        <Button
          disabled={isDisabled}
          label={brokerageAccountDetailsContent.ctaLabel}
          onClick={onContinue}
          size="large"
        />
      </Flex>
    </AcatWizardStepContainer>
  );
}
