import * as React from 'react';
import { Route } from 'react-router';

import { FinancialSuitabilityPage } from './FinancialSuitabilityPage';

export const FinancialSuitabilityRoute = () => {
  return (
    <Route
      path="financial-details(/:step)"
      component={FinancialSuitabilityPage}
    />
  );
};
