import {
  Flex,
  Maybe,
  PL,
  Pill,
  PillProps,
  styled,
  css,
} from '@m1/liquid-react';
import * as React from 'react';

import { AppPill, CommonTransactionAmount } from '~/graphql/types';

import { getTextColorForAmount } from './utils';

const StyledAmountText = styled(PL)<{ strikeThrough?: boolean }>`
  ${({ strikeThrough }) => {
    if (strikeThrough) {
      return css`
        text-decoration: line-through;
      `;
    }
  }};
`;

const PILL_KIND_MAPPING: Record<string, PillProps['kind']> = {
  'DANGER': 'danger',
};

type CommonTransactionAmountCellProps = {
  pill?: Maybe<AppPill>;
  amount?: Maybe<CommonTransactionAmount>;
  strikeThrough?: boolean;
};

export const CommonTransactionAmountCell = ({
  pill,
  amount,
  strikeThrough,
}: CommonTransactionAmountCellProps) => {
  return (
    <Flex justifyContent="flex-end" flexWrap="wrap-reverse" alignItems="center">
      {pill?.label && (
        <Pill
          label={pill?.label}
          kind={PILL_KIND_MAPPING[pill?.kind] ?? 'neutral'}
        />
      )}
      <StyledAmountText
        content={amount?.text}
        color={getTextColorForAmount(amount)}
        ml={4}
        strikeThrough={strikeThrough}
      />
    </Flex>
  );
};
