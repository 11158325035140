import { ApolloError } from '@apollo/client';
import { SagaIterator } from 'redux-saga';
import { call, fork, put, spawn } from 'redux-saga/effects';

import {
  CreateIraTransferDocument,
  CreateIraTransferMutationResult,
  CreateTransferInstanceDocument,
  CreateTransferInstanceMutationResult,
  CreateTransferInstanceRefetchDocument,
  CreateTransferLogSagaDocument,
  CreateTransferLogSagaQueryResult,
  GenerateIdempotencyKeyDocument,
  GenerateIdempotencyKeyMutationResult,
  SetScheduledTransferRuleDocument,
  SetScheduledTransferRuleMutationResult,
  SetScheduledTransferRuleRefetchDocument,
} from '~/graphql/hooks';

import {
  CreateIraTransferInput,
  CreateIraTransferMutation,
  CreateTransferInstanceErrorEnum,
  CreateTransferInstanceInput,
  CreateTransferInstanceMutation,
  GenerateIdempotencyKeyInput,
  Maybe,
  SetScheduledTransferRuleInput,
} from '~/graphql/types';
import { calculateWithholdingAmountInDollars } from '~/pages/dashboard/covers/create-transfer/utils/iraTaxWithholding';
import {
  hideLoadingSpinner,
  setTransferIdempotencyKey,
  showLoadingSpinner,
} from '~/redux/actions';
import {
  CREATE_TRANSFER_FLOW_MODES as MODES,
  CREATE_TRANSFER_FLOW_STEPS as STEPS,
} from '~/static-constants';

import { apolloMutationSaga } from '../../apolloMutationSaga';
import { apolloQuerySaga } from '../../apolloQuerySaga';

import { getAnalyticsReporter, getLoggers } from '../../common';
import { changeStep, makeFlowFuncs } from '../utils';

type CREATE_TRANSFER_STEPS = ValueOf<typeof STEPS>;

type CreateTransferPayload = {
  payload: CREATE_TRANSFER_STEPS;
};

const { selectFlowState, takeFlow, takeFlowStep } =
  makeFlowFuncs('CREATE_TRANSFER');

export function* createTransferSaga(): SagaIterator<void> {
  yield fork(takeFlow, beginCreateTransferFlow);
}

function* beginCreateTransferFlow(action: any): SagaIterator<void> {
  yield fork(takeFlowStep, STEPS.SETUP_TRANSFER, finishedTransferSetup);
  yield fork(takeFlowStep, STEPS.IRA_DISTRIBUTION_SETUP, finishedIraSetup);
  yield fork(takeFlowStep, STEPS.NIA_REPORT, finishNiaReport);
  yield fork(takeFlowStep, STEPS.CALCULATE_NIA, finishCalculateNia);
  yield fork(takeFlowStep, STEPS.CONFIRM_TRANSFER, finishedConfirmTransfer);
  yield fork(
    takeFlowStep,
    STEPS.CONFIRM_TRANSFER_SCHEDULE,
    finishedConfirmTransferSchedule,
  );
  yield fork(
    takeFlowStep,
    STEPS.CONFIRM_LIQUIDATION,
    finishedConfirmLiquidation,
    action.payload.onFinish,
  );
  yield fork(
    takeFlowStep,
    STEPS.SHOW_RECEIPT,
    finishedShowReceipt,
    action.payload.onFinish,
  );
  try {
    const { data }: GenerateIdempotencyKeyMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: GenerateIdempotencyKeyDocument,
        variables: { input: {} satisfies GenerateIdempotencyKeyInput },
      },
    );
    yield put(
      setTransferIdempotencyKey(
        data?.generateIdempotencyKey?.outcome?.idempotencyKey,
      ),
    );
  } catch (e: any) {
    // currently transfers don't require an idempotent key, this will not block transfer creation
    const { sentry } = yield call(getLoggers);
    sentry.message('generateIdempotencyKey mutation failed', {
      rawError: e,
    });
  }
}

function* finishedIraSetup({ payload }: CreateTransferPayload) {
  if (payload === STEPS.SETUP_TRANSFER) {
    yield call(changeStep, STEPS.SETUP_TRANSFER);
  } else if (payload === STEPS.NIA_REPORT) {
    yield call(changeStep, STEPS.NIA_REPORT);
  } else {
    yield call(changeStep, STEPS.CONFIRM_TRANSFER);
  }
}

function* finishNiaReport({ payload }: CreateTransferPayload) {
  if (payload === STEPS.IRA_DISTRIBUTION_SETUP) {
    yield call(changeStep, STEPS.IRA_DISTRIBUTION_SETUP);
  } else {
    yield call(changeStep, STEPS.CONFIRM_TRANSFER);
  }
}

function* finishCalculateNia({ payload }: CreateTransferPayload) {
  if (payload === STEPS.NIA_REPORT) {
    yield call(changeStep, STEPS.NIA_REPORT);
  } else {
    yield call(changeStep, STEPS.CONFIRM_TRANSFER);
  }
}

// @ts-expect-error - TS7031 - Binding element 'payload' implicitly has an 'any' type.
function* finishedTransferSetup({ payload }): SagaIterator<void> {
  const mode: ValueOf<typeof MODES> = yield call(selectFlowState, 'mode');
  const { isOneTimeIraDistribution } = payload;

  if (mode === MODES.SCHEDULE) {
    yield call(changeStep, STEPS.CONFIRM_TRANSFER_SCHEDULE);
  } else if (isOneTimeIraDistribution) {
    yield call(changeStep, STEPS.IRA_DISTRIBUTION_SETUP);
  } else {
    // If a user is in a transfer that isn't an unscheduled
    // IRA distribution then we don't want to send excess
    // contribution in the request.
    yield put({
      type: 'CLEAR_EXCESS_CONTRIBUTION_DATA',
    });
    yield call(changeStep, STEPS.CONFIRM_TRANSFER);
  }
}

function* finishedConfirmTransfer() {
  function* onFinish() {
    yield call(changeStep, STEPS.SHOW_RECEIPT);
  }
  // @ts-expect-error - TS2769 - No overload matches this call.
  yield call(confirmTransfer, onFinish);
}

function* finishedConfirmTransferSchedule(): SagaIterator<void> {
  function* onFinish() {
    yield call(changeStep, STEPS.SHOW_RECEIPT);
  }
  yield call(confirmTransferSchedule, onFinish);
}

function* finishedConfirmLiquidation(onFinish: (...args: Array<any>) => any) {
  yield call(confirmTransfer, onFinish, {
    isLiquidation: true,
  });
}

function* setTransferAmount(): SagaIterator<number> {
  const niaAdjustedTotal = yield call(
    selectFlowState,
    'excessIraDistribution.niaAdjustedTotal',
  );
  if (typeof niaAdjustedTotal === 'number') {
    return niaAdjustedTotal;
  }
  const amount = yield call(selectFlowState, 'input.amount');
  return amount;
}

function* setIdempotencyKey(): SagaIterator<string> {
  return yield call(selectFlowState, 'idempotencyKey');
}

function* setIraTaxWithholdingAmounts(amount: number): SagaIterator<{
  stateWithholdingAmount: Maybe<number>;
  federalWithholdingAmount: Maybe<number>;
}> {
  const stateWithholdingPercentage = yield call(
    selectFlowState,
    'stateWithholdingPercentage',
  );
  const federalWithholdingPercentage = yield call(
    selectFlowState,
    'federalWithholdingPercentage',
  );

  /*
   * Withholding amounts are always rounded UP to the next penny.
   * This avoids any potential Apex errors downstream where withholding
   * is insufficient due to rounding DOWN.
   */
  const stateWithholdingAmount = calculateWithholdingAmountInDollars({
    withholdingPercentage: Number(stateWithholdingPercentage) || 0,
    amount,
  });
  const federalWithholdingAmount = calculateWithholdingAmountInDollars({
    withholdingPercentage: Number(federalWithholdingPercentage) || 0,
    amount,
  });

  return {
    stateWithholdingAmount,
    federalWithholdingAmount,
  };
}

function* createTransferInstance(
  input: CreateTransferInstanceInput,
  additionalInput: Partial<CreateTransferInstanceInput> | null | undefined,
): SagaIterator<CreateTransferInstanceMutation | null | undefined> {
  const mutationInput: CreateTransferInstanceInput = {
    amount: yield call(setTransferAmount),
    fromParticipantId: input.fromParticipantId,
    toParticipantId: input.toParticipantId,
    idempotencyKey: yield call(setIdempotencyKey),
    isLiquidation: input.isLiquidation,
    ...additionalInput,
  };

  const { data }: CreateTransferInstanceMutationResult = yield call(
    apolloMutationSaga,
    {
      mutation: CreateTransferInstanceDocument,
      variables: {
        input: mutationInput,
      },
      refetchQueries: [{ query: CreateTransferInstanceRefetchDocument }],
    },
  );

  return data;
}

function* createIraTransfer(
  input: CreateIraTransferInput,
  additionalInput: Partial<CreateTransferInstanceInput> | null | undefined,
): SagaIterator<CreateIraTransferMutation | null | undefined> {
  const excessIraDistribution = yield call(
    selectFlowState,
    'excessIraDistribution',
  );

  const {
    fromParticipantId,
    toParticipantId,
    iraContributionYear,
    iraDistributionReason,
    isIraRollover,
    isLiquidation,
    niaAmount,
  } = input;

  const amount = yield call(setTransferAmount);
  const idempotencyKey = yield call(setIdempotencyKey);

  const { stateWithholdingAmount, federalWithholdingAmount } = yield call(
    setIraTaxWithholdingAmounts,
    amount,
  );

  const mutationInput = {
    amount,
    fromParticipantId,
    toParticipantId,
    idempotencyKey,
    iraContributionYear,
    iraDistributionReason,
    isIraRollover,
    isLiquidation,
    niaAmount,
    ...(stateWithholdingAmount && { stateWithholdingAmount }),
    ...(federalWithholdingAmount && { federalWithholdingAmount }),
    ...(excessIraDistribution.iraDistributionReason && excessIraDistribution),
    ...additionalInput,
  };

  const { data }: CreateIraTransferMutationResult = yield call(
    apolloMutationSaga,
    {
      mutation: CreateIraTransferDocument,
      variables: {
        input: mutationInput,
      },
      refetchQueries: [{ query: CreateTransferInstanceRefetchDocument }],
    },
  );

  return data;
}

function* confirmTransfer(
  onFinish: (...args: Array<any>) => any,
  additionalInput: Partial<CreateTransferInstanceInput> | null | undefined,
): SagaIterator<void> {
  const input = yield call(selectFlowState, 'input');

  const isIraTransfer =
    input.iraContributionYear ||
    input.isOneTimeIraDistribution ||
    input.isIraRollover;

  try {
    yield put(showLoadingSpinner());

    let outcome;

    if (isIraTransfer) {
      const data = yield call(createIraTransfer, input, additionalInput);
      outcome = data.createIraTransfer.outcome;
    } else {
      const data = yield call(createTransferInstance, input, additionalInput);
      outcome = data.createTransferInstance.outcome;
    }

    yield put({
      type: 'TRANSFER_INSTANCE_CREATED',
      payload: {
        outcome,
      },
    });

    const transferInstanceId = outcome?.instance?.id as string;

    const { analytics } = yield call(getLoggers);
    if (outcome.successAnalyticsEvent) {
      analytics.recordAppAnalyticsEvent(outcome.successAnalyticsEvent);
    }
    yield spawn(logAnalyticsEvent, transferInstanceId);
    yield call(onFinish);
  } catch (e: any) {
    const error: ApolloError = e;
    yield spawn(logCreateTransferInstanceError, e.message);

    const amountRequiresInvestLiquidation = error.graphQLErrors.some(
      (err) => err.extensions.code === 'AMOUNT_REQUIRES_INVEST_LIQUIDATION',
    );
    if (amountRequiresInvestLiquidation) {
      yield call(changeStep, STEPS.CONFIRM_LIQUIDATION);
    } else {
      const returnStep = isIraTransfer
        ? STEPS.IRA_DISTRIBUTION_SETUP
        : STEPS.SETUP_TRANSFER;
      yield call(changeStep, returnStep);
      yield put({
        payload: {
          content: error.message,
          kind: 'alert',
        },
        type: 'ADD_TOAST',
      });
    }
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* confirmTransferSchedule(
  onFinish: (...args: Array<any>) => any,
): SagaIterator<void> {
  const input: SetScheduledTransferRuleInput = yield call(
    selectFlowState,
    'input',
  );
  const { analytics } = yield call(getLoggers);

  try {
    yield put(showLoadingSpinner());
    const { data }: SetScheduledTransferRuleMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: SetScheduledTransferRuleDocument,
        variables: {
          input: {
            amount: input.amount,
            fromParticipantId: input.fromParticipantId,
            schedule: input.schedule,
            toParticipantId: input.toParticipantId,
            iraContributionYear: input.iraContributionYear,
            iraDistributionReason: input.iraDistributionReason,
            scheduledTransferRuleId: input.scheduledTransferRuleId,
          } satisfies SetScheduledTransferRuleInput,
        },
        refetchQueries: [
          {
            query: SetScheduledTransferRuleRefetchDocument,
          },
        ],
      },
    );
    const transferRuleId = data?.setScheduledTransferRule.outcome?.rule
      ?.id as string;
    yield put({
      type: 'SCHEDULED_TRANSFER_RULE_SET',
      payload: {
        outcome: data?.setScheduledTransferRule.outcome,
      },
    });
    analytics.recordEvent('m1_invest_schedule_recurring_deposit_success');
    yield spawn(logAnalyticsEvent, transferRuleId);
    yield call(onFinish);
  } catch (e: any) {
    yield call(changeStep, STEPS.SETUP_TRANSFER);
    analytics.recordEvent('m1_invest_schedule_recurring_deposit_failed');
    yield put({
      payload: {
        content: e.message,
        kind: 'alert',
      },
      type: 'ADD_TOAST',
    });
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* finishedShowReceipt(
  onFinish: (...args: Array<any>) => any,
): SagaIterator<void> {
  yield call(onFinish);
}

function* logAnalyticsEvent(nodeId: string): SagaIterator<void> {
  const analytics = yield call(getAnalyticsReporter);

  try {
    const { data }: CreateTransferLogSagaQueryResult = yield call(
      apolloQuerySaga,
      {
        query: CreateTransferLogSagaDocument,
        variables: {
          nodeId,
        },
      },
    );
    const result = data?.node;
    let eventName;
    if (result?.__typename === 'TransferInstance') {
      if (result.isLiquidation) {
        eventName = 'achLiquidationWithdrawalCreated';
      } else if (
        result.from &&
        result.from.transferParticipantType === 'INVEST'
      ) {
        eventName = 'achImmediateWithdrawalCreated';
      } else if (result.to && result.to.transferParticipantType === 'INVEST') {
        eventName = 'achImmediateDepositCreated';
      }
    } else if (result?.__typename === 'ScheduledTransferRule') {
      if (result.from && result.from.transferParticipantType === 'INVEST') {
        eventName = 'achScheduleWithdrawalCreated';
      } else if (result.to && result.to.transferParticipantType === 'INVEST') {
        eventName = 'achScheduleDepositCreated';
      }
    }

    if (eventName) {
      yield call([analytics, 'mutation'], 'funding', eventName);
    }
  } catch (e: any) {
    // Just do nothing if this fails.
  }
}

function* logCreateTransferInstanceError(
  e: CreateTransferInstanceErrorEnum,
): SagaIterator<void> {
  const analytics = yield call(getAnalyticsReporter);
  let eventName;
  if (e === CreateTransferInstanceErrorEnum.AmountRequiresMarginPayBack) {
    eventName = 'achWithdrawalDeniedUntilPayBack';
  }

  if (eventName) {
    yield call([analytics, 'mutation'], 'funding', eventName);
  }
}
