import { useCallback } from 'react';

import {
  navigate,
  NavigateFunction,
  NavigableRoute,
} from '~/redux/actions/thunks/navigate';
import { useDispatch } from '~/redux/hooks';

export const useNavigate = (): NavigateFunction => {
  const dispatch = useDispatch();

  const handleNavigate = useCallback(
    (route: NavigableRoute) => {
      dispatch(navigate(route));
    },
    [dispatch],
  );

  return handleNavigate;
};
