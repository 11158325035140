import tail from 'lodash-es/tail';

import { AppHistory, RoutingState } from './routingReducer.types';

// Keep ordered history of every route visited
export const buildAppHistory = (
  state: RoutingState,
  {
    payload,
  }: {
    payload: {
      pathname: string;
      search: string;
      query: Record<string, string>;
      action: string;
    };
  },
): AppHistory => {
  const pathNameAndQueryParams = `${payload.pathname}${payload.search}`;
  if (state.appHistory.length === 0) {
    return [pathNameAndQueryParams];
  }
  switch (payload.action) {
    case 'REPLACE':
      return [pathNameAndQueryParams, ...tail(state.appHistory)];
    default:
      return [pathNameAndQueryParams, ...state.appHistory];
  }
};
