import * as React from 'react';
import { Route } from 'react-router';

import { forwardIfAuth, RouteHandlers } from '~/router';

import { PersonalLoanDirectWizard } from './PersonalLoanDirectWizard';

export const PersonalLoanDirectWizardRoute = (routeHandlers: RouteHandlers) => (
  <Route
    path="direct-loan-application"
    fallbackRoute="/signup"
    component={PersonalLoanDirectWizard}
    onEnter={routeHandlers(forwardIfAuth)}
  />
);
