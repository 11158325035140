import { Maybe } from '@m1/liquid-react';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  CommonTransactionHeader,
  CommonTransaction,
  CreditCardCommonTransactionMetadata,
  CommonTransactionsEmptyStateFragment,
} from '~/graphql/types';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';
import { GridTable } from '~/toolbox/grid-table';

import { CreditCardCommonTransactionRow } from './CreditCardCommonTransactionRow';

type CreditCardCommonTransactionsTableProps = {
  tableHeaders?: Maybe<Array<Maybe<CommonTransactionHeader>>>;
  transactions?: Maybe<Array<Omit<CommonTransaction, 'details'>>>;
  emptyStateContent?: CommonTransactionsEmptyStateFragment | null;
  handleDetailsModalOpen: (transactionId: string) => void;
  selectedTransactionId: string | null;
};

export const CreditCardCommonTransactionsTable = ({
  tableHeaders,
  transactions,
  emptyStateContent,
  handleDetailsModalOpen,
  selectedTransactionId,
}: CreditCardCommonTransactionsTableProps) => {
  const isLargeScreen = useMediaQuery({ query: RESPONSIVE_BREAKPOINTS.LARGE });

  return (
    <GridTable
      isNewStyle
      gridTemplateColumns={
        isLargeScreen ? 'auto 160px 88px 160px' : 'auto 96px 88px 96px'
      }
      emptyStateContent={emptyStateContent}
    >
      <GridTable.HeaderRow>
        {tableHeaders?.map((header, idx) => (
          <GridTable.HeaderCell
            key={idx}
            label={header?.title}
            textAlign={
              header?.align?.toLowerCase() as 'left' | 'right' | 'center'
            }
            className={idx === tableHeaders.length - 1 ? 'stickyColumn' : ''}
          />
        ))}
      </GridTable.HeaderRow>
      {transactions?.length ? (
        <GridTable.Groups
          data={transactions}
          rowRender={(rows) =>
            rows.map((transaction) => (
              <CreditCardCommonTransactionRow
                transaction={
                  transaction as CommonTransaction & {
                    metadata: CreditCardCommonTransactionMetadata;
                  }
                }
                key={transaction?.id}
                handleClick={handleDetailsModalOpen}
                isHighlighted={selectedTransactionId === transaction?.id}
              />
            ))
          }
        />
      ) : null}
    </GridTable>
  );
};
