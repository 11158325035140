import * as React from 'react';
import { InjectedRouteProps } from 'react-router';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { useLayout } from '~/hooks/useLayout';
import { enteredPage, exitedPage } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { StyledPageContent } from '../navigation/Navigation.styled';

type Props = InjectedRouteProps & {
  children: React.ReactNode;
};

export const ResearchLayout = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { contentWidth } = useLayout();
  React.useEffect(() => {
    dispatch(enteredPage('RESEARCH', undefined));

    return () => dispatch(exitedPage('RESEARCH', undefined));
  }, []);

  return (
    <StyledPageContent contentWidth={contentWidth}>
      <PageErrorBoundary>{children}</PageErrorBoundary>
    </StyledPageContent>
  );
};
