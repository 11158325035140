import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { SetOrderPage } from './SetOrderPage';

export const SetOrderRoute = () => {
  return (
    <Route
      path="set-order/:portfolioSliceId(/:step)" // component={SetOrderPage}
      component={createRouteComponent(SetOrderPage, (props) => ({
        direction: props.location.query.direction,
        showBuyingPowerOverview: props.location.query.buyingPower === 'true',
        isFromHoldingsPositionDetail:
          props.location.query.isFromHoldingsPositionDetail === 'true',
        portfolioSliceId: props.params.portfolioSliceId,
      }))}
      fallbackRoute="/d/invest/portfolio"
      cover
    />
  );
};
