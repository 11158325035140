import { SagaIterator } from 'redux-saga';
import { call, fork, put, select } from 'redux-saga/effects';

import { SetMaxCashThresholdMutationResult } from '~/graphql/hooks';

import { BorrowAccount } from '~/graphql/types';
import {
  completeDisableAutoInvestMutation,
  displayInlineErrorNotification,
  hideLoadingSpinner,
  setMarginCallInformationPreviousRoute,
  showLoadingSpinner,
  submitDisableAutoInvestMutation,
  updateActivePositionId,
} from '~/redux/actions';
import { MARGIN_CALL_INFORMATION_STEPS as STEPS } from '~/static-constants';

import { changeStep, makeFlowFuncs } from '../utils';

import {
  mutateSetMaxCashThreshold,
  queryHasAutoInvestTurnedOn,
} from './remote';

const { takeFlow, takeFlowStep } = makeFlowFuncs('MARGIN_CALL_INFORMATION');

export function* marginCallInformationSaga(): SagaIterator<void> {
  yield fork(takeFlow, beginResolveMarginFlow);
}

function* beginResolveMarginFlow(): SagaIterator<void> {
  const appHistory = yield select((state) => state.routing.appHistory);
  const lastVisited = appHistory.length > 1 ? appHistory[1] : null;

  if (lastVisited) {
    yield put(setMarginCallInformationPreviousRoute(lastVisited));
  }

  yield fork(takeFlowStep, STEPS.INFO_SCREEN, finishedInfoStep);
  yield fork(
    takeFlowStep,
    STEPS.RESOLVE_CALL_OPTIONS,
    finishedResolveCallOptionsStep,
  );
  yield fork(
    takeFlowStep,
    STEPS.DISABLE_AUTO_INVEST,
    finishedDisableAutoInvest,
  );
  yield fork(takeFlowStep, STEPS.HOLDINGS_OVERVIEW, finishedHoldingsOverview);
  yield fork(takeFlowStep, STEPS.POSITION_DETAILS, finishedPositionDetails);
}

function* finishedInfoStep() {
  yield call(changeStep, STEPS.RESOLVE_CALL_OPTIONS);
}

function* finishedResolveCallOptionsStep(action: any) {
  if (action.payload === STEPS.INFO_SCREEN) {
    yield call(changeStep, STEPS.INFO_SCREEN);
  } else {
    // @ts-expect-error - TS7057 - 'yield' expression implicitly results in an 'any' type because its containing generator lacks a return-type annotation.
    const borrowAccountId = yield select(
      (state) => state.newFlows.MARGIN_CALL_INFORMATION.borrowAccountId,
    );
    const hasAutoInvestTurnedOn: BorrowAccount =
      yield queryHasAutoInvestTurnedOn(borrowAccountId);

    if (
      hasAutoInvestTurnedOn &&
      hasAutoInvestTurnedOn.investAccount &&
      hasAutoInvestTurnedOn.investAccount.maxCashThreshold !== null
    ) {
      yield call(changeStep, STEPS.DISABLE_AUTO_INVEST);
    } else {
      yield call(changeStep, STEPS.HOLDINGS_OVERVIEW);
    }
  }
}

function* finishedDisableAutoInvest() {
  // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
  yield put(submitDisableAutoInvestMutation());
  // @ts-expect-error - TS7057 - 'yield' expression implicitly results in an 'any' type because its containing generator lacks a return-type annotation.
  const borrowAccountId = yield select(
    (state) => state.newFlows.MARGIN_CALL_INFORMATION.borrowAccountId,
  );
  yield put(showLoadingSpinner());
  const { data }: SetMaxCashThresholdMutationResult = yield call(
    mutateSetMaxCashThreshold,
    borrowAccountId,
  );
  yield put(hideLoadingSpinner());
  // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
  yield put(completeDisableAutoInvestMutation());

  if (
    data &&
    data.setMaxCashThreshold &&
    data.setMaxCashThreshold.result &&
    data.setMaxCashThreshold.result.didSucceed
  ) {
    yield call(changeStep, STEPS.HOLDINGS_OVERVIEW);
  } else {
    yield put(
      displayInlineErrorNotification({
        location: 'RESOLVE_MARGIN_CALL_TURN_OFF_AUTO_INVEST',
        message: 'There was an error disabling auto invest. Please try again.',
      }),
    );
  }
}

function* finishedHoldingsOverview(action: any) {
  const { payload } = action;
  if (payload === STEPS.RESOLVE_CALL_OPTIONS) {
    yield call(changeStep, STEPS.RESOLVE_CALL_OPTIONS);
  } else {
    yield put(updateActivePositionId(payload));
    yield call(changeStep, STEPS.POSITION_DETAILS);
  }
}

function* finishedPositionDetails(action: any) {
  const { payload } = action;
  if (payload === STEPS.HOLDINGS_OVERVIEW) {
    yield call(changeStep, STEPS.HOLDINGS_OVERVIEW);
  }
}
