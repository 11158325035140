import { Box, Button, Flex, HXXS, LS } from '@m1/liquid-react';
import * as React from 'react';
import { withRouter } from 'react-router';

import { GenericSystemError } from '~/components/GenericSystemError';
import { NavigableButton } from '~/components/NavigableButton';
import { useOnDemandPortfolioTradingInProgressPageQuery } from '~/graphql/hooks';
import { useNavigate } from '~/hooks/useNavigate';
import { useSupportLink } from '~/hooks/useSupportLink';
import type { AppState } from '~/redux';
import { useSelector } from '~/redux/hooks';
import { InjectedRouteProps } from '~/router';
import { Spinner } from '~/toolbox/spinner';
import { fibonacci, formatNumber } from '~/utils';

import { OnDemandAllocatedTradesHeader } from './components/OnDemandAllocatedTradesHeader';
import { OnDemandAllocatedTradesTable } from './components/OnDemandAllocatedTradesTable';

const TRANSIENT_STATUSES = ['PENDING', 'OPEN'];

const formatCryptoValue = (value: number | null | undefined) => {
  return value
    ? `$${formatNumber(
        value,
        value < 1 ? '0,0.00[00000000]' : '0,0.00',
      ).toUpperCase()}`
    : '--';
};

const OnDemandPortfolioTradingInProgressComponent = ({
  params,
}: InjectedRouteProps) => {
  const accountId = useSelector(
    (state: AppState) => state.global.activeAccountId,
  );
  const navigate = useNavigate();
  const supportLink = useSupportLink('SUPPORT_CRYPTO');
  const pollingCount = React.useRef(1);
  const pollingInterval = fibonacci(pollingCount.current) * 1000;
  const pollingStartedTimestamp = new Date();

  const { data, loading, startPolling, stopPolling } =
    useOnDemandPortfolioTradingInProgressPageQuery({
      variables: {
        accountId: accountId as string,
        tradeWindowId: params.tradeWindowId,
      },
      skip: !accountId || !params.tradeWindowId,
      onCompleted: () => pollingCount.current++,
    });

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (
    data?.node?.__typename !== 'Account' ||
    !data?.node?.trading ||
    !params.tradeWindowId
  ) {
    return <GenericSystemError />;
  }

  const trading = data.node.trading;

  const secondsPolled = pollingStartedTimestamp
    ? Math.abs(pollingStartedTimestamp.getTime() - new Date().getTime()) / 1000
    : 0;
  const shouldPoll = secondsPolled < 60;

  const trades = trading.onDemandAllocatedTrades;
  const hasPendingTrades =
    trading.onDemandAllocatedTrades?.some((trade) =>
      TRANSIENT_STATUSES.includes(trade.status),
    ) ?? true;
  const hasAllCompletedTrades = Boolean(
    trading.onDemandAllocatedTrades?.every(
      (trade) => trade.status === 'FILLED',
    ),
  );
  const hasRejectedNoPending = Boolean(
    !hasPendingTrades &&
      trading.onDemandAllocatedTrades?.some(
        (trade) => trade.status === 'REJECTED',
      ),
  );
  const hasPendingAndTimedOutTrades = hasPendingTrades && !shouldPoll;
  const hasIncompleteTrades =
    hasRejectedNoPending || hasPendingAndTimedOutTrades;

  // Only refetch if there are still pending trades and within the first minute
  // If no polling started timestamp, then poll until dismount
  if (hasPendingTrades && shouldPoll) {
    startPolling(pollingInterval);
  } else {
    stopPolling();
  }

  const buys = trades?.filter((trade) => trade.side === 'BUY');
  const sells = trades?.filter((trade) => trade.side === 'SELL');

  const buysSum = buys?.reduce(
    (acc, trade) => acc + (trade.allocatedAmount ?? 0),
    0,
  );
  const sellsSum = sells?.reduce(
    (acc, trade) => acc + (trade.allocatedAmount ?? 0),
    0,
  );
  return (
    <Box maxWidth={1200} m="0 auto" p={16}>
      <OnDemandAllocatedTradesHeader
        hasAllCompletedTrades={hasAllCompletedTrades}
        hasIncompleteTrades={hasIncompleteTrades}
      />
      <Box mb={32}>
        <Flex justifyContent="space-between">
          <HXXS mb={16}>Buys</HXXS>
          <LS>Total: {formatCryptoValue(buysSum)}</LS>
        </Flex>
        <OnDemandAllocatedTradesTable trades={buys} label="buys" />
      </Box>
      <Box>
        <Flex justifyContent="space-between">
          <HXXS mb={16}>Sells</HXXS>
          <LS>Total: {formatCryptoValue(sellsSum)}</LS>
        </Flex>
        <OnDemandAllocatedTradesTable trades={sells} label="sells" />
      </Box>
      {!hasPendingTrades && (
        <Box my={32}>
          {hasIncompleteTrades && (
            <NavigableButton
              kind="secondary"
              label="Contact us"
              size="large"
              mr={16}
              {...supportLink}
            />
          )}
          <Button
            label="View my portfolio"
            onClick={() => {
              navigate({ to: '/d/invest/portfolio' });
            }}
            size="large"
          />
        </Box>
      )}
    </Box>
  );
};

export const OnDemandPortfolioTradingInProgressPage = withRouter(
  OnDemandPortfolioTradingInProgressComponent,
);
