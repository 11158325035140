/* eslint-disable react/sort-comp */

import some from 'lodash-es/some';
import * as React from 'react';
import { RouteDefinition } from 'react-router';

import { TransitionGroup } from '~/toolbox/transition-group';

import style from './style.module.scss';

export type CoverEscrowProps = {
  children?: React.ReactElement<any>;
  enabled: boolean;
  routes: Array<RouteDefinition>;
};

export class CoverEscrow extends React.Component<CoverEscrowProps> {
  static defaultProps = {
    enabled: true,
  };

  previousChildren: React.ReactElement<any> | null | undefined = null;

  UNSAFE_componentWillReceiveProps(nextProps: CoverEscrowProps) {
    const currentlyCover = isCoverRoute(this.props.routes);
    const nextCover = isCoverRoute(nextProps.routes);

    if (nextCover && !currentlyCover) {
      this.previousChildren = this.props.children;
    }
  }

  render() {
    const isCover = isCoverRoute(this.props.routes) && this.props.enabled;
    const children = isCover ? this.previousChildren : this.props.children;

    const transitionName = {
      enter: style.enter,
      enterActive: style.enterActive,
      leave: style.leave,
      leaveActive: style.leaveActive,
    };

    return (
      <div>
        <div className={style.children}>{children}</div>
        <TransitionGroup
          transitionName={transitionName}
          enterTimeout={300}
          leaveTimeout={300}
        >
          {isCover ? this.props.children : null}
        </TransitionGroup>
      </div>
    );
  }
}

function isCoverRoute(routes: Array<RouteDefinition>): boolean {
  return some(routes, (route) => route.cover);
}
