import { LayoutableProps, PL, Flex, Box, styled, css } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

export type Suggestion = {
  text: string;
  value: string;
};

export type SearchInputProps = LayoutableProps & {
  emptyComponent?: React.ReactNode;
  onChange: React.ChangeEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
  onSelectSuggestion?: (suggestion: Suggestion) => void;
  placeholder?: string;
  size?: number;
  suggestions?: Suggestion[];
  value?: string;
  fullWidth?: boolean;
  width?: React.CSSProperties['width'];
  borderless?: boolean;
};

const StyledSearchInput = styled(Box)<{
  fullWidth?: boolean;
  width: string | number;
}>`
  width: ${({ fullWidth, width }) => (fullWidth ? '100%' : width)};
  position: relative;
`;

const StyledSvg = styled.svg`
  margin-right: 8px;
`;

const StyledPath = styled.path`
  fill: ${(props) => props.theme.colors.foregroundNeutralSecondary};
`;

const StyledInput = styled.input`
  background: transparent;
  border: 0;
  flex: 1 0 auto;
`;

const StyledSearchResultContainer = styled(Box)<{
  showSuggestions?: boolean;
  fullWidth?: boolean;
  width?: React.CSSProperties['width'];
}>`
  background: ${(props) => props.theme.colors.backgroundNeutralSecondary};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: opacity 0.25s;
  opacity: ${({ showSuggestions }) => (showSuggestions ? 1 : 0)};
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : width || 600)};
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  div:first-child {
    margin-top: 8px;
  }
  div:last-child {
    border-bottom: 0;
  }
`;

const StyledSearchResult = styled(Flex)`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderMain};
  transition: background-color 200ms;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundInfoSubtle};
  }
`;

const StyledRootFlex = styled(Flex)<{ $borderless?: boolean }>`
  background-color: ${(props) => props.theme.colors.backgroundNeutralSecondary};

  ${({ $borderless, theme }) =>
    $borderless
      ? null
      : css`
          border: 1px solid ${theme.colors.borderMain};
        `}
  border-radius: 16px;
  font-size: 12px;
  padding: 8px 16px;
  transition: all 0.1s ease-in-out;

  ${StyledInput} {
    color: ${(props) => props.theme.colors.foregroundNeutralSecondary};
  }

  &:focus-within {
    border-color: ${(props) => props.theme.colors.primary};
    ${StyledInput} {
      color: ${(props) => props.theme.colors.foregroundNeutralMain};
    }
  }
`;

export const SearchInput = React.forwardRef<'input', SearchInputProps>(
  (props: SearchInputProps, ref) => {
    const {
      onChange,
      onKeyDown,
      placeholder,
      suggestions = [],
      emptyComponent,
      onSelectSuggestion,
      width = 300,
      fullWidth = false,
      size,
      value,
      borderless,
      ...rest
    } = props;
    const innerRef = React.createRef<HTMLInputElement>();
    const inputRef = (ref ?? innerRef) as React.RefObject<HTMLInputElement>;

    const handleClick = (): void => {
      innerRef.current?.focus?.();
    };

    const handleSuggestion = (suggestion: Suggestion) =>
      onSelectSuggestion ? onSelectSuggestion(suggestion) : null;

    const showSuggestions = suggestions.length !== 0 && Boolean(props.value);
    const showEmptyComponent = emptyComponent && props.value;
    return (
      <StyledSearchInput fullWidth={fullWidth} width={width}>
        <StyledRootFlex
          {...rest}
          onClick={handleClick}
          $borderless={borderless}
        >
          <Icon name="search16" mr={8} color="foregroundNeutralMain" />
          <StyledInput
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={placeholder || 'Search'}
            ref={inputRef}
            size={size}
            value={value}
          />
        </StyledRootFlex>
        <StyledSearchResultContainer
          borderRadius={8}
          showSuggestions={showSuggestions}
          width={width}
          fullWidth={fullWidth}
        >
          {showSuggestions &&
            suggestions?.map((suggestion) => (
              <StyledSearchResult
                key={suggestion.text}
                padding={8}
                onClick={() => handleSuggestion(suggestion)}
              >
                <PL
                  ml={4}
                  mt={4}
                  color="foregroundNeutralMain"
                  content={suggestion.text}
                />
              </StyledSearchResult>
            ))}
          {showEmptyComponent && (
            <Box p={8} color="foregroundNeutralMain">
              {emptyComponent}
            </Box>
          )}
        </StyledSearchResultContainer>
      </StyledSearchInput>
    );
  },
);
