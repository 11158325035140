import { Flex, Maybe } from '@m1/liquid-react';
import * as React from 'react';

import { useMediaQuery } from 'react-responsive';

import { ConnectedPie } from '~/components/ConnectedPie';
import { EditModelSlice } from '~/pages/share/SharePiePage.types';

import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

import { SharePieDonutChartHighlights } from './SharePieDonutChartHighlights';

export type SharePieDonutChartCardProps = {
  slices: Array<EditModelSlice>;
  sinceDate: string;
  valuePercentChange: Maybe<number>;
};

export const SharePieDonutChartCard = ({
  slices,
  sinceDate,
  valuePercentChange,
}: SharePieDonutChartCardProps) => {
  const pieChartData = slices.map((slice) => ({
    id: slice.to.id,
    percentage: slice.percentage,
  }));
  const isMobile = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.SMALL,
  });

  return (
    <Flex
      alignItems="center"
      position="relative"
      p={`${isMobile ? 16 : 32}px 0`}
      width={292}
    >
      <ConnectedPie
        data={pieChartData}
        height={292}
        innerRadius={103}
        onClick={(dataItem) => {}}
        width={292}
      />
      <SharePieDonutChartHighlights
        slices={slices}
        sinceDate={sinceDate}
        percentChange={valuePercentChange}
      />
    </Flex>
  );
};
