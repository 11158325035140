import { Banner, Button, HXS, PL } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';

import { CreateTransferNotifications } from '~/components/notifications';
import { useConfirmLiquidationQuery } from '~/graphql/hooks';
import { useHistory } from '~/hooks/useHistory';
import { useSelector } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';

type Props = {
  onFinishStep: (...args: Array<any>) => any;
};

export const ConfirmLiquidationStep = (props: Props) => {
  const accountId = useSelector((state) => state.global.activeAccountId);
  const history = useHistory();
  const handleBack = (): void => history.goBack();
  const handleConfirm = (): void => props.onFinishStep();
  const { data, loading } = useConfirmLiquidationQuery({
    variables: {
      id: accountId as string,
    },
    skip: !accountId,
  });

  if (loading) {
    return null;
  }

  if (data?.node?.__typename !== 'Account') {
    return <GenericSystemError />;
  }

  return (
    <>
      <HXS content="Confirm liquidation" />
      <PL
        content="This withdrawal request will result in account liquidation. Due to market fluctuations, this may result in an amount greater or less than requested."
        mt={16}
      />
      <PL content="Are you sure you want to proceed?" mt={16} />
      {data.node.isRetirement && (
        <Banner
          content="M1 charges an industry-standard $100 IRA closing fee for a liquidation."
          kind="alert"
          mt={16}
          size="inline"
        />
      )}
      <CreateTransferNotifications />
      <ButtonGroup mt={48}>
        <Button
          kind="secondary"
          label="Back"
          onClick={handleBack}
          size="large"
        />
        <Button
          kind="primary"
          label="Accept Liquidation"
          onClick={handleConfirm}
          size="large"
        />
      </ButtonGroup>
    </>
  );
};
