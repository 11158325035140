import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { ReportTransactionIssuePage } from './ReportTransactionIssuePage';

export const ReportTransactionIssueRoute = () => {
  return (
    <Route
      path="report-transaction-issue(/:disputeDetailsId)"
      component={createRouteComponent(ReportTransactionIssuePage, (props) => ({
        nodeId: props.params.disputeDetailsId,
      }))}
      fallbackRoute="spend"
      cover
    />
  );
};
