import { SagaIterator } from 'redux-saga';
import { put, take } from 'redux-saga/effects';

import { ACTION_TYPES as ACTIONS, navigate } from '~/redux/actions';

export function* openAdditionalAccountsMonitor(): SagaIterator<void> {
  yield take([
    ACTIONS.ACCOUNT_SETUP_FLOW_CANCELED,
    ACTIONS.ACCOUNT_SETUP_FLOW_FINISHED,
    ACTIONS.INVEST_ACCOUNT_SETUP_FLOW_CANCELED,
  ]);
  yield put(navigate({ to: '/d/invest/portfolio' }));
}
