import * as React from 'react';
import { Route } from 'react-router';

import { Redirect } from './Redirect';

export const OpenAccountRoute = () => (
  <Route
    path="open-account(/:accountSetupStep)"
    component={Redirect}
    fallbackRoute="/d/open-invest-account"
    cover
  />
);
