import { Box, ListItem, Maybe } from '@m1/liquid-react';
import React from 'react';

import { useForm } from 'react-hook-form';

import { usePersonalLoansDirectQuery } from '~/graphql/hooks';
import {
  PersonalLoanDirectQuestionEnum,
  PersonalLoanDirectQuestion,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { submitReactFormData } from '~/redux/actions';

import { useDispatch } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';
import { getEnumEntries } from '~/utils';

import { BackButton } from '../BackButton';
import { StyledHeader } from '../components/StyledHeader';
import { usePersonalLoanDirectWizardContext } from '../personal-loan-direct-wizard/usePersonalLoanDirectWizardContext';
import { getQuestionProps } from '../utils/getQuestionprops';

export interface AvailableCashNetWorthFormValues {
  availableCashNetWorth: string;
}

export const AvailableCash = () => {
  const { goTo, back } = usePersonalLoanDirectWizardContext();
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const { data, loading } = usePersonalLoansDirectQuery();

  const questions =
    data?.viewer?.borrow?.personalLoans?.applicationFlowDirect?.questions;

  let props: Maybe<Partial<PersonalLoanDirectQuestion>> | undefined;

  if (questions) {
    props = getQuestionProps({
      question: PersonalLoanDirectQuestionEnum.AvailableCash,
      questions,
    });
  }

  const { title, nextQuestionType } = props || {};

  const totalNetWorthOptions = getEnumEntries('TotalNetWorthEnum');

  const { handleSubmit, setValue } = useForm<AvailableCashNetWorthFormValues>();

  const onSubmit = (data: AvailableCashNetWorthFormValues) => {
    dispatch(
      submitReactFormData({
        directToPersonalLoansAvailableCashNetWorthInfo: data,
      }),
    );
    goTo(nextQuestionType ?? 'FINANCIAL_DISCLOSURES');
  };

  const handleOptionClick = (name: string) => {
    setValue('availableCashNetWorth', name);
    handleSubmit(onSubmit)();
  };

  React.useEffect(() => {
    analytics.pageView('/direct-loan-application/available-cash');
  }, [analytics]);

  if (loading) {
    return <Spinner fullScreen centered />;
  }

  return (
    <>
      <Box mb={-16}>
        <BackButton onBackClick={() => back()} />
      </Box>
      <Box mb={24}>
        <StyledHeader
          content={
            title ??
            'Approximately how much do you have in cash and investments?'
          }
        />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ul>
          {totalNetWorthOptions.map(({ name, description }) => (
            <ListItem
              key={name}
              header={description}
              size="SMALL"
              selectable
              onClick={() => handleOptionClick(name)}
            />
          ))}
        </ul>
      </form>
    </>
  );
};
