import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import { CryptoScreener } from '~/pages/dashboard/research/crypto/components';

import { AddCryptoDetailsRoute } from './details';

export const AddCryptoRoute = () => {
  return (
    <Route path="crypto">
      {AddCryptoDetailsRoute()}
      <IndexRoute component={CryptoScreener} />
    </Route>
  );
};
