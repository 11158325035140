import { css, SemanticColorNames, styled } from '@m1/liquid-react';

import { StyledInputBox } from './StyledInputBox';

interface StyledLabelProps {
  additionalContentOnly: boolean;
  align: 'left' | 'center' | 'right';
  backgroundColor?: SemanticColorNames;
  disabled?: boolean;
  error: boolean;
  iconAndContentOrder?: 'left' | 'right';
  isFilled: boolean;
  isFocused: boolean;
  size: 'medium' | 'large' | 'xlarge';
}

export const StyledInputLabel = styled.label<StyledLabelProps>`
  color: ${({ disabled, theme, error, isFocused }) => {
    if (isFocused) {
      return error ? theme.colors.critical : theme.colors.primary;
    } else if (disabled) {
      return error
        ? theme.colors.critical
        : theme.colors.foregroundNeutralTertiary;
    }
    return error
      ? theme.colors.critical
      : theme.colors.foregroundNeutralSecondary;
  }};
  pointer-events: none;
  position: absolute;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor
      ? theme.colors[backgroundColor]
      : theme.colors.backgroundNeutralSecondary};
  right: ${({ align }) => align !== 'left' && '0'};
  text-align: ${({ align }) => align};
  font-weight: ${({ error }) => error && 600};

  ${({ size }) => {
    let labelOffsetAmount = -20;
    if (size === 'large') {
      labelOffsetAmount = -24;
    } else if (size === 'xlarge') {
      labelOffsetAmount = -28;
    }
    return css<StyledLabelProps>`
      transition: all 0.3s ease-in-out;
      // Prevent safari stutter with transition
      -webkit-transition-duration: 0.2s;
      left: 10px;
      padding: 0px 8px;
      ${({ isFilled, isFocused }: StyledLabelProps) =>
        (isFilled || isFocused) &&
        css`
          font-size: 12px;
          transform: translateY(${labelOffsetAmount}px);
        `};
      ${StyledInputBox}:focus-within & {
        font-size: 12px;
        transform: translateY(${labelOffsetAmount}px);
      }
      ${({ additionalContentOnly }: StyledLabelProps) =>
        additionalContentOnly &&
        css`
          top: 34px;
        `}
    `;
  }};
`;
