import isEmpty from 'lodash-es/isEmpty';
import { LOCATION_CHANGE } from 'react-router-redux';

import {
  INTERFACE_ACTIONS,
  InterfaceAction,
} from '../actions/interfaceActions';

export type InterfaceState = {
  highlightedSliceId: string | null | undefined;
};

export const initialState: InterfaceState = {
  highlightedSliceId: null,
};

export const interfaceReducer = (
  state: InterfaceState = initialState,
  action: InterfaceAction,
): InterfaceState => {
  switch (action.type) {
    case INTERFACE_ACTIONS.CLICKED_ON_SLICEABLE:
    case LOCATION_CHANGE:
      return {
        ...state,
        highlightedSliceId: null,
      };
    case INTERFACE_ACTIONS.HIGHLIGHT_SLICE:
      return state.highlightedSliceId !== action.payload
        ? {
            ...state,
            highlightedSliceId: isEmpty(action.payload) ? null : action.payload,
          }
        : state;
    default:
      return state;
  }
};
