import * as React from 'react';

import { useSpendPageQuery } from '~/graphql/hooks';
import { Spinner } from '~/toolbox/spinner';
import { isNotNil } from '~/utils';

import { SpendRedirect } from './SpendRedirect';

type SpendPageProps = {
  children: typeof React.Children;
};

const terminalApplicationStatuses = ['APPROVED', 'ACCEPTED', 'REJECTED'];

export const SpendPage = ({ children }: SpendPageProps) => {
  const { data, loading } = useSpendPageQuery();

  if (loading) {
    return <Spinner />;
  }

  const credit = data?.viewer.credit;
  const hasClosedCreditCardAccount = credit?.hasClosedAccount ?? false;
  const hasActiveCreditCardAccount = credit?.hasActiveAccount ?? false;
  const hasSuspendedCreditCardAccount = credit?.hasSuspendedAccount ?? false;

  const hasActiveApplication =
    isNotNil(credit?.activeApplication) &&
    !terminalApplicationStatuses.includes(credit.activeApplication.status);
  const hasCreditCardAccount =
    hasActiveCreditCardAccount || hasSuspendedCreditCardAccount;

  return (
    <SpendRedirect
      hasActiveApplication={hasActiveApplication}
      hasClosedCreditCardAccount={hasClosedCreditCardAccount}
      hasCreditCardAccount={hasCreditCardAccount}
    >
      {children}
    </SpendRedirect>
  );
};
