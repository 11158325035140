import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { ContactUsPage as ContactUsPageComponent } from './ContactUs';

export const ContactUsRoute = () => {
  return (
    <Route
      path="contact-us"
      component={createRouteComponent(ContactUsPageComponent)}
    />
  );
};
