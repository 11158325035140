import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import {
  CreateProfileDocument,
  CreateProfileMutationResult,
} from '~/graphql/hooks';

import {
  CreateProfileInput,
  MailingAddressCountryEnum,
  ProductListIdentifier,
} from '~/graphql/types';
import {
  ACTION_TYPES as ACTIONS,
  finishedSpendOnboarding,
  hideLoadingSpinner,
  navigate,
  setProfileCreated,
  showLoadingSpinner,
} from '~/redux/actions';
import { formatDateForAccountMutation } from '~/utils';

import type { AppState } from '../../reducers/types';
import { apolloMutationSaga } from '../apolloMutationSaga';

export function* createProfileSaga(): SagaIterator<void> {
  yield takeEvery(ACTIONS.FINISHED_CREATE_PROFILE, finishedCreateProfile);
  yield takeEvery(ACTIONS.EDIT_KNOW_YOUR_CUSTOMER, editKnowYourCustomer);
  yield takeEvery(ACTIONS.EDIT_PROFILE_IDENTITY, editProfileIdentity);
}

function* editKnowYourCustomer(): SagaIterator<void> {
  yield put(navigate({ to: 'spend-onboarding/kyc' }));
}

function* editProfileIdentity(): SagaIterator<void> {
  yield put(navigate({ to: 'spend-onboarding/identity-profile' }));
}

function* finishedCreateProfile(): SagaIterator {
  const {
    input,
    phone,
    disclosures,
    productIdentifier,
  }: {
    disclosures: Record<string, any>;
    input: Record<string, any>;
    phone: string | null | undefined;
    productIdentifier: ProductListIdentifier | null;
  } = yield select((state: AppState) => ({
    input: state.newFlows.accountSetup.input,
    phone: state.newFlows.accountSetup.phone,
    productIdentifier: state.newFlows.onboarding.product.productIdentifier,
  }));

  try {
    if (!input) {
      throw new Error('Missing input');
    }
    yield put(showLoadingSpinner());
    const createProfileInput: CreateProfileInput = transformHolderInput(
      input,
      phone,
      disclosures,
      productIdentifier,
    );
    const result: CreateProfileMutationResult = yield call(apolloMutationSaga, {
      mutation: CreateProfileDocument,
      variables: {
        input: {
          ...createProfileInput,
        } satisfies CreateProfileInput,
      },
    });
    const hasProfile = readProfileId(result.data);

    yield put(setProfileCreated(hasProfile));
    yield put(finishedSpendOnboarding());
  } catch (e: any) {
    yield put({
      payload: {
        content: e.message,
        kind: 'alert',
      },
      type: 'ADD_TOAST',
    });
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function transformHolderInput(
  input: Record<string, any>,
  phone: string | null | undefined,
  disclosures: Record<string, any>,
  productIdentifier: ProductListIdentifier | null,
): CreateProfileInput {
  const createProfileInput: CreateProfileInput = {
    phoneNumber: phone,
    name: {
      firstName: input.primary.firstName,
      lastName: input.primary.lastName,
    },
    countryOfCitizenship: input.primary.countryOfCitizenship,
    address: {
      lineOne: input.primary.homeAddress.lineOne,
      city: input.primary.homeAddress.city,
      stateOrProvince: input.primary.homeAddress.stateOrProvince,
      postalCode: input.primary.homeAddress.postalCode,
      country: MailingAddressCountryEnum.Usa,
    },
    dateOfBirth: formatDateForAccountMutation(input.primary.dateOfBirth),
    ssn: input.primarySsn,
    employmentInfo: input.primary.employment,
    disclosures: {
      politicalExposureDisclosure: {
        isPoliticallyExposed: disclosures.politicalExposureDisclosure,
      },
    },
    productSelection: productIdentifier,
    suitability: input.suitability,
  };
  return createProfileInput;
}

function readProfileId(
  result: Record<string, any> | null | undefined,
): boolean {
  return Boolean(result?.createProfile?.outcome?.userProfileId);
}
