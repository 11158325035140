import { Box, css, styled } from '@m1/liquid-react';
import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';

import { useSentry } from '~/hooks/useSentry';

import { GridContext } from '../grid-table/context';
import { StyledGridTableRow } from '../grid-table/row';

interface Props<T> {
  rowHeight: number;
  row: React.FC<VirtualRowProps<T>>;
  widthReduction?: number;
}

export interface VirtualRowProps<T> {
  index: number;
  style?: any;
  data: T[];
}

const RowContainer = styled(Box)``;

const VirtualizedRowsContainer = styled(Box)<{ $isNewStyle: boolean }>`
  ${({ $isNewStyle, theme }) =>
    !$isNewStyle &&
    css`
      border-bottom: 1px solid ${theme.colors.borderMain};
      border-left: 1px solid ${theme.colors.borderMain};
      border-right: 1px solid ${theme.colors.borderMain};
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      ${StyledGridTableRow} {
        border-left: none;
        border-right: none;
      }
    `}
`;

export const VirtualizedRows = <T,>({
  rowHeight,
  row: Row,
  widthReduction = 1,
}: Props<T>) => {
  const sentry = useSentry();
  const { virtualization, isVirtualized, isNewStyle } =
    React.useContext(GridContext);
  if (!virtualization) {
    sentry.message(
      'The VirtualizedRows component requires that virtualization props be passed to the GridTable parent component',
      { level: 'warning' },
    );
    return null;
  }
  const items = virtualization.rowList;

  // Return normal rows if there are less rows than the visibleRowCount
  if (!isVirtualized) {
    return (
      <>
        {items.map((item, index) => {
          return (
            <Row key={`virtualized-row-${index}`} index={index} data={items} />
          );
        })}
      </>
    );
  }

  // We peek 1/2 of the next row to let user know more rows exist.
  // If there are less items than the visible row count,
  // we want to make the height equal to the number of items.
  const windowHeight =
    rowHeight *
    (Math.min(virtualization.visibleRowCount, items.length - 0.5) + 0.5);

  return (
    <VirtualizedRowsContainer height={windowHeight} $isNewStyle={isNewStyle}>
      <AutoSizer>
        {({ width }: { width: number }) => (
          <List
            {...{
              height: windowHeight,
              itemCount: items.length,
              itemSize: () => rowHeight,
              width: width - widthReduction, // Reduce determined width if needed for displaying a border
              itemData: items,
            }}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    </VirtualizedRowsContainer>
  );
};
