import * as React from 'react';
import {
  Router,
  applyRouterMiddleware,
  ReadyStateChangeEvent,
  EnterHook,
  RouteConfig,
} from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { Store } from 'redux';

import { AnalyticsReporter, SentryReporter } from '~/loggers';
import { AppState } from '~/redux';

import { RouteHandler } from './auth-handlers';
import { createScrollMiddleware } from './middlewares';
import { createRouteHandler } from './utils/deprecated';

export type RouteHandlers = (
  ...handlers: RouteHandler[]
) => EnterHook | undefined;

type ConfigureRouterArgs = {
  analytics: AnalyticsReporter;
  history: any;
  renderScreensRoot: (
    analytics: AnalyticsReporter,
    routeHandlers: RouteHandlers,
  ) => RouteConfig;
  sentry: SentryReporter;
  store: Store<AppState>;
};

export function configureRouter({
  analytics,
  history,
  renderScreensRoot,
  sentry,
  store,
}: ConfigureRouterArgs) {
  const syncedHistory = syncHistoryWithStore(history, store);
  const routes = renderScreensRoot(analytics, createRouteHandler(store));

  function handleReadyStateChange({ error }: ReadyStateChangeEvent) {
    if (error) {
      sentry.exception(error);
    }
  }

  return (
    <Router
      history={syncedHistory}
      routes={routes}
      // Ignore the error below since it will be fixed with the react-router upgrade.
      // @ts-ignore TS2769: No overload matches this call
      render={applyRouterMiddleware(createScrollMiddleware())}
      onReadyStateChange={handleReadyStateChange}
    />
  );
}
