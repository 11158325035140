import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';

import {
  InvestAccountOpeningQuestion,
  InvestAccountOpeningQuestionType,
} from '~/graphql/types';
import { INVEST_ONBOARDING_FLOW_STEPS } from '~/static-constants';

import { changeStep } from '../../utils';

import { mapQuestionToFlowStep, mapSuitabilityToQuestion } from './mappings';

export function* handleInvestSuitabilityNavigation(
  action: any,
  investSuitabilityQuestions: InvestAccountOpeningQuestion[],
): SagaIterator<void> {
  const suitabilityField: string = action.payload.field;
  const currentQuestion = mapSuitabilityToQuestion(suitabilityField);
  const nextQuestion = getNextQuestion(
    currentQuestion,
    investSuitabilityQuestions,
  );

  yield call(finishedInvestSuitabilityQuestion, nextQuestion);
}

export const getInitialInvestQuestion = (
  allQuestions: Array<InvestAccountOpeningQuestion>,
): InvestAccountOpeningQuestionType | null =>
  allQuestions.filter((question) => !question.previousQuestionType)[0]
    ?.questionType || null;

export const getNextQuestion = (
  currentQuestion: InvestAccountOpeningQuestionType,
  allQuestions: Array<InvestAccountOpeningQuestion>,
): InvestAccountOpeningQuestionType | null =>
  allQuestions.filter(
    (question) => question.questionType === currentQuestion,
  )[0].nextQuestionType || null;

export function* finishedInvestSuitabilityQuestion(
  nextQuestion: InvestAccountOpeningQuestionType | null | undefined,
): SagaIterator<void> {
  const isPrimary = yield select(
    (state) => state.newFlows.INVEST_ONBOARDING.input.holder === 'primary',
  );

  if (!nextQuestion) {
    yield call(finishedInvestSuitabilitySection);
  } else {
    yield call(changeStep, mapQuestionToFlowStep(nextQuestion, isPrimary));
  }
}

export function* finishedInvestSuitabilitySection(): SagaIterator<void> {
  yield call(changeStep, INVEST_ONBOARDING_FLOW_STEPS.REVIEW);
}
