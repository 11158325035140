import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  ArchiveTransferRuleDocument,
  SetScheduledTransferRuleDocument,
  SetScheduledTransferRuleRefetchDocument,
  TransfersRulesPageDocument,
} from '~/graphql/hooks';
import {
  ArchiveTransferRuleInput,
  SetScheduledTransferRuleInput,
} from '~/graphql/types';

import {
  ACTION_TYPES as ACTIONS,
  hideLoadingSpinner,
  navigate,
  showLoadingSpinner,
} from '~/redux/actions';

import { apolloMutationSaga } from '../apolloMutationSaga';

export function* editTransferScheduleMonitor(): SagaIterator<void> {
  yield takeEvery(
    ACTIONS.FINISHED_EDITING_TRANSFER_SCHEDULE,
    function* (action: any): SagaIterator<void> {
      let Mutation;
      let message;
      let input: SetScheduledTransferRuleInput | ArchiveTransferRuleInput;

      let refetch;
      if (typeof action.payload === 'string') {
        // Archive rule
        Mutation = ArchiveTransferRuleDocument;
        message = 'Schedule deleted.';
        input = {
          transferRuleId: action.payload,
        };
        refetch = [
          {
            query: TransfersRulesPageDocument,
          },
        ];
      } else {
        // Edit rule
        Mutation = SetScheduledTransferRuleDocument;
        message = 'Schedule edited.';
        input = {
          amount: action.payload.amount,
          fromParticipantId: action.payload.fromParticipantId,
          schedule: action.payload.schedule,
          toParticipantId: action.payload.toParticipantId,
          iraContributionYear: action.payload.iraContributionYear,
          iraDistributionReason: action.payload.iraDistributionReason,
          scheduledTransferRuleId: action.payload.scheduledTransferRuleId,
        };
        refetch = [
          {
            query: SetScheduledTransferRuleRefetchDocument,
          },
        ];
      }

      try {
        yield put(showLoadingSpinner());
        yield call(apolloMutationSaga, {
          mutation: Mutation,
          variables: {
            input,
          },
          refetchQueries: refetch,
          awaitRefetchQueries: true,
        });
        yield put({
          payload: {
            content: message,
            kind: 'success',
          },
          type: 'ADD_TOAST',
        });
        yield put(navigate({ to: '/d/transfers/rules' }));
      } catch (e: any) {
        message = e.message;

        yield put({
          payload: {
            content: message,
            kind: 'alert',
          },
          type: 'ADD_TOAST',
        });
      } finally {
        yield put(hideLoadingSpinner());
      }
    },
  );
}
