import { Flex, PM, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { TruncateStringContent } from '~/components/TruncateStringContent';

import { Input } from '~/toolbox/Input';

const EditIcon = styled(Icon)``;
const RenameContainer = styled(Flex)`
  ${EditIcon} {
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    ${EditIcon} {
      opacity: 1;
    }
  }
`;

type RenambeablePieCellProps = {
  pieName: string;
  isActive: boolean;
  onRename?: (newName: string) => void;
  showEditMode: boolean;
  setShowEditMode: (value: boolean) => void;
  childrenCount: number;
};

export const RenameablePieCell = ({
  pieName,
  onRename,
  showEditMode,
  setShowEditMode,
  isActive,
  childrenCount,
}: RenambeablePieCellProps) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [newName, setNewName] = React.useState(pieName);

  React.useEffect(() => {
    if (!isActive) {
      setNewName(pieName);
    }

    function handleExternalClick(event: MouseEvent) {
      const eventTarget = event.target as HTMLElement;

      const isChildTarget =
        wrapperRef.current?.contains?.(eventTarget) ?? false;

      if (isActive && wrapperRef.current && !isChildTarget) {
        setShowEditMode(false);
        if (validateInput()) {
          onRename?.(newName);
        }
      }
    }

    document.addEventListener('mousedown', handleExternalClick);

    return () => {
      document.removeEventListener('mousedown', handleExternalClick);
    };
  }, [pieName, isActive, newName]);

  // Set focus to the input when the user clicks the "Edit" button:
  React.useEffect(() => {
    if (showEditMode) {
      inputRef.current?.focus();
    }
  }, [showEditMode, inputRef]);

  const validateInput = () =>
    showEditMode &&
    Boolean(newName) &&
    newName !== pieName &&
    newName.length <= 50;

  const handleClick = () => {
    if (validateInput()) {
      onRename?.(newName);
    }

    setShowEditMode(!showEditMode);
  };

  return (
    <Flex ref={wrapperRef} alignItems="center" overflow="hidden">
      <RenameContainer
        alignItems="center"
        justifyContent="space-between"
        overflow="hidden"
      >
        {showEditMode ? (
          <Flex height={40} flex="1 1 auto" width="42ch">
            <Input
              ref={inputRef}
              kind="basic"
              size="medium"
              value={newName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setNewName(event.currentTarget.value)
              }
              placeholder={pieName}
            />
          </Flex>
        ) : (
          <Flex
            alignItems="center"
            flex="1 1 auto"
            onClick={handleClick}
            overflow="hidden"
          >
            <TruncateStringContent style={{ maxWidth: 330 }}>
              <PM
                fontWeight={600}
                whiteSpace="no-wrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {newName || pieName}
                {childrenCount > 0 ? ` · ${childrenCount}` : ''}
                <EditIcon
                  pl={8}
                  cursor="pointer"
                  name="edit24"
                  color="foregroundNeutralMain"
                  position="absolute"
                />
              </PM>
            </TruncateStringContent>
          </Flex>
        )}
      </RenameContainer>
    </Flex>
  );
};
