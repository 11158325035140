import { Flex, styled, Card } from '@m1/liquid-react';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { BackArrow } from '~/components/BackArrow';
import { PortfolioEditorConfirmationDialog } from '~/components/pie/ConfirmationDialog/PortfolioEditorConfirmationDialog';
import { ConnectedPie } from '~/components/pie/ConnectedPie';
import { SliceListAllocationFeedbackBanner } from '~/components/PieEditors/PortfolioEditor/components/Banners';
import { EditSlicesButtonGroup } from '~/components/PieEditors/PortfolioEditor/components/Buttons';
import { EditorHeader } from '~/components/PieEditors/PortfolioEditor/components/EditorHeader';
import { PortfolioNameAndDescriptionForm } from '~/components/PieEditors/PortfolioEditor/components/Forms';
import {
  StyledPieDetailsContainer,
  StyledSliceablesContainer,
} from '~/components/PieEditors/PortfolioEditor/components/Layout';
import { EditorExitConfirmationModal } from '~/components/PieEditors/PortfolioEditor/components/Modals';
import { PieControls } from '~/components/PieEditors/PortfolioEditor/components/PieControls';
import {
  SliceableListNoSlicesCard,
  SliceablesList,
} from '~/components/PieEditors/PortfolioEditor/components/SliceablesList';
import { useEditPortfolioStepQuery } from '~/graphql/hooks';
import { useLayout } from '~/hooks/useLayout';
import { useNavigate } from '~/hooks/useNavigate';
import { hasChanges, readPieTreeByPath } from '~/pie-trees';
import { savePortfolioEditor } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';

type EditPortfolioStepProps = {
  onFinishStep: (arg0: { goBack?: boolean }) => void;
};

const EditorContainer = styled(Flex)`
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  width: 100vw;
`;

export const EditPortfolioStep = ({ onFinishStep }: EditPortfolioStepProps) => {
  const { addToast } = useToast();
  const { contentWidth } = useLayout();
  const navigate = useNavigate();

  const activeInvestAccountId = useSelector(
    (state) => state.global.activeAccountId,
  );

  const portfolioSliceIdsToMove: Array<string> = [];

  const { data, loading } = useEditPortfolioStepQuery({
    variables: {
      activeAccountId: activeInvestAccountId as string,
      portfolioSliceIdsToMove,
    },
    skip: !activeInvestAccountId,
  });

  const dispatch = useDispatch();

  const isLessThan1400: boolean = useMediaQuery({
    query: '(max-width: 1400px)',
  });

  const isLessThan1000: boolean = useMediaQuery({
    query: '(max-width: 1220px)',
  });

  const [isExitModalOpen, setIsExitModalOpen] = React.useState<boolean>(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState<boolean>(false);

  const { pie, pieBeforeUpdates, rootPie, sourcePiePortfolioSliceId } =
    useSelector((state) => {
      const {
        path,
        sourcePie,
        sourcePieBeforeUpdates,
        sourcePiePortfolioSliceId,
      } = state.newFlows.PORTFOLIO_EDITOR;

      return {
        pie: readPieTreeByPath(sourcePie, path),
        pieBeforeUpdates: sourcePieBeforeUpdates,
        rootPie: sourcePie,
        sourcePiePortfolioSliceId,
      };
    });
  const onContinue = React.useCallback(() => {
    dispatch(savePortfolioEditor(activeInvestAccountId));
  }, [activeInvestAccountId, dispatch]);

  const moveSlices =
    data?.node?.__typename === 'Account' ? data.node.moveSlices : null;

  if (loading) {
    return null;
  }

  if (!activeInvestAccountId) {
    addToast({
      content:
        'Something went wrong. Please try again or contact us for further assistance.',
      duration: 'short',
      kind: 'alert',
    });
  }

  const pieHasChanges = hasChanges(pieBeforeUpdates, rootPie);

  const title = pie.name ? `Edit ${pie.name}` : 'Edit your Pie';

  // <Pie /> requires data in a particular format.
  const pieData = pie.slices?.map((slice) => ({
    id: slice.to.__id,
    percentage: slice.percentage,
  }));
  return (
    <EditorContainer>
      <EditorHeader
        backButton={
          <BackArrow
            content="Back"
            onClick={() => {
              onFinishStep({
                goBack: true,
              });
            }}
          />
        }
        onCancel={() => {
          setIsExitModalOpen(true);
        }}
        onContinue={() => {
          setConfirmationDialogOpen(true);
        }}
        pieHasChanges={pieHasChanges}
        rootPie={rootPie}
        title={title}
      />

      <Flex
        flexDirection={isLessThan1000 ? 'column' : 'row'}
        justifyContent="space-between"
        maxWidth={contentWidth}
        mt={0}
        mx="auto"
        width="100%"
        padding="32px 80px 0px 80px"
      >
        <StyledPieDetailsContainer isLessThan1000={isLessThan1000}>
          <PortfolioNameAndDescriptionForm
            name={pie.name}
            description={pie.description}
          />

          <Card backgroundColor="backgroundNeutralSecondary" mt={24}>
            {pieData && (
              <Flex justifyContent="center">
                <ConnectedPie
                  data={pieData}
                  height={380}
                  innerRadius={100}
                  width={330}
                />
              </Flex>
            )}
          </Card>
        </StyledPieDetailsContainer>
        <StyledSliceablesContainer isLessThan1000={isLessThan1000}>
          <Flex
            alignItems="space-between"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
            pb={24}
            width="100%"
          >
            <PieControls from="/d/invest/portfolio-editor" />
            <Flex flexDirection="row" mt={isLessThan1000 ? 32 : 0}>
              <EditSlicesButtonGroup
                moveSlices={moveSlices}
                pie={pie}
                user={data?.viewer.user}
              />
            </Flex>
          </Flex>

          {pie.slices?.length ? (
            <>
              <SliceListAllocationFeedbackBanner mb={24} slices={pie.slices} />
              <SliceablesList allowZeroPercentSlices pie={pie} />
            </>
          ) : (
            <Flex alignItems="center">
              <SliceableListNoSlicesCard />
            </Flex>
          )}
        </StyledSliceablesContainer>
      </Flex>

      {confirmationDialogOpen && (
        <PortfolioEditorConfirmationDialog
          active={confirmationDialogOpen}
          onCancel={() => setConfirmationDialogOpen(false)}
          onConfirm={() => {
            setConfirmationDialogOpen(false);
            onContinue();
          }}
          showApplicableLocations
        />
      )}

      <EditorExitConfirmationModal
        onCancel={() => {
          setIsExitModalOpen(false);
        }}
        onConfirm={() => {
          setIsExitModalOpen(false);

          navigate({
            to: '/d/invest/portfolio/:sourcePiePortfolioSliceId',
            params: { sourcePiePortfolioSliceId },
          });
        }}
        open={isExitModalOpen}
      />
    </EditorContainer>
  );
};
