import { SagaIterator } from 'redux-saga';
import { fork, put } from 'redux-saga/effects';

import { navigate } from '~/redux/actions';
import { WAITLIST_STEPS as STEPS } from '~/static-constants';

import { makeFlowFuncs } from '../utils';

const { takeFlow, takeFlowStep } = makeFlowFuncs('WAITLIST');

export function* waitlistSaga(): SagaIterator<void> {
  yield fork(takeFlow, beginWaitlistFlow);
}

function* beginWaitlistFlow(): SagaIterator<void> {
  yield fork(
    takeFlowStep,
    STEPS.WAITLIST_CONFIRMATION,
    finishedWaitlistConfirmation,
  );
}

function* finishedWaitlistConfirmation() {
  yield put(navigate({ to: '/d/invest' }));
}
