import { Box, Flex, PS, styled, useTheme } from '@m1/liquid-react';
import debounce from 'lodash-es/debounce';
import * as React from 'react';

import { ChartableSliceChart } from '~/components/ChartableSliceChart/ChartableSliceChart';
import { PercentGain } from '~/components/percent-gain';
import { usePortfolioChartPerformanceLazyQuery } from '~/graphql/hooks';
import {
  PortfolioSlicePerformancePeriodEnum,
  RootPortfolioSlice,
} from '~/graphql/types';
import { EXTERNAL_LINKS } from '~/static-constants';
import { Link } from '~/toolbox/link';
import { CashFormatter } from '~/utils';

import {
  PortfolioDetailsContext,
  PortfolioDetailsContextType,
} from '../PortfolioDetailsContext';

export type PortfolioSliceValueHistoryChartProps = {
  height?: number;
  portfolioSlice: PortfolioDetailsContextType['portfolioSlice'];
};

const StatisticsTable = styled(Flex)`
  justify-content: space-between;
  gap: 24px;
`;

const StatisticContainer = styled(Flex)`
  flex-direction: column;
  flex: 1 0 0;
`;

const StatisticDivider = styled(Box)`
  margin: 12px 0;
  border: 1px solid ${({ theme }) => theme.colors.borderMain};
`;

const ChartAndStatistics = styled(Box)`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 32px;
`;

const LabeledStatistic = ({
  label,
  value,
}: {
  label: React.ReactNode | string;
  value: React.ReactNode | string;
}) => (
  <Box>
    <PS fontWeight={400} mb={4}>
      {label}
    </PS>
    <Box font="PM" fontWeight={600} css={{ display: 'flex' }}>
      {value}
    </Box>
  </Box>
);

export const PortfolioSliceValueHistoryChart = ({
  height = 280,
  portfolioSlice,
}: PortfolioSliceValueHistoryChartProps) => {
  const sliceContext = React.useContext(PortfolioDetailsContext);
  const [fetchPerformanceStats, { data }] =
    usePortfolioChartPerformanceLazyQuery();
  const chartContainerRef = React.useRef(null);
  const theme = useTheme();

  const startDate = sliceContext.chartRange?.chartStartDate.format('ll');
  const startAmount = sliceContext.chartRange?.start.y ?? 0;
  const endDate = sliceContext.chartRange?.chartEndDate.format('ll');
  const endAmount = sliceContext.chartRange?.end.y ?? 0;

  const MWRR = (data?.node as RootPortfolioSlice)?.performance
    ?.moneyWeightedRateOfReturn;

  const dividendsEarned = sliceContext.chartRange?.sumDividends;
  const netCashFlow = sliceContext.chartRange?.netCashFlow;
  const marketGain = endAmount - startAmount - (netCashFlow ?? 0);

  const updatePerformanceMetrics = React.useCallback(
    debounce(
      (portfolioId: string, period: PortfolioSlicePerformancePeriodEnum) =>
        fetchPerformanceStats({
          variables: {
            portfolioId,
            period,
          },
        }),
      500,
      { trailing: true, maxWait: 1000 },
    ),
    [],
  );

  React.useEffect(() => {
    if (sliceContext.chartPeriod) {
      updatePerformanceMetrics(portfolioSlice.id, sliceContext.chartPeriod);
    }
  }, [fetchPerformanceStats, portfolioSlice.id, sliceContext.chartPeriod]);

  return (
    <Flex flexDirection="column" width="100%">
      <ChartAndStatistics ref={chartContainerRef} minHeight={height}>
        <ChartableSliceChart
          chartableSliceIds={[portfolioSlice.id]}
          chartName="invest_portfolio"
          features={{}}
          periods={['1d', '1w', '1M', '3M', 'ytd', '1y', 'all']}
          onChangePeriod={sliceContext.onChangePeriod}
          onRangeSelectionChange={sliceContext.onChangeChartRange}
        />
        <StatisticsTable>
          <StatisticContainer>
            <LabeledStatistic
              label={`Starting value: ${startDate ?? '--'}`}
              value={CashFormatter.format(startAmount)}
            />
            <StatisticDivider />
            <LabeledStatistic
              label="Dividends earned"
              value={
                typeof dividendsEarned === 'number'
                  ? CashFormatter.format(dividendsEarned)
                  : '$--'
              }
            />
          </StatisticContainer>
          <StatisticContainer>
            <LabeledStatistic
              label={`Ending value: ${endDate ?? '--'}`}
              value={CashFormatter.format(endAmount)}
            />
            <StatisticDivider />
            <LabeledStatistic
              label="Market gain"
              value={
                typeof marketGain === 'number'
                  ? CashFormatter.format(marketGain)
                  : '$--'
              }
            />
          </StatisticContainer>
          <StatisticContainer>
            <LabeledStatistic
              label={
                <Link to="/d/invest/activity" font="PS">
                  Net cash flow
                </Link>
              }
              value={
                typeof netCashFlow === 'number'
                  ? CashFormatter.format(netCashFlow)
                  : '$--'
              }
            />
            <StatisticDivider />
            <LabeledStatistic
              label="Money weighted rate of return"
              value={<PercentGain value={MWRR} />}
            />
          </StatisticContainer>
        </StatisticsTable>
        <Link
          mt={16}
          to={EXTERNAL_LINKS.FAQ_BACKTESTING}
          target="_blank"
          title="Understanding performance information"
        >
          Understanding performance information
        </Link>
      </ChartAndStatistics>
      <hr style={{ borderBottom: `1px solid ${theme.colors.borderMain}` }} />
    </Flex>
  );
};
