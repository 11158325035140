import * as React from 'react';
import { Route } from 'react-router';

import { InvestBankConnectionPage } from '~/pages/dashboard/bank-connection/InvestBankConnectionPage';
import { createRouteComponent } from '~/router';

export const InvestBankConnectionRoute = () => {
  return (
    <Route
      path="bank-connection"
      component={createRouteComponent(InvestBankConnectionPage)}
    />
  );
};
