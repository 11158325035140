import { useSelector } from '~/redux/hooks';

/**
 * Given the current url broken up into parts, this function calculates the active nav,
 * returning null if it can't determine the active L1 nav group.
 * */
const getActiveL1Nav = (
  pathParts: Array<string>,
): string | null | undefined => {
  if (pathParts.length < 2) {
    return null;
  }

  const investExceptions = ['portfolio-organizer', 'portfolio-editor'];
  const pathname = pathParts[1];
  switch (pathname) {
    case 'invest':
      return investExceptions.includes(pathParts[2]) ? null : pathname;
    case 'home':
    case 'save':
    case 'spend':
    case 'research':
    case 'borrow':
    case 'settings':
    case 'transfers':
      return pathname;
    case 'open-invest-account':
      return 'invest';
    case 'acat-wizard':
      return 'invest';
    default:
      return null;
  }
};

const getActiveL2ReducerKey = (
  activeL1Nav: string | null | undefined,
  activeL3Nav: string | null | undefined,
): string | null | undefined => {
  switch (activeL1Nav) {
    case 'home':
      return null;
    case 'invest':
      return 'investAccountId';
    case 'spend':
      if (
        activeL3Nav === 'credit' ||
        activeL3Nav?.startsWith('spend-credit') === true
      ) {
        return 'creditAccountId';
      } else if (activeL3Nav === 'checking') {
        return 'checkingAccountId';
      }
      return null;
    case 'research':
      return null;
    case 'borrow':
      if (
        activeL3Nav === 'margin' ||
        activeL3Nav?.startsWith('borrow-margin') === true
      ) {
        return 'borrowAccountId';
      } else if (activeL3Nav?.startsWith('borrow-personal') === true) {
        return 'personalLoanAccountId';
      }
      return null;
    case 'save':
      if (activeL3Nav?.startsWith('save-checking') === true) {
        return 'checkingAccountId';
      } else if (activeL3Nav?.startsWith('save-savings') === true) {
        return 'savingsAccountId';
      }
      return null;
    default:
      return null;
  }
};

const getActiveL3Nav = (
  pathParts: Array<string>,
): string | null | undefined => {
  if (pathParts.length < 2) {
    return null;
  }

  const part1 = pathParts[1];

  if (part1 === 'borrow' && pathParts.length > 3) {
    if (pathParts[2] === 'margin') {
      return `borrow-margin-${pathParts[3]}`;
    }
    if (pathParts[2] === 'personal') {
      return `borrow-personal-${pathParts[3]}`;
    }
    return pathParts[3];
  }

  if (part1 === 'open-invest-account') {
    return 'open-invest-account';
  }

  if (part1 === 'acat-wizard') {
    return 'acat-wizard';
  }

  if (part1 === 'spend' && pathParts[2] === 'credit' && pathParts.length > 3) {
    return `spend-credit-${pathParts[3]}`;
  }

  if (part1 === 'save' && pathParts[2] === 'checking' && pathParts.length > 3) {
    return `save-checking-${pathParts[3]}`;
  }

  if (part1 === 'save' && pathParts[2] === 'savings' && pathParts.length > 3) {
    return `save-savings-${pathParts[3]}`;
  }

  return pathParts[2];
};
/**
 * L1 - The various product domains/modules. Ex: Invest, Spend, Borrow, Research etc.
 * L2 - The specific account or sub domain item. Ex: "My Portfolio", or "My Margin Loan"
 * L3 - The sub routes of the active L1 domain. Ex: portfolio, activity, bank connection for invest etc.
 */
export type DashboardNavigation = {
  activeL1Nav: string | null | undefined;
  activeL2Nav: string | null | undefined;
  activeL3Nav: string | null | undefined;
};

/**
 * A hook which returns the active L1, L2 and L3 nav items.
 * @returns {DashboardNavigation} - Returns an object with the active L1, L2 and L3 navs.
 */
export const useDashboardNavigation = (): DashboardNavigation => {
  const currentPathname = useSelector<string | null | undefined>(
    (state) => state.routing.locationBeforeTransitions?.pathname,
  );

  const pathParts = currentPathname ? currentPathname.split('/') : [];
  const activeL1Nav = getActiveL1Nav(pathParts);
  const activeL3Nav = getActiveL3Nav(pathParts);

  const activeL2ReducerKey = getActiveL2ReducerKey(activeL1Nav, activeL3Nav);
  const activeL2Nav = useSelector((state) =>
    activeL2ReducerKey
      ? (state.global[
          activeL2ReducerKey as keyof typeof state.global
        ] as string)
      : null,
  );

  return {
    activeL1Nav,
    activeL2Nav,
    activeL3Nav,
  };
};
