import * as React from 'react';
import { Route } from 'react-router';

import { SecurityDetailsLayout } from '~/pages/dashboard/research/SecurityDetailsLayout';

import { createRouteComponent } from '~/router';

export const AddCryptoDetailsRoute = () => {
  return (
    <Route
      path="details/:cryptoId"
      component={createRouteComponent(SecurityDetailsLayout, (props) => ({
        disableWatchlist: true,
        securityId: props.params.cryptoId,
        isCover: true,
      }))}
    />
  );
};
