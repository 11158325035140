import * as React from 'react';
import { IndexRedirect, Route } from 'react-router';

import { FullyPaidLendingSettingsPage } from './FullyPaidLendingSettingsPage/FullyPaidLendingSettingsPage';
import { InvestSettingsLayout } from './InvestSettingsLayout';

export const InvestSettingsRoute = () => {
  return (
    <Route path="invest" component={InvestSettingsLayout}>
      <IndexRedirect to="fully-paid-lending" />
      <Route
        path="fully-paid-lending"
        component={FullyPaidLendingSettingsPage}
      />
    </Route>
  );
};
