import { Box, Flex, HM, PS, PXL, theme, styled } from '@m1/liquid-react';
import * as React from 'react';
import { Control, FormState } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';

import { TextField } from '~/components/form/TextField';
import { emailValidator, passwordValidator } from '~/forms/validators';
import { useSharePieLogInBannerQuery } from '~/graphql/hooks';
import { AppButton } from '~/lens-toolbox/AppButton';
import { AppImage } from '~/lens-toolbox/AppImage';
import { RichText } from '~/lens-toolbox/RichText/RichText';

import { FIELD_MAX_LENGTHS } from '~/static-constants';

type SharePieUserCTASectionProps = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onLoginClick: () => void;
  control: Control<{ username: string; password: string }>;
  errors: FormState<{ username: string; password: string }>['errors'];
};

const PrimaryContentContainer = styled(Box)`
  width: calc(100% - 400px);

  @media (max-width: ${theme.breakpoints.SMALL}) {
    width: 100%;
  }
`;

const PhoneIllustrationContainer = styled(Flex)`
  align-items: end;
  overflow: hidden;
  position: relative;

  @media (max-width: ${theme.breakpoints.SMALL}) {
    display: none;
  }
`;

const StyledButtonSection = styled(Flex)`
  flex-direction: column;
`;

export const SharePieUserCTASection = ({
  onSubmit,
  onLoginClick,
  control,
  errors,
}: SharePieUserCTASectionProps) => {
  const { data, loading } = useSharePieLogInBannerQuery();
  const banner = data?.viewer?.unauthenticated?.sharePieLandingPage.logInBanner;

  const isMobile = useMediaQuery({
    query: `(max-width:${theme.breakpoints.SMALL}`,
  });

  if (loading || !banner) {
    return null;
  }

  return (
    <Flex
      minHeight={420}
      maxWidth={1200}
      mx="auto"
      justifyContent="center"
      padding={`56px ${isMobile ? 16 : 32}px 0`}
      gap={80}
      css={{ transform: 'scaleY(-1)' }}
      flexWrap="wrap"
    >
      <PrimaryContentContainer mb={16}>
        <HM color="foregroundNeutralOnDark" fontWeight={300} mb={20}>
          {banner.title}
        </HM>
        <PXL color="foregroundNeutralOnDark">{banner.description}</PXL>
        <form onSubmit={onSubmit} style={{ width: '100%' }}>
          <Flex width="100%" flexWrap="wrap">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              gap={20}
              mt={32}
              mb={8}
              width="100%"
              flexWrap="wrap"
            >
              <Box flexGrow={1}>
                <TextField
                  control={control}
                  name="username"
                  rules={{ validate: emailValidator }}
                  backgroundColor="backgroundNeutralMain"
                  placeholder="Email"
                  maxLength={FIELD_MAX_LENGTHS.USERNAME}
                />
                <PS color="foregroundCriticalOnFeature">
                  <span>{errors?.username?.message}</span>
                </PS>
              </Box>
              <Box flexGrow={1}>
                <TextField
                  control={control}
                  name="password"
                  type="password"
                  backgroundColor="backgroundNeutralMain"
                  placeholder="Password"
                  rules={{
                    validate: passwordValidator,
                  }}
                />
                <PS color="foregroundCriticalOnFeature">
                  <span>{errors?.password?.message}</span>
                </PS>
              </Box>
              {!isMobile && (
                <Flex flexGrow={0} gap={16} alignItems="center">
                  {banner.primaryCTA && (
                    <AppButton type="submit" appButton={banner.primaryCTA} />
                  )}
                  {banner.logInCTA && (
                    <AppButton
                      onClick={onLoginClick}
                      kind="text"
                      appButton={banner.logInCTA}
                      style={{ padding: '4px' }}
                    />
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>
          <PS color="foregroundNeutralTertiary" fontStyle="italic" mb={24}>
            Password must be 10 character minimum; include one lowercase, one
            uppercase, and one number.
          </PS>
          {isMobile && (
            <StyledButtonSection>
              {banner.primaryCTA && (
                <AppButton type="submit" appButton={banner.primaryCTA} />
              )}
              {banner.logInCTA && (
                <AppButton
                  onClick={onLoginClick}
                  kind="text"
                  appButton={banner.logInCTA}
                />
              )}
            </StyledButtonSection>
          )}
          <Flex pb={16}>
            {banner?.createAccountDisclosure?.map((disclosure, idx) => (
              <RichText
                key={idx}
                richText={disclosure}
                containerProps={{ display: 'block' }}
                linkProps={{
                  textDecoration: 'underline',
                  font: 'PS',
                }}
              />
            ))}
          </Flex>
        </form>
      </PrimaryContentContainer>
      {banner.illustration && (
        <PhoneIllustrationContainer>
          <AppImage appImage={banner.illustration} display="flex" />
        </PhoneIllustrationContainer>
      )}
    </Flex>
  );
};
