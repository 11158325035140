import { Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import { UpdatePieTreeMutationResult } from '~/graphql/hooks';

import { MoveDrawerRowFragment } from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { moveUnmanagedHoldings } from '~/redux/actions';
import { PieOrganizerConfig } from '~/redux/actions/pieOrganizer/pieOrganizerConfig.types';
import { useDispatch } from '~/redux/hooks';
import { Link } from '~/toolbox/link';

const StyledDestinationRow = styled(Box)`
  // this styled component gets rendered as an anchor so box props won't work here
  align-items: center;
  display: flex;
  padding: 16px;
  width: 100%;

  transition: 200ms background-color linear;

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundNeutralTertiary};
  }
`;

type MoveFlowProps = {
  destinationId: string;
  parentPortfolioSliceId: string;
  type: 'MOVE_FLOW';
};

type CreateAndMoveFlowProps = {
  type: 'CREATE_AND_MOVE_FLOW';
};

type MoveDrawerRowProps = {
  children: React.ReactNode;
  flow: MoveFlowProps | CreateAndMoveFlowProps;
  moveSecuritiesConfirmTargets: MoveDrawerRowFragment;
  selectedCheckboxes: Array<string>;
};

export const MoveDrawerRow = ({
  children,
  flow,
  moveSecuritiesConfirmTargets,
  selectedCheckboxes,
}: MoveDrawerRowProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const moveOnConfirm = React.useCallback(
    (parentPortfolioSliceId: string) => {
      dispatch(
        moveUnmanagedHoldings({
          parentPortfolioSliceId: parentPortfolioSliceId,
          unmanagedHoldingIds: selectedCheckboxes,
        }),
      );
    },
    [dispatch, selectedCheckboxes],
  );

  const createAndMoveOnConfirm = React.useCallback(
    (pie: any, config: PieOrganizerConfig) => {
      navigate({
        to: '/d/invest/select-parent-pie',
        options: { state: { pie, previousConfig: config } },
      });
    },
    [navigate],
  );

  return (
    <StyledDestinationRow
      as={Link}
      to="/d/invest/portfolio-organizer"
      state={{
        event: {
          type: 'INITIALIZE_ORGANIZER',
          initConfig: {
            buttons: {
              backButton: {
                label: 'Holdings not in Pies',
                to: '/d/invest/unmanaged-holdings',
              },
              primary: moveSecuritiesConfirmTargets.saveTargetsCtaLabel,
            },
            confirmationDialog: {
              showApplicableLocations: false,
            },
            disabledFeatures: [
              'ADD_SLICES',
              'MOVE_SLICES',
              'REMOVE_SLICES',
              'CREATE_PIE',
            ],
            mode: flow.type === 'MOVE_FLOW' ? 'EDIT_PIE' : 'NEW_PIE',
            onConfirm: (
              config: PieOrganizerConfig,
              result: UpdatePieTreeMutationResult,
            ) =>
              flow.type === 'MOVE_FLOW'
                ? moveOnConfirm(flow.parentPortfolioSliceId)
                : createAndMoveOnConfirm(
                    result.data?.updatePieTree?.pie,
                    config,
                  ),
            pieId: flow.type === 'MOVE_FLOW' ? flow.destinationId : null,
            returnTo:
              flow.type === 'MOVE_FLOW' ? '/d/invest/unmanaged-holdings' : null,
            sliceableIds: selectedCheckboxes,
            subtitle: moveSecuritiesConfirmTargets.header,
            subtitleLink: moveSecuritiesConfirmTargets.link,
            title: moveSecuritiesConfirmTargets.title,
            unmanagedHoldingsToMove: selectedCheckboxes,
            zeroPercentDisabled: true,
          },
        },
      }}
    >
      {children}
    </StyledDestinationRow>
  );
};
