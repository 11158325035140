import * as React from 'react';
import { Route, IndexRedirect } from 'react-router';

import { requireAuth } from '~/router/auth-handlers';

import { SettingsAccountsRoute } from './accounts';
import { SettingsAppearanceRoute } from './appearance';
import { SettingsDocumentsRoute } from './documents';
import { FeedbackFormRoute } from './feedback';
import { InvestSettingsRoute } from './invest/InvestSettingsRoute';
import { SettingsNotificationsRoute } from './notifications';
import { SettingsPaymentsRoute } from './payments';
import { SettingsPlatformBillingRoute } from './platform-billing';
import { ProfileRoute } from './profile';
import { SettingsSecurityRoute } from './security';
import { SettingsPage } from './SettingsPage';

export const SettingsRoute = (routeHandlers: (...args: Array<any>) => any) => {
  return (
    <Route
      path="settings"
      component={SettingsPage}
      onEnter={routeHandlers(requireAuth)}
    >
      {SettingsAccountsRoute()}
      {SettingsPlatformBillingRoute()}
      {InvestSettingsRoute()}
      {SettingsDocumentsRoute()}
      {SettingsNotificationsRoute()}
      {SettingsPaymentsRoute()}
      {ProfileRoute()}
      {SettingsSecurityRoute()}
      {FeedbackFormRoute()}
      {SettingsAppearanceRoute()}
      <IndexRedirect to="profile" />
    </Route>
  );
};
