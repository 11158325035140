import * as React from 'react';

import { Navigation } from '~/toolbox/navigation';

import style from '../navStyles.module.scss';

const navigationLinks: Array<{
  to: string;
  label: string;
}> = [
  {
    to: '/d/settings/invest/fully-paid-lending',
    label: 'Fully Paid Securities Lending',
  },
];

export const InvestSettingsNavigation = () => (
  <Navigation
    orientation="vertical"
    items={navigationLinks.map(({ to, label }) => ({
      activeClassName: style.active,
      className: style.link,
      to,
      label,
    }))}
  />
);
