import { Color, Box, Flex } from '@m1/liquid-react';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import * as React from 'react';
import { InjectedRouteProps, RouterDefinition } from 'react-router';

import { UNSAFE_connectRedux } from '~/hocs';
import type { AppState } from '~/redux';
import { navigate } from '~/redux/actions';
import { TransitionGroup } from '~/toolbox/transition-group';

import { BackButton, CloseButton } from './components';

export type CoverProps = InjectedRouteProps & {
  children: React.ReactElement;
  closeButtonColor?: Color;
  dispatch: (...args: Array<any>) => any;
  lastVisited: string | null | undefined;
  location: Record<string, any>;
  // TODO: Update to actual navigate type def
  navigate: (...rest: any) => void;
  navigationState: NavigationState | null | undefined;
  routes: Array<Record<string, any>>;
  withBackButton: boolean;
  canClose: boolean; // if a modal is open, close the modal first
};

export type NavigationState = {
  forceRefetch: boolean;
};

export class CoverComponent extends React.Component<CoverProps> {
  declare context: React.ContextType<
    React.Context<{ router: RouterDefinition }>
  >;

  lastVisited: string | null | undefined;

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  static defaultProps = {
    lastVisited: null,
    withBackButton: false,
  };

  constructor(props: CoverProps) {
    super(props);

    this.lastVisited = props.lastVisited;

    this.handleBack = this.handleBack.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);

    window.setTimeout(() => {
      document.body.style.overflow = 'hidden';
    }, 400);
  }

  componentWillUnmount() {
    document.body.style.overflow = 'initial';
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  render() {
    return (
      <Box
        position="fixed"
        top={0}
        left={0}
        zIndex={300}
        width="100vw"
        height="100vh"
        overflowY="auto"
        backgroundColor="backgroundNeutralSecondary"
        color="foregroundNeutralMain"
      >
        <Box
          position="fixed"
          zIndex={200}
          top={0}
          left={0}
          right={0}
          backgroundColor="backgroundNeutralSecondary"
        >
          <TransitionGroup>
            {this.shouldShowBackButton() ? (
              <BackButton onClick={this.handleBack} />
            ) : null}
          </TransitionGroup>
          <CloseButton
            color={this.props.closeButtonColor ?? 'foregroundNeutralMain'}
            onClick={this.handleClose}
          />
        </Box>
        <Flex
          flexDirection="column"
          alignItems={this.shouldBeCentered() ? 'center' : 'unset'}
          justifyContent={this.shouldBeCentered() ? 'center' : 'unset'}
          minHeight="100%"
          backgroundColor="backgroundNeutralSecondary"
        >
          {this.props.children}
        </Flex>
      </Box>
    );
  }

  handleBack(): void {
    this.context.router.goBack();
  }

  handleClose(): void {
    // TODO: Roll this up into a saga?
    document.body.style.overflow = 'initial';
    const navState = this._findPropOnRoute('navigationState', {});

    const { previousRouteName } = this.props.location.query;
    const previousRoute = Array.isArray(previousRouteName)
      ? previousRouteName[0]
      : previousRouteName;

    return this.props.dispatch(
      navigate({
        to: previousRoute || this.lastVisited || this.getFallbackRoute(),
        options: isEmpty(navState) ? undefined : { state: navState },
      }),
    );
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape' && this.props.canClose) {
      this.handleClose();
    }
  }

  shouldBeCentered(): boolean {
    return this._findPropOnRoute('centered', false);
  }

  shouldShowBackButton(): boolean {
    return this._findPropOnRoute('backButton', false);
  }

  getFallbackRoute(): string {
    return this._findPropOnRoute('fallbackRoute', '/d/invest/portfolio');
  }

  _findPropOnRoute<T>(prop: string, fallback: T): T {
    if (this.props.hasOwnProperty(prop)) {
      return this.props[prop as keyof typeof this.props] as T;
    }

    for (let index = this.props.routes.length - 1; index >= 0; index--) {
      const route = this.props.routes[index];

      if (route.hasOwnProperty(prop)) {
        return route[prop];
      }
    }

    return fallback;
  }
}

const enhancer = UNSAFE_connectRedux(
  (state: AppState, ownProps: Partial<CoverProps>): Partial<CoverProps> => ({
    lastVisited:
      ownProps.lastVisited ||
      (state.routing.appHistory.length > 1
        ? state.routing.appHistory[1]
        : null),
    // if a modal is open, `canClose` is false
    canClose: Boolean(
      !Object.values(state.modals).find((modalState) => modalState.isOpened),
    ),
  }),
);

// @ts-expect-error - TS2345 - Argument of type 'typeof CoverComponent' is not assignable to parameter of type 'JSXElementConstructor<never>'.
export const Cover = enhancer(CoverComponent) as any;
