import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { ResponsiveButton } from '~/components/ResponsiveButton/ResponsiveButton';

import { PossibleOptions } from '~/toolbox/Dropdown';

type BankDepositTransferParticipantFormValues = {
  transferParticipantId: string;
};

type BankDepositTransferParticipantFormProps = {
  onSubmit: SubmitHandler<BankDepositTransferParticipantFormValues>;
  options: PossibleOptions;
  initialValue: { transferParticipantId?: string };
};

export const BankDepositTransferParticipantForm = ({
  onSubmit,
  options,
  initialValue,
}: BankDepositTransferParticipantFormProps) => {
  const { control, handleSubmit } =
    useForm<BankDepositTransferParticipantFormValues>({
      defaultValues: {
        transferParticipantId: initialValue?.transferParticipantId,
      },
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ControlledDropdown
        size="large"
        isSearchable={false}
        control={control}
        name="transferParticipantId"
        label="Deposit to"
        options={options}
        rules={{ required: 'Required' }}
      />
      <ResponsiveButton mt={48} type="submit" size="large" label="Continue" />
    </form>
  );
};
