import merge from 'lodash-es/merge';
import pick from 'lodash-es/pick';
import { LOCATION_CHANGE } from 'react-router-redux';

import { ACTION_TYPES as ACTIONS } from '~/redux/actions';

// TODO: move to static constants
import { STEPS } from '../../../../flows/components/personal-loans/application';

import type { FlowState } from '../newFlowsReducer.types';
import { createFlowReducer, createStepReducer } from '../utils';

export type PersonalLoansApplicationFlowState = FlowState<
  typeof STEPS,
  {
    annualIncome: number | null;
    applicationId: string;
    basePath: string;
    employmentStatus: null;
    loanAmount: number;
    loanId: string;
    loanPurpose: string;
    loanTerm: string;
    monthlyHousingCosts: number;
    offerId: string;
    personalLoanDepositAccount: string;
    termsAndConditionsSignature: string;
    transferParticipantId: string;
  }
>;

export const personalLoansApplicationInitialState: PersonalLoansApplicationFlowState =
  {
    basePath: 'd/borrow/personal/loan-application',
    step: '',
    stepTitle: '',
    annualIncome: null,
    applicationId: '',
    monthlyHousingCosts: 0,
    employmentStatus: null,
    loanAmount: 0,
    loanPurpose: '',
    loanTerm: '',
    transferParticipantId: '',
    termsAndConditionsSignature: '',
    personalLoanDepositAccount: '',
    offerId: '',
    loanId: '',
  };

const readStepTitle = (step: ValueOf<typeof STEPS>) => {
  switch (step) {
    case STEPS.LANDING_PAGE:
      return 'Delivery of disclosures';
    case STEPS.LOAN_INFORMATION:
      return 'Loan information';
    case STEPS.FINANCIAL_INFORMATION:
      return 'Financial information';
    case STEPS.FINANCIAL_REVIEW:
      return 'Confirm';
    case STEPS.LOAN_OFFERS_AND_SUBMIT:
      return 'Select offer';
    case STEPS.INCOME_VERIFICATION:
    case STEPS.MANUAL_REVIEW:
      return 'Verification needed';
    case STEPS.LOAN_TERMS_AND_ACCEPT:
      return 'Confirm and accept';
    case STEPS.AUTOPAY_ENROLLMENT:
      return 'AutoPay';
    case STEPS.LOAN_RECAP:
      return 'Review information';
    case STEPS.DEPOSIT_INFO:
      return 'Deposit information';
    default:
      return '';
  }
};

const stepReducer = createStepReducer(personalLoansApplicationInitialState);

const reducer = (
  state: PersonalLoansApplicationFlowState = personalLoansApplicationInitialState,
  action: any,
): PersonalLoansApplicationFlowState => {
  switch (action.type) {
    case ACTIONS.BEGIN_FLOW:
      return {
        ...state,
        ...pick(
          action.payload,
          Object.keys(personalLoansApplicationInitialState),
        ),
        step: personalLoansApplicationInitialState.step,
      };
    case LOCATION_CHANGE: {
      const step = stepReducer(state, action);
      return {
        ...state,
        step,
        stepTitle: readStepTitle(step),
      };
    }
    case ACTIONS.FINISHED_FLOW_STEP:
      return merge({}, state, action.payload);
    case ACTIONS.PERSONAL_LOANS_STORE_APPLICATION_ID:
      if (action?.payload?.applicationId) {
        return {
          ...state,
          applicationId: action.payload.applicationId,
        };
      }
      return state;
    case ACTIONS.PERSONAL_LOANS_STORE_LOAN_ID:
      if (action?.payload?.loanId) {
        return {
          ...state,
          loanId: action.payload.loanId,
        };
      }
      return state;
    case ACTIONS.SET_PERSONAL_LOAN_DEPOSIT_ACCOUNT:
      return {
        ...state,
        personalLoanDepositAccount: action.payload,
      };
    default:
      return state;
  }
};

export const personalLoansApplication = createFlowReducer(
  'PERSONAL_LOANS_APPLICATION',
  reducer,
);
