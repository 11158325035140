import { Button, Text } from '@m1/liquid-react';
import * as React from 'react';

import { useNavigate } from '~/hooks/useNavigate';

import { Grid } from '~/toolbox/grid';

export const ResetPasswordSuccessStep = () => {
  const navigate = useNavigate();

  return (
    <Grid constrain>
      <Grid.Row xsCenter>
        <Grid.Col xs={12} xsTextCenter>
          <div
            style={{
              padding: `16px 0 32px 0`,
            }}
          >
            <Text content="Your password has been reset." />
          </div>
          <Button
            label="Continue"
            onClick={() => {
              navigate({ to: '/d/home' });
            }}
            kind="primary"
            size="large"
          />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};
