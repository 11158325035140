import * as React from 'react';
import { Route } from 'react-router';

import { PieDetailsPage } from '~/components/pie/PieDetailsPage/PieDetailsPage';
import { createRouteComponent } from '~/router';

export const AddModelPortfolioDetailsRoute = () => {
  return (
    <Route
      path="details/:modelPortfolioId"
      component={createRouteComponent(PieDetailsPage, (props) => ({
        pieId: props.params.modelPortfolioId,
      }))}
    />
  );
};
