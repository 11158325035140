import * as React from 'react';
import { Route } from 'react-router';

import { PromotionCampaignAcceptancePage } from './PromotionCampaignAcceptancePage';

export const PromotionCampaignAcceptance = () => {
  return (
    <Route
      path="accept-offer"
      component={PromotionCampaignAcceptancePage}
      fallbackRoute="/d/home"
    />
  );
};
