import { LocationDescriptorObject } from 'history';
import { routerActions } from 'react-router-redux';

import { toValidRoutePath } from '~/router';
import { parseQuery } from '~/utils';

// TODO(RRU): Remove/refactor this during RR upgrade.
export interface LocationParams {
  query?: Record<string, string | boolean | null | undefined>;
  state?: Record<string, any>;
}

/**
 * Options for the `navigate` thunk, which match the `navigate` function from
 * React Router v6. Additional custom options have been added for compatibility
 * with our codebase, but should be removed at some point to match the RR v6
 * API.
 */
export interface NavigateOptions {
  /**
   * Specifying `replace: true` will cause the navigation to replace the current
   * entry in the history stack instead of adding a new one.
   */
  replace?: boolean;
  /**
   * Additional state to store in `history.state`, which can then be accessed
   * on the destination route via `useLocation`.
   */
  state?: any;
}

export interface NavigableRoute {
  to: string | number;
  params?: Record<string, string | undefined | null> | undefined | null;
  query?: Record<string, any> | undefined | null;
  options?: NavigateOptions;
}

type ThunkReturn = (...args: any[]) => any;

export type NavigateFunction = (route: NavigableRoute) => void;

export function navigate(route: NavigableRoute): ThunkReturn {
  const { to, query, params, options } = ensureValidRouteInput(route);

  if (typeof to === 'number') {
    return (dispatch) => {
      dispatch(routerActions.go(to as number));
    };
  }

  if (typeof to !== 'string') {
    throw new Error(`Invalid route.to: ${to}`);
  }

  const locationDescriptor: LocationDescriptorObject = {
    pathname: '',
    query,
    state: options?.state,
  };

  // If there's a search query already in the URL, make sure we parse it out
  // and include the entries with the existing `route.query` object:
  const [pathOnly, queryString] = to.split('?');

  if (queryString) {
    const parsedQueryObject = parseQuery(queryString);

    locationDescriptor.query = {
      ...query,
      ...parsedQueryObject,
    };
  }

  locationDescriptor.pathname = toValidRoutePath(pathOnly, params);

  return (dispatch) => {
    if (options?.replace) {
      dispatch(routerActions.replace(locationDescriptor));
    } else {
      dispatch(routerActions.push(locationDescriptor));
    }
  };
}

/**
 * If the route input being passed into `navigate` is using a `locationParams` entry,
 * extract the `locationParams` entries into the route input and show a console error.
 * Eventually, we can remove this check entirely, but we don't want to accidentally
 * break existing code that's passing in an object with `locationParams`.
 */
function ensureValidRouteInput(input: Record<string, any>): NavigableRoute {
  let query = input.query;
  const options = input.options ?? {};

  if ('locationParams' in input) {
    // Only log out the message/trace when running app locally:
    if (__DEBUG__) {
      const message = [
        'Use of `route.locationParams` found in the following stack trace.',
        'Replace `route.locationParams.query` with `route.query`,',
        'and `route.locationParams.state` with `route.options.state`.',
      ].join(' ');
      // eslint-disable-next-line no-console
      console.error(message);
      // eslint-disable-next-line no-console
      console.trace();
    }

    query = input.locationParams.query;

    const inputState = options.state ?? {};
    if ('state' in input.locationParams) {
      options.state = { ...inputState, ...input.locationParams.state };
    }
  }

  return { to: input.to, query, params: input.params, options };
}

export function goBack(): ThunkReturn {
  return (dispatch) => {
    dispatch(routerActions.goBack());
  };
}
