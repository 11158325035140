import { PXL, Box, Card } from '@m1/liquid-react';
import * as React from 'react';

import { isPieNameValid, isPieDescriptionValid } from '~/pie-trees';
import {
  updatePieDescriptionForPortfolioEditor,
  updatePieNameForPortfolioEditor,
} from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Input } from '~/toolbox/Input';

type PortfolioNameAndDescriptionFormProps = {
  description: string | null | undefined;
  name: string | null | undefined;
};

export const PortfolioNameAndDescriptionForm = ({
  description,
  name,
}: PortfolioNameAndDescriptionFormProps) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = React.useState(true);

  return (
    <Card backgroundColor="backgroundNeutralSecondary" p={16}>
      <PXL
        fontWeight={600}
        color="foregroundNeutralMain"
        content="Pie details"
      />

      <form name="portfolio-details-form">
        <Box>
          <Input
            error={
              !isPieNameValid(name, true)
                ? 'Maximum character limit is 80.'
                : undefined
            }
            label="Pie name"
            placeholder="Maximum character limit is 80"
            onChange={(e: React.SyntheticEvent<HTMLInputElement>): void => {
              if (!isEditing) {
                setIsEditing(true);
              }
              // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
              dispatch(updatePieNameForPortfolioEditor(e.target.value));
            }}
            value={name ?? ''}
          />
        </Box>
        <Box>
          <Input
            error={
              !isPieDescriptionValid(description)
                ? 'Maximum character limit is 1000.'
                : undefined
            }
            label="Description (optional)"
            placeholder="Maximum character limit is 1000"
            onChange={(e: React.SyntheticEvent<HTMLInputElement>): void => {
              if (!isEditing) {
                setIsEditing(true);
              }
              // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
              dispatch(updatePieDescriptionForPortfolioEditor(e.target.value));
            }}
            value={description ?? ''}
          />
        </Box>
      </form>
    </Card>
  );
};
