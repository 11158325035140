import { Button, Flex, Modal, styled } from '@m1/liquid-react';
import * as React from 'react';

import { SecurityDetailFragment } from '~/graphql/types';
import { useDynamicSizing } from '~/hooks/useDynamicSizing';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

import { SecurityHistoricalChart } from './SecurityHistoricalChart';
import { SecurityName } from './SecurityName';
import { SecurityCurrentQuote } from './SecurityQuote/SecurityCurrentQuoteContainer';

type SecurityChartModalProps = {
  modalOpen: boolean;
  security: SecurityDetailFragment;
  setModalOpen: (value: boolean) => void;

  xDomain?: string;
};

export const StyledFlexContainer = styled(Flex)`
  width: 90vw;
  height: 90vh;
  background-color: var(--colors-background-neutral-secondary);
  flex-direction: column;
  @media screen and ${RESPONSIVE_BREAKPOINTS.MEDIUM} {
    width: 100vw;
  }

  button {
    align-self: flex-start;
  }
`;

export const SecurityChartModal = ({
  modalOpen,
  security,
  setModalOpen,
}: SecurityChartModalProps) => {
  const [{ height, width }, resizeRef] = useDynamicSizing({
    shouldResize: modalOpen,
  });

  return (
    <Modal
      open={modalOpen}
      closeIcon={null}
      onClose={() => setModalOpen(false)}
    >
      <StyledFlexContainer>
        <Flex
          width="100%"
          p={16}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Flex flexDirection="column">
            <SecurityName sliceable={security} />
            <SecurityCurrentQuote security={security} />
          </Flex>
          <Button
            kind="secondary"
            label="Exit"
            rightIcon="exitArrow20"
            size="medium"
            onClick={() => setModalOpen(false)}
          />
        </Flex>
        <Flex
          padding={10}
          height="90%"
          justifyContent="center"
          ref={resizeRef}
          overflowY="scroll"
        >
          {height && width && (
            <SecurityHistoricalChart
              securityId={security.id}
              chartHeight={height && height * 0.6}
              chartWidth={width && width * 0.9}
              isModal
            />
          )}
        </Flex>
      </StyledFlexContainer>
    </Modal>
  );
};
