import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { useInvestPageQuery } from '~/graphql/hooks';
import { InvestPageAccountNodeFragment } from '~/graphql/types';
import { DashboardNavigation } from '~/hooks/useDashboardNavigation';
import { useSelector } from '~/redux/hooks';

import { TopNavLink } from './TopNavLink';

export const TopNavInvest = ({
  selectedNavigation,
}: {
  selectedNavigation: DashboardNavigation;
}) => {
  const activeAccountId = useSelector((state) => state.global.activeAccountId);

  const { data, loading, error } = useInvestPageQuery({
    variables: {
      activeAccountId: activeAccountId as string,
    },
    skip: !activeAccountId,
  });

  const isEligibleForFundingSourceUpdate =
    data?.viewer?.transfers?.isEligibleForFundingSourceUpdate ?? false;

  const account = data?.account as InvestPageAccountNodeFragment;

  const allowsExternalFunding =
    Boolean(account?.allowsExternalFunding) && isEligibleForFundingSourceUpdate;
  // const isCryptoAccount = Boolean(account?.isCryptoAccount);
  const isCustodialAccount = Boolean(account?.isCustodialAccount);
  // const acatWizardEntryLink = viewer?.invest?.acatWizardEntryLink;
  const items = React.useMemo(() => {
    const navItems = [
      {
        id: 'portfolio',
        to: '/d/invest/portfolio',
        label: 'Portfolio',
      },
      {
        id: 'activity',
        to: '/d/invest/activity',
        label: 'Activity',
      },
      {
        id: 'holdings',
        to: '/d/invest/holdings',
        label: 'Holdings',
      },
      {
        id: 'funding-history',
        to: '/d/invest/funding-history',
        label: 'Funding history',
      },
    ];

    if (allowsExternalFunding) {
      /*
        `allowsExternalFunding` covers the following cases:
        1. If a user has not connected a FS to their invest account
        2. AND they do not have a savings account (or have a savings account that is closed).

        If they have a savings account (that is not CLOSED), they should connect their invest
        FS via the Setup Checklist, which is Lens-driven and provides the correct URL path.
      */
      navItems.push({
        id: 'bank-connection',
        to: '/d/invest/bank-connection',
        label: 'Bank connection',
      });
    }

    if (isCustodialAccount) {
      navItems.push({
        id: 'custodial-beneficiary',
        to: '/d/invest/custodial-beneficiary',
        label: 'Custodial beneficiary',
      });
    }

    // no top nav links when in the open account wizard
    return selectedNavigation.activeL3Nav === 'open-invest-account' ||
      selectedNavigation.activeL3Nav === 'invest-marketing'
      ? []
      : navItems;
  }, [allowsExternalFunding, isCustodialAccount, selectedNavigation]);

  return (
    <Flex flexDirection="row">
      {items.map((item) => {
        return (
          <TopNavLink
            selected={
              selectedNavigation.activeL1Nav === 'invest' &&
              selectedNavigation.activeL3Nav === item.id
            }
            key={item.to}
            label={item.label}
            linkTo={item.to}
          />
        );
      })}
    </Flex>
  );
};
