import { Button, PL } from '@m1/liquid-react';
import truncate from 'lodash-es/truncate';
import * as React from 'react';

import { SliceableLogo, SliceableSelect } from '~/components/pie';

import { Grid } from '~/toolbox/grid';

import { AddToPortfolioLinkButton } from './AddToPortfolioLinkButton';

import {
  StyledPieItem,
  StyledPieItemColumn,
  StyledPieItemDescription,
  StyledPieItemLogo,
  StyledPieItemNameAndDescription,
  StyledPieItemSelectBox,
} from './MyPie.styled';

import { PieItemSecondaryLabel } from './PieItemSecondaryLabel';
import { NonNullablePieItem } from './types';
import { useMyPiesContext } from './useMyPiesContext';

type PieListItemProps = {
  actions: React.ReactElement<any> | null | undefined;
  onClick: (pieId: string) => void;
  pie: NonNullablePieItem;
};

export const PieListItem = (props: PieListItemProps) => {
  const { mode } = useMyPiesContext();
  const { actions, onClick, pie } = props;
  const hasPortfolioLink =
    'portfolioLinks' in pie && Boolean(pie.portfolioLinks?.length);

  return (
    <StyledPieItem>
      <Grid.Row>
        <StyledPieItemColumn xs={4}>
          {mode === 'ADD' && (
            <StyledPieItemSelectBox>
              <SliceableSelect sliceable={pie} />
            </StyledPieItemSelectBox>
          )}
          <StyledPieItemLogo>
            <SliceableLogo size="32" sliceable={pie} />
          </StyledPieItemLogo>
          <StyledPieItemNameAndDescription>
            <PL fontWeight={600}>
              <Button
                kind="link"
                label={pie.name}
                onClick={(): void => onClick(pie.id)}
              />
            </PL>
            <PieItemSecondaryLabel pie={pie} />
          </StyledPieItemNameAndDescription>
        </StyledPieItemColumn>
        <StyledPieItemColumn xs={6}>
          {pie.description && (
            <StyledPieItemDescription>
              {truncate(pie.description, {
                length: 150,
              })}
            </StyledPieItemDescription>
          )}
        </StyledPieItemColumn>
        <StyledPieItemColumn
          style={{
            justifyContent: 'flex-end',
          }}
          xs={2}
          xsTextRight
        >
          {mode === 'DEFAULT' && !hasPortfolioLink && (
            <AddToPortfolioLinkButton pieId={pie.id} />
          )}
          {actions}
        </StyledPieItemColumn>
      </Grid.Row>
    </StyledPieItem>
  );
};
