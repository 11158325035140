import classNames from 'classnames';
import * as React from 'react';

import style from './style.module.scss';

type Props = {
  active: boolean;
  className?: string | null | undefined;
  disabled: boolean;
  label: any;
  onActive?: (...args: Array<any>) => any;
  onClick: (...args: Array<any>) => any;
  onClickParent?: (...args: Array<any>) => any;
};

export class LegacyTab extends React.Component<Props> {
  static defaultProps = {
    active: false,
    disabled: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.active && this.props.active && this.props.onActive) {
      this.props.onActive();
    }
  }

  render() {
    const {
      label,
      className,
      active,
      disabled,
      onClick,
      // eslint-disable-line no-unused-vars
      onClickParent, // eslint-disable-line no-unused-vars
    } = this.props;

    const classes = classNames(style.tab, className, {
      [style.active]: active,
      [style.disabled]: disabled,
    });

    return (
      <span className={classes} onClick={this.handleClick}>
        <label>{label}</label>
      </span>
    );
  }

  handleClick = (e: React.MouseEvent<HTMLElement>): void => {
    if (this.props.onClickParent) {
      this.props.onClickParent();
    }
    if (!this.props.disabled && this.props.onClick) {
      e.stopPropagation();
      this.props.onClick(e);
    }
  };
}
