import * as React from 'react';

import {
  useCreditCardPageQuery,
  useUpdateUserDataMutation,
} from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useLayout } from '~/hooks/useLayout';
import { useLocation } from '~/hooks/useLocation';
import { StyledPageContent } from '~/pages/dashboard/navigation/Navigation.styled';
import { Spinner } from '~/toolbox/spinner';

import { getCreditApplicationStatusEventContext } from './CreditCardPage.utils';

export const CreditCardPage = ({ children }: React.PropsWithChildren) => {
  const { pathname } = useLocation();
  const { contentWidth } = useLayout();
  const analytics = useAnalytics();

  const { data, loading } = useCreditCardPageQuery({
    variables: {
      keys: [
        'approvedCreditCardApplicationIds',
        'acceptedCreditCardApplicationIds',
      ],
    },
  });
  const [updateUserData, { loading: mutationLoading }] =
    useUpdateUserDataMutation();

  const user = data?.viewer.user;

  const credit = data?.viewer.credit;

  // check if we need to send any 'at most once' analytics events
  // do this on every spend tab load for eventual consistency.
  const eventContext = React.useMemo(
    () =>
      getCreditApplicationStatusEventContext(credit?.activeApplication?.status),
    [credit],
  );

  React.useEffect(() => {
    const userData = user ? user.data.map((e) => e.value) : [];

    if (user && userData && eventContext && credit?.activeApplication?.id) {
      // userData is a stringified array, so convert to actual array
      const userDataValue = userData[eventContext.userDataIndex] || '';
      const applicationIds = userDataValue.split(',');
      if (!applicationIds.includes(credit.activeApplication?.id)) {
        // send appropriate analytics event
        analytics.recordEvent(eventContext.eventName);
        // update user data with the new applicationId
        const userDataUpdates = [
          {
            key: eventContext.userDataKey,
            value: [credit.activeApplication?.id]
              .concat(applicationIds)
              .join(','),
          },
        ];

        updateUserData({ variables: { input: { entries: userDataUpdates } } });
      }
    }
  }, [
    analytics,
    credit?.activeApplication?.id,
    eventContext,
    updateUserData,
    user,
  ]);

  if (loading || mutationLoading) {
    return <Spinner />;
  }
  const shouldRenderNoWrapper =
    pathname.includes('marketing') ||
    pathname.includes('transactions') ||
    pathname.includes('report-transaction-issue');

  return shouldRenderNoWrapper ? (
    children
  ) : (
    <StyledPageContent contentWidth={contentWidth}>
      {children}
    </StyledPageContent>
  );
};
