import React from 'react';

import { SecurityDetailsPage } from './SecurityDetailsPage';
import { SecurityDetailsProvider } from './SecurityDetailsProvider';

type Props = {
  disableActions?: boolean;
  disableBreadcrumbs?: boolean;
  disableWatchlist?: boolean;
  securityId: string;
  isCover?: boolean;
};
export const SecurityDetailsLayout = (props: Props) => {
  return (
    <SecurityDetailsProvider {...props}>
      <SecurityDetailsPage isCover={props.isCover} />
    </SecurityDetailsProvider>
  );
};
