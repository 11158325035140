import { SagaIterator } from 'redux-saga';

import {
  ACTION_TYPES as ACTIONS,
  beginOnboarding,
  navigate,
} from '~/redux/actions';

import { put, take } from '../effects';

export function* signupMonitorSaga(): SagaIterator<void> {
  yield take(ACTIONS.FINISHED_REGISTER_USER_FLOW);
  yield put(navigate({ to: '/onboarding/phone-verification' }));
  yield put(beginOnboarding());
}
