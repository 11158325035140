import * as React from 'react';

import { GetTransferWizardPreloadQueryResult } from '~/graphql/hooks';
import { useLocation } from '~/hooks/useLocation';
import { CREATE_TRANSFER_FLOW_MODES } from '~/static-constants';

type TransferMode = keyof typeof CREATE_TRANSFER_FLOW_MODES;
export type CreateTransferContextType = {
  mode: TransferMode;
  fromParticipantIdQueryParam: string | null;
  toParticipantIdQueryParam: string | null;
  idempotencyKey: string | null | undefined;
} & GetTransferWizardPreloadQueryResult;

export const CreateTransferContext =
  React.createContext<CreateTransferContextType | null>(null);
export const CreateTransferProvider = ({
  children,
  data,
  idempotencyKey,
}: {
  children: React.ReactChild;
  data: GetTransferWizardPreloadQueryResult;
  idempotencyKey: string | null | undefined;
}) => {
  const location = useLocation();

  const mode = (location.query.mode ?? 'ONE_TIME') as TransferMode;
  const fromParticipantIdQueryParam = location.query.fromParticipantId ?? null;
  const toParticipantIdQueryParam = location.query.toParticipantId ?? null;

  return (
    <CreateTransferContext.Provider
      value={{
        mode,
        fromParticipantIdQueryParam,
        toParticipantIdQueryParam,
        idempotencyKey,
        ...data,
      }}
    >
      {children}
    </CreateTransferContext.Provider>
  );
};
