import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import { forwardIfAuth, RouteHandlers } from '~/router';

import { CollectUsernamePassword } from '../promo-signup/flow/CollectUsernamePassword';
import { PromotionSignUpOutcomePage } from '../promo-signup/flow/PromotionSignupOutcomePage';

import { ReferralsSignupPage } from './ReferralsSignupPage';
import { SignupPage } from './SignupPage';

export const SignupRoute = (routeHandlers: RouteHandlers) => {
  return (
    <Route path="signup" onEnter={routeHandlers(forwardIfAuth)}>
      {/* This route leads users to the invest refferal bonus */}
      <Route path="referrals" component={ReferralsSignupPage} />

      {/* This route leads to the promo signup page for new users with a promo code */}
      <Route path="promotion/:promoCode" component={CollectUsernamePassword}>
        <Route path="/outcome" component={PromotionSignUpOutcomePage} />
      </Route>

      {/* This route leads to the referral signup page for users with a referral code */}
      <Route path="referral/:promoCode" component={CollectUsernamePassword}>
        <Route path="/outcome" component={PromotionSignUpOutcomePage} />
      </Route>

      <IndexRoute component={SignupPage} />
    </Route>
  );
};
