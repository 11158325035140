import { Box, useTheme } from '@m1/liquid-react';
import * as React from 'react';

import type { DataPoint } from '../../charts/secondary-historical-chart/types';

import { SecurityVolumeBarsD3 } from './SecurityVolumeBarsD3';

type SecurityVolumeBarsProps = {
  data: Array<DataPoint>;
  intradayDateMinutes: Array<string> | null | undefined;
  width?: number;
};

export const SecurityVolumeBars = ({
  data,
  intradayDateMinutes,
  width, // this is dynamic and will change when the modal size does.
}: SecurityVolumeBarsProps) => {
  const barsRef = React.useRef(null);
  const d3Bars = React.useRef(null); // only interact with d3 object via ref.

  const [barsInitialized, setBarsInitialized] = React.useState(false);
  const [filteredData, setFilteredData] = React.useState([]);
  const [hasVolumeData, setHasVolumeData] = React.useState(null);
  const initialHeight = 150;
  const initialWidth = 780;
  const theme = useTheme();

  const initializeBars = React.useCallback(() => {
    // @ts-expect-error - TS2322 - Type 'SecurityVolumeBarsD3' is not assignable to type 'null'.
    d3Bars.current = new SecurityVolumeBarsD3({
      data: filteredData,
      bars: barsRef.current,
      height: initialHeight,
      intradayDateMinutes,
      width: width || initialWidth,
      theme,
    });
    setBarsInitialized(true);
  }, [width, filteredData, intradayDateMinutes, theme]);

  React.useEffect(() => {
    if (!barsInitialized && filteredData.length > 1) {
      initializeBars();
    }
    if (d3Bars.current && barsInitialized) {
      // @ts-expect-error - TS2339 - Property 'input' does not exist on type 'never'.
      d3Bars.current.input.data = filteredData;
      // @ts-expect-error - TS2339 - Property 'updateBars' does not exist on type 'never'.
      d3Bars.current.updateBars();
    }
  }, [barsInitialized, initializeBars, filteredData]);

  React.useEffect(() => {
    // @ts-expect-error - TS2345 - Argument of type 'boolean' is not assignable to parameter of type 'SetStateAction<null>'.
    setHasVolumeData(data.some((datum) => datum.shareVolume > 0));
    setFilteredData(
      // @ts-expect-error - TS2345 - Argument of type '{ date: string; shareVolume: number; previousQuoteClosePrice: number | null | undefined; value: number; }[]' is not assignable to parameter of type 'SetStateAction<never[]>'.
      data.map((datum) => {
        return {
          date: datum.date,
          shareVolume: datum.shareVolume,
          previousQuoteClosePrice: datum.previousQuoteClosePrice,
          value: datum.value,
        };
      }),
    );
  }, [data]);

  React.useEffect(() => {
    // we need to re-draw the bars when screen size changes.
    if (d3Bars.current) {
      // @ts-expect-error - TS2339 - Property 'removeBars' does not exist on type 'never'.
      d3Bars.current.removeBars();
      initializeBars();
    }
  }, [initializeBars, width]);
  return (
    hasVolumeData && (
      <Box>
        <svg ref={barsRef} height={initialHeight} width={width} />
      </Box>
    )
  );
};
