import * as React from 'react';
import { Route } from 'react-router';

import { AcatWizard } from './AcatWizard';

export const AcatWizardRoute = () => {
  return (
    <Route path="acat-wizard" fallbackRoute="/d/invest" component={AcatWizard}>
      <Route path="*" component={AcatWizard} />
    </Route>
  );
};
