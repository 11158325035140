import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { TransfersRulesPage } from './TransfersRulesPage';

export const TransfersRulesRoute = () => {
  return (
    <Route path="rules" component={createRouteComponent(TransfersRulesPage)} />
  );
};
