import { PL, styled, Box, Card } from '@m1/liquid-react';
import * as React from 'react';

import {
  isPieNameValid,
  isPieDescriptionValid,
  readPieTreeByPath,
} from '~/pie-trees';
import {
  updatePortfolioDescriptionAction,
  updatePortfolioNameAction,
} from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Input } from '~/toolbox/Input';

import {
  HelperDialog,
  DialogSequenceConfig,
} from '../HelperDialog/HelperDialog';
import { portfolioNameDialogText } from '../PortfolioOrganizer.constants';

const FullWidthHelperDialog = styled(HelperDialog)`
  width: 100%;
`;

export type PortfolioNameAndDescriptionFormProps = {
  dialogSequenceConfig: DialogSequenceConfig;
};

export const PortfolioNameAndDescriptionForm = ({
  dialogSequenceConfig,
}: PortfolioNameAndDescriptionFormProps) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = React.useState(true);

  const { isCreatingPortfolio, path, showHelperDialogs } = useSelector(
    (state) => {
      const { path, session, showHelperDialogs } = state.portfolioOrganizer;
      const isCreatingPortfolio = session.mode === 'NEW_ROOT_PIE';

      return {
        isCreatingPortfolio,
        path,
        showHelperDialogs: showHelperDialogs && isCreatingPortfolio,
      };
    },
  );

  const { name, description } = useSelector((state) => {
    return readPieTreeByPath(
      state.portfolioOrganizer.pieTree,
      state.portfolioOrganizer.path,
    );
  });

  return (
    <Card backgroundColor="backgroundNeutralSecondary" p={16}>
      <PL
        fontWeight={600}
        color="foregroundNeutralMain"
        content={`${
          isCreatingPortfolio && path.length === 0 ? 'Portfolio' : 'Pie'
        } details`}
      />
      <form name="portfolio-details-form">
        {/* @ts-expect-error - TS2769 - No overload matches this call. */}
        <FullWidthHelperDialog
          alignment="start"
          content={portfolioNameDialogText}
          height={160}
          margin="-16px 0 0 0"
          isVisible={
            showHelperDialogs &&
            dialogSequenceConfig.SEQUENCED_DIALOGS
              .PORTFOLIO_NAME_AND_DESCRIPTION ===
              dialogSequenceConfig.activeDialogPage
          }
          pageNumber={
            dialogSequenceConfig.SEQUENCED_DIALOGS
              .PORTFOLIO_NAME_AND_DESCRIPTION
          }
          sequence={dialogSequenceConfig}
          title="Portfolio Name"
        >
          <Box>
            <Input
              error={
                !isPieNameValid(name, true)
                  ? 'Maximum character limit is 80.'
                  : undefined
              }
              label={isCreatingPortfolio ? 'Portfolio Name' : 'Pie name'}
              placeholder="Maximum character limit is 80"
              onChange={(e: React.SyntheticEvent<HTMLInputElement>): void => {
                if (!isEditing) {
                  setIsEditing(true);
                }
                // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
                dispatch(updatePortfolioNameAction(e.target.value));
              }}
              value={name ?? ''}
            />
          </Box>
        </FullWidthHelperDialog>
        <Box>
          <Input
            error={
              !isPieDescriptionValid(description)
                ? 'Maximum character limit is 1000.'
                : undefined
            }
            label="Description (optional)"
            placeholder="Maximum character limit is 1000"
            onChange={(e: React.SyntheticEvent<HTMLInputElement>): void => {
              if (!isEditing) {
                setIsEditing(true);
              }
              // @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
              dispatch(updatePortfolioDescriptionAction(e.target.value));
            }}
            value={description ?? ''}
          />
        </Box>
      </form>
    </Card>
  );
};
