import { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ChartableSliceChartDocument } from '~/graphql/hooks';
import {
  ACTION_TYPES as ACTIONS,
  displayNotification,
  navigate,
} from '~/redux/actions';
import { AppState } from '~/redux/reducers';
import { readLandingUrl } from '~/redux/selectors';
import { NOTIFICATION_LOCATIONS, NOTIFICATION_TYPES } from '~/static-constants';
import { isNotNil } from '~/utils';

import { apolloQuerySaga } from '../apolloQuerySaga';
import {
  hasUserOnboarded,
  handleProductAutoSelectDeepLinksSaga,
} from '../common';

export function* loginMonitor(): SagaIterator<void> {
  yield takeLatest(
    ACTIONS.FINISHED_LOGIN_FLOW,
    function* (): SagaIterator<void> {
      try {
        const [landingUrl, userHasOnboarded, history] = yield all([
          select(readLandingUrl),
          call(hasUserOnboarded),
          getContext('history'),
        ]);

        const deeplinkRedirects = yield call(
          handleProductAutoSelectDeepLinksSaga,
        );

        if (deeplinkRedirects) {
          const { route, previousRoute } = deeplinkRedirects;
          if (isNotNil(previousRoute)) {
            yield put(
              navigate({ to: previousRoute, options: { replace: true } }),
            );
          }
          yield put(navigate({ to: route }));
        } else if (!userHasOnboarded) {
          yield put(navigate({ to: '/start/product-selection' }));
        } else if (
          (landingUrl && landingUrl.startsWith('/d/')) ||
          landingUrl.includes('activate-your-card')
        ) {
          yield call(history.push, landingUrl);
        } else {
          yield put(navigate({ to: '/d/home' }));

          const netWorthNodeId = yield select(
            (state: AppState) => state.global.netWorthNodeId,
          );
          // Preload the net worth chart
          if (netWorthNodeId) {
            yield call(apolloQuerySaga, {
              query: ChartableSliceChartDocument,
              variables: {
                ids: [netWorthNodeId],
              },
            });
          }
        }
      } catch (e: any) {
        yield put(
          displayNotification({
            type: NOTIFICATION_TYPES.ERROR_ALT,
            location: NOTIFICATION_LOCATIONS.LOGIN,
            message: 'Something went wrong. Please try again.',
          }),
        );
      }
    },
  );
}
