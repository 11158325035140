import * as React from 'react';
import { LocationDescriptor, Route } from 'react-router';

import { PortfolioOrganizerPage } from '~/components/PortfolioOrganizerPage';

export const PortfolioOrganizerRoute = () => {
  return (
    <Route
      path="portfolio-organizer"
      component={({ location }: { location: LocationDescriptor }) => {
        return <PortfolioOrganizerPage event={location.state?.event} />;
      }}
    />
  );
};
