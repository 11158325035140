import * as React from 'react';
import { Route } from 'react-router';

import { ConfirmIdentityPage } from './ConfirmIdentityPage';

export const ConfirmIdentityRoute = () => {
  return (
    <Route
      path="confirm-identity"
      component={ConfirmIdentityPage}
      fallbackRoute="'/d/invest/portfolio'"
      cover
    />
  );
};
