export const RESOLVE_MARGIN_CALL_ACTIONS = Object.freeze({
  BEGIN_RESOLVE_CALL_FLOW: 'BEGIN_RESOLVE_CALL_FLOW' as const,
  RETURN_TO_RESOLVE_MARGIN_CALL_INFORMATION:
    'RETURN_TO_RESOLVE_MARGIN_CALL_INFORMATION' as const,
  SELECTED_CREATE_TRANSFER_RESOLUTION_OPTION:
    'SELECTED_CREATE_TRANSFER_RESOLUTION_OPTION' as const,
  SELECTED_SELL_POSITION_RESOLUTION_OPTION:
    'SELECTED_SELL_POSITION_RESOLUTION_OPTION' as const,
  CLICKED_CANCEL_SET_ORDER: 'CLICKED_CANCEL_SET_ORDER' as const,
});

export type SelectedSellPositionResolutionOptionAction = {
  payload: {
    isFromHoldingsPositionDetail?: boolean;
    portfolioSliceId: string;
    previousRouteName?: string;
    setAsSellOrder?: boolean;
  };
  type: typeof RESOLVE_MARGIN_CALL_ACTIONS.SELECTED_SELL_POSITION_RESOLUTION_OPTION;
};

export type BeginResolveCallFlowAction = {
  payload: unknown;
  type: typeof RESOLVE_MARGIN_CALL_ACTIONS.BEGIN_RESOLVE_CALL_FLOW;
};

export type ReturnToResolveMarginCallInformationAction = {
  payload: unknown;
  type: typeof RESOLVE_MARGIN_CALL_ACTIONS.RETURN_TO_RESOLVE_MARGIN_CALL_INFORMATION;
};

export type SelectedCreateTransferResolutionOptionAction = {
  payload: unknown;
  type: typeof RESOLVE_MARGIN_CALL_ACTIONS.SELECTED_CREATE_TRANSFER_RESOLUTION_OPTION;
};

export type ResolveMarginCallAction =
  | BeginResolveCallFlowAction
  | ReturnToResolveMarginCallInformationAction
  | SelectedSellPositionResolutionOptionAction
  | SelectedCreateTransferResolutionOptionAction;
