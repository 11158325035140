import { PL, PM, Box, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { List } from '~/toolbox/list';

export const SEQUENCED_DIALOGS_WITHOUT_SLICES = {
  PORTFOLIO_NAME_AND_DESCRIPTION: 1,
  SLICES: 2,
  CREATE_PIE: 3,
  SEARCH_AND_ADD: 4,
  BULK_ACTIONS: 5,
  SET_TARGETS: null,
  DRAG_AND_DROP: null,
};

export const SEQUENCED_DIALOGS_WITH_SLICES = {
  PORTFOLIO_NAME_AND_DESCRIPTION: 1,
  SLICES: 2,
  SET_TARGETS: 3,
  CREATE_PIE: 4,
  SEARCH_AND_ADD: 5,
  DRAG_AND_DROP: 6,
  BULK_ACTIONS: 7,
};

export const slicesDialogMessage = `Each row is a slice of this Pie and has a
  target weight percentage. Click on each row
  to view details of a slice. Click on the target
  input area to change the percentage.`;

export const setTargetWeightDialogMessage = `The sum of your slices per Pie must
  equal 100%. Set a slice to 0% to remove from your portfolio.`;

export const noSlicesDialogMessage = `Once you add slices, you'll see that each
  row is a slice of this Pie and has a target
  weight percentage. Tap each row to view
  details of a slice. Tap the target input
  area to change the percentage.
  Swipe left to remove a slice.`;

export const portfolioNameDialogText = (
  <PM content="This is the name of your portfolio associated with your invest account." />
);

const StyledIcon = styled(Icon)`
  user-select: none;
`;

export const dragAndDropDialogMessage = () => (
  <PL>
    When a new Pie has been created, a drag and drop icon will appear here. You
    can move slices by clicking and holding a row that has the drag icon.
    {/* @ts-expect-error - TS2769 - No overload matches this call. */}
    <StyledIcon name="drag24" ml={5} top={3} draggable={false} /> .
  </PL>
);

export const bulkActionsDialogMessage = () => (
  <Box>
    <List>
      <List.Item content="Move: Select slices to move to a different or new Pie." />
      <List.Item content="Equalize: Select all slices to split target weights percentages equally." />
      <List.Item content="Remove: Select slices to remove from your portfolio." />
    </List>
  </Box>
);
