import * as React from 'react';
import { Route } from 'react-router';

import { MoveMoneyPage } from './MoveMoneyPage';

export const MoveMoneyRoute = () => {
  return (
    <Route
      path="move-money(/:step)"
      component={MoveMoneyPage}
      fallbackRoute="/d/transfers"
    />
  );
};
