import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import { FinancialSuitabilityRoute } from '~/pages/onboarding/financial-suitability-wizard';
import { SetupIraAccountRoute } from '~/pages/setup-ira-account';
import { requireAuth, RouteHandlers } from '~/router';
import { ONBOARDING_FLOW_STEPS as STEPS } from '~/static-constants';

import { InitialFundingPage } from './initial-funding';
import { OnboardingPage } from './OnboardingPage';
import { PersonalLoansOnboardingPage } from './PersonalLoansOnboardingPage';
import { PhoneVerificationPage } from './phone-verification';
import { SavingsOnboardingPage } from './SavingsOnboardingPage';
import { IdentityFirstWrapper } from './setup-account';
import { SetupInvestAccount } from './SetupInvestAccount';

export const OnboardingRoute = (routeHandlers: RouteHandlers) => {
  return (
    <Route
      component={OnboardingPage}
      onEnter={routeHandlers(requireAuth)}
      path="onboarding"
    >
      <Route
        component={PhoneVerificationPage}
        path={`${STEPS.PHONE_VERIFICATION}(/:phoneVerificationStep)`}
        unmask
      />
      <Route
        component={IdentityFirstWrapper}
        path={`${STEPS.SETUP_ACCOUNT}(/:accountSetupStep)`}
        unmask
      />
      {FinancialSuitabilityRoute()}
      <Route
        path="personal-loans-onboarding(/:step)"
        navigationState={{
          forceRefetch: true,
        }}
        onEnter={routeHandlers(requireAuth)}
        component={PersonalLoansOnboardingPage}
      />
      <Route
        path="savings-onboarding(/:step)"
        navigationState={{
          forceRefetch: true,
        }}
        onEnter={routeHandlers(requireAuth)}
        component={SavingsOnboardingPage}
      />
      <Route
        component={SetupInvestAccount}
        path={`${STEPS.SETUP_INVEST_ACCOUNT}(/:investAccountSetupStep)`}
        onEnter={routeHandlers(requireAuth)}
        unmask
      />
      {SetupIraAccountRoute()}
      <Route
        component={InitialFundingPage}
        path={`${STEPS.INITIAL_FUNDING}(/:initialFundingStep)(/:childStep)`}
        unmask
      />
      <IndexRoute component={PhoneVerificationPage} />
    </Route>
  );
};
