import { TwoColumn3by9 } from '@m1/liquid-react';
import * as React from 'react';

import { InvestSettingsNavigation } from './InvestSettingsNavigation';

export const InvestSettingsLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <TwoColumn3by9>
      <TwoColumn3by9.Column1>
        <InvestSettingsNavigation />
      </TwoColumn3by9.Column1>
      <TwoColumn3by9.Column2>{children}</TwoColumn3by9.Column2>
    </TwoColumn3by9>
  );
};
