import { Flex } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { InvestmentsTable } from '~/components/invest/InvestmentsTable';
import { UnmanagedHoldingsCard } from '~/components/invest/UnmanagedHoldingsCard';
import { useHoldingsPageQuery } from '~/graphql/hooks';
import { useLocation } from '~/hooks/useLocation';
import { useSelector } from '~/redux/hooks';
import { Link } from '~/toolbox/link';
import { Spinner } from '~/toolbox/spinner';

import { BuildPortfolioBanner } from './components/BuildPortfolioBanner';
import { HoldingsNav } from './components/HoldingsNav';
import { InvestmentsUnavailable } from './components/InvestmentsUnavailable';

export const HoldingsPage = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const accountId = useSelector<string | null | undefined>(
    (state) => state.global.activeAccountId,
  );

  const { pathname } = useLocation();

  const { data, loading } = useHoldingsPageQuery({
    variables: {
      accountId: accountId as string,
    },
    skip: !accountId,
  });

  // Position details page is returned here.
  if (pathname.includes('position/')) {
    return children;
  }

  if (loading) {
    return <Spinner fullScreen />;
  }

  const account = data?.account;

  if (!accountId || account?.__typename !== 'Account') {
    return <InvestmentsUnavailable />;
  }

  const hasSetRootPie = Boolean(account.hasPortfolio);
  const hasUnmanagedHoldings = Boolean(
    account.receivedSecurities?.unmanagedHoldingsScreen?.hasUnmanagedHoldings,
  );

  const receivedSecuritiesCard =
    account.receivedSecurities?.receivedSecuritiesCard;

  const isDividendsPage = pathname.includes('dividends');

  return (
    <>
      {!account.isCryptoAccount && <HoldingsNav />}
      {isDividendsPage ? (
        children
      ) : (
        <>
          {receivedSecuritiesCard && (
            <UnmanagedHoldingsCard
              receivedSecuritiesCard={receivedSecuritiesCard}
            />
          )}
          {!hasSetRootPie && hasUnmanagedHoldings && <BuildPortfolioBanner />}
          <InvestmentsTable accountId={account.id} />
          {account.isCryptoAccount && (
            <Flex flexDirection="column" alignItems="flex-end" mt={8}>
              <Illustration name="cryptoDisclosure" height={24} />
              <Link
                to="https://bakkt.com/disclosures"
                target="_blank"
                font="PS"
              >
                View disclosures
              </Link>
            </Flex>
          )}
        </>
      )}
    </>
  );
};
