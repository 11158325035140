import { SemanticColorNames, styled, Theme } from '@m1/liquid-react';

interface StyledInputProps {
  align: 'left' | 'center' | 'right';
  backgroundColor?: SemanticColorNames;
  disabled?: boolean;
  error?: string | boolean;
  isBold?: boolean;
  size: 'medium' | 'large' | 'xlarge';
  value?: string | number;
  theme: Theme;
}

export const StyledInput = styled.input<StyledInputProps>`
  background-color: ${({ backgroundColor, theme }: StyledInputProps) =>
    backgroundColor ? theme.colors[backgroundColor] : 'inherit'};
  border: 0;
  display: block;
  font-weight: ${({ isBold }) => isBold && '600'};
  outline: none;
  text-align: ${({ align }) => align};
  width: 100%;

  &::before,
  &::after,
  &::placeholder {
    color: ${({ error, theme }: StyledInputProps) => {
      return error
        ? theme.colors.critical
        : theme.colors.foregroundNeutralSecondary;
    }};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ error, theme }: StyledInputProps) => {
      return error
        ? theme.colors.critical
        : theme.colors.foregroundNeutralTertiary;
    }};

    // Fixes a Safari bug where placeholder text isn't colored correctly when disabled
    -webkit-text-fill-color: ${({ error, theme }: StyledInputProps) => {
      return error
        ? theme.colors.critical
        : theme.colors.foregroundNeutralTertiary;
    }};
  }

  border-radius: 8px;
  padding: ${({ size }) => (size === 'medium' ? '9' : '14')}px 16px;
`;
