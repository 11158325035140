import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import { MyPiesResearchPage } from './components/MyPiesResearchPage';

import { UserPieDetailsRoute } from './details';

export const MyPiesResearchRoute = () => {
  return (
    <Route path="my-pies">
      {UserPieDetailsRoute()}
      <IndexRoute component={MyPiesResearchPage} />
    </Route>
  );
};
