import { Box, Button, Flex, HM, PM, PXL } from '@m1/liquid-react';
import * as React from 'react';

import { useFormContext } from 'react-hook-form';

import { EmailVerificationCard } from '~/components/cards/EmailVerificationCard/EmailVerificationCard(DEPRECATED)';

import { ControlledInput } from '~/components/form/ControlledInput';
import { useWizardContext } from '~/flows/wizard';
import {
  useCompletePhoneVerificationMutation,
  useVerifyReceivedCodeStepQuery,
} from '~/graphql/hooks';
import { CompletePhoneVerificationInput } from '~/graphql/types';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { NOTIFICATION_LOCATIONS } from '~/static-constants';
import { useToast } from '~/toasts';
import { Spinner } from '~/toolbox/spinner';
import { normalizePhoneNumber } from '~/utils';

type VerifyReceivedCodeStepProps = {
  onFinishStep: (args?: any) => void;
};

export const VerifyReceivedCodeStep = ({
  onFinishStep,
}: VerifyReceivedCodeStepProps) => {
  const { data, loading } = useVerifyReceivedCodeStepQuery();
  const [completePhoneVerification, { loading: verificationLoading }] =
    useCompletePhoneVerificationMutation();

  const { addToast } = useToast();

  usePortaledSpinner(verificationLoading);

  const { back } = useWizardContext();

  const {
    watch,
    control,
    formState: { isValid },
    setValue,
    handleSubmit,
  } = useFormContext<CompletePhoneVerificationInput>();

  React.useEffect(() => {
    setValue('code', '');
  }, [setValue]);

  const phone = watch('phone');

  if (loading) {
    return <Spinner fullScreen />;
  }
  const isPrimaryEmailVerified = Boolean(
    data?.viewer.user?.isPrimaryEmailVerified,
  );

  const handleTryAgain = () => back();

  const onSubmit = (input: CompletePhoneVerificationInput) => {
    completePhoneVerification({
      variables: {
        input: {
          code: input.code,
          phone: normalizePhoneNumber(input.phone),
        },
      },
      onCompleted: (data) => {
        onFinishStep(data);
      },
      onError: (err) => {
        addToast({
          content: err.message,
          kind: 'alert',
          duration: 'short',
        });
        if (
          err.graphQLErrors.some(
            (err) =>
              typeof err.extensions.code === 'string' &&
              ['NO_PENDING_VERIFICATION', 'PHONE_ALREADY_USED'].includes(
                err.extensions.code,
              ),
          )
        ) {
          back();
        }
      },
    });
  };

  const isProduction =
    __NODE_ENV__ === 'production' && __ENV__ === 'production';

  return (
    <Flex flexDirection="column">
      <HM mb={32} content="Please enter the code here:" />

      <ControlledInput
        name="code"
        control={control}
        autoFocus
        backgroundColor="backgroundNeutralSecondary"
        label="Confirmation code"
        maxLength={10}
        minLength={4}
        rules={{ required: 'Required.', minLength: 4 }}
      />
      <PXL mt={16} mb={48}>
        <Button
          kind="link"
          label="Resend code"
          onClick={handleTryAgain}
          style={{
            fontSize: '16px',
            fontWeight: '600',
          }}
        />
      </PXL>
      {!isPrimaryEmailVerified && (
        <EmailVerificationCard
          notificationLocation={
            NOTIFICATION_LOCATIONS.ONBOARDING_RESEND_VERIFICATION_EMAIL
          }
        />
      )}
      <Box>
        <Button
          disabled={!isValid}
          kind="primary"
          label="Continue"
          mt={48}
          type="submit"
          size="large"
          onClick={handleSubmit(onSubmit)}
        />
        {!isProduction && (
          <PM
            color="primary"
            cursor="pointer"
            content="Mock this value!"
            onClick={() => {
              setValue('code', '5678', { shouldValidate: true });
            }}
            pt={8}
          />
        )}
      </Box>
    </Flex>
  );
};
