import {
  Box,
  Button,
  Flex,
  HM,
  HXS,
  HXXS,
  Modal,
  ModalContent,
  PM,
  PS,
  Pill,
  Tooltip,
  styled,
  Card,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import capitalize from 'lodash-es/capitalize';
import * as React from 'react';

import { CashBalanceControlForm } from '~/components/invest/CashBalanceControlForm/CashBalanceControlForm';
import { useAccountTradingStatusQuery } from '~/graphql/hooks';
import {
  AccountConfigurationDividendsCta,
  AppLink,
  PortfolioDetailsSliceQuery,
  PreferredTradeWindowEnum,
  TradingStatusFragment,
} from '~/graphql/types';
import { useLinkable } from '~/hooks/useLinkable';
import { useSelector } from '~/redux/hooks';

import { formatTradingTimeByTimeStamp } from '~/utils';

import { AccountTradingWindowPreference } from './AccountTradingWindowPreference';
import { ActivityLink } from './ActivityLink';

type AutomationProps = {
  account: ExtractTypename<PortfolioDetailsSliceQuery['account'], 'Account'>;
};

const FlexWithGap = styled(Flex)`
  flex-flow: column;
  gap: 16px;
`;

const OverflowPM = styled(PM)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Border = styled(Box)`
  height: 1px;
  min-height: 1px;
  border-top: ${({ theme }) => `solid 1px ${theme.colors.borderMain}`};
`;

const StyledNextTradeWindowButtonLink = styled(Button)`
  display: block;
  width: 100%;
  padding: 14px 0px;

  span {
    color: ${({ theme }) => theme.colors.foregroundNeutralMain};
  }

  &:hover:enabled,
  &:focus:enabled {
    background-color: ${({ theme }) => theme.colors.backgroundNeutralMain};

    span {
      color: ${({ theme }) => theme.colors.foregroundNeutralMain};
    }
  }
`;

type NextTradeProps = {
  trading: TradingStatusFragment;
  preferredTradeWindow: PreferredTradeWindowEnum | null;
  isCryptoAccount: boolean;
};

const NextTrade = ({
  trading,
  preferredTradeWindow,
  isCryptoAccount,
}: NextTradeProps) => {
  const [isOpen, setModalOpen] = React.useState<boolean>(false);

  const { nextWindowForAccount, inProgressWindowForAccount } = trading;

  const tradeWindow = React.useMemo(() => {
    switch (nextWindowForAccount) {
      case 'MORNING':
        return trading?.nextMorningWindow;
      case 'AFTERNOON':
        return trading?.nextAfternoonWindow;
      case 'ON_DEMAND':
        return trading?.nextOnDemandWindow;
      default:
        return undefined;
    }
  }, [trading, nextWindowForAccount]);

  if (!tradeWindow) {
    return null;
  }

  const windowDate =
    inProgressWindowForAccount === nextWindowForAccount
      ? 'In progress'
      : formatTradingTimeByTimeStamp(tradeWindow.startTime);

  return !isCryptoAccount ? (
    <>
      <Border />
      <StyledNextTradeWindowButtonLink
        label={
          <Flex justifyContent="space-between" alignItems="center">
            <PM content="Next trade window" />
            <Pill kind="neutral" label={windowDate} />
          </Flex>
        }
        kind="link"
        onClick={() => setModalOpen(true)}
      />
      <Modal open={isOpen} onClose={() => setModalOpen(false)}>
        <ModalContent withPadding={false} width="wide">
          <Box padding={32}>
            <HXS fontWeight={300} content="Next trading window" mb={48} />
            <Box>
              {nextWindowForAccount && (
                <PM
                  content={capitalize(nextWindowForAccount)}
                  color="foregroundNeutralSecondary"
                  mb={8}
                />
              )}
              <HM mb={32} fontWeight={300} content={windowDate} />
            </Box>
            <FlexWithGap>
              <AccountTradingWindowPreference
                preferredTradeWindow={preferredTradeWindow}
                trading={trading}
              />
            </FlexWithGap>
            <Button
              mt={56}
              size="medium"
              label="Done"
              onClick={() => setModalOpen(false)}
              fullWidth
            />
          </Box>
        </ModalContent>
      </Modal>
    </>
  ) : (
    <Flex py={14} justifyContent="space-between" alignItems="center">
      <Flex>
        <PM content="Next trade window" />
        <Tooltip
          body={
            <PM
              content="Crypto also trades at the same times on weekends"
              py={8}
              px={12}
            />
          }
        />
      </Flex>
      <Pill kind="neutral" label={windowDate} />
    </Flex>
  );
};

const RecurringTransfers = ({ pillLabel }: { pillLabel: string }) => (
  <>
    <Border />
    <ActivityLink py={14} to="/d/transfers/rules" kind="secondary">
      <Flex alignItems="center" width="100%" justifyContent="space-between">
        <PM content="Recurring transfers" />
        <Pill kind="neutral" label={pillLabel} />
      </Flex>
    </ActivityLink>
  </>
);

export const Automation = ({ account }: AutomationProps) => {
  const accountId = useSelector((state) => state.global.activeAccountId);
  const { data, loading } = useAccountTradingStatusQuery({
    skip: !accountId,
    variables: {
      accountId: accountId as string,
    },
  });

  if (loading) {
    return null;
  }

  if (data?.node?.__typename !== 'Account') {
    return null;
  }
  const { isCryptoAccount, trading, preferredTradeWindow, isTradingPaused } =
    data.node;

  const { transfers } = data.viewer;

  const numberOfTransferRules = transfers?.scheduledTransferRules?.length;
  const pillLabel = numberOfTransferRules
    ? `${numberOfTransferRules} active`
    : 'None';

  return (
    <Card minHeight={204} p={24} mt={24}>
      <HXXS fontWeight={300} content="Automation" mb={16} />
      <OverflowPM
        content={`For ${account.name}`}
        color="foregroundNeutralSecondary"
        mb={12}
      />
      <CashBalanceControlForm accountId={account.id} />
      {account.configuration?.dividends?.cta && (
        <AccountConfigurationDividendsEntryPoint
          cta={account.configuration.dividends?.cta}
        />
      )}
      {trading && !isTradingPaused && (
        <NextTrade
          trading={trading}
          preferredTradeWindow={preferredTradeWindow}
          isCryptoAccount={Boolean(isCryptoAccount)}
        />
      )}
      <RecurringTransfers pillLabel={pillLabel} />
    </Card>
  );
};

function AccountConfigurationDividendsEntryPoint({
  cta,
}: {
  cta: AccountConfigurationDividendsCta;
}) {
  const { routeName } = useLinkable(cta.link as AppLink);

  if (!routeName) {
    // `routeName` only exists for internal links.
    // If cta.link isn't an internal link, what should we do?
    return null;
  }

  return (
    <ActivityLink
      kind="secondary"
      justifyContent="space-between"
      py={14}
      to={routeName}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <PM>{cta.label}</PM>
        <Flex alignContent="center">
          <PS fontWeight={600}>{cta.value}</PS>
          <Icon name="caretRight16" style={{ lineHeight: 0 }} />
        </Flex>
      </Flex>
    </ActivityLink>
  );
}
