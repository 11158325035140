import { HotButton } from '@m1/liquid-react';
import * as React from 'react';

import { PieOrganizerNavigationButton } from '~/components/PieOrganizerNavigationButton/PieOrganizerNavigationButton';
import { PortfolioSliceEditButtonFragment } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useNavigate } from '~/hooks/useNavigate';
import { useNewPieEditor } from '~/hooks/useNewPieEditor';
import { PieEditorEntryHotButton } from '~/pages/dashboard/wizards/pie-editor/PieEditorEntryButton';

import { useSelector } from '~/redux/hooks';

export type PortfolioSliceEditButtonProps = {
  portfolioSlice: PortfolioSliceEditButtonFragment;
};

export const PortfolioSliceEditButton = ({
  portfolioSlice,
}: PortfolioSliceEditButtonProps) => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const isCrypto = useSelector((state) => state.global.activeAccountIsCrypto);
  const { showNewPieEditor } = useNewPieEditor();
  if (!portfolioSlice.to.id || portfolioSlice.to.__typename !== 'UserPie') {
    return null;
  }

  const handleClick = () => {
    analytics.recordEvent('m1_invest_managepie_element_clicked', null, {
      'click_id': 'edit_pie',
    });
  };

  return isCrypto && !showNewPieEditor ? (
    <PieOrganizerNavigationButton
      kind="secondary"
      label="Edit"
      buttonType="HOT_BUTTON"
      event={{
        type: 'INITIALIZE_ORGANIZER',
        // @ts-expect-error - TS2322 - Type '{ confirmationDialog: { showApplicableLocations: true; }; mode: "EDIT_ROOT_PIE"; pieId: string; returnTo: string; isCrypto: true; }' is not assignable to type 'PieOrganizerConfig'.
        initConfig: {
          confirmationDialog: {
            showApplicableLocations: true,
          },
          mode: 'EDIT_ROOT_PIE',
          pieId: portfolioSlice.to.id,
          returnTo: '/d/invest/portfolio',
          isCrypto,
        },
      }}
      size="medium"
    />
  ) : (
    <>
      {showNewPieEditor ? (
        <PieEditorEntryHotButton
          data-testid="portfolio-slice-edit-button"
          label="Edit"
          icon="editPie"
          size="small"
          pieEditorRouteParam={portfolioSlice.id}
        />
      ) : (
        <HotButton
          data-testid="portfolio-slice-edit-button"
          label="Edit"
          onClick={() => {
            handleClick();
            navigate({
              to: '/d/invest/portfolio-editor',
              options: {
                state: {
                  portfolioSliceableId: portfolioSlice.to.id,
                  portfolioSliceId: portfolioSlice.id,
                },
              },
            });
          }}
          icon="editPie"
          size="small"
        />
      )}
    </>
  );
};
