import { Flex, styled } from '@m1/liquid-react';

import * as React from 'react';
import { InjectedRouteProps } from 'react-router';

import { BorrowMarginCallDialog } from '~/components/borrow';

import { useInvestPageQuery } from '~/graphql/hooks';
import { useLayout } from '~/hooks/useLayout';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { StyledPageContent } from '~/pages/dashboard/navigation/Navigation.styled';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

type InvestPageProps = InjectedRouteProps & {
  children?: React.ReactNode;
};

const PortfolioPageBackground = styled(Flex)`
  background-image: ${({ theme }) =>
    `linear-gradient(${theme.colors.backgroundFeatureFlat}, ${theme.colors.backgroundFeatureFlat}), linear-gradient(${theme.colors.backgroundNeutralMain}, ${theme.colors.backgroundNeutralMain})`};
  background-size:
    100% 200px,
    100% 100%;
  background-repeat: no-repeat;
  width: 100%;
`;

const InvestPageContent = styled(StyledPageContent)`
  padding-top: 0;
`;

export const InvestPage = ({ children, routes }: InvestPageProps) => {
  const dispatch = useDispatch();
  const { contentWidth } = useLayout();
  const location = useLocation();

  const navigate = useNavigate();
  const activeAccountId = useSelector((state) => state.global.activeAccountId);

  const isPortfolio =
    /^(d\/c\/.*|d\/invest\/portfolio(\/[0-9A-Za-z=+/]+)?)/.test(
      location.pathname,
    );

  const { data, loading, error } = useInvestPageQuery({
    variables: {
      activeAccountId: activeAccountId as string,
    },
    skip: !activeAccountId,
  });

  const inPortfolioEditor =
    routes?.length > 4 &&
    (routes[4].path.includes('portfolio-editor') ||
      routes[4].path.includes('portfolio-organizer'));

  React.useEffect(() => {
    if (!activeAccountId && !inPortfolioEditor) {
      navigate({
        to: '/d/invest/invest-marketing',
        options: { replace: true },
      });
      dispatch({
        type: 'SET_INVEST_MARKETING_SESSION',
      });
    }
  }, [dispatch, activeAccountId, navigate]);

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.account) {
    return (
      <>
        {children}
        <BorrowMarginCallDialog />
      </>
    );
  }

  if (inPortfolioEditor) {
    return (
      <PortfolioPageBackground>
        <StyledPageContent contentWidth={contentWidth}>
          {children}
          <BorrowMarginCallDialog />
        </StyledPageContent>
      </PortfolioPageBackground>
    );
  }

  return (
    <InvestPageContent
      contentWidth={contentWidth}
      padding={isPortfolio ? '0 0 0 0 !important' : undefined}
    >
      {children}
      <BorrowMarginCallDialog />
    </InvestPageContent>
  );
};
