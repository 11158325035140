import { Box, Button, Flex, HM } from '@m1/liquid-react';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { BackArrow } from '~/components/BackArrow';
import { ControlledDropdown } from '~/components/form/ControlledDropdown';
import { TextField } from '~/components/form/TextField';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useOpenInvestJointAccountWizardContext } from '~/flows/components/OpenInvestJointAccountWizard/hooks/useOpenInvestJointAccountWizardContext';
import { FormMockerReactHookForm } from '~/forms/FormMockers';
import { invalidWhiteSpace, validInput } from '~/forms/validators';
import { useInvestConfirmationStepQuery } from '~/graphql/hooks';
import {
  EmploymentStatusEnum,
  OnlineAccountRegistrationEnum,
} from '~/graphql/types';
import { submitReactFormData } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { FIELD_MAX_LENGTHS } from '~/static-constants';
import { Spinner } from '~/toolbox/spinner';
import { getEnumEntries } from '~/utils';

export interface EmploymentFormValues {
  employer: string | null | undefined;
  employmentStatus: EmploymentStatusEnum | undefined;
  occupation: string | null | undefined;
}

export const Employment = () => {
  const dispatch = useDispatch();

  const submittedFormValues = useSelector((state) => {
    if (!state.reactHookForm.openJointInvestAccountEmployment) {
      return {
        employer: undefined,
        employmentStatus: undefined,
        occupation: undefined,
      };
    }

    return state.reactHookForm.openJointInvestAccountEmployment;
  });

  const { control, handleSubmit, setValue, watch } =
    useForm<EmploymentFormValues>({
      defaultValues: submittedFormValues,
    });

  const values = watch();

  const { employmentStatus } = values;

  const isEmployed =
    employmentStatus === 'EMPLOYED' || employmentStatus === 'SELF_EMPLOYED';

  const wiz = useOpenInvestJointAccountWizardContext();

  const { data, loading } = useInvestConfirmationStepQuery({
    variables: {
      accountRegistration: OnlineAccountRegistrationEnum.Joint,
    },
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!data?.viewer.onboarding?.dueDiligence?.occupationDropdownOptions) {
    return <GenericSystemError />;
  }

  const occupationOptions =
    data?.viewer.onboarding.dueDiligence.occupationDropdownOptions;

  const occupationsWithNames = occupationOptions?.map((option) => {
    return {
      name: option.label,
      description: option.label,
    };
  });

  const onSubmit: SubmitHandler<EmploymentFormValues> = (data) => {
    dispatch(submitReactFormData({ openJointInvestAccountEmployment: data }));
    wiz.next();
  };

  return (
    <Flex maxWidth={588} flexDirection="column" mx="auto">
      <Box pt={32}>
        <BackArrow content="Back" onClick={() => wiz.back()} />
      </Box>
      <HM mt={48} mb={32}>
        Tell us about your work.
      </HM>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ControlledDropdown
          name="employmentStatus"
          label="Employment status"
          placeholder="Select"
          control={control}
          source={getEnumEntries('EmploymentStatusEnum')}
          rules={{
            required: 'Required',
          }}
          backgroundColor="backgroundNeutralMain"
        />
        {isEmployed && (
          <>
            <Box>
              <TextField
                name="employer"
                label="Employer"
                control={control}
                rules={{
                  required: 'Required',
                  maxLength: FIELD_MAX_LENGTHS.DEFAULT,
                  validate: (value: any): boolean =>
                    !invalidWhiteSpace.test(value) || validInput.test(value),
                }}
                backgroundColor="backgroundNeutralMain"
              />
            </Box>
            <Box>
              <ControlledDropdown
                name="occupation"
                label="Occupation/Industry"
                source={occupationsWithNames}
                control={control}
                rules={{
                  required: 'Required',
                }}
                backgroundColor="backgroundNeutralMain"
              />
            </Box>
          </>
        )}
        <Button
          type="submit"
          kind="primary"
          size="large"
          label="Continue"
          mt={32}
        />
        <FormMockerReactHookForm
          fields={[
            {
              name: 'employmentStatus',
              value: 'EMPLOYED',
            },
            {
              name: 'employer',
              value: 'M1',
            },
            {
              name: 'occupation',
              value: 'Accountant or Auditor',
            },
          ]}
          setValue={setValue}
        />
      </form>
    </Flex>
  );
};
