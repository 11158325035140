import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { SecurityDetailsLayout } from '../../SecurityDetailsLayout';

export const ResearchStockDetailsRoute = () => {
  return (
    <Route
      path="details/:equityId"
      component={createRouteComponent(SecurityDetailsLayout, (props) => ({
        securityId: props.params.equityId,
      }))}
    />
  );
};
