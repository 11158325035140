import * as React from 'react';
import { IndexRoute, Redirect, Route } from 'react-router';

import { ResearchModelPortfolioCategoryDetailsRoute } from './category-details';
import { ModelPortfolioDetailsRoute } from './details';
import { ModelPortfolioPage } from './ModelPortfolioPage';

export const ModelPortfolioCategoriesRoute = () => {
  return (
    <Route path="model-portfolios">
      <Redirect
        from="/d/research/expert-pies/:categoryKey"
        to="/d/research/model-portfolios/:categoryKey"
      />
      <Redirect
        from="/d/research/expert-pies/details/:modelPortfolioId"
        to="/d/research/model-portfolios/details/:modelPortfolioId"
      />
      {ResearchModelPortfolioCategoryDetailsRoute()}
      {ModelPortfolioDetailsRoute()}
      <IndexRoute component={ModelPortfolioPage} />
    </Route>
  );
};
