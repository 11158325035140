import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { Grid } from '~/toolbox/grid';
import { M1Logo } from '~/toolbox/M1Logo';

type InitiateResetPasswordPageProps = {
  children?: React.ReactNode;
};

export const InitiateResetPasswordPageLayout = ({
  children,
}: InitiateResetPasswordPageProps) => {
  return (
    <Grid
      constrain
      style={{
        paddingTop: 35,
      }}
    >
      <Grid.Row xsCenter xsTextCenter>
        <Grid.Col md={5} sm={7} xs={12}>
          <Box pb={16}>
            <M1Logo width={42} />
          </Box>
          {children}
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};
