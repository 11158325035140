import { Flex, styled, FlexProps } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';

import { ErrorIcon } from '../ErrorIcon';
import { InputIconAndContentOrder } from '../Input.types';

export const StyledContent = styled(Flex)<
  FlexProps & {
    iconAndContentOrder: InputIconAndContentOrder;
  }
>`
  align-items: center;
  flex-direction: ${({ iconAndContentOrder }) =>
    iconAndContentOrder === 'left' ? 'row-reverse' : 'row'};
  ${({ iconAndContentOrder }) =>
    iconAndContentOrder === 'left'
      ? 'margin-left: 16px;'
      : 'margin-right: 16px;'};
`;

export const StyledMessageContainer = styled.div`
  position: relative;
`;

export const StyledMessage = styled.div<{
  error: boolean;
}>`
  color: ${({ error, theme }) =>
    error ? theme.colors.critical : theme.colors.foregroundNeutralSecondary};
  font-weight: ${({ error }) => error && 600};
  font-size: 12px;
  left: 0;
  position: absolute;
  top: 4px;
  padding-top: 4px;
`;

export const StyledDollarSign = styled.span`
  left: 16px;
  padding-right: 2px;
  position: relative;
`;

export const StyledPercentSign = styled.span`
  right: 16px;
  position: absolute;
`;

export const StyledErrorIcon = styled(ErrorIcon)<{
  iconAndContentOrder: InputIconAndContentOrder;
}>`
  ${({ iconAndContentOrder }) =>
    iconAndContentOrder === 'right'
      ? `margin-left: 8px;`
      : `margin-right: 8px;`};
`;

export const StyledIcon = styled(Icon)<{
  iconAndContentOrder: InputIconAndContentOrder;
  error?: boolean;
  onClick?: (...args: Array<any>) => any;
  value: string;
}>`
  color: ${({ error, theme }) =>
    error === true
      ? theme.colors.critical
      : theme.colors.foregroundNeutralSecondary};

  ${({ iconAndContentOrder }) =>
    iconAndContentOrder === 'right'
      ? `margin-left: 8px;`
      : `margin-right: 8px;`};

  ${({ onClick }) => onClick && `cursor: pointer;`};
`;
