import { Button, HM, HXS, Card } from '@m1/liquid-react';
import moment from 'moment';
import * as React from 'react';

import { LabelWithValue } from '~/components/label-with-value';
import { TransferParticipantCell } from '~/components/transfers/TransferParticipantCell';
import { WidthConstrainerWithArrow } from '~/components/WidthConstrainerWithArrow';
import { useConfirmTransferScheduleQuery } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useHistory } from '~/hooks/useHistory';
import { useSelector } from '~/redux/hooks';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Divider } from '~/toolbox/divider';
import { Grid } from '~/toolbox/grid';
import { formatNumber, getEnumDescription } from '~/utils';

type Props = {
  onFinishStep: (...args: Array<any>) => any;
  router: any;
};

export const ConfirmTransferScheduleStep = (props: Props) => {
  const {
    fromParticipantId,
    iraContributionYear,
    iraDistributionReason,
    toParticipantId,
    amount,
    schedule,
    isPersonalLoanPayment,
    label,
    isCreditCardPayment,
  } = useSelector((state) => state.newFlows.CREATE_TRANSFER.input as any);

  const date = React.useMemo(() => moment().toISOString(), []);

  const { data, loading } = useConfirmTransferScheduleQuery({
    variables: {
      date,
      destinationId: toParticipantId as string,
      schedule,
      sourceId: fromParticipantId as string,
    },
    skip: !toParticipantId || !fromParticipantId,
  });
  const analytics = useAnalytics();
  const history = useHistory();

  if (loading) {
    return null;
  }

  const getScheduleAmount = () => {
    if (label) {
      return label;
    }
    if (typeof amount === 'number') {
      return formatNumber(amount, '$0,0[.]00');
    }
    return '$--';
  };

  const handleBack = () => history.goBack();
  const handleConfirm = () => {
    if (isPersonalLoanPayment) {
      analytics.recordEvent('m1_personal_loan_autopay_confirmed');
    }
    props.onFinishStep();
  };

  const frequency = data?.viewer?.transfers?.scheduleDetails?.description;

  return (
    <>
      <HXS
        content={
          isCreditCardPayment || isPersonalLoanPayment
            ? 'Confirm AutoPay'
            : 'Confirm schedule'
        }
      />
      <Card mt={32} p={32}>
        <LabelWithValue
          label="Amount"
          value={<HM content={getScheduleAmount()} />}
        />
        <Divider />

        <Grid.Row
          style={{
            marginTop: 16,
          }}
        >
          <Grid.Col xs={6}>
            <LabelWithValue
              label="From"
              value={
                data?.source && 'transferParticipantName' in data.source ? (
                  <WidthConstrainerWithArrow>
                    <TransferParticipantCell
                      transferParticipant={data.source}
                    />
                  </WidthConstrainerWithArrow>
                ) : (
                  '--'
                )
              }
            />
          </Grid.Col>
          <Grid.Col xs={6}>
            <LabelWithValue
              label="To"
              value={
                data?.destination &&
                'transferParticipantName' in data.destination ? (
                  <TransferParticipantCell
                    transferParticipant={data.destination}
                  />
                ) : (
                  '--'
                )
              }
            />
          </Grid.Col>
          {iraContributionYear && (
            <Grid.Col xs={6}>
              <LabelWithValue
                label="Contribution year"
                style={{
                  paddingTop: 16,
                }}
                value={
                  iraContributionYear === 'CURRENT_YEAR'
                    ? 'Current year'
                    : 'Last year'
                }
              />
            </Grid.Col>
          )}
          {iraDistributionReason && (
            <Grid.Col xs={6}>
              <LabelWithValue
                label="Distribution reason"
                style={{
                  paddingTop: 16,
                }}
                value={
                  getEnumDescription(
                    iraDistributionReason,
                    'IraDistributionReasonEnum',
                  ) || '--'
                }
              />
            </Grid.Col>
          )}
          {frequency && (
            <Grid.Col xs={12}>
              <LabelWithValue
                label="Frequency"
                style={{
                  marginTop: 32,
                }}
                value={frequency}
              />
            </Grid.Col>
          )}
        </Grid.Row>
      </Card>
      <ButtonGroup mt={64}>
        <Button
          kind="secondary"
          label="Back"
          onClick={handleBack}
          size="large"
        />
        <Button
          kind="primary"
          label="Confirm"
          onClick={handleConfirm}
          size="large"
        />
      </ButtonGroup>
    </>
  );
};
