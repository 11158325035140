import * as React from 'react';
import { Route } from 'react-router';

import { PieDetailsPage } from '~/components/pie/PieDetailsPage/PieDetailsPage';
import { createRouteComponent } from '~/router';

export const AddMyPieDetailsRoute = () => {
  return (
    <Route
      path="details/:userPieId"
      component={createRouteComponent(PieDetailsPage, (props) => ({
        disableEditing: true,
        disableSharing: true,
        pieId: props.params.userPieId,
      }))}
    />
  );
};
