import * as React from 'react';
import { Route } from 'react-router';

import { CreateSmartTransferPage } from './CreateSmartTransferPage';

export const CreateSmartTransferRoute = () => {
  return (
    <Route
      path="create-smart-transfer(/:step)"
      component={CreateSmartTransferPage}
      fallbackRoute="/d/transfers"
      cover
    />
  );
};
