import * as React from 'react';
import { Route } from 'react-router';

import { BorrowUseCasesPage } from './BorrowUseCasesPage';

export const BorrowUseCasesRoute = () => (
  <Route
    component={BorrowUseCasesPage}
    cover
    fallbackRoute="/d/borrow"
    path="borrow-use-cases(/:useCase)"
    unmask
  />
);
