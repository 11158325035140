import {
  Maybe,
  PersonalLoanDirectQuestion,
  PersonalLoanDirectQuestionEnum,
} from '~/graphql/types';

export const getQuestionProps = ({
  question,
  questions,
}: {
  question: PersonalLoanDirectQuestionEnum;
  questions: Maybe<Array<Maybe<Partial<PersonalLoanDirectQuestion>>>>;
}): Maybe<Partial<PersonalLoanDirectQuestion>> | undefined => {
  return questions?.find((q) => q?.questionType === question.toString());
};
