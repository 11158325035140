import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type CdsSender from 'cross-domain-storage-sender';
import * as React from 'react';
import { Store } from 'redux';

import { AuthManager } from '~/auth';
import { LaunchDarkly } from '~/launch-darkly';
import { AnalyticsReporter, SentryReporter } from '~/loggers';

import { AppState } from './redux';

export type AppContextType = {
  analytics: AnalyticsReporter;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  auth: AuthManager;
  cdsSender: CdsSender;
  history: any;
  launchDarkly: LaunchDarkly;
  sentry: SentryReporter;
  store: Store<AppState>;
};

// @ts-ignore - As a practical matter, AppContext will always be available.
export const AppContext = React.createContext<AppContextType>();
