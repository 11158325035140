import { PS } from '@m1/liquid-react';
import * as React from 'react';
import { FormSection, formValues } from 'redux-form';

import { AppContext } from '~/AppContext';
import { RecurrenceScheduleInput } from '~/graphql/types';
import { compose } from '~/hocs';
import { TRANSFER_SCHEDULE_FREQUENCIES } from '~/static-constants';
import {
  WeeklyScheduleDayEnumDropdownOptions,
  WeekOfMonthScheduleWeekEnumDropdownOptions,
  MonthlyScheduleDateEnumDropdownOptions,
} from '~/static-constants/dropdown-options';
import { Dropdown } from '~/toolbox/Dropdown';
import { Grid } from '~/toolbox/grid';

import { required } from '../validators';

import { BiweeklyIsEvenWeeksDropdownField } from './biweekly-isevenweeks-dropdown-field';
import { DropdownField } from './dropdown-field';

type Props = {
  initialFrequency: ValueOf<typeof TRANSFER_SCHEDULE_FREQUENCIES>;
  initialValues: RecurrenceScheduleInput;
  isEvenWeek: boolean;
  isScheduleSwitchOn: boolean;
};

const WeeklyScheduleWithoutWeekendsEnum =
  WeeklyScheduleDayEnumDropdownOptions.slice(1, 6);
const WeekOfMonthWithoutFifthScheduleWeekEnum =
  WeekOfMonthScheduleWeekEnumDropdownOptions.slice(0, 4);

const analyticsFrequencyMap = (
  freq: ValueOf<typeof TRANSFER_SCHEDULE_FREQUENCIES>,
): string => {
  switch (freq) {
    case 'weekly':
      return 'weekly';
    case 'biweekly':
      return 'bi-weekly';
    case 'monthly':
      return 'monthly';
    case 'weekOfMonth':
      return 'week of the month';
    default:
      return '';
  }
};

const ScheduleFrequencyFieldsComponent = ({
  initialFrequency,
  initialValues,
  isEvenWeek,
  isScheduleSwitchOn,
}: Props) => {
  const { analytics } = React.useContext(AppContext);
  const [frequency, setFrequency] =
    React.useState<ValueOf<typeof TRANSFER_SCHEDULE_FREQUENCIES>>(
      initialFrequency,
    );

  const handleFrequencyChange = (
    freq: ValueOf<typeof TRANSFER_SCHEDULE_FREQUENCIES>,
  ): void => {
    analytics.recordEvent('m1_recurring_deposit_click_frequency', null, {
      frequency: analyticsFrequencyMap(freq),
    });
    setFrequency(freq);
  };

  // @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type. | TS7006 - Parameter 'allValues' implicitly has an 'any' type.
  const dayOfMonthValidation = (value) => {
    if (isScheduleSwitchOn && !value) {
      return 'Required';
    }
  };

  const renderFrequencyOptions = () => {
    switch (frequency) {
      case 'weekly':
        return (
          <DropdownField
            name="dayOfWeek"
            label="Day of week"
            placeholder="Select"
            source={WeeklyScheduleWithoutWeekendsEnum}
            style={{
              marginTop: 16,
            }}
            validate={[required]}
          />
        );

      case 'biweekly':
        return (
          <>
            <Grid.Row>
              <Grid.Col xs={6}>
                <DropdownField
                  name="dayOfWeek"
                  label="Day of week"
                  placeholder="Select"
                  source={WeeklyScheduleWithoutWeekendsEnum}
                  style={{
                    marginTop: 16,
                  }}
                  validate={[required]}
                />
              </Grid.Col>
              <Grid.Col xs={6}>
                <BiweeklyIsEvenWeeksDropdownField
                  initialValues={initialValues}
                  isEvenWeek={isEvenWeek}
                  style={{
                    marginTop: 16,
                  }}
                />
              </Grid.Col>
            </Grid.Row>
            <PS
              color="foregroundNeutralMain"
              content="Weeks start on Mondays"
            />
          </>
        );

      case 'monthly':
        return (
          <DropdownField
            name="dayOfMonth"
            label="Day of month"
            placeholder="Select"
            // NOTE: Handles form validation error where the user has the day of month
            // selected but opts in to one time deposits
            source={MonthlyScheduleDateEnumDropdownOptions}
            style={{
              marginTop: 16,
            }}
            validate={[required]}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Dropdown
        label="Frequency"
        name="frequency"
        onChange={handleFrequencyChange}
        options={[
          {
            value: TRANSFER_SCHEDULE_FREQUENCIES.WEEKLY,
            label: 'Weekly',
          },
          {
            value: TRANSFER_SCHEDULE_FREQUENCIES.BI_WEEKLY,
            label: 'Biweekly',
          },
          {
            value: TRANSFER_SCHEDULE_FREQUENCIES.MONTHLY,
            label: 'Monthly',
          },
        ]}
        style={{
          marginTop: 16,
        }}
        value={frequency}
      />
      {frequency && (
        <FormSection name={frequency}>{renderFrequencyOptions()}</FormSection>
      )}
    </>
  );
};

const enhancer = compose<any, any>(formValues(TRANSFER_SCHEDULE_FREQUENCIES));

export const ScheduleFrequencyFields = enhancer(
  ScheduleFrequencyFieldsComponent,
) as any;
