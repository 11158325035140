import * as React from 'react';
import { Route, IndexRoute } from 'react-router';

import { forwardIfAuth, RouteHandlers } from '~/router';

import { ForgotPasswordConfirmRoute } from './forgot-password-confirm';

import { InitiateResetPasswordPage } from './InitiateResetPasswordPage';
import { InitiateResetPasswordPageLayout } from './InitiateResetPasswordPageLayout';

export const ForgotPasswordRoute = (routeHandlers: RouteHandlers) => {
  return (
    <Route
      path="forgot-password"
      onEnter={routeHandlers(forwardIfAuth)}
      component={InitiateResetPasswordPageLayout}
    >
      {ForgotPasswordConfirmRoute()}
      <IndexRoute component={InitiateResetPasswordPage} />
    </Route>
  );
};
