import { PL, Flex, styled } from '@m1/liquid-react';
import copy from 'copy-to-clipboard';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import { Input } from '~/toolbox/Input';

const StyledCopyText = styled(PL)<{
  $disabled: boolean;
}>`
  ${(props) => {
    if (props.$disabled) {
      return `
        pointer-events: none;
        cursor: not-allowed;
      `;
    }
    return `
      &:hover {
        cursor: pointer;
      }
    `;
  }};
`;
type CopyInputProps = Readonly<{
  value: string;
  onCopyInput?: () => void;
}>;

export const CopyInput = ({ onCopyInput, value }: CopyInputProps) => {
  const { sentry } = React.useContext(AppContext);
  const [hasCopied, setHasCopied] = React.useState<boolean>(false);

  const handleUrlClick = (e: React.SyntheticEvent): void => {
    const target = e.target;
    if (target) {
      // @ts-expect-error - TS2339 - Property 'select' does not exist on type 'EventTarget'.
      target.select();
    }
  };

  const handleCopyClick = (shareLink: string): void => {
    try {
      copy(shareLink);
      setHasCopied(true);
    } catch (e: any) {
      sentry.message('Error using copy to clipboard library in CopyInput', {
        rawError: e,
      });
    }

    if (onCopyInput) {
      onCopyInput();
    }
  };

  return (
    <Flex alignItems="center">
      <Input
        value={value}
        onClick={handleUrlClick}
        readOnly
        kind="basic"
        additionalContent={
          <StyledCopyText
            color={hasCopied ? 'foregroundNeutralSecondary' : 'primary'}
            content={hasCopied ? 'Copied' : 'Copy'}
            onClick={() => handleCopyClick(value)}
            $disabled={hasCopied}
            fontWeight={600}
          />
        }
      />
    </Flex>
  );
};
