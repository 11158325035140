import has from 'lodash-es/has';
import merge from 'lodash-es/merge';
import { routerMiddleware as createRouterMiddleware } from 'react-router-redux';
import { StoreEnhancer, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';

import { SentryReporter } from '~/loggers';
import { ACTION_TYPES } from '~/redux/actions';

type PersistedStateMap = Record<string, (payload?: any) => Record<string, any>>;

const persistedStateMap: PersistedStateMap = {
  [ACTION_TYPES.SET_ACTIVE_INVEST_ACCOUNT]: (accountId) => ({
    global: {
      investAccountId: accountId,
    },
  }),
};

export function createStoreEnhancer(
  sentry: SentryReporter,
  history: Record<string, any>,
): [StoreEnhancer<any, any>, any, any] {
  // @ts-expect-error - TS2345 - Argument of type 'Record<string, any>' is not assignable to parameter of type 'History<DefaultQuery<string>, DefaultQueryLike>'.
  const routerMiddleware = createRouterMiddleware(history);
  const sagaMiddleware = createSagaMiddleware({
    onError: (err, context) => sentry.exception(err, context),
  });

  /**
   * LOCAL STORAGE MIDDLEWARE
   * This middleware was written to persist specific slices of our redux schema's state as a result
   * of a specific action being called.
   *
   * In order to add a persisted slice of state based off of an action type, add an entry to the
   * persistedStateMap constant in the following manner:
   *   1) The KEY should match the action type that, when fired, should store a slice of state locally
   *   2) The VALUE should be a function that accepts the action's payload as an argument
   *   3) The function should return an object, whose root key(s) should be representative of key(s) in the
   *      rootReducer's schema. this must be a plain object with the same shape as the keys passed to combineReducers
   *      in `reducers/index.js`
   *
   * The localStorageMiddleware will store this data locally and upon store creation on refresh or app load,
   * this data will be rehydrated into the store's initial values via the rehydrateStore funciton, below.
   */
  // @ts-expect-error - TS7006 - Parameter 'next' implicitly has an 'any' type. | TS7006 - Parameter 'action' implicitly has an 'any' type.
  const localStorageMiddleware = () => (next) => (action) => {
    const result: Record<string, any> = next(action);

    if (result) {
      const { payload = null, type = null } = result;

      if (has(persistedStateMap, type)) {
        localStorage.setItem(
          type,
          JSON.stringify(persistedStateMap[type](payload)),
        );
      }

      if (type === 'SUBMIT_LOGIN_FORM') {
        Object.keys(persistedStateMap).map((key) => {
          localStorage.removeItem(key);
        });
      }
    }

    return result;
  };

  const rehydrateStore = (): Record<string, any> => {
    const rehydrateData = {};

    Object.keys(persistedStateMap).map((key) => {
      const locallyStoredData = localStorage.getItem(key);
      if (locallyStoredData) {
        merge(rehydrateData, JSON.parse(locallyStoredData));
      }
    });

    return rehydrateData;
  };

  const middlewareEnhancer = applyMiddleware(
    localStorageMiddleware,
    routerMiddleware,
    sagaMiddleware,
    thunkMiddleware,
  );

  const isProduction =
    __NODE_ENV__ === 'production' && __ENV__ === 'production';

  const storeEnhancers = [
    middlewareEnhancer,
    !isProduction && window.__REDUX_DEVTOOLS_EXTENSION__?.(),
  ].filter(Boolean);

  // @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
  const storeEnhancer = compose<any, any>(...storeEnhancers);

  return [storeEnhancer, sagaMiddleware, rehydrateStore];
}
