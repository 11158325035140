import { PS, Card } from '@m1/liquid-react';
import React from 'react';

import { Sticky } from '~/components/sticky';

import { usePieDetailsContext } from '../PieDetailsPage/hooks/usePieDetailsContext';

import { PieActionButtonGroup } from './PieActionButtonGroup';

import { PieChartContainer } from './PieChartContainer';

export const PieDetailsCard = () => {
  const { disableSharing, pie } = usePieDetailsContext();

  return (
    <Sticky>
      <Card backgroundColor="backgroundNeutralSecondary" p={16}>
        <PieChartContainer />
        <div
          style={{
            padding: `0 16px 16px 16px`,
          }}
        >
          <PieActionButtonGroup />
          {pie?.__typename === 'SystemPie' && (
            <PS
              mt={16}
              color="foregroundNeutralMain"
              content="By adding this Pie to your portfolio, you acknowledge that
                        there are scheduled rebalances (e.g., buys/sells) which may
                        cause taxable events in your account."
            />
          )}
        </div>
      </Card>
    </Sticky>
  );
};
