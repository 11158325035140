import { SemanticColorNames } from '@m1/liquid-react';
import type { MonochromaticIconName } from '@m1/liquid-react/icons';
import * as React from 'react';
import type { IMask } from 'react-imask';

import {
  StyledContent,
  StyledErrorIcon,
  StyledIcon,
  StyledMessage,
  StyledMessageContainer,
} from './subcomponents/Input.styled';
import { RootMaskedInput } from './subcomponents/RootMaskedInput';
import { StyledInput } from './subcomponents/StyledInput';
import { StyledInputBox } from './subcomponents/StyledInputBox';
import { StyledInputContainer } from './subcomponents/StyledInputContainer';
import { StyledInputLabel } from './subcomponents/StyledInputLabel';

export interface MaskedInputProps {
  additionalContent?: React.ReactNode;
  additionalContentOnly?: boolean;
  align?: 'left' | 'center' | 'right';
  backgroundColor?: SemanticColorNames;
  disabled?: boolean;
  error?: string | boolean;
  hint?: string;
  icon?: MonochromaticIconName;
  iconAndContentOrder?: 'left' | 'right';
  id?: string;
  isBold?: boolean;
  kind?: 'simple' | 'with-spacing' | 'basic';
  label?: React.ReactNode;
  maskOptions: IMask.AnyMaskedOptions;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onChange: (numericValue: number | null, maskedValue: string | null) => void;
  onClickIcon?: (e: React.MouseEvent<any>) => void;
  onFocus?: (event: React.FocusEvent) => void;
  placeholder?: string;
  size?: 'medium' | 'large' | 'xlarge';
  type?: 'text' | 'password' | 'email' | 'hidden' | 'date';
  value?: string | number;
  warning?: string | boolean;
}

/*
 * This component contains logic + styling that handles the following:
 * - Input container
 * - Input label
 * - Input icon
 * - Input error message
 * - Input
 *
 * It has been styled to match Input.tsx, but allows for a text mask
 * to be applied to the input.
 *
 * For more information, please visit this Confluence page here:
 * https://m1finance.atlassian.net/l/cp/p92KXhCW
 */
export const MaskedInput = ({
  additionalContent,
  additionalContentOnly = false,
  align = 'left',
  backgroundColor,
  disabled,
  error,
  hint,
  icon,
  iconAndContentOrder = 'right',
  id,
  isBold = false,
  kind = 'with-spacing',
  label,
  maskOptions,
  onBlur,
  onChange,
  onClickIcon,
  onFocus,
  placeholder,
  size = 'large',
  type = 'text',
  value,
  warning,
  ...otherInputProps
}: MaskedInputProps) => {
  const [isFocused, setIsFocused] = React.useState(false);

  /*-------------------------------
      Callbacks
    -------------------------------*/
  const handleFocus = React.useCallback(
    (event: React.FocusEvent) => {
      if (!isFocused) {
        setIsFocused(true);
      }
      if (typeof onFocus === 'function') {
        onFocus(event);
      }
    },
    [isFocused, onFocus],
  );

  const handleBlur = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (isFocused) {
        setIsFocused(false);
      }
      if (typeof onBlur === 'function') {
        onBlur(e);
      }
    },
    [isFocused, onBlur],
  );

  const handleClickIcon = (e: React.MouseEvent<any>): void => {
    if (typeof onClickIcon === 'function') {
      onClickIcon(e);
    }
  };

  /*-------------------------------
      Error message
    -------------------------------*/
  const message = error || warning || hint;
  // Should the component add spacing for messaging to prevent movement of fields
  const addMessageSpacing =
    typeof error === 'string' ||
    typeof warning === 'string' ||
    typeof hint === 'string';

  /*-------------------------------
      isFilled status
    -------------------------------*/
  // Should the component act as if there's something present in the input?
  const isFilled = (): boolean => {
    return (
      isFocused ||
      Boolean(value) ||
      ['date', 'number'].includes(type) ||
      Boolean(placeholder) ||
      additionalContentOnly
    );
  };

  return (
    <StyledInputBox
      {...{
        disabled,
        hasValue: value === 0 || Boolean(value),
        hidden: type === 'hidden',
        isBold,
        kind,
        size,
        isFocused,
      }}
    >
      <StyledInputContainer
        {...{
          disabled,
          error,
          iconAndContentOrder,
          isFocused,
        }}
      >
        {!label || kind === 'basic' ? null : (
          // @ts-expect-error - TS2769 - No overload matches this call.
          <StyledInputLabel
            {...{
              htmlFor: id,
              align,
              disabled,
              error: Boolean(error),
              iconAndContentOrder,
              isFilled,
              size,
              isFocused,
              additionalContentOnly,
              backgroundColor,
            }}
          >
            {label}
          </StyledInputLabel>
        )}
        {!additionalContentOnly && (
          <RootMaskedInput
            {...{
              align,
              disabled,
              id,
              isBold,
              onBlur: handleBlur,
              placeholder,
              type,
              onFocus: handleFocus,
              size,
              error,
              backgroundColor,
              defaultValue: value,
              maskOptions,
              inputComponent: StyledInput,
              onChange,
              ...otherInputProps,
            }}
          />
        )}
        {(icon || additionalContent || error) && (
          <StyledContent
            iconAndContentOrder={iconAndContentOrder}
            p={additionalContentOnly ? '14px 16px' : undefined}
          >
            {additionalContent}
            {icon && (
              // @ts-expect-error - TS2769 - No overload matches this call.
              <StyledIcon
                iconAndContentOrder={iconAndContentOrder}
                onClick={handleClickIcon}
                value={icon}
              />
            )}
            {error && kind !== 'basic' && (
              <StyledErrorIcon iconAndContentOrder={iconAndContentOrder} />
            )}
          </StyledContent>
        )}
      </StyledInputContainer>
      {addMessageSpacing && (
        <StyledMessageContainer>
          {kind === 'with-spacing' && (
            <StyledMessage error={Boolean(error)}>
              {typeof message === 'string' && message}
            </StyledMessage>
          )}
        </StyledMessageContainer>
      )}
    </StyledInputBox>
  );
};
