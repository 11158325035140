import * as React from 'react';
import { Route } from 'react-router';

import { PersonalLoansApplicationPage } from './PersonalLoansApplicationPage';

export const PersonalLoansApplicationRoute = () => {
  return (
    <Route
      path="loan-application(/:step)"
      navigationState={{
        forceRefetch: true,
      }}
      component={PersonalLoansApplicationPage}
      fallbackRoute="landing-page"
    />
  );
};
