import { ApolloError } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  HS,
  HXS,
  PL,
  Skeleton,
  SkeletonProvider,
} from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import isNil from 'lodash-es/isNil';
import React from 'react';

import { useWizardContext } from '~/flows/wizard';
import {
  HomePageDocument,
  useCreateIraTransferMutation,
  useCreateTransferInstanceMutation,
  useSetScheduledTransferRuleMutation,
  useTransferValidationQuery,
} from '~/graphql/hooks';
import {
  AppAction,
  AppActionEnum,
  CreateIraTransferMutation,
  CreateTransferInstanceMutation,
  HomePageQueryVariables,
  SetScheduledTransferRuleMutation,
  TransferCreationDetailsFragment,
  TransferParticipantSideEnum,
} from '~/graphql/types';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { RichText } from '~/lens-toolbox/RichText/RichText';
import { clearSingleReactHookForm } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { useToast } from '~/toasts';
import { ToastProps } from '~/toolbox/toast';
import { formatCurrencyWithCommas } from '~/utils';

import { TransferLineItems } from '../components/TransferLineItems';
import { TransferValidationMessages } from '../components/TransferValidationMessages';
import { CreateTransferContext } from '../CreateTransferProvider';
import { getParticipantIconName } from '../utils/lensMappers';

import { useParticipantSummary } from '../utils/useParticipantSummary';

import { StyledTransfersCard } from './TransferDetails';

export const TransferValidationStep = () => {
  const transferContext = React.useContext(CreateTransferContext);
  const { goTo } = useWizardContext();
  const { query } = useLocation();
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    setScheduledTransferRule,
    { loading: setScheduledTransferRuleLoading },
  ] = useSetScheduledTransferRuleMutation();
  const [createTransferInstance, { loading: createTransferInstanceLoading }] =
    useCreateTransferInstanceMutation();

  const [
    createIRATransferInstance,
    { loading: createIRATransferInstanceLoading },
  ] = useCreateIraTransferMutation();

  const {
    amount,
    contributionYear,
    destinationId,
    distributionReason,
    federalWithholding,
    frequency,
    isRollOver,
    niaAmount,
    schedule,
    sourceId,
    stateWithholding,
    withholdingType,
  } = useSelector((state) => {
    return (
      state.reactHookForm['createTransferDetails'] ?? {
        amount: 0,
        frequency: 'one-time',
      }
    );
  });

  const { sourceDetails, destinationDetails } = useParticipantSummary({
    sourceId,
    destinationId,
    frequency,
    anchoredSide: TransferParticipantSideEnum.From,
  });

  const sourceAccount = sourceDetails?.account;
  const destinationAccount = destinationDetails?.account;

  const { data: validationData, error } = useTransferValidationQuery({
    fetchPolicy: 'cache-first',
    variables: {
      input: {
        amount: parseFloat(amount),
        sourceId,
        destinationId,
        iraContributionYear: !isRollOver ? contributionYear : undefined,
        federalWithholding: federalWithholding
          ? federalWithholding / 100
          : undefined,
        stateWithholding: stateWithholding ? stateWithholding / 100 : undefined,
        niaAmount: isNil(niaAmount) ? undefined : parseFloat(niaAmount),
        withholdingType,
        schedule,
      },
    },
    skip: !sourceId || !destinationId || !parseFloat(amount),
  });

  const submitting =
    createTransferInstanceLoading ||
    setScheduledTransferRuleLoading ||
    createIRATransferInstanceLoading;

  const {
    amount: actualAmountToUser,
    amountLineItems,
    ctaText,
    primaryButton,
    primaryMessages,
    secondaryButton,
    secondaryMessages,
    title,
    isLiquidation,
  } = validationData?.viewer?.transfers?.transferValidation ?? {};

  const handleConfirm = async (button: AppAction) => {
    const idempotencyKey = transferContext?.idempotencyKey;
    if (!idempotencyKey) {
      return onError(
        new ApolloError({ errorMessage: 'Failed to generate ID' }),
      );
    }
    switch (primaryButton?.type) {
      case AppActionEnum.CreateTransferInstance: {
        return createTransferInstance({
          variables: {
            input: {
              amount: parseFloat(amount),
              fromParticipantId: sourceId,
              idempotencyKey,
              isLiquidation,
              toParticipantId: destinationId,
            },
          },
          onCompleted: (data) => onCompleted(data),
          onError,
          refetchQueries: [
            {
              query: HomePageDocument,
              variables: { first: 5 } satisfies HomePageQueryVariables,
            },
          ],
        });
      }
      case AppActionEnum.CreateIraTransfer: {
        const federalWithholdingAmount = Math.abs(
          amountLineItems?.find((item) =>
            item.key.includes('federalWithholdingAmount'),
          )?.value ?? 0,
        );
        const stateWithholdingAmount = Math.abs(
          amountLineItems?.find((item) =>
            item.key.includes('stateWithholdingAmount'),
          )?.value ?? 0,
        );

        const niaAdjustedAmount = niaAmount
          ? parseFloat(niaAmount) + parseFloat(amount)
          : parseFloat(amount);

        return createIRATransferInstance({
          variables: {
            input: {
              amount: niaAdjustedAmount,
              federalWithholdingAmount,
              fromParticipantId: sourceId,
              idempotencyKey,
              iraContributionYear: contributionYear,
              iraDistributionReason: distributionReason,
              isIraRollover: isRollOver,
              isLiquidation,
              stateWithholdingAmount,
              toParticipantId: destinationId,
              niaAmount: isNil(niaAmount) ? undefined : parseFloat(niaAmount),
            },
          },
          onCompleted: (data) => onCompleted(data),
          onError,
          refetchQueries: [
            {
              query: HomePageDocument,
              variables: { first: 5 } satisfies HomePageQueryVariables,
            },
          ],
        });
      }

      case AppActionEnum.SetScheduledTransferRule:
      default: {
        return setScheduledTransferRule({
          variables: {
            input: {
              amount: parseFloat(amount),
              fromParticipantId: sourceId,
              toParticipantId: destinationId,
              schedule,
            },
          },
          onCompleted: (data) => onCompleted(data),
          onError,
        });
      }
    }
  };

  const onCompleted = (data: {
    __typename?: 'Mutation' | undefined;
    createTransferInstance?: CreateTransferInstanceMutation['createTransferInstance'];
    createIraTransfer?: CreateIraTransferMutation['createIraTransfer'];
    setScheduledTransferRule?: SetScheduledTransferRuleMutation['setScheduledTransferRule'];
  }) => {
    const {
      createTransferInstance,
      createIraTransfer,
      setScheduledTransferRule,
    } = data;

    let completedToastConfig: ToastProps = {
      content: `$${amount} should reach your account soon.`,
      kind: 'success',
    };

    if (createTransferInstance && createTransferInstance.outcome?.details) {
      completedToastConfig = formatToastConfig(
        createTransferInstance.outcome.details,
        completedToastConfig,
      );
    } else if (createIraTransfer && createIraTransfer.outcome?.details) {
      completedToastConfig = formatToastConfig(
        createIraTransfer.outcome.details,
        completedToastConfig,
      );
    } else if (
      setScheduledTransferRule &&
      setScheduledTransferRule.outcome?.details
    ) {
      completedToastConfig = formatToastConfig(
        setScheduledTransferRule.outcome.details,
        completedToastConfig,
      );
    }

    addToast(completedToastConfig);

    navigate({ to: query.previousRouteName ?? '/d/home' });
    dispatch(clearSingleReactHookForm('createTransferDetails'));
  };

  const onError = (result: ApolloError) => {
    addToast({
      content:
        result?.message ??
        'Sorry, the transfer failed to initiate. Please, try again.',
      kind: 'alert',
    });
    goTo('TRANSFER_DETAILS');
  };

  if (error) {
    onError(error);
  }

  if (!sourceAccount || !destinationAccount) {
    return null;
  }

  return (
    <StyledTransfersCard>
      <SkeletonProvider isLoading={!validationData} fadeOut>
        <HXS>{title ?? 'Review'}</HXS>
        <Box
          mt={32}
          display="grid"
          gridTemplateColumns="32px auto"
          columnGap={16}
        >
          {/* <!--- LOGO COLUMN WITH ARROW --> */}
          <Box
            display="grid"
            gridColumnStart={1}
            gridColumnEnd={2}
            gridTemplateRows="32px auto 32px"
          >
            <Box display="grid" gridRowStart={1}>
              <Skeleton width="fit-content">
                <Icon
                  name={getParticipantIconName(
                    sourceAccount.transferParticipantType,
                  )}
                />
              </Skeleton>
            </Box>
            <Box
              display="grid"
              gridRowStart={2}
              my={5}
              style={{
                transition: 'opacity 200ms ease, height 800ms ease-out',
                opacity: !validationData ? 0 : 1,
                height: validationData ? 'calc(100% - 6px)' : '25%',
              }}
            >
              {/* <!--- ARROW --> */}
              <Flex
                flexDirection="column"
                alignItems="center"
                position="relative"
                mb={20}
              >
                {/* <!--- ARROW STEM --> */}
                <Box
                  backgroundColor="foregroundNeutralTertiary"
                  width="1px"
                  margin="0 auto -10px"
                  flexGrow={1}
                />
                {/* <!--- ARROW RIGHT TAIL --> */}
                <Box
                  backgroundColor="foregroundNeutralTertiary"
                  width="1px"
                  height="10px"
                  position="absolute"
                  bottom={0}
                  css={{
                    transform: 'translateY(9px) rotate(40deg) translateX(4px)',
                  }}
                />
                {/* <!--- ARROW LEFT TAIL --> */}
                <Box
                  backgroundColor="foregroundNeutralTertiary"
                  width="1px"
                  height="10px"
                  position="absolute"
                  bottom={0}
                  css={{
                    transform:
                      'translateY(9px) rotate(-40deg) translateX(-4px)',
                  }}
                />
              </Flex>
            </Box>
            <Box display="grid" gridRowStart={3}>
              <Skeleton width="fit-content">
                <Icon
                  name={getParticipantIconName(
                    destinationAccount.transferParticipantType,
                  )}
                />
              </Skeleton>
            </Box>
          </Box>
          {/* <!----- CONTENT COLUMN ------> */}
          <Box display="grid" gridColumnStart={2} gridColumnEnd={3}>
            <Flex flexDirection="column" justifyContent="center" height={32}>
              <Skeleton width="fit-content">
                <PL>{sourceAccount?.transferParticipantName}</PL>
              </Skeleton>
            </Flex>
            <Skeleton width="100%">
              <Box
                style={{
                  transition:
                    'height 500ms ease-out, transform 500ms ease-out, opacity 500ms ease-out',
                  height: validationData ? 'calc(100% - 6px)' : '25%',
                  opacity: validationData ? 1 : 0,
                  transform: `translateY(${validationData ? 0 : '-1em'})`,
                }}
              >
                {/* <!----- AMOUNT ------> */}
                {actualAmountToUser && (
                  <HS
                    mt={8}
                  >{`$${formatCurrencyWithCommas(actualAmountToUser)}`}</HS>
                )}

                {/* <!----- PRIMARY MESSAGES ------> */}
                {primaryMessages && (
                  <TransferValidationMessages
                    {...{
                      isScheduledTransfer: Boolean(schedule),
                      messages: primaryMessages,
                      font: 'PL',
                      color: 'foregroundNeutralMain',
                    }}
                  />
                )}

                {/* <!----- AMOUNT LINE ITEMS ------> */}
                {amountLineItems && (
                  <TransferLineItems items={amountLineItems} />
                )}

                {/* <!----- SECONDARY MESSAGES ------> */}
                {secondaryMessages && (
                  <TransferValidationMessages
                    {...{
                      messages: secondaryMessages,
                      font: 'PM',
                      color: 'foregroundNeutralSecondary',
                      messageGap: 20,
                    }}
                  />
                )}
              </Box>
            </Skeleton>

            <Flex
              flexDirection="column"
              justifyContent="center"
              height={32}
              mt={32}
            >
              <Skeleton width="fit-content">
                <PL>{destinationAccount?.transferParticipantName}</PL>
              </Skeleton>
            </Flex>
          </Box>
        </Box>

        <Box mt={40}>
          {/* <!----- CTA TEXT ------> */}
          {ctaText && (
            <RichText
              richText={ctaText}
              textProps={{ font: 'PS', color: 'foregroundNeutralMain' }}
              linkProps={{
                font: 'PS',
                fontWeight: 400,
                pr: 4,
              }}
              containerProps={{
                display: 'inline-block',
                textAlign: 'center',
                mb: 16,
              }}
            />
          )}

          {/* <!----- PRIMARY & SECONDARY BUTTONS ------> */}
          <Flex gap={16}>
            <Skeleton flexGrow={1}>
              <Button
                label={secondaryButton?.label ?? 'Back'}
                kind="secondary"
                fullWidth
                disabled={submitting}
                onClick={() => {
                  goTo('TRANSFER_DETAILS');
                }}
              />
            </Skeleton>
            <Skeleton flexGrow={1}>
              <Button
                label={
                  submitting
                    ? 'Creating...'
                    : (primaryButton?.title ?? 'Confirm')
                }
                fullWidth
                disabled={submitting}
                onClick={primaryButton && (() => handleConfirm(primaryButton))}
              />
            </Skeleton>
          </Flex>
        </Box>
      </SkeletonProvider>
    </StyledTransfersCard>
  );
};

const formatToastConfig = (
  details: TransferCreationDetailsFragment,
  completedToastConfig: ToastProps,
) => {
  completedToastConfig.content =
    details.description ?? completedToastConfig.content;
  if (details.detailsLink?.internalPath) {
    completedToastConfig.link = {
      to: details.detailsLink.internalPath,
      children: 'View details',
    };
  }
  return completedToastConfig;
};
