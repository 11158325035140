import get from 'lodash-es/get';
import isPlainObject from 'lodash-es/isPlainObject';
import mapValues from 'lodash-es/mapValues';
import pickBy from 'lodash-es/pickBy';
import set from 'lodash-es/set';

export function pickByPaths(
  source: Record<string, any>,
  paths: Array<string | [string, string]>,
): Record<string, any> {
  const obj = {};
  for (const path of paths) {
    if (Array.isArray(path)) {
      const [destinationPath, sourcePath] = path;
      set(obj, destinationPath, get(source, sourcePath));
    } else {
      set(obj, path, get(source, path));
    }
  }
  return obj;
}

export function deepPickBy(
  obj: Record<string, any>,
  predicate: (...args: Array<any>) => any,
): Record<string, any> {
  const _obj = mapValues(obj, (v) => {
    if (Array.isArray(v)) {
      return v.map((item) => {
        return isPlainObject(item) ? deepPickBy(item, predicate) : item;
      });
    }

    if (isPlainObject(v)) {
      return deepPickBy(v, predicate);
    }

    return v;
  });

  return pickBy(_obj, predicate);
}
