import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import { PendingLiquidationRoute } from './pending-liquidation';
import { UnmanagedHoldingsPage } from './UnmanagedHoldingsPage';

export const UnmanagedHoldingsRoute = () => {
  return (
    <Route path="unmanaged-holdings">
      {PendingLiquidationRoute()}

      <IndexRoute component={UnmanagedHoldingsPage} />
    </Route>
  );
};
