import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  ArchiveTransferRuleDocument,
  SetSmartTransferRuleDocument,
  TransfersRulesPageDocument,
} from '~/graphql/hooks';
import {
  ACTION_TYPES as ACTIONS,
  hideLoadingSpinner,
  navigate,
  showLoadingSpinner,
} from '~/redux/actions';

import { FinishedEditingSmartTransferAction } from '../../actions/smartTransfer/smartTransferActions.types';
import { apolloMutationSaga } from '../apolloMutationSaga';

export function* editSmartTransferMonitor(): SagaIterator<void> {
  yield takeEvery(
    ACTIONS.FINISHED_EDITING_SMART_TRANSFER,
    function* (action: FinishedEditingSmartTransferAction): SagaIterator<void> {
      let Mutation;
      let message;
      let input;
      if (typeof action.payload === 'string') {
        // Archive rule
        Mutation = ArchiveTransferRuleDocument;
        message = 'Smart Transfer deleted.';
        input = {
          transferRuleId: action.payload,
        };
      } else {
        // Edit rule
        Mutation = SetSmartTransferRuleDocument;
        message = 'Smart Transfer edited.';
        input = action.payload;
      }

      try {
        yield put(showLoadingSpinner());
        yield call(apolloMutationSaga, {
          mutation: Mutation,
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: TransfersRulesPageDocument,
            },
          ],
        });
        yield put({
          payload: {
            content: message,
            kind: 'success',
          },
          type: 'ADD_TOAST',
        });
        yield put(navigate({ to: '/d/transfers/rules' }));
      } catch (e: any) {
        message = e.message;

        yield put({
          payload: {
            content: message,
            kind: 'alert',
          },
          type: 'ADD_TOAST',
        });
      } finally {
        yield put(hideLoadingSpinner());
      }
    },
  );
}
