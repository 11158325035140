import * as React from 'react';

import { makeFlowComponent } from '~/flows/components/utils';
import { beginRegisterUserFlow } from '~/redux/actions';
import { REGISTER_USER_FLOW_STEPS as STEPS } from '~/static-constants';

import { PromotionSignupPage } from '../PromotionSignupPage';

import { PromotionSignUpOutcomePage } from './PromotionSignupOutcomePage';

export const RegisterUserPromotionFlow = makeFlowComponent({
  name: 'registerUser',
  begin: beginRegisterUserFlow,
  stepElements: {
    [STEPS.COLLECT_USER_DETAILS]: <PromotionSignupPage />,
    // @ts-expect-error - // TODO: How to type check props of the factory-created component?
    [STEPS.PROMOTION_SIGNUP_OUTCOME]: <PromotionSignUpOutcomePage />,
  },
});
