import * as React from 'react';

import { AccountChallengeForm } from '~/forms';

type Props = {
  onFinishStep: (...args: Array<any>) => any;
};

export class AccountChallengeStep extends React.Component<Props> {
  render() {
    return <AccountChallengeForm onSubmit={this.props.onFinishStep} />;
  }
}
