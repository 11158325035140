import { styled, Box, PS, useTheme } from '@m1/liquid-react';
import React from 'react';

import {
  CurrencyInputProps,
  DEFAULT_MAX_LENGTH,
  DEFAULT_SCALE,
  createMaskOptions,
} from '~/forms/fields/CurrencyInput';

import { RootMaskedInput } from '../Input/subcomponents/RootMaskedInput';

export type AmountInputProps = CurrencyInputProps;

/*
 * We need to use forwardRef to avoid a console error caused by react-hook-form
 * forwarding the ref to the component. In this case, we ignore the forwarded ref,
 * because we _always_ need a ref and the forwarded ref may be undefined.
 */
export const AmountInput = React.forwardRef(
  (
    {
      allowNegative = false,
      maxLength = DEFAULT_MAX_LENGTH,
      scale = DEFAULT_SCALE,
      value,
      onChange,
      error,
      ...rest
    }: AmountInputProps,
    _ignoredRef,
  ) => {
    // Initialize input mask:
    const maskOptions = React.useMemo(
      () => createMaskOptions({ maxLength, allowNegative, scale }),
      [allowNegative],
    );

    const theme = useTheme();

    const color = error
      ? theme.colors.critical
      : theme.colors.foregroundNeutralMain;

    // We add some extra handling here for negative values:
    const handleChange = (
      value: string | number | null,
      maskedValue: string | null,
    ) => {
      if (maskedValue && maskedValue.includes('-')) {
        if (allowNegative) {
          return onChange?.(Number(value) * -1);
        }
      }
      return onChange?.(value);
    };

    return (
      <Box>
        <Box
          css={{
            ...theme.typography.HXL,
            backgroundColor: 'transparent',
            border: 0,
            color: color,
            fontSize: '52px',
            fontWeight: 400,
            textAlign: 'center',
            transition: 'color 0.3s ease-in-out',
            '& input': {
              backgroundColor: 'transparent',
            },
            '& input::placeholder': {
              color: color,
              transition: 'color 0.3s ease-in-out',
            },
          }}
        >
          <RootMaskedInput
            {...{
              ...rest,
              maskOptions,
              value: typeof value === 'string' ? value : String(value),
              onChange: handleChange,
              inputComponent: StyledInput,
            }}
          />
        </Box>
        <Box height={16} textAlign="center">
          {error && <PS color="critical" content={error} />}
        </Box>
      </Box>
    );
  },
);

export const StyledInput = styled.input<any>`
  width: 100%;
  text-align: center;
`;
