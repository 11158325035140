import * as React from 'react';
import { Route } from 'react-router';

import { SaveMarketingRoute } from '~/pages/save/marketing/SaveMarketingRoute';
import { SavePage } from '~/pages/save/SavePage';
import { createRouteComponent } from '~/router';

import { SavingsRoute } from './savings';

export const SaveRoute = () => {
  return (
    <Route path="save" component={createRouteComponent(SavePage)}>
      {SavingsRoute()}
      {SaveMarketingRoute()}
    </Route>
  );
};
