import { Box, Button, Flex, HM, HXXS, PL } from '@m1/liquid-react';
import * as React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { BackArrow } from '~/components/BackArrow';
import { ControlledInput } from '~/components/form/ControlledInput';
import { useOpenInvestJointAccountWizardContext } from '~/flows/components/OpenInvestJointAccountWizard/hooks/useOpenInvestJointAccountWizardContext';
import { FormMockerReactHookForm } from '~/forms/FormMockers/FormMockerReactHookForm';
import { phoneNumber as phoneNumberValidator } from '~/forms/validators';
import { submitReactFormData } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { FIELD_MAX_LENGTHS } from '~/static-constants';
import { Dropdown } from '~/toolbox/Dropdown';
import { formatPhoneNumber, getEnumEntries } from '~/utils';

export interface ContactInfoFormValues {
  firstName: string;
  lastName: string;
  middleInitial?: string;
  suffix?: string;
  homeAddress: {
    lineOne: string;
    lineTwo?: string;
    city: string;
    stateOrProvince: string;
    postalCode: string;
  };
  phoneNumber: string;
}
export const ContactInfo = () => {
  const submittedFormValues = useSelector((state) => {
    if (!state.reactHookForm['openJointInvestAccountContactInfo']) {
      return {
        firstName: undefined,
        lastName: undefined,
        middleInitial: undefined,
        suffix: undefined,
        homeAddress: {
          lineOne: undefined,
          lineTwo: undefined,
          city: undefined,
          stateOrProvince: undefined,
          postalCode: undefined,
        },
        phoneNumber: undefined,
      };
    }

    return state.reactHookForm['openJointInvestAccountContactInfo'];
  });

  const { control, handleSubmit, watch, register, setValue, formState } =
    useForm<ContactInfoFormValues>({
      defaultValues: submittedFormValues,
    });

  const wiz = useOpenInvestJointAccountWizardContext();

  const suffix = watch('suffix');
  const address = watch('homeAddress');
  const dispatch = useDispatch();

  const suffixSource = [
    {
      description: 'None',
      name: '__NONE__',
    },
    ...getEnumEntries('NameSuffixEnum'),
  ];

  const onSubmit: SubmitHandler<ContactInfoFormValues> = (data) => {
    dispatch(
      submitReactFormData({
        openJointInvestAccountContactInfo: data,
      }),
    );

    wiz.next();
  };
  return (
    <Flex maxWidth={888} flexDirection="column" mx="auto">
      <Box pt={32}>
        <BackArrow
          content="Back"
          onClick={() => {
            window.history.back();
          }}
        />
      </Box>
      <HM mt={32} textAlign="center">
        Secondary applicant information
      </HM>
      <PL py={32}>
        Joint investment accounts have both a primary and secondary account
        holder. Please input the details of the <b>secondary account holder</b>{' '}
        below.
      </PL>
      <form name="joint-account-contact-info" onSubmit={handleSubmit(onSubmit)}>
        <HXXS content="Legal name" fontWeight={600} mb={12} />
        <Flex>
          <Box width="60%">
            <ControlledInput
              name="firstName"
              rules={{
                required: true,
              }}
              control={control}
              label="First Name"
              maxLength={FIELD_MAX_LENGTHS.FIRST_NAME}
              backgroundColor="backgroundNeutralMain"
            />
          </Box>
          <Box width="40%" pl={16}>
            <ControlledInput
              name="middleInitial"
              rules={{
                required: false,
              }}
              control={control}
              label="M.I. (Opt.)"
              maxLength={FIELD_MAX_LENGTHS.MIDDLE_INITIAL}
              backgroundColor="backgroundNeutralMain"
            />
          </Box>
        </Flex>
        <Flex>
          <Box width="60%">
            <ControlledInput
              name="lastName"
              rules={{
                required: true,
              }}
              control={control}
              label="Last name"
              maxLength={FIELD_MAX_LENGTHS.LAST_NAME}
              backgroundColor="backgroundNeutralMain"
            />
          </Box>
          <Box width="40%" pl={16}>
            <Dropdown
              {...register('suffix', { required: false })}
              name="suffix"
              label="Suffix"
              onChange={(value) => {
                if (value === '__NONE__') {
                  setValue('suffix', undefined);
                } else {
                  setValue('suffix', value);
                }
              }}
              source={suffixSource}
              value={suffix}
              backgroundColor="backgroundNeutralMain"
            />
          </Box>
        </Flex>
        <HXXS content="Address" fontWeight={600} mt={40} mb={12} />
        <Flex>
          <Box width="60%">
            <ControlledInput
              name="homeAddress.lineOne"
              rules={{
                required: true,
              }}
              control={control}
              label="Address"
              maxLength={FIELD_MAX_LENGTHS.ADDRESS_LINE_ONE}
              backgroundColor="backgroundNeutralMain"
            />
          </Box>
          <Box width="40%" pl={16}>
            <ControlledInput
              name="homeAddress.lineTwo"
              rules={{
                required: false,
              }}
              control={control}
              label="Apt, Fl, Suite (Opt.)"
              maxLength={FIELD_MAX_LENGTHS.ADDRESS_LINE_TWO}
              backgroundColor="backgroundNeutralMain"
            />
          </Box>
        </Flex>
        <Flex>
          <Box width="60%">
            <ControlledInput
              name="homeAddress.city"
              rules={{
                required: true,
              }}
              control={control}
              label="City"
              maxLength={FIELD_MAX_LENGTHS.CITY}
              backgroundColor="backgroundNeutralMain"
            />
          </Box>
          <Box width="40%" pl={16}>
            <Dropdown
              {...register('homeAddress.stateOrProvince', {
                required: true,
              })}
              name="homeAddress.stateOrProvince"
              label="State"
              onChange={(value) => {
                setValue('homeAddress.stateOrProvince', value);
              }}
              source={getEnumEntries('MailingAddressSubdivisionEnum')}
              value={address?.stateOrProvince}
              backgroundColor="backgroundNeutralMain"
            />
          </Box>
        </Flex>
        <Flex>
          <Box width="60%">
            <ControlledInput
              name="homeAddress.postalCode"
              label="Postal code"
              rules={{
                required: true,
                maxLength: 5,
              }}
              control={control}
              backgroundColor="backgroundNeutralMain"
            />
          </Box>
          <Box width="40%" pl={16}>
            <ControlledInput
              name="phoneNumber"
              label="Phone number"
              rules={{
                required: true,
                validate: (value) => {
                  if (!value) {
                    return undefined;
                  }

                  const errorMessage = phoneNumberValidator(value);
                  return errorMessage ?? undefined;
                },
                maxLength: 14,
              }}
              control={control}
              transformInput={(value) => formatPhoneNumber(value)}
              backgroundColor="backgroundNeutralMain"
              error={formState.errors.phoneNumber?.message}
            />
          </Box>
        </Flex>
        <Button
          mt={32}
          type="submit"
          size="large"
          label="Continue"
          kind="primary"
        />
        <FormMockerReactHookForm
          setValue={setValue}
          fields={[
            {
              name: 'firstName',
              value: 'B0b',
            },
            {
              name: 'middleInitial',
              value: 'N',
            },
            {
              name: 'lastName',
              value: 'R0ss',
            },
            {
              name: 'homeAddress.lineOne',
              value: '123 Main St',
            },
            {
              name: 'homeAddress.lineTwo',
              value: 'Unit A',
            },
            {
              name: 'homeAddress.city',
              value: 'Chicago',
            },
            {
              name: 'homeAddress.city',
              value: 'Chicago',
            },
            {
              name: 'homeAddress.stateOrProvince',
              value: 'IL',
            },
            {
              name: 'homeAddress.postalCode',
              value: '60618',
            },
            {
              name: 'phoneNumber',
              value: '(630) 555-1234',
            },
          ]}
        />
      </form>
    </Flex>
  );
};
