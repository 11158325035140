import * as React from 'react';
import { Route } from 'react-router';

import { DisclosuresForPromotionsPage } from './DisclosuresForPromotionsPage';

export const DisclosuresForPromotionsRoute = () => {
  return (
    <Route
      path="promotions-disclosures"
      component={DisclosuresForPromotionsPage}
    />
  );
};
