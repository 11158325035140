import { SagaIterator } from 'redux-saga';
import {
  call,
  put,
  select,
  setContext,
  spawn,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  CreateTransferInstanceDocument,
  CreateTransferInstanceMutationResult,
  CreateTransferInstanceRefetchDocument,
  SetScheduledTransferRuleDocument,
  SetScheduledTransferRuleRefetchDocument,
} from '~/graphql/hooks';
import {
  CreateTransferInstanceInput,
  SetScheduledTransferRuleInput,
} from '~/graphql/types';

import {
  ACTION_TYPES as ACTIONS,
  hideLoadingSpinner,
  navigate,
  showLoadingSpinner,
} from '~/redux/actions';
import { apolloMutationSaga } from '~/redux/sagas/apolloMutationSaga';
import { showFailureToast } from '~/redux/sagas/open-invest-account/helpers';
import { INITIAL_FUNDING_FLOW_STEPS as STEPS } from '~/static-constants';

import { hasFundingSourceSaga, hasUserOnboarded } from '../../../common';
import { changeStep, logDepositFundingEvents } from '../../utils';

export function* initialFundingSaga(): SagaIterator<void> {
  yield takeLatest(ACTIONS.BEGIN_INITIAL_FUNDING_FLOW, beginInitialFundingFlow);
}

function* beginInitialFundingFlow(action: any): SagaIterator<void> {
  const { basePath, onFinish } = action.payload;
  yield setContext({
    basePath,
  });
  yield takeEvery(
    ACTIONS.FINISHED_BEGIN_INITIAL_FUNDING,
    finishedBeginInitialFunding,
    // @ts-expect-error - TS2554 - Expected 2 arguments, but got 3.
    onFinish,
  );
  yield takeEvery(
    ACTIONS.FINISHED_INITIAL_BANK_CONNECTION,
    finishedInitialBankConnection,
    onFinish,
  );
  yield takeEvery(
    ACTIONS.SUBMITTED_INITIAL_DEPOSIT_FORM,
    finishedInitialDeposit,
  );
  yield takeEvery(
    ACTIONS.READ_INITIAL_DEPOSIT_FEEDBACK,
    finishedReadingInitialDepositFeedback,
    onFinish,
  );
  yield takeEvery(
    ACTIONS.INITIAL_FUNDING_DEPOSIT_CONFIRMATION,
    handleInitialFundingConfirmationDeposit,
  );
  yield takeEvery(
    ACTIONS.FUNDED_DEPOSIT_CONFIRMATION,
    handleFundedDepositConfirmation,
  );

  yield takeEvery(ACTIONS.SKIPPED_ONBOARDING_DEPOSIT, skippedOnboardingDeposit);
  // TODO follow up on exact flow considered here
  yield takeEvery(ACTIONS.SKIPPED_BANK_SETUP, skippedOnboardingDeposit);
}

function* skippedOnboardingDeposit(): SagaIterator<void> {
  yield call(changeStep, STEPS.SKIPPED_DEPOSIT_CONFIRMATION);
}

function* finishedBeginInitialFunding(): SagaIterator<void> {
  const userHasOnboarded = yield call(hasUserOnboarded);
  const hasFundingSource = yield call(hasFundingSourceSaga);

  // When signing up for invest (onboarded) but the user already has a connected bank (hasFundingSources)
  // send them directly to the deposit step instead
  if (userHasOnboarded && hasFundingSource) {
    yield call(changeStep, STEPS.MAKE_INITIAL_DEPOSIT);
  } else {
    yield call(changeStep, STEPS.CONNECT_EXTERNAL_BANK);
  }
}

function* finishedInitialBankConnection(
  onFinish: (...args: Array<any>) => any,
  action: any,
): SagaIterator<void> {
  const { achRelationshipId } = action.payload;

  // achRelationshipId can be missing for two reasons:
  // - Bank connection failed for whatever reason.
  // - User purposefully skipped this step.
  if (achRelationshipId) {
    yield call(changeStep, STEPS.MAKE_INITIAL_DEPOSIT);
  } else {
    yield call(onFinish);
  }
}

function* finishedInitialDeposit(action: any): SagaIterator<void> {
  if (action.payload.isOneTimeDeposit) {
    yield call(handleOneTimeDeposit, action);
  } else {
    yield call(handleRecurringDeposits, action);
  }
  yield call(changeStep, STEPS.FUNDED_DEPOSIT_CONFIRMATION);
}

function* handleRecurringDeposits(action: any): SagaIterator<void> {
  const input = action.payload;
  const { accountId, achRelationshipId, fundingType } = yield select(
    (state) => state.newFlows.initialFunding,
  );

  const { amount, schedule, iraContributionYear } = input;

  try {
    yield put(showLoadingSpinner());

    const { data }: CreateTransferInstanceMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: CreateTransferInstanceDocument,
        variables: {
          input: {
            toParticipantId: accountId,
            fromParticipantId: achRelationshipId,
            amount,
            iraContributionYear,
          } satisfies CreateTransferInstanceInput,
        },
        refetchQueries: [{ query: CreateTransferInstanceRefetchDocument }],
      },
    );

    yield call(apolloMutationSaga, {
      mutation: SetScheduledTransferRuleDocument,
      variables: {
        input: {
          toParticipantId: accountId,
          fromParticipantId: achRelationshipId,
          amount,
          schedule,
        } as SetScheduledTransferRuleInput,
      },
      refetchQueries: [
        {
          query: SetScheduledTransferRuleRefetchDocument,
        },
      ],
    });

    yield put({
      type: ACTIONS.INITIAL_DEPOSIT_MUTATION_COMPLETED,
      payload: data?.createTransferInstance?.outcome?.instance?.id,
    });
    yield call(changeStep, STEPS.SHOW_FEEDBACK_AFTER_DEPOSIT);
    yield spawn(logDepositFundingEvents, {
      accountId,
      achRelationshipId,
      amount: input.amount,
      isSchedule: false,
      fundingType,
    });
  } catch (e: any) {
    yield put({
      type: ACTIONS.INITIAL_DEPOSIT_MUTATION_FAILED,
      payload: e.message,
    });

    yield put(
      yield call(
        showFailureToast,
        e.message ??
          'We were unable to complete your transfer. Please contact Client Support',
      ),
    );
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* handleOneTimeDeposit(action: any): SagaIterator<void> {
  const input = action.payload;
  const { accountId, achRelationshipId, fundingType } = yield select(
    (state) => state.newFlows.initialFunding,
  );

  try {
    yield put(showLoadingSpinner());

    const { data }: CreateTransferInstanceMutationResult = yield call(
      apolloMutationSaga,
      {
        mutation: CreateTransferInstanceDocument,
        variables: {
          input: {
            toParticipantId: accountId,
            fromParticipantId: achRelationshipId,
            amount: input.amount,
            isIraRollover: input.isIraRollover,
            iraContributionYear: input.iraContributionYear,
          } satisfies CreateTransferInstanceInput,
        },
      },
    );

    yield put({
      type: ACTIONS.INITIAL_DEPOSIT_MUTATION_COMPLETED,
      payload: data?.createTransferInstance?.outcome?.instance?.id,
    });
    yield call(changeStep, STEPS.SHOW_FEEDBACK_AFTER_DEPOSIT);
    yield spawn(logDepositFundingEvents, {
      accountId,
      achRelationshipId,
      amount: input.amount,
      isSchedule: false,
      fundingType,
    });
  } catch (e: any) {
    yield put({
      type: ACTIONS.INITIAL_DEPOSIT_MUTATION_FAILED,
      payload: e.message,
    });

    yield put(
      yield call(
        showFailureToast,
        e.message ??
          'We were unable to complete your transfer. Please contact Client Support',
      ),
    );
  } finally {
    yield put(hideLoadingSpinner());
  }
}

function* finishedReadingInitialDepositFeedback(
  onFinish: (...args: Array<any>) => any,
): SagaIterator<void> {
  yield call(onFinish);
}

function* handleFundedDepositConfirmation(): SagaIterator<void> {
  yield put(navigate({ to: '/d/invest/portfolio-organizer' }));
}

function* handleCallbackUrl(callbackUrl: string): SagaIterator<void> {
  if (callbackUrl.includes('portfolio-organizer')) {
    yield put(
      navigate({
        to: callbackUrl,
        options: {
          state: {
            event: {
              initConfig: {
                confirmationDialog: {
                  showApplicableLocations: false,
                },
                mode: 'NEW_ROOT_PIE',
                returnTo: '/d/invest',
                sliceableIds: [],
              },
              type: 'INITIALIZE_ORGANIZER',
            },
          },
        },
      }),
    );
  } else {
    yield put(navigate({ to: callbackUrl }));
  }
}

function* handleInitialFundingConfirmationDeposit(
  action: any,
): SagaIterator<void> {
  const { callbackUrl } = action.payload;
  yield put({
    type: ACTIONS.STORE_CALLBACK_URL,
    payload: action.payload.callbackUrl,
  });
  yield call(handleCallbackUrl, callbackUrl);
}
