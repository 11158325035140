import moment from 'moment';
import * as React from 'react';

import { Input, InputProps } from '~/toolbox/Input';

type DateInputProps = InputProps & {
  // Can be expanded to support more formats in the future
  format?: 'MM-DD-YYYY';
  onDateChange?: (date: string) => void;
};

export const DateInput = ({
  value,
  onDateChange,
  format = 'MM-DD-YYYY',
  max = '9999-12-31',
  ...props
}: DateInputProps) => {
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onDateChange) {
      const date = moment(event.target.value, 'YYYY-MM-DD').format(format);
      onDateChange(date);
    }

    props.onChange && props.onChange(event);
  };

  // Works on modern browsers, displays a regular text input on IE
  return (
    <Input
      {...props}
      autoComplete="off"
      value={moment(value, format).format('YYYY-MM-DD')}
      type="date"
      max={max}
      onChange={handleDateChange}
    />
  );
};
