// reduceReducers
// Takes an array of reducer functions, applying each one in turn and returning
// the result. Use case is for if you need multiple reducer functions to act on
// the same state key. Returns the resultant state shape.
//
// {
//   "someKey": reduceReducers(firstReducerFunction, secondReducerFunction)
// }

export function reduceReducers(
  ...reducers: Array<(...args: Array<any>) => any>
): (...args: Array<any>) => any {
  return function (
    previousState: Record<string, any>,
    action: any,
  ): Record<string, any> {
    return reducers.reduce(
      // Flow complains about the arrow syntax, so it's either
      // Flow or eslint here.

      /* eslint-disable prefer-arrow-callback */
      function (
        previousState: Record<string, any>,
        reducer: (...args: Array<any>) => any,
      ): Record<string, any> {
        return reducer(previousState, action);
      },
      previousState,
    );
  };
}
