import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { WaitlistPage } from './WaitlistPage';

export const WaitlistRoute = () => {
  return (
    <Route path="waitlist" component={createRouteComponent(WaitlistPage)} />
  );
};
