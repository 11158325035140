import { globalColors, useTheme, useThemeMode, Maybe } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import Highcharts, {
  ExportingMenuObject,
  SeriesLineOptions,
} from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import capitalize from 'lodash-es/capitalize';
import moment from 'moment-timezone';
import * as React from 'react';
import { renderToString } from 'react-dom/server';

import { useMediaQuery } from 'react-responsive';

import { useChartableSliceChartQuery } from '~/graphql/hooks';
import { ChartableSliceChartQuery, SeriesTypeEnum } from '~/graphql/types';

import { useAnalytics } from '~/hooks/useAnalytics';
import { useStableReference } from '~/hooks/useStableReference';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';
import { CompactCashFormatter, CashFormatter } from '~/utils';

import { roundToDecimal } from '~/utils/round';

import { StockChart } from '../../components/charts';

import { CHART_DATUM_KEY } from '../charts/StockChart/StockChart';

import { HighchartsStyles } from './ChartableSliceChart.styled';
import {
  ChartableSliceChartFeatures,
  SeriesTypes,
  ChartableSliceDatumPoint,
  ChartableSliceChartProps,
  ChartableSliceChartPeriodDuration,
  ChartableSliceSeries,
  ChartKeys,
} from './ChartableSliceChart.types';
import {
  periodDurationToPeriodEnum,
  periodDurationToRangeSelectorButton,
  sumDividends,
  sumTrades,
} from './ChartableSliceChart.utils';
import { ChartSkeleton } from './ChartSkeleton';
import { useChartableSliceChartContext } from './hooks/ChartableSliceChartContext';

type ChartablSliceNode = ExtractTypenameByKey<
  NonNullable<ChartableSliceChartQuery['nodes']>[number],
  'chartData'
>;

/**
 * A chart for displaying time series data for securities, pies, and portfolios.
 * This is a reusable component for all ChartableSlices which will eventually replace every single one of our charts of security/pie/portfolio time series data.
 *
 * This component takes in an array of chartableSlice IDs, fetches the data for each chartableSlice, and charts them on top of each other.
 *
 * For example, adding the key 'candleSticks' to the features prop will display a button to toggle candlesticks on and off. Without adding that key, the button will not show.
 *
 * - `chartableSliceIds` - An array of IDs which this component fetches the data for each chartableSlice by node ID, and charts them on top of each other. Useful for comparisons or benchmarking.
 * - `defaultPeriod` - The period for the initial query of data. Usually you want to keep this as large of a time period as possible, because Highcharts can only display up to that amount of data.
 * Rather than requery for each time period change, Highcharts "zooms" in on the existing data, hence why you want to initially load as much data as possible.
 * - `periods` - An array of time periods which this component will display as clickable buttons. The chart will animate and zoom to the clicked time period. If you add too many for the width of the chart, the time periods will convert to a dropdown.
 *   - `ChartableSliceChartPeriodDuration` is a type which is a union of all the possible time periods. You can use this type to ensure you are passing in valid time periods that Highcharts supports.
 *     - `d` = days, `w` = weeks, `M` = months, `y` = years - these all allow numbers, ex. `1d`, `2w`, `3M`, `4y`
 *       - `M` is capitalized because Moment uses `m` for minutes, and we need to use the same format for Moment functions
 *     - `ytd` = year to date, `all` = all time - neither of these allow numbers
 * - `features` - An object of features to enable/disable. Adding a key for a feature will display the button, and the boolean value will be if the feature is initially turned on or off
 * - `onRangeSelectionChange` - A callback for when the time period changes, and includes data which can be useful to display elsewhere, such as the percent change between the min and max shown dates.
 * - `chartName` - The name of the chart, which is ONLY used for analytics, do not use to change any component conditions.
 */
export const ChartableSliceChart = ({
  chartableSliceIds,
  chartName,
  defaultPeriod: defaultPeriodProp,
  features = { navigator: true, dateRangeInputs: true, print: false },
  isOnDarkBackground = false,
  isPieGraphSection = false,
  initialValueForBacktesting = 100,
  onChangePeriod,
  onRangeSelectionChange,
  periods = ['1w', '1M', '3M', '6M', 'ytd', '1y', '5y', 'all'],
  plotLines,
  height,
  ...rest
}: ChartableSliceChartProps) => {
  const liveDatum = rest.liveDatum;
  const shouldHaveLiveDatum = Object.keys(rest).includes('liveDatum');
  // If live datum is not a provided key in the props, we use default values for
  // these so we don't have to wait for a non-existent live datum below.
  const liveDatumLoading = shouldHaveLiveDatum && !liveDatum;
  const liveDatumReady = shouldHaveLiveDatum ? Boolean(liveDatum) : true;
  const rangeSelectionKey = `${chartName}_range_selection`;
  const getDefaultPeriod = React.useCallback(
    () => {
      return (localStorage.getItem(rangeSelectionKey) ??
        defaultPeriodProp ??
        'all') as ChartableSliceChartPeriodDuration;
    },
    [defaultPeriodProp, rangeSelectionKey], // We don't want this to be a controlled component, so only process default period once
  );
  const [featureState, setFeatureState] =
    React.useState<ChartableSliceChartFeatures>(features);
  const [hasEmittedLatestChartData, setHasEmittedLatestChartData] =
    React.useState(false);
  const theme = useTheme();
  const chartRef = React.useRef<HighchartsReact.RefObject>(null);
  const { setisChartDataNull } = useChartableSliceChartContext();
  // This is used for the time-range selector buttons (they collapse on mobile devices only)
  const isMobile = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.SMALL,
  });

  const { data, loading } = useChartableSliceChartQuery({
    variables: {
      ids: chartableSliceIds.filter(Boolean) as string[],
      initialValueForBacktesting,
    },
  });

  const currentPeriod =
    React.useRef<ChartableSliceChartPeriodDuration>(getDefaultPeriod());

  const { activeThemeMode } = useThemeMode();
  const indexOfThemeInColorsArray = Number(activeThemeMode === 'dark');

  const analytics = useAnalytics();
  const visiblePoints = React.useRef<Maybe<Highcharts.Series[]>>(null);
  // Memoize simple input objects that are used in dependency arrays.
  const memoizedFeatures = useStableReference(features);
  const memoizedPeriods = useStableReference(periods);

  React.useEffect(() => {
    if (chartRef.current) {
      const { chart } = chartRef.current;
      if (loading || liveDatumLoading) {
        // @ts-ignore-error no type exported for this module's function
        chart?.hideNoData();
        chart?.showLoading();
      } else if (!loading && !liveDatumLoading) {
        chart?.hideLoading();
        if (chart?.series.length === 0) {
          // @ts-ignore-error no type exported for this module's function
          chart?.showNoData();
          setisChartDataNull(true);
        }
      }
    }
  }, [loading, setisChartDataNull, liveDatumLoading]);

  const updateFeatureState = (property: keyof ChartableSliceChartFeatures) => {
    setFeatureState((prevState) => ({
      ...prevState,
      [property]: !prevState[property],
    }));
  };
  const supportsFeature = React.useCallback(
    (property: keyof ChartableSliceChartFeatures) => {
      // Some features have options that enable partial support (e.g. navigator can be toggled or disabled entirely)
      return property in memoizedFeatures;
    },
    [memoizedFeatures],
  );

  const isFeatureEnabled = React.useCallback(
    (property: keyof ChartableSliceChartFeatures) => {
      return Boolean(supportsFeature(property) && featureState[property]);
    },
    [featureState, supportsFeature],
  );

  // Potential mechanism for adding feature toggles to the exporting chart
  const menuItems = React.useMemo(() => {
    const menuItems: Array<ExportingMenuObject | string> = [
      'viewFullscreen',
      ...(isFeatureEnabled('print') ? ['printChart'] : []),
      'separator',
      'downloadCSV',
      'downloadXLS',
      // 'separator',
      // TODO - leaving out these options - background color is black when exporting
      // Possible solutions: https://stackoverflow.com/a/45966131 https://stackoverflow.com/a/18055949
      // 'separator',
      // 'downloadPNG',
      // 'downloadJPEG',
    ];

    if (supportsFeature('candleSticks')) {
      menuItems.push({
        text: 'Toggle Candlesticks',
        onclick: function () {
          updateFeatureState('candleSticks');
        },
      });
    }
    if (supportsFeature('navigator')) {
      menuItems.push({
        text: `${isFeatureEnabled('navigator') ? 'Hide' : 'Show'} Navigator`,
        onclick: function () {
          updateFeatureState('navigator');
        },
      });
    }
    return menuItems;
  }, [isFeatureEnabled, supportsFeature]);

  const handleRangeSelectionChange = React.useCallback(
    (series: Maybe<ChartableSliceSeries[]>) => {
      if (series?.[0]?.points?.length) {
        let sumDividendsTotal = 0;
        let sumTradesTotal = 0;
        const valueHistory = series?.[0].points;
        // Not sure why these types don't infer correctly
        const start = Object.assign(
          {},
          valueHistory[0],
        ) as ChartableSliceDatumPoint;
        const end = Object.assign(
          {},
          valueHistory[valueHistory.length - 1],
        ) as ChartableSliceDatumPoint;

        const startValue = start.y;
        const endValue = end.y;
        let startDate = moment(start.datum?.date);

        let percentChange = null;
        if (typeof endValue === 'number' && typeof startValue === 'number') {
          const changeInValue = endValue - startValue;
          const percentChangeInValue = changeInValue / (startValue || 1);
          percentChange = roundToDecimal(percentChangeInValue * 100);
        }

        (
          data?.nodes?.[0] as ChartablSliceNode
        )?.chartData?.additionalData?.forEach((data) => {
          let earliestStartDate = startDate;
          // If the current period is 'all' or 'ytd', we want to get cash flows that may be before the user's value history
          // If 'all', we want all cash flows ever, which may be before the visible value history.
          // If 'ytd', we want cash flows that may have happened since Jan 1 but before their value history started after Jan 1.
          // This is very edge case-y, and should only really happen if the customer asks for cash flows to be manually added.
          if (['all', 'ytd'].includes(currentPeriod.current)) {
            earliestStartDate =
              currentPeriod.current === 'all'
                ? moment(0)
                : moment().startOf('year');
          }
          // Instead of using chart points, use the original data response filtered by the adjusted start date
          // and the chart's end date
          const filteredData = data?.data?.filter((datum) => {
            const date = moment(datum.date);
            return (
              date.isSameOrAfter(earliestStartDate) &&
              date.isSameOrBefore(end.datum?.date)
            );
          });
          // Track the minimum start date for all series so we have an accurate "Starting value date"
          // This starting date tracks the beginning of performance, not just value history, so that's why it can
          // be earlier than the visible value history due to cash flows that may be added before (again, edge-casey)
          startDate = moment.min(startDate, moment(filteredData?.[0]?.date));
          if (data?.dataType === 'DIVIDEND' && filteredData) {
            sumDividendsTotal += sumDividends(filteredData);
          }
          if (data?.dataType === 'NET_CASH_FLOW' && filteredData) {
            sumTradesTotal += sumTrades(filteredData);
          }
        });

        // setting this first to ensure the effect below doesn't fire immediately after
        setHasEmittedLatestChartData(true);
        onRangeSelectionChange?.({
          start,
          chartStartDate: moment(startDate),
          end,
          chartEndDate: moment(end.datum?.date),
          percentChange,
          sumDividends: sumDividendsTotal,
          netCashFlow: sumTradesTotal,
        });
      }
    },
    [onRangeSelectionChange, currentPeriod, data?.nodes],
  );

  React.useEffect(() => {
    if (!hasEmittedLatestChartData && data?.nodes) {
      const series = updateVisiblePoints();
      handleRangeSelectionChange(series);
    }
  }, [hasEmittedLatestChartData, data, onRangeSelectionChange]);

  React.useEffect(() => {
    onChangePeriod?.(periodDurationToPeriodEnum(getDefaultPeriod()));
  }, []);

  Highcharts.setOptions({
    lang: {
      noData: 'No data available',
      loading: 'Loading...',
      rangeSelectorFrom: 'From',
      rangeSelectorTo: 'To',
    },
  });

  /**
   * Use this to update the visible points ref. Do not use this as a getter as a first
   * resort, instead use the `visiblePoints` ref.
   */
  const updateVisiblePoints = React.useCallback(() => {
    const chartSeries = chartRef.current?.chart?.series;
    const xAxisMin = moment(chartSeries?.[0]?.xAxis?.min);
    const xAxisMax = moment(chartSeries?.[0]?.xAxis?.max);
    const mapped = chartSeries?.map((serie) => {
      serie.points = serie.points?.filter((point) => {
        const date = moment(point.x);
        return (
          date.isSameOrAfter(xAxisMin ?? undefined, 'day') &&
          date.isSameOrBefore(xAxisMax ?? undefined, 'day')
        );
      });
      return serie;
    });
    visiblePoints.current = mapped;
    return mapped;
  }, []);

  const updateYAxisRange = React.useCallback(() => {
    const chart = chartRef.current?.chart;
    // Calculate min and max of visible range to set y-axis extremes so we don't always show $0 on y-axis
    const { minY, maxY } = visiblePoints.current?.[0]?.points?.reduce(
      (acc, point) => {
        return {
          minY: Math.min(acc.minY, point.y ?? acc.minY),
          maxY: Math.max(acc.maxY, point.y ?? acc.maxY),
        };
      },
      { minY: Infinity, maxY: -Infinity },
    ) ?? { minY: Infinity, maxY: -Infinity };
    chart?.yAxis[0].setExtremes(minY, maxY);
  }, [chartRef.current, visiblePoints.current?.length]);

  const options = React.useMemo(() => {
    let minYRange = 1;
    let series;
    let additionalSeries: SeriesTypes[] = [];
    if (data?.nodes && liveDatumReady) {
      series = data.nodes
        ?.map((node, i) => {
          if (node) {
            const chartableSlice = node as ChartablSliceNode;

            if (!chartableSlice.chartData.data) {
              return null;
            }
            // make the minimum range 2x the difference between the last close and the second to last close so 1D always shows the general slope
            minYRange = Math.max(
              minYRange,
              2 *
                Math.abs(
                  (chartableSlice.chartData.data?.[
                    chartableSlice.chartData.data?.length - 1
                  ]?.close ?? 0) -
                    (chartableSlice.chartData.data?.[
                      chartableSlice.chartData.data?.length - 2
                    ]?.close ?? 0),
                ),
            );

            const data: ChartKeys =
              chartableSlice.chartData.data?.map((datum) => {
                const parsedDate = Date.parse(datum.date);
                return [
                  parsedDate,
                  datum.close,
                  datum.open,
                  datum.high,
                  datum.low,
                  datum.close,
                  // Above order is required for candlesticks, all values must match keys below
                  datum,
                ];
              }) ?? [];

            if (liveDatum) {
              const liveDatumParsedDate = Date.parse(liveDatum.date);
              const liveDatumChartPoint: ChartKeys[number] = [
                liveDatumParsedDate,
                liveDatum.close,
                liveDatum.open,
                liveDatum.high,
                liveDatum.low,
                liveDatum.close,
                liveDatum,
              ];
              data.push(liveDatumChartPoint);
            }
            if (chartableSlice.chartData.additionalData) {
              additionalSeries = chartableSlice.chartData.additionalData.map(
                (dataSeries) => {
                  const data =
                    dataSeries?.data?.map(
                      ({
                        date,
                        sumDividends,
                        sumTrades,
                        dividends,
                        trades,
                      }) => {
                        const isDividend = dataSeries.dataType === 'DIVIDEND';
                        const parsedDate = Date.parse(date);
                        const item = isDividend ? dividends : trades;
                        const text = item
                          ?.map(({ symbol, amount }) => {
                            return `${symbol}: ${CashFormatter.format(amount)}`;
                          })
                          .join('<br/>');
                        return {
                          x: parsedDate,
                          sumDividends,
                          sumTrades,
                          title: dataSeries?.dataType?.[0], // First letter
                          text,
                        };
                      },
                    ) ?? [];
                  return {
                    name: dataSeries?.name,
                    data,
                    onSeries: chartableSlice.chartData.seriesId,
                    accessibility: {
                      exposeAsGroupOnly: true,
                      description: dataSeries?.name,
                    },
                    shape: 'squarepin',
                    width: 16,
                    dataType: dataSeries?.dataType,
                    visible: false,
                    type:
                      dataSeries?.seriesType === SeriesTypeEnum.FlagEvents
                        ? 'flags'
                        : 'line',
                    tooltip: {
                      customTooltipPerSeries: function (
                        this: Highcharts.TooltipFormatterContextObject,
                      ) {
                        return `<span style='color:${
                          theme.colors.foregroundNeutralMain
                          // @ts-expect-error this is valid
                        }'><strong>${capitalize(dataSeries?.dataType?.replace(/_/g, ' '))}</strong><br />${this.point.text}</span><br /><span style='color:${
                          theme.colors.foregroundNeutralSecondary
                        };border-color:${theme.colors.borderMain}'>${
                          typeof this.x === 'number'
                            ? Highcharts.dateFormat('%B %e, %Y', this.x)
                            : null
                        }</span>`;
                      },
                    },
                  } as SeriesTypes;
                },
              );
            }
            return {
              name: chartableSlice.chartData.name,
              data,
              id: chartableSlice.chartData.seriesId,
              // Order here matters for some types, such as candlesticks. Using `keys` allows us to provide this data for all types
              // and access this data in our callback functions, like in `target.chart.series`
              keys: [
                'x',
                'y',
                'open',
                'high',
                'low',
                'close',
                // Above order is required for candlesticks, all keys must match values above
                CHART_DATUM_KEY,
              ],
              type: isFeatureEnabled('candleSticks')
                ? 'hollowcandlestick'
                : 'area',
              tooltip: {
                customTooltipPerSeries: function (
                  this: Highcharts.TooltipFormatterContextObject,
                ) {
                  const visiblePoints = updateVisiblePoints();
                  const firstVisibleY = visiblePoints?.[0]?.points?.[0]?.y;

                  // firstVisibleY could be 0, so we just want to check truthiness so we don't get infinite gains
                  const percentChange =
                    firstVisibleY && typeof this.y === 'number'
                      ? ` (${(((this.y - firstVisibleY) / firstVisibleY) * 100).toFixed(2)}%)`
                      : '';
                  return [
                    `<span style='color:${
                      theme.colors.foregroundNeutralMain
                    }'>${CashFormatter.format(this.y ?? 0)}${percentChange}
                    </span><br/>`,
                    `<span style='color:${
                      theme.colors.foregroundNeutralSecondary
                    };border-color:${theme.colors.borderMain}'>${Highcharts.dateFormat(
                      '%B %e, %Y',
                      this.x as number,
                    )}</span>`,
                  ];
                },
              },
              color:
                theme.chartColors[i % theme.chartColors.length][
                  isOnDarkBackground ? 'datapointFeature' : 'datapoint'
                ],
              fillColor: {
                linearGradient: {
                  x1: 0,
                  x2: 0,
                  y1: 0,
                  y2: 1,
                },
                stops: [
                  [
                    0.58,
                    `rgb(${globalColors.blue03[indexOfThemeInColorsArray]} / 0.24)`,
                  ],
                  [
                    1,
                    `rgb(${globalColors.blue03[indexOfThemeInColorsArray]} / 0)`,
                  ],
                ],
              },
            } as SeriesTypes;
          }
          return null;
        })
        .filter((s): s is SeriesTypes => Boolean(s));
    }

    if (series && additionalSeries.length) {
      series.push(...additionalSeries);
    }

    const buttons = memoizedPeriods.map((periodDuration) =>
      periodDurationToRangeSelectorButton({
        periodDuration,
        analytics,
        chartName,
        onChangePeriod: (...args) => {
          currentPeriod.current = periodDuration;
          onChangePeriod?.(...args);
          localStorage.setItem(rangeSelectionKey, periodDuration);
        },
      }),
    );

    const opts: Highcharts.Options = {
      loading: {
        style: {
          opacity: 0,
        },
      },
      chart: {
        height: height ?? (isFeatureEnabled('navigator') ? 540 : 433),
        backgroundColor: 'transparent',
        panning: {
          enabled: false,
        },
        zooming: {
          mouseWheel: false,
        },
        style: {
          fontFamily: 'Inter',
        },
        events: {
          fullscreenOpen: function () {
            chartRef.current?.chart.update({
              chart: {
                backgroundColor: isOnDarkBackground
                  ? theme.colors.backgroundPlotFeature
                  : theme.colors.backgroundNeutralMain,
              },
            });
          },
          fullscreenClose: function () {
            chartRef.current?.chart.update({
              chart: {
                backgroundColor: 'transparent',
              },
            });
          },
        },
      },
      credits: {
        enabled: false,
      },
      // @ts-ignore good by docs
      xAxis: {
        type: 'datetime',
        lineColor: isOnDarkBackground
          ? theme.colors.foregroundNeutralTertiary
          : theme.colors.borderMain,
        minRange: 24 * 3600 * 1000,
        labels: {
          style: {
            color: isOnDarkBackground
              ? theme.colors.foregroundNeutralOnDark
              : theme.colors.foregroundNeutralSecondary,
            fontSize: '11px',
          },
        },
        crosshair: {
          color: theme.colors.foregroundSecondaryTint,
        },
        ordinal: false,
        tickColor: isOnDarkBackground
          ? theme.colors.foregroundNeutralTertiary
          : theme.colors.borderMain,
        events: {
          afterSetExtremes: function () {
            const series = updateVisiblePoints();
            handleRangeSelectionChange(series);
            updateYAxisRange();
          },
        },
      },
      // @ts-expect-error Types are wrong, dashStyle is valid according to docs
      yAxis: {
        opposite: false,
        labels: {
          // TODO - use this if we ever want % y-axis
          formatter: ({ value }: any) => {
            return CompactCashFormatter.format(value);
          },
          style: {
            color: isOnDarkBackground
              ? theme.colors.foregroundNeutralOnDark
              : theme.colors.foregroundNeutralSecondary,
            fontSize: '12px',
          },
          align: 'center',
        },
        showLastLabel: true,
        plotLines,
        crosshair: {
          color: theme.colors.foregroundSecondaryTint,
          dashStyle: '4 4',
        },
        gridLineColor: isOnDarkBackground
          ? theme.colors.backgroundPlotFeature
          : theme.colors.borderMain,
        lineColor: theme.colors.borderMain,
        tickColor: theme.colors.borderMain,
        minRange: minYRange,
      },
      rangeSelector: {
        buttons,
        buttonPosition: {
          align: 'right',
        },
        buttonTheme: {
          width: 28,
          height: 28,
          r: '8px',
          fill: 'transparent',
          style: {
            color: isOnDarkBackground
              ? theme.colors.foregroundNeutralOnDark
              : theme.colors.foregroundNeutralMain,
            fontWeight: '400',
            fontSize: '12px',
            opacity: 100,
            textTransform: 'uppercase',
            textDecoration: 'none',
          },
          states: {
            hover: {
              fill: isOnDarkBackground
                ? theme.colors.backgroundPlotFeature
                : theme.colors.borderMain,
              style: {
                fontWeight: '400',
                opacity: '100%',
              },
            },
            select: {
              fill: 'none',
              style: {
                color: isOnDarkBackground
                  ? theme.colors.primaryTint
                  : theme.colors.primary,
                fontWeight: '600',
                opacity: '100%',
                textDecoration: 'underline',
              },
            },
            disabled: {
              style: {
                color: theme.colors.foregroundNeutralTertiary,
              },
            },
          },
        },
        dropdown: isMobile ? 'always' : 'never',
        inputEnabled: isFeatureEnabled('dateRangeInputs'),
        inputBoxBorderColor: 'gray',
        inputPosition: {
          align: 'left',
          x: 0,
        },
        inputBoxWidth: 80,
        inputBoxHeight: 18,
        inputDateFormat: '%b %e, %Y',
        inputStyle: {
          color: theme.colors.foregroundNeutralSecondary,
          borderRadius: 5,
          fontSize: '12px',
        },
        labelStyle: {
          color: theme.colors.foregroundNeutralMain,
          fontWeight: '400',
          fontSize: '12px',
        },
      },
      navigator: {
        enabled: isFeatureEnabled('navigator'),
        outlineColor: theme.colors.foregroundNeutralTertiary,
        xAxis: {
          gridLineColor: theme.colors.foregroundNeutralTertiary,
        },
      },
      plotOptions: {
        series: {
          // TODO - use this if we ever want % y-axis
          // compare: 'percent',
          showInNavigator: true,
          lineWidth: 2,
          dataGrouping: {
            // Turned off so start and end points are not approximated into group
            enabled: false,
          },
          marker: {
            states: {
              hover: {
                radius: 6,
                fillColor: isOnDarkBackground
                  ? theme.chartColors[0].datapointFeature
                  : theme.colors.blue04,
                lineWidth: 30,
                lineColor: `rgba(85, 102, 185, 0.2)`,
              },
            },
          },
        },
      },
      tooltip: {
        split: false,
        padding: 12,
        formatter: function () {
          // @ts-ignore this is valid
          return this.series.tooltipOptions.customTooltipPerSeries.call(this);
        },
        valueDecimals: 2,
        backgroundColor: theme.colors.backgroundNeutralSecondary,
        className: 'chartable-slice-chart-tooltip',
        shadow: {
          color: 'rgba(0, 0, 0, 0.2)',
          offsetX: 2,
          offsetY: 3,
          opacity: 0.15,
          width: 10,
        },
        style: {
          fontSize: '14px',
          opacity: isOnDarkBackground ? 1.0 : 0.85,
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            useHTML: true,
            symbol: undefined,
            symbolFill: 'transparent',
            symbolStroke: undefined,
            theme: {
              fill: 'transparent',
              // @ts-ignore states is a valid key
              states: {
                hover: {
                  fill: 'red',
                },
                select: {
                  fill: theme.colors.backgroundNeutralMain,
                },
              },
            },
            text: renderToString(
              <Icon
                data-cool="true"
                name="more24"
                color={
                  isOnDarkBackground
                    ? 'foregroundNeutralOnDark'
                    : 'foregroundNeutralMain'
                }
                // @ts-expect-error the `theme` prop is used to retain the ThemeProvider
                theme={theme}
              />,
            ),
            // @ts-expect-error Types are wrong, menuItems can be strings or object handlers
            menuItems,
          },
        },
        filename: `${series?.[0]?.name} - ${moment().format('YYYY-MM-DD')}`,
        csv: {
          columnHeaderFormatter: function (item: any, key: string) {
            if (key === 'x') {
              return 'Date';
            }
            if (key === 'y') {
              return 'Value';
            }
            return capitalize(key);
          },
        },
      },
      series,
      noData: {
        style: {
          fontWeight: '400',
          fontSize: '16px',
          color: theme.colors.foregroundNeutralMain,
        },
      },
    };

    return opts;
  }, [
    data?.nodes,
    memoizedPeriods,
    isFeatureEnabled,
    isOnDarkBackground,
    theme,
    plotLines,
    isMobile,
    menuItems,
    liveDatum,
    analytics,
    chartName,
    onChangePeriod,
    getDefaultPeriod,
    updateVisiblePoints,
    handleRangeSelectionChange,
    indexOfThemeInColorsArray,
  ]);

  React.useEffect(() => {
    if (getDefaultPeriod() !== 'all' && options?.series) {
      // Setting the button after the options.series is set allows
      // us to update the selected button after all the data is available
      // then like when selecting a button, we adjust the y axis range.
      const buttons = memoizedPeriods.map((periodDuration) =>
        periodDurationToRangeSelectorButton({
          periodDuration,
          analytics,
          chartName,
          onChangePeriod,
        }),
      );
      const selectedButton = getDefaultPeriod()
        ? buttons.findIndex((button) => button.text === getDefaultPeriod())
        : null;

      if (typeof selectedButton === 'number') {
        chartRef.current?.chart?.update({
          rangeSelector: {
            selected: selectedButton,
          },
        });
        updateYAxisRange();
      }
    }
    if (
      currentPeriod.current === 'all' &&
      getDefaultPeriod() === 'all' &&
      options?.series
    ) {
      // For share pie and other charts that always want to show all, we need to recalculate x and y axis extremes
      const chart = chartRef.current?.chart;
      const seriesOptions = options.series?.[0] as Maybe<SeriesLineOptions>;
      if (seriesOptions?.data?.length) {
        const data = seriesOptions.data as ChartKeys;
        const firstPoint = data[0];
        const lastPoint = data[data.length - 1];
        const { minY, maxY } = data.reduce(
          (acc, point) => {
            return {
              minY: Math.min(acc.minY, point[1] ?? acc.minY),
              maxY: Math.max(acc.maxY, point[1] ?? acc.maxY),
            };
          },
          { minY: Infinity, maxY: -Infinity },
        ) ?? { minY: Infinity, maxY: -Infinity };
        chart?.xAxis[0].setExtremes(firstPoint?.[0], lastPoint?.[0]);
        chart?.yAxis[0].setExtremes(minY, maxY);
      }
    }
  }, [getDefaultPeriod, options?.series, chartRef.current]);

  const hiddenSeriesIndices = options?.series?.reduce(
    (hiddenSeriesIndices, series, i) => {
      // We pass in a custom `hidden` property above that we can filter on.
      // Any series can be hidden. You should only mark the series as hidden if you still want to reference
      // the points in the series. If you don't want to show the series or reference its points at all,
      // just don't include its `id` in `chartableSliceIds`.
      if ((series as SeriesTypes)?.hidden) {
        hiddenSeriesIndices.push(i);
      }
      return hiddenSeriesIndices;
    },
    [] as number[],
  );

  return (
    <HighchartsStyles
      isLoading={loading || liveDatumLoading}
      fadeOut
      skeletonWidth="100%"
      skeletonHeight="100%"
      skeletons={<ChartSkeleton />}
      $isPieGraphSection={isPieGraphSection}
      $isOnDarkBackground={isOnDarkBackground}
    >
      <StockChart options={options} chartRef={chartRef} />
    </HighchartsStyles>
  );
};
