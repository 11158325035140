import { Box, Flex, HM, PXL, PL, HXXS } from '@m1/liquid-react';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { useMediaQuery } from 'react-responsive';

import { useSharePieLandingPageQuery } from '~/graphql/hooks';
import {
  AppRichTextDisclosureLinkFragment,
  SharePieHeaderPropsFragment,
} from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { AppImage } from '~/lens-toolbox/AppImage';
import { AppState } from '~/redux';
import { clickedAddSharedPie } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';
import { Spinner } from '~/toolbox/spinner';

import { type Path } from '../SharePiePage.types';

import { InvalidTokenPartial } from './InvalidTokenPartial';
import { SharePieDetailsSection } from './SharePieDetailsSection/SharePieDetailsSection';

import {
  annotateLocalModel,
  readEditModel,
} from './SharePieDetailsSection/SharePieDetailsSection.utils';
import { SharePieDisclosures } from './SharePieDisclosures';
import { SharePieHeader } from './SharePieHeader';
import { SharePieUserCTASection } from './SharePieUserCTASection';

export type SharePiePageContentProps = {
  shareToken: string;
  path: Path | null | undefined;
};

export const SharePiePageContent = ({
  shareToken,
  path,
}: SharePiePageContentProps) => {
  const dispatch = useDispatch();
  const { query, pathname } = useLocation();

  const isMobile = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.SMALL,
  });

  const analytics = useAnalytics();
  const navigate = useNavigate();

  const isLoggedIn = useSelector((state: AppState) =>
    Boolean(state.auth.accessToken),
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const { data, loading } = useSharePieLandingPageQuery({
    variables: {
      shareToken,
    },
  });

  const pageViewAnalyticsEvent =
    data?.viewer.unauthenticated?.sharePieLandingPage.pageViewAnalyticsEvent;

  React.useEffect(() => {
    if (pageViewAnalyticsEvent) {
      analytics.recordAppAnalyticsEvent(pageViewAnalyticsEvent);
    }
  }, [pageViewAnalyticsEvent]);

  const isPieActive = Boolean(data?.viewer.sharedPie?.isActive);

  const registerNewUser = (values: { username: string; password: string }) => {
    // opening the share pie modal kicks off the saga for adding the shared pie
    dispatch(
      clickedAddSharedPie({
        shareToken,
        isCrypto: false,
        flow: 'signup',
        isPieActive,
        username: getValues('username'),
        password: getValues('password'),
      }),
    );
  };

  const onLoginClick = () => {
    dispatch(
      clickedAddSharedPie({
        shareToken: query.token,
        isCrypto: false,
        flow: 'login',
        username: getValues('username'),
        password: getValues('password'),
        isPieActive,
      }),
    );
  };

  const handleCTAClick = () => {
    if (!isPieActive && isLoggedIn) {
      navigate({ to: '/d/research/my-pies' });
    } else {
      dispatch(
        clickedAddSharedPie({
          shareToken: shareToken,
          isCrypto: false,
          isPieActive,
        }),
      );
    }
  };

  if (loading) {
    return <Spinner fullScreen />;
  }

  const sharedPie = data?.viewer.sharedPie;

  if (!sharedPie) {
    return <InvalidTokenPartial />;
  }

  const parsedModel = JSON.parse(sharedPie.editModel);
  const editModel = readEditModel(parsedModel, path);

  // Annotate the local model with id's and __typename's for the "new_pie" slices.
  const localModel = annotateLocalModel(editModel);
  const sharePieLandingPage = data?.viewer.unauthenticated?.sharePieLandingPage;
  const headerData = sharePieLandingPage?.header as SharePieHeaderPropsFragment;
  const disclosureFooter =
    sharePieLandingPage?.disclosuresFooter as AppRichTextDisclosureLinkFragment;
  const marketingDetails = sharePieLandingPage?.marketingDetails;

  return (
    <Flex flexDirection="column" margin="0 auto" alignItems="stretch">
      <Box
        alignContent="center"
        position="relative"
        width="100%"
        maxWidth={1200}
        margin="0 auto"
      >
        <SharePieHeader
          ctaButton={headerData.ctaButton}
          icon={headerData.icon}
          text=""
          handleCTAClick={handleCTAClick}
        />
      </Box>
      <Flex alignItems="center" mt={112} px={16} flexDirection="column">
        <HXXS textAlign="center" fontWeight={300}>
          {headerData.text}
        </HXXS>
        <HXXS textAlign="center" mb={8} fontWeight={300}>
          {sharePieLandingPage?.pieTitle}
        </HXXS>
      </Flex>
      <SharePieDetailsSection
        isPieActive={isPieActive}
        localModel={localModel}
        parsedModel={parsedModel}
        token={shareToken}
        delistedBanner={sharePieLandingPage?.delistedPieBanner}
        handleCTAClick={handleCTAClick}
      />
      {/* we have the scaleY(-1) to invert the background gradient, as designs have gradient in opposite direction */}
      <Box
        css={{
          background: 'var(--colors-gradient-linear-feature)',
          transform: 'scaleY(-1)',
        }}
      >
        <SharePieUserCTASection
          onSubmit={handleSubmit(registerNewUser)}
          onLoginClick={onLoginClick}
          control={control}
          errors={errors}
        />
      </Box>
      <Box backgroundColor="backgroundInfoSubtle">
        <Flex
          maxWidth={1200}
          margin="0 auto"
          justifyContent="center"
          flexDirection="column"
          padding={`56px ${isMobile ? 16 : 32}px`}
        >
          <HM
            fontWeight={300}
            color="blue05"
            maxWidth={660}
            m="0 auto"
            textAlign="center"
          >
            {marketingDetails?.title}
          </HM>
          <Flex
            alignItems="flex-start"
            flexDirection="row"
            mt={31}
            justifyContent="space-between"
            flexWrap="wrap"
          >
            {marketingDetails?.marketingContent?.map((content, idx) => (
              <Flex
                alignItems="center"
                flexDirection="column"
                maxWidth={isMobile ? undefined : 300}
                m="24px auto 0"
                key={idx}
              >
                <PXL fontWeight={600} mb={32}>
                  {content.title}
                </PXL>
                {content.illustration && (
                  <AppImage appImage={content.illustration} />
                )}
                <PL fontWeight={400} mt={32}>
                  {content.description}
                </PL>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Box>
      <Flex width="100%" justifyContent="center" margin="0 auto">
        {disclosureFooter && (
          <SharePieDisclosures disclosures={disclosureFooter} />
        )}
      </Flex>
    </Flex>
  );
};
