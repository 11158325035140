import { SagaIterator } from 'redux-saga';
import {
  call,
  put,
  setContext,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { ACTION_TYPES as ACTIONS, navigate } from '~/redux/actions';
import { BEGIN_ONBOARDING_FLOW_STEPS as STEPS } from '~/static-constants';

import { changeStep } from '../utils';

export function* beginOnboardingSaga(): SagaIterator<void> {
  yield takeLatest(ACTIONS.BEGIN_ONBOARDING, beginOnboardingFlow);
}

function* beginOnboardingFlow(): SagaIterator<void> {
  // Setup basePath context for step routing.
  yield setContext({
    basePath: 'start',
  });

  yield takeEvery(
    ACTIONS.CHANGE_BEGIN_ONBOARDING_STEP,
    function* (action): SagaIterator<void> {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ BEGIN_INVEST_ONBOARDING: string; BEGIN_SPEND_ONBOARDING: string; BEGIN_IRA_ONBOARDING: string; ONBOARDING_PLUS_ERROR: string; PRODUCT_SELECTION: string; SET_GOALS: string; }'. | TS2339 - Property 'payload' does not exist on type 'Action<string>'.
      yield call(changeStep, STEPS[action.payload.step]);
    },
  );

  yield takeEvery(
    'FINISHED_ONBOARDING_PHONE_VERIFICATION',
    finishedPhoneVerification,
  );

  function* finishedPhoneVerification(): SagaIterator<void> {
    yield put(navigate({ to: '/onboarding/setup-account' }));
  }
}
