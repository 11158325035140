import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { TransferRuleDetailsPage } from './TransferRuleDetailsPage';

export const TransferRuleDetailsRoute = () => {
  return (
    <Route
      path="rule-details/:transferRuleId"
      component={createRouteComponent(TransferRuleDetailsPage, (props) => ({
        transferRuleId: props.params.transferRuleId,
      }))}
      fallbackRoute="/d/transfers"
      cover
    />
  );
};
