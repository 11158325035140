import Highcharts from 'highcharts/highstock';
import highchartsData from 'highcharts/modules/data';
import highchartsExportData from 'highcharts/modules/export-data';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsCandleStick from 'highcharts/modules/hollowcandlestick';
import highchartsNoData from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import {
  every,
  flow,
  head,
  partialRight,
  reject,
  tail,
  unzip,
} from 'lodash-es';
import * as React from 'react';

import { isNil } from '~/utils';

export const CHART_DATUM_KEY = 'datum';

/**
 * Highcharts extension to remove columns from CSV exports.
 */
function highchartsExportingDataOmitter(H: typeof Highcharts) {
  H.wrap(
    H.Chart.prototype,
    'getDataRows',
    function (this: unknown, proceed, ...args) {
      // Apply the original function with the original arguments,
      // which are sliced off this function's arguments
      const result = proceed.apply(
        // eslint-disable-next-line no-invalid-this
        this,
        Array.prototype.slice.call(args, 1),
      );

      // Remove second row of Excel export which is duplicate headers
      if (result[1].every((column: any) => typeof column === 'string')) {
        result.splice(1, 1);
      }

      const removeNilColumns = flow(
        unzip,
        partialRight(reject, flow(tail, partialRight(every, isNil))), // Remove columns where all values are nil
        partialRight(reject, (column: any) => {
          // Remove datum column
          const header = head(column);
          return (
            typeof header === 'string' &&
            header.toUpperCase() === CHART_DATUM_KEY.toUpperCase()
          );
        }),
        unzip,
      );
      return removeNilColumns(result);
    },
  );
}

highchartsData(Highcharts);
highchartsExporting(Highcharts);
highchartsExportData(Highcharts);
highchartsCandleStick(Highcharts);
highchartsNoData(Highcharts);
highchartsExportingDataOmitter(Highcharts);

export const StockChart = ({ options, chartRef }: any) => (
  <HighchartsReact
    highcharts={Highcharts}
    constructorType="stockChart"
    options={options}
    ref={chartRef}
  />
);
