import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useSetFulfillmentConditionAddQuery,
  useSetFulfillmentConditionEditQuery,
} from '~/graphql/hooks';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { FulfillmentConditionEditContainer } from '../FulfillmentConditionEditContainer';
import { FulfillmentConditionRefetchContainer } from '../FulfillmentConditionRefetchContainer';

type SetFulfillmentConditionProps = {
  onFinishStep: () => void;
};

export const SetFulfillmentCondition = ({
  onFinishStep,
}: SetFulfillmentConditionProps) => {
  const fulfillmentConditionMode = useSelector(
    (state) => state.newFlows.CREATE_SMART_TRANSFER.fulfillmentConditionMode,
  );
  switch (fulfillmentConditionMode) {
    case 'ADD':
      return <AddQueryRenderer onFinishStep={onFinishStep} />;
    case 'EDIT':
      return <EditQueryRenderer onFinishStep={onFinishStep} />;
    default:
      return null;
  }
};

const EditQueryRenderer = ({ onFinishStep }: SetFulfillmentConditionProps) => {
  const editRequirementsId = useSelector((state) => {
    const activeContraParticipantId =
      state.newFlows.CREATE_SMART_TRANSFER.activeContraParticipantEntry
        ?.contraParticipantId;
    if (
      activeContraParticipantId &&
      state.newFlows.CREATE_SMART_TRANSFER.editRequirementsIdByContraId
    ) {
      return state.newFlows.CREATE_SMART_TRANSFER.editRequirementsIdByContraId[
        activeContraParticipantId
      ];
    }
    return null;
  });

  const { data, loading, refetch } = useSetFulfillmentConditionEditQuery({
    variables: {
      editRequirementsId: editRequirementsId as string,
    },
  });

  if (loading) {
    return <Spinner />;
  }
  if (
    data?.node?.__typename !==
    'SmartTransferContraParticipantEntryEditRequirements'
  ) {
    return <GenericSystemError />;
  }
  return (
    <Flex>
      <FulfillmentConditionEditContainer
        editRequirements={data.node}
        onFinishStep={onFinishStep}
      />
    </Flex>
  );
};

const AddQueryRenderer = ({ onFinishStep }: SetFulfillmentConditionProps) => {
  const newSmartTransferContraParticipantEntryOptionId = useSelector<
    string | null | undefined
  >(
    (state) =>
      state.newFlows.CREATE_SMART_TRANSFER
        .newSmartTransferContraParticipantEntryOptionId,
  );
  const contraParticipantId: string | null | undefined = useSelector(
    (state) =>
      state.newFlows.CREATE_SMART_TRANSFER.activeContraParticipantEntry
        ?.contraParticipantId,
  );

  const { data, loading, refetch } = useSetFulfillmentConditionAddQuery({
    variables: {
      newSmartTransferContraParticipantEntryOptionId:
        newSmartTransferContraParticipantEntryOptionId as string,
      contraParticipantId: contraParticipantId ?? '',
    },
  });

  if (loading) {
    return <Spinner />;
  }
  if (
    data?.node?.__typename !== 'NewSmartTransferContraParticipantEntryOption'
  ) {
    return <GenericSystemError />;
  }
  return (
    <Flex>
      <FulfillmentConditionRefetchContainer
        newSmartTransferContraParticipantEntryOption={data.node}
        onFinishStep={onFinishStep}
        refetch={refetch}
      />
    </Flex>
  );
};
