import {
  Box,
  Flex,
  HM,
  Maybe,
  PL,
  PM,
  Skeleton,
  SkeletonProvider,
  styled,
  Card,
} from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import {
  aBreakdownDetail,
  aRichTextContent,
  anAppDisclosureLink,
} from '~/graphql/fixtures';
import {
  useBuyingPowerOverviewQuery,
  useUpdateUserDataMutation,
} from '~/graphql/hooks';
import {
  BuyingPowerOverviewQuery,
  MarginBreakdownDetailFragment,
} from '~/graphql/types';
import { useRecordDisplayAppAnalyticsEvent } from '~/hooks/useRecordDisplayAppAnalyticsEvent';
import { AppSkeletonButton } from '~/lens-toolbox/AppButton';
import { AppCard } from '~/lens-toolbox/AppCard/AppCard';
import { AppDisclosureLink } from '~/lens-toolbox/AppDisclosureLink/AppDisclosureLink';
import { AppTooltip } from '~/lens-toolbox/AppTooltip';
import { LinkableSkeletonButton } from '~/lens-toolbox/LinkableButton';
import { LinkableSkeletonLink } from '~/lens-toolbox/LinkableLink';
import { RichText } from '~/lens-toolbox/RichText/RichText';
import { SetOrderFlowState } from '~/redux/reducers/newFlows/reducers/setOrderReducer';

const mockBreakdownItems: MarginBreakdownDetailFragment[] = [
  aBreakdownDetail(),
  aBreakdownDetail(),
];

const StyledBreakdownItem = styled(Flex)`
  justify-content: space-between;

  &:last-child {
    border-top: 1px solid ${({ theme }) => theme.colors.borderMain};
  }
`;

const BreakWordSkeleton = styled(Skeleton)`
  a {
    display: inline;
    word-break: break-word;
  }
`;

const BreakdownItem = ({
  item,
}: {
  item: Maybe<MarginBreakdownDetailFragment>;
}) => {
  return (
    <StyledBreakdownItem py={14}>
      <Skeleton skeletonWidth="25%">
        <PM
          fontWeight={400}
          color="foregroundNeutralSecondary"
          display="inline-flex"
        >
          {item?.label}
          {item?.tooltip && <AppTooltip appTooltip={item?.tooltip} />}
        </PM>
      </Skeleton>
      <Skeleton skeletonWidth="25%">
        <PM fontWeight={400}>{item?.labelValue}</PM>
      </Skeleton>
    </StyledBreakdownItem>
  );
};

export const BuyingPowerOverviewStep = ({
  portfolioSliceId,
  onFinishStep,
}: SetOrderFlowState & { onFinishStep: () => void }) => {
  const { data, loading } = useBuyingPowerOverviewQuery({
    variables: {
      id: portfolioSliceId as string,
    },
    skip: !portfolioSliceId,
  });
  const [updateUserData] = useUpdateUserDataMutation();

  const {
    header,
    details,
    breakdown,
    link,
    marginRisk,
    marginPromotionCard,
    primaryCta,
    secondaryCta,
    disclosures,
    displayAnalyticsEvent,
  } =
    (
      data?.node as Maybe<
        ExtractTypenameByKey<BuyingPowerOverviewQuery['node'], 'buyingPower'>
      >
    )?.buyingPower.overview ?? {};

  useRecordDisplayAppAnalyticsEvent(displayAnalyticsEvent);

  React.useEffect(() => {
    return () => {
      if (data) {
        updateUserData({
          variables: {
            input: {
              entries: [{ key: 'hasSeenMarginExplainer', value: 'true' }],
            },
          },
        });
      }
    };
  }, [data]);

  return (
    <SkeletonProvider isLoading={loading}>
      <Box maxWidth={640} width="100%" px={8}>
        <BackArrow content="Back" onClick={() => window.history.back()} />
        <Skeleton mt={48} mb={8} skeletonWidth="50%">
          <HM fontWeight={300}>{header}</HM>
        </Skeleton>
        {marginPromotionCard?.map((card, i) => (
          <AppCard key={i} appCard={card} my={24} />
        ))}
        {details?.map((detail, i) => (
          <Box key={i} mb={32}>
            <RichText richText={detail} />
          </Box>
        ))}
        <Card mt={24} p="0 24px 16px">
          <Skeleton my={14}>
            <PL fontWeight={600}>{breakdown?.header}</PL>
          </Skeleton>
          {(loading ? mockBreakdownItems : breakdown?.items)?.map((item, i) => (
            <BreakdownItem key={i} item={item} />
          ))}
          <BreakdownItem item={breakdown?.total} />
        </Card>
        <Skeleton
          my={16}
          font="PM"
          color="foregroundNeutralSecondary"
          skeletonWidth="100%"
        >
          <RichText
            // @ts-ignore this mock is fine
            richText={loading ? aRichTextContent({}) : breakdown?.footer}
          />
        </Skeleton>
        <Skeleton my={16} skeletonWidth={240}>
          <LinkableSkeletonLink linkable={link} />
        </Skeleton>
        {(loading ? [anAppDisclosureLink()] : disclosures)?.map(
          (disclosure, i) => (
            <Skeleton key={i} my={8} skeletonWidth={80}>
              <AppDisclosureLink appDisclosureLink={disclosure} />
            </Skeleton>
          ),
        )}
        <BreakWordSkeleton
          mt={48}
          mb={16}
          font="PM"
          color="foregroundNeutralSecondary"
          skeletonWidth="100%"
        >
          <RichText
            // @ts-ignore this mock is fine
            richText={loading ? aRichTextContent({}) : marginRisk}
            containerProps={{ display: 'inline' }}
            linkProps={{
              underline: true,
              fontWeight: 400,
              font: 'PM',
            }}
          />
        </BreakWordSkeleton>
        <Flex gap={24} flexWrap="wrap">
          <Skeleton skeletonWidth={120}>
            <AppSkeletonButton
              appButton={primaryCta}
              size="large"
              onClick={() => onFinishStep()}
            />
          </Skeleton>
          <Skeleton
            skeletonWidth={120}
            skeletonHeight="fit-content"
            borderRadius={1000}
          >
            <LinkableSkeletonButton
              kind="secondary"
              linkable={secondaryCta}
              size="large"
            />
          </Skeleton>
        </Flex>
      </Box>
    </SkeletonProvider>
  );
};
