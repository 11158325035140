import * as React from 'react';
import { Route } from 'react-router';

import { PortfolioTradingDetails } from './components/PortfolioTradingDetails';
import { ExcludedBuysPage } from './excluded-buys/ExcludedBuysPage';
import { OnDemandPortfolioTradingDetailsPage } from './OnDemandPortfolioTradingDetailsPage';
import { OnDemandPortfolioTradingInProgressPage } from './OnDemandPortfolioTradingInProgressPage';
import { PortfolioPage } from './PortfolioPage';

export const PortfolioRoute = () => {
  return (
    <Route path="portfolio" component={PortfolioPage}>
      <Route path="trading" component={PortfolioTradingDetails} />
      <Route path="on-demand-trading">
        <Route
          path=":tradeWindowId"
          component={OnDemandPortfolioTradingInProgressPage}
        />
      </Route>
      <Route path=":portfolioSliceId">
        <Route path="trading" component={PortfolioTradingDetails} />
        <Route
          path="on-demand-trading"
          component={OnDemandPortfolioTradingDetailsPage}
        />
        <Route path="excluded-buys" component={ExcludedBuysPage} />
      </Route>
    </Route>
  );
};
