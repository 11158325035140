import { Box, Flex, HXXS, PS } from '@m1/liquid-react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ControlledInput } from '~/components/form/ControlledInput';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { isValidPercentage } from '~/utils';

export const IraWithholding = ({ iraWithholding }: any) => {
  const { control } = useFormContext();
  return (
    <Box>
      <HXXS>Tax withholding</HXXS>
      <ControlledInput
        maxLength={5}
        defaultValue="federalWithholding"
        autoComplete="off"
        name="federalWithholding"
        control={control}
        label="Federal tax withholding (%)"
        transformInput={isValidPercentage}
        includePercentSign
      />
      <ControlledInput
        maxLength={5}
        defaultValue="stateWithholding"
        autoComplete="off"
        name="stateWithholding"
        label="State tax withholding (%)"
        disabled={!iraWithholding.isStateEditable}
        transformInput={isValidPercentage}
        includePercentSign
      />
      <Flex flexDirection="column">
        <PS
          mt={8}
          color="foregroundNeutralSecondary"
          content={iraWithholding?.stateWithholdingInformationText}
          whiteSpace="pre-wrap"
        />
        {iraWithholding?.stateWithholdingInformationLink && (
          <LinkableLink
            mt={16}
            linkable={iraWithholding.stateWithholdingInformationLink}
          />
        )}
      </Flex>
      {iraWithholding?.iraWithholdingLink && (
        <LinkableLink mt={16} linkable={iraWithholding.iraWithholdingLink} />
      )}
    </Box>
  );
};
