import { Button } from '@m1/liquid-react';
import * as React from 'react';
import { InjectedFormProps } from 'redux-form';

import { connectForm } from '~/hocs';
import {
  InvestmentExperienceEnumDropdownOptions,
  LiquidityNeedsEnumDropdownOptions,
  RiskToleranceEnumDropdownOptions,
  TimeHorizonEnumDropdownOptions,
} from '~/static-constants/dropdown-options/suitability-dropdown-options';
import { Grid } from '~/toolbox/grid';

import { getEnumEntries } from '~/utils';

import { DropdownField, CurrencyField } from './fields';
import { required, requireNonNil } from './validators';

type CollectSuitabilityProfileFormProps = InjectedFormProps<any> & {
  backgroundColor?: 'backgroundNeutralMain' | 'backgroundNeutralSecondary';
};

const CollectSuitabilityProfileFormComponent = (
  props: CollectSuitabilityProfileFormProps,
) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid.Row>
        <Grid.Col xs={6}>
          <DropdownField
            backgroundColor={props.backgroundColor}
            name="investmentExperience"
            label="Investment Experience"
            source={InvestmentExperienceEnumDropdownOptions}
            validate={[required]}
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <CurrencyField
            backgroundColor={props.backgroundColor}
            name="annualIncomeAmount"
            label="Annual Income"
            validate={[requireNonNil]}
            maxLength={9}
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <DropdownField
            backgroundColor={props.backgroundColor}
            name="totalNetWorth"
            label="Net Worth"
            validate={[requireNonNil]}
            source={getEnumEntries('TotalNetWorthEnum')}
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <DropdownField
            backgroundColor={props.backgroundColor}
            name="liquidNetWorth"
            label="Liquid Net Worth"
            validate={[requireNonNil]}
            source={getEnumEntries('LiquidNetWorthEnum')}
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <DropdownField
            backgroundColor={props.backgroundColor}
            name="riskTolerance"
            label="Risk Tolerance"
            source={RiskToleranceEnumDropdownOptions}
            validate={[required]}
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <DropdownField
            backgroundColor={props.backgroundColor}
            name="timeHorizon"
            label="Time Horizon"
            source={TimeHorizonEnumDropdownOptions}
            validate={[required]}
          />
        </Grid.Col>
        <Grid.Col xs={6}>
          <DropdownField
            backgroundColor={props.backgroundColor}
            name="liquidityNeeds"
            label="Liquidity Needs"
            source={LiquidityNeedsEnumDropdownOptions}
            validate={[required]}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row xsTextRight>
        <Grid.Col xs={12}>
          <Button kind="primary" type="submit" size="medium" label="Save" />
        </Grid.Col>
      </Grid.Row>
    </form>
  );
};

export const CollectSuitabilityProfileForm = connectForm({
  form: 'collect-suitability-profile',
})(CollectSuitabilityProfileFormComponent);
