/* eslint-disable no-param-reassign */

import cloneDeep from 'lodash-es/cloneDeep';
import moment from 'moment';

import { PieOrganizerConfig } from '~/components/PortfolioOrganizerPage';
import { UpdatePieTreeMutationResult } from '~/graphql/hooks';

import {
  mapRemoteSliceableByType,
  readPieTreeByPath,
  readPieTreeBySliceId,
  updatePieTreeByPath,
  sortSlicesByPercentage,
} from '~/pie-trees';
import { AppAction, ACTION_TYPES } from '~/redux/actions';

import {
  OrganizerSessionState,
  PortfolioOrganizerState,
} from './portfolioOrganizerReducer.types';

/* partially applies the pie editor config to the onConfirm callback */
const partiallyApplyOnConfirm = (
  onConfirm: (
    config: PieOrganizerConfig,
    result: UpdatePieTreeMutationResult,
  ) => void,
  config: PieOrganizerConfig,
): ((result: UpdatePieTreeMutationResult) => void) => {
  return (result) => onConfirm(config, result);
};

// Helper function to reduce boilerplate
const updateSessionState = (
  state: PortfolioOrganizerState,
  newSessionState: OrganizerSessionState,
): PortfolioOrganizerState => ({
  ...state,
  session: {
    ...state.session,
    state: newSessionState,
  },
});

// Fields prefixed with `__` are considered "private". These keys are
// stripped out before passing the serialized tree back to Lens in
// updatePieTree.
export const initialState: PortfolioOrganizerState = {
  confirmationDialog: {
    showApplicableLocations: true,
  },
  onConfirm: null,
  path: [],
  pieTree: {
    __checked: false,
    __highlighted__: false,
    __shouldEqualizeSlicePercentagesOnAdd: true,
    __id: 'root-portfolio-pie',
    description: '',
    name: 'My Portfolio',
    slices: [],
    type: 'new_pie',
  },
  pieTreeBeforeUpdates: {
    __checked: false,
    __highlighted__: false,
    __shouldEqualizeSlicePercentagesOnAdd: true,
    __id: 'root-portfolio-pie',
    description: '',
    name: 'My Portfolio',
    slices: [],
    type: 'new_pie',
  },
  showHelperDialogs: true,
  session: {
    state: 'UNINITIALIZED',
    mode: null,
    returnTo: null,
  },
  zeroPercentDisabled: false,
};

export const portfolioOrganizerReducer = (
  state: PortfolioOrganizerState = initialState,
  action: AppAction,
): PortfolioOrganizerState => {
  switch (action.type) {
    case ACTION_TYPES.INITIALIZE_PIE_ORGANIZER: {
      const session = {
        state: 'INITIALIZING',
        mode: action.payload.mode ?? state.session.mode,
        returnTo: action.payload.returnTo,
      };

      // NOTE: We have to spread over the pieTree nested object.
      // If we don't then the pieTree property will retain its previous state,
      // this is due to the fact that new variables to existing objects are references, not copies.
      // To read more on the why see the redux docs here.
      // https://redux.js.org/usage/structuring-reducers/immutable-update-patterns
      const newState = {
        ...initialState,
        buttons: action.payload.buttons,
        disabledFeatures: action.payload.disabledFeatures,
        isCrypto: action.payload.isCrypto,
        confirmationDialog: action.payload.confirmationDialog,
        onConfirm: action.payload.onConfirm
          ? partiallyApplyOnConfirm(action.payload.onConfirm, action.payload)
          : null,
        onExit: action.payload.onExit,
        pieTree: {
          ...initialState.pieTree,
        },
        pieTreeBeforeUpdates: {
          ...initialState.pieTreeBeforeUpdates,
        },
        session,
        subtitle: action.payload.subtitle,
        subtitleLink: action.payload.subtitleLink,
        title: action.payload.title,
        unmanagedHoldingsToMove: action.payload.unmanagedHoldingsToMove,
        zeroPercentDisabled: Boolean(action.payload.zeroPercentDisabled),
      };

      if (
        action.payload.mode === 'NEW_PIE' ||
        action.payload.mode === 'NEW_ROOT_PIE'
      ) {
        updatePieTreeByPath(newState.pieTree, newState.path, {
          toUpdateName:
            action.payload.mode === 'NEW_PIE'
              ? `New ${
                  action.payload.isCrypto ? 'Crypto ' : ''
                }Pie ${moment().format('l')}`
              : `New Portfolio`,
        });
      }

      // @ts-expect-error - TS2322 - Type '{ buttons: { backButton?: { label: string; state: LocationState; to: string; } | undefined; primary: string; secondary?: string | undefined; } | undefined; disabledFeatures: PieEditorFeature[] | undefined; ... 13 more ...; showHelperDialogs: boolean; }' is not assignable to type 'PortfolioOrganizerState'.
      return newState;
    }
    case 'UPDATE_IS_CRYPTO_PORTFOLIO_ORGANIZER':
      return {
        ...state,
        isCrypto: action.payload,
      };
    case ACTION_TYPES.UPDATE_PORTFOLIO_ORGANIZER_ACTIVE_SLICE_IDS:
      updatePieTreeByPath(state.pieTree, state.path, {
        toUpdateCheckState: [action.payload.ids, true],
      });

      return cloneDeep(state);

    case ACTION_TYPES.REMOVE_PORTFOLIO_ORGANIZER_ACTIVE_SLICE_IDS:
      updatePieTreeByPath(state.pieTree, state.path, {
        toUpdateCheckState: [action.payload.ids, false],
      });

      return cloneDeep(state);
    case ACTION_TYPES.FETCHING_DATA_IN_PIE_ORGANIZER:
      return updateSessionState(state, 'FETCHING');
    case ACTION_TYPES.FETCHED_PIE_DATA_FOR_ORGANIZER_SET_ROOT_PIE: {
      const mappedPie = mapRemoteSliceableByType(action.payload.pie);

      // this will always be an "old_pie" because that's what we are passing into our mapping function.
      // however, we've added a check here to appease flow. we could update our map function to return a "pie" if passed a pie as a potential typing improvement.
      if (mappedPie.type === 'old_pie') {
        mappedPie.slices = sortSlicesByPercentage(
          mappedPie.slices || [],
          'DESC',
        );

        return {
          ...updateSessionState(state, 'READY'),
          pieTree: mappedPie,
          pieTreeBeforeUpdates: cloneDeep(mappedPie),
        };
      }

      return state;
    }
    case ACTION_TYPES.FETCH_PIE_DATA_FOR_ORGANIZER_FAILED:
      return updateSessionState(state, 'READY');
    case ACTION_TYPES.ADD_SLICES_TO_PIE_ORGANIZER:
      if (state.session.state === 'UNINITIALIZED') {
        return state;
      }

      return updateSessionState(state, 'FETCHING');
    case ACTION_TYPES.FETCHED_PIE_SLICEABLES: {
      updatePieTreeByPath(state.pieTree, state.path, {
        toAdd: action.payload,
      });

      return updateSessionState(state, 'READY');
    }
    case ACTION_TYPES.ADD_SLICES_TO_PIE_ORGANIZER_FAILED:
      return updateSessionState(state, 'READY');
    case ACTION_TYPES.MOVED_PORTFOLIO_ORGANIZER_SLICES_ONTO_OTHER_SLICE: {
      const { movedSliceIds, destinationOntoSliceableId } = action.payload;

      const [activePie, targetPie] = [
        readPieTreeByPath(state.pieTree, state.path),
        readPieTreeBySliceId(state.pieTree, destinationOntoSliceableId),
      ];

      const slicesToMove = activePie.slices?.filter((slice) =>
        movedSliceIds.includes(slice.to.__id),
      );

      if (slicesToMove) {
        updatePieTreeByPath(state.pieTree, state.path, {
          toMove: [slicesToMove, targetPie],
        });
      }

      return cloneDeep(state);
    }
    case ACTION_TYPES.CREATED_PORTFOLIO_ORGANIZER_NEW_PIE_SLICE: {
      updatePieTreeByPath(state.pieTree, state.path, {
        toAdd: [action.payload],
      });
      return cloneDeep(state);
    }
    case ACTION_TYPES.ADJUSTED_PORTFOLIO_ORGANIZER_SLICE_PERCENTAGE: {
      updatePieTreeByPath(state.pieTree, state.path, {
        toAdjustPercentage: action.payload,
      });
      return cloneDeep(state);
    }
    case ACTION_TYPES.REMOVE_PORTFOLIO_ORGANIZER_SLICES: {
      updatePieTreeByPath(state.pieTree, state.path, {
        toRemove: action.payload,
      });

      return cloneDeep(state);
    }
    case ACTION_TYPES.UPDATE_PORTFOLIO_NAME:
      updatePieTreeByPath(state.pieTree, state.path, {
        toUpdateName: action.payload,
      });
      return cloneDeep(state);
    case ACTION_TYPES.UPDATE_PORTFOLIO_DESCRIPTION:
      updatePieTreeByPath(state.pieTree, state.path, {
        toUpdateDescription: action.payload,
      });
      return cloneDeep(state);
    case 'CLICKED_PORTFOLIO_ORGANIZER_BREADCRUMB':
      return {
        ...state,
        path: action.payload,
      };
    case ACTION_TYPES.CLICKED_PORTFOLIO_ORGANIZER_PIE_SLICE:
      return {
        ...state,
        path: [...state.path, action.payload.__id],
      };
    case ACTION_TYPES.TOGGLE_PORTFOLIO_ORGANIZER_HELPER_DIALOGS:
      return {
        ...state,
        showHelperDialogs: !state.showHelperDialogs,
      };
    case ACTION_TYPES.SAVE_PIE_ORGANIZER:
      return updateSessionState(state, 'SAVING');
    case ACTION_TYPES.CONFIRMED_PIE_ORGANIZER_EXIT:
    case ACTION_TYPES.SAVED_PIE_ORGANIZER:
      return initialState;
    case ACTION_TYPES.SAVE_PIE_ORGANIZER_FAILED:
      return updateSessionState(state, 'READY');
    default:
      return state;
  }
};
