import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent, formatPattern } from '~/router';

import { ResetPasswordPage } from './ResetPasswordPage';

export const ResetPasswordRoute = () => {
  return (
    <Route
      path="reset-password/:token"
      component={createRouteComponent(ResetPasswordPage, (props) => ({
        basePath: formatPattern(props.route.path, props.routeParams),
        token: props.params.token,
      }))}
      onEnter={({ params, routes }, replace) => {
        // TODO: test this route.path?.includes logic.
        // Find the route corresponding to name="reset-password"
        const routeIndex = routes.findIndex((route) =>
          route.path?.includes('reset-password'),
        );

        // Only redirect if
        // - the above found the route
        // - the route is not the last one. This forces the flow to always
        // start at "reset-password/:token".
        if (routeIndex !== -1 && routeIndex !== routes.length - 1) {
          const path = formatPattern(routes[routeIndex]?.path ?? '', params);
          // @ts-ignore Typing is overlapping with react-router-redux. We can fix this in the react-router upgrade.
          replace(path);
        }
      }}
    >
      <Route path="(:step)" />
    </Route>
  );
};
