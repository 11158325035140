import { Box } from '@m1/liquid-react';
import * as React from 'react';
import { InjectedRouteProps } from 'react-router';

import { ConnectBankFlow } from '~/flows/components';
import { useNavigate } from '~/hooks/useNavigate';
import { LocationParams } from '~/redux/actions/thunks/navigate';
import { useToast } from '~/toasts';

type Props = InjectedRouteProps & {
  accountId: string | null | undefined;
  dispatch: (...args: Array<any>) => any;
};

type HandleFinish = {
  achRelationshipId?: string | null | undefined;
  route?: string | null | undefined;
  locationParams?: LocationParams | null | undefined;
  shouldClearToast?: boolean;
};

export const ConnectBankPage = ({ location }: Props) => {
  const previousRouteName = location.query.previousRouteName;
  const navigate = useNavigate();
  const { clearToast } = useToast();

  const handleFinish = (action?: HandleFinish): void => {
    let nextRoute = '/d/invest/bank-connection';
    let locationParams;

    if (action?.shouldClearToast) {
      clearToast();
    }

    if (action?.route) {
      nextRoute = action.route;
    } else if (previousRouteName) {
      nextRoute = previousRouteName;
    }

    if (action?.locationParams) {
      locationParams = action.locationParams;
    }

    navigate({
      to: nextRoute,
      query: locationParams?.query,
      options: { state: locationParams?.state },
    });
  };

  const connectBankFlowProps = {
    initialStep: location.query?.initialStep
      ? location.query.initialStep
      : null,
    onFinish: handleFinish,
    canNavigateBackFromMicrodeposits: false,
    plaidRedirect: location.state?.plaidPayload
      ? location.state.plaidPayload
      : null,
    plaidFailure: location.state?.plaidFailure
      ? location.state.plaidFailure
      : false,
    fundingSourceId: location.query.fs ? location.query.fs : null,
    redirectUrl: '/d/c/connect-bank',
    connectionType: location.query.connectionType,
  };
  return (
    <Box width={550} m="0 auto" p="64px 0 100px 0">
      <ConnectBankFlow {...connectBankFlowProps} />
    </Box>
  );
};
