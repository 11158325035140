import { LocationDescriptor } from 'react-router';

export const readQuestionQueryParam = ({
  location,
}: {
  location: LocationDescriptor;
}): number | null | undefined => {
  const { query } = location;
  if (
    query &&
    query.q &&
    // eslint-disable-next-line
    Number(query.q) === Number(query.q)
  ) {
    return Number(query.q);
  }
  return null;
};
