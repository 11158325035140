import * as React from 'react';
import { Route } from 'react-router';

import { SetupIRAAccountPage } from './SetupIRAAccountPage';

export const SetupIraAccountRoute = () => {
  return (
    <Route
      path="setup-ira-account(/:step)"
      component={SetupIRAAccountPage}
      fallbackRoute="/onboarding"
      cover
    />
  );
};
