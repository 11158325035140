import * as React from 'react';

type ChartableSliceChartState = {
  isChartDataNull: boolean;
  setisChartDataNull: (isChartDataNull: boolean) => void;
};
type ChartablSliceChartProviderProps = {
  children: React.ReactNode;
};

const defaultChartableSliceChartState: ChartableSliceChartState = {
  isChartDataNull: false,
  setisChartDataNull: (isChartDataNull: boolean) => {},
};
const ChartableSliceChartContext = React.createContext(
  defaultChartableSliceChartState,
);
export const useChartableSliceChartContext = () => {
  return React.useContext(ChartableSliceChartContext);
};
export const ChartableSliceChartProvider = ({
  children,
}: ChartablSliceChartProviderProps) => {
  const [isChartDataNull, setisChartDataNull] = React.useState<boolean>(false);

  return (
    <ChartableSliceChartContext.Provider
      value={{ isChartDataNull, setisChartDataNull }}
    >
      {children}
    </ChartableSliceChartContext.Provider>
  );
};
