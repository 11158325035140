import merge from 'lodash-es/merge';
import pick from 'lodash-es/pick';
import { LOCATION_CHANGE } from 'react-router-redux';

import { ResetPasswordInput } from '~/graphql/types';
import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import { RESET_PASSWORD_FLOW_STEPS as STEPS } from '~/static-constants';

import { FlowState } from '../newFlowsReducer.types';
import { createFlowReducer, createStepReducer } from '../utils';

export type ResetPasswordFlowState = FlowState<
  string,
  {
    basePath: string;
    input: Partial<ResetPasswordInput>;
  }
>;

export const resetPasswordFlowInitialState: ResetPasswordFlowState = {
  basePath: '',
  input: {},
  step: STEPS.INPUT_NEW_PASSWORD,
  stepTitle: '',
};

const stepReducer = createStepReducer(resetPasswordFlowInitialState);

function reducer(
  state: ResetPasswordFlowState = resetPasswordFlowInitialState,
  action: any,
): ResetPasswordFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_FLOW:
      return {
        ...resetPasswordFlowInitialState,
        ...pick(action.payload, Object.keys(resetPasswordFlowInitialState)),
        input: {
          resetPasswordToken: action.payload.token,
        },
        step: resetPasswordFlowInitialState.step,
      };
    case ACTIONS.FINISHED_FLOW_STEP:
      switch (action.meta.step) {
        case STEPS.INPUT_NEW_PASSWORD:
        case STEPS.ACCOUNT_CHALLENGE:
          return {
            ...state,
            input: merge({}, state.input, action.payload),
          };
        case STEPS.ACCOUNT_CHALLENGE_FAILED:
          return {
            ...state,
            input: merge({}, pick(state.input, ['resetPasswordToken'])),
          };
        default:
          return state;
      }
    case LOCATION_CHANGE:
      return {
        ...state,
        step: stepReducer(state, action),
      };
    default:
      return state;
  }
}

export const resetPassword = createFlowReducer('RESET_PASSWORD', reducer);
