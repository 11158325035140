import classNames from 'classnames';
import * as React from 'react';

import style from './style.module.scss';

export type Props = {
  children: React.ReactNode;
  // TODO: Add specific component type
  disableHoverHighlight?: boolean;
  emptyMessage?: React.ReactNode;
  narrow?: boolean;
};

export class TableBody extends React.Component<Props> {
  static defaultProps = {
    emptyMessage: 'No Data to Display',
    narrow: false,
  };

  render() {
    const { narrow, children, disableHoverHighlight, emptyMessage } =
      this.props;
    const hasNoChildren = this.hasNoChildren();
    const classes = classNames(style.tableBody, {
      [style.noHover]: disableHoverHighlight,
      [style.narrow]: narrow,
      [style.tableBodyNoData]: hasNoChildren,
    });

    return (
      <tbody className={classes}>
        {hasNoChildren ? (
          <tr>
            <td>
              <div className={style.noData}>
                <span>{emptyMessage}</span>
              </div>
            </td>
          </tr>
        ) : (
          children
        )}
      </tbody>
    );
  }

  hasNoChildren(): boolean {
    const { children } = this.props;
    return React.Children.toArray(children).filter(Boolean).length === 0;
  }
}
