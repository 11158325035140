import { Flex, styled } from '@m1/liquid-react';

import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { PageErrorBoundary } from '~/components/page-error-boundary';
import { CreateTransferFlow } from '~/flows';
import { useLocation } from '~/hooks/useLocation';
import { returnToResolveMarginCallInformation } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { CREATE_TRANSFER_FLOW_STEPS as STEPS } from '~/static-constants';

type ResolveMarginCallCreateTransferProps = {
  handleFinish: (...args: Array<any>) => any;
};

// Styled Components
const StyledContainer = styled(Flex)`
  margin: auto;
  width: 550px;
`;

export const CreateTransferPage = (
  props: ResolveMarginCallCreateTransferProps,
) => {
  const [step, setStep] = React.useState<
    ValueOf<typeof STEPS> | null | undefined
  >(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const basePath = location.pathname;

  return (
    <PageErrorBoundary>
      <StyledContainer flexDirection="column" pb={32} pt={100}>
        {step === STEPS.SETUP_TRANSFER && (
          <BackArrow
            content="Resolve Call Options"
            mb={24}
            onClick={() =>
              // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
              dispatch(returnToResolveMarginCallInformation())
            }
          />
        )}
        <CreateTransferFlow
          basePath={basePath}
          onChangeStep={(step: ValueOf<typeof STEPS>) => setStep(step)}
          onFinish={props.handleFinish}
        />
      </StyledContainer>
    </PageErrorBoundary>
  );
};
