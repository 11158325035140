import * as React from 'react';
import { InjectedRouteProps } from 'react-router';

import { ChildrenWithProps } from '~/components/ChildrenWithProps';
import { usePersonalLoansPageQuery } from '~/graphql/hooks';
import { useLayout } from '~/hooks/useLayout';
import { useNavigate } from '~/hooks/useNavigate';
import { StyledPageContent } from '~/pages/dashboard/navigation/Navigation.styled';
import { setBorrowAccountDestination } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { PersonalLoansNotEligiblePage } from './not-eligible/PersonalLoansNotEligiblePage';

export const PersonalLoansPage = ({
  children,
  routes,
}: React.PropsWithChildren<Pick<InjectedRouteProps, 'routes'>>) => {
  const { contentWidth } = useLayout();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, loading } = usePersonalLoansPageQuery();
  const inPersonalLoanProductLandingPage = routes?.find(
    (route) => route?.path === 'transactions',
  );

  React.useEffect(() => {
    dispatch(setBorrowAccountDestination('personal'));
  }, [dispatch]);

  if (loading) {
    return <Spinner fullScreen centered />;
  }

  // This is to lock the "borrow/personal" URLs from anyone without the flag
  if (!data?.viewer.borrow?.isEligibleToApplyForPersonalLoan) {
    return <PersonalLoansNotEligiblePage />;
  }
  if (!children) {
    // In unlikely scenario user visits /borrow/personal
    // The following will redirect them to either manage-loan
    // or the application flow depending on their current loan
    if (data.viewer.borrow.hasActivePersonalLoan) {
      navigate({ to: `/d/borrow/personal/manage-loan` });
      return null;
    }

    navigate({ to: `/d/borrow/marketing` });
    return null;
  }

  return inPersonalLoanProductLandingPage ? (
    <ChildrenWithProps children={children} />
  ) : (
    <StyledPageContent contentWidth={contentWidth}>
      <ChildrenWithProps children={children} />
    </StyledPageContent>
  );
};
