import { SkeletonGroup, styled } from '@m1/liquid-react';

import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

export const HighchartsStyles = styled(SkeletonGroup)<{
  $isPieGraphSection: boolean;
  $isOnDarkBackground: boolean;
  $hiddenSeries?: number[];
}>`
  /* A lot of these styles are HTML style attributes from highcharts, so we have to make them important to override */
  .highcharts-range-selector-buttons .highcharts-label,
  .highcharts-scrollbar {
    display: none;
  }

  ${({ $isPieGraphSection }) =>
    $isPieGraphSection
      ? `.highcharts-range-selector-group > .highcharts-range-selector-buttons {
      @media ${RESPONSIVE_BREAKPOINTS.TABLET} {
        transform: translate(320px, 10px) !important;
      }
    }`
      : ''}

  /* Exporting Menu Button */
  .highcharts-contextbutton {
    /* Icon color in button */
    > path {
      stroke: var(--colors-foreground-neutral-inverse) !important;
    }

    &.highcharts-button-hover {
      > rect {
        fill: ${({ $isOnDarkBackground }) =>
          $isOnDarkBackground
            ? 'var(--colors-background-plot-feature) !important'
            : 'var(--colors-border-main) !important'};
      }
    }

    &.highcharts-button-pressed {
      fill: var(--colors-background-neutral-main) !important;
      > rect {
        fill: var(--colors-background-neutral-main) !important;
      }

      span {
        color: var(--colors-foreground-neutral-main) !important;
      }
    }
  }

  /* Exporting Menu Dropdown */
  .highcharts-contextmenu {
    font-size: ${({ theme }) => theme.typography.PL.fontSize} !important;

    .highcharts-menu {
      background: var(--colors-background-neutral-tertiary) !important;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;

      > hr {
        border: 1px solid var(--colors-border-main);
        margin: 8px;
      }

      .highcharts-menu-item {
        color: var(--colors-foreground-neutral-main) !important;

        &:hover {
          background: var(--colors-border-main) !important;
        }
      }
    }
  }

  .highcharts-input-group {
    transform: translate3d(10px, 15px, 0);
  }

  .highcharts-yaxis-labels {
    transform: translateY(4px);
  }
`;
