import * as React from 'react';
import { Route } from 'react-router';

import { PortfolioEditorPage } from './PortfolioEditorPage';

export const PortfolioEditorRoute = () => {
  return (
    <Route
      path="portfolio-editor(/:step)"
      component={({ location }) => {
        return (
          <PortfolioEditorPage
            portfolioSliceableId={location.state?.portfolioSliceableId}
            portfolioSliceId={location.state?.portfolioSliceId}
            sliceableIds={location.state?.sliceableIds}
          />
        );
      }}
      fallbackRoute="/d/invest"
    />
  );
};
