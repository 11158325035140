import { Box, Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import { defaultProps } from '~/hocs';

import { email, optionalPhoneNumber, required } from '../validators';

import { PhoneNumberField } from './phone-number-field';
import { TextField } from './text-field';

type TrustedContactFieldProps = {
  backgroundColor?: 'backgroundNeutralMain' | 'backgroundNeutralSecondary';
  showLongInputs?: boolean;
};

const StyledInputWrapper = styled(Box)`
  width: 100%;
  margin-right: 24px;
`;

// @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type. | TS7006 - Parameter 'allValues' implicitly has an 'any' type.
const validateContactFields = (value, allValues) => {
  const isRequired =
    // @ts-expect-error - TS2367 - This condition will always return 'false' since the types 'boolean' and 'string' have no overlap.
    Boolean(!allValues.email || !allValues.email === '') &&
    // @ts-expect-error - TS2367 - This condition will always return 'false' since the types 'boolean' and 'string' have no overlap.
    Boolean(!allValues.phoneNumber || !allValues.phoneNumber === '');

  if (isRequired && !value) {
    return 'Must provide email or phone number';
  }
};

const enhancer = defaultProps({
  validate: [validateContactFields, optionalPhoneNumber],
});

const ConditionalPhoneNumberField = enhancer(PhoneNumberField);

export const TrustedContactFields = (props: TrustedContactFieldProps) => {
  return (
    <Box>
      <Flex flexDirection="column">
        <StyledInputWrapper>
          <TextField
            backgroundColor={props.backgroundColor}
            name="firstName"
            label="First name"
            validate={[required]}
          />
        </StyledInputWrapper>
        <Box mt={16}>
          <TextField
            backgroundColor={props.backgroundColor}
            name="lastName"
            label="Last name"
            validate={[required]}
          />
        </Box>
      </Flex>
      {props.showLongInputs ? (
        <>
          <Box mt={16}>
            <TextField
              backgroundColor={props.backgroundColor}
              name="email"
              label="Email"
              mr={24}
              validate={[validateContactFields, email]}
            />
          </Box>
          <Box mt={16}>
            <ConditionalPhoneNumberField
              // @ts-expect-error - TS2322 - Type '{ name: string; backgroundColor: "backgroundNeutralMain" | "backgroundNeutralSecondary" | undefined; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<Omit<{ validate: (((value: any, allValues: any) => "Must provide email or phone number" | undefined) | ((value: string | null | undefined) => string | ... 1 more ... | undefined))[]; }, "validate"> & Partial<...>, any, any>> & Readonly<...>'.
              name="phoneNumber"
              backgroundColor={props.backgroundColor}
            />
          </Box>
        </>
      ) : (
        <Flex flexDirection="row">
          <StyledInputWrapper>
            <TextField
              backgroundColor={props.backgroundColor}
              name="email"
              label="Email"
              mr={24}
              validate={[validateContactFields, email]}
            />
          </StyledInputWrapper>
          <ConditionalPhoneNumberField
            // @ts-expect-error - TS2322 - Type '{ backgroundColor: "backgroundNeutralMain" | "backgroundNeutralSecondary" | undefined; name: string; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<Omit<{ validate: (((value: any, allValues: any) => "Must provide email or phone number" | undefined) | ((value: string | null | undefined) => string | ... 1 more ... | undefined))[]; }, "validate"> & Partial<...>, any, any>> & Readonly<...>'.
            backgroundColor={props.backgroundColor}
            name="phoneNumber"
          />
        </Flex>
      )}
    </Box>
  );
};
