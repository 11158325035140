import * as React from 'react';
import { Route } from 'react-router';

import { requireAuth, RouteHandlers } from '~/router';

import { LogoutPage } from './LogoutPage';

export const LogoutRoute = (routeHandlers: RouteHandlers) => {
  return (
    <Route
      path="logout"
      component={LogoutPage}
      onEnter={routeHandlers(requireAuth)}
    />
  );
};
