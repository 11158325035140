import React from 'react';
import { IMask, useIMask } from 'react-imask';

interface RootMaskedInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'size'
  > {
  maskOptions: IMask.AnyMaskedOptions;
  onChange: (numericValue: number | null, maskValue: string | null) => void;
  inputComponent: React.ComponentClass<any> | React.FC<any>;
}

export const RootMaskedInput = ({
  maskOptions,
  defaultValue,
  onChange,
  inputComponent: InputComponent,
  ...inputProps
}: RootMaskedInputProps) => {
  const [savedDefaultValue, setSavedDefaultValue] = React.useState<
    string | number | null | readonly string[] | undefined
  >(null);

  // react-imask input uses "onAccept" instead of "onChange"
  const onAccept = (
    _val: string,
    mask: IMask.InputMask<IMask.AnyMaskedOptions>,
  ) => {
    const numericValue = Number(mask.unmaskedValue);
    onChange(numericValue === 0 ? null : numericValue, mask.value);
  };

  /*
   * Initialize iMask input state:
   */
  const { ref, value, maskRef, unmaskedValue, setUnmaskedValue } = useIMask(
    {
      unmask: true,
      ...maskOptions,
    },
    { onAccept },
  );

  if (inputProps.value !== unmaskedValue) {
    setUnmaskedValue(inputProps.value as string);
  }

  /*
   * Initialize input with default value:
   * Note that you must set unmasked and masked values.
   */
  React.useEffect(() => {
    if (!defaultValue) {
      return;
    }
    if (savedDefaultValue !== null) {
      return;
    }
    const maskedValue = IMask.pipe(defaultValue, maskOptions);
    setSavedDefaultValue(defaultValue);
    if (maskRef?.current) {
      maskRef.current.unmaskedValue = String(defaultValue);
      maskRef.current.value = maskedValue;
      maskRef.current.updateCursor(ref.current.selectionStart ?? 0);
    }
  }, [defaultValue, savedDefaultValue, maskRef, ref]);

  return (
    <InputComponent
      {...{
        ...inputProps,
        ref,
        /* We need to pass in an onChange function here,
         * even though it is unused due to onAccept()
         * (otherwise TS will complain)
         */
        onChange: () => {
          /* do nothing here because we are using onAccept instead */
        },
        value,
      }}
    />
  );
};
