import { Flex, HS, HL, HXXS, Box } from '@m1/liquid-react';
import castArray from 'lodash-es/castArray';
import moment from 'moment';
import * as React from 'react';

import { useMediaQuery } from 'react-responsive';

import { useSharePieDetailsQuery } from '~/graphql/hooks';
import {
  InformationBannerFragment,
  PieBacktestPeriodEnum,
} from '~/graphql/types';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { usePortaledSpinner } from '~/hooks/usePortaledSpinner';
import { InformationBanner } from '~/lens-toolbox/InformationBanner';

import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

import { Link } from '~/toolbox/link';

import { EditModel } from '../../SharePiePage.types';

import { SharePiePerformanceChartSection } from '../SharePiePerformanceChartSection';

import { SharedPieMetrics } from './components/SharedPieMetrics';
import { SharePieDonutChartCard } from './components/SharePieDonutChartCard.tsx/SharePieDonutChartCard';
import { SharePiePageSliceList } from './components/SharePiePageSliceList';
import {
  getBreadcrumbLinks,
  PATH_DELIMITER,
  readPath,
} from './SharePieDetailsSection.utils';

export type SharePieDetailsSectionProps = {
  isPieActive: boolean;
  localModel: EditModel;
  token: string;
  parsedModel: any;
  delistedBanner?: InformationBannerFragment | null;
  handleCTAClick: () => void;
};

export const SharePieDetailsSection = ({
  isPieActive,
  localModel,
  parsedModel,
  token,
  delistedBanner,
  handleCTAClick,
}: SharePieDetailsSectionProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.SMALL,
  });

  const { data, loading } = useSharePieDetailsQuery({
    variables: {
      editModel: JSON.stringify(localModel) as string,
      period: PieBacktestPeriodEnum.FiveYears,
    },
    skip: !localModel,
  });

  usePortaledSpinner(loading);

  const { dividendYield, netExpenseRatio } =
    data?.viewer.readPieEditModel?.analysis ?? {};

  const pieAnalysis = data?.viewer.readPieEditModel?.analysis;
  const sinceDateLabel = pieAnalysis?.backtesting?.startTime
    ? moment(pieAnalysis?.backtesting?.startTime).format('MMM DD, YYYY')
    : '--';

  const { name, slices } = localModel;
  const valuePercentChange = pieAnalysis?.backtesting?.valuePercentChange;

  const handlePieSliceClick = (id: string | null | undefined): void => {
    const index = slices.findIndex((s: any) => s.to.id === id);
    const path = castArray(readPath(location));

    navigate({
      to: '/share',
      query: { path: [...path, index].join(PATH_DELIMITER), token },
    });
  };

  const breadcrumbLinks = getBreadcrumbLinks(location, parsedModel, name);

  return (
    <>
      <Flex
        alignItems="center"
        mt={12}
        flexDirection="column"
        textAlign="center"
      >
        <HL fontWeight={300}>{name}</HL>
        <Box>
          {breadcrumbLinks &&
            breadcrumbLinks.length > 0 &&
            breadcrumbLinks?.map(({ label, to }, i) => (
              <Box display="inline-flex" key={to} mt={16}>
                {to ? (
                  <Link to={to} font="HXXS" fontWeight={400}>
                    {label}
                  </Link>
                ) : (
                  <HXXS>{label}</HXXS>
                )}
                {i < breadcrumbLinks.length - 1 && <HXXS mx={8}>/</HXXS>}
              </Box>
            ))}
        </Box>
        {delistedBanner && (
          <InformationBanner
            mt={32}
            appBanner={delistedBanner}
            onClick={handleCTAClick}
            textAlign="left"
          />
        )}
      </Flex>
      <Flex
        alignContent="stretch"
        justifyContent="space-between"
        width="100%"
        maxWidth={1200}
        margin="48px auto 0"
        flexWrap="wrap"
      >
        <Flex flexGrow={1} justifyContent="center" mt={24}>
          <SharePieDonutChartCard
            slices={slices}
            sinceDate={sinceDateLabel}
            valuePercentChange={isPieActive ? valuePercentChange : null}
          />
        </Flex>
        <Box
          flexGrow={1}
          px={isMobile ? 16 : 32}
          mt={16}
          maxWidth="100%"
          mb={isPieActive ? 0 : 16}
        >
          <HS fontWeight={300} mb={20}>
            Target allocations
          </HS>
          <SharePiePageSliceList
            slices={slices}
            handleClickOnSlice={handlePieSliceClick}
          />
        </Box>
      </Flex>
      {isPieActive && (
        <>
          <SharedPieMetrics
            dividendYield={dividendYield}
            valuePercentChange={valuePercentChange}
            netExpenseRatio={netExpenseRatio}
            sinceDate={sinceDateLabel}
          />

          <SharePiePerformanceChartSection
            pieId={localModel.id}
            sinceDate={sinceDateLabel}
          />
        </>
      )}
    </>
  );
};
