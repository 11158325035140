import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import { createRouteComponent } from '~/router';
import { Spinner } from '~/toolbox/spinner';

import { VerifyEmailFailure } from './components/VerifyEmailFailure';
import { VerifyEmailSuccess } from './components/VerifyEmailSuccess';
import { VerifyEmailPage } from './VerifyEmailPage';

export const VerifyEmailRoute = () => {
  return (
    <Route
      path="verify-email(/:verificationToken)"
      component={createRouteComponent(VerifyEmailPage, (props) => ({
        token: props.params.verificationToken,
      }))}
    >
      <Route path="success" component={VerifyEmailSuccess} />
      <Route path="error" component={VerifyEmailFailure} />
      <IndexRoute component={Spinner} />
    </Route>
  );
};
