import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { PortfolioEditorFlow } from '~/flows/components/portfolio-editor/PortfolioEditorFlow';
import { useNavigate } from '~/hooks/useNavigate';
import { addSlicesToPortfolioEditorAction } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

type PortfolioEditorPageProps = {
  portfolioSliceableId: string;
  portfolioSliceId: string;
  sliceableIds?: Array<string>;
};

export const PortfolioEditorPage = ({
  portfolioSliceableId,
  portfolioSliceId,
  sliceableIds,
}: PortfolioEditorPageProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFinish = (): void => {
    navigate({ to: '/d/invest' });
  };

  React.useEffect(() => {
    if (sliceableIds) {
      dispatch(addSlicesToPortfolioEditorAction(sliceableIds));
    }
  }, [dispatch, sliceableIds]);

  return (
    <PageErrorBoundary onClickRetry={handleFinish} retryButtonLabel="Exit">
      <PortfolioEditorFlow
        basePath="d/invest/portfolio-editor"
        portfolioSliceableId={portfolioSliceableId}
        portfolioSliceId={portfolioSliceId}
        onFinish={handleFinish}
      />
    </PageErrorBoundary>
  );
};
