import { Flex, HM } from '@m1/liquid-react';
import { Illustration } from '@m1/liquid-react/illustrations';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { AspectRatioFrame } from '~/flows/components/personal-loans/application/components/AspectRatioFrame';
import { useIraFundingTypeQuery } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useBreakpoints } from '~/hooks/useBreakpoints';
import { useHistory } from '~/hooks/useHistory';
import { setIRAFundingType } from '~/redux/actions/newFlows/onboarding/onboardingActions';
import { useDispatch } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';
import { isTruthy } from '~/utils/isTruthy';

import { AccountSelectionCard } from '../components/AccountSelectionCard';
import { useIRAWizardContext } from '../hooks/useIRAWizardContext';

export const SelectIRAFundingTypeStep = () => {
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const history = useHistory();
  const handleBack = (): void => history.goBack();

  const { isDesktop, isMedium } = useBreakpoints();
  const { data, loading } = useIraFundingTypeQuery();
  const { goTo } = useIRAWizardContext();

  const iraFundingTypeSelectionScreen =
    data?.viewer.onboarding?.onboardingInvest.iraOnboarding
      .iraFundingSelectionScreen;

  const analyticsEvent =
    iraFundingTypeSelectionScreen?.screenViewedAnalyticsEvent;

  React.useEffect(() => {
    if (analyticsEvent) {
      analytics.recordAppAnalyticsEvent(analyticsEvent);
    }
  }, [analytics, analyticsEvent]);

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!iraFundingTypeSelectionScreen) {
    return <GenericSystemError />;
  }

  const iraFundingTypes = iraFundingTypeSelectionScreen?.fundingTypes;

  const handleClick = (value: string) => {
    if (value === 'NEW') {
      dispatch(setIRAFundingType('NEW'));
      goTo('SELECT_IRA_ACCOUNT_TYPE');
    }

    if (value === 'ROLLOVER') {
      dispatch(setIRAFundingType('ROLLOVER'));
      goTo('SELECT_IRA_ROLLOVER_TYPE');
    }
  };

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      maxWidth={1200}
      mx="auto"
      mt={88}
      px={isMedium ? 48 : 0}
      width="100%"
    >
      <BackArrow
        alignSelf="flex-start"
        content="Back"
        my={48}
        onClick={handleBack}
      />
      <Flex width="100%">
        <Flex
          flexDirection="column"
          width={isDesktop ? '50%' : '100%'}
          mr={isDesktop ? 126 : 0}
        >
          <HM content={iraFundingTypeSelectionScreen.title} mb={48} />
          <>
            {iraFundingTypes.filter(isTruthy).map((card, index) => (
              <AccountSelectionCard
                key={index}
                card={card}
                onClick={handleClick}
              />
            ))}
          </>
        </Flex>
        {isDesktop && (
          <Flex flexDirection="column" p={24} width="50%">
            <AspectRatioFrame ratio="4:3">
              <Illustration name="directToIRA2Web" />
            </AspectRatioFrame>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
