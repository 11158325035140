import { Flex, styled } from '@m1/liquid-react';
import * as React from 'react';

import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';

import { Link, type LinkProps } from '../link';

export type NavigationItems = Array<
  LinkProps & {
    label?: React.ReactNode;
  }
>;

export type NavigationProps = {
  // @ts-expect-error - TS2694 - Namespace 'React' has no exported member 'ChildrenArray'.
  children?: React.ChildrenArray<React.ReactElement<typeof Link>>;
  items?: NavigationItems;
  orientation: 'horizontal' | 'vertical';
};

const StyledNav = styled.nav`
  display: flex;
  flex-direction: ${(props) =>
    // @ts-expect-error - TS2339 - Property 'orientation' does not exist on type 'ThemedStyledProps<Pick<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "key" | keyof HTMLAttributes<HTMLElement>> & { ...; }, any>'.
    props.orientation === 'horizontal' ? 'row' : 'column'};
  align-items: stretch;

  @media screen and ${RESPONSIVE_BREAKPOINTS.SMALL} {
    flex-wrap: wrap;
    justify-content: center;
  }

  a {
    color: ${(props) => props.theme.colors.foregroundNeutralMain};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    min-width: 96%;
  }
`;

const isExternalLink = (link: string) => /^https?/.test(link);

export const Navigation = ({
  children,
  items,
  orientation,
}: NavigationProps) => {
  if (!items) {
    return children || null;
  }

  return (
    // @ts-expect-error - TS2769 - No overload matches this call.
    <StyledNav orientation={orientation}>
      {items.map(({ label, onClick, to, ...rest }) => (
        <Flex key={to} onClick={onClick}>
          {isExternalLink(to as string) ? (
            <Link
              title={`View ${label}`}
              target="_blank"
              to={to}
              fontWeight={400}
              font="PL"
              lineHeight="20px"
              {...rest}
            >
              {label}
            </Link>
          ) : (
            <Link
              to={to}
              fontWeight={400}
              font="PL"
              lineHeight="20px"
              {...rest}
            >
              {label}
            </Link>
          )}
        </Flex>
      ))}
    </StyledNav>
  );
};
