import { Flex } from '@m1/liquid-react';
import * as React from 'react';

import { PageErrorBoundary } from '~/components/page-error-boundary';
import { SetOrderFlow } from '~/flows/components';

export type SetOrderPageProps = {
  direction?: 'buy' | 'sell';
  isFromHoldingsPositionDetail?: boolean;
  portfolioSliceId: string;
  showBuyingPowerOverview: boolean;
};

export const SetOrderPage = ({
  portfolioSliceId,
  direction = 'buy',
  isFromHoldingsPositionDetail,
  showBuyingPowerOverview,
}: SetOrderPageProps) => {
  return (
    <Flex justifyContent="center" p="100px 0">
      <PageErrorBoundary>
        <SetOrderFlow
          basePath={`d/c/set-order/${encodeURIComponent(portfolioSliceId)}`}
          direction={direction}
          showBuyingPowerOverview={showBuyingPowerOverview}
          isFromHoldingsPositionDetail={isFromHoldingsPositionDetail}
          portfolioSliceId={portfolioSliceId}
        />
      </PageErrorBoundary>
    </Flex>
  );
};
