import { Theme } from '@m1/liquid-react';
import * as d3 from 'd3';
import moment from 'moment-timezone';
import * as React from 'react';

import {
  formatLargeNumber,
  formatFill,
} from '../../../utils/format-large-number';

import style from './style.module.scss';

type Input = {
  bars: React.ElementRef<any> | null | undefined;
  data: Array<DataPoint>;
  height: number;
  intradayDateMinutes: Array<string> | null | undefined;
  theme: Theme;
  width: number;
};

type DataPoint = {
  date: string;
  shareVolume: number;
};
type D3Selection = Record<string, any>;

export class SecurityVolumeBarsD3 {
  input: Input;
  // @ts-expect-error - TS2564 - Property 'd3' has no initializer and is not definitely assigned in the constructor.
  d3: D3Selection;
  chartMargin: Record<string, any>;
  constructor(input: Input) {
    this.input = input;
    this.chartMargin = {
      top: 15,
      right: 0,
      bottom: 30,
      left: 0,
    };
    this.initializeBars();
  }

  initializeBars() {
    // @ts-expect-error - TS2769 - No overload matches this call.
    const svg = d3.select(this.input.bars);

    const group = svg
      .append('g')
      .attr('height', this.input.height)
      .attr('width', this.input.width)
      .attr('transform', `translate(${this.chartMargin.left},0)`);

    const xRange = this.calculateXRange();

    const xScale = d3
      .scaleBand()
      .domain(this.input.data.map((datum) => datum.date))
      .range(xRange)
      .paddingInner(0.2);

    const yScale = d3
      .scaleLinear()
      // @ts-expect-error - TS2345 - Argument of type '[number, number] | [undefined, undefined]' is not assignable to parameter of type 'Iterable<NumberValue>'.
      .domain(d3.extent(this.input.data.map((datum) => datum.shareVolume)))
      .range([this.input.height - this.chartMargin.top, 0]);

    const yAxis = d3
      .axisLeft(yScale)
      .ticks(3, 'f')
      .tickSizeInner(-this.input.width)
      .tickSizeOuter(0)
      .tickPadding(-30);

    const yAxisSelection = svg
      .append('g')
      .attr('transform', `translate(${this.chartMargin.left},0)`)
      .attr('class', style.yAxis);

    const bars = group.selectAll('rect').data(this.input.data);

    this.d3 = {
      bars,
      group,
      svg,
      xScale,
      yScale,
      yAxis,
      yAxisSelection,
    };
    this.updateBars();
  }

  updateBars() {
    const { bars, xScale, yScale, yAxis, yAxisSelection } = this.d3;
    xScale.domain(this.input.data.map((datum) => datum.date));
    yScale.domain(d3.extent(this.input.data.map((datum) => datum.shareVolume)));
    // @ts-expect-error - TS7006 - Parameter 'x' implicitly has an 'any' type.
    yAxis.tickFormat((x) => formatLargeNumber(x));
    yAxisSelection.style('opacity', 1).call(yAxis);
    bars
      .enter()
      .append('rect')
      .merge(bars)
      .attr('class', 'volumeBars')
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .attr('x', (d) => xScale(d.date))
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .attr('y', (d) => yScale(d.shareVolume))
      .attr('width', xScale.bandwidth())
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .attr('height', (d) => this.input.height - yScale(d.shareVolume))
      // @ts-expect-error - TS7006 - Parameter 'd' implicitly has an 'any' type.
      .attr('fill', (d) =>
        formatFill(d.previousQuoteClosePrice, d.value, this.input.theme),
      );
    // update with previous period close

    bars.exit().remove();
  }
  calculateXRange() {
    // add intraday interpolation
    const dataLength = this.input.data.length;
    if (dataLength > 1) {
      const intraDay = this.input.intradayDateMinutes;
      const currentlTime = moment().startOf('minute').toISOString();
      const intraDayCurrentTime = intraDay?.indexOf(currentlTime);

      // range for intraday chart - using the current time set to determine the chart range
      if (intraDay && intraDayCurrentTime && intraDayCurrentTime >= 0) {
        // getting the minute separated dates to use
        const currentTradingPeriod = intraDay.slice(0, intraDayCurrentTime);
        // how we're making progress throughout the day. The intraday range should only be a percentage of full width.
        const currentTradingWidth =
          this.input.width * (currentTradingPeriod.length / intraDay.length);
        return [0, currentTradingWidth];
      }
    }
    return [0, this.input.width];
  }
  removeBars() {
    const { bars, group, yAxisSelection } = this.d3;
    yAxisSelection.remove();
    group.remove();
    bars.remove();
  }
}
