import * as React from 'react';
import { Route } from 'react-router';

import { ActivateCreditCardPage } from './ActivateCreditCardPage';

export const ActivateCreditCardCover = () => {
  return (
    <Route
      path="activate-credit-card"
      component={ActivateCreditCardPage}
      fallbackRoute="/d/spend/credit/transactions"
      lastVisited="/d/spend/credit/transactions"
      navigationState={{
        forceRefetch: true,
      }}
      cover
    />
  );
};
