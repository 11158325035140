import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import { StockScreenerPage } from './components';

import { ResearchStockDetailsRoute } from './details';

export const StockScreenerRoute = () => {
  return (
    <Route path="stocks" ignoreScrollBehavior>
      {ResearchStockDetailsRoute()}
      <IndexRoute component={StockScreenerPage} />
    </Route>
  );
};
