import { css, SemanticColorNames, styled } from '@m1/liquid-react';

export const StyledInputContainer = styled.div<{
  backgroundColor?: SemanticColorNames;
  disabled?: boolean;
  error?: string | boolean;
  iconAndContentOrder?: 'left' | 'right';
  isFocused: boolean;
}>`
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${({ theme, error, iconAndContentOrder, isFocused, backgroundColor }) => {
    return css`
      background-color: ${backgroundColor
        ? theme.colors[backgroundColor]
        : 'inherit'};
      border: 1px solid;
      border-color: ${error
        ? theme.colors.borderCritical
        : theme.colors.borderMain};
      border-radius: 9px;
      flex-direction: ${iconAndContentOrder === 'left' ? 'row-reverse' : 'row'};
      ${isFocused &&
      css`
        border-color: ${error ? theme.colors.critical : theme.colors.primary};
      `}
    `;
  }};
`;
