import { Box } from '@m1/liquid-react';
import * as React from 'react';
import { LocationDescriptor } from 'react-router';

import { CoverEscrow } from '~/components/cover';
import { AppNotifications } from '~/components/notifications';
import { LayoutProvider } from '~/hooks/useLayout';
import { InjectedRouteProps } from '~/router';

import { Navigation } from './navigation/Navigation';

export const DashboardPage = ({
  children,
  routes,
}: InjectedRouteProps & {
  children: React.ReactElement<any>;
  location: LocationDescriptor;
}) => {
  // This accounts for z-index issues when rendering covers not at the
  // route d/c. If this isn't present, <DashboardHeader /> is rendered on top
  // of the cover. Not ideal.
  const currentlyRenderingACover = routes.some((route) => route.cover);

  return (
    <LayoutProvider>
      <Box>
        <Box
          {...(!currentlyRenderingACover && {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 101,
          })}
        >
          <AppNotifications />
        </Box>
        <CoverEscrow
          enabled={routes.some(
            (route) => route.path === '/d/c' || route.path === 'c',
          )}
          routes={routes}
        >
          <Navigation>{children}</Navigation>
        </CoverEscrow>
      </Box>
    </LayoutProvider>
  );
};
