import * as React from 'react';

/**
 * Renders React component children with desired props
 * @param children Child components of React component (this.props.children)
 * @param props Props to pass to children
 * @returns React.Element Children with new props
 */
export const ChildrenWithProps = ({
  children,
  props,
}: {
  children: React.ReactNode | undefined;
  props?: Record<string, any>;
}) => {
  if (!children) {
    return null;
  }

  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child as React.ReactElement, props);
      })}
    </>
  );
};
