import { SagaIterator } from 'redux-saga';
import { fork, getContext, put, take } from 'redux-saga/effects';

import { ACTION_TYPES as ACTIONS, navigate } from '~/redux/actions';

export function* clickedSetOrder(opts: {
  isFromHoldingsPositionDetail?: boolean;
  portfolioSliceId: string;
  previousRouteName?: string;
  setAsSellOrder?: boolean;
}): SagaIterator<void> {
  const history = yield getContext('history');
  const previousLocation =
    opts.previousRouteName || history.getCurrentLocation();
  // If flow is canceled, return to wherever the user came from.
  // Should this be here or within setOrderFlow? Or maybe on a page monitor?
  yield fork(function* () {
    yield take([
      ACTIONS.CLICKED_CANCEL_SET_ORDER,
      ACTIONS.FINISHED_SET_ORDER_FLOW,
    ]);
    yield put(navigate({ to: previousLocation }));
  });
  yield put(
    navigate({
      to: '/d/c/set-order/:portfolioSliceId',
      params: {
        portfolioSliceId: opts.portfolioSliceId,
      },
      query: {
        direction: opts.setAsSellOrder ? 'sell' : 'buy',
        isFromHoldingsPositionDetail: opts.isFromHoldingsPositionDetail,
      },
    }),
  );
}
