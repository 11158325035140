import { Button } from '@m1/liquid-react';
import * as React from 'react';

import { PieOrganizerConfig } from '~/components/PortfolioOrganizerPage';
import { UpdatePieTreeMutationResult } from '~/graphql/hooks';

import {
  MoveSecuritiesConfirmTargetsFragment,
  ValidSliceDestinationFragment,
} from '~/graphql/types';
import { useNavigate } from '~/hooks/useNavigate';
import { moveUnmanagedHoldings } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

type UnmanagedHoldingsSelectParentPieContinueButtonProps = {
  label: string;
  moveSecuritiesConfirmTargets: MoveSecuritiesConfirmTargetsFragment;
  newPie: any;
  previousPieEditorConfig: PieOrganizerConfig;
  selectedRow: ValidSliceDestinationFragment | null | undefined;
};

export const UnmanagedHoldingsSelectParentPieContinueButton = ({
  label,
  moveSecuritiesConfirmTargets,
  newPie,
  previousPieEditorConfig,
  selectedRow,
}: UnmanagedHoldingsSelectParentPieContinueButtonProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { unmanagedHoldingsToMove } = previousPieEditorConfig;
  const grandparentPortfolioSliceId = selectedRow?.id;

  const onConfirm = React.useCallback(
    (config: PieOrganizerConfig, result: UpdatePieTreeMutationResult) => {
      const portfolioSlice =
        result.data?.updatePieTree?.pie?.portfolioLinks.find(
          (link) => link.id === grandparentPortfolioSliceId,
        );

      const matchingChild = portfolioSlice?.children.find(
        (child) => child?.to.id === newPie.id,
      );

      if (!matchingChild) {
        dispatch({
          payload: {
            content:
              'This update could not be saved. Try again or contact us for further assistance.',
            duration: 'short',
            kind: 'alert',
          },
          type: 'ADD_TOAST',
        });
      }

      if (unmanagedHoldingsToMove && matchingChild) {
        dispatch(
          moveUnmanagedHoldings({
            parentPortfolioSliceId: matchingChild.id,
            unmanagedHoldingIds: unmanagedHoldingsToMove,
          }),
        );
      }
    },
    [dispatch, grandparentPortfolioSliceId, newPie.id, unmanagedHoldingsToMove],
  );

  const onClick = React.useCallback(() => {
    if (selectedRow) {
      const pieOrganizerConfig: PieOrganizerConfig = {
        buttons: {
          backButton: {
            label: 'Choose a location',
            state: {
              pie: newPie,
              previousConfig: previousPieEditorConfig,
            },
            to: '/d/invest/select-parent-pie',
          },
          primary: moveSecuritiesConfirmTargets.saveTargetsCtaLabel
            ? moveSecuritiesConfirmTargets.saveTargetsCtaLabel
            : 'Continue',
        },
        confirmationDialog: {
          showApplicableLocations: false,
        },
        disabledFeatures: [
          'ADD_SLICES',
          'MOVE_SLICES',
          'REMOVE_SLICES',
          'CREATE_PIE',
        ],
        mode: 'EDIT_PIE',
        onConfirm,
        pieId: selectedRow.to.id,
        returnTo: '/d/invest/unmanaged-holdings',
        sliceableIds: [newPie.id],
        subtitle: moveSecuritiesConfirmTargets.header
          ? moveSecuritiesConfirmTargets.header
          : undefined,
        subtitleLink: moveSecuritiesConfirmTargets.link,
        title: moveSecuritiesConfirmTargets.title
          ? moveSecuritiesConfirmTargets.title
          : undefined,
        // clear our unmanagedHoldingsToMove because in the create state,
        // the uh is the grandchild of the pie being edited, not the immediate child
        unmanagedHoldingsToMove: [],
        zeroPercentDisabled: true,
      };

      navigate({
        to: '/d/invest/portfolio-organizer',
        options: {
          state: {
            event: {
              type: 'INITIALIZE_ORGANIZER',
              initConfig: pieOrganizerConfig,
            },
          },
        },
      });
    }
  }, [
    navigate,
    moveSecuritiesConfirmTargets,
    newPie,
    onConfirm,
    previousPieEditorConfig,
    selectedRow,
  ]);

  return (
    <Button
      disabled={!selectedRow}
      label={label}
      onClick={onClick}
      size="large"
    />
  );
};
