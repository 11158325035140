import { Flex, Maybe, PM, Tooltip } from '@m1/liquid-react';
import * as React from 'react';

import { AppTooltipFragment } from '~/graphql/types';
import { AppTooltip } from '~/lens-toolbox/AppTooltip';

import { PortfolioDetailsContext } from '../PortfolioDetailsContext';

export const BuyingPowerPopup = ({
  hasMarginBuyingPowerFlag,
  tooltipInfo,
}: {
  hasMarginBuyingPowerFlag?: boolean | null;
  tooltipInfo?: Maybe<AppTooltipFragment>;
}) => {
  const { account } = React.useContext(PortfolioDetailsContext);

  if (hasMarginBuyingPowerFlag && tooltipInfo) {
    return <AppTooltip appTooltip={tooltipInfo} />;
  }

  const body = account?.isCryptoAccount ? (
    <Flex flexDirection="column" gap={16}>
      <PM>
        Buying power represents the funds you can use to invest right now. It
        includes settled deposits, pending trades and provisional credit.
      </PM>
      <PM>
        In Crypto Accounts, you'll be provided up to $5,000 of your pending
        deposit as buying power with the remainder credited when the transfer
        settles.
      </PM>
    </Flex>
  ) : (
    <PM>Buying power represents the total value of cash available to trade.</PM>
  );

  return (
    <Tooltip
      icon="tooltip16"
      iconColor="foregroundPrimary"
      body={body}
      placement="bottom"
    />
  );
};
