import * as React from 'react';
import { Route } from 'react-router';

import { InvestProductSelectionPage } from '~/pages/start/InvestProductSelectionPage';
import { requireAuth, RouteHandlers } from '~/router';
import { BEGIN_ONBOARDING_FLOW_STEPS as STEPS } from '~/static-constants';

import { ProductSelectionPage, SetGoalPage } from './screens';

export const StartRoute = (routeHandlers: RouteHandlers) => {
  return (
    <Route path="start" onEnter={routeHandlers(requireAuth)}>
      <Route
        component={ProductSelectionPage}
        path={`${STEPS.PRODUCT_SELECTION}`}
        unmask
      />
      <Route component={SetGoalPage} path={`${STEPS.SET_GOALS}`} unmask />
      <Route
        component={InvestProductSelectionPage}
        path={`${STEPS.INVEST_PRODUCT_SELECTION}`}
        unmask
      />
    </Route>
  );
};
