import { LayoutableProps, LL, Flex, styled } from '@m1/liquid-react';
import pick from 'lodash-es/pick';
import uniqueId from 'lodash-es/uniqueId';
import * as React from 'react';

import { Ripple } from '../ripple';

type LabelProp = React.ReactElement<any> | (string | null | undefined);
type SizeProp = 'small' | 'medium' | 'large';

type HTMLCheckboxInputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type CheckboxProps = Omit<
  HTMLCheckboxInputProps,
  'size' | 'value' | 'css'
> &
  LayoutableProps & {
    hideRipple?: boolean;
    label?: LabelProp;
    size?: SizeProp | number;
    value?: boolean | HTMLCheckboxInputProps['value'];
  };

// @ts-expect-error - TS7006 - Parameter 'props' implicitly has an 'any' type. | TS7006 - Parameter 'variable' implicitly has an 'any' type.
const useTheme = (props, variable) => props.theme.colors[variable];

const isSizeProp = (value: SizeProp | number | undefined): value is SizeProp =>
  /small|medium|large/.test(`${value ?? ''}`);

const StyledCheckboxContainer = styled(Flex)<{
  disabled: boolean;
}>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const INPUT_CONTAINER_MARGIN = 8;

const StyledInputContainer = styled.div<{
  $height: number;
  $width: number;
  checked: boolean;
  disabled: boolean;
}>`
  margin: ${INPUT_CONTAINER_MARGIN}px;
  position: relative;
  flex: 0 0 auto;
  transition: all 0.1s ease-in-out;
  width: ${({ $width }) => `${$width}px`};
  height: ${({ $height }) => `${$height}px`};

  background-color: ${(props) => {
    if (props.disabled) {
      return `${useTheme(props, 'backgroundNeutralTertiary')}`;
    }

    return useTheme(
      props,
      props.checked ? 'primary' : 'backgroundNeutralSecondary',
    );
  }};

  border: 2px solid
    ${(props) => {
      if (props.disabled) {
        return `${useTheme(props, 'foregroundNeutralTertiary')}`;
      }

      return useTheme(
        props,
        props.checked ? 'primary' : 'foregroundNeutralSecondary',
      );
    }};
  border-radius: 4px;
`;

const StyledInput = styled.input<{
  $height: number;
  $width: number;
}>`
  width: ${({ $width }) => `${$width + 2 * INPUT_CONTAINER_MARGIN}px`};
  height: ${({ $height }) => `${$height + 2 * INPUT_CONTAINER_MARGIN}px`};
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
  outline: none;
  opacity: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

const StyledSvgIcon = styled.svg<{
  $height: number;
  $width: number;
  children: React.ReactNode;
  viewBox: string;
  xmlns: string;
}>`
  position: absolute;
  bottom: -1px;
  left: 0;
  pointer-events: none;
  transition: all 0.1s ease-in-out;
  transform: scale(1);
  width: ${({ $width }) => `${$width}px`};
  height: ${({ $height }) => `${$height}px`};
`;

const StyledSVGPath = styled.path<{
  clipRule: string;
  d: string;
  fillRule: string;
}>`
  fill: ${(props) => useTheme(props, 'foregroundNeutralInverse')};
`;

const StyledLabel = styled(LL)<{
  content: React.ReactNode;
  font: 'LL' | 'LM';
  htmlFor: string;
}>`
  padding: 0 0 1px 4px;
  user-select: none;
  color: ${(props) => useTheme(props, 'foregroundNeutralMain')};
  cursor: pointer;
`;

const StyledRipple = styled(Ripple)`
  background-color: 'primary';
`;

function readCheckmarkSvgWidthAndHeight(size: CheckboxProps['size']): number {
  switch (size) {
    case 'small':
      return 14;
    case 'medium':
      return 18;
    case 'large':
      return 22;
    default:
      throw new Error('Invalid size passed to readCheckmarkSvgWidthAndHeight');
  }
}

function readInputContainerWidthAndHeight(size: CheckboxProps['size']): number {
  switch (size) {
    case 'small':
      return 16;
    case 'medium':
      return 20;
    case 'large':
      return 24;
    default:
      throw new Error(
        'Invalid size passed to readInputContainerWidthAndHeight',
      );
  }
}

function readInputProps({
  id,
  isChecked,
  handleChange,
  handleMouseDown,
  ...rest
}: HTMLCheckboxInputProps & {
  isChecked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleMouseDown: (event: React.MouseEvent<HTMLInputElement>) => void;
}): HTMLCheckboxInputProps {
  return {
    ...pick(rest, ['autoFocus', 'disabled', 'name']),
    type: 'checkbox',
    checked: isChecked,
    id,
    onChange: handleChange,
    onMouseDown: handleMouseDown,
    value: isChecked.toString(),
  };
}

type LabelProps = {
  id: string;
  label?: LabelProp;
  size: SizeProp;
};

const Label = ({ label, id, size }: LabelProps) => {
  if (!label) {
    return null;
  }

  if (typeof label === 'string') {
    return (
      <StyledLabel
        content={label}
        forwardedAs="label"
        htmlFor={id}
        font={size === 'medium' ? 'LL' : 'LM'}
      />
    );
  }

  return (
    <label
      style={{
        cursor: 'pointer',
      }}
      htmlFor={id}
    >
      {label}
    </label>
  );
};

export const Checkbox = ({
  disabled = false,
  hideRipple = false,
  size,
  onChange,
  checked,
  value,
  label,
  ...rest
}: CheckboxProps) => {
  const isChecked = React.useMemo(
    () => Boolean(checked || value),
    [checked, value],
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof onChange === 'function' && !disabled) {
        onChange(event);
      }
    },
    [disabled, onChange],
  );

  const handleMouseDown = React.useCallback((event: React.MouseEvent) => {
    if (rippleRef.current) {
      // @ts-expect-error - TS2339 - Property 'start' does not exist on type 'never'.
      rippleRef.current.start(event);
    }
  }, []);

  const id = React.useMemo(() => uniqueId('checkbox-'), []);

  const inputProps = readInputProps({
    id,
    isChecked,
    handleChange,
    handleMouseDown,
    ...rest,
  });

  const rippleRef = React.useRef<Ripple | null>(null);

  const [containerDimension, svgDimension] = React.useMemo<
    [number, number]
  >(() => {
    return [
      readInputContainerWidthAndHeight(size ?? 'medium'),
      readCheckmarkSvgWidthAndHeight(size ?? 'medium'),
    ];
  }, [size]);

  // FIXME(Wolf): Address type mismatch due to conflicts with LIRE.
  const checkboxProps = rest as any;

  // If we forward the `id` attribute to the hidden input, there will be two
  // inputs in the DOM with the same ID:
  const { id: ignored, ...hiddenInputProps } = rest;

  return (
    <StyledCheckboxContainer
      {...checkboxProps}
      alignItems="center"
      disabled={disabled}
    >
      <input {...hiddenInputProps} aria-hidden type="hidden" />
      <StyledInputContainer
        checked={isChecked}
        disabled={disabled}
        // @ts-expect-error - TS2769 - No overload matches this call.
        size={size ?? 'medium'}
        $width={containerDimension}
        $height={containerDimension}
      >
        <StyledInput
          role="checkbox"
          disabled={disabled}
          {...inputProps}
          $width={containerDimension}
          $height={containerDimension}
          checked={isChecked}
        />
        {isChecked && (
          <StyledSvgIcon
            xmlns="http://www.w3.org/2000/svg"
            $width={svgDimension}
            $height={svgDimension}
            viewBox="0 0 20 20"
          >
            <StyledSVGPath
              fillRule="evenodd"
              d="M17.375 6.4L8.192 16.438 2.439 10.54l1.79-1.746 3.904 4.004 7.397-8.086 1.845 1.687z"
              clipRule="evenodd"
            />
          </StyledSvgIcon>
        )}
        {!hideRipple && (
          <StyledRipple
            centered
            ref={(ref) => {
              rippleRef.current = ref;
            }}
            spread={3}
          />
        )}
      </StyledInputContainer>
      <Label id={id} label={label} size={isSizeProp(size) ? size : 'medium'} />
    </StyledCheckboxContainer>
  );
};
