import { parseQuery } from './query';

type PathWithQueryParam = `${string}?${string}`;
type DeepLinkPaths = {
  [key: string]: string;
} & {
  [key in PathWithQueryParam]: never;
};

// TODO(RRU): Deleted commented out route names when named routes are removed.

/**
  This is a complete alphabetical list of potential path names that can be returned from
  Lens. It will need to be updated as Lens adds additional path names that can be returned.
  See, https://m1finance.atlassian.net/l/c/fGMTUJ1T

  If a deep link does not have a corresponding named url to be used with react-router, map it to itself.

  Run `yarn sync-deep-links` to sync up web and lens automatically. Write your comments in this comment block only!

  Deep links can now handle dynamic query params. That means the keys do not need query params
  (it is assumed query params may or may not be present), and the value can be the react-router route name.

  @example ```
    // Old method:
    '/d/move-money?autoPay=true': '/d/move-money?autoPay=true',
    // New method (query params will be preserved):
    '/d/move-money': 'move-money',
  ```

  You must make sure that the given route handles query params that you want properly still!

  @todo Update waitlist mapping to 'spend-credit' after CC tab available
  @todo Remove query params from keys and values below
*/
export const deepLinkPaths: DeepLinkPaths = {
  '/d/accept-offer': '/d/accept-offer',
  '/d/bank-connection': 'bank-connection',
  '/d/borrow': 'borrow',
  '/d/borrow/margin': 'borrow-account',
  '/d/borrow/margin/marketing': 'borrow-margin-marketing',
  '/d/borrow/marketing': 'borrow-marketing',
  '/d/borrow/personal/loan-application': '/d/borrow/personal/loan-application',
  '/d/borrow/personal/manage-loan': 'manage-personal-loan',
  '/d/borrow/personal/loan-application/no-linked-account': `/d/borrow/personal/loan-application/no-linked-account`,
  '/d/borrow/personal/not-eligible': 'personal-loan-not-eligible',
  '/d/borrow/personal/scra-status': 'manage-personal-loan',
  '/d/borrow/personal/transactions': 'personal-loan-transactions',
  '/d/c/activate-debit-card': 'activate-debit-card',
  '/d/c/all-plus-benefits': 'all-plus-benefits',
  '/d/c/confirm-identity': 'confirm-identity',
  '/d/c/create-send-check/send-check-overview': 'create-send-check',
  '/d/c/create-transfer': 'create-transfer',
  '/d/c/credit-card-application': 'credit-card-application',
  '/d/c/invest/document-upload': '/d/c/invest/document-upload',
  '/d/c/open-account': 'open-account',
  '/d/c/payment': 'payment',
  '/d/c/plus-billing': 'plus-billing',
  '/d/c/resend-verification-email': 'resend-verification-email',
  '/d/c/transfer': 'transfer',
  '/d/contact-us': 'contact-us',
  '/d/crypto': 'crypto',
  '/d/document-upload': 'document-upload',
  '/d/home': 'home',
  '/d/invest/activity': 'activity',
  '/d/invest/automation/dividend-management': 'dividend-management',
  '/d/invest/bank-connection': 'invest-bank-connection',
  '/d/invest/fund-account': 'fund-account',
  '/d/invest/funding': 'funding-history',
  '/d/invest/holdings': 'holdings',
  '/d/invest/holdings/dividends': 'dividend-tracker',
  '/d/invest/portfolio': 'portfolio',
  '/d/invest/unmanaged-holdings': 'unmanaged-holdings',
  '/d/move-money': 'move-money',
  '/d/open-invest-account': 'open-invest-account',
  '/d/plaid-redirect': 'plaid-redirect',
  '/d/plus': 'plus',
  '/d/referrals': 'referrals',
  '/d/research': 'research',
  '/d/research/model-portfolios': 'research-model-portfolios',
  '/d/research/my-pies': 'research-my-pies',
  '/d/save': 'save',
  '/d/save/checking': 'save-checking',
  '/d/save/checking/debit-card': 'save-checking-debit-card',
  '/d/save/checking/direct-deposit': 'save-checking-direct-deposit',
  '/d/save/checking/transactions': 'save-checking-transactions',
  '/d/save/savings': 'save-savings',
  '/d/save/savings/transactions': 'save-savings-transactions',
  '/d/settings': 'settings',
  '/d/settings/billing': 'settings-billing',
  '/d/settings/documents': 'settings-documents',
  '/d/settings/notifications': 'settings-notifications',
  '/d/settings/payments': 'settings-payments',
  '/d/settings/profile': 'settings-profile',
  '/d/settings/profile/promotions-disclosures': 'promotions-disclosures',
  '/d/settings/security': 'settings-security',
  '/d/spend': 'spend',
  '/d/spend/checking': 'spend-checking',
  '/d/spend/checking/debit-card': 'spend-debit-card',
  '/d/spend/checking/direct-deposit': 'spend-account-detail',
  '/d/spend/checking/transactions': 'spend-checking-transactions',
  '/d/spend/credit': 'spend-credit',
  '/d/spend/credit/rewards': 'spend-credit-rewards-summary',
  '/d/spend/credit/transactions': 'spend-credit-transactions',
  '/d/spend/debit-card': 'spend-debit-card',
  '/d/spend/direct-deposit': 'spend-account-detail',
  '/d/spend/savings/transactions': 'spend-savings-transactions',
  '/d/spend/transactions': 'spend-checking-transactions',
  '/d/transfers': 'transfers',
  '/d/w/acat-wizard': 'acat-wizard',
  '/d/waitlist': 'waitlist',
  '/login': 'login',
  '/onboarding/financial-details': 'financial-suitability-wizard',
  '/onboarding/setup-invest-account': 'onboarding-setup-invest-account',
  '/onboarding/setup-ira-account': 'ira-wizard',
  '/savings-onboarding': 'savings-onboarding',
};

type PathAndQueries = {
  routeName: string;
  queryParams: Record<string, string> | undefined;
};

// Ideally the specific route names are a union type.
export function mapInternalPathToRouteName(
  internalPath: string,
): PathAndQueries {
  let queryParams: PathAndQueries['queryParams'];
  const [pathOnly, queries] = internalPath.split('?');

  // First try to match the path without query params, if not, fallback to full path with params
  const matchedPath = deepLinkPaths[pathOnly] || deepLinkPaths[internalPath];

  if (queries && matchedPath) {
    queryParams = parseQuery(queries);
  }

  // Ensure we're returning a route _path_, not a route _name_:
  const nonRouteNamePath = (matchedPath ?? '').startsWith('/')
    ? matchedPath
    : internalPath;

  return { routeName: nonRouteNamePath, queryParams };
}
