import * as React from 'react';
import { IndexRedirect, Redirect, Route } from 'react-router';

import { requireAuth, requireAccountOrProfile, RouteHandlers } from '~/router';

import { BankConnectionRoute } from './bank-connection';
import { BorrowRoute } from './borrow';
import { ContactUsRoute } from './contact-us';
import { CoversRoute } from './covers';
import { CryptoRoute } from './crypto';
import { DashboardPage } from './DashboardPage';
import { DocumentUploadRoute } from './document-upload';
import { HomeRoute } from './home';
import { InvestRoute } from './invest';
import { MoveMoneyRoute } from './move-money';
import { OpenInvestAccountRoute } from './open-invest-account';
import { OpenInvestJointAccountRoute } from './open-invest-joint-account';
import { PromotionCampaignAcceptance } from './promotions';
import { ReferralsRoute } from './referrals';
import { ResearchRoute } from './research';
import { SaveRoute } from './save';
import { SavingsOnboardingInitialFunding } from './savings-onboarding-initial-funding';
import { SettingsRoute } from './settings';
import { SpendRoute } from './spend';
import { TransfersRoute } from './transfers';
import { WaitlistRoute } from './waitlist';
import { WizardsRoute } from './wizards';

export const DashboardRoute = (routeHandlers: RouteHandlers) => {
  return (
    <Route
      path="d"
      component={DashboardPage}
      onEnter={routeHandlers(requireAuth, requireAccountOrProfile)}
    >
      {BorrowRoute()}
      {CoversRoute()}
      {ContactUsRoute()}
      {CryptoRoute()}
      {DocumentUploadRoute()}
      {OpenInvestAccountRoute()}
      {OpenInvestJointAccountRoute()}
      {HomeRoute()}
      {InvestRoute()}
      {BankConnectionRoute()}
      {MoveMoneyRoute()}
      {PromotionCampaignAcceptance()}
      {ReferralsRoute()}
      {ResearchRoute()}
      {SavingsOnboardingInitialFunding()}
      {SettingsRoute(routeHandlers)}
      {SaveRoute()}
      {SpendRoute()}
      {TransfersRoute()}
      {WaitlistRoute()}
      {WizardsRoute(routeHandlers)}
      <Redirect from="portfolio" to="invest" />
      <Redirect from="wire-transfer" to="c/wire-transfer" />
      <IndexRedirect to="invest" />
    </Route>
  );
};
