import * as React from 'react';
import { IndexRedirect, Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { CreditCardMarketingRoute } from '../spend-marketing';

import { CreditCardClosedRoute } from './closed';
import { CreditCardLandingPageRoute } from './credit-card-landing-page';
import { CreditCardPage } from './CreditCardPage';
import { CreditCardRewardsRoute } from './rewards';

export const CreditCardRoute = () => (
  <Route path="credit" component={createRouteComponent(CreditCardPage)}>
    {CreditCardClosedRoute()}
    {CreditCardLandingPageRoute()}
    {CreditCardRewardsRoute()}
    {CreditCardMarketingRoute()}
    <IndexRedirect to="/d/spend" />
  </Route>
);
