import * as React from 'react';
import { Route } from 'react-router';

import { TransferInstanceDetailsPage } from './TransferInstanceDetailsPage';

export const TransferInstanceDetailsRoute = () => {
  return (
    <Route
      path="transfer-details/:transferInstanceId"
      component={({ params, location }) => {
        const transferInstanceId = params.transferInstanceId;

        return (
          <TransferInstanceDetailsPage
            transferInstanceId={transferInstanceId}
            location={location}
          />
        );
      }}
      fallbackRoute="/d/transfers"
      cover
    />
  );
};
