import { Box, Modal, ModalContent, PL, PXL } from '@m1/liquid-react';
import React from 'react';

import { useInvestTradeDisclosuresLazyQuery } from '~/graphql/hooks';
import { AppLink } from '~/graphql/types';
import { connectModal } from '~/hocs';
import { ModalProp } from '~/redux/reducers/modals';
import { Link } from '~/toolbox/link';

import { Spinner } from '~/toolbox/spinner';

type DisclosuresModalProps = {
  modal: ModalProp<any>;
};

type TradeDisclosureProps = {
  title?: string | null | undefined;
  content?: string | null | undefined;
  links?: Array<{ title?: string | null | undefined } | null> | null;
};

const TradeDisclosure = ({ title, content, links }: TradeDisclosureProps) => {
  return (
    <Box mb={24}>
      <PXL fontWeight={600}>{title}</PXL>
      <PL>{content}</PL>
      <Box mt={12}>
        {links
          ? (links as AppLink[]).map(({ title, url }, i) => (
              <Link key={`link-${i}`} to={url} title={title} target="_blank">
                {title}
              </Link>
            ))
          : null}
      </Box>
    </Box>
  );
};

const DisclosureModalComponent = ({ modal }: DisclosuresModalProps) => {
  const [loadInvestDisclosures, { data, error, loading }] =
    useInvestTradeDisclosuresLazyQuery();

  React.useEffect(() => {
    if (modal.isOpened) {
      loadInvestDisclosures({
        variables: {
          disclosuresFor: modal?.payload?.disclosuresFor ?? 'SECURITIES',
        },
      });
    }
  }, [modal.isOpened, modal?.payload?.disclosuresFor]);

  const disclosures =
    data?.viewer.invest?.investDisclosures?.investTradeDisclosures
      ?.disclosures ?? [];
  return (
    <Modal open={modal.isOpened} onClose={modal.hide}>
      <ModalContent>
        <Box pt={24}>
          {loading ? (
            <Spinner fullScreen />
          ) : (
            disclosures.map((disclosure, i) => (
              <TradeDisclosure key={`disclosure-${i}`} {...disclosure} />
            ))
          )}
        </Box>
      </ModalContent>
    </Modal>
  );
};

export const DisclosureModal = connectModal('TRADE_DISCLOSURES')(
  DisclosureModalComponent,
);
