import { Button, Flex } from '@m1/liquid-react';
import * as React from 'react';

import { InvestBankConnectionAccountNodeFragment } from '~/graphql/types';
import { useCancelAchRelationship } from '~/hooks/useCancelAchRelationship';
import { useNavigate } from '~/hooks/useNavigate';
import { useResendEmailVerification } from '~/hooks/useResendEmailVerification';
import { useDispatch } from '~/redux/hooks';
import { Link } from '~/toolbox/link';

type BankConnectionActionsProps = {
  account: InvestBankConnectionAccountNodeFragment | null | undefined;
  isPrimaryEmailVerified: boolean | null | undefined;
};

export const BankConnectionActions = ({
  account,
  isPrimaryEmailVerified,
}: BankConnectionActionsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cancelAchRelationship = useCancelAchRelationship();
  const resendEmailVerification = useResendEmailVerification();

  const lastAchRelationship = account?.lastAchRelationship;

  const isAccountFunded = Boolean(account?.balance.totalValue?.hasValue);

  const isEligibleToConnectBank =
    account?.lastAchRelationship &&
    (!account.lastAchRelationship ||
      /(CANCELED|REJECTED)/.test(account.lastAchRelationship?.status));

  const handleCancelVerifyClick = (): void => {
    if (lastAchRelationship) {
      cancelAchRelationship({
        achRelationshipId: lastAchRelationship.id,
      });
    }
  };

  const renderTransferButtons = () => {
    const shouldPromptForTransfer = account?.shouldPromptForTransfer;

    let withdrawalQuery = {};
    let depositQuery = {};
    if (account && lastAchRelationship?.id) {
      withdrawalQuery = {
        fromParticipantId: account.id,
        toParticipantId: lastAchRelationship.id,
      };

      depositQuery = {
        fromParticipantId: lastAchRelationship.id,
        toParticipantId: account.id,
      };
    }

    return (
      <Flex flexDirection="column">
        <Button
          disabled={account?.isLiquidating}
          kind="primary"
          label="Deposit"
          onClick={() => {
            navigate({
              to: '/d/c/create-transfer',
              query: { ...depositQuery },
            });
          }}
          size="large"
          mb={24}
        />
        {!shouldPromptForTransfer && (
          <Link
            disabled={!isAccountFunded || account?.isLiquidating}
            to="/d/c/create-transfer"
            query={withdrawalQuery}
          >
            Withdraw funds from M1
          </Link>
        )}
      </Flex>
    );
  };

  const renderButtons = () => {
    if (isEligibleToConnectBank) {
      const query = {
        previousRouteName: '/d/invest/bank-connection',
      };

      return (
        <Button
          kind="primary"
          label="Connect bank"
          onClick={() => navigate({ to: '/d/c/connect-bank', query })}
          size="large"
        />
      );
    } else if (!isPrimaryEmailVerified) {
      return (
        <Button
          kind="link"
          label="Resend Email"
          onClick={() => resendEmailVerification()}
        />
      );
    }

    return renderTransferButtons();
  };

  return <Flex justifyContent="center">{renderButtons()}</Flex>;
};
