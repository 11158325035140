import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';

import { SetRootPieDocument, SetRootPieMutationResult } from '~/graphql/hooks';
import { SetRootPieInput } from '~/graphql/types';
import {
  hideLoadingSpinner,
  navigate,
  showLoadingSpinner,
} from '~/redux/actions';

import { apolloMutationSaga } from '../apolloMutationSaga';
import { call } from '../effects';

import { getLoggers } from './getLoggersSaga';

export function* setRootPieSaga(
  accountId: string | null | undefined,
  pieId: string | null | undefined,
): SagaIterator<void> {
  try {
    yield put(showLoadingSpinner());

    if (!accountId || !pieId) {
      throw new Error('accountId or pieId is missing');
    }
    const result: SetRootPieMutationResult = yield call(apolloMutationSaga, {
      mutation: SetRootPieDocument,
      variables: {
        input: {
          accountId,
          pieId,
        } satisfies SetRootPieInput,
      },
    });

    const { analytics } = yield call(getLoggers);
    if (result.data?.setRootPie?.successAnalyticsEvent) {
      analytics.recordAppAnalyticsEvent(
        result.data.setRootPie.successAnalyticsEvent,
      );
    }
    yield put(navigate({ to: '/d/invest/fund-account' }));
  } catch (e: any) {
    const defaultErrorMessage =
      'This update could not be saved. Try again or contact us for further assistance. ';

    yield put({
      payload: {
        content: e.message ?? defaultErrorMessage,
        duration: 'short',
        kind: 'alert',
      },
      type: 'ADD_TOAST',
    });
  } finally {
    yield put(hideLoadingSpinner());
  }
}
