import React from 'react';
import { LocationDescriptor } from 'react-router';

import { AppContext } from '~/AppContext';

export const useLocation = (): LocationDescriptor => {
  const { history } = React.useContext(AppContext);
  const location = history.getCurrentLocation();
  // The memoization here prevents unnecessary re-renders, since `history.getCurrentLocation()`
  // produces a new object everytime it's called. This will only produce the new object if
  // the pathname or query params change.
  return React.useMemo(
    () => location,
    // eslint-disable-next-line
    [location.pathname, JSON.stringify(location.query)],
  );
};
