import * as React from 'react';
import { Route } from 'react-router';

import { CreditCardApplicationPage } from './CreditCardApplicationPage';

export const CreditCardApplicationRoute = () => {
  return (
    <Route
      path="credit-card-application(/:step)"
      fallbackRoute="/d/spend/credit"
      navigationState={{
        forceRefetch: true,
      }}
      cover
      component={CreditCardApplicationPage}
    />
  );
};
