import * as React from 'react';
import { IndexRedirect, Route } from 'react-router';

import { BeneficiariesProfileRoute } from './beneficiaries';
import { ContactInformationRoute } from './contact';
import { DisclosuresProfileRoute } from './disclosures';
import { DisclosuresForCashRoute } from './disclosures-for-cash';
import { DisclosuresForCreditRoute } from './disclosures-for-credit';
import { DisclosuresForCryptoRoute } from './disclosures-for-crypto';
import { DisclosuresForPromotionsRoute } from './disclosures-for-promotions';
import { DisclosuresForSavingsRoute } from './disclosures-for-savings';
import { EmploymentRoute } from './employment';
import { ProfilePage } from './ProfilePage';
import { SuitabilityRoute } from './suitability';
import { TrustedContactRoute } from './trusted-contact';

export const ProfileRoute = () => (
  <Route path="profile" component={ProfilePage}>
    {ContactInformationRoute()}
    {EmploymentRoute()}
    {SuitabilityRoute()}
    {DisclosuresProfileRoute()}
    {DisclosuresForCashRoute()}
    {DisclosuresForSavingsRoute()}
    {DisclosuresForCreditRoute()}
    {DisclosuresForCryptoRoute()}
    {DisclosuresForPromotionsRoute()}
    {TrustedContactRoute()}
    {BeneficiariesProfileRoute()}
    <IndexRedirect to="contact" />
  </Route>
);
