import { OneColumnConstrained, Box } from '@m1/liquid-react';
import React from 'react';

import { useWizard, WizardProvider } from '~/flows/wizard';
import { useLaunchDarkly } from '~/hooks/useLaunchDarkly';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';

import { AppState } from '~/redux';
import { logout } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

import { PersonalLoanDirectHeader } from './PersonalLoanDirectHeader';
import { AcceptLoanOffer } from './steps/AcceptLoanOffer';
import { AnnualIncomeMonthlyRentMortgage } from './steps/AnnualIncomeMonthlyRentMortgage';
import { Autopay } from './steps/Autopay';
import { AvailableCash } from './steps/AvailableCash';
import { BankConnection } from './steps/BankConnection';
import { BankDeposit } from './steps/BankDeposit';
import { DateOfBirthCitizenship } from './steps/DateOfBirthCitizenship';
import { EmploymentStatusAndInfo } from './steps/EmploymentStatusAndInfo';
import { FinancialDisclosures } from './steps/FinancialDisclosures';
import { HardPullConsent } from './steps/HardPullConsent';
import { HomeAddress } from './steps/HomeAddress';
import { IncomeVerificationRequired } from './steps/IncomeVerificationRequired';
import { LoanUseAndDuration } from './steps/LoanUseAmountAndDuration';
import { NameEmailPassword } from './steps/NameEmailPassword';
import { PhoneNumber } from './steps/PhoneNumber';
import { PhoneNumberVerification } from './steps/PhoneNumberVerification';
import { PlaidIncomeVerification } from './steps/PlaidIncomeVerification';
import { PreApprovedOffers } from './steps/PreApprovedOffers';
import { Receipt } from './steps/Receipt';
import { RejectedCreditFrozen } from './steps/RejectedCreditFrozen';
import { SocialSecurityNumber } from './steps/SocialSecurityNumber';
import { PersonalLoanDirectSteps } from './types';

type PersonalLoanDirectWizardSteps = Record<
  keyof PersonalLoanDirectSteps,
  React.ReactElement
>;

export const PersonalLoanDirectWizard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isLoggedIn = useSelector((state: AppState) =>
    Boolean(state.auth.accessToken),
  );
  const { didSucceed, flagResult } = useLaunchDarkly(
    'borrow-personal-loans-application-access',
    false,
  );

  // If Personal Loans access is disabled, reroute user to sign up page.
  // Check this flag on the client since the user may not have logged in yet.
  const cannotAccessPersonalLoans = didSucceed && flagResult === false;
  React.useEffect(() => {
    if (cannotAccessPersonalLoans) {
      navigate({ to: '/signup' });
    }
  }, [navigate, cannotAccessPersonalLoans]);

  // TODO: Update each step's component
  // NOTE: For storing each step's form values, reference OpenInvestJointAccountWizard
  const steps: PersonalLoanDirectWizardSteps = {
    NAME_EMAIL_PASSWORD: <NameEmailPassword />,
    LOAN_USE_AMOUNT_AND_DURATION: <LoanUseAndDuration />,
    HOME_ADDRESS: <HomeAddress />,
    PHONE_NUMBER: <PhoneNumber />,
    PHONE_NUMBER_VERIFICATION: <PhoneNumberVerification />,
    DATE_OF_BIRTH_CITIZENSHIP: <DateOfBirthCitizenship />,
    ANNUAL_INCOME_MONTHLY_RENT_MORTGAGE: <AnnualIncomeMonthlyRentMortgage />,
    SOCIAL_SECURITY_NUMBER: <SocialSecurityNumber />,
    REJECTED_CREDIT_FROZEN: <RejectedCreditFrozen />,
    PRE_APPROVED_OFFERS: <PreApprovedOffers />,
    EMPLOYMENT_STATUS_AND_INFO: <EmploymentStatusAndInfo />,
    AVAILABLE_CASH: <AvailableCash />,
    FINANCIAL_DISCLOSURES: <FinancialDisclosures />,
    HARD_PULL_CONSENT: <HardPullConsent />,
    INCOME_VERIFICATION_REQUIRED: <IncomeVerificationRequired />,
    PLAID_INCOME_VERIFICATION: <PlaidIncomeVerification />,
    BANK_CONNECTION: <BankConnection />,
    BANK_DEPOSIT: <BankDeposit />,
    AUTOPAY: <Autopay />,
    ACCEPT_LOAN_OFFER: <AcceptLoanOffer />,
    RECEIPT: <Receipt />,
  };

  const wizard = useWizard(steps);
  const queryStep = location.query?.step;

  React.useEffect(() => {
    // Warn users before they leave the page that they will lose their progress.
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      // NOTE: Most browsers ignore this value and show a generic message
      event.returnValue = 'Are you sure you want to leave?';
    };

    // If the user proceeds with exiting, attempt to clear their session.
    const handlePageHide = () => {
      dispatch(logout());
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('pagehide', handlePageHide);

    // Clean up listeners when we navigate away from the PLOE wizard.
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('pagehide', handlePageHide);
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (
      isLoggedIn &&
      queryStep !== wizard.stepKey &&
      queryStep === 'BANK_DEPOSIT'
    ) {
      wizard.goTo('BANK_DEPOSIT');
    }
  }, [queryStep, isLoggedIn]);

  return (
    <WizardProvider value={{ ...wizard }}>
      <Box backgroundColor="backgroundNeutralSecondary" minHeight="100vh">
        <PersonalLoanDirectHeader />
        <OneColumnConstrained>{wizard.step}</OneColumnConstrained>
      </Box>
    </WizardProvider>
  );
};
