import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { TransferAcatInstanceDetailsPage } from './TransferAcatInstanceDetailsPage';

export const TransferAcatInstanceDetailsRoute = () => {
  return (
    <Route
      path="acat-transfer-details/:transferInstanceId"
      component={createRouteComponent(
        TransferAcatInstanceDetailsPage,
        (props) => ({
          transferInstanceId: props.params.transferInstanceId,
          ...props,
        }),
      )}
      fallbackRoute="/d/transfers"
      cover
    />
  );
};
