import { toValidRoutePath } from '~/router';
import { isNil } from '~/utils';

export const buildPath = (
  to: string,
  query?:
    | string
    | string[][]
    | Record<string, any>
    | URLSearchParams
    | undefined
    | null,
  params?: Record<string, string | null | undefined> | null | undefined,
): string => {
  // TODO: This really shouldn't happen, but can break the app if `to` is undefined, so handle this case for now
  if (isNil(to)) {
    return '';
  }

  const search = query ? `?${new URLSearchParams(query).toString()}` : '';

  return `${toValidRoutePath(to, params ?? undefined)}${search}`;
};
