import { castArray } from 'lodash-es';
import get from 'lodash-es/get';
import uniqueId from 'lodash-es/uniqueId';
import { LocationDescriptor } from 'react-router';

import { EditModel, EditModelNewPie, Path } from '../../SharePiePage.types';

export const PATH_DELIMITER = ',';

export const BACKTESTING_INITIAL_VALUE = 100_000;

export const readEditModel = (
  parsedModel: EditModel,
  path: Path | null | undefined,
): EditModel => {
  return path ? get(parsedModel, createTo(path)) : parsedModel;
};

export const createTo = (path: Path): string => {
  return path.map((s) => `slices.${s}.to`).join('.');
};

type SliceableTypeEnum = 'UserPie' | 'SystemPie' | 'Security' | 'Fund';
const mapSliceableTypename = (
  sliceableType: 'security' | 'new_pie' | 'old_pie',
): SliceableTypeEnum => {
  switch (sliceableType) {
    case 'security':
      return 'Security';
    case 'old_pie':
    case 'new_pie':
      return 'UserPie';
    default:
      throw new Error(`Unknown sliceable type: ${sliceableType}`);
  }
};

/**
 * Iterates through the edit model pie tree and add's id's and __typename's to all the "new_pie"s.
 * @param editModel - The pie tree edit model.
 */
export const annotateLocalModel = (editModel: EditModelNewPie): EditModel => {
  editModel.slices = (editModel.slices ?? []).map((slice) => {
    slice = {
      ...slice,
      to: {
        ...slice.to,
        __typename: slice.to.__typename ?? mapSliceableTypename(slice.to.type),
        id: slice.to.id ?? uniqueId('slice-'),
      },
    };

    if (slice.to.type === 'new_pie') {
      annotateLocalModel(slice.to);
    }

    return slice;
  });

  return editModel;
};

export const readPath = (
  location: LocationDescriptor | null | undefined,
): Array<string> | null => {
  const path: unknown = location?.query?.path;
  // For URLs like ?path&path=123&path=456
  if (Array.isArray(path)) {
    // Pick all non-empty parts of the path array.
    return path.filter(
      (pathPart) => typeof pathPart === 'string' && pathPart.length > 0,
    );
  }
  // For URLs like ?path=123,456
  if (typeof path === 'string' && path.length > 0) {
    return path.split(PATH_DELIMITER).filter(Boolean);
  }

  return null;
};

type SharePieBreadcrumbs = { label: string; to?: string }[];

export const getBreadcrumbLinks = (
  location: any,
  parsedModel: EditModel,
  name: string,
): SharePieBreadcrumbs | null => {
  const { query } = location;
  const pathArray = castArray(readPath(location));

  if (query.path) {
    const { token } = query;
    const breadcrumb: SharePieBreadcrumbs = pathArray.map((_, index) => {
      const segment = pathArray.slice(0, index) as string[];
      const to = [createTo(segment), 'name'].filter(Boolean).join('.');
      return {
        label: get(parsedModel, to) as string,
        to: `/share?token=${token}&path=${segment.join(PATH_DELIMITER)}`,
      };
    });

    breadcrumb.push({ label: name });
    return breadcrumb;
  }

  return null;
};
