import { Box, Button, Flex, styled, theme } from '@m1/liquid-react';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { BackArrow } from '~/components/BackArrow';
import { Footer } from '~/components/Footer';
import { GenericSystemError } from '~/components/GenericSystemError';
import {
  usePromotionCampaignAcceptancePageQuery,
  useAcceptPromotionCampaignMutation,
  HomePagePromotionsQueryDocument,
} from '~/graphql/hooks';
import { AnalyticsEventFragment } from '~/graphql/types';
import { useAnalytics } from '~/hooks/useAnalytics';
import { useLayout } from '~/hooks/useLayout';
import { useNavigate } from '~/hooks/useNavigate';
import { AppInfoPage } from '~/lens-toolbox/AppInfoPage';
import { useSelector } from '~/redux/hooks';
import { RESPONSIVE_BREAKPOINTS } from '~/static-constants';
import { useToast } from '~/toasts';
import { Spinner } from '~/toolbox/spinner';

import { StyledLandingPageContent } from '../common/ProductLandingPage.styled';

const StyledPromotionCampaignAcceptancePage = styled(Box)`
  width: 100%;
  background: ${(props) => props.theme.colors?.gradientLinearMain};
`;

export const PromotionCampaignAcceptancePage = () => {
  const { contentWidth } = useLayout();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const analytics = useAnalytics();
  const isTabletBreakpoint = useMediaQuery({
    query: RESPONSIVE_BREAKPOINTS.TABLET,
  });
  const { appHistory } = useSelector((state) => state.routing);
  const queryParams = new URLSearchParams(window.location.search);
  const promotionKey = queryParams.get('key');

  const goBack = React.useCallback(() => {
    // use app history to determine where to route users.
    if (appHistory.length < 2) {
      navigate({ to: '/d/home' });
    } else {
      navigate({ to: appHistory[1] });
    }
  }, [navigate, appHistory]);

  const { data, loading, error } = usePromotionCampaignAcceptancePageQuery({
    variables: {
      promotionKey,
    },
    skip: !promotionKey,
  });

  const [acceptPromotionCampaign] = useAcceptPromotionCampaignMutation({
    refetchQueries: [{ query: HomePagePromotionsQueryDocument }],
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  const { campaignId, acceptanceScreen } =
    data?.viewer?.promotions?.campaign || {};

  const docBundleSignature =
    data?.viewer?.promotions?.campaign?.documentBundle?.signature;

  if (error || !promotionKey) {
    return (
      <GenericSystemError
        button={<Button label="Continue" onClick={goBack} />}
        title="This offer couldn’t be applied to your account."
        content="Something went wrong, so please contact us to see if we can apply this offer."
      />
    );
  }

  const handlePrimaryButtonClick = () => {
    if (typeof campaignId !== 'string') {
      return addToast({
        content: `This offer couldn't be applied to your account.`,
        kind: 'alert',
        duration: 'short',
      });
    }

    acceptPromotionCampaign({
      variables: {
        input: {
          promotionCampaignKey: promotionKey,
          campaignId,
          docBundleSignature,
        },
      },
      onCompleted: (data) => {
        const {
          acceptPromotionCampaign: { outcome },
        } = data;

        if (outcome?.successAnalyticsEvent) {
          analytics.recordAppAnalyticsEvent(
            outcome.successAnalyticsEvent as AnalyticsEventFragment,
          );
        }

        goBack();
      },
      onError: (error) => {
        addToast({
          content: error.graphQLErrors[0].message,
          kind: 'alert',
          duration: 'short',
        });
      },
    });
  };

  return (
    <StyledPromotionCampaignAcceptancePage theme={theme}>
      <StyledLandingPageContent contentWidth={contentWidth}>
        <Flex>
          <BackArrow
            pl={isTabletBreakpoint ? 16 : 108}
            mt={56}
            content="Back"
            onClick={goBack}
          />
        </Flex>
        <Flex flexGrow={1} flexDirection="column" margin="auto">
          {acceptanceScreen && (
            <AppInfoPage
              appInfoPage={acceptanceScreen}
              onPrimaryButtonClick={handlePrimaryButtonClick}
              onSecondaryButtonClick={goBack}
            />
          )}
        </Flex>
      </StyledLandingPageContent>
      <Footer />
    </StyledPromotionCampaignAcceptancePage>
  );
};
