import { PL, PS, PXS, Flex, Box, styled } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { Slice, isPieTreeValid } from '~/pie-trees';
import { useDispatch, useSelector } from '~/redux/hooks';
import { Checkbox } from '~/toolbox/checkbox';
import { GridTable } from '~/toolbox/grid-table';
import { Indicator } from '~/toolbox/indicator';
import { Logo } from '~/toolbox/logo';
import { Pill } from '~/toolbox/Pill';

import { SliceableListItemPercentageAdjustor } from './SliceableListItemPercentageAdjustor';

const getLogo = (slice: Slice): string | React.ReactNode | null => {
  switch (slice.to.type) {
    case 'security':
      return slice.to.profile?.logoUrl || null;
    case 'new_pie':
      return <Icon name="createPie32" />;
    case 'old_pie':
      return slice.to.__typename === 'USER_PIE' ? (
        <Icon name="createPie32" />
      ) : (
        <Icon name="m1Logo32" />
      );
    default:
      return null;
  }
};

const StyledReorganizerIcon = styled(Icon)`
  cursor: grab;
  user-select: none;
`;

export type SliceableListItemProps = {
  allowDrag: boolean;
  slice: Slice;
};

export const SliceableListItem = ({
  allowDrag,
  slice,
}: SliceableListItemProps) => {
  const disabledFeatures = useSelector(
    (state) => state.portfolioOrganizer.disabledFeatures,
  );

  const dispatch = useDispatch();

  const onCheckboxChange = React.useCallback(() => {
    if (slice.to.__checked) {
      dispatch({
        type: 'REMOVE_PORTFOLIO_ORGANIZER_ACTIVE_SLICE_IDS',
        payload: {
          ids: [slice.to.__id],
        },
      });
    } else {
      dispatch({
        type: 'UPDATE_PORTFOLIO_ORGANIZER_ACTIVE_SLICE_IDS',
        payload: {
          ids: [slice.to.__id],
        },
      });
    }
  }, [dispatch, slice.to.__checked, slice.to.__id]);

  const isNewPieSliceAndHasZeroSlices =
    slice.to.type === 'new_pie' && slice.to.slices.length === 0;

  const sliceIsPieAndIsInvalid =
    (slice.to.type === 'new_pie' || slice.to.type === 'old_pie') &&
    !isPieTreeValid(slice.to);

  const logo = getLogo(slice);
  // @ts-expect-error - TS2339 - Property 'symbol' does not exist on type 'Sliceable'. | TS2339 - Property 'symbol' does not exist on type 'Sliceable'.
  const sublabel = slice.to.symbol ? slice.to.symbol : null;
  const hideCheckbox =
    disabledFeatures?.includes('REMOVE_SLICES') &&
    disabledFeatures?.includes('MOVE_SLICES');

  return (
    <>
      <GridTable.Cell>
        <Flex alignItems="center" overflow="hidden">
          {!hideCheckbox && (
            <Checkbox
              checked={slice.to.__checked}
              onChange={onCheckboxChange}
              onClick={(e) => e.stopPropagation()}
              size="large"
            />
          )}
          <Box position="relative">
            <Logo
              content={logo || null} // we need to default to null here since Logo won't accept undefined.
              placeholder=""
              style={{
                borderRadius: '4px',
                height: '32px',
                marginLeft: 16,
                paddingBottom: 0,
                userSelect: 'none',
                width: '32px',
              }}
            />
            {/* @ts-expect-error - TS2339 - Property 'isActive' does not exist on type 'Sliceable'. */}
            {slice.to.isActive === false && (
              <Indicator color="orange" icon="warning24" />
            )}
          </Box>
          <Flex
            flexDirection="column"
            alignItems="baseline"
            ml={12}
            maxWidth="calc(100% - 60px)"
            flexGrow={1}
            style={{
              cursor: 'pointer',
            }}
          >
            {(sublabel || slice.percentage === 0) && (
              <Flex>
                {sublabel && (
                  <PS
                    color="foregroundNeutralSecondary"
                    content={sublabel}
                    mr={8}
                  />
                )}
                {slice.percentage === 0 && (
                  <Pill kind="danger" label="pending" />
                )}
              </Flex>
            )}
            <Flex
              alignItems="center"
              overflow="hidden"
              minWidth={0}
              maxWidth="calc(100% - 20px)"
              paddingRight={16}
            >
              <PL
                color={
                  sliceIsPieAndIsInvalid
                    ? 'warningShade'
                    : 'foregroundNeutralMain'
                }
                overflow="hidden"
                maxWidth="100%"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                content={
                  slice.to.type !== 'security' && slice.to.slices?.length
                    ? `${slice.to.name || 'Slice'} (${slice.to.slices.length})`
                    : slice.to.name
                }
              />
              {isNewPieSliceAndHasZeroSlices && (
                <PXS
                  content={
                    allowDrag
                      ? 'New pie requires a minimum of one slice. Drag & drop slice here.'
                      : 'New pie requires a minimum of one slice.'
                  }
                  color="foregroundNeutralMain"
                  ml={12}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      </GridTable.Cell>
      <GridTable.Cell textAlign="right">
        <Flex alignItems="center" justifyContent="flex-end">
          <SliceableListItemPercentageAdjustor slice={slice} />

          {allowDrag && (
            // @ts-expect-error - TS2769 - No overload matches this call.
            <StyledReorganizerIcon name="drag24" ml={16} draggable={false} />
          )}
        </Flex>
      </GridTable.Cell>
    </>
  );
};
