import { SagaIterator } from 'redux-saga';

import {
  PortfolioOrganizerSagaFetchSliceableSagaDocument,
  PortfolioOrganizerSagaFetchSliceableSagaQueryResult,
  PortfolioOrganizerSagaFetchSliceablesDocument,
  PortfolioOrganizerSagaFetchSliceablesQueryResult,
  UpdatePieTreeMutationResult,
} from '~/graphql/hooks';
import { SentryReporter } from '~/loggers';
import {
  hasCircularReference,
  mapRemoteSliceableByType,
  Pie,
  readPieTreeByPath,
  serializePieTree,
  sortSlicesByPercentage,
} from '~/pie-trees';
import {
  fetchedPieDataForOrganizerSetRootPie,
  fetchedPieSliceables,
  fetchPieDataForOrganizerFailedAction,
  navigate,
  savedPieOrganizerAction,
  savePieOrganizerFailedAction,
} from '~/redux/actions';
import type {
  AddSlicesToPieOrganizerAction,
  ClickedPortfolioOrganizerPieSliceAction,
  CreatePieFromPopupPieOrganizerAction,
  InitializePieOrganizerAction,
  SavePieOrganizerAction,
} from '~/redux/actions/pieOrganizer/pieOrganizerActions.types';

import { apolloQuerySaga } from '../apolloQuerySaga';
import { getSentryReporter } from '../common';
import { setRootPieSaga } from '../common/setRootPieSaga';
import { updatePieTreeSaga } from '../common/updatePieTreeSaga';
import { call, put, select, takeEvery } from '../effects';

import { mapFetchSliceablesResponseToSlices } from './portfolioOrganizerMappers';
import { queryUserHasPortfolio } from './remote';

export function* portfolioOrganizerSaga(): SagaIterator<void> {
  yield takeEvery('INITIALIZE_PIE_ORGANIZER', handleInitializePieOrganizer);

  yield takeEvery('ADD_SLICES_TO_PIE_ORGANIZER', handleAddSlicesToPieOrganizer);

  yield takeEvery('SAVE_PIE_ORGANIZER', handleClickedSaveButton);

  yield takeEvery(
    'CREATED_PORTFOLIO_ORGANIZER_NEW_PIE_SLICE',
    handleCreatedNewPieSlice,
  );

  yield takeEvery(
    // @ts-expect-error - TS2769 - No overload matches this call.
    'MOVED_PORTFOLIO_ORGANIZER_SLICES_ONTO_OTHER_SLICE',
    handleMovedPortfolioOrganizerSlicesOntoOtherSlice,
  );

  yield takeEvery(
    'CLICKED_CREATE_PIE_FROM_DROP_DOWN',
    handleClickCreatePieFromDropDown,
  );

  yield takeEvery(
    'CLICKED_PORTFOLIO_ORGANIZER_PIE_SLICE',
    handleClickedOrganizerPieSlice,
  );
}

function* handleClickCreatePieFromDropDown(
  action: CreatePieFromPopupPieOrganizerAction,
): SagaIterator<void> {
  const pieTree = yield select<Pie>((state) => {
    return readPieTreeByPath(
      state.portfolioOrganizer.pieTree,
      state.portfolioOrganizer.path,
    );
  });

  // @ts-expect-error - TS7006 - Parameter 'accumulatedSlices' implicitly has an 'any' type. | TS7006 - Parameter 'slice' implicitly has an 'any' type.
  const slicesToMove = pieTree.slices.reduce((accumulatedSlices, slice) => {
    if (slice.to.__checked) {
      accumulatedSlices.push(slice);
    }
    return accumulatedSlices;
  }, []);

  if (slicesToMove.length) {
    yield put({
      type: 'MOVED_PORTFOLIO_ORGANIZER_SLICES_ONTO_OTHER_SLICE',
      payload: {
        destinationOntoSliceableId: action.payload.id,
        destinationOntoSliceableName: action.payload.pieName,
        // @ts-expect-error - TS7006 - Parameter 'slice' implicitly has an 'any' type.
        movedSliceIds: slicesToMove.map((slice) => slice.to.__id),
      },
    });
  }
}

function* handleClickedOrganizerPieSlice({
  payload,
}: ClickedPortfolioOrganizerPieSliceAction) {
  const { __id, type } = payload;

  if (type !== 'new_pie') {
    yield call(handleGetPieDataForOrganizer, __id, 'UPDATE_PIE_IN_TREE');
  }
}

function* handleAddSlicesToPieOrganizer(action: AddSlicesToPieOrganizerAction) {
  yield call(
    handleGetSliceablesForOrganizer,
    action.payload.sliceableIds,
    action.payload.legacyIds,
  );
}

function* handleInitializePieOrganizer(
  action: InitializePieOrganizerAction,
): SagaIterator<void> {
  const { legacyIds, sliceableIds } = action.payload;

  if (
    action.payload.mode === 'EDIT_ROOT_PIE' ||
    action.payload.mode === 'EDIT_PIE'
  ) {
    // Fetch our pie data
    yield call(
      handleGetPieDataForOrganizer,
      action.payload.pieId,
      'SET_ROOT_PIE',
    );
  } else if (action.payload.mode === 'NEW_ROOT_PIE') {
    // Fire off our Began Portfolio Organizer Session action so the invest navlink is changed
    yield put({
      type: 'BEGAN_NEW_ROOT_PIE_ORGANIZER_SESSION',
    });
  } else if (action.payload.mode === 'NEW_PIE') {
    // nothing to do for new
  }

  if (sliceableIds?.length) {
    yield call(handleGetSliceablesForOrganizer, sliceableIds, legacyIds);
  } else {
    // If we aren't given sliceableIds we still need to call fetchedPieSliceables otherwise
    // the organizer will be stuck in the INITIALIZING state
    yield put(fetchedPieSliceables([]));
  }
}

function* handleGetPieDataForOrganizer(
  pieId: string,
  context: 'SET_ROOT_PIE' | 'UPDATE_PIE_IN_TREE',
): SagaIterator<void> {
  yield put({
    type: 'FETCHING_DATA_IN_PIE_ORGANIZER',
  });

  const data: PortfolioOrganizerSagaFetchSliceableSagaQueryResult['data'] =
    yield call(fetchSliceable, pieId);

  const pie = data?.node && 'id' in data.node ? data.node : null;

  if (pie && pie.id && pie.name && pie.type) {
    const payload = {
      pie: {
        ...pie,
        id: pie.id,
        name: pie.name,
        type: pie.type,
      },
    };

    // if we're setting a root pie, update the organizer with our whole root pie tree
    // otherwise, simply pass along our "sliceables" which in this case is just a single pie
    if (context === 'SET_ROOT_PIE') {
      yield put(fetchedPieDataForOrganizerSetRootPie(payload));
    } else {
      const mappedPiesSliceable = mapRemoteSliceableByType(payload.pie);

      // @ts-expect-error - TS2339 - Property 'slices' does not exist on type 'Sliceable'.
      if (mappedPiesSliceable.slices) {
        // @ts-expect-error - TS2339 - Property 'slices' does not exist on type 'Sliceable'.
        mappedPiesSliceable.slices = sortSlicesByPercentage(
          // @ts-expect-error - TS2339 - Property 'slices' does not exist on type 'Sliceable'.
          mappedPiesSliceable.slices || [],
          'DESC',
        );

        // @ts-expect-error - TS2339 - Property 'slices' does not exist on type 'Sliceable'.
        yield put(fetchedPieSliceables(mappedPiesSliceable.slices));
      }
    }
  } else {
    yield put(fetchPieDataForOrganizerFailedAction());

    const errorMessage =
      'No data found for pie, please try again or contact support.';

    yield put({
      payload: {
        content: errorMessage,
        duration: 'short',
        kind: 'alert',
      },
      type: 'ADD_TOAST',
    });
  }
}

function* handleGetSliceablesForOrganizer(
  sliceableIds: Array<string>,
  legacyIds: Array<string> | null | undefined,
): SagaIterator<void> {
  const { isCrypto, pieTree, path } = yield select<{
    isCrypto: boolean;
    path: Array<string>;
    pieTree: Pie;
  }>((state) => {
    return {
      isCrypto: state.portfolioOrganizer.isCrypto ?? false,
      path: state.portfolioOrganizer.path,
      pieTree: state.portfolioOrganizer.pieTree,
    };
  });

  const currentPie = readPieTreeByPath(pieTree, path);
  const currentPieSliceIds =
    isCrypto && legacyIds
      ? currentPie.slices?.map((slice) =>
          slice.to.type === 'security' ? slice.to.__legacyId : slice.to.__id,
        ) || []
      : currentPie.slices?.map((slice) => slice.to.__id) || [];

  const hasDuplicateSlices =
    isCrypto && legacyIds
      ? // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
        currentPieSliceIds.some((id) => legacyIds.includes(id))
      : // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
        currentPieSliceIds.some((id) => sliceableIds.includes(id));

  const pieIsCircular = hasCircularReference(sliceableIds, pieTree, path);

  // if we're trying to add a duplicate slice or adding a slice
  // would cause a circular pie to be formed, fail fast
  if (hasDuplicateSlices) {
    yield put({
      payload: {
        content: 'Some of your chosen slices already exist in this Pie.',
        kind: 'alert',
        duration: 'short',
      },
      type: 'ADD_TOAST',
    });

    yield put(fetchedPieSliceables([]));
  } else if (pieIsCircular) {
    yield put({
      payload: {
        content: 'This Pie is already in the Pie you are editing.',
        kind: 'alert',
        duration: 'short',
      },
      type: 'ADD_TOAST',
    });

    yield put(fetchedPieSliceables([]));
  } else {
    const response: PortfolioOrganizerSagaFetchSliceablesQueryResult['data'] =
      yield call(fetchSliceables, sliceableIds);

    const mappedSliceables = mapFetchSliceablesResponseToSlices(response);

    yield put(fetchedPieSliceables(mappedSliceables));

    const successMessage = isCrypto
      ? 'Coin(s) successfully added to your Pie.'
      : 'Securities added.';

    yield put({
      payload: {
        content: successMessage,
        kind: 'success',
        duration: 'short',
      },
      type: 'ADD_TOAST',
    });
  }
}

function* handleClickedSaveButton(
  action: SavePieOrganizerAction,
): SagaIterator<void> {
  const userHasSetupPortfolioAlready = yield call(queryUserHasPortfolio);
  const { onConfirm, mode, pieTree, returnTo, isCrypto } = yield select(
    (state) => ({
      onConfirm: state.portfolioOrganizer.onConfirm,
      mode: state.portfolioOrganizer.session.mode,
      pieTree: state.portfolioOrganizer.pieTree,
      returnTo: state.portfolioOrganizer.session.returnTo,
      isCrypto: state.portfolioOrganizer.isCrypto,
    }),
  );

  try {
    const serializedTree = serializePieTree(pieTree);
    const updatePieTreeResult: UpdatePieTreeMutationResult = yield call(
      updatePieTreeSaga,
      serializedTree,
      isCrypto,
    );

    if (mode === 'NEW_ROOT_PIE') {
      yield call(
        setRootPieSaga,
        action.payload,
        updatePieTreeResult.data?.updatePieTree?.pie?.id,
      );
      yield put({
        type: 'ENDED_NEW_ROOT_PIE_ORGANIZER_SESSION',
      });
    }

    // If we have an onConfirm callback, call it with the result
    // from the updatePieTree mutation
    if (onConfirm) {
      // Doesnt hit this for the first time user
      onConfirm(updatePieTreeResult);
    }

    yield put(savedPieOrganizerAction());

    if (returnTo) {
      yield put(navigate({ to: returnTo }));
    }
  } catch (e: any) {
    yield put({
      payload: {
        content: e.message,
        kind: 'alert',
      },
      type: 'ADD_TOAST',
    });

    yield put(savePieOrganizerFailedAction());
  }
}

function* handleCreatedNewPieSlice(): SagaIterator<void> {
  yield put({
    payload: {
      content: 'Pie details created.',
      duration: 'short',
      kind: 'success',
    },
    type: 'ADD_TOAST',
  });
}

function* handleMovedPortfolioOrganizerSlicesOntoOtherSlice({
  // @ts-expect-error - TS7031 - Binding element 'payload' implicitly has an 'any' type.
  payload,
}): SagaIterator<void> {
  const { destinationOntoSliceableName } = payload;

  const content = destinationOntoSliceableName
    ? `Slices moved to ${destinationOntoSliceableName}.`
    : 'Slices moved.';

  yield put({
    payload: {
      content,
      duration: 'short',
      kind: 'success',
    },
    type: 'ADD_TOAST',
  });
}

function* fetchSliceable(
  sliceableId: string,
): SagaIterator<
  PortfolioOrganizerSagaFetchSliceableSagaQueryResult['data'] | null | undefined
> {
  const sentry: SentryReporter = yield call(getSentryReporter);
  try {
    const { data }: PortfolioOrganizerSagaFetchSliceableSagaQueryResult =
      yield call(apolloQuerySaga, {
        query: PortfolioOrganizerSagaFetchSliceableSagaDocument,
        variables: {
          sliceableId,
        },
      });

    return data;
  } catch (e: any) {
    sentry.message(
      'Error fetching PortfolioOrganizerSagaFetchSliceableSagaQuery.',
      {
        rawError: e,
      },
    );
    return null;
  }
}

function* fetchSliceables(
  sliceableIds: Array<string>,
): SagaIterator<
  PortfolioOrganizerSagaFetchSliceablesQueryResult['data'] | null | undefined
> {
  try {
    const { data }: PortfolioOrganizerSagaFetchSliceablesQueryResult =
      yield call(apolloQuerySaga, {
        query: PortfolioOrganizerSagaFetchSliceablesDocument,
        variables: {
          sliceableIds,
        },
      });
    return data;
  } catch (e: any) {
    // do something
  }

  return null;
}
