import { NetworkStatus } from '@apollo/client';
import { Box, Button, Flex, HM, PL, SkeletonProvider } from '@m1/liquid-react';
import * as React from 'react';

import { anAccountDocument } from '~/graphql/fixtures';
import { useDocumentListQuery } from '~/graphql/hooks';
import {
  DocumentNodeFragment,
  DocumentTypeFilterEnumType,
} from '~/graphql/types';
import { Dropdown } from '~/toolbox/Dropdown';
import { GridTable } from '~/toolbox/grid-table';
import { isNotNil } from '~/utils';

import { AccountOrSpendAccountDocumentRow } from './AccountOrSpendAccountDocumentRow';
import { InvestAccountDocumentRow } from './InvestAccountDocumentRow';

const PAGE_SIZE = 10;
const mockRows = Array(PAGE_SIZE)
  .fill(0)
  .map((_, index) =>
    anAccountDocument({
      id: `mock-${index}`,
      date: new Date().toISOString(),
    }),
  );

const useDateFilterOptions = (documentType: DocumentTypeFilterEnumType) => {
  const currentYear = new Date().getUTCFullYear();
  // Tax forms lag a year. If it is currently 2024,
  // show tax year 2023 as the first and latest option.
  // Include the current year for other document types.
  const latestYear =
    documentType === DocumentTypeFilterEnumType.TaxForm
      ? currentYear - 1
      : currentYear;

  const makeDateOptions = React.useCallback(
    (userCreatedDate: string | null | undefined) => {
      const earliestYear = userCreatedDate
        ? new Date(userCreatedDate).getUTCFullYear()
        : currentYear - 10;
      return Array(latestYear - earliestYear + 1)
        .fill(0)
        .map((_, index) => {
          const year = `${latestYear - index}`;
          return { label: year, value: year };
        });
    },
    [currentYear, latestYear],
  );

  return { latestYear, makeDateOptions };
};

type DocumentListProps = {
  documentType: DocumentTypeFilterEnumType;
  title: string;
};

export const DocumentList = ({ documentType, title }: DocumentListProps) => {
  const { latestYear, makeDateOptions } = useDateFilterOptions(documentType);
  const [year, setYear] = React.useState(`${latestYear}`);
  const { data, error, fetchMore, networkStatus } = useDocumentListQuery({
    variables: {
      first: PAGE_SIZE,
      documentType,
      startDate: `${year}-01-01`,
      endDate: `${year}-12-31`,
    },
    // Notify on status change so we can show skelton when querying
    // and hide the Load More button when fetching the next page.
    notifyOnNetworkStatusChange: true,
  });

  const handleLoadMoreClick = React.useCallback(() => {
    fetchMore({
      variables: {
        after: data?.viewer?.documents?.pageInfo?.endCursor,
      },
    });
  }, [data, fetchMore]);

  const loading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables;
  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  const userCreatedDate = data?.viewer.user?.created;

  const dateOptions = React.useMemo(
    () => makeDateOptions(userCreatedDate),
    [userCreatedDate, makeDateOptions],
  );

  const documents = React.useMemo(() => {
    if (loading) {
      return mockRows;
    }

    const edges = data?.viewer?.documents?.edges ?? [];
    return edges.reduce<DocumentNodeFragment[]>((nodes, edge) => {
      const node = edge?.node;
      if (isNotNil(node)) {
        nodes.push(node);
      }
      return nodes;
    }, []);
  }, [data, loading]);

  const hasNextPage = data?.viewer?.documents?.pageInfo?.hasNextPage ?? false;

  if (error) {
    return (
      <PL
        content="There was an error loading your documents. Please try again or contact support."
        pt={32}
        textAlign="center"
      />
    );
  }

  return (
    <SkeletonProvider isLoading={loading}>
      <HM content={title} fontWeight={300} />
      <Flex justifyContent="flex-start">
        <Dropdown
          id="year"
          label="Year"
          name="year"
          options={dateOptions}
          value={year}
          onChange={setYear}
        />
      </Flex>

      <GridTable
        gridTemplateColumns="minmax(auto, 25%) minmax(360px, 20%) minmax(360px, auto)"
        emptyMessage="No documents to display."
      >
        <GridTable.HeaderRow>
          <GridTable.HeaderCell label="Date" />
          <GridTable.HeaderCell label="Account" />
          <GridTable.HeaderCell label="Document" />
        </GridTable.HeaderRow>

        {documents.map((document) => (
          <AccountDocumentsTableRow key={document.id} document={document} />
        ))}
      </GridTable>

      {hasNextPage && (
        <Box pt={16} textAlign="center">
          <Button
            disabled={loading || loadingMore}
            label="Load More"
            kind="secondary"
            size="small"
            onClick={handleLoadMoreClick}
          />
        </Box>
      )}
    </SkeletonProvider>
  );
};

function AccountDocumentsTableRow({
  document,
}: {
  document: DocumentNodeFragment;
}) {
  switch (document.__typename) {
    case 'AccountDocument':
    case 'SpendAccountDocument':
      return <AccountOrSpendAccountDocumentRow document={document} />;

    case 'InvestAccountDocument':
      return <InvestAccountDocumentRow document={document} />;

    default:
      return null;
  }
}
