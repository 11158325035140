import * as React from 'react';

import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';

type SpendRedirectProps = {
  children: typeof React.Children;
  hasActiveApplication: boolean;
  hasClosedCreditCardAccount: boolean;
  hasCreditCardAccount: boolean;
};

export const SpendRedirect = ({
  children,
  hasActiveApplication,
  hasClosedCreditCardAccount,
  hasCreditCardAccount,
}: SpendRedirectProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const validPaths = [
    '/d/spend/',
    '/d/spend',
    'd/spend',
    'd/spend/credit/marketing',
  ];
  const shouldRedirect = validPaths.includes(pathname);

  React.useEffect(() => {
    if (shouldRedirect) {
      if (hasCreditCardAccount) {
        navigate({
          to: '/d/spend/credit/transactions',
          options: { replace: true },
        });
      } else if (hasActiveApplication) {
        navigate({
          to: '/d/c/credit-card-application',
          options: { replace: true },
        });
      } else if (hasClosedCreditCardAccount) {
        navigate({
          to: '/d/spend/credit/closed',
          options: { replace: true },
        });
      } else {
        navigate({
          to: '/d/spend/credit/marketing',
          options: { replace: true },
        });
      }
    }
  }, [
    hasActiveApplication,
    hasCreditCardAccount,
    hasClosedCreditCardAccount,
    shouldRedirect,
    navigate,
  ]);

  return <>{children}</>;
};
