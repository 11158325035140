import * as React from 'react';
import { Route, IndexRedirect, Redirect } from 'react-router';

import { ResearchCryptoRoute } from './crypto';
import { FundsScreenerRoute } from './funds';
import { ResearchMarketNewsRoute } from './market-news';
import { ModelPortfolioCategoriesRoute } from './model-portfolios';
import { MyPiesResearchRoute } from './my-pies';
import { ResearchLayout } from './ResearchLayout';
import { StockScreenerRoute } from './stocks';
import { WatchlistRoute } from './watchlist';

export const ResearchRoute = () => {
  return (
    <Route path="research" component={ResearchLayout}>
      <Redirect from="expert-pies" to="model-portfolios" />
      {ModelPortfolioCategoriesRoute()}
      {FundsScreenerRoute()}
      {ResearchMarketNewsRoute()}
      {MyPiesResearchRoute()}
      {StockScreenerRoute()}
      {ResearchCryptoRoute()}
      {WatchlistRoute()}
      <IndexRedirect to="market-news" />
    </Route>
  );
};
