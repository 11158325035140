import { Flex, PM, styled, theme } from '@m1/liquid-react';
import React, { useState } from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { MarketingScreenTemplate } from '~/components/MarketingScreenTemplate';
import { useBorrowMarketingScreenQuery } from '~/graphql/hooks';
import { AnnouncementContextEnum } from '~/graphql/types';
import { useLocation } from '~/hooks/useLocation';
import { AppImage } from '~/lens-toolbox/AppImage';
import { Link } from '~/toolbox/link';

import { BorrowMarketingPageModal } from './BorrowMarketingPageModal';

const StyledLink = styled(Link)`
  padding: 12px 8px;
  font-size: 16px;
  color: ${theme.colors.foregroundSecondary};
  font-weight: 400;

  span {
    width: 100%;
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.SMALL}) {
      margin-left: 40px;
      text-align: left;
    }
  }
`;

const BottomContentContainer = styled(Flex)`
  @media screen and (min-width: ${theme.breakpoints.SMALL}) {
    margin-bottom: 32px;
  }
`;

export const BorrowMarketingPage = () => {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, loading } = useBorrowMarketingScreenQuery({
    errorPolicy: 'all',
  });

  if (loading) {
    return null;
  }

  if (!data?.viewer) {
    return <GenericSystemError />;
  }

  const handleToggleModal = () => setIsModalOpen(!isModalOpen);

  const showOnlyMarginLoanCard = location.query.borrowAccountId;

  const title = data?.viewer?.borrow?.borrowMarketingScreen?.header;
  let productMarketingCards =
    data?.viewer?.borrow?.borrowMarketingScreen?.productMarketingCards;
  let disclosures = data?.viewer?.borrow?.borrowMarketingScreen?.disclosures;
  const bankDisclosure =
    data?.viewer?.borrow?.borrowMarketingScreen?.bankDisclosure;
  const marginPersonalLoanInfoLink =
    data?.viewer?.borrow?.borrowMarketingScreen?.marginPersonalLoanInfoLink;
  const marginLoanModalData =
    data?.viewer?.borrow?.borrowMarketingScreen?.marginLoanModal;
  const announcement = data?.viewer?.announcements?.forBorrowMarketing;

  // If user clicks on margin eligible account on sidebar under borrow, we only want to show the margin loan card and hide the personal loan card.
  if (showOnlyMarginLoanCard) {
    productMarketingCards = productMarketingCards?.filter(
      (card) => card?.title !== 'Personal Loans',
    );
    disclosures = disclosures?.filter(
      (disclosure) =>
        !disclosure?.linkText?.includes('Personal Loan disclosures'),
    );
  }

  return (
    <>
      <MarketingScreenTemplate
        title={title}
        productMarketingCards={productMarketingCards}
        disclosures={disclosures}
        handleToggleModal={handleToggleModal}
        announcement={announcement}
        context={AnnouncementContextEnum.BorrowMarketing}
      >
        <BottomContentContainer>
          <Flex flexDirection="column">
            {marginPersonalLoanInfoLink?.url && (
              <StyledLink
                mt={16}
                id="mobileContent"
                to={marginPersonalLoanInfoLink?.url}
                kind="secondary"
                target="_blank"
              >
                {marginPersonalLoanInfoLink?.title}
              </StyledLink>
            )}
            {!showOnlyMarginLoanCard && (
              <>
                <Flex alignItems="center">
                  {bankDisclosure?.icon && (
                    <AppImage
                      appImage={bankDisclosure?.icon}
                      width={32}
                      height={32}
                    />
                  )}

                  <PM
                    ml={16}
                    content={bankDisclosure?.text}
                    color="foregroundNeutralSecondary"
                  />
                </Flex>
                {marginPersonalLoanInfoLink?.url && (
                  <StyledLink
                    id="desktopContent"
                    to={marginPersonalLoanInfoLink?.url}
                    kind="secondary"
                    target="_blank"
                  >
                    {marginPersonalLoanInfoLink?.title}
                  </StyledLink>
                )}
              </>
            )}
          </Flex>
        </BottomContentContainer>
      </MarketingScreenTemplate>
      {marginLoanModalData && (
        <BorrowMarketingPageModal
          isOpen={isModalOpen}
          handleToggleModal={handleToggleModal}
          marginLoanModalData={marginLoanModalData}
        />
      )}
    </>
  );
};
