import {
  Box,
  Button,
  Flex,
  HXS,
  Modal,
  ModalContent,
  type LayoutableProps,
} from '@m1/liquid-react';
import * as React from 'react';

import { useSetFullyPaidLendingStatusMutation } from '~/graphql/hooks';
import { useAnalytics } from '~/hooks/useAnalytics';
import { RichText } from '~/lens-toolbox/RichText/RichText';
import { useToast } from '~/toasts';
import { Checkbox } from '~/toolbox/checkbox';

import type { FullyPaidLendingPage } from './FullyPaidLendingSettingsPage';

export type FullyPaidLendingOptInSectionProps = LayoutableProps & {
  optInSection: NonNullable<FullyPaidLendingPage['optInSection']>;
};

export function FullyPaidLendingOptInSection({
  optInSection,
  ...rest
}: FullyPaidLendingOptInSectionProps) {
  const analytics = useAnalytics();
  const { addToast } = useToast();

  const [isEnrollmentAgreedTo, setIsEnrollmentAgreedTo] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [setFullyPaidLendingStatus, { loading }] =
    useSetFullyPaidLendingStatusMutation();

  const handleConfirm = () => {
    setFullyPaidLendingStatus({
      refetchQueries: ['FullyPaidLendingSettingsPage'],
      onCompleted: (data) => {
        const payload = data.setFullyPaidLendingStatus;
        if (payload?.didSucceed) {
          setIsModalOpen(false);
        }
        if (payload?.outcome?.successAnalyticsEvent) {
          analytics.recordAppAnalyticsEvent(
            payload.outcome.successAnalyticsEvent,
          );
        }
      },
      onError: (err) => {
        addToast({
          content: err.message,
          kind: 'alert',
        });
      },
      variables: { input: { fplStatus: true } },
    });
  };

  return (
    <Box {...rest}>
      <Button
        kind="secondary"
        label={optInSection.ctaLabel}
        onClick={() => setIsModalOpen(true)}
      />
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onExit={() => setIsEnrollmentAgreedTo(false)}
      >
        <ModalContent css={{ padding: '32px !important' }}>
          {optInSection.confirmContent.title && (
            <HXS content={optInSection.confirmContent.title} />
          )}
          {optInSection.confirmContent.paragraphs.map((richText, index) => (
            <RichText
              containerProps={{
                alignItems: 'normal',
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                mt: 16,
              }}
              key={index}
              richText={richText}
            />
          ))}
          <Checkbox
            css={{
              '& > div': {
                margin: 0,
                marginRight: 8,
              },
            }}
            label={optInSection.confirmContent.agreementLabel}
            mt={32}
            onChange={() => setIsEnrollmentAgreedTo(!isEnrollmentAgreedTo)}
            value={isEnrollmentAgreedTo}
          />
          <Flex alignItems="center" flexDirection="column" gap={8} mt={32}>
            <Button
              disabled={loading || !isEnrollmentAgreedTo}
              fullWidth
              label={
                loading
                  ? 'Enrolling...'
                  : optInSection.confirmContent.confirmCtaLabel
              }
              kind="primary"
              onClick={handleConfirm}
            />
            <Button
              label={optInSection.confirmContent.cancelCtaLabel}
              kind="text"
              onClick={() => setIsModalOpen(false)}
            />
          </Flex>
        </ModalContent>
      </Modal>
    </Box>
  );
}
