import { Flex, Box, styled } from '@m1/liquid-react';
import * as React from 'react';

import {
  SliceableNameCell,
  SliceableAddToPortfolioButton,
} from '~/components/pie';
import { SecurityMiniChartAndReturn } from '~/components/security/SecurityMiniChartAndReturn';
import {
  HistoricalQuotePeriodEnum,
  ScreenerCryptoFragment,
} from '~/graphql/types';
import { AppPill } from '~/lens-toolbox/AppPill';

import type { AppState } from '~/redux';
import { clickedOnSliceable } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

import { Table } from '~/toolbox/table';
import { formatNumber } from '~/utils';

import { useCryptoScreenerResult } from './CryptoScreenerProvider';

const PerformanceCellContainer = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    padding-left: 8px;
  }
`;

const formatUppercaseNumber = (num: number) =>
  formatNumber(num, '0.0a').toUpperCase();

export const CryptoResultsRow = ({
  index,
  hasCryptoAccount,
  crypto,
}: {
  crypto: ScreenerCryptoFragment['node'] | null | undefined;
  hasCryptoAccount: boolean | undefined;
  index: number;
}) => {
  const dispatch = useDispatch();
  const addMode = useSelector((state: AppState) => state.mode === 'ADD');
  const {
    queryResult: { variables },
  } = useCryptoScreenerResult();

  if (!crypto) {
    return null;
  }

  const handleRowClick = (id: string, e: React.SyntheticEvent) => {
    const ele = e.target;
    // @ts-expect-error - TS2339 - Property 'type' does not exist on type 'EventTarget'.
    if (ele.type !== 'checkbox') {
      dispatch(clickedOnSliceable(id));
    }
  };

  return (
    <Table.Row key={crypto.symbol}>
      <Table.Cell
        content={
          <Flex>
            <SliceableNameCell
              selectable
              disabled={Boolean(crypto.restrictions)}
              sliceable={crypto}
            />
            {crypto.restrictionPill && (
              <Box flex="1 0 auto" alignSelf="center">
                <AppPill appPill={crypto.restrictionPill} />
              </Box>
            )}
          </Flex>
        }
        onClick={(e) => handleRowClick(crypto.id, e)}
      />
      <Table.Cell
        align="right"
        content={
          crypto.quote?.lastPrice
            ? `$${formatNumber(
                crypto.quote.lastPrice,
                crypto.quote.lastPrice < 1 ? '0,0.00[0000000000]' : '0,0.00',
              ).toUpperCase()}`
            : '--'
        }
        onClick={(e) => handleRowClick(crypto.id, e)}
      />
      <Table.Cell
        align="right"
        content={
          crypto.marketCap ? formatUppercaseNumber(crypto.marketCap) : '--'
        }
        onClick={(e) => handleRowClick(crypto.id, e)}
      />
      <Table.Cell
        align="right"
        content={crypto.volume ? formatUppercaseNumber(crypto.volume) : '--'}
        onClick={(e) => handleRowClick(crypto.id, e)}
      />
      <Table.Cell
        align="right"
        content={
          crypto.circulatingSupply
            ? formatUppercaseNumber(crypto.circulatingSupply)
            : '--'
        }
        onClick={(e) => handleRowClick(crypto.id, e)}
      />
      <Table.Cell align="right" onClick={(e) => handleRowClick(crypto.id, e)}>
        <PerformanceCellContainer>
          <SecurityMiniChartAndReturn
            percentChange={crypto.priceChange?.percentChange}
            securityId={crypto.id}
            period={variables?.period || HistoricalQuotePeriodEnum.OneYear}
          />
        </PerformanceCellContainer>
      </Table.Cell>
      <Table.Cell align="center">
        {/* TODO - make click targets work so surrounding cell still triggers
         clickedOnSliceable but button only triggers adding slices to portfolios
         via modal without navigation */}
        {hasCryptoAccount && !addMode && !crypto.restrictions?.length && (
          <SliceableAddToPortfolioButton
            sliceable={crypto}
            id={crypto.id}
            type={crypto.type}
            label="Add"
            kind="secondary"
            left={10}
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
};
