import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import { MyPiesResearchPage } from '~/pages/dashboard/research/my-pies/components/MyPiesResearchPage';
import { createRouteComponent } from '~/router';
import { APP_MODES } from '~/static-constants';

import { AddMyPieDetailsRoute } from './details';

export const AddMyPiesRoute = () => {
  return (
    <Route path="my-pies">
      {AddMyPieDetailsRoute()}
      <IndexRoute
        component={createRouteComponent(MyPiesResearchPage, (props) => ({
          ...props,
          minimal: true,
          mode: APP_MODES.ADD,
          pieId: props.params.userPieId,
        }))}
      />
    </Route>
  );
};
