import { Box, HXS, Card } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { EditTransferScheduleForm } from '~/forms';

import { usePageEditTransferScheduleQuery } from '~/graphql/hooks';
import {
  enteredPage,
  exitedPage,
  finishedEditingTransferSchedule,
} from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { InjectedRouteProps } from '~/router';
import { Spinner } from '~/toolbox/spinner';

export const EditTransferSchedulePage = ({ params }: InjectedRouteProps) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(enteredPage('EDIT_TRANSFER_SCHEDULE', undefined));

    return () => dispatch(exitedPage('EDIT_TRANSFER_SCHEDULE', undefined));
  }, [dispatch]);

  const { data, loading } = usePageEditTransferScheduleQuery({
    variables: {
      id: params.scheduledTransferRuleId,
    },
  });

  if (loading) {
    return <Spinner />;
  }

  if (data?.node?.__typename !== 'ScheduledTransferRule') {
    return <GenericSystemError />;
  }

  const handleDelete = (): void => {
    dispatch(finishedEditingTransferSchedule(params.scheduledTransferRuleId));
  };

  const handleSubmit = (values: Record<string, any>): void => {
    dispatch(
      finishedEditingTransferSchedule({
        ...values,
        scheduledTransferRuleId: params.scheduledTransferRuleId,
      }),
    );
  };

  return (
    <Box m="0 auto" p="64px 0 100px 0" width={490}>
      <HXS
        content={
          data?.node?.isCreditCardAutoPay || data?.node?.isPersonalLoanAutoPay
            ? 'Edit AutoPay schedule'
            : 'Edit schedule'
        }
      />
      <Card mt={32} p={32}>
        <EditTransferScheduleForm
          onClickDelete={handleDelete}
          onSubmit={handleSubmit}
          scheduledTransferRuleId={params.scheduledTransferRuleId}
        />
      </Card>
    </Box>
  );
};
