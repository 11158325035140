import * as React from 'react';
import { Route } from 'react-router';

import { OpenInvestAccountPage } from './OpenInvestAccountPage';

export const OpenInvestAccountRoute = () => {
  return (
    <Route
      path="open-invest-account(/:step)"
      component={OpenInvestAccountPage}
    />
  );
};
