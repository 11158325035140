import { LayoutableProps } from '@m1/liquid-react';
import * as React from 'react';

import { useAmountRangeValidation } from '~/hooks/useAmountRangeValidation';
import { Input } from '~/toolbox/Input';
import { formatCurrencyWithCommas } from '~/utils/formatting';

export type SmartTransferBalanceTriggerProps = LayoutableProps & {
  balanceTriggerEditRequirements: any;
  onInputDataChange: (arg0: {
    amountErrorMessage: string;
    amountValue: string;
  }) => void;
  preFilledAmount?: string;
};

export const SmartTransferBalanceTriggerInput = ({
  balanceTriggerEditRequirements,
  onInputDataChange,
  preFilledAmount = '',
  ...rest
}: SmartTransferBalanceTriggerProps) => {
  const [amountValueInitial, setAmountValue] = React.useState(preFilledAmount);
  const { amountErrorMessage, amountValue, onInputChange } =
    // @ts-expect-error - TS2554 - Expected 5-7 arguments, but got 4.
    useAmountRangeValidation(
      amountValueInitial,
      setAmountValue,
      balanceTriggerEditRequirements.minBalanceTriggerThreshold,
      balanceTriggerEditRequirements.maxBalanceTriggerThreshold,
    );
  React.useEffect(() => {
    onInputDataChange({
      amountValue,
      amountErrorMessage,
    });
  }, [amountValue, amountErrorMessage, onInputDataChange]);
  return (
    <Input
      {...rest}
      autoComplete="off"
      onChange={onInputChange}
      value={formatCurrencyWithCommas(amountValue)}
      error={amountErrorMessage}
      label="Enter amount"
      includeDollarSign
    />
  );
};
