import * as React from 'react';

export class ErrorIcon extends React.Component<{
  className?: string;
}> {
  render() {
    return (
      <svg
        className={this.props.className || undefined}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <path
          fill="#D02F33"
          stroke="#D02F33"
          d="M15.5 8c0 4.142-3.358 7.5-7.5 7.5C3.858 15.5.5 12.142.5 8 .5 3.858 3.858.5 8 .5c4.142 0 7.5 3.358 7.5 7.5z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M7.875 8.752c.484 0 .875-.354.875-.792V4.792c0-.438-.391-.792-.875-.792S7 4.354 7 4.792V7.96c0 .438.391.792.875.792zM7.875 10.25c-.483 0-.875.391-.875.875 0 .483.392.875.875.875s.875-.392.875-.875c0-.484-.392-.875-.875-.875z"
          clipRule="evenodd"
        />
      </svg>
    );
  }
}
