import type { Maybe } from '@m1/liquid-react';
import isNil from 'lodash-es/isNil';

/**
 * Coerces the specified value to a boolean and returns true if the value is
 * _not_ null or undefined AND true. This is a utility function to address
 * issues with the @typescript-eslint/strict-boolean-expressions rule and is
 * a little more clear than using Boolean(), because it's intended to _only_
 * accept boolean values.
 */
export function isTrue(value: boolean | null | undefined): boolean {
  return value ?? false;
}

/**
 * Inverse check of {@link isTrue} that accepts a boolean, null, or undefined
 * value and returns false if the coerced boolean value is `false`.
 */
export function isFalse(value: boolean | null | undefined): boolean {
  return !isTrue(value);
}

/**
 * Returns an array of values even if the specified values are null or undefined.
 * Since most API responses from arrays could also be null or undefined, we provide
 * this type to avoid having to do nullish coalescing all over the place.
 */
export function toArray<T extends Array<any>>(values: T | null | undefined): T {
  return (values ?? []) as T;
}

export { default as isNil } from 'lodash-es/isNil';

/**
 * Returns true if the specified value is _not_ null or undefined.
 *
 * @example
 *   console.log(isNotNil("test"));
 *   // Expected output: true
 *
 *   console.log(isNotNil(null));
 *   // Expected output: false
 *
 *   console.log(isNotNil(undefined));
 *   // Expected output: false
 */
export function isNotNil<T extends Maybe<unknown>>(
  value?: T,
): value is NonNullable<T> {
  return !isNil(value);
}
