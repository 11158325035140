import { Box, Button, HXS, PL, styled } from '@m1/liquid-react';
import * as React from 'react';
import { InjectedRouteProps } from 'react-router';

import { ExternalLink } from '~/components/ExternalLink';
import { useNavigate } from '~/hooks/useNavigate';
import { useSentry } from '~/hooks/useSentry';
import { Grid } from '~/toolbox/grid';

const StyledNotFoundPageContainer = styled(Box)`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotFoundPage = (props: InjectedRouteProps) => {
  const navigate = useNavigate();
  const sentry = useSentry();

  React.useEffect(() => {
    sentry.message('User caused 404', {
      level: 'warning',
      extra: {
        location: props.location,
      },
    });
  }, [props.location, sentry]);

  return (
    <StyledNotFoundPageContainer>
      <Grid.Row xsCenter xsTextCenter>
        <Grid.Col xs={8}>
          <HXS content="Page Not Found" />
          <PL pt={8}>
            We're sorry, this page doesn't exist. If you believe this is an
            error, please contact{' '}
            <ExternalLink destination="SUPPORT_CENTER">Support</ExternalLink>.
          </PL>
          <div
            style={{
              paddingTop: 64,
            }}
          >
            <Button
              kind="primary"
              size="large"
              label="Back to Dashboard"
              onClick={() => {
                navigate({ to: '/d/home' });
              }}
            />
          </div>
        </Grid.Col>
      </Grid.Row>
    </StyledNotFoundPageContainer>
  );
};
