import * as React from 'react';
import { IndexRoute, Route } from 'react-router';

import { FundScreenerPage } from '~/pages/dashboard/research/funds/components';

import { AddFundDetailsRoute } from './details';

export const AddFundRoute = () => {
  return (
    <Route path="funds">
      {AddFundDetailsRoute()}
      <IndexRoute component={FundScreenerPage} />
    </Route>
  );
};
