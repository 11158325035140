/**
 * Returns the nth fibonacci number. Uses memoization for performance.
 * @param {number} n the index of the fibonacci number
 * @param {{ [key: number]: number }} memo the memoization cache
 * @returns the nth fibonacci nubmer
 */
export const fibonacci = (
  n: number,
  memo: Record<number, number> = {},
): number => {
  if (n <= 1) {
    return n;
  }

  if (memo[n]) {
    return memo[n];
  }

  // eslint-disable-next-line no-unused-vars
  const result = fibonacci(n - 1) + fibonacci(n - 2);

  // eslint-disable-next-line no-param-reassign
  memo[n] = result;

  return result;
};
