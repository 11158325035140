import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { EarnLandingPage } from './EarnLandingPage';
export const SavingsTransactionsRoute = () => {
  return (
    <Route
      path="transactions"
      component={createRouteComponent(EarnLandingPage)}
    />
  );
};
