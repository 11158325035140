import * as React from 'react';
import { Route } from 'react-router';

import { UnmanagedHoldingsSelectParentPiePage } from './UnmanagedHoldingsSelectParentPiePage';

export const UnmanagedHoldingsSelectParentPieRoute = () => {
  return (
    <Route
      path="select-parent-pie"
      component={({ location }) => (
        <UnmanagedHoldingsSelectParentPiePage
          previousPieEditorConfig={location.state?.previousConfig}
          newPie={location.state?.pie}
        />
      )}
    />
  );
};
