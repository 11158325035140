import * as React from 'react';
import { Route } from 'react-router';

import { SavingsOnboardingInitialFundingPage } from './SavingsOnboardingInitialFundingPage';

export const SavingsOnboardingInitialFunding = () => {
  return (
    <Route
      path="onboarding/savings-onboarding-initial-funding"
      component={SavingsOnboardingInitialFundingPage}
      fallbackRoute="/d/c/create-transfer"
      cover
    />
  );
};
