import pick from 'lodash-es/pick';
// @ts-expect-error - TS2305 - Module '"react-router-redux"' has no exported member 'LocationChangeAction'.
import { LocationChangeAction, LOCATION_CHANGE } from 'react-router-redux';

import { ACTION_TYPES as ACTIONS } from '~/redux/actions';

import { FlowDefinition } from '../newFlowsReducer.definition';

import { Routable } from '../newFlowsReducer.types';

type RequiredState<S> = {
  step: S;
  stepTitle?: string;
};

type FlowReducerStep<D extends FlowDefinition> = keyof D['steps'];

export type FlowReducerState<D extends FlowDefinition<any>> = RequiredState<
  FlowReducerStep<D>
> & {
  _isFlowRunning: boolean;
  basePath: string;
};

export function createAugmentedFlowReducer<D extends FlowDefinition, S>(
  definition: D,
  initialState: S & RequiredState<FlowReducerStep<D>>,
  customReducer?: (arg0: S, arg1: any) => S,
) {
  const augmentedInitialState: FlowReducerState<D> = {
    ...initialState,
    _isFlowRunning: false,
    basePath: '',
  };
  const defaultReducer = createDefaultReducer(
    augmentedInitialState,
    customReducer,
  );
  return function (state: any, action: any): any {
    switch (action.type) {
      case ACTIONS.BEGIN_FLOW:
      case ACTIONS.FINISHED_FLOW_STEP:
      case ACTIONS.END_FLOW:
        return action.meta.flow === definition.name
          ? defaultReducer(state, action)
          : state;
      default:
        return defaultReducer(state, action);
    }
  };
}

function createDefaultReducer(
  // @ts-expect-error - TS7006 - Parameter 'initialState' implicitly has an 'any' type.
  initialState,
  customReducer: ((...args: Array<any>) => any) | null | undefined,
) {
  const stepReducer = createStepReducer(initialState);
  // @ts-expect-error - TS7006 - Parameter 'action' implicitly has an 'any' type.
  return function (state = initialState, action) {
    switch (action.type) {
      case ACTIONS.BEGIN_FLOW:
        return {
          ...initialState,
          ...pick(action.payload, Object.keys(initialState)),
          step: initialState.step,
          _isFlowRunning: true,
        };
      case ACTIONS.END_FLOW:
        return initialState;
      case LOCATION_CHANGE:
        return state._isFlowRunning
          ? {
              ...state,
              step: stepReducer(state, action),
            }
          : state;
      default:
        return state._isFlowRunning && customReducer
          ? customReducer(state, action)
          : state;
    }
  };
}

function createStepReducer(initialState: Routable) {
  return function stepReducer(
    state: Routable,
    action: LocationChangeAction,
  ): string {
    const pathSegments = action.payload.pathname
      .replace(state.basePath, '')
      .split('/')
      .filter(Boolean);

    if (action.payload.state && action.payload.state.step) {
      return action.payload.state.step;
    }

    // If there's no path after basePath, set step to initial step. Otherwise, use the next segment in the path.
    return pathSegments.length === 0 ? initialState.step : pathSegments[0];
  };
}
