import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { enteredPage, exitedPage } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';
import { InjectedRouteProps } from '~/router';

import { readPath } from './components/SharePieDetailsSection/SharePieDetailsSection.utils';
import { SharePiePageContent } from './components/SharePiePageContent';

export const SharedPiePage = (props: InjectedRouteProps) => {
  const dispatch = useDispatch();
  const shareToken = props.location.query.token;
  const path = readPath(props.location);

  React.useEffect(() => {
    dispatch(enteredPage('SHARE', undefined));
    return () => dispatch(exitedPage('SHARE', undefined));
  }, [dispatch]);

  return (
    <Box backgroundColor="backgroundNeutralSecondary">
      <SharePiePageContent shareToken={shareToken} path={path} />
    </Box>
  );
};
