import * as React from 'react';
import { Route } from 'react-router';

import { ReacceptLegalTermsPage } from './ReacceptLegalTermsPage';

export const ReacceptLegalTermsRoute = () => {
  return (
    <Route
      path="reaccept-legal-terms"
      component={ReacceptLegalTermsPage}
      cover
    />
  );
};
