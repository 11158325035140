import * as React from 'react';
import { Route } from 'react-router';

import { createRouteComponent } from '~/router';

import { FundingHistoryPage } from './FundingHistoryPage';

export const FundingHistoryRoute = () => {
  return (
    <Route
      path="funding-history"
      component={createRouteComponent(FundingHistoryPage)}
    />
  );
};
