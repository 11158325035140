import * as React from 'react';

import {
  Controller,
  ControllerProps,
  FieldValues,
  Path,
} from 'react-hook-form';

import { Input, InputProps } from '~/toolbox/Input';

type InputFieldProps<
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues = FieldValues,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  InputProps & {
    transformInput?: (value: any, prevValue?: string) => any; // display value => data value
    transformValue?: (value: any) => string | number | undefined; // data value => display value
  };

export const ControlledInput = <
  TName extends Path<TFieldValues>,
  TFieldValues extends FieldValues,
>({
  control,
  name,
  rules,
  transformInput,
  transformValue,
  ...inputProps
}: InputFieldProps<TName, TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState, ...rest }) => (
        <Input
          {...field}
          {...inputProps}
          {...rest}
          value={transformValue?.(field.value) ?? field.value}
          error={fieldState.error?.message}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            field.onChange(
              transformInput?.(e.target.value, field.value) ?? e.target.value,
            );
          }}
        />
      )}
    />
  );
};
