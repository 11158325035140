import pick from 'lodash-es/pick';
import { LOCATION_CHANGE } from 'react-router-redux';

import { ACTION_TYPES as ACTIONS } from '~/redux/actions';
import { MOVE_MONEY_FLOW_STEPS as STEPS } from '~/static-constants';

import { FlowState } from '../newFlowsReducer.types';
import { createFlowReducer, createStepReducer } from '../utils';

export type MoveMoneyFlowState = FlowState<
  typeof STEPS,
  {
    accountNumber: string;
    basePath: string;
    previousRouteName: string | null | undefined;
    directDepositIds: { hysaIds: string[] | null; investIds: string[] | null };
  }
>;

export const moveMoneyFlowInitialState: MoveMoneyFlowState = {
  accountNumber: '',
  basePath: '',
  previousRouteName: null,
  step: STEPS.MOVE_MONEY,
  stepTitle: '',
  directDepositIds: {
    hysaIds: null,
    investIds: null,
  },
};

const stepReducer = createStepReducer(moveMoneyFlowInitialState);

function reducer(
  state: MoveMoneyFlowState = moveMoneyFlowInitialState,
  action: any,
): MoveMoneyFlowState {
  switch (action.type) {
    case ACTIONS.BEGIN_FLOW:
      return {
        ...moveMoneyFlowInitialState,
        ...pick(action.payload, Object.keys(moveMoneyFlowInitialState)),
        step: action.payload.initialStep || moveMoneyFlowInitialState.step,
      };
    case ACTIONS.END_FLOW:
      return moveMoneyFlowInitialState;
    case ACTIONS.FINISHED_FLOW_STEP:
      return state;
    case 'SET_MOVE_MONEY_FROM':
      return {
        ...state,
        previousRouteName: action.payload,
      };
    case 'SET_MOVE_MONEY_DIRECT_DEPOSIT_IDS': {
      return {
        ...state,
        directDepositIds: action.payload,
      };
    }
    case LOCATION_CHANGE:
      return {
        ...state,
        step: stepReducer(state, action),
      };
    default:
      return state;
  }
}

export const moveMoney = createFlowReducer('MOVE_MONEY', reducer);
