import * as React from 'react';
import { IndexRedirect, Route } from 'react-router';

import { SavingsRedirectRoute } from '~/pages/dashboard/spend/SavingsRedirectRoute';
import { SpendPage } from '~/pages/dashboard/spend/SpendPage';
import { createRouteComponent } from '~/router';

import { CreditCardRoute } from './credit';

export const SpendRoute = () => (
  <Route path="spend" component={createRouteComponent(SpendPage)}>
    {/* Purely to handle old deeplinks */}
    {SavingsRedirectRoute()}

    {/* Valid Spend Route */}
    {CreditCardRoute()}

    <Route path="debit-card">
      <IndexRedirect to="/d/save/checking/debit-card" />
    </Route>

    <Route path="direct-deposit">
      <IndexRedirect to="/d/save/checking/direct-deposit" />
    </Route>

    <Route path="transactions">
      <IndexRedirect to="/d/save/checking/direct-deposit" />
    </Route>
  </Route>
);
