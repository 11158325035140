type KindEnum = 'basic' | 'simple' | 'with-spacing';

export const collectPaddingStyles = (
  $kind: KindEnum | undefined,
): Record<string, any> | null | undefined => {
  switch ($kind) {
    case 'basic':
      return null;
    case 'simple':
      return {
        paddingTop: '8px',
      };
    case 'with-spacing':
    default:
      return {
        paddingBottom: '8px',
        paddingTop: '16px',
      };
  }
};
