import { Button } from '@m1/liquid-react';
import * as React from 'react';

import { hasChanges, hasInactiveSlices, isPieTreeValid } from '~/pie-trees';
import { useSelector } from '~/redux/hooks';

export const PortfolioOrganizerSaveButton = ({
  onClick,
  primaryButtonLabel = 'Save',
}: {
  onClick: () => void;
  primaryButtonLabel?: string;
}) => {
  const { isEditing, pieTree, pieTreeBeforeUpdates, unmanagedHoldingsToMove } =
    useSelector((state) => {
      const {
        pieTree,
        pieTreeBeforeUpdates,
        session,
        unmanagedHoldingsToMove,
      } = state.portfolioOrganizer;

      return {
        isEditing:
          session.mode === 'EDIT_ROOT_PIE' || session.mode === 'EDIT_PIE',
        pieTree,
        pieTreeBeforeUpdates,
        unmanagedHoldingsToMove,
      };
    });

  const pieHasChanges = React.useMemo(() => {
    return (
      hasChanges(pieTreeBeforeUpdates, pieTree) ||
      unmanagedHoldingsToMove?.length
    ); // Note, we need the pieTree.slices dependency here, otherwise useMemo
    // won't recalculate pieHasChanges if the slices change
  }, [pieTree, pieTree.slices, pieTreeBeforeUpdates, unmanagedHoldingsToMove]); // eslint-disable-line

  // TODO: once we re-enable clicking down into user pies while editing
  //       we should modify isPieTreeValid to check for inactive slices
  //       the reason we currently don't do that is because we don't want
  //       to check for inactive slices in nested pies until we allow the
  //       user to click into user pies.
  return (
    <Button
      disabled={
        !isPieTreeValid(pieTree) ||
        hasInactiveSlices(pieTree) ||
        (!pieHasChanges && isEditing)
      }
      kind="inverse-primary"
      label={primaryButtonLabel}
      onClick={() => onClick()}
    />
  );
};
