import React from 'react';

import {
  SliceableNameCell,
  PieMiniChartAndReturn,
  PieRiskLevel,
} from '~/components/pie';

import {
  ModelPortfolioScreenerNodeFragment,
  PieBacktestPeriodEnum,
} from '~/graphql/types';
import { useSliceableClick } from '~/hooks/useSliceableClick';
import { Table } from '~/toolbox/table';

import { StyledPerformanceCellContainer } from './CategoryDetails.styled';

type ModelPortfolioTableRowProps = {
  pie: ModelPortfolioScreenerNodeFragment;
  period: PieBacktestPeriodEnum;
};

export const ModelPortfolioTableRow = ({
  pie,
  period,
}: ModelPortfolioTableRowProps) => {
  const onClick = useSliceableClick();
  const { analysis, performance, id } = pie;

  const totalHoldings = analysis?.totalHoldings ?? '--';
  const dividendYield =
    typeof analysis?.dividendYield === 'number'
      ? `${analysis.dividendYield}%`
      : '--';
  return (
    <Table.Row key={id} onClick={() => onClick(id)}>
      <Table.Cell>
        <SliceableNameCell selectable sliceable={pie} />
      </Table.Cell>
      <Table.Cell>{totalHoldings}</Table.Cell>
      <Table.Cell>{dividendYield}</Table.Cell>
      <Table.Cell colSpan={2}>
        <StyledPerformanceCellContainer>
          <PieMiniChartAndReturn pieId={id} period={period} />
        </StyledPerformanceCellContainer>
      </Table.Cell>
      <Table.Cell>
        {performance?.riskGrade && performance.riskLevel && (
          <PieRiskLevel
            riskGrade={performance.riskGrade}
            riskLevel={performance.riskLevel}
            size="small"
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
};
