import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { ResetPasswordFlow } from '~/flows';
import { useNavigate } from '~/hooks/useNavigate';
import { Grid } from '~/toolbox/grid';
import { Link } from '~/toolbox/link';
import { M1Logo } from '~/toolbox/M1Logo';

type ResetPasswordPageProps = {
  basePath: string;
  token: string;
};

export const ResetPasswordPage = ({
  basePath,
  token,
}: ResetPasswordPageProps) => {
  const navigate = useNavigate();
  const handleFinish = ({ didSucceed }: { didSucceed: boolean }): void => {
    const next = didSucceed ? '/d/home' : '/forgot-password';
    navigate({ to: next });
  };

  return (
    <Grid
      constrain
      style={{
        paddingTop: 35,
      }}
    >
      <Grid.Row xsCenter xsTextCenter>
        <Grid.Col md={5} sm={7} xs={12}>
          <Box pb={16}>
            <M1Logo width={42} />
          </Box>
          <ResetPasswordFlow
            basePath={basePath}
            onFinish={handleFinish}
            token={token}
          />
          <Box pt={32}>
            <Link to="/login">Back to login</Link>
          </Box>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};
