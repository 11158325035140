import { HXS, PL, Card } from '@m1/liquid-react';
import * as React from 'react';
import { InjectedRouteProps } from 'react-router';

import { CreateOneTimeOrScheduledTransferForm } from '~/forms';
import { useGetTransferViewerPreloadQuery } from '~/graphql/hooks';
import { TransferTypeEnum } from '~/graphql/types';
import { useLocation } from '~/hooks/useLocation';
import { clickedSwitchModes, dismissNotifications } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';
import { CREATE_TRANSFER_FLOW_MODES as MODES } from '~/static-constants';
import { formatNumber } from '~/utils';

type CreateTransferProps = InjectedRouteProps & {
  onFinishStep: (...args: Array<any>) => any;
};

export const SetupTransferStep = (props: CreateTransferProps) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const transferType =
    location?.query.mode && location.query.mode === 'SCHEDULE'
      ? TransferTypeEnum.ScheduledTransferRule
      : TransferTypeEnum.TransferInstance;

  const initialValues = useSelector((state) => ({
    amount:
      (location?.query.amount ||
        state.form?.['transfer-instance']?.values?.amount) ??
      null,
    fromParticipantId: location?.query.fromParticipantId || null,
    toParticipantId: location?.query.toParticipantId || null,
    ...state.newFlows.CREATE_TRANSFER.input,
  }));
  const { data } = useGetTransferViewerPreloadQuery({
    variables: {
      fromParticipantId: location?.query.fromParticipantId || null,
      toParticipantId: location?.query.toParticipantId || null,
      transferType,
    },
  });

  React.useEffect(() => {
    return () => dispatch(dismissNotifications(undefined));
  }, [dispatch]);

  const requirements = data?.viewer.transfers?.requirements;

  const requiresInitialDeposit = Boolean(requirements?.requiresInitialDeposit);

  const minTransferAmount = requirements?.minTransferAmount;
  const isCreditCardPayment = Boolean(
    requirements?.isCreditCardPaymentTransfer,
  );
  const isPersonalLoanPayment = Boolean(requirements?.isLoanPaymentTransfer);
  const isCreditAutoPayEnabled = data?.viewer.credit?.isAutoPayEnabled;
  const isPersonalLoansAutoPayEnabled =
    data?.viewer.borrow?.personalLoans?.isPersonalLoansAutoPayEnabled;

  const transferDueDate = requirements?.transferDueDateLabel;
  const handleSwitchModes = (mode: ValueOf<typeof MODES>): void => {
    dispatch(
      clickedSwitchModes({
        mode,
      }),
    );
  };

  const readTitleContent = (): string => {
    const isSchedule = transferType === 'SCHEDULED_TRANSFER_RULE';

    if (requiresInitialDeposit) {
      return 'Initial deposit';
    } else if (isSchedule && isCreditCardPayment) {
      return 'Enable AutoPay';
    } else if (isSchedule && isPersonalLoanPayment) {
      return 'Set up AutoPay';
    } else if (isSchedule && !isCreditCardPayment) {
      return 'Create a schedule';
    } else if (!isSchedule && isCreditCardPayment) {
      return 'Pay M1 Credit Card';
    } else if (!isSchedule && isPersonalLoanPayment) {
      return 'Pay your personal loan';
    }
    return 'One-time transfer';
  };

  return (
    <>
      <HXS color="foregroundNeutralMain" content={readTitleContent()} />
      {(isCreditCardPayment || isPersonalLoanPayment) && (
        <PL content={transferDueDate} />
      )}
      {requiresInitialDeposit && minTransferAmount && (
        <PL
          content={`Please make a minimum deposit of ${formatNumber(
            minTransferAmount,
            '$0,0.[00]',
          )}`}
          style={{
            marginTop: 8,
          }}
        />
      )}
      <Card mt={32} p={32}>
        <CreateOneTimeOrScheduledTransferForm
          initialValues={initialValues}
          onSwitchModes={handleSwitchModes}
          onSubmit={props.onFinishStep}
          requiresInitialDeposit={requiresInitialDeposit}
          isCreditCardPayment={isCreditCardPayment}
          isPersonalLoanPayment={isPersonalLoanPayment}
          isCreditAutoPayEnabled={isCreditAutoPayEnabled}
          isPersonalLoansAutoPayEnabled={isPersonalLoansAutoPayEnabled}
        />
      </Card>
    </>
  );
};
