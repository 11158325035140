import * as React from 'react';
import { Route } from 'react-router';

import { EditTransferSchedulePage } from './EditTransferSchedulePage';

export const EditTransferScheduleRoute = () => {
  return (
    <Route
      path="edit-transfer-schedule/:scheduledTransferRuleId"
      component={EditTransferSchedulePage}
      fallbackRoute="/d/transfers"
      cover
    />
  );
};
