import { Box } from '@m1/liquid-react';
import * as React from 'react';

import { GenericSystemError } from '~/components/GenericSystemError';
import { useConfirmationDialogContainerQuery } from '~/graphql/hooks';
import { ConfirmationDialogPieFragment } from '~/graphql/types';
import { preparePieTreeForUpdate } from '~/pie-trees';
import { useSelector } from '~/redux/hooks';
import { Spinner } from '~/toolbox/spinner';

import { ConfirmationDialog } from './ConfirmationDialog';

type ConfirmationDialogContainerProps = {
  active: boolean;
  isCrypto?: boolean;
  onCancel: () => void;
  onConfirm: (e: React.SyntheticEvent<any>) => void | Promise<void>;
  showApplicableLocations: boolean;
};

export const ConfirmationDialogContainer = ({
  active,
  isCrypto = false,
  onCancel,
  onConfirm,
  showApplicableLocations,
}: ConfirmationDialogContainerProps) => {
  const { pieTree, unmanagedHoldingsToMove } = useSelector(
    (state) => state.portfolioOrganizer,
  );
  const { data, loading } = useConfirmationDialogContainerQuery({
    variables: {
      isCrypto,
      pieId: pieTree.__id,
      newSerializedPieTree: JSON.stringify(preparePieTreeForUpdate(pieTree)),
      unmanagedHoldingIdsForMergedSlices: unmanagedHoldingsToMove,
    },
  });

  if (loading) {
    return (
      <Box padding={16}>
        <Spinner />
      </Box>
    );
  }

  if (!data?.node || !data.viewer) {
    return <GenericSystemError />;
  }

  const { node, viewer } = data;

  return (
    <ConfirmationDialog
      active={active}
      onCancel={onCancel}
      onConfirm={onConfirm}
      pie={node as ConfirmationDialogPieFragment}
      pieChangesConfirmation={viewer.invest?.pieChangesConfirmation}
      showApplicableLocations={showApplicableLocations}
    />
  );
};
