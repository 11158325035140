import { Box, Button, css, Flex, PS, styled, Card } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';

import { useSmartTransferWaterfallQuery } from '~/graphql/hooks';
import { DragAndDrop } from '~/toolbox/drag-and-drop';

import type { SmartTransferContraParticipantEntryInput } from '../../redux/reducers/newFlows/reducers/createSmartTransferReducer';

import { SmartTransferWaterfallEntry } from './SmartTransferWaterfallEntry';

type SmartTransferSelectedContraParticipantsProps = {
  balanceTriggerType: 'OVER_BALANCE' | 'UNDER_BALANCE';
  contraParticipantEntries: Array<SmartTransferContraParticipantEntryInput>;
  contraParticipantIdToEditRequirementsIdMapping?: Record<string, string>;
  editRequirements: any;
  focusAccountId: string;
  isInEditOrderMode?: boolean;
  isShowingEntryOptions: boolean;
  onAddMoreContraParticipantsClick: (e: React.SyntheticEvent<any>) => void;
  onEditContraClick: (arg0: string) => void;

  setContraParticipantIdOrder: (order: Array<string>) => void;
};

// Styled Components
const StyledContraParticipantRow = styled(Flex)(
  ({ theme }) => css`
    :not(:last-child) {
      border-bottom: 1px solid ${theme.colors.borderMain};
    }
    padding: 12px 16px;
  `,
);

const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    margin-right: 8px;
    color ${theme.colors.primary};
  `,
);

const SytledReorganizerImage = styled(Icon)`
  user-select: none;
`;

const StyledEditItem = styled(Flex)<{
  active: boolean;
  disabled: boolean;
  isFirst: boolean;
  isLast: boolean;
  isMouseDown: boolean;
}>`
  background-color: ${(props) => {
    if (props.active && props.isMouseDown) {
      return props.theme.colors.backgroundNeutralMain;
    }

    if (!props.active && props.isMouseDown) {
      return props.theme.colors.backgroundNeutralSecondary;
    }
  }};
  border-bottom: ${(props) =>
    `1px solid ${
      !props.isLast ? props.theme.colors.borderMain : 'transparent'
    }`};
  border-radius: ${(props) => {
    if (props.isFirst) {
      return '8px 8px 0 0';
    } else if (props.isLast) {
      return '0 0 8px 8px';
    }

    return null;
  }};
  color: ${(props) => props.disabled && props.theme.colors.borderMain};

  &:hover {
    background-color: ${(props) =>
      (!props.active || !props.isMouseDown) &&
      !props.disabled &&
      props.theme.colors.backgroundNeutralTertiary};
  }
`;

export const SmartTransferWaterfall = ({
  balanceTriggerType,
  contraParticipantEntries,
  contraParticipantIdToEditRequirementsIdMapping,
  isInEditOrderMode,
  isShowingEntryOptions,
  editRequirements,
  focusAccountId,
  onAddMoreContraParticipantsClick,
  onEditContraClick,
  setContraParticipantIdOrder,
}: SmartTransferSelectedContraParticipantsProps) => {
  const { data } = useSmartTransferWaterfallQuery({
    variables: {
      contraParticipantEntries,
      smartTransferRuleEditRequirementsId: editRequirements.id,
    },
  });

  const ruleEditRequirements =
    data?.node?.__typename === 'SmartTransferRuleEditRequirements'
      ? data.node
      : null;

  const { shouldShowContraParticipantEntryAddition } =
    ruleEditRequirements ?? {};
  // @ts-expect-error - TS7006 - Parameter 'order' implicitly has an 'any' type.
  const handleContraParticipantReorder = (order) => {
    setContraParticipantIdOrder(order);
  };

  const containerRef = React.useRef<HTMLDivElement>(null);
  // @ts-expect-error - TS7006 - Parameter 'contraParticipantId' implicitly has an 'any' type.
  const handleEditContraClick = (contraParticipantId) => {
    // if a mapping of "contraParticipantIdToEditRequirementsIdMapping" exists, assume we are editing a ST.
    // if that is the case then pass along editRequirementsId from the mapping.
    // otherwise, assume we are creating and simply pass along contraParticipantId.
    if (contraParticipantIdToEditRequirementsIdMapping) {
      onEditContraClick(
        contraParticipantIdToEditRequirementsIdMapping[contraParticipantId],
      );
    } else {
      onEditContraClick(contraParticipantId);
    }
  };

  return (
    <Box mt={16} mx={32} ref={containerRef}>
      <Card>
        {isInEditOrderMode ? (
          // @ts-expect-error - TS2745 - This JSX tag's 'children' prop expects type '{ key: string; render: (arg0: { active: boolean; disabled: boolean; isFirst: boolean; isLast: boolean; isMouseDown: boolean; }) => ReactNode; }[]' which requires multiple children, but only a single child was provided.
          <DragAndDrop
            disableLast={Boolean(
              contraParticipantEntries[contraParticipantEntries.length - 1]
                .fulfillmentCondition.fulfillmentConditionType === 'INDEFINITE',
            )}
            minHeight={containerRef.current?.clientHeight}
            onDragEnd={handleContraParticipantReorder}
          >
            {contraParticipantEntries
              .filter((contraParticipant) =>
                Boolean(contraParticipant.contraParticipantId),
              )
              .map((contraParticipant) => ({
                key: contraParticipant.contraParticipantId,
                render: ({
                  active,
                  disabled,
                  isFirst,
                  isLast,
                  isMouseDown,
                }) => (
                  <StyledEditItem
                    active={active}
                    disabled={disabled}
                    flexDirection="column"
                    isFirst={isFirst}
                    isLast={isLast}
                    isMouseDown={isMouseDown}
                    p="12px 16px"
                  >
                    <Flex alignItems="center">
                      {!disabled && (
                        <SytledReorganizerImage
                          name="drag24"
                          // @ts-expect-error - TS2769 - No overload matches this call.
                          draggable={false}
                        />
                      )}
                      <Box ml={!disabled ? 16 : 0} width="100%">
                        <SmartTransferWaterfallEntry
                          balanceTriggerType={balanceTriggerType}
                          contraParticipantId={
                            contraParticipant.contraParticipantId
                          }
                          disabled={disabled}
                          isInEditOrderMode
                          focusAccountId={focusAccountId}
                          fulfillmentCondition={
                            contraParticipant.fulfillmentCondition
                          }
                          onEditContraClick={onEditContraClick}
                        />
                      </Box>
                    </Flex>
                    {disabled && (
                      <Flex mt={8}>
                        <Icon name="tooltipFill20" />
                        <PS
                          color="foregroundNeutralSecondary"
                          content="An account with an indefinite endpoint must be ordered last."
                          ml={8}
                        />
                      </Flex>
                    )}
                  </StyledEditItem>
                ),
              }))}
          </DragAndDrop>
        ) : (
          contraParticipantEntries.map((contraParticipant) => {
            const { fulfillmentCondition } = contraParticipant;
            return (
              <StyledContraParticipantRow
                justifyContent="space-between"
                key={contraParticipant.contraParticipantId}
              >
                <Box width="100%">
                  <SmartTransferWaterfallEntry
                    balanceTriggerType={balanceTriggerType}
                    focusAccountId={focusAccountId}
                    fulfillmentCondition={fulfillmentCondition}
                    isInEditOrderMode={false}
                    contraParticipantId={contraParticipant.contraParticipantId}
                    onEditContraClick={handleEditContraClick}
                  />
                </Box>
              </StyledContraParticipantRow>
            );
          })
        )}
        {!isShowingEntryOptions &&
          shouldShowContraParticipantEntryAddition &&
          !isInEditOrderMode && (
            <Flex alignItems="center" justifyContent="center" py={16}>
              <StyledIcon name="addBubble20" />
              <Button
                kind="link"
                onClick={onAddMoreContraParticipantsClick}
                label={
                  ruleEditRequirements?.contraParticipantEntryAdditionLabel ??
                  'Add Destination'
                }
              />
            </Flex>
          )}
      </Card>
    </Box>
  );
};
