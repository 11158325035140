import { Box, Flex, Image, PL, PM, Card } from '@m1/liquid-react';
import * as React from 'react';

import { BackArrow } from '~/components/BackArrow';
import { GenericSystemError } from '~/components/GenericSystemError';
import { useRewardsSingularProgramComponentQuery } from '~/graphql/hooks';
import { InjectedRouteProps } from '~/router';
import { Spinner } from '~/toolbox/spinner';

import { RewardsSingularProgramTable } from './components/RewardsSingularProgramTable';

export const RewardsSingularProgramPage = (props: InjectedRouteProps) => {
  const programId = props.routeParams.programId;

  const { data, loading } = useRewardsSingularProgramComponentQuery({
    variables: {
      programId,
    },
  });

  if (loading) {
    return <Spinner fullScreen />;
  }

  const rewardProgramDetails =
    data?.viewer.credit?.rewardsPortal?.rewardsDetails;
  const rewardsTransactions = rewardProgramDetails?.relatedTransactions;

  if (!rewardProgramDetails) {
    return <GenericSystemError />;
  }

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      mt={150}
      justifyContent="center"
    >
      <Box width={600}>
        <BackArrow
          content="Rewards Portal"
          mb={16}
          to="/d/spend/credit/rewards/summary"
        />
        <Card mb={32}>
          <Flex alignItems="center" justifyContent="space-between" p={16}>
            <Flex alignItems="center">
              {rewardProgramDetails.programOverview?.securityLogoUrl && (
                <Image
                  alt=""
                  height={32}
                  width={32}
                  src={rewardProgramDetails.programOverview.securityLogoUrl}
                />
              )}
              <PL
                ml={16}
                content={rewardProgramDetails.programOverview?.title}
              />
            </Flex>
            <PL content={rewardProgramDetails.programOverview?.value} />
          </Flex>
        </Card>
        <PL fontWeight={600} mb={8} content={rewardProgramDetails.header} />
        <PM mb={16} content={rewardProgramDetails.content} />
        <RewardsSingularProgramTable
          rewardsTransactions={rewardsTransactions}
        />
      </Box>
    </Flex>
  );
};
