import { Box, css, Flex, LM, LS, styled } from '@m1/liquid-react';
import * as React from 'react';

import { useSelector } from 'react-redux';

import { TruncateStringContent } from '~/components/TruncateStringContent';
import { SliceableCellFragment } from '~/graphql/types';
import { getSliceableStatus } from '~/pie-trees/getSliceableStatus';
import { ApolloAppState as AppState } from '~/redux';
import { clickedOnSliceable } from '~/redux/actions';
import { useDispatch } from '~/redux/hooks';

import { SliceableLogo } from '../SliceableLogo';
import { SliceableSelect } from '../SliceableSelect';
import { SliceableStatusIndicator } from '../SliceableStatusIndicator';

export type SliceableNameCellProps = {
  disabled?: boolean;
  indicators?: React.ReactNode;
  navigable?: boolean;
  onClick?: (...args: Array<any>) => any;
  selectable?: boolean;
  size?: 'medium' | 'large';
  sliceable: SliceableCellFragment | null | undefined;
  truncated?: boolean;
};

const Clickable = styled(Box)<{
  navigable: boolean;
}>`
  ${({ navigable }) =>
    navigable &&
    css`
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    `}
`;

const Logo = styled(Box)<{
  size: 'medium' | 'large';
}>`
  ${({ size }) => {
    if (size === 'large') {
      return css`
        min-width: 48px;
        margin: 0 1rem 0 0;
      `;
    }
    return css`
      min-width: 32px;
      margin: 0 1.2rem 0 0;
    `;
  }}
`;

export const SliceableNameCell = ({
  sliceable,
  indicators,
  onClick,
  navigable = false,
  selectable: selectableProp = false,
  disabled,
  size = 'medium',
  truncated = true,
}: SliceableNameCellProps) => {
  const dispatch = useDispatch();
  const handleNameClick = (): void => {
    if (sliceable && onClick) {
      onClick(sliceable.id);
    } else if (sliceable && navigable && clickedOnSliceable) {
      dispatch(clickedOnSliceable(sliceable.id));
    }
  };

  const symbol = sliceable && 'symbol' in sliceable ? sliceable.symbol : null;

  const selectable = useSelector((state: AppState) => {
    return selectableProp && state.mode === 'ADD';
  });

  return (
    <Flex
      width="100%"
      alignItems="center"
      minHeight={size === 'medium' ? 36 : undefined}
      data-testid={`sliceable-name-cell-${symbol}`}
    >
      {selectable && sliceable && (
        <Box mr="0.5rem">
          <SliceableSelect sliceable={sliceable} disabled={disabled} />
        </Box>
      )}
      <Logo position="relative" size={size}>
        {sliceable && <SliceableLogo size="32" sliceable={sliceable} />}
        {indicators || (
          <SliceableStatusIndicator status={getSliceableStatus(sliceable)} />
        )}
      </Logo>
      <Box minWidth={0}>
        <Clickable navigable={navigable} onClick={handleNameClick}>
          {symbol && (
            <div
              style={{
                marginBottom: -4,
              }}
            >
              <LS
                color="foregroundNeutralMain"
                content={symbol}
                data-testid="symbol-name"
              />
            </div>
          )}
          {truncated ? (
            <TruncateStringContent>
              <NameText name={sliceable?.name} size="medium" />
            </TruncateStringContent>
          ) : (
            <NameText name={sliceable?.name} size="medium" />
          )}
        </Clickable>
      </Box>
    </Flex>
  );
};

/* used in SharePie for slices that don't have the necessary data to display the full cell */

export const FakeNameCell = ({
  sliceable,
  onClick,
  navigable = false,
  size = 'medium',
  truncated = true,
}: SliceableNameCellProps) => {
  const dispatch = useDispatch();
  const handleNameClick = (): void => {
    if (sliceable && onClick) {
      onClick(sliceable.id);
    } else if (sliceable && navigable && clickedOnSliceable) {
      dispatch(clickedOnSliceable(sliceable.id));
    }
  };

  return (
    <Flex
      width="100%"
      alignItems="center"
      minHeight={size === 'medium' ? 36 : undefined}
    >
      <Logo position="relative" size={size}>
        {sliceable && <SliceableLogo size="32" sliceable={sliceable} />}
      </Logo>
      <Box minWidth={0}>
        <Clickable navigable={navigable} onClick={handleNameClick}>
          {truncated ? (
            <TruncateStringContent>
              <NameText name={sliceable?.name} size="medium" />
            </TruncateStringContent>
          ) : (
            <NameText name={sliceable?.name} size="medium" />
          )}
        </Clickable>
      </Box>
    </Flex>
  );
};

const NameText = ({
  size,
  name,
}: {
  size: 'medium' | undefined;
  name: string | undefined;
}) => (
  <LM
    content={name}
    font={size === 'medium' ? 'LM' : 'PXL'}
    color="foregroundNeutralMain"
  />
);
