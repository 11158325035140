import { Box, Button, Flex, HXS, PL, Card } from '@m1/liquid-react';
import * as React from 'react';

import { AppContext } from '~/AppContext';
import { GenericSystemError } from '~/components/GenericSystemError';
import {
  useGetTransferRuleQuery,
  useSetEnabledOnTransferRuleMutation,
} from '~/graphql/hooks';
import { TransferRuleDetail_ScheduledTransferRule_Fragment } from '~/graphql/types';
import { useLocation } from '~/hooks/useLocation';
import { useNavigate } from '~/hooks/useNavigate';
import { ButtonGroup } from '~/toolbox/ButtonGroup';
import { Divider } from '~/toolbox/divider';
import { Grid } from '~/toolbox/grid';
import { Spinner } from '~/toolbox/spinner';
import { Switch } from '~/toolbox/switch';

import { CheckTransferDetails } from './components/CheckTransferDetails';
import { LatestInstance } from './components/LatestInstance';
import { RuleHeaderDetails } from './components/RuleHeaderDetails';
import { SwitchLabel } from './components/SwitchLabel';
import { TransferDetails } from './components/TransferDetails';

type Props = {
  transferRuleId: string;
};

export const TransferRuleDetailsPage = ({ transferRuleId }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, loading } = useGetTransferRuleQuery({
    variables: {
      id: transferRuleId,
    },
    fetchPolicy: 'network-only',
  });

  const [
    setEnabledOnTransferRule,
    { loading: mutationLoading, error: mutationError },
  ] = useSetEnabledOnTransferRuleMutation();

  const { analytics } = React.useContext(AppContext);

  if (loading) {
    return <Spinner />;
  }

  if (!data?.node) {
    return <GenericSystemError />;
  }

  const transferRule =
    data.node as TransferRuleDetail_ScheduledTransferRule_Fragment;

  const handleChange = (): void => {
    setEnabledOnTransferRule({
      variables: {
        input: {
          isEnabled: !transferRule.isEnabled,
          transferRuleId: transferRule.id,
        },
      },
      onCompleted: () => {
        const analyticsEvent = transferRule.isEnabled
          ? 'm1_personal_loan_autopay_toggled_off'
          : 'm1_personal_loan_autopay_toggled_on';
        analytics.recordEvent(analyticsEvent);
      },
    });
  };

  const isRecurringCheck =
    transferRule.to?.transferParticipantType === 'PHYSICAL_CHECK';
  const isAutoPay =
    transferRule.__typename === 'ScheduledTransferRule' &&
    (transferRule.isCreditCardAutoPay || transferRule.isPersonalLoanAutoPay);

  const getPageTitle = (): string => {
    switch (transferRule.to?.transferParticipantType) {
      case 'PHYSICAL_CHECK':
        return 'Check schedule details';
      case 'CREDIT_CARD':
      case 'LOAN':
        return 'AutoPay details';
      default:
        return 'Schedule details';
    }
  };

  return (
    <Box m="0 auto" p="64px 0 100px 0" width={490}>
      <HXS content={getPageTitle()} />
      <Card
        style={{
          marginTop: 32,
          padding: 32,
        }}
      >
        <RuleHeaderDetails transferRule={transferRule} isAutoPay={isAutoPay} />
        <Divider spacing="relaxed" />
        <Grid
          style={{
            padding: 0,
          }}
        >
          {isRecurringCheck &&
          transferRule.__typename === 'ScheduledTransferRule' ? (
            <CheckTransferDetails transferRule={transferRule} />
          ) : (
            <TransferDetails
              transferRule={transferRule}
              isAutoPay={isAutoPay}
            />
          )}
        </Grid>
        {transferRule.latestInstance && (
          <LatestInstance instance={transferRule.latestInstance} />
        )}
        <Flex alignItems="center" justifyContent="flex-start" mt={32}>
          <Switch
            checked={transferRule.isEnabled}
            disabled={mutationLoading}
            label={
              <SwitchLabel
                isEnabled={transferRule.isEnabled}
                isAutoPay={isAutoPay}
              />
            }
            onChange={handleChange}
          />
          {mutationLoading && (
            <Box ml={16}>
              <Spinner radius={16} thickness={3} />
            </Box>
          )}
          {mutationError && (
            <Box ml={16}>
              <PL color="critical" content="Error saving schedule." />
            </Box>
          )}
        </Flex>
      </Card>
      <ButtonGroup mt={64}>
        <Button
          kind="secondary"
          label="Edit"
          onClick={() => {
            navigate({
              to: transferRule.sendCheckRecipient
                ? '/d/c/create-send-check/:step/:scheduledTransferRuleId'
                : '/d/c/edit-transfer-schedule/:scheduledTransferRuleId',
              params: {
                scheduledTransferRuleId: transferRule.id,
                step: 'send-check-overview',
              },
            });
          }}
          size="large"
        />
        <Button
          kind="primary"
          label="Done"
          onClick={() => {
            navigate({
              to: location?.query?.previousRouteName ?? '/d/transfers/rules',
            });
          }}
          size="large"
        />
      </ButtonGroup>
    </Box>
  );
};
