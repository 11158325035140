import * as React from 'react';
import { IndexRedirect, Route } from 'react-router';

// TODO: remove once we stop supporting the old spend deep links for HYSA/savings
export const SavingsRedirectRoute = () => {
  return (
    <Route path="savings">
      <IndexRedirect to="/d/save/savings/transactions" />

      <Route path="transactions">
        <IndexRedirect to="/d/save/savings/transactions" />
      </Route>

      <Route path="account">
        <IndexRedirect to="/d/save/savings/account" />
      </Route>
    </Route>
  );
};
