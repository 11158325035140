import { Box, Button, Flex, HM, PL, PM } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { BackArrow } from '~/components/BackArrow';
import { ControlledInput } from '~/components/form/ControlledInput';
import { useOpenInvestJointAccountWizardContext } from '~/flows/components/OpenInvestJointAccountWizard/hooks/useOpenInvestJointAccountWizardContext';
import { format, normalize } from '~/forms/fields/ssn-field';
import { FormMockerReactHookForm } from '~/forms/FormMockers';
import { submitReactFormData } from '~/redux/actions';
import { useDispatch, useSelector } from '~/redux/hooks';

export interface SocialSecurityNumberFormValues {
  socialSecurityNumber: string;
}

export const SocialSecurityNumber = () => {
  const dispatch = useDispatch();

  const submittedFormValues = useSelector((state) => {
    if (!state.reactHookForm.openJointInvestAccountSocialSecurityNumber) {
      return {
        socialSecurityNumber: undefined,
      };
    }

    return state.reactHookForm.openJointInvestAccountSocialSecurityNumber;
  });

  const { control, handleSubmit, watch, register, setValue, formState } =
    useForm<SocialSecurityNumberFormValues>({
      defaultValues: submittedFormValues,
    });

  const wiz = useOpenInvestJointAccountWizardContext();

  const socialSecurityNumber = watch('socialSecurityNumber');

  const onSubmit: SubmitHandler<SocialSecurityNumberFormValues> = (data) => {
    dispatch(
      submitReactFormData({
        openJointInvestAccountSocialSecurityNumber: data,
      }),
    );

    wiz.next();
  };

  return (
    <Flex maxWidth={588} flexDirection="column" mx="auto">
      <Box pt={32}>
        <BackArrow
          content="Back"
          onClick={() => {
            wiz.back();
          }}
        />
      </Box>
      <HM mt={32}>We need to verify your Social Security number.</HM>
      <PL py={32}>
        Legally we have to ask and verify your answer. We will <b>not</b> run a
        credit check.
      </PL>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex>
          <ControlledInput
            name="socialSecurityNumber"
            label="Secondary account holder SSN"
            control={control}
            rules={{ required: true }}
            transformInput={(value) => {
              const norm = normalize(value, socialSecurityNumber);
              return format(norm);
            }}
            maxLength={11}
            backgroundColor="backgroundNeutralMain"
            placeholder="123-45-6789"
          />
        </Flex>
        <Flex alignItems="center" mt="16px">
          <Icon
            name="lock32"
            style={{
              ariaHidden: true,
            }}
          />
          <PM
            ml={8}
            content="We protect your personal information using security and encryption best practices."
          />
        </Flex>
        <Button
          mt={32}
          type="submit"
          size="large"
          label="Continue"
          kind="primary"
        />
        <FormMockerReactHookForm
          setValue={setValue}
          fields={[
            {
              name: 'socialSecurityNumber',
              value: '666-00-9258',
            },
          ]}
        />
      </form>
    </Flex>
  );
};
