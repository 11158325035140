import { styled, theme, Box, Flex, PL, PM, PXL } from '@m1/liquid-react';
import { Icon } from '@m1/liquid-react/icons';
import * as React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

import { DisclosureLink } from '~/components/DisclosureLink/DisclosureLink';
import { BillingMethodSectionFragment } from '~/graphql/types';
import { AppImage } from '~/lens-toolbox/AppImage';
import { LinkableLink } from '~/lens-toolbox/LinkableLink';
import { Dropdown } from '~/toolbox/Dropdown';

import { createRichTextParagraphs } from '~/utils/createRichTextParagraphs';

import { PlatformBillingMethod } from './SettingsPlatformBillingPage';
import { participantToDropdownOption } from './SettingsPlatformBillingPage.utils';

type BillingMethodSectionProps = {
  billingMethodSection: BillingMethodSectionFragment;
  formMethods: UseFormReturn<PlatformBillingMethod, any, undefined>;
  handleChange: (value: string) => void;
  platformBillingMethod: string | null | undefined;
};

const StyledLinkableLink = styled(LinkableLink)`
  white-space: nowrap;
  color: ${theme.colors.foregroundPrimary};

  width: 100%;
`;

export const BillingMethodSection = ({
  billingMethodSection,
  formMethods,
  handleChange,
  platformBillingMethod,
}: BillingMethodSectionProps) => {
  const dropdownOptions = participantToDropdownOption(
    billingMethodSection?.billingMethods,
  );
  const hasNoAccounts = dropdownOptions.length === 0;
  const placeholder = hasNoAccounts ? 'No account available' : 'Select account';
  const iconName = hasNoAccounts
    ? 'accountAccountsDisabled32'
    : 'accountAccountsPrimary32';

  return (
    <Flex flexDirection="column" gap={16}>
      <Flex flexDirection="row">
        {billingMethodSection?.titleIcon && (
          <AppImage appImage={billingMethodSection.titleIcon} />
        )}
        <PXL fontWeight={500} ml={8} content={billingMethodSection?.title} />
      </Flex>
      {billingMethodSection.billingMethods && (
        <FormProvider {...formMethods}>
          <form name="platform-billing-method">
            {/* `Dropdown` has its own padding. Add negative margin on the container to skip the flexbox `gap` for this item. */}
            <Box maxWidth={385} my={-16}>
              <Dropdown
                name="platformBillingMethod"
                isSearchable={false}
                disabled={hasNoAccounts}
                size="large"
                source={[{ label: 'M1 accounts', options: dropdownOptions }]}
                placeholder={
                  <Flex flexDirection="row" alignItems="center">
                    <Icon name={iconName} />
                    <PL ml={16} content={placeholder} />
                  </Flex>
                }
                onChange={handleChange}
                value={platformBillingMethod}
              />
            </Box>
          </form>
        </FormProvider>
      )}
      {billingMethodSection.subtitle && (
        <PM
          content={billingMethodSection.subtitle}
          color="foregroundNeutralSecondary"
        />
      )}
      {billingMethodSection?.disclosures &&
        billingMethodSection.disclosures.length > 0 && (
          <Flex flexDirection="column" gap={16}>
            {billingMethodSection.disclosures.map((disc, i) => (
              <DisclosureLink
                key={i}
                linkText={disc.linkText}
                paragraphs={createRichTextParagraphs(disc.paragraphs)}
                font="PM"
              />
            ))}
          </Flex>
        )}
      {billingMethodSection?.links && billingMethodSection.links.length > 0 && (
        <Flex flexDirection="column" gap={16}>
          {billingMethodSection.links.map((link) => {
            return (
              <StyledLinkableLink
                width="fit-content"
                underline
                fontSize="14px"
                fontWeight={400}
                key={link.title}
                linkable={link}
              />
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};
