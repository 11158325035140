import * as React from 'react';
import { Route } from 'react-router';

import { SmartTransferContraParticipantEntryInstancesPage } from './SmartTransferContraParticipantEntryInstancesPage';

export const SmartTransferContraParticipantEntryInstancesRoute = () => (
  <Route
    component={SmartTransferContraParticipantEntryInstancesPage}
    cover
    fallbackRoute="/d/c/smart-transfer-details"
    path="smart-transfer-instances/:smartTransferContraParticipantEntryId"
  />
);
